import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import BusinessCalendarModal from "./BusinessCalendarModal";
import BusinessCalendarIrrBatchModal from "./BusinessCalendarIrrBatchModal";
import Type from "./Type.json";
import * as GlobalConst from "../../components/AscConstants";
import moment from 'moment';
import 'moment/locale/ja';
moment.locale('ja');

export default class BusinessCalenadr extends Component {
    constructor(props) {
        super(props);

        this.day_string = {
            [Type.DAY.SUN]: this.props.langText.Body.Sunday,
            [Type.DAY.MON]: this.props.langText.Body.Monday,
            [Type.DAY.TUE]: this.props.langText.Body.Tuesday,
            [Type.DAY.WED]: this.props.langText.Body.Wednesday,
            [Type.DAY.THU]: this.props.langText.Body.Thursday,
            [Type.DAY.FRI]: this.props.langText.Body.Friday,
            [Type.DAY.SAT]: this.props.langText.Body.Saturday,
            [Type.DAY.HOLI]: this.props.langText.Body.Holiday,
            // [Type.DAY.B_HOLI]: this.props.langText.Body.DayBeforeHoliday,
        };

        this.action_string = {
            [Type.ACTION.INBOUND]: this.props.langText.Body.Inbound,
            [Type.ACTION.BUSY]: this.props.langText.Body.Busy,
            [Type.ACTION.TRANSFER]: this.props.langText.Body.ExternalTransfer,
            [Type.ACTION.VOICE]: this.props.langText.Body.PlayVoice,
            [Type.ACTION.SCRIPT]: this.props.langText.Body.Script,
        };

        this.business_string = {
            [Type.BUSINESS.START]: this.props.langText.Body.BusinessDay,
            [Type.BUSINESS.END]: this.props.langText.Body.DayOff
        };

        this.irr_business_string = {
            [Type.BUSINESS.START]: this.props.langText.Body.BusinessDay,
            [Type.BUSINESS.END]: this.props.langText.Body.DayOff,
            [Type.BUSINESS.DEL]: this.props.langText.Body.Delete
        };

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null
        };
    }

    getColumnsData = () => {
        let elem = [];
        let day_column = this.getDayColumns();

        elem.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            width: this.props.boardWidth.id,
            filterable: false
        });

        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            elem.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                Filter: () => <FormControl 
                    placeholder={this.props.langText.Body.ForwardMatch} 
                    onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")}/>
            });
        }

        elem.push({
            Header: this.props.langText.Body.BusinessCalendar,
            accessor: "business_calendar_name",
            Filter: () => <FormControl 
                placeholder={this.props.langText.Body.ForwardMatch} 
                onChange={e => this.onFilterChange(e.target.value, "business_calendar_name")}/>
        },
        ...day_column,
        {
            Header: this.props.langText.Body.Control,
            width: this.props.boardWidth.smedium,
            Cell: data => {
                let elem = [];

                if (this.props.currentPermission.edit) {
                    elem.push(
                        <Button
                            key="update"
                            bsSize="xsmall"
                            onClick={this.modalShow("update", data.row._original)}
                            className="control-button">
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    );
                } else {
                    elem.push(
                        <Button
                            key="read"
                            bsSize="xsmall"
                            onClick={this.modalShow("read", data.row._original)}
                            className="control-button">
                            <Glyphicon glyph="eye-open"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.create) {
                    elem.push(
                        <Button
                            key="copy"
                            bsSize="xsmall"
                            onClick={this.modalShow("copy", data.row._original)}
                            className="control-button">
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    elem.push(
                        <Button
                            key="delete"
                            bsSize="xsmall"
                            onClick={this.modalShow("delete", data.row._original)}
                            className="control-button">
                            <Glyphicon glyph="minus"/>
                        </Button>
                    );
                }

                return(
                    <Row className="text-center">
                        {elem}
                    </Row>
                )
            },
            sortable: false,
            filterable: false
        });

        return elem;
    }

    getDayColumns = () => {
        let day_colums = [];

        for (let day in this.day_string) {
            day_colums.push({
                Header: this.day_string[day],
                accessor: "cm32_business_calendar_details",
                width: this.props.boardWidth.week,
                Cell: data => {
                    const day_info = data.row.cm32_business_calendar_details.find(row => row.day_of_the_week === day);

                    if (day_info) {
                        const action = day_info.type === Type.BUSINESS.START ? day_info.business_hour_action_flag : day_info.non_business_hour_action_flag;
                        if (this.action_string[action]) return this.action_string[action];
                    }

                    return "---";
                },
                sortable: false,
                filterable: false
            });
        }

        return day_colums;
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();
        this.blockUI();

        try {
            if (param && param.id) param = (await this.getModal(param.id)).data[0];

            //複製モーダルには過去のイレギュラーを表示しない
            let business_calendar_name = param? param.business_calendar_name : "";
            let cm33_business_calendar_irregular_infos = param ? param.cm33_business_calendar_irregular_infos: [];
            
            if (modalType == "copy"){
                business_calendar_name = "";
                cm33_business_calendar_irregular_infos = param.cm33_business_calendar_irregular_infos_future;
            }

            this.setState({
                id: param ? param.id : "",
                business_calendar_name,
                company_id: param ? param.cm12_companies.id : this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) ? "" : this.props.userInfo.company_id,
                company_name: param ? param.cm12_companies.company_name : this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) ? "" : this.props.userInfo.company_name,
                cm32_business_calendar_details: param ? param.cm32_business_calendar_details : [],
                cm33_business_calendar_irregular_infos,
                modalType,
                show: true
            });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
            this.reactTableRefresh();
        }
    }

    getModal = (id) => {
        return this.ascAxios("post", `${this.reactContainerPath}/Modal`, {id});
    }

    onClickHandle = (state) => {
        switch(this.state.modalType) {
            case "insert":
            case "copy":
                this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                    company_id: state.company_id,
                    business_calendar_name: state.business_calendar_name,
                    cm32_business_calendar_details: state.cm32_business_calendar_details,
                    cm33_business_calendar_irregular_infos: state.cm33_business_calendar_irregular_infos
                })
                .then(res => {
                    alert(this.props.langText.Message.DataInsertSuccess);
                    this.reactTableRefresh();
                })
                .catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;
            case "update":
                this.ascAxios("post", `${this.reactContainerPath}/update`, {
                    id: state.id,
                    company_id: state.company_id,
                    business_calendar_name: state.business_calendar_name,
                    cm32_business_calendar_details: state.cm32_business_calendar_details,
                    cm33_business_calendar_irregular_infos: state.cm33_business_calendar_irregular_infos
                })
                .then(res => {
                    alert(this.props.langText.Message.DataUpdateSuccess);
                    this.reactTableRefresh();
                })
                .catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;
            case "delete":
                this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    id: state.id
                })
                .then(res => {
                    alert(this.props.langText.Message.DataDeleteSuccess);
                    this.reactTableRefresh();
                })
                .catch(err => {
                    alert(this.getErrorString(err.response.data));
                });
                break;
            default:
                break;
        }
        
        this.setState({
            show: false
        });
    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "insert":
            case "read":
            case "copy":
            case "update":
            case "delete":
                return (
                    <BusinessCalendarModal
                        state={this.state}
                        propSetState={this.propSetState}
                        reactContainerPath={this.reactContainerPath}
                        currentPermission={this.props.currentPermission}
                        dayString={this.day_string}
                        actionString={this.action_string}
                        businessString={this.business_string}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick={this.onClickHandle}
                    />
                );
            case "irregularBatchSetting":
                return (
                    <BusinessCalendarIrrBatchModal
                        state={this.state}
                        propSetState={this.propSetState}
                        reactContainerPath={this.reactContainerPath}
                        currentPermission={this.props.currentPermission}
                        dayString={this.day_string}
                        actionString={this.action_string}
                        businessString={this.irr_business_string}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick={this.onClickHandle}
                    />
                );
            default:
                break;
        }
    }

    render() {
        return (
            <div className="BusinessCalendar">
                <Row>
                    <Col xs={12} md={12}>
                        {this.props.currentPermission.create &&
                            <Button
                                className="table-button"
                                id="calendar-inster-btn"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                                value="insert">
                                {this.props.langText.Body.InsertTitle}
                            </Button>                 
                        }
                        {this.props.currentPermission.edit &&
                            <Button
                                className="table-button"
                                id="board_irregular-batch-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("irregularBatchSetting")}>
                                {this.props.langText.Body.IrregularBatchSetting}
                            </Button>
                        }
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>
                {this.state.show && this.getModalBranch()}
            </div>
        );
    }
}