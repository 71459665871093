import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Col, ControlLabel, Glyphicon} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import ASSelect from "../Elements/Select/Select";
import InlineForm from "../Elements/FromGroup/InlineForm"
import DatePicker from "react-datepicker";
import "../Elements/FromGroup/form_select.css"
import moment from "moment";


export default class ExternalNumberScheduleDispensingModal extends Component {

    constructor(props) {
        super(props);
        // 時
        this.hourShowList = this.getSelectOption('hour_show_list', this.props.langText.SelectOption);
        // 分
        this.minuteShowList = this.getSelectOption('minute_show_list', this.props.langText.SelectOption);
    }

    getScheduleDispensingInfoItem() {
        let {
            state,
            langText,
            validationHandle,
            onIntChange,
            charaLimit,
            onDateChangeHandleDispensing,
            onKeyDownIntCheck,
            checkDispensingTime,
            checkDispensingCount
        } = this.props;

        let itemArr = [];

        if(!state.dispensing_date_validation){
            itemArr.push(
                <div
                    key = "schedule-dispensing-validation"
                    className = "schedule-dispensing-validation"
                >
                    {langText.Message[state.validate_msg]}
                </div>
            );
        }

        // 開始日時
        itemArr.push(
            <InlineForm
                key = "start-datetime"
                label = {langText.Body.DispensingStartDateTime}
                componentClass = {ControlLabel}
                validationState = {validationHandle("dispensing_start_datetime")}
            >
                <Col xs={11} sm={11} md={4} lg={4}>
                    <span className="start-datetime">
                        <DatePicker 
                            onChange = {e => onDateChangeHandleDispensing(e, "dispensing_start_datetime")}
                            selected = {state.dispensing_start_datetime ? moment(state.dispensing_start_datetime) : null}
                            dateFormat = "YYYY-MM-DD"
                            className = "form-control"
                            todayButton = {this.props.langText.Body.Today}
                            placeholderText = {this.props.langText.Body.StartDate}
                            minDate = {moment({h:0, m:0, s:0, ms:0})}
                            isClearable = {true}
                        />
                    </span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="start_time_hour"
                        validationState = {validationHandle("dispensing_start_time_hour")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            value={state.dispensing_start_time_hour}
                            options={this.hourShowList}
                            placeholder="---"
                            onChange = {e => onDateChangeHandleDispensing(e, "dispensing_start_time_hour")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="start_time_minutes"
                        validationState = {validationHandle("dispensing_start_time_minutes")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="minutes-select"
                            value={state.dispensing_start_time_minutes}
                            options={this.minuteShowList}
                            placeholder="---"
                            onChange = {e => onDateChangeHandleDispensing(e, "dispensing_start_time_minutes")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                </Col>
            </InlineForm>
        );

        // 時間と分のセレクトボックスのサイズ変更
        const customStyles = {
            menuList: (provided) => ({
                ...provided,
                maxHeight: '200px',
                overflowY: 'auto'
            })
        };

        // 終了日時
        itemArr.push(
            <InlineForm
                key = "end-datetime"
                label = {langText.Body.DispensingEndDateTime}
                componentClass = {ControlLabel}
                validationState = {validationHandle("dispensing_end_datetime")}
            >
                <Col xs={11} sm={11} md={4} lg={4}>
                    <DatePicker 
                        onChange = {e => onDateChangeHandleDispensing(e, "dispensing_end_datetime")}
                        selected = {state.dispensing_end_datetime ? moment(state.dispensing_end_datetime) : null}
                        dateFormat = "YYYY-MM-DD"
                        className = "form-control"
                        todayButton = {this.props.langText.Body.Today}
                        placeholderText = {this.props.langText.Body.EndDate}
                        minDate = {state.dispensing_start_datetime ? moment(state.dispensing_start_datetime) : null}
                        maxDate = {moment(state.dispensing_start_datetime).add(1,'weeks')}
                        isClearable = {true}
                    />
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="end_time_hour"
                        validationState = {validationHandle("dispensing_end_time_hour")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            styles={customStyles}
                            value={state.dispensing_end_time_hour}
                            options={this.hourShowList}
                            placeholder="---"
                            onChange = {e => onDateChangeHandleDispensing(e, "dispensing_end_time_hour")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="end_time_minutes"
                        validationState = {validationHandle("dispensing_end_time_minutes")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            styles={customStyles}
                            value={state.dispensing_end_time_minutes}
                            options={this.minuteShowList}
                            placeholder="---"
                            onChange = {e => onDateChangeHandleDispensing(e, "dispensing_end_time_minutes")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                </Col>
            </InlineForm>
        );

        // 秒数
        itemArr.push(
            <InlineForm
                key = "dispensing_time"
                controlId = "dispensing_time"
                label = {langText.Body.SecCnt}
                type = "text"
                onKeyDown = {e => onKeyDownIntCheck(e)}
                onBlur={() => checkDispensingTime()}
                onChange={onIntChange("dispensing_schedule_time",charaLimit.External_dispensing_time)}
                value = {state.dispensing_schedule_time}
                placeholder={langText.Message.ExternalNumber_DispensingTime}
                validationState = {validationHandle("dispensing_schedule_time")}
            />
        );

        if(state.dispensingScheduleTimeValidationFlag){
                itemArr.push(
                <p key="dispensing-time-limit" className="text-danger">
                    {langText.Message.ExternalNumber_DispensingTimeLimit}
                </p>
                );
            }
        ;

        // 着信数
        itemArr.push(
            <InlineForm
                key = "dispensing-count"
                controlId = "dispensing-count"
                label = {langText.Body.InboundCnt}
                type = "text"
                onKeyDown = {e => onKeyDownIntCheck(e)}
                onBlur={() => checkDispensingCount()}
                onChange={onIntChange("dispensing_schedule_count",charaLimit.External_dispensing_count)}
                value = {state.dispensing_schedule_count}
                validationState = {validationHandle("dispensing_schedule_count")}
            />
        );

        if(state.dispensingScheduleCountValidationFlag){
            itemArr.push(
            <p key="dispensing-count-limit" className="text-danger">
                {langText.Message.ExternalNumber_DispensingTimeOverChannelNumber}
            </p>
            );
        }

        return itemArr
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ScheduleDispensingSettings}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getScheduleDispensingInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
