import React from "react";
import InlineForm from "../FromGroup/InlineForm";
import AscComponent from "../../../components/AscComponent";
import MultiValueDisableForm from "../FromGroup/MultiValueDisableForm";
import { CSVReader } from 'react-papaparse'

/**
 * 自動発信リスト新規登録
 */
export class InsertAutoCallList extends AscComponent {
    render() {
        return (
            <InlineForm
                key="list_name"
                controlId="list_name"
                label={this.props.langText.Body.ListName}
                type="text"
                value={this.props.state.list_name}
                onChange={e => this.props.onTextChange_Limit(
                    e, "list_name", this.props.charaLimit.AutoCallList_Name)}
                validationState={this.props.state.list_name ? null : "error"}
            />

        );
    }
}

/**
 * 自動発信リスト削除
 */
export class DeleteAutoCallList extends AscComponent {
    render() {
        return (
            <div>
                <InlineForm.static
                    label={this.props.langText.Body.CompanyName}
                    children={this.props.state.company_name}
                />
                <InlineForm.static
                    label={this.props.langText.Body.ListName}
                    children={this.props.state.list_name}
                />
                <InlineForm.static
                    label={this.props.langText.Body.ListCount}
                    children={this.props.state.list_count + this.props.langText.Body.Count}
                />
            </div>

        );
    }
}

/**
 * 自動発信リスト詳細画面（削除、閲覧）
 */
export class ViewAutoCallDetailList extends AscComponent {

    createEmptyForm = (header) => {
        return (
            <InlineForm.static
                key={header.column_name}
                controlId={header.column_name}
                label={header.header_name}
                type="text"
            />
        );
    }

    createItemsForm = () => {
        let items = [];
        let cm77HeaderNames;
        this.props.state.header_json.forEach((header) => {
                this.props.state.detail_json.forEach(detail => {
                    cm77HeaderNames = Object.keys(detail);
                    if (cm77HeaderNames.indexOf(header.column_name) >= 0 && header.disabled !== 'Y') {
                        //cm76とcm77のヘッダカラムが一致かつ有効なデータを表示
                        items.push(
                            <InlineForm.static
                                key={header.column_name}
                                controlId={header.column_name}
                                label={header.header_name}
                                type="text"
                            >
                                {detail[header.column_name]}
                            </InlineForm.static>
                        )
                    } else if (header.disabled !== 'Y') {
                        //cm76にヘッダーが登録されているが、cm77.customize*に項目がない場合は空白で表示
                        items.push(
                            this.createEmptyForm(header)
                        )
                    }
                });
        });
        return items;
    }

    render() {
        return (
            <div>
                <InlineForm.static
                    key="tel_number"
                    controlId="tel_number"
                    type="text"
                    label={this.props.langText.Body.Telno}
                >
                    {this.props.state.tel_number}
                </InlineForm.static>
                <div>{this.createItemsForm()}</div>
            </div>

        );
    }

}

/**
 * 自動発信リスト詳細新規登録画面
 */
export class InsertAutoCallDetailList extends AscComponent {
    validateTelNumber(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validateTelNumber(param);
        }
        return validation_flag;
    }

    render() {
        let items = [];
        if (this.props.state.header_json.length > 0) {
            this.props.state.header_json.forEach(header => {
                if (header.disabled !== 'Y' && Object.keys(header).length > 0) {
                    //削除されていないヘッダーを空白で表示
                    items.push(
                        <InlineForm
                            key={header.column_name}
                            controlId={header.column_name}
                            label={header.header_name}
                            type="text"
                            value={this.props.state.detail_json[0][header.column_name] ? this.props.state.detail_json[0][header.column_name] : ""}
                            onChange={e => this.props.onChangeValue(
                                e, "detail_json", header.column_name)}
                        />
                    )
                }
            });
        }

        return (
            <div>
                <InlineForm
                    key="tel_number"
                    controlId="tel_number"
                    label={this.props.langText.Body.RequiredTelno}
                    type="text"
                    value={this.props.state.tel_number}
                    onChange={e => this.props.onTextChange_Limit(
                        e, "tel_number", this.props.langText.Value.tel_number.max)}
                    validationState={
                        this.validateTelNumber(this.props.state.tel_number, true) &&
                        !this.props.state.duplicateTelFlag
                        }
                    placeholder = {this.props.langText.Body.ScriptTelNo}
                    onBlur={(event) => this.props.onFocusOut(event)}
                />
                <div>{items}</div>
            </div>
        );
    }
}

/**
 * 自動発信リスト詳細編集画面
 */
export class EditAutoCallDetailList extends AscComponent {
    validateTelNumber(param, dflt = false){
        let validation_flag = dflt;
        if(param){
            validation_flag = super.validateTelNumber(param);
        }
        return validation_flag;
    }

    createEmptyForm = (header) => {
        return (
            <InlineForm
                key={header.column_name}
                controlId={header.column_name}
                label={header.header_name}
                type="text"
                value=""
                onChange={e => this.props.onChangeValue(
                    e, "detail_json", header.column_name)}
            />
        );
    }

    createItemsForm = () => {
        let items = [];
        let cm77HeaderNames;
        this.props.state.header_json.forEach((header) => {
            this.props.state.detail_json.forEach(detail => {
                cm77HeaderNames = Object.keys(detail);
                if (cm77HeaderNames.indexOf(header.column_name) >= 0 && header.disabled !== 'Y') {
                    //cm76とcm77のヘッダカラムが一致かつ有効なデータを表示
                    items.push(
                        <InlineForm
                            key={header.column_name}
                            controlId={header.column_name}
                            label={header.header_name}
                            type="text"
                            value={detail[header.column_name] ? detail[header.column_name] : ""}
                            onChange={e => this.props.onChangeValue(
                                e, "detail_json", header.column_name)}
                        />
                    )
                } else if (header.disabled !== 'Y') {
                    //cm76にヘッダーが登録されているが、cm77.customize*に項目がない場合は空白で表示
                    items.push(
                        this.createEmptyForm(header)
                    )
                }
            });
        });
        return items;
    }

    render() {
        return (
            <div>
                <InlineForm
                    key="tel_number"
                    controlId="tel_number"
                    label={this.props.langText.Body.RequiredTelno}
                    type="text"
                    value={this.props.state.tel_number}
                    onChange={e =>
                        this.props.onTextChange_Limit(
                            e,
                            "tel_number",
                            this.props.langText.Value.tel_number.max
                        )
                    }
                    validationState={
                        this.validateTelNumber(this.props.state.tel_number, true) &&
                        !this.props.state.duplicateTelFlag
                    }
                    placeholder = {this.props.langText.Body.ScriptTelNo}
                    onBlur={(event) => this.props.onFocusOut(event)}
                />
                <div>{this.createItemsForm()}</div>
            </div>
        );
    }
}


/**
 * 自動発信リスト詳細インポート画面
 */
export class InsertCsvAutoCallDetailList extends AscComponent {
    render() {
        return (
            <CSVReader
                onDrop={this.props.handleOnDrop}
                onError={this.props.handleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={this.props.handleOnRemoveFile}
                isReset={true}
            >
                <span>{this.props.langText.Message.CsvUpload}</span>
            </CSVReader>
        );
    }
}

/**
 * リスト管理画面
 */
export class EditAutoCallListManagement extends AscComponent {
    /**
     * リスト名表示
     */
    displayListName = () => {
        return (
            <InlineForm
                key="autocalldetail_listname"
                controlId="autocalldetai_listname"
                label={this.props.langText.Body.ListName}
                type="text"
                value={this.props.state.list_name}
                onChange={e => this.props.onTextChange_Limit(
                    e, "list_name", this.props.charaLimit.AutoCallList_Name)}
                validationState={this.props.state.list_name ? null : "error"}
            />
        );
    }

    /**
     * 項目一覧表示
     */
    displayItemNames = () => {
        let {
            onMultiValueFormTextChange,
            onMultiValueFormSizeChange,
            validationHandle
        } = this.props;

        //有効数
        const enabledCount = this.props.state.headerItems.filter(Item => Item.disabled !== 'Y').length;
        //無効数
        const disableCount = this.props.state.headerItems.filter(Item => Item.disabled === 'Y').length;
        return (
            <InlineForm
                label={this.props.langText.Body.ItemNameManagement +
                    '（' + this.props.langText.Body.ItemNameCount + enabledCount + '　' +
                    this.props.langText.Body.ItemNameDeleteCount + disableCount + ')'}>
                <MultiValueDisableForm
                    key="headerItems"
                    controlId="headerItems"
                    value={this.props.state.headerItems}
                    onTextChange={onMultiValueFormTextChange}
                    onSizeChange={onMultiValueFormSizeChange}
                    validationState={validationHandle('autoCallListManagement')}
                />
            </InlineForm>
        );
    }

    render() {
        return (
            <div>
                {this.displayListName()}
                {this.displayItemNames()}
            </div>
        );
    }
}
