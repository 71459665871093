import React from "react";
import ReactTable from "react-table-6";
import "./ColumnFixTable.css";
import "react-table-6/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";

const ReactTableFixedColumns = withFixedColumns(ReactTable);

export default ({
                    talbeRef,
                    className = "",
                    data,
                    columns,
                    defaultPageSize = 20,
                    page,
                    ...props
                }
                ) => {
    return (<ReactTableFixedColumns
        onSortedChange={(newSorted, column, shiftKey) => {
            props.page=0;
        }} 
        ref={talbeRef}
        className={`ColumnFixTable -striped -highlight ${className}`}
        data={data}
        columns={columns}
        page={page}
        defaultPageSize={defaultPageSize}
        getTrProps={(state, rowInfo) => {
            let returnObj = {};
            let classNameList = "";
            let style = [];
            
            if (rowInfo && rowInfo.row && rowInfo.row._original.style) {
                style = rowInfo.row._original.style;
                style.forEach(name => {
                    if (classNameList.length > 0) {
                        classNameList += ` ${name}`
                    } else {
                        classNameList += name
                    }
                })
            }
            
            if (style.length > 0) {
                returnObj = {className: classNameList}
            }
            return returnObj
        }}
        {...props}
    />);
};
