import React, { Component } from "react";
import { Row, Tooltip, OverlayTrigger } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

/**
 * 吹き出しを表示するデータを表示
 */
export default class SetDataWithSpeechBubble extends Component {

    /**
     * マウスオーバーでデータ内容が表示されるコンポーネントを作成
     * @return {component}
     */
    setDisplayData = () => {
        let iconElement = "";
        if (this.props.infoIconDisplayFlag) {
            //アイコンを設置する
            iconElement = (
                <FontAwesomeIcon
                    className={this.props.infoIconClassName}
                    icon={this.props.infoIconType}
                    color={this.props.infoIconColor} size={this.props.infoIconSize}
                />
            );
        }

        let displayDataAndIcon = "";
        if (this.props.infoIconDisplayPosition && this.props.infoIconDisplayPosition === "left") {
            displayDataAndIcon = (
                <span>
                    {iconElement}
                    {this.props.displayData}
                </span>
            );
        } else {
            displayDataAndIcon = (
                <span>
                    {this.props.displayData}
                    {iconElement}
                </span>
            );
        }

        return (
            <OverlayTrigger
                placement={this.props.placement ? this.props.placement : "bottom"}
                overlay={
                    <Tooltip id="tooltip" className={this.props.custom}>
                        {this.props.speechBubbleData || this.props.displayData}
                    </Tooltip>
                }
                delayShow={300}
            >
                {displayDataAndIcon}
            </OverlayTrigger>
        );
    }

    render() {
        return (
            <div>
                {this.setDisplayData()}
            </div>

        );
    }
}
