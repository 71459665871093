import AscMenu from "./Menu";

export default class Start extends AscMenu {
    constructor(props) {
        super(props);
    }

    getOptionElem() {
        return [];
    }
}