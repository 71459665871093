import React, { Component } from "react";
import Select from "react-select";

export default class CallTagSelect extends Component {
    handleChange = (e) => {
        const {
            onChange,
            param,
            compareFlag
        } = this.props;

        if (compareFlag) onChange(e, param, compareFlag);
        else onChange(e, param);
    }

    render() {
        const {
            options,
            value,
            langText
        } = this.props;

        const customStyles = {
            multiValue: (styles, { data }) => {
                return {
                    ...styles,
                    backgroundColor: data.rgb,
                };
            },
            multiValueLabel: (styles, { data }) => {
                let returnObj = {
                    ...styles,
                    color: data.font_rgb,
                }

                if (typeof data.label === "object") {
                    returnObj = {
                        ...returnObj,
                        paddingLeft: 2,
                        paddingRight: 0
                    }
                }

                return returnObj;
            },
            option: (styles, { data }) => {
                return {
                    ...styles,
                    color: data.font_rgb
                }
            }
        };

        return (
            <Select
                isMulti={true}
                value={value}
                onChange={e => this.handleChange(e)}
                options={options}
                styles={customStyles}
                placeholder={langText.SelectOption.Placeholder}
            />
        );
    }
}
