import React from "react";
import {Button, Row, Col, FormControl, Glyphicon, Table} from "react-bootstrap";
import Select from "react-select";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import InlineForm from "../Elements/FromGroup/InlineForm";
import { HorizontalBar } from 'react-chartjs-2';
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import * as GlobalConst from '../../components/AscConstants';
import fscreen from 'fscreen';
import BlockUi from 'react-block-ui';


const chartOptions = {
    tooltips:{
        enabled:false
    },
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
        labels: {
            render: 'percentage',
        }
    },
    legend: {
        display: false, // titleが見えないようにする
        labels: {
            fontSize: 0
        }
    },
    scales: {
        //barChartのｘ軸
        xAxes: [
            {
                gridLines: {
                    display:false,
                },
                stacked: true,
                ticks: {
                    display:false,
                    min: 0,
                    stepSize: 10
                }
            }
        ],
        //barChartのｙ軸
        yAxes: [
            {
                gridLines: {
                    display:false,
                },
                stacked: true
            },
        ],
    },
};
export default class SeatView extends Component {
    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);
        this.state = {
            // table item
            // columns: this.getColumnsData(),
            data: [],
            filtered: 
            this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) ?
            [
                {
                    id: "cm13_departments.cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg
                    }
                }
            ]:
            [],
            pages: null,
            loading: false,
            autoUpdateInterval: 1,
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],
            company_id: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)? this.props.userInfo.company_id : 0,
            cm13_id: [],
            floor_id: [],
            // CRTM用のため
            floor_flg: true,
            board_floor_flg: true,

            fullscreenEnabled: fscreen.fullscreenEnabled,
            inFullscreen: false,

            // サマリー
            operationSecAll: 0,
            connectInSecAll: 0,
            connectOutSecAll: 0,
            connectSecAll: 0,
            inboundConnectionCnt: 0,
            outboundConnectionCnt: 0,
            inboundCnt: 0,
            outboundCnt: 0,

            blocking: true,
            disableFlg: false,
        }

        // 会社選択肢セット
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            this.getCommonCompanySelect(this.reactContainerPath.split("/")[1])
                .then(res => {
                    let board_floor_flg = this.props.userInfo.floor_flg;
                    this.setState({
                        companySelect: res.data,
                        floor_flg: board_floor_flg,
                        board_floor_flg
                    });
                    this.getDepartmentSelect({company_id: this.state.company_id, floor_flg: this.props.userInfo.floor_flg});
                }).catch(err => this.showErrorObjectMesssage(err));
        }
    }

    getBarData(call, connect) {
        let datasets =[];
        let labels = [];
        if (call === 0 && connect === 0) {
            labels.push("0%");
            datasets.push({
                backgroundColor: ['rgba(180, 220, 80, 0.4)',],
                data: [100]
            });
        } else if (call === connect) {
            labels.push("100%");
            datasets.push({
                backgroundColor: ['rgba(180, 220, 80, 1)'],
                label: '',
                data: [100]
            });
        } else {
            let per = Math.floor(connect / call * 100);
            labels.push(String(per) + "%");
            datasets.push({
                backgroundColor: ['rgba(180, 220, 80, 1)'],
                label: '',
                data: [per]
            });
            datasets.push({
                backgroundColor: ['rgba(180, 220, 80, 0.4)',],
                data: [100 - per]
            });
        }
        return {
            labels,
            datasets
        };
    }

    getColumnsData() {
        let itemArr = [];
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm13_departments.cm12_companies.company_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.cm12_companies.company_name")} />
            });
        }
        if ((this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) && this.state.board_floor_flg)
            || (!this.state.board_floor_flg && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))) {
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: "cm13_departments.department_name",
                Cell: data => {
                    let rowData = null;
                    rowData= data.row._original.cm13_departments? data.row._original.cm13_departments.department_name:null
                    return (<div>{rowData}</div>)
                },
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")} />
            });
        }

        if (this.state.board_floor_flg) {
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: "cm13_departments.floor_name",
                Cell: data => {
                    let rowData = data.row._original.cm13_departments.floor_name
                    return (<div>{rowData}</div>)
                },
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.floor_name")} />
            });
        }

        itemArr.push({
            Header: this.props.langText.Body.ExtensionNumber,
            accessor: "extension_number",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "extension_number")} />,
            Cell: data => {
                let rowData = data.value;
                let memo = data.row._original.memo;
                rowData = memo ? rowData + "(" + memo + ")" : rowData;
                return (<div>{rowData}</div>)
            },
        });

        itemArr.push({
            Header: this.props.langText.Body.OperationTime,
            accessor: "operation_sec_total",
            sortable: false,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "operation_sec_total")} />,
            Cell: data => {
                return data.value? this.secToTime(data.value): "00:00:00"
            }
        });
        if(this.state.inFullscreen){
            itemArr.push({
                Header: this.props.langText.Body.TotalCallTimeAndCallRate,
                accessor: "connect_sec_total",
                sortable: false,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "connect_sec_total")} />,
                Cell: data => {
                    return data.value? this.secToTime(data.value): "00:00:00"
                }

            });

            itemArr.push({
                Header: this.props.langText.Body.TotalWaitingTime,
                accessor: "wait_sec_total",
                sortable: false,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "am12_seatview_infos.inout_type")} />,
                Cell: data => {
                    return data.value? this.secToTime(data.value): "00:00:00"
                }
            });

            itemArr.push({
                Header: this.props.langText.Body.OutboundCnt,
                accessor: "out_count_total",
                sortable: false,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "am12_seatview_infos.inout_type")} />,
                Cell: data => {
                    return data.value? this.numberWithCommas(data.value): 0
                }
            });

            itemArr.push({
                Header: this.props.langText.Body.InboundCnt,
                accessor: "in_count_total",
                sortable: false,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "am12_seatview_infos.inout_type")} />,
                Cell: data => {
                    return ( data.value || data.value===0 )? this.numberWithCommas(data.value): 0
                }
            });
        }


        itemArr.push({
            Header: this.props.langText.Body.InOutType,
            accessor: "am12_seatview_infos.inout_type",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "am12_seatview_infos.inout_type")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.ExternalNumber,
            accessor: "am12_seatview_infos.display_number",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "am12_seatview_infos.display_number")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.DestUserNumber,
            accessor: "am12_seatview_infos.dest_tel_no",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "am12_seatview_infos.dest_tel_no")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.RingTime,
            accessor: "calling_sec",
            sortable: false,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "calling_sec")} />,
            Cell: data => {
                return data.value? this.secToTime(data.value): "00:00:00"
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.WaitingTime,
            accessor: "waiting_sec",
            sortable: false,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "waiting_sec")} />,
            Cell: data => {
                return data.value? this.secToTime(data.value): "00:00:00"
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.Status,
            accessor: "am12_seatview_infos.status",
            defaultSortDesc: false,
            width:this.props.boardWidth.smedium,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "status")} />,
            Cell: data => {
                let status = this.getStatus(data.value);
                return status;
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.TalkingTime,
            accessor: "connect_sec",
            sortable: false,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "connect_sec")} />,
            Cell: data => {
                return data.value? this.secToTime(data.value): "00:00:00"
            }
        });

        return itemArr;
    }

    printSecToTime = (secs) => {
        let hour = Math.floor((secs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes = Math.floor((secs % (1000 * 60 * 60)) / (1000 * 60)),
            sec = Math.floor((secs %  (1000 * 60)) / 1000);

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    secToTimePannel = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}${this.props.langText.Body.Time}${minutes.toString().padStart(2, "0")}${this.props.langText.Body.Minute}`;
    }

    //am12のステータス表示制御
    getStatus = (status = null) => {
        let {
            StatusAnswered,
            StatusEnd,
            StatusCalling,
            StatusWisper,
            StatusMonitor,
            StatusNull,
        } = this.props.langText.Body;

        let statusListObj = {
            answered: {label: StatusAnswered, class: "status-answered"},
            calling: {label: StatusCalling, class: "status-calling"},
            end: {label: StatusEnd, class: "status-end"},
            wisper: {label: StatusWisper, class: "status-wisper"},
            monitor: {label: StatusMonitor, class: "status-monitor"},
            etc: {label: StatusNull, class: "status-null"},
        };

        let statusData = status ? statusListObj[status] : statusListObj.etc;

        return [
            <div className={statusData.class + " status-common"} key = {0}>
                {statusData.label}
            </div>
        ];
    }

    getCompanySelect = () => {
        this.ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath.split("/")[1]})
            .then(result => {
                this.setState({
                    companySelect: result.data
                });
            }).catch(err => {
            this.showErrorObjectMesssage(err.response.data);
        });
    };

    getDepartmentSelect = ({company_id, floor_flg}) => {
        this.ascAxios('post', `Common/departmentSelect`, {
            container: this.reactContainerPath.split("/")[1],
            company_id,
            floor_flg
        }).then(res => {
            this.setState({departmentSelect: res.data});
        }).catch(err => {
            this.showErrorObjectMesssage(err.response.data);
        });
    };

    getFloorSelect = (departmentIds) => {
        this.ascAxios('post', `Common/getFloorsByDepartmentIds`, {
            container: this.reactContainerPath.split("/")[1],
            departmentIds
        }).then(res => {
            let {floor_id} = this.state;
            let floor_id_arr = [];
            for (let i = 0; i < res.data.length; i++) {
                for (let j = 0; j < floor_id.length; j++) {
                    if(res.data[i].value == floor_id[j]){
                        floor_id_arr.push(floor_id[j])
                    }
                }
            }
            this.setState({floorSelect: res.data, floor_id: floor_id_arr});
        }).catch(err => {
            this.showErrorObjectMesssage(err.response.data);
        });
    };

    getFilterData(param) {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    onChange = (event, param) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";
        let company = this.state.filtered.find(row => row.id === "cm13_departments.cm12_id");

        // セレクトボックス用。event無い場合はvalue空のまま
        if(event) {
            value = event.target ? event.target.value : event;
        }

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value,
                department_prefix: event.department_prefix || "",
                company_id: company && company.value && company.value.value ? company.value.value : this.state.company_id,
                floor_flg: event.floor_flg ? event.floor_flg === "Y" : this.state.floor_flg
            });
        }
        switch (param) {
            case "cm13_departments.cm12_id":
                if (event && event.value) {
                    this.setState({
                        cm13_id: [],
                        floor_flg: event.floor_flg === "Y",
                        company_id: event.value.value
                    });
                    this.getDepartmentSelect({company_id: event.value, floor_flg: event.floor_flg === "Y"});
                    let tmpArr = my_filtered.filter(row => row.id === "cm13_departments.cm12_id");
                    my_filtered = tmpArr;
                } else {
                    // クリアした時
                    this.setState({
                        departmentSelect: [],
                        floorSelect: [],
                        cm13_id: [],
                        floor_id: []
                    })
                    my_filtered = [];
                }
                break;
            default:
                break;
        }

        let returnValue = [];
        returnValue = my_filtered;

        this.setState({filtered: returnValue});
    }

    //複数選択用のChange関数
    onMultiSelectChange = (event, param) => {
        super.onMultiSelectChange(event, param);
        
        let value_arr = [];
        let prefix_arr = [];
        
        event.forEach((row, key) => {
            value_arr[key] = row.value;
            if(this.state.floor_flg && param === "cm13_id"){
                prefix_arr[key] = row.department_prefix;
            }
        });

        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param);

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (event && (value_arr || prefix_arr)) {
            my_filtered.push({
                id: param,
                value: value_arr,
                department_prefix: prefix_arr,
                company_id: this.state.company_id,
                floor_flg: this.state.floor_flg
            });
        }

        switch (param) {
            case "cm13_id":
                if (prefix_arr.length) {
                    this.getFloorSelect(value_arr);
                    this.setState({cm13_id: value_arr});
                    let tmpArr = my_filtered.filter(row => row.id !== "floor_id");
                    my_filtered = tmpArr;
                } else {
                    if (value_arr.length) {
                        this.setState({
                            cm13_id: value_arr,
                            floorSelect: []
                        });
                        let tmpArr = my_filtered.filter(row => row.id !== "floor_id");
                        my_filtered = tmpArr;
                    } else {
                        this.setState({
                            // クリア時
                            floor_id: [],
                            floorSelect: []
                        });
                        let tmpArr = my_filtered.filter(row => row.id === "cm13_departments.cm12_id");
                        my_filtered = tmpArr;
                    }
                }
                break;
            case "floor_id":
                if (value_arr.length) {
                    this.setState({floor_id: value_arr});
                }
                break;
            default:
                break;
        }

        let returnValue = [];
        returnValue = my_filtered;
        this.setState({filtered: returnValue});
    }

    onSelectChange = (event, param) => {
        super.onSelectChange(event, param);
        if(param == "autoUpdateInterval"){
            clearInterval(this.state.interval);
            this.setState({disableFlg: false});
        }
    }

    onIntervalChange = (e) => {
        let {autoUpdateInterval} = this.state;
        let stateInterval;
        if(autoUpdateInterval == 1){
            //自動更新無しが選択された場合は自動更新を止める
            this.onSearch();
            return clearInterval(this.state.interval);
        }else if(autoUpdateInterval != 1 && e){
            //自動更新間隔が選択されたら一回画面更新後、自動更新
            clearInterval(this.state.interval);
            this.onSearch();
            stateInterval = setInterval(() => {
                this.onSearch();
            }, autoUpdateInterval);
            this.setState({interval : stateInterval, disableFlg: true});
        }
    }

    onSearch = (event) => {
        let filter =[];
        this.state.filtered.forEach((row, index) => {
            filter.push(row);
        })

        // 検索ボタンを押した後にフロア名カラム表示を制御する
        this.setState({ board_floor_flg: this.state.floor_flg });
        this.toggleBlocking();
        //検索時にpageを1ページする
        this.table.current.state.page = 0;
        this.table.current.state.filtered = filter;
        this.fetchData(this.table.current.state, this.table.current.instance);
    }

    //自動更新Select
    getIntervalSelectItem = () => {
        return(
            <Col className = "seatview-form interval-select" sm={12} md={12} lg={12}>
                <Col sm={9} md={9} lg={9}>
                    <InlineForm
                        type = "select"
                        options = {this.props.langText.SelectOption.autoUpdateIntervalList}
                        value = {this.state.autoUpdateInterval}
                        onChange = {e => this.onSelectChange(e, "autoUpdateInterval")}
                    />
                </Col>
                <Col sm={1} md={1} lg={1}>
                    <Button
                        className = "auto-update-btn"
                        bsStyle = "primary"
                        bsSize = "sm"
                        onClick = {e => this.onIntervalChange(e)}
                        disabled = {this.state.disableFlg}
                    >
                        <Glyphicon glyph="refresh"/>
                    </Button>
                </Col>
            </Col>
        )
    }


    //全体画面モード
    handleFsChange = (e) => {
        if (fscreen.fullscreenElement !== null) {
            this.setState({ inFullscreen: true });
        } else {
            this.setState({ inFullscreen: false });
        }
    }

    handleFsError = (e) => {
        this.showErrorObjectMesssage(e, 'DataSelectError');
    }

    toggleFullscreen = () => {
        if (this.state.inFullscreen) {
            fscreen.exitFullscreen();
        } else {
            fscreen.requestFullscreen(this.appElement);
        }
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    componentDidMount() {
        let {floor_flg, company_id, parent_department_id} = this.props.userInfo;
        if(this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)){
            this.getDepartmentSelect({company_id: company_id, floor_flg});
            this.setState({
                floor_flg,
                board_floor_flg: floor_flg,
                company_id: this.props.userInfo.company_id
            });
            if(floor_flg && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)){
                this.getFloorSelect(parent_department_id)
            }
        }
        if (this.state.fullscreenEnabled) {
            fscreen.addEventListener('fullscreenchange', this.handleFsChange, false);
            fscreen.addEventListener('fullscreenerror', this.handleFsError, false);
        }
    }

    componentWillUnmount() {
        this._ismounted = true;
        fscreen.removeEventListener('fullscreenchange', this.handleFsChange);
        fscreen.removeEventListener('fullscreenerror', this.handleFsError);
        clearInterval(this.state.interval);
        this._ismounted = false;
    }

    handleRef = (element) => {
        this.appElement = element;
    }

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/CallMonitoring", name: this.props.langText.Body.CallMonitoring },
                        { name: this.props.langText.Body.SeatView },
                    ]}
                />
                <Row>
                    <Col xs={12} md={12}>
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) &&
                        <Col className = "seatview-form" sm={12} md={12} lg={12}>
                            <Col sm={4} md={4} lg={4}>
                                <div className="margin-top-05">{this.props.langText.Body.CompanyName}</div>
                            </Col>
                            <Col sm={8} md={8} lg={8}>
                                <InlineForm
                                    type = "select"
                                    value = {this.state.company_id}
                                    onChange = { e => this.onChange(e, "cm13_departments.cm12_id") }
                                    options={this.state.companySelect}
                                    placeholder = {!this.state.companySelect[0] && this.state.company_id
                                        ? <Glyphicon glyph="refresh" className="spinning"/>
                                        : this.props.langText.SelectOption.Placeholder}
                                />
                            </Col>
                        </Col>
                        }
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) &&
                        <Col className="seatview-form" sm={12} md={12} lg={12}>
                            <Col sm={4} md={4} lg={4}>
                                <div className="margin-top-05">{this.props.langText.Body.DepartmentName}</div>
                            </Col>
                            <Col sm={8} md={8} lg={8}>
                                <InlineForm
                                    id = "department-select"
                                    type = "select"
                                    value = {this.state.cm13_id}
                                    onChange={e => this.onMultiSelectChange(e, "cm13_id")}
                                    options={this.state.departmentSelect}
                                    isMulti = {true}
                                    isClearable = {false}
                                    closeMenuOnSelect={false}
                                    placeholder={!this.state.departmentSelect[0]
                                        ? <Glyphicon glyph="refresh" className="spinning"/>
                                        : this.props.langText.SelectOption.Placeholder}
                                />
                            </Col>
                        </Col>
                        }
                        {(this.state.floor_flg && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) &&
                        <Col className = "seatview-form" sm={12} md={12} lg={12}>
                            <Col sm={4} md={4} lg={4}>
                                <div className="margin-top-05">{this.props.langText.Body.FloorName}</div>
                            </Col>
                            <Col sm={8} md={8} lg={8}>
                                <InlineForm
                                    type = "select"
                                    value = {this.state.floor_id}
                                    onChange = {e => this.onMultiSelectChange(e, "floor_id")}
                                    options={this.state.floorSelect}
                                    isMulti = {true}
                                    isClearable = {false}
                                    closeMenuOnSelect={false}
                                    placeholder = {!this.state.floorSelect[0]
                                        ? <Glyphicon glyph="refresh" className="spinning"/>
                                        :this.props.langText.SelectOption.Placeholder}
                                />
                            </Col>
                        </Col>
                        }
                        <Col className = "seatview-form" sm={12} md={12} lg={12}>
                            <Col sm={4} md={4} lg={4}>
                                <div className="margin-top-05">{this.props.langText.Body.ExtensionNumber}</div>
                            </Col>
                            <Col sm={6} md={6} lg={6}>
                                <FormControl
                                    value = {this.getFilterData("extension_number")}
                                    onChange = {
                                        e => this.onChange(e, "extension_number")
                                    }
                                />
                            </Col>
                            <Col  sm={1} md={1} lg={1}>
                                <Button
                                    className="seatview-search"
                                    bsStyle="primary"
                                    bsSize="sm"
                                    onClick={e=>this.onSearch(e)}
                                >
                                    {this.props.langText.Body.Search}
                                </Button>
                            </Col>
                        </Col>

                        {!this.state.floor_flg &&
                            this.getIntervalSelectItem()
                        }

                    </Col>
                </Row>
                {this.state.floor_flg &&
                    this.getIntervalSelectItem()
                }

                {/* サマリー */}
                <div ref={this.handleRef} className = {this.state.inFullscreen ? "full-screen-mode" : ""}>
                    <Col xs={12} md={12} className="margin-top-05 seatview-table-col">
                        <BlockUi tag="div" className="z-index-0" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                            <Table className = "seatview-table">
                                <tbody>
                                <tr>
                                    <td rowSpan = "3" className="seatview-width-1" >
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalOperationTime}
                                        </div>
                                        <div className="seatview-panel-time">
                                            {this.state.operationSecAll}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalOutboundTime}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.state.connectOutSecAll}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalOutboundCnt}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.numberWithCommas(this.state.outboundCnt)}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalOutboundConnectionCnt}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.numberWithCommas(this.state.outboundConnectionCnt)}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.OutboundConnectionRate}
                                        </div>
                                        <HorizontalBar
                                            data={this.getBarData(this.state.outboundCnt, this.state.outboundConnectionCnt)}
                                            options={chartOptions} width={18} height={2}/>
                                    </td>
                                    <td rowSpan = "3" className="seatView-width-2">
                                        <Button
                                            className = "seatview-btn"
                                            onClick = {this.toggleFullscreen}
                                        >
                                            {this.state.inFullscreen?
                                                <Glyphicon glyph="resize-small"/>
                                                :
                                                <Glyphicon glyph="fullscreen"/>
                                            }

                                        </Button>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalInboundTime}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.state.connectInSecAll}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalInboundCnt}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.numberWithCommas(this.state.inboundCnt)}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalInboundConnectionCnt}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.numberWithCommas(this.state.inboundConnectionCnt)}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.InboundConnectionRate}
                                        </div>
                                        <HorizontalBar
                                            data={this.getBarData(this.state.inboundCnt, this.state.inboundConnectionCnt)}
                                            options={chartOptions} width={18} height={2}/>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalCallTime}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.state.connectSecAll}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.numberWithCommas(this.props.langText.Body.TotalCallCnt)}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.numberWithCommas(this.state.inboundCnt + this.state.outboundCnt)}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.TotalCallConnectionCnt}
                                        </div>
                                        <div className="seatview-panel-data">
                                            {this.numberWithCommas(this.state.inboundConnectionCnt + this.state.outboundConnectionCnt)}
                                        </div>
                                    </td>
                                    <td className="seatview-width-1">
                                        <div className="seatview-panel-text">
                                            {this.props.langText.Body.CallConnectionRate}
                                        </div>
                                        <HorizontalBar
                                            data={this.getBarData(this.state.inboundCnt + this.state.outboundCnt,
                                                this.state.inboundConnectionCnt + this.state.outboundConnectionCnt)}
                                            options={chartOptions} width={18} height={2}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>

                        </BlockUi>
                    </Col>


                    <Col xs={12} md={12}>
                        <CommonTable
                            filtered={this.state.filtered}
                            talbeRef={this.table}
                            style={{height: this.state.inFullscreen? this.props.tableHeight - 140 : this.props.tableHeight - 240}}
                            manual
                            columns = {this.getColumnsData()}
                            defaultSorted={[{ id: "am12_seatview_infos.status", desc: false }]}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {false}
                            onFetchData={this.fetchData}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </div>
            </React.Fragment>
        )
    }
}