import React, { Component } from "react";
import { Panel } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import { EditAutoCallDetailList } from "../Elements/AscElements/AutoCallListElements";

/**
 * 自動発信リスト詳細編集画面
 */
export default class AutoCallDetailListUpdateModal extends Component {
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}
            >
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            <div className="text-danger modal-checked-message">
                                {this.props.state.duplicateTelFlag
                                    ? this.props.langText.Message.DuplicateTelno
                                    : false}
                            </div>
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <EditAutoCallDetailList
                            state={this.props.state}
                            langText={this.props.langText}
                            onTextChange_Limit={this.props.onTextChange_Limit}
                            onChangeValue={this.props.onChangeValue}
                            charaLimit={this.props.charaLimit}
                            boardWidth={this.props.boardWidth}
                            onFocusOut={this.props.onFocusOut}
                        ></EditAutoCallDetailList>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
