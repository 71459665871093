import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import "../Elements/FromGroup/form_select.css";
import { ViewCompanyElement } from "../Elements/AscElements/CompanyElements";
import { DeleteAndReadKeywordGroup } from "../Elements/AscElements/KeywordGroupElements";

/**
 * キーワードグループ削除画面
 */
export default class KeywordGroupDeleteModal extends Component {
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.KeywordGroupInfo}
                        </Panel.Title>
                        {(this.props.state.using_keyword_groups.length > 0)
                            && <>
                                <div className="text-danger modal-checked-message">
                                    <p>{this.props.langText.Message.KeywordDeleteError}</p>
                                    <ul>{this.props.state.using_keyword_groups}</ul>
                                </div>
                            </>
                        }
                    </Panel.Heading>

                    <Panel.Body>
                        <ViewCompanyElement
                            state={this.props.state}
                            langText={this.props.langText}>
                        </ViewCompanyElement>
                        <DeleteAndReadKeywordGroup
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
