import React, {Component} from "react";
import {Panel, Radio, Col, Glyphicon} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import MultiSelect from "../Elements/Select/MultiSelect";
import "../Elements/FromGroup/form_select.css";
import * as GlobalConst from "../../components/AscConstants";

export default class GroupExtensionNumberModal extends Component {
    getGroupExtensionNumberInfoItem = () => {
        let {
            state,
            propSetState,
            langText,
            charaLimit,
            onTextChange_Limit,
            onKeyDownIntCheck,
            onSelectChange,
            validationHandle,
            getScopeGreaterEqual,
            onMultiSelectChange
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    placeholder = {langText.SelectOption.Placeholder}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.company_name}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push(
                <InlineForm
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.department_id}
                    onChange = {e => onSelectChange(e, "department_id")}
                    options = {state.departmentSelect}
                    placeholder = {langText.SelectOption.Placeholder}
                    isClearable = {true}
                    validationState = {validationHandle("department_id")}
                    placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.department_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}>
                    {state.department_name}
                </InlineForm.static>
            );
        }

        if (this.props.state.floor_flg) {
            if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                itemArr.push(
                    <InlineForm
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}
                        type = "select"
                        value = {state.floor_id}
                        onChange = {e => onSelectChange(e, "floor_id")}
                        options = {state.floorSelect}
                        placeholder = {langText.SelectOption.Placeholder}
                        isClearable = {true}
                        validationState = {validationHandle("floor_id")}
                        placeholder = {state.buttondisabledArr.floor_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.floor_id[0]}/>
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}>
                        {state.floor_name}
                    </InlineForm.static>
                );
            }
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.GroupExtensionNumberName}
                    type = "text"
                    value = {state.memo}
                    onChange = {e =>onTextChange_Limit(e, "memo",charaLimit.Group_extension_memo)}
                    validationState = {validationHandle("memo")}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.GroupExtensionNumberName}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        if (state.modalType == "insert") {
            itemArr.push(
                <InlineForm
                    key = "group_extension_number"
                    controlId = "group_extension_number"
                    label = {langText.Body.GroupExtensionNumber}
                    type = "text"
                    value = {state.group_extension_number}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {e =>onTextChange_Limit(e, "group_extension_number",charaLimit.Group_extension_number_num)}
                    validationState = {validationHandle("group_extension_number")}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "group_extension_number"
                    controlId = "group_extension_number"
                    label = {langText.Body.GroupExtensionNumber}>
                    {state.group_extension_number}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "group_department_id"
                    controlId = "group_department_id"
                    label = {langText.Body.GroupExtensionDepartmentName}
                    type = "select"
                    options = {this.props.state.floor_flg ? state.departmentSelect : state.groupExtensionDepartmentSelect}
                    onChange = {e => onMultiSelectChange(e, "group_department_id")}
                    value = {state.group_department_id}
                    isClearable = {true}
                    isMulti = {true}
                    closeMenuOnSelect={false}
                    placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.department_id[0] || !getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)}
                />
            );
        }

        if (this.props.state.floor_flg && (state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key="group_Floor_id"
                    controlId="group_Floor_id"
                    label={langText.Body.GroupExtensionFloorName}
                    type="select"
                    options={state.groupExtensionFloorSelect}
                    onChange={e => onMultiSelectChange(e, "group_floor_id")}
                    value = {state.group_floor_id}
                    isClearable={true}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    placeholder = {state.buttondisabledArr.group_floor_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.group_department_id[0] || !getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)}
                />
            );
        }
        itemArr.push(
            <InlineForm
                key = "extension_number"
                label = {langText.MultiSelect.ExtensionSelect}
            >
                <MultiSelect
                    key = "extension_number"
                    controlId = "extension_number"
                    propSetState = {propSetState}
                    multiSelectText = {langText.MultiSelect}
                    searchText = {langText.MultiSelect.ExtensionAndMemoSearch}
                    options = {state.extensionNumberSelect}
                    value = {state.extension_number}
                    type = {state.modalType}
                    isDisabled = {state.modalType === "delete" || state.modalType === "read" }
                    multiSelectMessage = {langText.MultiSelect.SelectDataNumStr_NDelete}
                    multiSelectDeleteMessage = {langText.MultiSelect.SelectDataNumStr_Delete}
                    />
            </InlineForm>
        );

        //メモ(説明)
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key="description"
                    controlId="description"
                    label={langText.Body.Memo}
                    componentClass = "textarea"
                    rows="3"
                    value={state.description}
                    onChange={e => onTextChange_Limit(e, "description", charaLimit.Group_extension_description)}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "description"
                    controlId = "description"
                    label = {langText.Body.Memo}>
                    {state.description}
                </InlineForm.static>
            );
        }
        
        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.GroupExtensionNumberSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getGroupExtensionNumberInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
