import React from 'react';
import { Table, ControlLabel, Panel, Button } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import styled from '@emotion/styled';

import AscMenu from "./Menu";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";

const FontAwesomeSubIcon = styled(FontAwesomeIcon)`
	color: ${(p) => p.color || "rgba(108, 117, 113)"};
    margin-top: 4px;
`;

const ItemClickUl = styled.ul`
    border-radius: 4px;
    box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
    list-style-type: none;
    padding: 3px;
    background: white;
    width: 50%;
    margin-left: 25%;
    margin-top: 8%;
`;

const ItemClickLi = styled.li`
    &:hover {
        cursor: pointer;
    }
`;

export default class Question extends AscMenu {
    constructor(props) {
        super(props);

        this.audio_select = [{value: "none", label: this.props.langText.SelectOption.AudioNonePlay}, ...this.selectOptions.audio_type];

        if (this.state.data.next_other_num_select_audio_type === "file" && !this.state.data.next_other_num_select_audio_file.length) {
            this.state.data.next_other_num_select_audio_type = this.audio_select[0].value;
        }

        if (this.state.data.timeout_audio_type === "file" && !this.state.data.timeout_audio_file.length) {
            this.state.data.timeout_audio_type = this.audio_select[0].value;
        }

        this.q_nexts = JSON.parse(JSON.stringify(this.state.data.nexts));
        this.roop_flag = this.setRoopFlag();
    }

    getPushNumber(number) {
        let push_number = [];
        this.selectOptions.push_number.forEach(row => {
            if (row.value === number || !this.state.data.push_nums.find(data => row.value === data.number)) {
                push_number.push(row);
            }
        });
        return push_number;
    }

    getPushNumsElem() {
        return (
            <>
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptAnswerNumber}
                    groupStyle={{marginTop: "10px"}}>
                    <Button
                        className="pull-right"
                        bsStyle="default"
                        bsSize="sm"
                        onClick={e => {
                            const find_number = this.selectOptions.push_number.find(row => {
                                if (this.state.data.push_nums.find(data => row.value === data.number)) {
                                    return false;
                                }
                                return true;
                            });

                            if (find_number) {
                                this.state.data.push_nums.push({ number: find_number.value, text: "", block_no: null });
                                this.setState({data: this.state.data});
                            }
                        }}>
                        {this.props.langText.Body.Add}
                    </Button>
                </ASFieldGroup>
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{this.props.langText.Body.Number}</th>
                            <th>{this.props.langText.Body.Text}</th>
                            <th>{this.props.langText.Body.Control}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.data.push_nums.map((row, index) => 
                            <tr key={index}>
                                <td width="30%">
                                    <ASSelect
                                        value={row.number}
                                        options={this.getPushNumber(row.number)}
                                        placeholder={this.placeholder}
                                        onChange={e => {
                                            let next_target = this.q_nexts.find(next => next.condition === row.number);
                                            if (next_target) {
                                                next_target.condition = e.value;
                                                this.state.data.nexts = this.q_nexts;
                                            }
                                            row.number = e.value;
                                            this.setState({data: this.state.data});
                                        }}/>
                                </td>
                                <td width="50%">
                                    <ASFieldGroup
                                        type="text"
                                        value={row.text}
                                        groupStyle={{marginBottom: 0}}
                                        onChange={e => {
                                            row.text = e.target.value;
                                            this.setState({data: this.state.data});
                                        }}/>
                                </td>
                                <td width="20%" style={{textAlign: "center"}}>
                                    <ItemClickUl>
                                        <ItemClickLi onClick={e => {
                                            if (this.state.data.push_nums.length > 1) {
                                                let target_index = this.q_nexts.findIndex(next => next.condition === this.state.data.push_nums[index].number);
                                                if (target_index !== -1) {
                                                    this.q_nexts.splice(target_index, 1);
                                                    this.state.data.nexts = this.q_nexts;
                                                }
                                                this.state.data.push_nums.splice(index, 1);
                                                this.setState({data: this.state.data});
                                            }
                                        }}>
                                            <FontAwesomeSubIcon
                                                className="fa-fw fa-lg li-item"
                                                icon={faTrashAlt}
                                                color="#606060"/>
                                        </ItemClickLi>
                                    </ItemClickUl>
                                </td>
                            </tr>    
                        )}
                    </tbody>
                </Table>
            </>
        );
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                {this.getAudioElem({
                    key: 0,
                    audio_label: this.props.langText.Body.Audio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "audio_type",
                    audio_file: "audio_file",
                    audio_file_id: "audio_file_id",
                    audio_gen_language_original: "audio_gen_language_original",
                    audio_gen_language: "audio_gen_language",
                    audio_gen_person: "audio_gen_person",
                    audio_gen_text_original: "audio_gen_text_original",
                    audio_gen_text: "audio_gen_text",
                    synthetic_type: "synthetic_type"
                })}
                {this.getPushNumsElem()}
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptRepeat}>
                    <ASSelect
                        value={this.state.data.loop_cnt}
                        options={this.selectOptions.select_number}
                        onChange={e => {
                            this.state.data.loop_cnt = e.value;
                            this.setState({data: this.state.data});
                        }}
                        isDisabled={!this.roop_flag}/>
                </ASFieldGroup>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title><ControlLabel className="label-color-333333">{this.props.langText.Body.ScriptOthersNumberSetting}</ControlLabel></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getAudioElem({
                            key: 1,
                            audio_type_select: this.audio_select,
                            audio_label: this.props.langText.Body.Audio,
                            synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                            audio_type: "next_other_num_select_audio_type",
                            audio_file: "next_other_num_select_audio_file",
                            audio_file_id: "next_other_num_select_audio_file_id",
                            audio_gen_language_original: "next_other_num_select_audio_gen_language_original",
                            audio_gen_language: "next_other_num_select_audio_gen_language",
                            audio_gen_person: "next_other_num_select_audio_gen_person",
                            audio_gen_text_original: "next_other_num_select_audio_gen_text_original",
                            audio_gen_text: "next_other_num_select_audio_gen_text",
                            synthetic_type: "next_other_num_select_synthetic_type"
                        })}
                        <ASFieldGroup
                            label={this.props.langText.Body.RunType}>
                            <ASSelect
                                value={this.state.data.next_other_num_select_event}
                                options={this.getSessions()}
                                placeholder={this.props.langText.SelectOption.EndCall}
                                isClearable={true}
                                onChange={e => {
                                    this.onAdditionalEventChange(e,"next_other_num_select_event");
                                    this.roop_flag = this.setRoopFlag();
                                }}/>
                        </ASFieldGroup>
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title><ControlLabel className="label-color-333333">{this.props.langText.Body.ScriptTimeoutSetting}</ControlLabel></Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getAudioElem({
                            key: 2,
                            audio_type_select: this.audio_select,
                            audio_label: this.props.langText.Body.Audio,
                            synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                            audio_type: "timeout_audio_type",
                            audio_file: "timeout_audio_file",
                            audio_file_id: "timeout_audio_file_id",
                            audio_gen_language_original: "timeout_audio_gen_language_original",
                            audio_gen_language: "timeout_audio_gen_language",
                            audio_gen_person: "timeout_audio_gen_person",
                            audio_gen_text_original: "timeout_audio_gen_text_original",
                            audio_gen_text: "timeout_audio_gen_text",
                            synthetic_type: "timeout_synthetic_type"
                        })}
                        <ASFieldGroup
                            label={this.props.langText.Body.RunType}>
                            <ASSelect
                                value={this.state.data.timeout_event}
                                options={this.getSessions()}
                                placeholder={this.props.langText.SelectOption.EndCall}
                                isClearable={true}
                                onChange={e => {
                                    this.onAdditionalEventChange(e,"timeout_event");
                                    this.roop_flag = this.setRoopFlag();
                                }}/>
                        </ASFieldGroup>
                        <ASFieldGroup
                            type="number"
                            label={this.props.langText.Body.Second}
                            value={this.state.data.timeout_seconds}
                            onChange={e => {
                                let num_value = parseInt(e.target.value);
                                if (isNaN(num_value)) {
                                    num_value = 1;
                                }
                                this.state.data.timeout_seconds = num_value;
                                this.setState({data: this.state.data});
                            }}
                            min="1"/>
                    </Panel.Body>
                </Panel>
            </>
        );
    }

    getNextSession() {
        if (this.state.data.push_nums && this.state.data.push_nums.length) {
            let push_elem = [];

            this.state.data.push_nums.forEach((row, index) => {
                const condition = row.number;
                const target = this.q_nexts.find(next => next.condition === condition);
                const selected_flag = target && target.block_no === this.props.linkNumber;
                push_elem.push(
                    <tr key={index} style={{backgroundColor: selected_flag ? "#fff5d7" : null, borderBottom: selected_flag ? "1pt solid black" : null}}>
                        <td>{condition}</td>
                        <td>
                            {this.getSessionSelect(condition)}
                        </td>
                    </tr>
                );
            });

            return (
                <Table striped bordered condensed hover>
                    <thead>
                        <tr>
                            <th>{this.props.langText.Body.Number}</th>
                            <th>{this.props.langText.Body.ScriptNextSession}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {push_elem}
                    </tbody>
                </Table>
            );
        }
        return null;
    }

    getLinkElem() {
        return (
            <>
                {this.getNextSession()}
            </>
        );
    }

    setRoopFlag() {
        const roop_flag = !this.selectOptions.script_additional_event.find(row =>
            this.state.data[row.value] === this.selectOptions.next_session_event[0].value)
            ? false : true;
        if (!roop_flag) {
            this.state.data.loop_cnt = this.selectOptions.select_number[0].value;
        }
        this.setState({data: this.state.data});
        return roop_flag;
    }
}