import React, { Component } from "react";
import AscComponent from "../../../../components/AscComponent";
import { Row, Col, FormControl, ControlLabel, Panel, Radio, Button, Modal, Form, Glyphicon} from "react-bootstrap";
import styled from "@emotion/styled";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";
import BlockUi from 'react-block-ui';
import * as GlobalConst from "../../../../components/AscConstants";
import AscAudio from "../../../Elements/Audio/Audio";


const MenuRow = styled(Row)`
    width: 100%;
    margin: 0;
    label {
        margin-bottom: 0;
    }
`;
const TitleCol = styled(Col)`
    padding: 10px;
`;
const ContentCol = styled(Col)`
    padding: 10px;
    margin-bottom: 25px;
`;
const BottomCol = styled(Col)`
    width: ${(p) => p.width || 0};
    background: #eef3f9;
    position: fixed;
    bottom: 19px;
    right: 15px;
    padding: 10px;
    border-bottom-left-radius: 4px;
    transition: 0.5s;
`;
export default class ASMenu extends AscComponent  {
    constructor(props) {
        super(props);

        this.model = this.props.model;
        this.number = this.model.getOptions().number;
        this.validation = this.model.getOptions().validation;

        this.ascAxios = this.props.ascAxios;
        this.getErrorString = this.props.getErrorString;
        this.selectOptions = this.props.selectOptions;
        this.showErrorObjectMesssage = this.props.showErrorObjectMesssage;

        this.placeholder = this.props.langText.SelectOption.Placeholder;

        this.state = {
            data: this.mData(),
            screen_data: {
                audio_custom_column: [
                    this.selectOptions.audio_custom_column[0].value,
                    this.selectOptions.audio_custom_column[0].value,
                    this.selectOptions.audio_custom_column[0].value,
                    this.selectOptions.audio_custom_column[0].value
                ]
            },
            footter_width: 0,
            blocking: false,

            audio_select: null,
            synthetic_type_select: [],
            translate_select_list: this.selectOptions.language_code_list.map(row => {
                let obj = {};
                obj['label'] = row["label"];
                obj['value'] = row["code_translate"];
                return obj;
            }),
            polly_select_list: this.selectOptions.language_code_list.map(row => {
                let obj = {};
                obj['label'] = row["label"];
                obj['value'] = row["code_polly"];
                return obj;
            }),
            language_select_before: [],
            language_select_after: [],
            all_voice_list: [],
            voice_select: [],

            pre_audio_gen_text: null,
            pre_audio_gen_person: null,

            voice_data_url: "",
            play: false,
            modal_title : ""
        }
    }

    // 会社の翻訳権限取得
    async getTranslatePermission(cm12_id) {
        try {
            const result = await this.ascAxios(
                "post", `Script/getTranslatePermission`, {
                cm12_id
            });

            if (result) {
                let permission_flag = result.data;

                this.setState({
                    synthetic_type_select: permission_flag
                    ? this.selectOptions.script_audio_synthetic_type
                    : this.selectOptions.script_audio_synthetic_type.filter(row => row.value !== "translate")
                });
            }

        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    // 音声分類リスト取得
    async setPollyVoice() {
        try {
            const result = await this.ascAxios(
                "post", `Script/getLanguageVoiceList`
            );

            const voices = result.data.Voices.map(row => {
                let obj = {};

                const gender = row["Gender"] === "Male"
                    ? this.props.langText.Body.Male
                    : this.props.langText.Body.Female;

                obj['label'] = row["Id"] + "(" +gender+")";
                obj['value'] = row["Id"];
                obj['code'] = row["LanguageCode"];

                return obj;
            });

            this.setState({
                all_voice_list: voices
            });

            return voices;

        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    componentDidMount() {
        this.setState({footter_width: `${parseInt(this.props.width) - 15}px`});
        this.setSelectOption("audio_file_id");
        this.setSelectOption("audio_gen_person");
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    mData() {
        let data = JSON.parse(JSON.stringify(this.model.c_data));
        for (let key in this.model.c_data) {
            if (key.endsWith("audio_file")) data[key] = this.model.c_data[key];
        }
        return data;
    }

    // 翻訳用言語と合成音声再生用言語のコード変換
    getOtherlanguageCode(value, value_code) {
        let result = "";

        if (value_code === "translate") {
            result = this.selectOptions.language_code_list.find(row =>
                row.code_translate === value
            ).code_polly;
        } else if (value_code === "polly") {
            result = this.selectOptions.language_code_list.find(row =>
                row.code_polly === value
            ).code_translate;
        } else {
            return;
        }

        return result;
    }

    setSelectOption(params) {
        let params_keys = Object.keys(this.state.data).filter(row => row.includes(params));
        let setting_flag = params_keys.find(row => this.state.data[row]) ? true : false;

        switch(params) {
            case "audio_file_id":
                if (setting_flag) {
                    this.getAudioSelect();
                }
                break;
            case "audio_gen_person":
                if (setting_flag) {
                    this.setPollyVoice();
                    this.getTranslatePermission(this.props.company_id);
                }
                break;
            default:
                break;
        }
    }

    onRadioChange(key_obj, param) {
        let {
            synthetic_type,
            audio_gen_language_original,
            audio_gen_language,
            audio_gen_person,
            audio_gen_text_original,
            audio_gen_text,
            audio_file,
            audio_file_id
        } = key_obj;

        let data = this.state.data;

        switch (param) {
            case "audio_type_select":
                if (!this.state.audio_select) {
                    this.getAudioSelect();
                }
                break;
            case "audio_type_synthetic":
                if (!this.state.all_voice_list.length) {
                    this.getTranslatePermission(this.props.company_id);
                    this.setPollyVoice();
                }
                break;
            default:
                break;
        }

        data[synthetic_type] = "nomal";
        data[audio_file] = [];
        data[audio_file_id] = null;
        data[audio_gen_language_original] = null;
        data[audio_gen_text_original] = "";
        data[audio_gen_language] = null;
        data[audio_gen_text] = "";
        data[audio_gen_person] = null;
        this.setState({data});
    }

    onCheckBoxChange() {
        let data = this.state.data;
        data["input_check_flag"] = !data["input_check_flag"];
        if( !data["input_check_flag"] ){
            // チェックオフの場合、全項目を初期状態に戻す
            data["input_check_audio_type"] = "file";
            data["input_check_audio_file"] = [];
            data["input_check_audio_file_id"] = null;
            data["input_check_synthetic_type"] = "nomal";
            data["input_check_audio_gen_language"] = null;
            data["input_check_audio_gen_language_original"] = null;
            data["input_check_audio_gen_person"] = null;
            data["input_check_audio_gen_text"] = "";
            data["input_check_audio_gen_text_original"] = "";
            data["input_check_decision_number"] = 0;
        }

        this.setState(data);
    }

    onChange(key_obj, param) {
        let {
            synthetic_type,
            audio_gen_language_original,
            audio_gen_language,
            audio_gen_person,
            audio_gen_text_original,
            audio_gen_text
        } = key_obj;

        let data = this.state.data;

        switch (param) {
            case "synthetic_type":
                if(data[synthetic_type] === "translate") {
                    this.setState({
                        language_select_before: this.state.translate_select_list.filter(row => row.value !== "en"),
                        language_select_after: this.state.polly_select_list,
                        voice_select: this.state.all_voice_list.filter(row => row.code === "en-US")
                    });
                } else {
                    this.setState({
                        voice_select: []
                    });
                }
                data[audio_gen_language_original] = null;
                data[audio_gen_text_original] = "";
                data[audio_gen_language] = data[synthetic_type] === "translate" ? "en-US" : null;
                data[audio_gen_text] = "";
                data[audio_gen_person] = null;
                this.setState({data});
                break;
            case "audio_gen_language_original":
                data[audio_gen_text_original] = "";
                this.setState({
                    data,
                    language_select_after: this.state.polly_select_list.filter(row =>
                        this.getOtherlanguageCode(
                            this.state.data[audio_gen_language_original],"translate") !== row.value)
                    });
                break;
            case "audio_gen_text_original":
                data[audio_gen_language] = null;
                data[audio_gen_text] = "";
                data[audio_gen_person] = null;
                this.setState({data});
                break;
            case "audio_gen_language":
                data[audio_gen_text] = "";
                data[audio_gen_person] = null;
                this.setState({
                    data,
                    language_select_before: this.state.translate_select_list.filter(row =>
                        this.getOtherlanguageCode(this.state.data[audio_gen_language],"polly") !== row.value)
                });
                break;
            case "audio_gen_person":
                this.setState({
                    modal_title : data[audio_gen_language] + " " + data[audio_gen_person]
                });
                break;
            default:
                break;
        }
    }

    getAudioSelect() {
        this
            .ascAxios(
                "post",
                "Common/voiceFileSelectAllType",
                {company_id: this.props.company_id}
            )
            .then(result => {
                this.setState({audio_select: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    // TITLE「ELEM」取得
    getTitleElem() {
        return (
            <ASFieldGroup
                type="text"
                label={this.props.langText.Body.Title}
                value={this.state.data.title}
                onChange={e => this.setState({data: Object.assign(this.state.data, {title: e.target.value})})}
                validationState={this.validation.menu.title(this.state.data)}/>
        );
    }

    // AUDIO「ELEM」取得
    getAudioElem({
        key,
        validation_type="menu",
        audio_type_select=this.selectOptions.audio_type,
        audio_label, //音声LABEL
        synthetic_label, //音声合成LABEL
        audio_type,
        audio_file,
        audio_file_id,
        audio_gen_language_original,
        audio_gen_language,
        audio_gen_person,
        audio_gen_text_original,
        audio_gen_text,
        synthetic_type
    }) {
        const param_obj = {
            audio_gen_language_original,
            audio_gen_language,
            audio_gen_person,
            audio_gen_text_original,
            audio_gen_text,
            synthetic_type,
            audio_file,
            audio_file_id
        };
        return (
            <>
                <ASFieldGroup
                    label={audio_label}>
                    {audio_type_select.map((row, index) => 
                        <Radio
                            key={index}
                            name={`AudioType-${key}`}
                            value={row.value}
                            checked={this.state.data[audio_type] === row.value}
                            onChange={e => {
                                this.onRadioChange(param_obj, "audio_type_"+`${e.target.value}`);
                                this.state.data[audio_type] = row.value;
                                this.setState({data: this.state.data});
                            }}
                            style={{marginTop: 0}}>
                            {row.label}
                        </Radio>
                    )}
                </ASFieldGroup>
                {this.state.data[audio_type] === "file" &&
                    <Row>
                        <Col sm={12} className="fileRow">
                            <Col sm={8}>
                                <ASFieldGroup
                                    type="file"
                                    accept="audio/*"
                                    onChange={e => {
                                        if(this.checkUploadFileSize(e, GlobalConst.AUDIO_UPLOAD_SIZE_LIMIT)){
                                            this.state.data[audio_file] = e.target.files;
                                        } else {
                                            this.state.data[audio_file] = [];
                                        }
                                        this.setState({data: this.state.data});
                                    }}
                                    validationState={this.validation[validation_type][audio_file](this.state.data)}/>
                            </Col>
                            <Col sm={1}>
                                <AscAudio
                                    className="control-button"
                                    type="file"
                                    data={this.state.data[audio_file]}
                                    langText={this.props.langText}
                                    disabled={
                                        this.state.data[audio_file].length > 0
                                        ? false
                                        : true
                                    }
                                />
                            </Col>
                        </Col>
                    </Row>
                }
                {this.state.data[audio_type] === "select" &&
                    <ASFieldGroup validationState={this.validation[validation_type][audio_file_id](this.state.data)}>
                        <Row>
                            <Col xs={6} sm={6} md={11} lg={11}>
                                <ASSelect
                                    value={this.state.data[audio_file_id]}
                                    options={this.state.audio_select}
                                    onChange={e => {
                                        this.state.data[audio_file_id] = e.value;
                                        this.setState({data: this.state.data});
                                    }}
                                    placeholder={this.placeholder}/>
                            </Col>
                            <Col xs={6} sm={6} md={1} lg={1}>
                                <AscAudio
                                    className="set_audio_button margin-top-01 margin-left--08"
                                    type="url"
                                    data={
                                        this.state.audio_select && this.state.audio_select[this.state.audio_select.findIndex((v) => v.value === this.state.data[audio_file_id])]
                                        ? this.state.audio_select[this.state.audio_select.findIndex((v) => v.value === this.state.data[audio_file_id])].url
                                        : null
                                    }
                                    disabled={
                                        this.state.audio_select && this.state.audio_select[this.state.audio_select.findIndex((v) => v.value === this.state.data[audio_file_id])]
                                        ? false
                                        : true
                                    }
                                />
                            </Col>
                        </Row>
                    </ASFieldGroup>
                }
                {this.state.data[audio_type] === "synthetic" &&
                    this.getSyntheticElem({
                        key,
                        validation_type,
                        synthetic_label,
                        audio_type,
                        audio_gen_language_original,
                        audio_gen_language,
                        audio_gen_person,
                        audio_gen_text_original,
                        audio_gen_text,
                        synthetic_type
                    })
                }
            </>
        );
    }

    /**
     * 画面からアップロードされたファイルのサイズチェック処理
     * 閾値以上の場合、エラーメッセージを表示して判定結果を返却
     * @param event
     * @param {integer} sizeLimit 閾値
     * @returns {boolean}
     */
    checkUploadFileSize(event, sizeLimit){
        if(event.target.files.length > 0 && event.target.files[0].size >= sizeLimit){
            event.target.value = "";
            alert(this.getErrorString({code: "Upload_sizeLimit", args: [this.props.langText.Message.AudioSizeLimit]}));
            return false;
        }
        return true;
    }

    getSyntheticElem({
        key,
        validation_type,
        synthetic_label,
        audio_type,
        audio_gen_language_original,
        audio_gen_language,
        audio_gen_person,
        audio_gen_text_original,
        audio_gen_text,
        synthetic_type
    }) {
        const param_obj = {
            key,
            validation_type,
            synthetic_label,
            audio_type,
            audio_gen_language_original,
            audio_gen_language,
            audio_gen_person,
            audio_gen_text_original,
            audio_gen_text,
            synthetic_type
        };
        return (
            <Panel bsStyle="info">
                <Panel.Heading>
                    <Panel.Title><ControlLabel className="label-color-333333">{synthetic_label}</ControlLabel></Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    <ASFieldGroup
                        label={this.props.langText.Body.ScriptAudioSyntheticType}
                        validationState={this.validation[validation_type][synthetic_type](this.state.data)}>
                        <ASSelect
                            value={this.state.data[synthetic_type]}
                            options={this.state.synthetic_type_select}
                            placeholder={this.placeholder}
                            onChange={e => {
                                this.state.data[synthetic_type] = e.value;
                                this.setState({
                                    data: this.state.data
                                });
                                this.onChange(param_obj, "synthetic_type");
                            }}/>
                    </ASFieldGroup>

                    {
                        this.state.data[synthetic_type]
                        && this.state.data[synthetic_type] === "translate"
                        ?
                        <ASFieldGroup
                            label={this.props.langText.Body.Lang}
                            validationState={this.validation[validation_type][audio_gen_language_original](this.state.data)}>
                            <ASSelect
                                value={this.state.data[audio_gen_language_original]}
                                placeholder={this.placeholder}
                                options={this.state.data[audio_gen_language]
                                    ? this.state.translate_select_list.filter(row =>
                                        this.getOtherlanguageCode(
                                            this.state.data[audio_gen_language],"polly") !== row.value)
                                    : this.state.language_select_before
                                }
                                onChange={e => {
                                    this.state.data[audio_gen_language_original] = e.value;
                                    this.setState({data: this.state.data});
                                    this.onChange(param_obj, "audio_gen_language_original");
                                }}/>
                        </ASFieldGroup>
                        : <ASFieldGroup
                            label={this.props.langText.Body.Lang}
                            validationState={this.validation[validation_type][audio_gen_language](this.state.data)}>
                            <ASSelect
                                value={this.state.data[audio_gen_language]}
                                placeholder={this.placeholder}
                                options={this.state.polly_select_list}
                                onChange={e => {
                                    this.state.data[audio_gen_language] = e.value;
                                    this.setState({data: this.state.data});
                                    this.onChange(param_obj, "audio_gen_language");
                                }}/>
                        </ASFieldGroup>
                    }


                    {
                        this.state.data[synthetic_type]
                        && this.state.data[synthetic_type] === "translate"
                        ?
                        <ASFieldGroup
                            label={this.props.langText.Body.Text}
                            validationState={this.validation[validation_type][audio_gen_text_original](this.state.data)}>
                                <FormControl
                                    componentClass="textarea"
                                    value={this.state.data[audio_gen_text_original]}
                                    onChange={e => {
                                        this.state.data[audio_gen_text_original] = e.target.value;
                                        this.setState({
                                            data: this.state.data,
                                        });
                                    }}/>
                        </ASFieldGroup>
                        : <ASFieldGroup
                            label={this.props.langText.Body.Text}
                            validationState={this.validation[validation_type][audio_gen_text](this.state.data)}>
                                <FormControl
                                    componentClass="textarea"
                                    value={this.state.data[audio_gen_text]}
                                    onChange={e => {
                                        this.state.data[audio_gen_text] = e.target.value;
                                        this.setState({
                                            data: this.state.data,
                                        });
                                    }}/>
                        </ASFieldGroup>
                    }


                    {
                        this.state.data[synthetic_type] && this.state.data[synthetic_type] === "translate" &&
                        <ASFieldGroup
                            label={""}
                            validationState={this.validation[validation_type][audio_gen_language](this.state.data)}>
                            <Col>
                                <Col className={"script-audio-synthetic-select"}>
                                    <ASSelect
                                        value={this.state.data[audio_gen_language]}
                                        placeholder={this.placeholder}
                                        options={this.state.data[audio_gen_language_original]
                                            ? this.state.polly_select_list.filter(row =>
                                                this.getOtherlanguageCode(
                                                    this.state.data[audio_gen_language_original],"translate") !== row.value)
                                            : this.state.language_select_after}
                                        onChange={e => {
                                            this.state.data[audio_gen_language] = e.value;
                                            this.setState({data: this.state.data});
                                            this.onChange(param_obj, "audio_gen_language");
                                        }}/>
                                </Col>
                                <Button
                                    className="translate-btn"
                                    bsStyle="info"
                                    onClick={e => this.translateEvent({
                                                            audio_gen_language_original,
                                                            audio_gen_language,
                                                            audio_gen_text_original,
                                                            audio_gen_text})}
                                    disabled={
                                        !this.state.data[audio_gen_language_original]
                                        || !this.state.data[audio_gen_language]
                                        || !this.state.data[audio_gen_text_original]
                                        || !this.state.data[audio_gen_text_original].trim()}>
                                    {this.props.langText.Body.Tlanslate}{<Glyphicon glyph="arrow-down"/>}
                                </Button>
                            </Col>
                        </ASFieldGroup>
                    }

                    {
                        this.state.data[synthetic_type] && this.state.data[synthetic_type] === "translate" &&
                        <ASFieldGroup
                            label={""}
                            validationState={this.validation[validation_type][audio_gen_text](this.state.data)}>
                            <FormControl
                                componentClass="textarea"
                                value={this.state.data[audio_gen_text]}
                                onChange={e => {
                                    this.state.data[audio_gen_text] = e.target.value;
                                    this.setState({data: this.state.data});
                                    this.onChange(param_obj, "audio_gen_text");
                                }}/>
                        </ASFieldGroup>
                    }

                    <ASFieldGroup
                        label={this.props.langText.Body.ScriptAudioClass}
                        validationState={this.validation[validation_type][audio_gen_person](this.state.data)}>
                        <Col>
                            <Col className={"script-audio-synthetic-select"}>
                                <ASSelect
                                    value={this.state.data[audio_gen_person]}
                                    placeholder={this.placeholder}
                                    options={this.state.data[audio_gen_language]
                                        ? this.state.all_voice_list.filter(row => row.code === this.state.data[audio_gen_language])
                                        : this.state.voice_select}
                                    onChange={e => {
                                        this.state.data[audio_gen_person] = e.value;
                                        this.setState({data: this.state.data});
                                }}/>
                            </Col>
                            <Button
                                className="play-btn"
                                bsStyle="info"
                                onClick={e => this.voiceSyntheticEvent(param_obj)}
                                disabled={!this.state.data[audio_gen_text].trim() || !this.state.data[audio_gen_person]}>
                                {this.props.langText.Body.PlayVoice}
                            </Button>
                        </Col>
                    </ASFieldGroup>


                    {/* <ASFieldGroup
                        label={this.props.langText.Body.ScriptCustomColumn}
                        groupStyle={{paddingLeft: "10%"}}
                        labelStyle={{fontSize: "20%"}}>
                        <Col xs={12} sm={12}>
                            <Col xs={9} sm={9} style={{paddingRight: 0}}>
                                <ASSelect
                                    value={this.state.screen_data.audio_custom_column[key]}
                                    options={this.selectOptions.audio_custom_column}
                                    onChange={e => {
                                        this.state.screen_data.audio_custom_column[key] = e.value;
                                        this.setState({screen_data: this.state.screen_data});
                                    }}/>
                            </Col>
                            <Col xs={3} sm={3}>
                                <Button
                                    bsStyle="default"
                                    bsSize="sm"
                                    style={{marginTop: "3px"}}
                                    onClick={e => {
                                        console.log(e);
                                    }}>
                                    {this.props.langText.Body.Insert2}
                                </Button>
                            </Col>
                        </Col>
                    </ASFieldGroup> */}
                </Panel.Body>
            </Panel>
        );
    }

    getSessionSelect(condition) {
        let target_index = this.state.data.nexts.findIndex(row => row.condition === condition);
        return <ASSelect
            value={target_index !== -1 ? this.state.data.nexts[target_index].block_no : null}
            options={this.getSessions()}
            placeholder={this.props.langText.SelectOption.EndCall}
            isClearable={true}
            onChange={e => {
                if (e) {
                    if (target_index !== -1) {
                        this.state.data.nexts[target_index].block_no = e.value;
                    } else {
                        this.state.data.nexts.push({condition, block_no: e.value});
                    }
                } else {
                    if (target_index !== -1) {
                        this.state.data.nexts.splice(target_index, 1);
                    }
                }
                this.setState({data: this.state.data});
            }}/>;
    }

    saveEvent() {
        let link_create_arr = [];

        this.state.data.nexts.forEach(next => {
            let la = link_create_arr.find(link => link.block_no === next.block_no);
            if (la) {
                la.condition += `,${next.condition}`;
            } else {
                link_create_arr.push(Object.assign({}, next));
            }
        });

        let script_additional_event = this.selectOptions.script_additional_event;
        script_additional_event.forEach(event => {
            let la = link_create_arr.find(link => link.block_no === this.state.data[event.value]);
            let condition = event.label;

            if (la) {
                la.condition += `,${condition}`;
            } else {
                let next_obj = {
                    block_no: this.state.data[event.value],
                    condition
                }
                link_create_arr.push(Object.assign({}, next_obj));
            }
        })

        this.model.removeRightLink();
        link_create_arr.forEach(create => {
            const find_model = this.model.models.find(model => model.getOptions().number === create.block_no);
            if (find_model) {
                const diagram = this.model.getParent().getParent();
                const source_port = this.model.getPorts().right;
                const target_port = find_model.getPorts().left;
                const new_link = source_port.createLinkModel();

                new_link.addLabel(create.condition);
                new_link.setSourcePort(source_port);
                new_link.setTargetPort(target_port);
                source_port.reportPosition();
                target_port.reportPosition();
                diagram.addLink(new_link);
            }
        });

        this.model.c_data = Object.assign(this.model.c_data, this.state.data);
        this.props.onHide();
    }

    cancelEvent() {
        this.props.onHide();
    }

    // 翻訳内容取得
    async translateEvent({
        audio_gen_language_original,
        audio_gen_language,
        audio_gen_text_original,
        audio_gen_text
    }) {
        try {
            const TargetCode = this.getOtherlanguageCode(
                this.state.data[audio_gen_language],
                "polly"
            );

            const translate = await this.ascAxios(
                "post", `Script/getTranslateText`, {
                    "Text" : this.state.data[audio_gen_text_original],
                    "SourceLanguageCode" : this.state.data[audio_gen_language_original],
                    "TargetLanguageCode" : TargetCode
                }
            );

            this.state.data[audio_gen_text] = translate.data.TranslatedText;
            this.setState({data: this.state.data});
        } catch (err) {
            console.error(err);
            alert(this.getErrorString(err.response.data));
        }
    }

    // 音声合成し再生
    async voiceSyntheticEvent(key_obj) {
        let {
            audio_gen_language,
            audio_gen_person,
            audio_gen_text
        } = key_obj;
        try {
            if ((this.state.pre_audio_gen_text !== this.state.data[audio_gen_text]) ||
                (this.state.pre_audio_gen_person !== this.state.data[audio_gen_person])) {
                this.toggleBlocking();

                const audio_language = this.selectOptions.language_code_list.find(row =>
                    row.code_polly === this.state.data[audio_gen_language]).label;

                const audio_name = this.state.all_voice_list.find(row =>
                    row.value === this.state.data[audio_gen_person]).label;

                this.setState({
                    downloadWaitTime: 5,
                    modal_title : audio_language + " " + audio_name
                });

                const trimed_text = this.state.data[audio_gen_text].trim();

                const voiceFileInfo = await this.ascAxios("post", `Script/getTranslateVoice`, {
                    Text : trimed_text,
                    VoiceId : this.state.data[audio_gen_person],
                });

                if(voiceFileInfo && voiceFileInfo.data){
                    const path = voiceFileInfo.data.OutputUri.replace(/(.*).com\//, "");
                    const path_arr = path.split("/");
                    const file_name = path_arr[path_arr.length-1];

                    const task_id = voiceFileInfo.data.TaskId;

                    const interval_id = setInterval(() => {
                        this.checkS3File({
                            task_id,
                            file_name,
                            interval_id,
                            audio_gen_text,
                            audio_gen_person
                        });
                    }, 3000);
                }

            } else {
                this.setState({
                    play: true
                });
            }

        } catch (err) {
            console.error(err);
            this.toggleBlocking();
            this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    // S3で音声ファイルの有無確認
    async checkS3File ({
        task_id,
        file_name,
        interval_id,
        audio_gen_text,
        audio_gen_person
    }) {
        const intervalSecond = 3;
        const maxWaitTime = 60 * 5;

        try {
            const s3fileCheck = await this.ascAxios("post", `Script/checkS3AudioFile`, {
                TaskId : task_id,
                fileName: file_name,
            });

            switch (s3fileCheck.data.SynthesisTask.TaskStatus) {
                case "completed":
                    clearInterval(interval_id);
                    this.setState({
                        voice_data_url: s3fileCheck.data.signed_url,
                        play: true,
                        pre_audio_gen_text: this.state.data[audio_gen_text],
                        pre_audio_gen_person: this.state.data[audio_gen_person]
                    });
                    this.toggleBlocking();
                    break;
                case "failed":
                    //サーバーから結果がエラーの場合
                    throw new Error("CreateFileFailed");
                default:
                    break;
            }

            //タイムアウト処理
            this.setState({downloadWaitTime: this.state.downloadWaitTime + intervalSecond});
            if (this.state.downloadWaitTime > maxWaitTime) {
                throw new Error("CreateFileFailed");
            }
        } catch (err) {
            clearInterval(interval_id);
            this.toggleBlocking();
            this.showErrorObjectMesssage(err);
        }

    }

    getSaveBtn() {
        return (
            <Button
                className="ok-execute-btn"
                bsStyle="info"
                onClick={e => this.saveEvent()}
                disabled={!this.model.checkValidation(this.props.linkFlag ? "link" : "menu", this.state.data)}>
                {this.props.langText.Body.Save}
            </Button>
        );
    }

    getCancelBtn() {
        return (
            <Button
                className="default-button"
                bsStyle="danger"
                style={{marginRight: "5px"}}
                onClick={e => this.cancelEvent()}>
                {this.props.langText.Body.Cancel}
            </Button>
        );
    }

    getSessions() {
        let session_data= [];

        this.model.models.forEach(row => {
            if (this.model.getID() !== row.getID() && row.getOptions().number !== 0) {
                session_data.push({
                    label: `#${row.getOptions().number} ${row.c_data.title}`,
                    value: row.getOptions().number
                });
            }
        });

        return session_data;
    }

    // 一般MENU用ELEM
    getOptionElem() {
        return [];
    }

    // 複数LINK用ELEM
    getLinkElem() {
        return (
            <>
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptNextSession}>
                    {this.getSessionSelect("all")}
                </ASFieldGroup>
            </>
        );
    }

    // タイムアウト・その他の番号入力の設定
    onAdditionalEventChange(event, event_name) {
        if (event) {
            this.state.data[event_name] = event.value;
        } else {
            this.state.data[event_name] 
            = this.selectOptions.next_session_event[0].value;
        }
        this.setState({data: this.state.data});
    }

    render() {
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <MenuRow>
                    <TitleCol xs={12} sm={12}>
                        <label>#{this.number} {this.state.data.title}</label>
                    </TitleCol>

                    <ContentCol xs={12} sm={12}>
                        {this.props.linkFlag ? this.getLinkElem() : this.getOptionElem()}
                    </ContentCol>

                    <BottomCol xs={12} sm={12} width={this.state.footter_width}>
                        <div className="pull-right">
                            {this.getCancelBtn()}
                            {this.getSaveBtn()}
                        </div>
                    </BottomCol>
                </MenuRow>

                <Modal
                    key = {0}
                    show = {this.state.play}
                    onHide = {e => this.setState({play: false})}
                    >
                    <Form horizontal = {true}>
                        <Modal.Header>
                            <Modal.Title>
                                {this.state.modal_title}
                            </Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <audio
                                key = "asc-audio"
                                className = "asc-audio"
                                controls = {true}
                                controlsList = "nodownload"
                                autoPlay = {true}
                                >
                                <source  src = {this.state.voice_data_url} type="audio/mp3"></source>
                            </audio>
                        </Modal.Body>
                    </Form>
                </Modal>
            </BlockUi>
        );
    }
}