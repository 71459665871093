import * as React from 'react';
import styled from '@emotion/styled';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";

const DivAlign = styled.div`
    position: relative;
    float: left;
    margin: 10px 15px 10px 10px;
    height: 52px;
    text-align: center;
`;

const DivItem = styled.div`
    background-color: ${(p) => p.color || "white"};
	cursor: pointer;
	width: 40px;
	height: 40px;
	box-shadow: 0px 0px 5px 0.1px rgba(172, 173, 168);
    border-radius: 5px;
`;

const FontAwesomeIconCustom = styled(FontAwesomeIcon)`
    color: #606060;
    position: absolute;
    top: 20px;
	left: 20px;
	transform: translate(-50%, -50%);
    &:hover {
        cursor: pointer;
        color: #404040;
    }
`;

const LabelFont = styled.label`
    color: white;
    font-size: 10px;
`;

export class DragItem extends React.Component {
    render() {
        return (
            <DivAlign>
                <DivItem 
                    className="ivr-node"
                    draggable={true}
                    onDragStart={(event) => {
                        event.dataTransfer.setData('item-node', JSON.stringify(this.props.model));
                    }}>
                    <FontAwesomeIconCustom
                        className="sidebar-icon fa-fw fa-2x"
                        icon={this.props.model.icon}/>
                </DivItem>
                <LabelFont>
                    {this.props.model.data.title}
                </LabelFont>
            </DivAlign>
        );
    }
}
