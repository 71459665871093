import React, { Component } from "react";
import { Panel } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import { InsertCsvAutoCallDetailList } from "../Elements/AscElements/AutoCallListElements";
import { CSVLink } from "react-csv";

/**
 * 自動発信リスト詳細インポートコンポーネント
 */
export default class AutoCallDetailListCsvInsertModal extends Component {
    /**
     * CSV投入用のテンプレートを作成。出力は有効な項目のみを出力する
     * @return {Element} CSVLink Element
     */
    templateDownload = () => {
        let headers = [[this.props.langText.Body.Telno]];
        this.props.state.header_json.forEach(header => {
            if (header.disabled !== "Y") headers[0].push(header.header_name);
        });
        return (
            <CSVLink
                className="csv-format-download-link"
                data={headers}
                filename={this.props.state.list_name + ".csv" }
            >
                <b>{this.props.langText.Message.FormatDownloadLink}</b>
            </CSVLink>
        );
    };

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}
            >
                <Panel bsStyle="info">
                    <Panel.Body>
                        <InsertCsvAutoCallDetailList
                            langText={this.props.langText}
                            handleOnDrop={this.props.handleOnDrop}
                            handleOnError={this.props.handleOnError}
                            handleOnRemoveFile={this.props.handleOnRemoveFile}
                        />
                        {this.templateDownload()}
                        <p className="csvImportMsg">
                            {this.props.langText.Message.CsvTelnoRequired}
                        </p>
                        <p className="csvImportMsg">{this.props.langText.Message.CsvAddColumn}</p>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
