import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row, Table } from "react-bootstrap";
import TotalCallLineChart from "./TotalCallLineChart";
import CallTypeBarChart from "./CallTypeBarChart";
import CallHeatMapChart from "./CallHeatMapChart";
import CallAnalysisCharts from "./CallAnalysisCharts";
import SetDataWithSpeechBubble from "../../Elements/AscElements/SetDataWithSpeechBubble";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import { CSVLink } from "react-csv";
import CallResultCharts from "./CallResultCharts";


export default class Overview extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            total_call_view_level: "count",
            call_heat_map_view_level: "all",
        }
    }

    // 初期設定
    componentDidMount = async () => {
        await this.props.onSummary();
    }

    onViewChange = (viewMode) => {
        switch (viewMode) {
            case "count":
            case "time":
                this.setState({
                    total_call_view_level: viewMode
                });
                break;
            case "all":
            case "out":
            case "in":
                this.setState({
                    call_heat_map_view_level: viewMode
                });
                break;
            default:
                break;
        }
    }

    getTotalCallViewModeInfo = () => {
        let {
            langText
        } = this.props;

        return (
            <ButtonGroup className="call-chart-button">
                <Button
                    key="TotalCallCount"
                    onClick={e => this.onViewChange("count")}
                    className={this.state.total_call_view_level === "count" ? "call-chart-button-focus" : ""}
                >
                    {langText.Body.TotalCallCount}
                </Button>
                <Button
                    key="TotalCallTime"
                    onClick={e => this.onViewChange("time")}
                    className={this.state.total_call_view_level === "time" ? "call-chart-button-focus" : ""}
                >
                    {langText.Body.TotalCallTime}
                </Button>
            </ButtonGroup>
        );
    }

    getCallHeatMapViewModeInfo = (hidden) => {
        let {
            state,
            langText
        } = this.props;
        let level = state.hidden_filtered.find(row => row.id === "summary_method");

        return (
            <div className={`margin-bottom-07 ${hidden ? "info-hidden" : ""}`}>
                <ButtonGroup className="call-chart-button">
                    <Button
                        key="AvgCallCount"
                        onClick={e => this.onViewChange("all")}
                        className={this.state.call_heat_map_view_level === "all" ? "call-chart-button-focus" : ""}
                    >
                        {
                            level.value === "average" ?
                            langText.Body.AvgCallCount :
                            langText.Body.MedCallCount
                        }
                    </Button>
                    <Button
                        key="OutboundRate"
                        onClick={e => this.onViewChange("out")}
                        className={this.state.call_heat_map_view_level === "out" ? "call-chart-button-focus" : ""}
                    >
                        <SetDataWithSpeechBubble
                            displayData={langText.Body.OutboundRate}
                            speechBubbleData={langText.Message.OutboundRateDescription}
                            infoIconDisplayFlag={true}
                            infoIconType={faIcon.faInfoCircle}
                            infoIconClassName="info-icon"
                            infoIconColor="silver"
                            infoIconSize="lg"
                            custom="custom"
                        />    
                    </Button>
                    <Button
                        key="InboundRate"
                        onClick={e => this.onViewChange("in")}
                        className={this.state.call_heat_map_view_level === "in" ? "call-chart-button-focus" : ""}
                    >
                        <SetDataWithSpeechBubble
                            displayData={langText.Body.InboundRate}
                            speechBubbleData={langText.Message.InboundRateDescription}
                            infoIconDisplayFlag={true}
                            infoIconType={faIcon.faInfoCircle}
                            infoIconClassName="info-icon"
                            infoIconColor="silver"
                            infoIconSize="lg"
                            custom="custom"
                        />  
                    </Button>
                </ButtonGroup>
                <ButtonGroup className="float-style">
                    <Button
                        id="heatmap-csv-download" 
                        className="analysis-summary-csv-download-button"
                        onClick={() => this.props.DownloadData()}
                    >
                        <FontAwesomeIcon
                            icon={faIcon.faCloudDownloadAlt}
                            size="lg"
                        />
                    </Button>
                    <CSVLink
                        data={this.props.createDownloadData(this.state.call_heat_map_view_level)}
                        filename={this.props.csvTitle}
                        className="csv-download-link-format hidden"
                        ref={this.props.csvLink}
                    />
                </ButtonGroup>
            </div>
        );
    }

    secToTime = (secs) => {
        let time = Math.round(secs),
            hour = Math.floor(time / 3600),
            minutes = Math.floor(time / 60) % 60,
            sec = time % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    render() {
        return (
            <div className="overview">
                <Row className="margin-top-1">
                    <Col sm={12} md={7}>
                        {this.getTotalCallViewModeInfo()}
                        <TotalCallLineChart
                            state={this.state}
                            data={this.props.data}
                            langText={this.props.langText}
                            hiddenFiltered={this.props.state.hidden_filtered}
                            secToTime={this.secToTime}
                        />
                    </Col>
                    <Col sm={12} md={5} className="padding-top-15">
                        <CallTypeBarChart
                            state={this.state}
                            data={this.props.data}
                            langText={this.props.langText}
                        />
                    </Col>
                </Row>
                <Row className="padding-top-15">
                    <Col sm={12} md={6}>
                        {this.getCallHeatMapViewModeInfo()}
                        <CallHeatMapChart
                            filtered={this.props.state.hidden_filtered}
                            state={this.state}
                            data={this.props.data}
                            langText={this.props.langText}
                        />
                    </Col>
                    <Col sm={12} md={6}>
                        {this.getCallHeatMapViewModeInfo(true)}
                        <CallResultCharts
                            filtered={this.props.state.hidden_filtered}
                            state={this.state}
                            data={this.props.data}
                            langText={this.props.langText}
                            csvLinkCallResult={this.props.csvLinkCallResult}
                            csvTitle={this.props.csvTitle}
                            createDownloadData={this.props.createDownloadData}
                            DownloadData={this.props.DownloadData}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={12}>
                        <CallAnalysisCharts
                            state={this.state}
                            data={this.props.data}
                            langText={this.props.langText}
                            secToTime={this.secToTime}
                        />
                    </Col>
                </Row>
                <Row className="padding-top-15">
                    <Col sm={12} md={12}>
                        <Table bordered className="overview-table">
                            <tbody>
                                <tr>
                                    <th>
                                        <span className="margin-left-05">{this.props.langText.Body.Other}</span>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.TalkSpeed}
                                                speechBubbleData={this.props.langText.Message.TalkSpeedDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                        <span>{this.props.data.speech_speed ? Number(this.props.data.speech_speed).toLocaleString() : 0}{this.props.langText.Body.TextSecond}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.FillerCount}
                                                speechBubbleData={this.props.langText.Message.FillerDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />     
                                        </span>
                                        <span>{this.props.data.filler_count ? Number(this.props.data.filler_count).toLocaleString() : 0}{` ${this.props.langText.Body.CountPerMinute}`}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.Frequency}
                                                speechBubbleData={this.props.langText.Message.FrequencyDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                        <span>{this.props.data.frequency ? Number(this.props.data.frequency).toLocaleString() : 0}{` ${this.props.langText.Body.Hz}`}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.FrequencyDifference}
                                                speechBubbleData={this.props.langText.Message.FrequencyDifferenceDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                        <span>{this.props.data.frequency_diff ? Number(this.props.data.frequency_diff).toLocaleString() : 0}{` ${this.props.langText.Body.Percent}`}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.Accent}
                                                speechBubbleData={this.props.langText.Message.AccentDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                        <span>{this.props.data.accent ? Number(this.props.data.accent).toLocaleString() : 0}</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <SetDataWithSpeechBubble
                                                displayData={this.props.langText.Body.AccentDifference}
                                                speechBubbleData={this.props.langText.Message.AccentDifferenceDescription}
                                                infoIconDisplayFlag={true}
                                                infoIconType={faIcon.faInfoCircle}
                                                infoIconClassName="info-icon"
                                                infoIconColor="silver"
                                                infoIconSize="lg"
                                                placement="top"
                                                custom="custom"
                                            />    
                                        </span>
                                        <span>{this.props.data.accent_diff ? Number(this.props.data.accent_diff).toLocaleString() : 0}{` ${this.props.langText.Body.Percent}`}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>
        );
    }
}
