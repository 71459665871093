import React, {Component} from "react";
import {Modal, Form, Button} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPlay} from '@fortawesome/free-solid-svg-icons'
import "./Audio.css";

export default class Audio extends Component {
    constructor(props) {
        super(props);
        this.state = {
            play: false
        };
    }

    getMyAudio = () => {
        let src_m4a = null;
        let src_mp3 = null;
        let src_ogg = null;
        let src_wav = null;
        let src = null;
        let real_path = "";
        try {
            if (this.props.type === "file") {
                src = URL.createObjectURL(this.props.data[0]);
            }
            let path = this.props.data.match(/(.*)(?:\.([^.]+$))/)[1];
            let path_arr = path.split("/");

            //ファイル名に特殊文字が含まれている場合、pathでファイル名だけエンコードする
            let specialChar = /[&$@=;:+,?]/g; 
            if(specialChar.test(path_arr[path_arr.length-1])) {
                let encoded_file_name = `${encodeURIComponent(path_arr[path_arr.length-1])}`;
                path_arr.pop();
                path_arr.push(encoded_file_name);
                real_path = path_arr.join('/');
            }else{ 
                real_path = path;
            }

            src_ogg = `${real_path}.ogg`;
            src_m4a = `${real_path}.m4a`;
            src_mp3 = `${real_path}.mp3`;
            src_wav = `${real_path}.wav`;
        } catch (err) {}

        let return_element =[];
        return_element.push(
            <audio
                id = "asc-audio"
                key = "asc-audio"
                className = "asc-audio"
                controls = {true}
                controlsList = "nodownload"
                autoPlay = {true}
                onPlay = {e => {
                    if (this.props.onPlayHandle) {
                        this.props.onPlayHandle(document.getElementById('asc-audio'));
                    }
                }}
            >
                <source  src = {src_ogg} type="audio/ogg"></source>
                <source  src = {src_m4a} type="audio/mp4"></source>
                <source  src = {src_mp3} type="audio/mp3"></source>
                <source  src = {src_wav} type="audio/wav"></source>
                <source  src = {src} type="audio/mp4"></source>
            </audio>
    
        );
        if (this.props.type === "file") {
            return_element.push(
                <label>{this.props.langText.Message.Format_Not_Support}</label>
            );
        }
        return return_element;
    }

    clickCheck(e) {
        e.preventDefault();
        return false;
    }

    render() {
        let {
            title = "",
            bsSize = "xsmall",
            type,
            data,
            onPlayHandle,
            ...props
        } = this.props;
        let audioElem = this.getMyAudio();
        let returnElem = [
            <Modal
                key = {0}
                show = {this.state.play}
                onHide = {e => this.setState({play: false})}
                onContextMenu = {e => this.clickCheck(e)}>
                <Form horizontal = {true}>
                    <Modal.Header>
                        <Modal.Title>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {audioElem}
                    </Modal.Body>
                </Form>
            </Modal>,
            <Button
                key = {1}
                bsSize = {bsSize}
                onClick = {e => this.setState({play: true})}
                {...props}>
                <FontAwesomeIcon
                    icon = {faPlay}/>
            </Button>
        ];

        return returnElem;
    }
}
