import { Action, InputType } from '@projectstorm/react-canvas-core';
import * as _ from "lodash";

export class ASAction extends Action {
	constructor(options = {}) {
		options = {
			keyCodes: [46, 8],
			...options
		};
		super({
			// type: InputType.KEY_DOWN,
			// fire: (event) => {
			// 	if (options.keyCodes.indexOf(event.event.keyCode) !== -1) {
			// 		const selected_entities = this.engine.getModel().getSelectedEntities();
			// 		console.log(selected_entities);

			// 		if (selected_entities > 0) {
			// 			const confirm = window.confirm('Are you sure you want to delete?');

			// 			if (confirm) {
			// 				_.forEach(selected_entities, (model) => {
			// 					// only delete items which are not locked
			// 					if (!model.isLocked()) {
			// 						model.remove();
			// 					}
			// 				});
			// 				this.engine.repaintCanvas();
			// 			}
			// 		}
			// 	}
			// }
		});
	}
}