import React from "react";
import { Panel } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import { InsertKeywordGroup } from "../Elements/AscElements/KeywordGroupElements";
import { SelectCompanyElement, ViewCompanyElement } from "../Elements/AscElements/CompanyElements";
import AscComponent from "../../components/AscComponent";
import * as GlobalConst from "../../components/AscConstants";

/**
 * キーワードグループ新規登録画面
 */
export default class KeywordGroupInsertModal extends AscComponent {
    /**
     * SystemとOem権限の場合に会社名セレクトボックスをセット
     */
    setCompanyElement = () => {
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            return (
                <SelectCompanyElement
                    state={this.props.state}
                    langText={this.props.langText}
                    onSelectChange={this.props.onSelectChange}>
                </SelectCompanyElement>
            )
        } else {
            <ViewCompanyElement
                state={this.props.state}
                langText={this.props.langText}>
            </ViewCompanyElement>
        }
    }
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.KeywordGroupInfo}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.setCompanyElement()}
                        <InsertKeywordGroup
                            state={this.props.state}
                            langText={this.props.langText}
                            onTextChange_Limit={this.props.onTextChange_Limit}
                            charaLimit={this.props.charaLimit}
                            validationHandle={this.props.validationHandle}
                            onSelectChange={this.props.onSelectChange}
                            onMultiValueFormTextChange={this.props.onMultiValueFormTextChange}
                            onMultiValueFormSizeChange={this.props.onMultiValueFormSizeChange}
                            checkDuplicateKeywordGroupName={this.props.checkDuplicateKeywordGroupName}
                            boardWidth={this.props.boardWidth}>
                        </InsertKeywordGroup>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
