import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import Favi from "../Elements/Favi/Favi"
import InboundGroupModal from "./InboundGroupModal";
import * as GlobalConst from "../../components/AscConstants";

export default class InboundGroup extends AscComponent {
    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],
            extensionNumberSelect: [],
            inboundGroupTypeSelect: this.getSelectOption(
                'inbound_group_type',
                this.props.langText.SelectOption
            ),

            // data item
            inbound_group_info_id: "",
            company_id: "",
            company_name: "",
            department_id: "",
            department_name: "",
            floor_id: "",
            floor_name: "",
            group_name: "",
            group_type: "",
            group_type_org: "",
            extension_number: [],

            // button_disabled
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load" : [],
                "floor_load" : []
            },
        };
        this.floor_load_flag = false;
    }

    getColumnsData() {
        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            filterable: false,
            width: this.props.boardWidth.id,
        });
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            //oem権限以上の場合、会社名を表示
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm13_departments.cm12_companies.company_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.cm12_companies.company_name")} />
            });
        }
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)){
            //会社権限以上の場合、拠点名を表示
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: "cm13_departments.department_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")} />
            });
        }
        if (
            (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            //システム権限または拠点管理権限のフロアありの場合、フロア名を表示
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: "cm13_departments.floor_name",
                Filter: () => (
                    <FormControl
                        onChange={e =>
                            this.onFilterChange(e.target.value, "cm13_departments.floor_name")
                        }
                    />
                ),
            });
        }
        itemArr.push({
            Header: this.props.langText.Body.GroupName,
            accessor: "inbound_group_name",
            Cell: data => {
                return ( <div title= {data.row._original.inbound_group_name}>
                        {data.row._original.inbound_group_name}
                    </div>
                );
            },
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "inbound_group_name")} />
        });
        itemArr.push({
            Header: this.props.langText.Body.ExtensionNumberNum,
            filterable: false,
            width: this.props.boardWidth.large,
            accessor: "ExtensionNumCount",
        });
        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.smedium,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            onClick={this.modalShow("update", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="pencil" />
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow("read", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="eye-open" />
                        </Button>
                    );
                }

                if (this.props.currentPermission.create) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow("copy", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            onClick={this.modalShow("delete", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus" />
                        </Button>
                    );
                }

                return (rowData);
            }
        });

        return itemArr;
    }

    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load" : [],
                "floor_load" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    modalShow = (modalType, param) => async event => {
        try{
            event.preventDefault();

            this.floor_load_flag = false;

            this.modalshow_commonInit();

            let setData = {
                inbound_group_info_id: "",
                company_id: "",
                company_name: "",
                department_id: "",
                department_name: "",
                group_name: "",
                group_type: this.state.inboundGroupTypeSelect[2].value,
                group_type_org:"",
                extension_number: [],
                companySelect: [],
                departmentSelect: [],
                extensionNumberSelect: [],
                floor_id: ""
            };

            if (param) {
                setData.inbound_group_info_id = param.id
                    ? param.id
                    : setData.inbound_group_info_id;
                setData.company_id = param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.id
                    ? param.cm13_departments.cm12_companies.id
                    : setData.company_id;
                setData.company_name = param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.company_name
                    ? param.cm13_departments.cm12_companies.company_name
                    : setData.company_name;
                setData.floor_flg = param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.floor_flg
                    ? param.cm13_departments.cm12_companies.floor_flg === "Y"
                    : null;
                setData.department_id = param.cm13_departments && param.cm13_departments.id
                    ? param.cm13_departments.id
                    : setData.department_id;
                setData.department_name = param.cm13_departments && param.cm13_departments.department_name
                    ? param.cm13_departments.department_name
                    : setData.department_name;
                setData.department_prefix = param.cm13_departments && param.cm13_departments.department_prefix
                    ? param.cm13_departments.department_prefix
                    : setData.department_prefix;
                setData.floor_id = param.cm13_departments && param.cm13_departments.cm12_companies
                && param.cm13_departments.cm12_companies.floor_flg === "Y" && param.cm13_departments && param.cm13_departments.id
                    ? param.cm13_departments.id
                    : "";
                setData.floor_name = param.cm13_departments && param.cm13_departments.floor_name
                    ? param.cm13_departments.floor_name
                    : setData.floor_name;
                setData.floor_flg = param.cm13_departments && param.cm13_departments.cm12_companies && param.cm13_departments.cm12_companies.floor_flg
                    ? param.cm13_departments.cm12_companies.floor_flg === "Y"
                    : null;
                setData.group_name = param.inbound_group_name
                    ? param.inbound_group_name
                    : setData.group_name;
                setData.group_type = param.inbound_type
                    ? param.inbound_type
                    : setData.group_type;
                setData.group_type_org = param.inbound_type
                    ? param.inbound_type
                    : setData.group_type;
                setData.extension_number = param.cm51_extension_numbers
                    ? param
                        .cm51_extension_numbers
                        .map(row => {
                            return row.id;
                        })
                    : setData.extension_number;
            } else {
                if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                    setData.floor_flg = this.props.userInfo.floor_flg;
                    setData.company_id = this.props.userInfo.company_id;
                    setData.company_name = this.props.userInfo.company_name;
                }

                if (this.props.currentPermission.scope_code === GlobalConst.SCOPE_OBJECT.department.name) {
                    //新規作成時に拠点情報とフロア選択肢をセットする
                    setData.department_id = this.props.userInfo.department_id
                    setData.department_name = this.props.userInfo.department_name
                    this.departmentSelectChanged({
                        company_id: setData.company_id,
                        department_prefix: this.props.userInfo.department_prefix,
                    });
                }

                if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                    setData.department_id = this.props.userInfo.department_id
                    setData.department_name = this.props.userInfo.department_name
                    setData.floor_id = this.props.userInfo.department_id
                    setData.floor_name = this.props.userInfo.floor_name
                }
            }

            if (modalType === "copy" && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                //グループ名は重複不可のため初期表示は空白にする
                setData.group_name = "";

                //フロアがある場合拠点idとprefix再設定
                if(setData.floor_flg){
                    setData.floor_id = param.cm13_departments.id;
                    const parentDepartment = await this.getParentDepartment(
                        param.cm13_departments.cm12_companies.id,
                        param.cm13_departments.department_prefix
                    );
                    setData.department_id = parentDepartment.data.id;
                    setData.department_prefix = parentDepartment.data.department_prefix;
                }

                this.departmentSelectChanged({
                    company_id: setData.company_id,
                    department_prefix: setData.department_prefix,
                });
            }

            this.getCompanySelect();
            this.getDepartmentSelect(setData);

            if (setData.company_id !== "" && setData.department_id !== "" && setData.group_type !== "") {
                this.getExtensionNumberSelect({
                    department_id: setData.floor_flg ? setData.floor_id : setData.department_id,
                    group_type: setData.group_type
                });
            }

            this.setState(setData);
            this.setState({show: true, modalType});
        }catch(err){
            this.showErrorObjectMesssage(err);
            this.reactTableRefresh();
        }
    };

    onSelectChange(event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        const floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : this.state.floor_flag;
        const department_prefix = (event && event.department_prefix) ? event.department_prefix : "";


        switch (param) {
            case "company_id":
                this.setState({department_id: "", floor_flg, extension_number: [], extensionNumberSelect: []});
                this.getDepartmentSelect({company_id: value, floor_flg});
                this.departmentSelectChanged({company_id: value, department_prefix});
                break;

            case "department_id":
                if (this.state.floor_flg) {
                    this.floor_load_flag = true;
                    this.departmentSelectChanged({company_id: this.state.company_id, department_prefix});
                }
                //内線を一覧取得のため、breakせずに処理を継続させる
            case "floor_id":
                this.setState({extension_number: []});
                let department_id = value,
                    group_type = this.state.group_type;

                this.getExtensionNumberSelect({department_id, group_type});
                break;

            default:
                break;

        }
    }

    onRadioChange(event, param) {
        super.onRadioChange(event, param);
        let value = (event && event.target && event.target.value) ? event.target.value : "";

        if (param === "group_type") {
            // this.setState({extension_number: []});
            let department_id = this.state.floor_id || this.state.department_id,
                group_type = value;
            this.getExtensionNumberSelect({department_id, group_type});
        }
    }

    getCompanySelect = () => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
                this.setState({companySelect: res.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };

    getDepartmentSelect = ({company_id, floor_flg}) => {
        if(company_id)
        {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        this.ascAxios('post', `Common/departmentSelect`, {
            container: this.reactContainerPath,
            company_id,
            floor_flg
        }).then(res => {
            if(this.state.company_id)
            {
                this.common_buttondisabledLengthCheck(`department_id`,`push`);
                this.common_buttondisabledLengthCheck(`department_load`,`pop`);
            }
            else
            {
                this.common_buttondisabledLengthCheck(`department_id`,`pop`);
            }
            this.setState({departmentSelect: res.data});
        }).catch(err => {
            alert(this.getErrorString(err.response.data));
        });
    };

    /**
 * 親拠点情報を取得する
 * @param {string} company_id 
 * @param {string} department_prefix 
 */
    getParentDepartment = async(company_id, department_prefix) => {
        try {
            const parentDepartment = await this.ascAxios("post", `Common/getParentDepartment`, {
                company_id: company_id,
                department_prefix: department_prefix.slice(0, 2),
            });
            return parentDepartment;
        } catch (err) {
            throw new Error(err);
        }
    }

    getExtensionNumberSelect = ({department_id, group_type}) => {
        this
            .ascAxios(
                'post',
                `${this.reactContainerPath}/extensionNumberSelect`,
                {department_id, group_type}
            )
            .then(res => {
                let extension_number_setup = [];
                let extensionNumberSelectData = res.data.cm51_extension_numbers.map(row => {
                    let labeldata = <div>
                                        {row.label}
                                        <Favi
                                            className="pull-right fa-fw fa-icon fa-icon-static"
                                            icon={row.type}
                                        />
                                    </div>;
                    this.state.extension_number.map(extension_data => {
                        if (row.value == extension_data) {
                            extension_number_setup.push(extension_data);
                        }
                    });

                    return {
                        search: row.label,
                        label: labeldata,
                        value: row.value
                    }

                });
                this.setState({extension_number: extension_number_setup});
                this.setState({extensionNumberSelect: extensionNumberSelectData});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };
    /**
     * フロアの選択肢をセット
     * @param {*}
     */
    departmentSelectChanged = ({company_id, department_prefix}) => {
        this.setState({
            floor_id: ""
        });

        if(this.floor_load_flag === true && department_prefix && company_id)
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
        }

        this.ascAxios('post', `Common/floorSelect`, {company_id, department_prefix})
        .then(res => {
            if(department_prefix)
            {
                this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                this.floor_load_flag = false;
            }
            else
            {
                this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
            }
            this.setState({
                floorSelect: res.data,
            });
        })
        .catch(err => {
            alert(this.getErrorString(err.response.data))
        })
    }

    onClickHandle = modalType => event => {
        let {
            inbound_group_info_id,
            company_id,
            department_id,
            floor_id,
            group_name,
            group_type,
            extension_number
        } = this.state;

        switch (modalType) {
            case "insert":
            case "copy":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        company_id,
                        department_id: floor_id || department_id,
                        group_name,
                        group_type,
                        extension_number,
                    })
                    .then(res => {
                        alert(
                            `${this.props.langText.Message.DataInsertSuccess}\r\n   ${this.props.langText.Body.GroupName}: ${res.data.group_name}\r\n   ${this.props.langText.Body.ExtensionNumberNum}: ${res.data.extension_count}`
                        );
                        this.reactTableRefresh();
                        this.setState({show: false});
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "update":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        company_id,
                        inbound_group_info_id,
                        department_id,
                        group_name,
                        group_type,
                        extension_number,
                    })
                    .then(res => {
                        alert(
                            this.props.langText.Message.DataUpdateSuccess
                        );
                        this.reactTableRefresh();
                        this.setState({show: false});
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "delete":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/delete`, {
                        inbound_group_info_id,
                        group_name,
                    })
                    .then(res => {
                        alert(
                            this.props.langText.Message.DataDeleteSuccess
                        );
                        this.reactTableRefresh();
                        this.setState({show: false});
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            default:
                alert(this.getErrorString({code: "modal"}));
        }
    };

    validationHandle = param => {
        let nowState = null;
        let {
            company_id,
            department_id,
            floor_id,
            group_name
        } = this.state;

        switch (param) {
            case "company_id":
            case "department_id":
            case "floor_id":
            case "group_name":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;

            case "insert":
            case "copy":
                nowState = (company_id && department_id && group_name);

                if (this.state.floor_flg) {
                    nowState = (nowState && floor_id)
                }
                break;
            case "update":
            case "delete":
                nowState = (company_id && department_id && group_name);
                break;

            default:

        }

        return nowState;
    };

    render() {
        return (
            <div className="ExternalNumber">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="inboundGroup-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <InboundGroupModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    validationHandle = {this.validationHandle}
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    />
            </div>
        );
    }
}
