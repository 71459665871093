import React, {Component} from "react"
import { Panel, Radio, Col, Glyphicon } from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css";
import moment from 'moment';
import 'moment/locale/ja';
import { ViewVoiceTextElement, ViewAudioControlElement} from "../Elements/AscElements/CallAnalysisElements";
import SetButtonWithSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import { ViewCallResultElement } from "../Elements/AscElements/CallResultElements";
moment.locale('ja');

export default class CallHistoryModal extends Component {

    getCallHistoryInfoItem = () => {
        let {
            state,
            langText,
            validationHandle,
            onRadioChange
        } = this.props;
        let itemArr = [];

        switch (state.modalType) {
            case "download":
                let search_date  = null;
                let downloadFileTypeRadio = state.downloadFileType;
                let downloadFileTypeRadioNomp3 = state.downloadFileTypeNoMp3;

                if (state.param) {
                    search_date = moment(state.param.original.start_datetime).format("YYYY-MM-DD")
                }
                
                if (typeof state.downloadFileType !="undefined") {
                    if (search_date < "2020-06-11") {
                        itemArr.push(
                            <InlineForm
                                key = "file_type"
                                controlId = "file_type"
                                label = {langText.Body.Download}
                                validationState = {validationHandle("file_type")}>
                                {downloadFileTypeRadioNomp3.map((row, key) =>
                                    <div key={key}>
                                        <Col xs={10} className="inboundType-text">
                                        <Radio
                                            name     = "download_type_radio"
                                            key      = {key}
                                            onChange = {e => onRadioChange(e, "file_type")}
                                            checked  = {row.value === state.file_type}
                                            value    = {row.value}
                                        >
                                            {row.label}
                                        </Radio>
                                        </Col>
                                    </div>
                                )}
                            </InlineForm>
                        );
                    } else {
                        itemArr.push(
                            <InlineForm
                                key = "file_type"
                                controlId = "file_type"
                                label = {langText.Body.Download}
                                validationState = {validationHandle("file_type")}>
                                {downloadFileTypeRadio.map((row, key) =>
                                    <div key={key}>
                                        <Col xs={10} className="inboundType-text">
                                        <Radio
                                            name     = "download_type_radio"
                                            key      = {key}
                                            onChange = {e => onRadioChange(e, "file_type")}
                                            checked  = {row.value === state.file_type}
                                            value    = {row.value}
                                        >
                                            {row.label}
                                        </Radio>
                                        </Col>
                                    </div>
                                )}
                            </InlineForm>
                        );
                    }
                }
                break;
            
            case "callRecordingPlay":
                break;
        
            default:
                break;
        }
        
        
        // itemArr.push(
        //     <InlineForm
        //         key = "file_type"
        //         controlId = "file_type"
        //         label = {langText.Body.Download}
        //         type = "select"
        //         options = {state.downloadFileType}
        //         onChange = {e => onSelectChange(e, "file_type")}
        //         validationState = {validationHandle("file_type")}
        //         value = {state.file_type}
        //         isClearable = {true}
        //         placeholder = {this.props.langText.SelectOption.Download_type}
        //         />
        // );
        return itemArr;
    }

    getModalInfo = () => {
        let {
            state,
            propSetState,
            langText,
            currentPermission,
            onPlayHandle,
            updateVoiceData,
            copyData,
            getCallRankList,
            updateCallResultRankData
        } = this.props;
        let info = null;

        switch (state.modalType) {
            case "download":
                info = (
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {langText.Body.DownloadFlag}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            {this.getCallHistoryInfoItem()}
                        </Panel.Body>
                    </Panel>
                );
                break;

            case "callRecordingPlay":
                info = (
                    <>
                        <Panel bsStyle="info">
                            <Panel.Heading>
                                <Panel.Title>
                                    {langText.Body.CallResultRank}
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body>
                                <ViewCallResultElement
                                    state={state}
                                    langText={langText}
                                    propSetState={propSetState}
                                    getCallRankList={getCallRankList}
                                    updateCallResultRankData={updateCallResultRankData}
                                    currentPermission={currentPermission}
                                />
                            </Panel.Body>
                        </Panel>
                        {
                            !state.isNotAnswered &&
                            <Panel bsStyle="info">
                                <Panel.Heading>
                                    <Panel.Title>
                                        {langText.Body.VoiceText}
                                        {state && state.selected && state.selected.text_data_json.length !==0 && 
                                        //音声テキストがあれば全体音声テキストコピーボタン・自動スクロールボタン表示
                                        <>
                                        <span className="pannel-btn-right">
                                            <SetButtonWithSpeechBubble
                                                className="control-button"
                                                bsSize="xsmall"
                                                onClick={e => {
                                                    let player = document.getElementById("audio-text-player");
                                                    let panel = document.getElementById("voice-text-panel");
                                                    propSetState({
                                                        auto_scroll_flag: !state.auto_scroll_flag,
                                                        message_box_data: [],
                                                        message_box_index: 0,
                                                    });
                                                    player.currentTime = 0;
                                                    panel.scrollTo(0,0)
                                                }}
                                                DisplayIcon="resize-vertical"
                                                speechBubble={langText.Body.AutoScroll}
                                            />
                                            <SetButtonWithSpeechBubble
                                                className="control-button"
                                                bsSize="xsmall"
                                                onClick={e => copyData("voice-all", state.selected.text_data_json)}
                                                disabled={state.voice_edit_flag.some(row => row)}
                                                DisplayIcon="copy"
                                                speechBubble={langText.Body.Copy}
                                            />
                                        </span>
                                        </>
                                        }
                                    </Panel.Title>
                                </Panel.Heading>
                                <ViewVoiceTextElement
                                    state={state}
                                    langText={langText}
                                    copyData={copyData}
                                    propSetState={propSetState}
                                    updateVoiceData={updateVoiceData}
                                    getKeywordData={function() {
                                        return "";
                                    }}
                                    currentPermission={currentPermission}
                                />
                                <Panel.Footer className="analysis-audio-control-footer">
                                    <ViewAudioControlElement
                                        state={state}
                                        langText={langText}
                                        copyData={copyData}
                                        propSetState={propSetState}
                                        onPlayHandle={onPlayHandle}
                                        getKeywordData={function() {
                                            return "";
                                        }}
                                        currentPermission={currentPermission}
                                    />
                                </Panel.Footer>
                            </Panel>
                        }
                    </>
                );
                break;
        
            default:
                break;
        }

        return info;
    }

    createCountDisplayMessage = () => {
        let message  = this.props.langText.Body.SearchResult;

        if (typeof(this.props.state.searchCount) === "number") {
            message += this.props.langText.Body.About + this.props.state.searchCount.toLocaleString() + this.props.langText.Body.Count;
        } else {
            // タイムアウト(エラー)
            message += this.props.langText.Message.TooManyResultsMessage;
        }
        return message;
    }

    render() {
        return (
            this.props.state.modalType === "callHistoryDownload" ?
                <AscModal
                    state = {this.props.state}
                    propSetState = {this.props.propSetState}
                    langText = {this.props.langText}
                    validationHandle = {this.props.validationHandle}
                    onClick = {this.props.onClick}>
                    <Panel bsStyle="info">
                        <Panel.Body>
                            <div className="message">
                                <p>{this.createCountDisplayMessage()}</p>
                                <p>{this.props.langText.Message.CallHistoryDownloadMessage[0]}</p>
                                <p>{this.props.langText.Message.CallHistoryDownloadMessage[1]}</p>
                                <p>{this.props.langText.Message.CallHistoryDownloadMessage[2]}</p>
                                <p className="text-danger">{this.props.langText.Message.DownloadDeadline}</p>
                            </div>
                            <Col sm={12} md={12} className="padding-left-0">
                                <Col sm={12} md={3} lg={2} className="padding-left-0 margin-top-05 width-auto">
                                    {this.props.langText.Body.FileName}
                                </Col>
                                <Col sm={12} md={9} lg={9} className="padding-left-0">
                                    <InlineForm
                                        key="fileName"
                                        controlId="fileName"
                                        type="text"
                                        value={this.props.state.fileName}
                                        onChange={e => this.props.onTextChange_Limit(e, "fileName", this.props.charaLimit.File_Name)}
                                        placeholder={this.props.state.fileName}
                                    />
                                </Col>
                            </Col>
                        </Panel.Body>
                    </Panel>
                </AscModal>
            :
                <AscModal
                    state = {this.props.state}
                    propSetState = {this.props.propSetState}
                    langText = {this.props.langText}
                    validationHandle = {this.props.validationHandle}
                    onClick = {this.props.onClick}
                >
                    {this.getModalInfo()}
                </AscModal>
        );
    }
}
