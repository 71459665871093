import React, { Component } from "react";
import { Panel, FormControl, Modal, Form, Button, Row, Col } from "react-bootstrap";
import "./ErrorMessageModal.css";


export default class ErrorMessageModal extends Component {
    render() {
        return (
            <Modal
                show={this.props.state.errorMessageModalShow}
                bsSize="large"
                aria-labelledby="contained-modal-title-lg">
                <Form horizontal={true}>
                    <Modal.Header>
                        <Modal.Title>
                            {this.props.langText.Body.ErrorTitle}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Panel bsStyle="info">
                            <Panel.Body>
                                <FormControl
                                    id="errTextarea"
                                    componentClass="textarea"
                                    rows="8"
                                    value={this.props.errText}
                                    readOnly={true} />
                            </Panel.Body>
                        </Panel>
                        <Row>
                            <Col className="panel-button pull-right button-margin-right">
                                <Button
                                    className="cancel-execute button-margin"
                                    onClick={e => this.props.errorModalClose()}
                                    variant='outline-secondary'>
                                    {this.props.langText.Body.Close}
                                </Button>
                                <Button
                                    className="button-margin copy-btn"
                                    onClick={e => {
                                        const myText = document.getElementById("errTextarea");
                                        myText.select();
                                        document.execCommand("copy");
                                    }}>
                                    {this.props.langText.Body.Copy}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Form>
            </Modal>
        )
    }
}
