import React from "react"
import { Button, Row, Col, Glyphicon, FormControl } from "react-bootstrap"
import AscComponent from "../../components/AscComponent"
import CommonTable from "../../containers/Elements/Table/CommonTable"
import KeywordGroupReadModal from "./KeywordGroupReadModal";
import KeywordGroupInsertModal from "./KeywordGroupInsertModal";
import KeywordGroupCopyModal from "./KeywordGroupCopyModal";
import KeywordGroupUpdateModal from "./KeywordGroupUpdateModal";
import KeywordGroupDeleteModal from "./KeywordGroupDeleteModal";
import * as GlobalConst from "../../components/AscConstants";

export default class KeywordGroup extends AscComponent {
    constructor(props) {
        super(props);
        this.modalShow = this.modalShow.bind(this);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            company_id: this.props.userInfo.company_id || 0,
            keywords: [{id : 1, value : ""}],
            companySelect: [],
            keywordTypeSelect: [],
            filtered: [],
            pages: null,
            loading: false,
            keyword_duplicate_flag: false,
            keyword_group_name_duplicate_flag: false,
            using_keyword_groups: [],
            using_keyword_groups_flag: false
        }
    }

    async componentDidMount() {
        try {
            await this.getKeywordTypeSelect();
        } catch (err) {
            console.error(err);
            alert(this.showErrorObjectMesssage(err.response.data));
        }
    }

    getColumnsData() {
        let displayDataList = [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                filterable: false,
                width: this.props.boardWidth.id,
            },{
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                filterable: false,
                sortable: false
            },{
                Header: this.props.langText.Body.GroupName,
                accessor: "keyword_group_name",
                Cell: data => {
                    return (    
                        <div title= {data.row._original.keyword_group_name}>
                            {data.row._original.keyword_group_name}
                        </div>
                    );
                },
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "keyword_group_name")} />
            },{
                Header: this.props.langText.Body.KeywordType,
                accessor: "keyword_type",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.medium,
                Cell: data => {
                    const keywordTypeSelect = this.state.keywordTypeSelect;
                    const keyword_type = data.row._original.keyword_type || "";
                    let return_label = "";
                    if (keywordTypeSelect && keywordTypeSelect.length > 0) {
                        return_label = keywordTypeSelect.find(row => row.value === keyword_type).label;
                    }
                    return return_label;
                }
            },{
                Header: this.props.langText.Body.Memo,
                accessor: "memo",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
            },{
                Header: this.props.langText.Body.KeywordCount,
                accessor: "ct80_keyword",
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.small,
                Cell: data => {
                    let keyword_arr = data.row._original.ct80_keyword || [];
                    return (keyword_arr.length > 0 ? keyword_arr.length : 0) + this.props.langText.Body.Count;
                }
            },{
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];
                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key="edit-btn"
                                bsSize="xsmall"
                                onClick={this.modalShow("update", data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="pencil"/>
                            </Button>
                        )
                    } else {
                        rowData.push(
                            <Button
                                key="read-btn"
                                bsSize="xsmall"
                                className="control-button"
                                onClick={this.modalShow("read", data.row._original)}
                            >
                                <Glyphicon glyph="eye-open"/>
                            </Button>
                        );
                    }
                    if(this.props.currentPermission.create){
                        rowData.push(
                            <Button
                                key="create-btn"
                                bsSize="xsmall"
                                onClick={this.modalShow("copy", data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="copy"/>
                            </Button>
                        );
                    }
                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key="delete-btn"
                                bsSize="xsmall"
                                onClick={this.modalShow("delete", data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="minus" />
                            </Button>
                        );
                    }
                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    )
                }
            }
        ];

        return displayDataList;
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();
        let setData = {
            id: param && param.id
                ? param.id
                : "",
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : "",
            company_name: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : "",
            keyword_group_name: param && param.keyword_group_name
                ? param.keyword_group_name
                : "",
            keyword_type: param && param.keyword_type
                ? param.keyword_type
                : "",
            memo: param && param.memo
                ? param.memo
                : "",
            keywords: param && param.ct80_keyword && param.ct80_keyword.length > 0
                ? param.ct80_keyword.map(row => {return {id: row.id, value: row.keyword}})
                : [{id : 1, value : ""}]
        }

        switch (modalType) {
            case "insert":
            case "update":
            case "copy":
                if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) await this.getCompanySelect();
                if (modalType === "copy") setData.keyword_group_name = "";
                break;
            case "delete":
                try {
                    let checkKeywordGroupsUsing = await this.ascAxios("post", 
                        `${this.reactContainerPath}/checkKeywordGroupsUsing`, 
                        {ct81_id : param.id}
                    );
                    setData.using_keyword_groups_flag = false;
                    setData.using_keyword_groups = [];
                    if(checkKeywordGroupsUsing.data.length > 0){
                        checkKeywordGroupsUsing.data.forEach(element => {
                            let external_info = element.cm61_external_numbers.memo
                                ? this.sprintf(this.props.langText.Message.AudioDeleteError_External,
                                    element.cm61_external_numbers.memo + " (" + element.cm61_external_numbers.display_number + ")")
                                : this.sprintf(this.props.langText.Message.AudioDeleteError_External,
                                    element.cm61_external_numbers.display_number);
                            setData.using_keyword_groups.push(<div>{external_info}</div>);
                        });
                        setData.using_keyword_groups_flag = true;
                    }
                } catch (err) {
                    console.error(err);
                    this.showErrorObjectMesssage(err, "GetDataError");
                }
                break;
            default:
                break;
        }

        this.setState(setData);
        this.setState({show: true, modalType});
    }

    /**
     * 会社一覧取得
     */
    getCompanySelect = async () => {
        try{
            const res = await this.ascAxios("post", `Common/companySelect`, {
                container: this.reactContainerPath
            });
            this.setState({
                companySelect: res.data
            });
        } catch (err) {
            console.error(err);
            alert(this.getErrorString(err.response.data));
        }
    }

    /**
     * キーワード種別取得
     */
    getKeywordTypeSelect = async () => {
        try{
            const res = await this.ascAxios("post", `Common/getKeywordTypes`, {
                parameterId: "KEYWORD_TYPE"
            });
            this.setState({
                keywordTypeSelect: res.data
            });
        } catch (err) {
            console.error(err);
            alert(this.getErrorString(err.response.data));
        }
    }

    /**
     * キーワードグループ名重複チェック
     */
    checkDuplicateKeywordGroupName = async () => {
        if (this.state.keyword_group_name === "") {
            //未入力の場合はチェックしない
            this.setState({keyword_group_name_duplicate_flag: false});
            return;
        }
        try{
            let res = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/checkDuplicateKeywordGroupName`,{
                    keyword_group_name: this.state.keyword_group_name,
                    company_id: this.state.company_id
                }
            );
            this.setState({keyword_group_name_duplicate_flag: res.data.isDuplicate});
        } catch (err) {
            this.setState({keyword_group_name: ""});
            this.showErrorObjectMesssage(err);
        }
    }

    /**
     * キーワード文字数制限&重複チェック
     */
    onMultiValueFormTextChange = (param, index, target, content) => {
        if(content.length <= this.props.charaLimit.KeywordGroup_Keyword)
        {
            let keywordsArr = this.state[param];

            if (target === "tag") {
                keywordsArr[index].tag = content;
            } else if (target === "value") {
                keywordsArr[index].value = content;
            }

            let keywordsNoDelete = keywordsArr.filter(row => !(row.value && row.del_flag === "Y"));
            const set = new Set(keywordsNoDelete.map(row => row.value));
            this.setState({
                [param]: keywordsArr,
                keyword_duplicate_flag: keywordsNoDelete.length !== set.size
            });
        }
    }

    /**
     * キーワード入力数制限
     */
    onMultiValueFormSizeChange = (param, handle, index) => {
        let keywordsArr = this.state[param];

        if (handle === "plus") {
            keywordsArr.push({id:null, tag:"", value:""});
        } else if (handle === "minus") {
            let count = 0;
            keywordsArr.forEach(row => {
                if (!row.del_flag || row.del_flag !== "Y") {
                    count++;
                }
            });
            if (count > 1) {
                keywordsArr[index].del_flag = "Y";
            }
        }

        let keywordsNoDelete = keywordsArr.filter(row => !(row.value && row.del_flag === "Y"));
        const set = new Set(keywordsNoDelete.map(row => row.value));
        this.setState({
            [param]: keywordsArr,
            keyword_duplicate_flag: keywordsNoDelete.length !== set.size
        });
    }

    /**
     * 各画面先でのボタン押下時の動作内容
     */
    onClickHandle = modalType => async event => {
        let {
            id,
            company_id,
            keyword_group_name,
            keyword_type,
            memo,
            keywords
        } = this.state;
        try {
            switch (modalType) {
                case "insert":
                case "copy":
                        this.blockUI();
                        await this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                            company_id,
                            keyword_group_name,
                            keyword_type,
                            memo,
                            keywords
                        });
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                    break;
                case "update":
                    this.blockUI();
                        await this.ascAxios("post", `${this.reactContainerPath}/update`, {
                            ct81_id: id,
                            company_id,
                            keyword_group_name,
                            keyword_type,
                            memo,
                            keywords
                        });
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    break;
                case "delete":
                    if (window.confirm(`${this.props.langText.Message.User_Delete_Check}`)) {
                        this.blockUI();
                        await this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                            ct81_id: id
                        });
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    }
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error(error);
            this.showErrorObjectMesssage(error);
        }
        this.setState({show: false});
    };

    validationHandle = param => {
        let {
            company_id,
            keyword_group_name,
            keyword_type,
            keywords,
            keyword_group_name_duplicate_flag,
            keyword_duplicate_flag,
            using_keyword_groups_flag          
        } = this.state;
        let validation_flag = true;

        switch (param) {
            case "keyword_group_name":
                validation_flag = this.state[param] && !keyword_group_name_duplicate_flag ? true : false;
                break;
            case "company_id":
            case "keyword_type":
                validation_flag = this.state[param] ? true : false;
                break;
            case "keywords":
                validation_flag = this.state[param].length > 0 && !this.state[param].find(row => 
                    row.del_flag !== "Y" && !row.value.replace(/\s/g, "").length) 
                    && !keyword_duplicate_flag
                break;
            case "insert":
            case "update":
            case "copy":
                validation_flag = (
                    company_id
                    && keyword_group_name
                    && !keyword_group_name_duplicate_flag
                    && keyword_type
                    && (keywords.length > 0 && !keywords.find(row => 
                        row.del_flag !== "Y" && !row.value.replace(/\s/g, "").length) 
                        && !keyword_duplicate_flag)
                );
                break;
            case "delete":
                validation_flag = !using_keyword_groups_flag;
                break;
            default:
                validation_flag = true;
                break;
        }

        return validation_flag;
    };

    /**
     * modalTypeによる分岐処理
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "read":
                return (
                    <KeywordGroupReadModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        validationHandle={this.validationHandle}
                    />
                );
            case "insert":
                return (
                    <KeywordGroupInsertModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        onTextChange_Limit={this.onTextChange_Limit}
                        validationHandle={this.validationHandle}
                        onSelectChange={this.onSelectChange}
                        currentPermission={this.props.currentPermission}
                        onMultiValueFormTextChange={this.onMultiValueFormTextChange}
                        onMultiValueFormSizeChange={this.onMultiValueFormSizeChange}
                        checkDuplicateKeywordGroupName={this.checkDuplicateKeywordGroupName}
                    />
                );
            case "copy":
                return (
                    <KeywordGroupCopyModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        onTextChange_Limit={this.onTextChange_Limit}
                        validationHandle={this.validationHandle}
                        onSelectChange={this.onSelectChange}
                        currentPermission={this.props.currentPermission}
                        onMultiValueFormTextChange={this.onMultiValueFormTextChange}
                        onMultiValueFormSizeChange={this.onMultiValueFormSizeChange}
                        checkDuplicateKeywordGroupName={this.checkDuplicateKeywordGroupName}
                    />
                )
            case "update":
                return (
                    <KeywordGroupUpdateModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        onTextChange_Limit={this.onTextChange_Limit}
                        validationHandle={this.validationHandle}
                        onSelectChange={this.onSelectChange}
                        currentPermission={this.props.currentPermission}
                        onMultiValueFormTextChange={this.onMultiValueFormTextChange}
                        onMultiValueFormSizeChange={this.onMultiValueFormSizeChange}
                        checkDuplicateKeywordGroupName={this.checkDuplicateKeywordGroupName}
                    />
                );
            case "delete":
                return (
                    <KeywordGroupDeleteModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        validationHandle={this.validationHandle}
                    />
                );
            default:
                break;
        }
    }

    render() {
        return (
            <div className="KeywordGroup">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="keywordGroup-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{ id: "id", desc: true }]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        )
    }
}
