import React from "react";
import { Panel } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import { DeleteAndReadKeywordGroup } from "../Elements/AscElements/KeywordGroupElements";
import { ViewCompanyElement } from "../Elements/AscElements/CompanyElements";
import AscComponent from "../../components/AscComponent";

/**
 * キーワードグループ閲覧画面
 */
export default class KeywordGroupReadModal extends AscComponent {
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.KeywordGroupInfo}
                        </Panel.Title>
                    </Panel.Heading>
                    
                    <Panel.Body>
                        <ViewCompanyElement
                            state={this.props.state}
                            langText={this.props.langText}>
                        </ViewCompanyElement>
                        <DeleteAndReadKeywordGroup
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
