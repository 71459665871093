import React from "react";
import {Panel, FormControl, Col} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import AscComponent from "../../components/AscComponent";

export default class ExtensionNumberCheckModal extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            company_name: "",
            department_name: "",
            floor_name: "",
            extensions: []
        };
    }

    componentDidMount = () => {
        const {
            company_name,
            companySelect,
            company_id,
            department_name,
            departmentSelect,
            department_id,
            floor_name,
            floor_flg,
            floorSelect,
            floor_id
        } = this.props.propsState;

        this.blockUI();
        let company = "",
            department = "",
            floor = "";

        if (!company_name) company = this.dataSetting(companySelect, company_id);
        if (!department_name) department = this.dataSetting(departmentSelect, department_id);
        if (floor_flg && !floor_name) floor = this.dataSetting(floorSelect, floor_id);

        this.setState({
            company_name: company ? company : company_name,
            department_name: department ? department : department_name,
            floor_name: floor ? floor : floor_name,
        }, () => {
            this.unblockUI();
        });
    }

    dataSetting = (select, id) => {
        let name = select.find(row => row.value === id);

        return name ? name.label : null;
    }

    validationHandle = () => {
        return true;
    }

    render() {
        const {
            state,
            propSetState,
            langText,
            propsState,
            onClick,
            omniphoneLoginStatusSearch,
            reactContainerPath
        } = this.props;

        return (
            <AscModal
                key="extension-multi-delete-asc-modal"
                state={state}
                propSetState={propSetState}
                langText={langText}
                validationHandle={this.validationHandle}
                customMsg={langText.Body.DeleteTitle}
                customFunc={async () => {
                    if (window.confirm(langText.Message.User_Delete_Check)) {
                        try {
                            this.blockUI();
                            let extension_numbers = propsState.extension_ids.map(row => {
                                let extension = propsState.extensionIdSelect.find(info => (info.value === Number(row) & info.type === "android"));
                                return extension ? extension.extension_number : null;
                            }).filter(extension_number => extension_number !== null);

                            let login_data = extension_numbers.length > 0 ? await omniphoneLoginStatusSearch(extension_numbers) : null;
                            this.blockUI();
                            const result = await this.ascAxios("post", `${reactContainerPath}/multiDelete`, {
                                extension_ids: propsState.extension_ids,
                                department_id: propsState.floor_flg ? propsState.floor_id : propsState.department_id,
                                omniphone_login_data: login_data
                            });
                            
                            if (result) {
                                alert(this.sprintf(
                                    langText.Message.MultiDeleteSuccess,
                                    propsState.extension_ids.length
                                ));
                            }
                        } catch (err) {
                            console.error(err);
                            alert(this.getErrorString(err.response.data));
                        }
                        onClick();
                    }
                }}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {langText.Body.CheckDelete}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <div>
                            <p className="preview_add">
                                {langText.Message.Preview_delete}
                            </p>
                            <p>
                                {langText.Message.Preview_delete_message1}
                            </p>
                            <p className="preview_message">
                                {langText.Message.Preview_delete_message2}
                            </p>
                            <hr color="#999"/>
                        </div>

                        <Col sm={12}>
                            <ASFieldGroup
                                key="company_name"
                                label={langText.Body.CompanyName}>
                                <FormControl.Static>{this.state.company_name}</FormControl.Static>
                            </ASFieldGroup>
                            <ASFieldGroup
                                key="department_name"
                                label={langText.Body.DepartmentName}>
                                <FormControl.Static>{this.state.department_name}</FormControl.Static>
                            </ASFieldGroup>
                            {
                                propsState.floor_flg &&
                                    <ASFieldGroup
                                        key="floor_name"
                                        label={langText.Body.FloorName}>
                                        <FormControl.Static>{this.state.floor_name}</FormControl.Static>
                                    </ASFieldGroup>
                            }
                            <ASFieldGroup
                                key="extensions_data"
                                label={this.props.langText.Body.ExtensionNumber}>
                                <Col>{
                                    propsState.extension_ids.map((id, key) => {
                                        let extension_info = propsState.extensionIdSelect.find(row => row.value === Number(id));
                                        return <Col key={key} className="multi_delete_extension">{extension_info ? extension_info.label : ""}</Col>;
                                })}
                                </Col>
                            </ASFieldGroup>
                        </Col>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
