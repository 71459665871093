import React from "react";
import { Button, Row, Col, Glyphicon} from 'react-bootstrap'
import ColumnFixTable from '../../Elements/Table/ColumnFixTable'
import AscComponent from "../../../components/AscComponent";
import SetBreadCrumb from "../../Elements/AscElements/SetBreadCrumb";
import 'react-datepicker/dist/react-datepicker.css';
import PredictvieCallStatisticsModal from "./PredictvieCallStatisticsModal";
import SetButtonWithSpeechBubble from "../../Elements/AscElements/SetButtonWithSpeechBubble";


export default class Histories extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            pageSize: null,
            show: false,
            modalType: null,
            loading: false,
            list: true,
            filtered: [
                {
                    id: "cm80_id",
                    value: this.props.location.state && this.props.location.state.displayData 
                        ? this.props.location.state.displayData.id 
                        : ""
                }
            ],
            hidden_filtered: [
                {
                    id: "cm80_id",
                    value: this.props.location.state && this.props.location.state.displayData
                        ? this.props.location.state.displayData.id 
                        : ""
                }
            ]
        };

        this.table = React.createRef();
    }

    getColumnsData = () => {
        const columnsDataList = [
            {
                Header: this.props.langText.Body.AutoCallCallStartTime,
                accessor: "execution_time",
                filterable: false,
                sortable: false,
                fixed: "left",
            },
            {
                Header: this.props.langText.Body.AutoCallTotalOutboundCnt,
                accessor: "call_count",
                filterable: false,
                sortable: false,
                fixed: "left",
            },
            {
                Header: this.props.langText.Body.AutoCallConnectCount,
                accessor: "connect_count_rate",
                filterable: false,
                sortable: false,
                fixed: "left",
            },
            {
                Header: this.props.langText.Body.AutoCallGiveUp,
                accessor: "disconnect_count_rate",
                filterable: false,
                sortable: false,
                fixed: "left",
            },
            {
                Header: this.props.langText.Body.List,
                accessor: "cm76_list_name",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.AutoCalledCount,
                accessor: "list_expense",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.ExternalNumber,
                accessor: "display_number_info",
                sortable: false,
                filterable: false,
            },
            {
                Header: this.props.langText.Body.AutoCallOutboundRate,
                accessor: "call_leverage",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.AutoCallOutboundTimeout,
                accessor: "ringing_timeout_sec",
                filterable: false,
                sortable: false,
                Cell: data => {
                    return data.value  ? `${data.value}秒` : 0;
                }
            },
            // {
            //     Header: this.props.langText.Body.AutoCallReOutboundTime,
            //     accessor: "recall_interval_hour",
            //     filterable: false,
            //     sortable: false,
            //     Cell: data => {
            //         return data.value  ? `${data.value}時間` : 0;
            //     }
            // },
            // {
            //     Header: this.props.langText.Body.AutoCallReOutboundMax,
            //     accessor: "recall_count",
            //     filterable: false,
            //     sortable: false,
            // },
            {
                Header: this.props.langText.Body.AutoCallConnectedTimeout,
                accessor: 'connect_wait_timeout_sec',
                filterable: false,
                sortable: false,
                Cell: data => {
                    return data.value  ? `${data.value}秒` : 0;
                }
            },
            {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                fixed: "right",
                Cell: data => {
                    let rowData = [];
                    if (data.row._original.call_count > 0) {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={2}
                                bsSize="xsmall"
                                onClick={() => {
                                    this.props.historyPush({
                                        pathname: "./HistoryDetail",
                                        state: { displayData: data.row._original, historySummaryData: this.props.location.state.displayData }
                                    });
                                }}
                                className="control-button"
                                DisplayIcon="list"
                                speechBubble={this.props.langText.Body.PredictiveCallHistoryDetail}
                            />
                        );
                    }
                    rowData.push(
                        <SetButtonWithSpeechBubble
                                key={3}
                                bsSize="xsmall"
                                onClick={this.modalShow("historyStatistics", data.row._original)}
                                className="control-button"
                                DisplayIcon="stats"
                                speechBubble={this.props.langText.Body.HistoryStatisticsTitle}
                            />
                    );
                    return (
                        <Row className='text-center'>
                            {rowData}
                        </Row>
                    )
                }
            }
        ];

        return columnsDataList;
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();

        let setData = {
            task_name: this.props.location.state && this.props.location.state.displayData
                ? this.props.location.state.displayData.task_name 
                : "",
            list_name: param && param.cm76_list_name
                ? param.cm76_list_name
                : "",
            call_count: param && param.call_count
                ? param.call_count
                : 0,
            connect_count: param && param.connect_count
                ? param.connect_count
                : 0,
            disconnect_count: param && param.disconnect_count
                ? param.disconnect_count
                : 0,
            connect_rate: param && param.connect_rate
                ? param.connect_rate
                : "",
            disconnect_rate: param && param.disconnect_rate
                ? param.disconnect_rate
                : "",
            call_time: param && param.call_time
                ? param.call_time
                : "00:00:00",
            avg_call_time: param && param.avg_call_time
                ? this.secToTime(param.avg_call_time)
                : "00:00:00",
            avg_connect_time: param && param.avg_connect_time
                ? this.secToTime(param.avg_connect_time)
                : "00:00:00",
            avg_disconnect_time: param && param.avg_disconnect_time
                ? this.secToTime(param.avg_disconnect_time)
                : "00:00:00",
            avg_hook_time: param && param.avg_hook_time
                ? this.secToTime(param.avg_hook_time)
                : "00:00:00",
            modalType,
            show: true
        }

        this.setState(setData);
    }

    onClickHandle = (modalType) => {
        switch (modalType) {
            default:
                break;
        }
    }

    validationHandle = (param) => {
        let nowState = null;

        switch (param) {
            default:
                break;
        }
        return nowState;
    }

    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/AutoCall", name: this.props.langText.Body.AutoCall },
                        { link: "/AutoCall/PredictiveCall", name: this.props.langText.Body.PredictiveCall },
                        { name: `${this.props.langText.Body.PredictiveCallHistory}（${this.props.location.state.displayData.task_name}）` }
                    ]}
                />
                <Row>
                    <Col xs={12} md={12}>
                        <ColumnFixTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            defaultSorted={[{id: "ct77_id", desc: false}]}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>

                <PredictvieCallStatisticsModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    onClick = {this.onClickHandle}
                    validationHandle = {this.validationHandle}
                    secToTime = {this.secToTime}
                />
            </React.Fragment>
        );
    }
}
