import React from "react";
import { Button, Row, Col} from 'react-bootstrap'
import ColumnFixTable from '../../containers/Elements/Table/ColumnFixTable'
import AscComponent from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import { SelectCompanyElement } from "../Elements/AscElements/CompanyElements";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import { MultiSelect} from "react-multi-select-component";
import { CSVLink } from "react-csv";
import * as GlobalConst from '../../components/AscConstants';


export default class ChUsedSummary extends AscComponent {
    constructor(props) {
        super(props);
        const now = moment();
        const startDate = moment(now).startOf("months");
        const endDate =  moment(now).endOf("months");
        const dateColumns = this.getDisplayColumns(startDate, endDate);

        this.state = {
            columns: this.getColumnsData(dateColumns, startDate),
            data: [],
            filtered: [
                { id: "company_id", value: this.props.userInfo.company_id || 0 },
                { id: "date", value: [startDate, endDate] },
                { id: "columns", value: dateColumns},
            ],
            pages: null,
            pageSize: null,
            show: false,
            loading: false,
            startDate: startDate,
            endDate: endDate,
            company_id: this.props.userInfo.company_id || 0,
            companyName: this.props.userInfo.company_name,
            companySelect: [],
            externalArr: [],
            externalSelected: [],
            validationMessage: null,
            lineTypeSelect: [],
            lineTypeSelected: [],
        };
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            this.setCommonCompanySelect("/Summary");
        } else {
            this.getAllExternal(this.props.userInfo.company_id);
        }

        this.getLineTypesSelect();

        this.csvLink = React.createRef();
        this.table = React.createRef();
    }

    getColumnsData(dateColumns, startDate) {
        const date = moment(startDate).add(-1, "days");
        const displayDataList = [
            {
                Header: this.props.langText.Body.LineType,
                width: this.props.boardWidth.medium,
                accessor: "line_type",
                filterable: false,
                sortable: false,
                fixed: "left",
                Cell: data => {
                    const lineTypeObj = this.state.lineTypeSelect.find(
                        (lineType) => data.row.line_type === lineType.value
                    );
                    const lineType = lineTypeObj ? lineTypeObj.label : data.row.line_type;
                    return data.row._original.isTotalRow
                        ? lineType + this.props.langText.Body.SummaryTotal
                        : lineType;
                }
            },
            {
                Header: this.props.langText.Body.ExternalNumber,
                width: this.props.boardWidth.small,
                accessor: "external_number",
                filterable: false,
                sortable: false,
                fixed: "left"
            },
            {
                Header: this.props.langText.Body.ExternalNumberMemo,
                width: this.props.boardWidth.xxlarge,
                accessor: "external_name",
                filterable: false,
                sortable: false,
                fixed: "left"
            },
        ];

        dateColumns.forEach(column => {
            displayDataList.push(
                {
                    Header: date.add(1, "days").format("MM/DD"),
                    width: this.props.boardWidth.xsmall,
                    accessor: column,
                    filterable: false,
                    sortable: false,
                    className: "text-align",
                    Cell: data => {
                        return data.row[column]
                            ? parseInt(data.row[column]).toLocaleString()
                            : 0;
                    }
                }
            );
        });

        displayDataList.push(
            {
                Header: this.props.langText.Body.Max,
                accessor: "max",
                filterable: false,
                sortable: false,
                fixed: "right",
                className: "text-align",
                Cell: data => {
                   return data.row.max && parseInt(data.row.max).toLocaleString();
                }
            },
            {
                Header: this.props.langText.Body.Average,
                accessor: "average",
                filterable: false,
                sortable: false,
                fixed: "right",
                className: "text-align",
                Cell: data => {
                   return (Math.floor(data.row.average * 10) / 10).toFixed(1);
                }
            },
        );
        return displayDataList;
    }

    /**
     * 会社一覧取得
     */
    setCommonCompanySelect = async (reactContainerPath) => {
        try {
            await super.setCommonCompanySelect(reactContainerPath);
            this.getAllExternal(this.props.userInfo.company_id);
            this.setState({
                company_id: this.props.userInfo.company_id,
            });
        } catch (err) {
            alert(this.getErrorString(err));
        }
    }

    /**
     * 回線種別取得
     */
    getLineTypesSelect = async () => {
        try {
            let result = await this.ascAxios("post", `Common/getLineTypes`, {
                parameterId: "LINE_TYPE"
            });
            this.setState({ lineTypeSelect: result.data, lineTypeSelected: result.data });
        } catch (err) {
            console.error(err);
            alert(this.getErrorString(err.response.data));
        }
    }

    onChangeDate = stateKey => event => {
        if (event == null) return false;
        let isOneMonth = false;
        let days = null;
        let message = null;

        //期間チェック
        switch (stateKey) {
            case "startDate":
                const startDay = moment(this.state.endDate).subtract(1, 'months').format('YYYY/MM/DD');
                if (event.isAfter(startDay)) isOneMonth = true;
                days = this.calcDateDays(event, this.state.endDate);
                this.setState({[stateKey]: event.startOf("days")});
                break;
            case "endDate":
                const endDay = moment(this.state.startDate).add(1, 'months').format('YYYY/MM/DD');
                if (event.isBefore(endDay)) isOneMonth = true;
                days = this.calcDateDays(this.state.startDate, event);
                this.setState({[stateKey]: event.endOf("days")});
                break;
            default:
                break;
        }
        if (!isOneMonth) {
            message = this.props.langText.Message.GreaterThanOneMonthError;
        } else if (days < 0) {
            message = this.props.langText.Message.DateSpanError; 
        }

        this.setState({validationMessage: message});
    };

    onSelectChange = stateKey => event => {
        super.onSelectChange(event, stateKey);
        this.getAllExternal(event.value);
        const message = event.value ? null : this.props.langText.Message.CompanySelectError;
        this.setState({
            externalArr: [],
            externalSelected: [],
            companyName: event.label,
            validationMessage: message || this.state.validationMessage,
        });
    }

    getAllExternal = async (cm12_id, lineType = []) => {
        try {
            const external = await this.ascAxios("post", `Summary/getExternal`, {
                container: "/Summary",
                cm12_id: cm12_id,
                //全選択されている場合は検索条件に含めない
                lineType: lineType.length === this.state.lineTypeSelect.length
                    ? []
                    : lineType,
            });
            this.setState({
                externalArr: external.data,
                externalSelected: external.data,
            });
            return external.data;
        } catch (err) {
            alert(this.getErrorString(err));
        }
    };

    onMultiSelectChange = stateKey => event => {
        this.setState({[stateKey]: event})
        if (stateKey === "lineTypeSelected") {
            this.getAllExternal(
                this.state.company_id,
                event.map((select) => select.value)
            );
        }
        
    }

    getDisplayColumns = (start, end) => {
        const startDate = moment(start).add(-1, "days");
        const endDate = moment(end);
        const dateCount = this.calcDateDays(startDate, endDate);
        const columns = [];
        // 取得する日付カラムを生成
        for (let i = 0; i < dateCount; i++) {
            columns.push("day_" + startDate.add(1, "days").format("DD"));
        }
        return columns;
    }

    /**
     *  検索ボタン押下処理
     */
    onSearch = async (isDownload = false) => {
        try {
            this.blockUI();
            const dateColumns = this.getDisplayColumns(this.state.startDate, this.state.endDate);
            const filter = [
                { id: "lineType", value: this.state.lineTypeSelected.map(lineType => lineType.value) },
                { id: "date", value: [this.state.startDate, this.state.endDate] },
                { id: "columns", value: dateColumns },
            ];

            if (this.state.company_id) {
                filter.push({ id: "company_id", value: this.state.company_id });
            }

            if (this.state.externalSelected.length === this.state.externalArr.length) {
                //全選択の場合は検索条件に含めない
                filter.push({id: "externalNumber", value: []});
            } else {
                filter.push({
                    id: "externalNumber",
                    value: this.state.externalSelected.map(external => external.value),
                });
            }

            this.setState({
                filtered: filter,
                columns: this.getColumnsData(dateColumns, this.state.startDate),
            }, () => {
                this.table.current.state.page = 0;
            });

            if (isDownload) {
                if (await this.existsDownloadData(filter)) {
                    this.csvLink.current.link.click();
                } else {
                    throw new Error("Download_Failed");
                }
            }

        } catch(err) {
            this.showErrorObjectMesssage(err);
        } finally {
            this.unblockUI();
        }
    }

    /**
     * ダウンロードデータの取得
     */
    existsDownloadData = async (filter) => {
        try {
            const dateColumns = this.getDisplayColumns(this.state.startDate, this.state.endDate);
            const downloadData = await this.ascAxios("post", `Summary/ChUsedSummary/board`, {
                filtered: filter,
                isDownload: true,
                sorted: [{
                    id: "line_type",
                    order: this.state.lineTypeSelect.map(
                        (line) => line.value
                    ),
                }]
            });

            if (downloadData.data.rows && downloadData.data.rows.length > 0) {
                downloadData.data.rows.forEach((row)=> {
                    let daysKey = Object.keys(row).filter(row => row.includes("day_"));
                    dateColumns.forEach(date => {
                        let sameDay = daysKey.find(daykey => daykey == date);
                        if(!sameDay){
                            row[date] = 0;
                        }
                    });
                }); 
            }

            this.setState({
                downloadData: downloadData.data.rows,
            });
            if (downloadData.data.rows && downloadData.data.rows.length > 0) {
                return true;
            } else {
                return false;
            }
        } catch(err) {
            this.showErrorObjectMesssage(err);
        } finally {
            this.unblockUI();
        }
    }

    /**
     * ダウンロードデータ(header)を作成
     */
    createHeaderData = () => {
        const header = [];

        if (this.state.columns) {
            this.state.columns.forEach(data => {
                header.push({label: data.Header, key: data.accessor})
            })
        }

        return header;
    }

    /**
     *ダウンロードデータ(detail)を作成
     */
    createDownloadData = () => {
        const csvData = [];

        //データの作成
        if (this.state.downloadData) {
            this.state.downloadData.forEach(obj => {
                for (const property in obj) {
                    if (property === "line_type") {
                        const lineType = this.state.lineTypeSelect.find(
                            (lineType) => obj.line_type === lineType.value
                        );
                        obj[property] = lineType ? lineType.label : obj.line_type;
                        if (obj.hasOwnProperty("isTotalRow")) {
                            obj[property] += this.props.langText.Body.SummaryTotal;
                        }      
                    } else if (
                        property.indexOf("day_") === 0 ||
                        property === "max"
                    ) {
                        obj[property] = obj[property].toLocaleString();
                    } else if (property === "average") {
                        obj[property] = (Math.floor(obj[property] * 10) / 10).toLocaleString();
                    }
                }

                csvData.push(obj);
            }); 
        }
        return csvData;
    }


    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/Summary", name: this.props.langText.Body.Summary },
                        { name: this.props.langText.Body.ChUsedSummary },
                    ]}
                />
                {this.state.validationMessage && (
                    <label className="search-message">
                        <p>{this.state.validationMessage}</p>
                    </label>
                )}
                <Row>
                    <Col>
                        <Col xs={1} className="margin-top-1">
                            {this.props.langText.Body.CompanyName}
                        </Col>
                        {/* OEM以上は会社選択表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col xs={2} md={2} className="margin-top-05">
                                <SelectCompanyElement
                                    state={this.state}
                                    colWidth = {8.5}
                                    langText={this.props.langText}
                                    onSelectChange={this.onSelectChange("company_id")}
                                    labelDisabled={true}
                                ></SelectCompanyElement>
                            </Col>
                            : 
                            <Col xs={2} md={2} className="margin-top-1">
                                {this.props.userInfo.company_name}
                            </Col>
                        }
                    </Col>
                    <Col>
                        <Col md={1} className="margin-top-1">
                            {this.props.langText.Body.LineType}
                        </Col>
                        <Col xs={2} md={2} className="margin-top-05">
                            <MultiSelect
                                value={this.state.lineTypeSelected}
                                onChange={this.onMultiSelectChange(
                                    "lineTypeSelected"
                                )}
                                options={this.state.lineTypeSelect}
                                overrideStrings={{
                                    selectSomeItems:
                                        this.props.langText.SelectOption.Placeholder,
                                    allItemsAreSelected:
                                        this.props.langText.MultiSelect.AllSelected,
                                    selectAll:
                                        this.props.langText.MultiSelect.AllSelect,
                                    search: this.props.langText.Body.Search,
                                }}
                            />
                        </Col>
                    </Col>
                    <Col>
                        <Col md={1} className="margin-top-1">
                            {this.props.langText.Body.ExternalNumber}
                        </Col>
                        <Col xs={3} md={3} className="margin-top-05">
                            <MultiSelect
                                value={this.state.externalSelected}
                                onChange={this.onMultiSelectChange(
                                    "externalSelected"
                                )}
                                options={this.state.externalArr}
                                overrideStrings={{
                                    selectSomeItems:
                                        this.props.langText.SelectOption.Placeholder,
                                    allItemsAreSelected:
                                        this.props.langText.MultiSelect.AllSelected,
                                    selectAll:
                                        this.props.langText.MultiSelect.AllSelect,
                                    search: this.props.langText.Body.Search,
                                }}
                            />
                        </Col>
                    </Col>
                </Row>
                <Row className="margin-top-1">
                    <Col>
                        <Col md={1} className="margin-top-05">
                            {this.props.langText.Body.CustomerBoardStart}
                        </Col>
                        <Col xs={2} md={2}>
                            <DatePicker
                                onChange={this.onChangeDate("startDate")}
                                selected={this.state.startDate}
                                dateFormat="YYYY-MM-DD"
                                className="form-control"
                                todayButton={this.props.langText.Body.Today}
                            />
                        </Col>
                        <Col md={1} className="margin-top-05">
                            {this.props.langText.Body.CustomerBoardEnd}
                        </Col>
                        <Col xs={2} md={2}>
                            <DatePicker
                                onChange={this.onChangeDate("endDate")}
                                selected={this.state.endDate}
                                dateFormat="YYYY-MM-DD"
                                className="form-control"
                                todayButton={this.props.langText.Body.Today}
                            />
                        </Col>
                        <Col md={1}>
                            <Button
                                id="search"
                                className="searchBtn"
                                bsStyle="primary"
                                bsSize="sm"
                                onClick={() => this.onSearch(false)}
                                disabled={!!this.state.validationMessage}
                            >
                                {this.props.langText.Body.Search}
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <Button
                            className="table-button margin-top-05"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => this.onSearch(true)}
                            disabled={!!this.state.validationMessage}
                        >
                            {this.props.langText.Body.SearchDownload}
                        </Button>
                        <CSVLink
                            headers={this.createHeaderData()}
                            data={this.createDownloadData()}
                            filename={
                                this.state.companyName +
                                "_" +
                                this.getMomentTime({
                                    date: this.state.startDate,
                                    format: "YYYYMMDD",
                                }) +
                                "-" +
                                this.getMomentTime({
                                    date: this.state.endDate,
                                    format: "YYYYMMDD",
                                }) +
                                ".csv"
                            }
                            className="csv-download-link-format hidden"
                            ref={this.csvLink}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <ColumnFixTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
