import React from 'react'
import { Button, Row, Col, Glyphicon, FormControl } from 'react-bootstrap'
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import AutoCallListDeleteModal from "./AutoCallListDeleteModal";
import AutoCallListInsertModal from "./AutoCallListInsertModal";
import * as GlobalConst from '../../components/AscConstants';

export default class AutoCallList extends AscComponent {
    constructor(props) {
        super(props);
        this.modalShow = this.modalShow.bind(this);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,
            companySelect: [],
            autoCallDelFlag: false,

        }
    }

    getColumnsData() {
        let displayDataList = [
            {
                Header: this.props.langText.Body.Id,
                accessor: 'id',
                filterable: false,
                width: this.props.boardWidth.id,
            }, {
                Header: this.props.langText.Body.ListName,
                accessor: 'list_name',
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "list_name")} />
            }, {
                Header: this.props.langText.Body.ListCount,
                accessor: 'list_count',
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.small,
                Cell: ({
                    value
                }) => value + this.props.langText.Body.Count
            }, {
                Header: this.props.langText.Body.UpdateUserName,
                accessor: 'update_user_name',
                width: this.props.boardWidth.UserName,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "update_user_name")} />
            }, {
                Header: this.props.langText.Body.Modified,
                accessor: 'modified',
                width: this.props.boardWidth.Date,
                Cell: ({
                    value
                }) => value ? this.getMomentTime({
                    format: this.props.langText.Body.DateFormat,
                    date: value
                }) : "",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "modified")} />,
                filterable: false,
            }, {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];
                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize='xsmall'
                                onClick={this.displayShow('detail', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='pencil' />
                            </Button>
                        );
                    } else {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize='xsmall'
                                onClick={this.displayShow('detail', data.row._original)}
                                className='control-button'
                            >
                                <Glyphicon glyph='eye-open' />
                            </Button>
                        );
                    }
                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize='xsmall'
                                onClick={(event) => this.modalShow(event, 'delete', data.row._original, true)}
                                className='control-button'
                            >
                                <Glyphicon glyph='minus' />
                            </Button>
                        );
                    }
                    return (
                        <Row className='text-center'>
                            {rowData}
                        </Row>
                    )
                }
            }
        ]

        //権限がOEMまたはSYSTEMの場合は会社名を表示する
        let addArrayData = {
            Header: this.props.langText.Body.CompanyName,
            accessor: "cm12_companies.company_name",
            width: this.props.boardWidth.CompanyName,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
        };
        displayDataList = this.displayByPermission(displayDataList, addArrayData, 1);

        return displayDataList;
    }

    async modalShow(event, modalType, param, loadingFlag = false) {
        try {
            event.preventDefault();
            let setData = {
                cm76_id: 0,
                list_name: "",
                list_count: 0,
                company_id: this.props.userInfo.company_id,
                company_name: "",
                listUsingData: [],
                show: true,
                autoCallDelFlag: true,
                modalType,
            };

            switch (modalType) {
                case "insert":
                    if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))
                        this.getCompanySelect();
                    break;
                case "delete":
                    if (!param) {
                        alert(this.props.langText.Message.DataSelectError);
                    } else {
                        let checkListUsing = await this.ascAxios("post", `${this.reactContainerPath}/checkListUsing`, {
                            list_id: param.id
                        });
                        let checkListUsingData = checkListUsing.data;
                        if (checkListUsingData.length > 0) setData.autoCallDelFlag = false;
                        checkListUsingData.forEach(row => {
                            setData.listUsingData.push(
                                this.sprintf(
                                    this.props.langText.Message.ListDeleteError_PredictiveCall,
                                    row.task_name
                                )
                            );
                        });
                        
                        setData.listUsingData = setData.listUsingData.map(row => {
                            return <div>{row}</div>
                        });

                        setData = Object.assign(setData, {
                            cm76_id: param.id,
                            list_name: param.list_name,
                            list_count: param.list_count,
                            company_id: param.cm12_companies.id,
                            company_name: param.cm12_companies.company_name,
                        });
                    }

                    //TODO: 未リリースのため、コメントアウト。スケジュール機能を追加時にアンコメントする（#1190）
                    //ローディング画面を表示
                    // if (loadingFlag) this.blockUI();
                    // const arrayStatus = await this.getStatusUsingAutoCallList(param.id);
                    // arrayStatus.some(status => {
                    //     if (status.status !== "finished") {
                    //         setData.autoCallDelFlag = false;
                    //         return true;
                    //     }
                    // });
                    break;
                default:
                    break;
            }
            this.setState(setData);
        } catch (err) {
            this.unblockUI();
            this.showErrorObjectMesssage(err, "ProcessingFailedError");
        }
    };

    displayShow = (display, displayData) => event => {
        switch (display) {
            case "detail":
                this.props.historyPush({
                    pathname: "AutoCallList/AutoCallDetailList",
                    state: { displayData: displayData },
                });
                break;
            default:
        }
    }

    /**
     * 各画面先でのボタン押下時の動作内容
     */
    onClickHandle = modalType => event => {
        let {
            list_name,
            cm76_id,
            company_id,
        } = this.state;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                    list_name,
                    company_id,
                })
                    .then(result => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    })
                    .catch(err => {
                        this.showErrorObjectMesssage(err, "DataInsertError");
                    });
                break;
            case "delete":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    cm76_id,
                })
                    .then(result => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.setState({ show: false });
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        this.showErrorObjectMesssage(err, "DataDeleteError");
                    });
                break;
            default:
                break;
        }
    };

    validationHandle = param => {
        let validation_flag = false;
        switch (param) {
            case "insert":
                validation_flag = !!(
                    this.state.list_name
                );
                break;
            case "delete":
                validation_flag = this.state.autoCallDelFlag;
                break;
            default:
                break;
        }

        return validation_flag;
    };

    /**
     * 発信リストのステータスを取得
     * @param {string} cm76_id
     * @returns {promise <string>} status
     */
    getStatusUsingAutoCallList = async (cm76_id) => {
        try{
            let status = await this.ascAxios("post", `AutoCallList/getStatus`, {
                cm76_id,
            });
            return status.data.length > 0 ? status.data : [];
        } catch (err) {
            throw new Error(err);
        }
    };

    /**
     * 会社一覧取得
     */
    getCompanySelect = () => {
        this.ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.setState({
                    companySelect: res.data
                })
            })
            .catch(err => {
                alert(
                    this.getErrorString(err.response.data)
                )
            })
    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "delete":
                return (
                    <AutoCallListDeleteModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        validationHandle={this.validationHandle}
                    />
                );
            case "insert":
                return (
                    <AutoCallListInsertModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        onTextChange_Limit={this.onTextChange_Limit}
                        validationHandle={this.validationHandle}
                        onSelectChange={this.onSelectChange}
                        currentPermission={this.props.currentPermission}
                    />
                );
            default:
                break;
        }
    }

    render() {
        return (
            <div className='AutoCallList'>
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="autoCallList-insert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={(event) => this.modalShow(event, 'insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{ id: "id", desc: true }]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        )
    }
}
