import React, {Component} from "react"
import {Panel, Col, Glyphicon, Checkbox, Row} from "react-bootstrap"
import AscModal from "../../Elements/Modal/Modal";
import AscAudio from "../../Elements/Audio/Audio";
import InlineForm from "../../Elements/FromGroup/InlineForm"
import "../../Elements/FromGroup/form_select.css"
import * as GlobalConst from '../../../components/AscConstants';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

export default class AutoCallInsertUpdateModal extends Component {
    getPredictiveCallInfoItem = () => {
        let {
            getScopeGreaterEqual,
            floor_flg,
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onSelectChange,
            onMultiSelectChange,
            onCheckBoxChange,
            validationHandle,
            onClickAccordion
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm
                key = "task_name"
                controlId = "task_name"
                label = {langText.Body.AutoCallTaskName}
                type = "text"
                value = {state.task_name} 
                onChange = {e => onTextChange_Limit(e, "task_name", charaLimit.Task_Name)}
                validationState = {validationHandle("task_name")}
            />
        );
        itemArr.push(
            <InlineForm.static
                key = "auto_stop_time"
                controlId = "auto_stop_time"
                label = {langText.Body.AutoCallAutoStopTime}
            >
                <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <Col xs={10} sm={10} md={4} lg={4}>
                        <InlineForm
                            key = "auto_stop_hour"
                            controlId = "auto_stop_hour"
                            label = {""}
                            type = "select"
                            value = {state.auto_stop_hour}
                            onChange = {e => onSelectChange(e, "auto_stop_hour")}
                            options = {state.autoStopHourSelect}
                            validationState = {validationHandle("auto_stop_hour")}
                            placeholder = {langText.SelectOption.Placeholder}
                        />
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2}
                            className="autocall-time-select"
                        >
                            <span >{langText.Body.Hour}</span>
                        </Col>
                        <Col xs={10} sm={10} md={4} lg={4}>
                            <InlineForm
                                key = "auto_stop_min"
                                controlId = "auto_stop_min"
                                label = {""}
                                type = "select"
                                value = {state.auto_stop_min}
                                onChange = {e => onSelectChange(e, "auto_stop_min")}
                                options = {state.autoStopMinSelect}
                                validationState = {validationHandle("auto_stop_min")}
                                placeholder = {langText.SelectOption.Placeholder}
                            />
                        </Col>
                        <Col xs={2} sm={2} md={2} lg={2}
                            className="autocall-time-select"
                        >
                            <span>{langText.Body.Minute}</span>
                        </Col>
                    </Col>
                </Row>
            </InlineForm.static>
        );

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.companyId}
                    onChange = {e => onSelectChange(e, "companyId")}
                    options = {state.companySelect}
                    validationState = {validationHandle("companyId")}
                    placeholder = {langText.SelectOption.Placeholder}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push(
                <InlineForm
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.departmentId}
                    onChange = {e => onMultiSelectChange(e, "departmentId")}
                    options = {state.departmentSelect}
                    validationState = {validationHandle("departmentId")}
                    placeholder = {langText.SelectOption.Placeholder}
                    isMulti = {true}
                    isDisabled = {!state.buttondisabledArr.department_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}>
                    {Array.isArray(state.departmentName) ? state.departmentName.join(", ") : state.departmentName}
                </InlineForm.static>
            );
        }

        if (floor_flg) {
            if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                itemArr.push(
                    <InlineForm
                        key = "floorId"
                        controlId = "floorId"
                        label = {langText.Body.FloorName}
                        type = "select"
                        value = {state.floorId}
                        onChange = {e => onMultiSelectChange(e, "floorId")}
                        options = {state.floorSelect}
                        validationState = {validationHandle("floorId")}
                        placeholder = {langText.SelectOption.Placeholder}
                        isMulti = {true}
                        isDisabled = {!state.buttondisabledArr.floor_id[0]}
                    />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "floorId"
                        controlId = "floorId"
                        label = {langText.Body.FloorName}>
                        {Array.isArray(state.floorName) ? state.floorName.join(", ") : state.floorName}
                    </InlineForm.static>
                );
            }
        }

        if (state.modalType !== "update") {
            itemArr.push(
                <InlineForm
                    key = "list_id"
                    controlId = "list_id"
                    label = {langText.Body.List}
                    type = "select"
                    value = {state.list_id}
                    onChange = {e => onSelectChange(e, "list_id")}
                    options = {state.listSelect}
                    validationState = {validationHandle("list_id")}
                    placeholder = {langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.list_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "list_id"
                    controlId = "list_id"
                    label = {langText.Body.List}>
                    {state.list_name ? state.list_name : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        // #2307_【OmniCuscon】プレディクティブコールの途中から発信除外
        // 202409リリース予定だったが、ARI機能の対応が間に合わないため画面表示箇所は一時的にコメントアウト
        // if (state.modalType !== "update") {
        //     itemArr.push(
        //         <InlineForm
        //             key = "list_id_reject"
        //             controlId = "list_id_reject"
        //             label = {langText.Body.AutoCallRejectList}
        //             type = "select"
        //             value = {state.list_id_reject}
        //             onChange = {e => onSelectChange(e, "list_id_reject")}
        //             options = {state.listRejectSelect}
        //             validationState = {validationHandle("list_id_reject")}
        //             placeholder = {langText.SelectOption.Placeholder}
        //             isClearable = {true}
        //             isDisabled = {!state.buttondisabledArr.list_id_reject[0]}
        //         />
        //     );
        //     if((state.list_id && state.list_id_reject) && (state.list_id === state.list_id_reject)){
        //         itemArr.push(
        //             <p key = "list_id_reject_duplicated" className="text-danger">
        //                 {langText.Body.AutoCallRejectListDuplicated}
        //             </p>
        //         );
        //     }
        // } else {
        //     itemArr.push(
        //         <InlineForm.static
        //             key = "list_id_reject"
        //             controlId = "list_id_reject"
        //             label = {langText.Body.AutoCallRejectList}>
        //             {state.list_name_reject ? state.list_name_reject : langText.SelectOption.Unset}
        //         </InlineForm.static>
        //     );
        // }

        itemArr.push(
            <React.Fragment>
                <Row key = {"externalNumberSetting"}>
                    <Col xs = {6} sm = {6} md = {9} lg = {9}>
                        <InlineForm
                            key = "externalNumberSelected"
                            controlId = "externalNumberSelected"
                            label = {langText.Body.ExternalNumber}
                            type = "select"
                            value = {state.externalNumberSelected}
                            onChange = {e => onSelectChange(e, "externalNumberSelected")}
                            options = {state.externalNumbers}
                            validationState = {validationHandle("externalNumberSelected")}
                            placeholder = {langText.SelectOption.Placeholder}
                        />
                    </Col>
                    <Col xs = {6} sm = {6} md = {3} lg = {3} className = "margin-top-2">
                        <Checkbox
                            key = "non_notification_flag"
                            className = "non_notification_flag"
                            checked = {state.non_notification_flag}
                            onChange = {e => onCheckBoxChange(e, "non_notification_flag")}
                            validationState = {validationHandle("non_notification_flag")}
                        >
                            {langText.Body.AutoCallExternalNumberNonNotification}
                        </Checkbox>
                    </Col>
                </Row>
                {
                    state.externalNumberSelected && 
                    <InlineForm
                        key = "externalMaxOutbound"
                        controlId = "externalMaxOutbound"
                        label = {langText.Body.AutoCallExternalLimitOutbound}
                        type = "text"
                        value = {state.externalMaxOutbound}
                        onChange = {onIntChange("externalMaxOutbound", charaLimit.Outbound_Rate)}
                        validationState = {validationHandle("externalMaxOutbound")}
                    />
                }
                {
                    state.externalNumberSelected &&
                    <p key="max_outbound_message" className="text-danger margin-left-1">
                        {state.externalMaxOutboundValue 
                            ? `${langText.Body.AutoCallExternalMaxOutbound}: ${state.externalMaxOutboundValue}`
                            : `${langText.Body.ChannelLimitError}`
                        }
                    </p>
                }
                <InlineForm
                    key = "inboundGroupsSelected"
                    controlId = "inboundGroupsSelected"
                    label = {langText.Body.InboundGroupName}
                    type = "select"
                    value = {state.inboundGroupsSelected}
                    onChange = {e => onMultiSelectChange(e, "inboundGroupsSelected")}
                    options = {state.inboundGroups}
                    validationState = {validationHandle("inboundGroupsSelected")}
                    placeholder = {langText.SelectOption.Placeholder}
                    isMulti = {true}
                />
            </React.Fragment>
        );

        itemArr.push(
            <Accordion allowZeroExpanded>
                <AccordionItem key="detailSettings" uuid="detailSettings">
                    <AccordionItemHeading>
                        <AccordionItemButton
                            className = "margin-bottom-05"
                        >
                            <div onClick={() => onClickAccordion()} className = "fa-11">
                                <span>{state.accordionFlag ? langText.Body.DownArrow : langText.Body.RightArrow}</span>
                                <b>{langText.Body.DetailSettings}</b>
                            </div>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel
                        className = "padding-left-30"
                    >
                        <InlineForm
                            key = "outboundRate"
                            controlId = "outboundRate"
                            label = {langText.Body.AutoCallMagnification}
                            type = "text"
                            value = {state.outboundRate}
                            onChange = {e => onTextChange_Limit(e, "outboundRate", charaLimit.Outbound_Rate)}
                            validationState = {validationHandle("outboundRate")}
                        />
                        <InlineForm
                            key = "timeout"
                            controlId = "timeout"
                            label = {langText.Body.AutoCallTimeOut}
                            type = "text"
                            value = {state.timeout}
                            onChange = {onIntChange("timeout", charaLimit.AutoCall_Time_Out)}
                            validationState = {validationHandle("timeout")}
                        />
                        {/* <InlineForm.static
                            key = "AutoCallRetrySetting"
                            controlId = "AutoCallRetrySetting"
                            className = "margin-left-1"
                            label = {langText.Body.AutoCallRetrySetting}
                        >
                            <InlineForm
                                key = "retry"
                                controlId = "retry"
                                label = {langText.Body.AutoCallRetry}
                                type = "text"
                                value = {state.retry}
                                onChange = {onIntChange("retry", charaLimit.AutoCall_Retry)}
                                validationState = {validationHandle("retry")}
                            />
                            <InlineForm
                                key = "retryInterval"
                                controlId = "retryInterval"
                                label = {langText.Body.AutoCallRetryInterval}
                                type = "text"
                                value = {state.retryInterval}
                                onChange = {onIntChange("retryInterval", charaLimit.AutoCall_Retry)}
                                validationState = {validationHandle("retryInterval")}
                            />
                        </InlineForm.static> */}
                        <InlineForm.static
                            key = "AutoCallConnectedSetting"
                            controlId = "AutoCallConnectedSetting"
                            className = "margin-left-1"
                            label = {langText.Body.AutoCallConnectedSetting}
                        >
                            <Row key = {"connected_sound_id"}>
                                <Col xs = {6} sm = {6} md = {11} lg = {11}>
                                    <InlineForm
                                        key = "connected_sound_id"
                                        controlId = "connected_sound_id"
                                        label = {langText.Body.AutoCallConnectedPlay}
                                        type = "select"
                                        onChange={e => onSelectChange(e, "connected_sound_id")}
                                        options = {state.connectedSoundSelect}
                                        value = {state.connected_sound_id}
                                        isClearable = {true}
                                        placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                                        isDisabled = {!state.buttondisabledArr.sound_id[0]}
                                    />
                                </Col>
                                <Col xs = {6} sm = {6} md = {1} lg = {1}>
                                    <AscAudio
                                        type = "url"
                                        data = {
                                                state.connectedSoundSelect[state.connectedSoundSelect.findIndex((v) => v.value === state.connected_sound_id)] ? 
                                                state.connectedSoundSelect[state.connectedSoundSelect.findIndex((v) => v.value === state.connected_sound_id)].url
                                                :
                                                state.connected_sound_url
                                                }
                                        className = "set_audio_button"
                                    />
                                </Col>
                            </Row>
                            <InlineForm
                                key = "connected_timeout"
                                controlId = "connected_timeout"
                                label = {langText.Body.AutoCallConnectedTimeOut}
                                type = "text"
                                value = {state.connected_timeout}
                                onChange = {onIntChange("connected_timeout", charaLimit.AutoCall_Connected_Time_Out)}
                            />
                            <Row key = {"connected_timeout_sound_id"}>
                                <Col xs = {6} sm = {6} md = {11} lg = {11}>
                                    <InlineForm
                                        key = "connected_timeout_sound_id"
                                        controlId = "connected_timeout_sound_id"
                                        label = {langText.Body.AutoCallConnectedTimeOutPlay}
                                        type = "select"
                                        onChange={e => onSelectChange(e, "connected_timeout_sound_id")}
                                        options = {state.connectedTimeoutSoundSelect}
                                        value = {state.connected_timeout_sound_id}
                                        isClearable = {true}
                                        placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                                        isDisabled = {!state.buttondisabledArr.sound_id[0]}
                                    />
                                </Col>
                                <Col xs = {6} sm = {6} md = {1} lg = {1}>
                                    <AscAudio
                                        type = "url"
                                        data = {
                                                state.connectedTimeoutSoundSelect[state.connectedTimeoutSoundSelect.findIndex((v) => v.value === state.connected_timeout_sound_id)] ? 
                                                state.connectedTimeoutSoundSelect[state.connectedTimeoutSoundSelect.findIndex((v) => v.value === state.connected_timeout_sound_id)].url
                                                :
                                                state.connected_timeout_sound_url
                                                }
                                        className = "set_audio_button"
                                    />
                                </Col>
                            </Row>
                        </InlineForm.static>
                        {/* <InlineForm.static
                            key = "AutoCallReportMail"
                            controlId = "AutoCallReportMail"
                            className = "margin-left-1"
                            label = {langText.Body.AutoCallReportMail}
                        >
                            <InlineForm
                                key = "mailAddress"
                                controlId = "mailAddress"
                                label = {langText.Body.AutoCallMailTo}
                                type = "email"
                                value = {state.mailAddress}
                                onChange = {e => onTextChange_Limit(e, "mailAddress", charaLimit.User_mailAddress)}
                                validationState = {validationHandle("mailAddress")}
                                placeholder ={langText.Message.Emails_Format}
                            />
                            <InlineForm
                                key = "mailCc"
                                controlId = "mailCc"
                                label = {langText.Body.AutoCallMailCc}
                                type = "email"
                                value = {state.mailCc}
                                onChange = {e => onTextChange_Limit(e, "mailCc", charaLimit.User_mailAddress)}
                                validationState = {validationHandle("mailCc")}
                                placeholder ={langText.Message.Emails_Format}
                            />
                            <InlineForm
                                key = "mailBcc"
                                controlId = "mailBcc"
                                label = {langText.Body.AutoCallMailBcc}
                                type = "email"
                                value = {state.mailBcc}
                                onChange = {e => onTextChange_Limit(e, "mailBcc", charaLimit.User_mailAddress)}
                                validationState = {validationHandle("mailBcc")}
                                placeholder ={langText.Message.Emails_Format}
                            />
                            <Checkbox
                                key = "start_flag"
                                className = "start_flag"
                                checked = {state.start_flag}
                                onChange = {e => onCheckBoxChange(e, "start_flag")}
                                validationState = {validationHandle("start_flag")}
                            >
                                {langText.Body.AutoCallStart}
                            </Checkbox>
                            <Checkbox
                                key = "stop_flag"
                                className = "stop_flag"
                                checked = {state.stop_flag}
                                onChange = {e => onCheckBoxChange(e, "stop_flag")}
                                validationState = {validationHandle("stop_flag")}
                            >
                                {langText.Body.AutoCallStop}
                            </Checkbox>
                            <Checkbox
                                key = "loop_flag"
                                className = "loop_flag"
                                checked = {state.loop_flag}
                                onChange = {e => onCheckBoxChange(e, "loop_flag")}
                                validationState = {validationHandle("loop_flag")}
                            >
                                {langText.Body.AutoCallLoopList}
                            </Checkbox>
                            <Checkbox
                                key = "hour_summary_flag"
                                className = "hour_summary_flag"
                                checked = {state.hour_summary_flag}
                                onChange = {e => onCheckBoxChange(e, "hour_summary_flag")}
                                validationState = {validationHandle("hour_summary_flag")}
                            >
                                {langText.Body.AutoCall1HourSummary}
                            </Checkbox>
                            
                        </InlineForm.static>
                        <InlineForm.static
                            key = "AutoCallErrorMail"
                            controlId = "AutoCallErrorMail"
                            className = "margin-left-1"
                            label = {langText.Body.AutoCallErrorMail}
                        >
                            <InlineForm
                                key = "errorMailAddress"
                                controlId = "errorMailAddress"
                                label = {langText.Body.AutoCallMailTo}
                                type = "email"
                                value = {state.errorMailAddress}
                                onChange = {e => onTextChange_Limit(e, "errorMailAddress", charaLimit.User_mailAddress)}
                                validationState = {validationHandle("errorMailAddress")}
                                placeholder ={langText.Message.Emails_Format}
                            />
                            <InlineForm
                                key = "errorMailCc"
                                controlId = "errorMailCc"
                                label = {langText.Body.AutoCallMailCc}
                                type = "email"
                                value = {state.errorMailCc}
                                onChange = {e => onTextChange_Limit(e, "errorMailCc", charaLimit.User_mailAddress)}
                                validationState = {validationHandle("errorMailCc")}
                                placeholder ={langText.Message.Emails_Format}
                            />
                            <InlineForm
                                key = "errorMailBcc"
                                controlId = "errorMailBcc"
                                label = {langText.Body.AutoCallMailBcc}
                                type = "email"
                                value = {state.errorMailBcc}
                                onChange = {e => onTextChange_Limit(e, "errorMailBcc", charaLimit.User_mailAddress)}
                                validationState = {validationHandle("errorMailBcc")}
                                placeholder ={langText.Message.Emails_Format}
                            />
                            <Checkbox
                                key = "error_stop_flag"
                                className = "error_stop_flag"
                                checked = {state.error_stop_flag}
                                onChange = {e => onCheckBoxChange(e, "error_stop_flag")}
                                validationState = {validationHandle("error_stop_flag")}
                            >
                                {langText.Body.AutoCallErrorStop}
                            </Checkbox>
                            <Checkbox
                                key = "connect_error_flag"
                                className = "connect_error_flag"
                                checked = {state.connect_error_flag}
                                onChange = {e => onCheckBoxChange(e, "connect_error_flag")}
                                validationState = {validationHandle("connect_error_flag")}
                            >
                                {langText.Body.AutoCallConnectError}
                            </Checkbox>
                            {state.connect_error_flag &&
                                <Row className = "padding-left-30">
                                    <Col xs = {6} sm = {6} md = {2} lg = {2} className = "margin-top-07">
                                        {langText.Body.AutoCall1Hour}
                                    </Col>
                                    <Col xs = {6} sm = {6} md = {8} lg = {8}>
                                        <InlineForm
                                            key = "connect_error"
                                            controlId = "connect_error"
                                            type = "text"
                                            value = {state.connect_error}
                                            onChange = {onIntChange("connect_error", charaLimit.AutoCall_Report)}
                                            validationState = {validationHandle("connect_error")}
                                        />
                                    </Col>
                                    <Col xs = {6} sm = {6} md = {2} lg = {2} className = "margin-top-07">
                                        {langText.Body.AutoCallLess}
                                    </Col>
                                </Row>
                            }
                            <Checkbox
                                key = "giveup_error_flag"
                                className = "giveup_error_flag"
                                checked = {state.giveup_error_flag}
                                onChange = {e => onCheckBoxChange(e, "giveup_error_flag")}
                                validationState = {validationHandle("giveup_error_flag")}
                            >
                                {langText.Body.AutoCallGiveUpError}
                            </Checkbox>
                            {state.giveup_error_flag &&
                                <Row className = "padding-left-30">
                                    <Col xs = {6} sm = {6} md = {2} lg = {2} className = "margin-top-07">
                                        {langText.Body.AutoCall1Hour}
                                    </Col>
                                    <Col xs = {6} sm = {6} md = {8} lg = {8}>
                                        <InlineForm
                                            key = "giveup_error"
                                            controlId = "giveup_error"
                                            type = "text"
                                            value = {state.giveup_error}
                                            onChange = {onIntChange("giveup_error", charaLimit.AutoCall_Report)}
                                            validationState = {validationHandle("giveup_error")}
                                        />
                                    </Col>
                                    <Col xs = {6} sm = {6} md = {2} lg = {2} className = "margin-top-07">
                                        {langText.Body.AutoCallUp}
                                    </Col>
                                </Row>
                            }
                        </InlineForm.static> */}
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
        );

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {
                                this.props.state.modalType !== "update"
                                ? this.props.langText.Body.InsertPredictiveCall
                                : this.props.langText.Body.UpdatePredictiveCall
                            }
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getPredictiveCallInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
