import React from "react";
import {Panel, FormControl, Col, ButtonGroup, Button, Checkbox, Glyphicon} from "react-bootstrap";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import Component from "../../components/AscComponent";
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import ASSelect from "../Elements/Select/Select";
import BusinessCalendarHourSettingModal from "./BusinessCalendarHourSettingModal";
import Type from "./Type.json";
import * as GlobalConst from "../../components/AscConstants";


export default class BusinessCalendarModal extends Component {
    constructor(props) {
        super(props);
        this.day_string = this.props.dayString;
        this.action_string = this.props.actionString;
        this.business_string = this.props.businessString;

        this.read = this.props.state.modalType === "read" || this.props.state.modalType === "delete" ? true : false;

        this.state = {
            show: false,
            modalType: null,
            action_string: {...this.action_string},
            company_select: [],
            script_select: [],
            voice_select: [],

            id: this.props.state.id,
            business_calendar_name: this.props.state.business_calendar_name,
            company_id: this.props.state.company_id,
            company_name: this.props.state.company_name,
            cm32_business_calendar_details: this.createBusinessCalendarDetail(this.props.state.cm32_business_calendar_details),
            cm33_business_calendar_irregular_infos: this.props.state.cm33_business_calendar_irregular_infos
        };
    }

    async componentDidMount(id) {
        try {
            this.blockUI();
            let company_id = id || this.props.state.company_id;
            let script_flag = false;
            let action_string = {...this.action_string};

            if (company_id) {
                let param = (await this.getParamenter(company_id)).data;
                let script_obj = param.find(row => row.function_id === "BUSINESS_CALENDAR" && row.parameter_id === "SCRIPT_SELECT");
                if (script_obj && script_obj.parameter_value === "Y") script_flag = true;
            }

            if (!script_flag) delete action_string[Type.ACTION.SCRIPT];

            this.setState({
                company_select: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                    ? (await this.getCommonCompanySelect(this.props.reactContainerPath)).data : [],
                script_select: script_flag && company_id ? (await this.getScriptSelect(company_id)).data : [],
                voice_select: company_id ? (await this.getVoiceSelect(company_id)).data.map(row => {
                    if (row.update_user_name) {
                        row.label += `(${this.props.langText.Body.VoiceListUpdate}${row.update_user_name})`;
                    } else if (row.entry_user_name) {
                        row.label += `(${this.props.langText.Body.VoiceListCreate}${row.entry_user_name})`;
                    }
                    return row;
                }) : [],
                action_string
            });
        } catch (err) {
            console.error(err);
            alert(this.showErrorObjectMesssage(err.response.data));
            this.propSetState({show: false});
        }
    }

    getParamenter = (company_id) => {
        return this.ascAxios('post', `Common/getCompanyControlParameters`, {cm12_id: company_id})
    }

    getScriptSelect = (company_id) => {
        return this.ascAxios("post", "Common/getScriptList", {container: '/BusinessCalendar', cm12_id: company_id});
    }

    getVoiceSelect = (company_id) => {
        return this.ascAxios('post', `Common/voiceFileSelectAllType`, {company_id});
    }

    getColumnsData = (type) => {
        let columns = [
            {
                Header: type === "day" ? this.props.langText.Body.Day : this.props.langText.Body.Date,
                accessor: type === "day" ? "day_of_the_week" : "irregular_date",
                width: type === "day" ? this.props.boardWidth.id : this.props.boardWidth.small,
                Cell: data => {
                    return type === "day" ? this.day_string[data.original.day_of_the_week] : data.original.irregular_date;
                }
            },
            {
                Header: this.props.langText.Body.Class,
                accessor: "type",
                width: this.props.boardWidth.xxsmall,
                Cell: data => {
                    return this.business_string[data.original.type] || null;
                }
            },
            {
                Header: this.props.langText.Body.BusinessHour,
                accessor: "hour",
                width: this.props.boardWidth.medium,
                Cell: data => {
                    let elems = [];

                    if (data.original.business_start_hour && data.original.business_start_hour !== "---"
                    && data.original.business_end_hour && data.original.business_end_hour !== "---") {
                        elems.push(<p className={"font-weight-bold"} key={"business_hour"} >{this.createTimeString(data.original.business_start_hour, data.original.business_end_hour)}</p>);
                    } else {
                        elems.push("---");
                    }

                    if (this.action_string[data.original.non_business_hour_action_flag] &&
                        this.business_string[data.original.type] != this.props.langText.Body.DayOff) {
                        elems.push(<p className={"font-color-grey"} key={"non_business_hour"}>{this.props.langText.Body.BusinessHourOut}</p>);
                    }

                    return elems;
                }
            },
            {
                Header: this.props.langText.Body.Behavior,
                accessor: "action",
                width: this.props.boardWidth.small,
                Cell: data => {
                    let elems = [];

                    if (this.action_string[data.original.business_hour_action_flag]) {
                        elems.push(<p className={"font-weight-bold"} key={"business_action"}>{this.action_string[data.original.business_hour_action_flag]}</p>);
                    }

                    if (this.action_string[data.original.non_business_hour_action_flag]) {
                        elems.push(<p className={"font-color-grey"}  key={"non_business_action"}>{this.action_string[data.original.non_business_hour_action_flag]}</p>);
                    }

                    return elems;
                }
            },
            {
                Header: this.props.langText.Body.BehaviorSetting,
                accessor: "detail",
                Cell: data => {
                    let columns = [
                        {
                            type: "business_hour",
                            action: data.original.business_hour_action_flag,
                            script_detail: data.original.business_hour_action_detail,
                            audio_detail : data.original.business_hour_voice_file_id
                        },{
                            type: "non_business_hour",
                            action: data.original.non_business_hour_action_flag,
                            script_detail: data.original.non_business_hour_action_detail,
                            audio_detail : data.original.non_business_hour_voice_file_id
                        }
                    ];

                    return columns.map((column) => {
                        if (column.script_detail || column.audio_detail) {
                            switch(column.action) {
                                case Type.ACTION.SCRIPT:
                                    let script_data = 
                                        this.state.script_select.find(
                                            script => script.value === parseInt(column.script_detail)
                                        );
                                    if (script_data) {
                                        return <p 
                                                    className = {column.type == "business_hour" 
                                                        ? "font-weight-bold white-space-normal" 
                                                        : "font-color-grey white-space-normal"} 
                                                    key = {column.type == "business_hour" 
                                                        ? "business_hour_action_detail" 
                                                        : "non_business_hour_action_detail"}>
                                                    {this.props.langText.Body.ScriptName+" : "+script_data.label}
                                                </p>
                                    }
                                    break;
                                case Type.ACTION.VOICE:
                                    let voice_data = 
                                        this.state.voice_select.find(
                                            script => script.value === parseInt(column.audio_detail)
                                        );
                                    if (voice_data) {
                                        return <p 
                                                    className = {column.type == "business_hour" 
                                                        ? "font-weight-bold white-space-normal" 
                                                        : "font-color-grey white-space-normal"} 
                                                    key = {column.type == "business_hour" 
                                                        ? "business_hour_action_detail" 
                                                        : "non_business_hour_action_detail"}>
                                                    {this.props.langText.Body.AudioName+" : "+voice_data.label}
                                                </p>
                                    }
                                    break;
                                case Type.ACTION.TRANSFER:
                                    let transfer_data = column.script_detail;
                                    if (transfer_data) {
                                        return <p
                                                className = {column.type == "business_hour" 
                                                    ? "font-weight-bold white-space-normal" 
                                                    : "font-color-grey white-space-normal"} 
                                                key = {column.type == "business_hour" 
                                                    ? "business_hour_action_detail" 
                                                    : "non_business_hour_action_detail"}>
                                                {transfer_data}
                                            </p>
                                    }
                                    break;
                                default: break;
                            }
                        }
                    });
                }
            },
            {
                Header: this.props.langText.Body.exceptionPlay,
                accessor: "exception_play_setting",
                width: this.props.boardWidth.small,
                style: {"whiteSpace": "normal"},
                Cell: data => {
                    return (data.original.exception_play_start_hour && data.original.exception_play_end_hour) 
                    ? this.props.langText.Body.Available : this.props.langText.Body.None;
                }
            },
            {
                Header: this.props.langText.Body.Control,
                accessor: "control",
                width: this.props.boardWidth.xxsmall,
                Cell: data => {
                    if (type === "day") {
                        if (!this.read) {
                            let checked = data.original.checked || false;
                            return <Checkbox
                                checked={checked}
                                onChange={e => {
                                    let detail_obj = this.state.cm32_business_calendar_details.find(detail => detail.day_of_the_week === data.original.day_of_the_week);
                                    if (detail_obj) detail_obj.checked = !checked;
                                    this.setState({
                                        cm32_business_calendar_details: this.state.cm32_business_calendar_details
                                    });     
                                }}>
                            </Checkbox>;
                        } else {
                            return <Button
                                bsSize="xsmall"
                                onClick={e => {
                                    let details = this.state.cm32_business_calendar_details.map(row => {
                                        if (data.original.day_of_the_week === row.day_of_the_week) {
                                            row.checked = true;
                                        } else {
                                            row.checked = false;
                                        }
                                        return row;
                                    });
                                    
                                    this.setState({
                                        cm32_business_calendar_details: details,

                                        modalType: "calendar_set",
                                        show: true
                                    });
                                }}
                                className="control-button">
                                <Glyphicon glyph="eye-open"/>
                            </Button>;
                        }
                    } else {
                        if (!this.read) {
                            return <Button
                                bsSize="xsmall"
                                onClick={e => {
                                    let irr = this.state.cm33_business_calendar_irregular_infos;
                                    irr.splice(data.index, 1);
                                    
                                    this.setState({
                                        cm33_business_calendar_irregular_infos: irr
                                    });
                                }}
                                className="control-button">
                                <Glyphicon glyph="minus"/>
                            </Button>;
                        } else {
                            return null;
                        }
                    }
                }
            }
        ];

        return columns;
    };

    // time = "0000"形
    createTimeString = (start_time, end_time) => {
        let times = [start_time, end_time].map(time => `${time.slice(0, 2)}${this.props.langText.Body.Hour}${time.slice(2)}${this.props.langText.Body.Minute}`);
        return times.join("~");
    }

    createBusinessCalendarDetail = (cm32_business_calendar_details) => {
        let detail_list = [];

        cm32_business_calendar_details.map(row => {
            if (row.exception_play_start_hour && row.exception_play_end_hour){
                row.exception_play_flag = true;
            }
        });

        for (let day in this.day_string) {
            detail_list.push(cm32_business_calendar_details.find(row => row.day_of_the_week === day) || {day_of_the_week: day, business_start_hour: "---", business_end_hour: "---"});
        }

        return detail_list;
    }

    getModalData = () => {
        const {
            modalType
        } = this.props.state;

        let elems = [];

        // 会社
        if (!this.read && modalType === "insert" && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            elems.push(
                <ASFieldGroup
                    key="company_id"
                    label={this.props.langText.Body.CompanyName}
                    validationState={this.validationHandle("company_id")}>
                    <ASSelect
                        value={this.state.company_id}
                        options={this.state.company_select}
                        placeholder={this.props.langText.SelectOption.Placeholder}
                        onChange={e => {
                            this.componentDidMount(e.value);
                            this.setState({company_id: e.value});
                        }}/>
                </ASFieldGroup>
            );
        } else {
            elems.push(
                <ASFieldGroup
                    key="company_id"
                    label={this.props.langText.Body.CompanyName}>
                    <FormControl.Static>{this.state.company_name}</FormControl.Static>
                </ASFieldGroup>
            );
        }

        // 営業カレンダー名
        elems.push(
            <ASFieldGroup
                key="business_calendar_name"
                type="text"
                label={this.props.langText.Body.BusinessCalendarName}
                value={this.state.business_calendar_name}
                onChange={e => this.setState({business_calendar_name: e.target.value})}
                validationState={this.validationHandle("business_calendar_name")}
                maxLength={this.props.charaLimit.BusCale_update_calendarname}
                readOnly={this.read}/>
        );

        // 営業時間設定文字表示
        elems.push(
            <ASFieldGroup
                key="group-btn"
                label={this.props.langText.Body.BusinessHourSetting}>
                {!this.read &&
                    <ButtonGroup className="margin-left-05">
                        <Button
                            className="group-btn"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => {
                                this.setState({
                                    cm32_business_calendar_details: this.state.cm32_business_calendar_details.map(row => {
                                        row.checked = true;
                                        return row;
                                    })
                                });
                            }}>
                            {this.props.langText.Body.AllCheck}
                        </Button>
                        <Button
                            className="group-btn"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => {
                                this.setState({
                                    cm32_business_calendar_details: this.state.cm32_business_calendar_details.map(row => {
                                        row.checked = false;
                                        return row;
                                    })
                                });
                            }}>
                            {this.props.langText.Body.AllUnCheck}
                        </Button>
                        <Button
                            className="group-btn"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => {
                                if (this.state.company_id) {
                                    if (this.state.cm32_business_calendar_details.find(row => row.checked)) {
                                        if (this.detailSameCheck(this.state.cm32_business_calendar_details) || window.confirm(this.props.langText.Body.Cautiondatadiff)) {
                                            this.setState({
                                                modalType: "calendar_set",
                                                show: true
                                            });
                                        }
                                    } else {
                                        alert(this.props.langText.Body.Cautionyoubiselect);
                                    }
                                } else {
                                    alert(this.props.langText.Body.Cautioncompanyselect);
                                }
                            }}>
                            {this.props.langText.Body.UpdateTitle}
                        </Button>
                    </ButtonGroup>
                }
                {this.read && <div></div>}
            </ASFieldGroup>
        );

        elems.push(
            <CommonTable
                key="day-setting-data-view-table"
                showPagination={false}
                columns={this.getColumnsData("day")}
                data={this.state.cm32_business_calendar_details}
                showPaginationBottom={false}
                pageSize={this.state.cm32_business_calendar_details.length}
                sortable={false}
                style={{height:"650px", marginBottom: "13px"}}
                previousText={this.props.langText.Table.PreviousText}
                nextText={this.props.langText.Table.NextText}
                loadingText={this.props.langText.Table.LoadingText}
                noDataText={this.props.langText.Table.NoDataText}
                rowsText={this.props.langText.Table.RowsText}/>
        );

        elems.push(
            <ASFieldGroup
                key="irr-btn"
                label={this.props.langText.Body.IrregularSetting}>
                {!this.read && 
                    <ButtonGroup className="margin-left-05">
                        <Button
                            className="group-btn"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => {
                                this.setState({
                                    modalType: "calendar_ire",
                                    show: true
                                });
                            }}>
                            {this.props.langText.Body.Add}
                        </Button>
                        {
                            //複製モーダルのイレギュラー一括削除機能
                            modalType=="copy"&&
                            <Button
                                className="group-btn"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={() => {
                                    this.setState({
                                        cm33_business_calendar_irregular_infos: []
                                    });
                                }}>
                                {this.props.langText.Body.BatchDelete}
                            </Button>
                        }
                    </ButtonGroup>
                }
                {this.read && <div></div>}
            </ASFieldGroup>
        );
        
        elems.push(
            <CommonTable
                key="irr-setting-data-view-table"
                showPagination={false}
                columns={this.getColumnsData("irr")}
                data={this.state.cm33_business_calendar_irregular_infos}
                showPaginationBottom={false}
                pageSize={this.state.cm33_business_calendar_irregular_infos.length}
                sortable={false}
                style={{height:"200px"}}
                previousText={this.props.langText.Table.PreviousText}
                nextText={this.props.langText.Table.NextText}
                loadingText={this.props.langText.Table.LoadingText}
                noDataText={this.props.langText.Table.NoDataText}
                rowsText={this.props.langText.Table.RowsText}/>
        );
        
        return <Col sm={12}>{elems}</Col>;
    }

    detailSameCheck = (check_arr) => {
        let check_obj;
        let diff_data = check_arr
            .filter(row => row.checked)
            .find(row => {
                let result = false;
                if (check_obj) {
                    for (let key in check_obj) {
                        if (key !== "day_of_the_week"
                            && key !== "irregular_date"
                            && check_obj[key] !== row[key]) {
                            result = true;
                            break;
                        }
                    }
                } else {
                    check_obj = row;
                }
                return result
            });
        return diff_data ? false : true;
    }

    getModalDetail = (detail_arr) => {
        if (this.detailSameCheck(detail_arr)) {
            return detail_arr.find(row => row.checked);
        } else {
            return {};
        }
    }

    onClickHandle = (data) => {
        let target;
        let target_data;
        if (this.state.modalType === "calendar_set") {
            target = "cm32_business_calendar_details";
            target_data = this.state[target].map(row => {
                if (row.checked) row = Object.assign(row, data);
                return row;
            });
        } else {
            target = "cm33_business_calendar_irregular_infos";
            target_data = this.state[target];
            let dates_arr = data.irregular_dates_arr;

            dates_arr.forEach(date => {
                const search_index = target_data.findIndex(row => row.irregular_date === date);
                const edit_data = Object.assign({irregular_date: date}, data);

                if (search_index !== -1) {
                    target_data[search_index] = edit_data;
                } else {
                    target_data.push(edit_data);
                }
            });

            target_data.sort((pre, crr) => 
                pre.irregular_date.toLowerCase() > crr.irregular_date.toLowerCase() ? -1 : 1);
        }

        this.setState({
            show: false,
            [target]: target_data
        });
    }

    validationHandle = (param) => {
        switch(param) {
            case "company_id":
                return this.state[param] ? null : this.props.langText.Message.Validation_Select_input;
            case "business_calendar_name":
                return this.state[param] ? null : this.props.langText.Message.Validation_string_input;
            case "insert":
            case "copy":
            case "update":
                return this.state.company_id && this.state.business_calendar_name;
            case "read":
            case "delete":
                return true;
            default:
                return false;
        }
    }

    render() {
        return (
            <>
                <AscModal
                    key="business-calendar-asc-modal"
                    dialogClassName="over-width-modal non-scroll-bar"
                    state={this.props.state}
                    propSetState={this.props.propSetState}
                    langText={this.props.langText}
                    validationHandle={this.validationHandle}
                    customFunc={() => {
                        this.props.onClick(this.state);
                    }}>
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.langText.Body.BusinessCalendarSetting}
                            </Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            {this.getModalData()}
                        </Panel.Body>
                    </Panel>
                </AscModal>
                {this.state.show && 
                    <BusinessCalendarHourSettingModal
                        state={this.state}
                        propSetState={this.propSetState}
                        dayString={this.day_string}
                        actionString={this.state.action_string}
                        businessString={this.business_string}
                        scriptSelect={this.state.script_select}
                        voiceSelect={this.state.voice_select}
                        days={this.state.modalType === "calendar_set" ? this.state.cm32_business_calendar_details.filter(row => row.checked).map(row => row.day_of_the_week) : []}
                        detail={this.state.modalType === "calendar_set" ? this.getModalDetail(this.state.cm32_business_calendar_details) : {}}
                        read={this.read}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        />}
            </>
        );
    }
}
