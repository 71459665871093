import React from "react";
import {Col, Glyphicon, Row} from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import Component from "../../../components/AscComponent";


/**
 * 会社選択表示
 * state.company_idとstate.companySelectが必要
 */
export class SelectCompanyElement extends Component {

    render() {
        return (
            <InlineForm
                colWidth = {this.props.colWidth ? this.props.colWidth : null}
                key = "company_id"
                controlId = "company_id"
                label = {this.props.labelDisabled ? null : this.props.langText.Body.CompanyName}
                type = "select"
                value = {this.props.state.company_id}
                onChange = {e => this.props.onSelectChange(e, "company_id")}
                options = {this.props.state.companySelect}
                validationState = {this.props.state.company_id ? null : "error"}
                placeholder = {!this.props.state.companySelect[0] ?
                    <Glyphicon glyph="refresh" className="spinning"/> : this.props.langText.SelectOption.Placeholder}/>
        );
    }
}

/**
 * 会社表示
 * state.company_nameが必要
 */
export class ViewCompanyElement extends Component {

    render() {
        return (
            <InlineForm.static
                key="company_name"
                controlId="company_name"
                label={this.props.langText.Body.CompanyName}>
                {this.props.state.company_name ? this.props.state.company_name : this.props.langText.Body.BasePermissionName}
                <span className="text-danger fa-pull-right">
                    {this.props.annotation}
                </span>
            </InlineForm.static>
        );
    }
}
