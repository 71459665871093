import React from "react"
import AscComponent from '../../components/AscComponent';
import { Panel, Radio, Col, Glyphicon ,Button, Checkbox } from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import Favi from "../Elements/Favi/Favi"
import InlineForm from "../Elements/FromGroup/InlineForm"
import MultiSelect from "../Elements/Select/MultiSelect"
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";
import ExtensionNumberCheckModal from "./ExtensionNumberCheckModal";


export default class ExtensionNumberModal extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            modalType: "multi_delete",
            maxSelect: 200
        };
    }

    getExtensionNumberInfoItem = () => {
        let {
            state,
            propSetState,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiSelectChange,
            onRadioChange,
            validationHandle,
            getinboundGroupName,
            getScopeGreaterEqual,
            omniphoneLoginStatusDelete,
            checkPrefixDuplicate,
            onCheckBoxChange,
            passwordValidationHandle
        } = this.props;
        let itemArr = [];

        if (
            getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) &&
            (state.modalType === "insert" ||
                state.modalType === "insertBatch" ||
                state.modalType === "copy" ||
                state.modalType === "multi_delete")
        ) {
            //oem権限以上の場合、会社選択可能
            itemArr.push(
                <InlineForm
                    key="company_id"
                    controlId="company_id"
                    label={langText.Body.CompanyName}
                    type="select"
                    options={state.companySelect}
                    value={state.company_id}
                    onChange={e => onSelectChange(e, "company_id")}
                    isClearable={true}
                    validationState={validationHandle("company_id")}
                    placeholder={
                        !state.buttondisabledArr.company_id[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder
                        )
                    }
                    isDisabled={!state.buttondisabledArr.company_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="company_id"
                    controlId="company_id"
                    label={langText.Body.CompanyName}
                >
                    {state.company_name}
                </InlineForm.static>
            );
        }

        if (
            getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) &&
            (state.modalType === "insert" ||
                state.modalType === "insertBatch" ||
                state.modalType === "copy" ||
                state.modalType === "multi_delete")
        ) {
            //会社管理者権限以上の場合、拠点選択可能
            itemArr.push(
                <InlineForm
                    key="department_id"
                    controlId="department_id"
                    label={langText.Body.DepartmentName}
                    type="select"
                    options={state.departmentSelect}
                    value={state.department_id}
                    onChange={e => onSelectChange(e, "department_id")}
                    isClearable={true}
                    validationState={validationHandle("department_id")}
                    placeholder={
                        state.buttondisabledArr.department_load[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder
                        )
                    }
                    isDisabled={!state.buttondisabledArr.department_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="department_id"
                    controlId="department_id"
                    label={langText.Body.DepartmentName}
                >
                    {state.department_name}
                </InlineForm.static>
            );
        }
        if (this.props.state.floor_flg) {
            if (
                getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) &&
                (state.modalType === "insert" ||
                    state.modalType === "insertBatch" ||
                    state.modalType === "copy" ||
                    state.modalType === "multi_delete")
            ) {
                //拠点管理者以上の場合、フロア選択可能
                itemArr.push(
                    <InlineForm
                        key="floor_id"
                        controlId="floor_id"
                        label={langText.Body.FloorName}
                        type="select"
                        options={state.floorSelect}
                        value={state.floor_id}
                        onChange={e => onSelectChange(e, "floor_id")}
                        isClearable={true}
                        validationState={validationHandle("floor_id")}
                        placeholder={
                            state.buttondisabledArr.floor_load[0] ? (
                                <Glyphicon glyph="refresh" className="spinning" />
                            ) : (
                                langText.SelectOption.Placeholder
                            )
                        }
                        isDisabled={!state.buttondisabledArr.floor_id[0]}
                    />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key="floor_id"
                        controlId="floor_id"
                        label={langText.Body.FloorName}
                    >
                        {state.floor_name}
                    </InlineForm.static>
                );
            }
        }

        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="sequence_number"
                    controlId="sequence_number"
                    label={langText.Body.ExtensionNumber}
                    type="text"
                    value={state.sequence_number}
                    onKeyDown={e => onKeyDownIntCheck(e)}
                    onChange={e => onTextChange_Limit(e, "sequence_number", 3)}
                    onBlur = {() => checkPrefixDuplicate()}
                    validationState={validationHandle("sequence_number")}
                    placeholder={langText.Message.Extension_SequenceNumberDigits}
                />
            );
            if(state.prefixDuplicateFlag)
            {
                itemArr.push(
                <p key="ExtensionNumberDuplicate" className="text-danger">
                    {langText.Body.ExtensionNumberDuplicate}
                </p>
                );
            }
            if(!this.isSystemUsedPrefix(state.sequence_number))
            {
                itemArr.push(
                    <p key = "prefixSystemUseNumber" className="text-danger">
                        {langText.Body.PrefixSystemUseNumber}
                    </p>
                );    
            }
        }

        if (state.modalType === "insertBatch") {
            itemArr.push(
                <InlineForm
                    key = "insert_count"
                    controlId = "insert_count"
                    label = {langText.Body.InsertCount}
                    type = "text"
                    value = {state.insert_count}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("insert_count",charaLimit.Extensions_insertcount)}
                    validationState = {validationHandle("insert_count")}/>
            );
        }

        if (
            state.modalType === "update" ||
            state.modalType === "delete" ||
            state.modalType === "read"
        ) {
            itemArr.push(
                <InlineForm.static
                    key="extension_number"
                    controlId="extension_number"
                    label={langText.Body.ExtensionNumber}
                >
                    {state.extension_number}
                </InlineForm.static>
            );
        }

        //デフォルト発信番号
        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="external_number"
                    controlId="external_number"
                    label={langText.Body.DefaultExternalNum}
                    type="select"
                    options={state.externalNumSelect}
                    value={state.external_number}
                    onChange={e => onSelectChange(e, "external_number")}
                    isClearable={true}
                    validationState={validationHandle("external_number")}
                    placeholder={
                        state.buttondisabledArr.external_load[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder_none
                        )
                    }
                    isDisabled={!state.buttondisabledArr.external_number[0]}
                />
            );
        } else if (
            state.modalType === "read" ||
            state.modalType === "delete"
        ) {
            itemArr.push(
                <InlineForm.static
                    key="external_number"
                    controlId="external_number"
                    label={langText.Body.DefaultExternalNum}
                >
                    {state.external_number_name ? state.external_number_name : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }


        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "copy" 
        ) {
            itemArr.push(
                <InlineForm
                    key="inbound_type"
                    controlId="inbound_type"
                    label={langText.Body.ExtensionNumberType}
                    validationState={validationHandle("inbound_type")}
                >
                    {state.inboundTypeRadio.map((row, key) => (
                        <div key={key}>
                            <Col xs={10} className="inboundType-text">
                                <Radio
                                    name="inbound_type_radio"
                                    key={key}
                                    onChange={e => onRadioChange(e, "inbound_type")}
                                    checked={row.value === state.inbound_type}
                                    value={row.value}
                                >
                                    {row.label}
                                </Radio>
                            </Col>
                            <Col xs={2} className="inboundType-icon">
                                <Favi
                                    className="pull-right fa-fw fa-icon fa-icon-static"
                                    icon={row.value}
                                />
                            </Col>
                        </div>
                    ))}
                </InlineForm>
            );
        } else if (
            state.modalType === "update" ||
            state.modalType === "delete"
        ) {
            itemArr.push(
                <InlineForm.static
                    key = "inbound_type"
                    controlId = "inbound_type"
                    label = {langText.Body.ExtensionNumberType}>
                    <Favi
                        className="pull-right fa-fw fa-icon fa-icon-static"
                        icon={state.inbound_type}/>
                    {state.inboung_type_name}
                </InlineForm.static>
            );
        }

        if (state.modalType === "multi_delete") {
            itemArr.push(
                <InlineForm
                    key = "delete_inbound_type"
                    controlId = "delete_inbound_type"
                    label = {langText.Body.ExtensionNumberType}>
                    {state.inboundTypeSelect.map((row, key) =>
                        <div key = {key}>
                            <Col xs = {10} className="groupType-text">
                                <Radio
                                    name = "delete_inbound_type_radio"
                                    key = {key}
                                    onChange = {e => onRadioChange(e, "delete_inbound_type")}
                                    checked = {row.value === state.delete_inbound_type}
                                    value = {row.value}
                                >
                                    {row.label}
                                </Radio>
                            </Col>
                            {row.value !== "all" &&
                                <Col xs = {2} className = "groupType-icon">
                                    <Favi
                                        className = "fa-fw fa-icon"
                                        icon = {row.value}
                                    />
                                </Col>
                            }
                        </div>
                    )}
                </InlineForm>
            );
        }

        if (
            (state.modalType === "update" ||
            state.modalType === "delete" ||
            state.modalType === "read") && state.inbound_type === 'android'
        ) { 
            if (state.omni_phone_version.length > 0) {
                itemArr.push(
                    <InlineForm.static
                        key = "omni_phone_version"
                        controlId = "omni_phone_version"
                        label = {langText.Body.OmniPhoneAppVersion}>
                        {state.omni_phone_version}
                    </InlineForm.static>
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "omni_phone_version"
                        controlId = "omni_phone_version"
                        label = {langText.Body.OmniPhoneAppVersion}
                        className="omniphone-version-not-found-message">
                        {langText.Message.OmniPhone_App_Version_NotFound}
                    </InlineForm.static>
                );
            }
        } 

        if (
            (state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "copy" ||
            state.modalType === "update") &&
            state.inbound_type === "android"
        ) {
            itemArr.push(
                <p key = "PasswordSettingAttention" className="text-danger">
                    {langText.Body.PasswordSettingAttention}
                </p>
            );
        }

        if (
            (state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "copy") &&
            state.inbound_type === "android"
        ) {
            itemArr.push(
                <InlineForm
                    key = "password"
                    controlId = "password"
                    label = {langText.Body.Password}
                    type = "password"
                    value = {state.password}
                    onChange = {e => onTextChange_Limit(e, "password",charaLimit.User_password)}
                    validationState = {(state.password || state.passwordRe) ? passwordValidationHandle("password", state) : true}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );

            itemArr.push(
                <InlineForm
                    key = "passwordRe"
                    controlId = "passwordRe"
                    label = {langText.Body.PasswordRe}
                    type = "password"
                    value = {state.passwordRe}
                    onChange = {e => onTextChange_Limit(e, "passwordRe",charaLimit.User_passwordRe)}
                    validationState = {(state.password || state.passwordRe) ? passwordValidationHandle("passwordRe", state) : true}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );

            if((state.password !== state.passwordRe) && (state.password.length > 0 && state.passwordRe.length > 0)){
                itemArr.push(
                    <p key = "passwordMismatch" className="text-danger">
                        {langText.Body.PasswordMismatch}
                    </p>
                );
            }
        }

        if (
            state.modalType === "update" &&
            state.inbound_type === "android" &&
            getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)
        ) {
            itemArr.push(
                <Checkbox
                    key = "password-reset-flag"
                    className = "password-reset-flag"
                    checked = {state.passwordResetFlag}
                    onChange = {e => onCheckBoxChange(e, "passwordResetFlag")}
                >
                    {langText.Body.ResetPassword}
                </Checkbox>
            )
        }

        if (state.passwordResetFlag) {
            itemArr.push(
                <InlineForm
                    key = "newPassword"
                    controlId = "newPassword"
                    label = {langText.Body.PasswordNew}
                    type = "password"
                    value = {state.newPassword}
                    onChange = {e => onTextChange_Limit(e, "newPassword",charaLimit.User_newPassword)}
                    validationState = {passwordValidationHandle("newPassword", state)}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );

            itemArr.push(
                <InlineForm
                    key = "newPasswordRe"
                    controlId = "newPasswordRe"
                    label = {langText.Body.PasswordNewRe}
                    type = "password"
                    value = {state.newPasswordRe}
                    onChange = {e => onTextChange_Limit(e, "newPasswordRe",charaLimit.User_newPasswordRe)}
                    validationState = {passwordValidationHandle("newPasswordRe", state)}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );

            if((state.newPassword !== state.newPasswordRe) && (state.newPassword.length > 0 && state.newPasswordRe.length > 0)){
                itemArr.push(
                    <p key = "passwordMismatch" className="text-danger">
                        {langText.Body.PasswordMismatch}
                    </p>
                );
            }
        }

        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="inbound_group_id"
                    controlId="inbound_group_id"
                    label={langText.Body.InboundGroupName}
                    type="select"
                    options={state.inboundGroupSelect}
                    value={state.inbound_group_id}
                    isClearable={true}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    onChange={e => onMultiSelectChange(e, "inbound_group_id")}
                    placeholder={
                        state.buttondisabledArr.inbound_load[0] ? (
                            <Glyphicon glyph="refresh" className="spinning" />
                        ) : (
                            langText.SelectOption.Placeholder
                        )
                    }
                    isDisabled={!state.buttondisabledArr.inbound_group_id[0]}
                />
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            let inbound_group = getinboundGroupName();
            itemArr.push(
                <InlineForm.static
                    key="inbound_group_id"
                    controlId="inbound_group_id"
                    label={langText.Body.InboundGroupName}
                >
                    {inbound_group ? inbound_group : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        //コーデック編集・観覧
        if (
            state.inbound_type === "softphone" &&
            (
                state.modalType === "insert" ||
                state.modalType === "insertBatch" ||
                state.modalType === "update" ||
                state.modalType === "copy"
            )
        ) {
            itemArr.push(
                <InlineForm
                    key = "codec_type"
                    controlId = "codec_type"
                    label = {langText.Body.Codec}
                    type = "select"
                    onChange = {e => onSelectChange(e, "codec_type")}
                    options = {state.softphoneCodecTypeSelect}
                    value = {state.codec_type}
                    placeholder = {langText.SelectOption.Placeholder}/>
            );
        } else if (state.inbound_type === "softphone" && state.modalType === "delete") {
            let value = state.softphoneCodecTypeSelect.find(row => row.value === state.codec_type)
            itemArr.push(
                <InlineForm.static
                    key = "codec_type"
                    controlId = "codec_type"
                    label = {langText.Body.Codec}>
                    {value && value.label ? value.label : ""}
                </InlineForm.static>
            );
        }

        //名称編集・観覧
        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="memo"
                    controlId="memo"
                    label={langText.Body.ExtensionName}
                    type="text"
                    value={state.memo}
                    onChange={e => onTextChange_Limit(e, "memo", charaLimit.Extension_memo)}
                    disabled={state.modalType === "delete"}
                />
            );
        } else if (
            state.modalType === "read" ||
            state.modalType === "delete"
        ) {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.ExtensionName}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        //メモ(説明)編集・観覧
        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="description"
                    controlId="description"
                    label={langText.Body.Memo}
                    componentClass = "textarea"
                    rows="3"
                    value={state.description}
                    onChange={e => onTextChange_Limit(e, "description", charaLimit.Extension_description)}
                />
            );
        } else if (
            state.modalType === "read" ||
            state.modalType === "delete"
        ) {
            itemArr.push(
                <InlineForm.static
                    key = "description"
                    controlId = "description"
                    label = {langText.Body.Memo}>
                    {state.description}
                </InlineForm.static>
            );
        }

        // モニタリング権限
        if (
            state.modalType === "insert" ||
            state.modalType === "insertBatch" ||
            state.modalType === "update" ||
            state.modalType === "copy"
        ) {
            itemArr.push(
                <InlineForm
                    key="monitoring_flg"
                    controlId="monitoring_flg"
                    label={langText.Body.MonitoringFlg}
                    validationState={validationHandle("monitoring_flg")}
                >
                    {state.monitoringFlgRadio.map((row, key) => (
                        <div key={key}>
                            <Col xs={10} className="inboundType-text">
                                <Radio
                                    name="monitoring_flg_radio"
                                    key={key}
                                    onChange={e => onRadioChange(e, "monitoring_flg")}
                                    checked={row.value === state.monitoring_flg}
                                    value={row.value}
                                >
                                    {row.label}
                                </Radio>
                            </Col>
                        </div>
                    ))}
                </InlineForm>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key="monitoring_flg"
                    controlId="monitoring_flg"
                    label={langText.Body.MonitoringFlg}
                >
                    {state.monitoringFlgRadio.map((row, key) =>
                        row.value === state.monitoring_flg ? row.label : ""
                    )}
                </InlineForm.static>
            );
        }

        // 一括削除内線番号リスト
        if (state.modalType === "multi_delete") {
            itemArr.push(
                <p key = "SelectMaxMessage" className="text-danger">
                    {this.sprintf(langText.Message.SelectMaxMessage, this.state.maxSelect)}
                </p>
            );
            itemArr.push(
                <InlineForm key="extension_ids" label={langText.MultiSelect.ExtensionSelect}>
                    <MultiSelect
                        key="extension_ids"
                        controlId="extension_ids"
                        propSetState={propSetState}
                        multiSelectText={langText.MultiSelect}
                        searchText={langText.MultiSelect.ExtensionAndMemoSearch}
                        options={state.extensionIdSelect}
                        value={state.extension_ids}
                        type={state.modalType}
                        isDisabled={state.modalType === "delete" || state.modalType === "read"}
                        multiSelectMessage={langText.MultiSelect.SelectDataNumStr_NDelete}
                        max={this.state.maxSelect}
                    />
                </InlineForm>
            );
        }

        return itemArr;
    }

    onClickHandle = () => {
        this.setState({
            show: false
        });
        this.props.propSetState({
            show: false
        });
        this.props.reactTableRefresh();
    }

    getModalInfo = () => {
        return (
            <Panel bsStyle="info">
                <Panel.Heading>
                    <Panel.Title>
                        {this.props.langText.Body.ExtensionNumberSetting}
                    </Panel.Title>
                </Panel.Heading>

                <Panel.Body>
                    {this.getExtensionNumberInfoItem()}
                </Panel.Body>
            </Panel>
        );
    }

    render() {
        return (
            <>
                {
                    this.props.state.modalType !== "multi_delete" ?
                    <AscModal
                        state={this.props.state}
                        propSetState={this.props.propSetState}
                        langText={this.props.langText}
                        validationHandle={this.props.validationHandle}
                        onClick={this.props.onClick}
                        omniphoneLoginStatusDelete={this.props.omniphoneLoginStatusDelete}
                    > 
                        {this.getModalInfo()}
                    </AscModal> :
                    <AscModal
                        key="extension-multi-delete-asc-modal"
                        state={this.props.state}
                        propSetState={this.props.propSetState}
                        langText={this.props.langText}
                        validationHandle={this.props.validationHandle}
                        customMsg={this.props.langText.Body.Check}
                        customFunc={() => {
                            this.setState({
                                show: true
                            });
                        }}
                        omniphoneLoginStatusDelete = {this.props.omniphoneLoginStatusDelete}
                    >
                        {this.getModalInfo()}
                    </AscModal>
                }
                {this.state.show &&
                    <ExtensionNumberCheckModal
                        state={this.state}
                        propsState={this.props.state}
                        propSetState={this.propSetState}
                        onClick={this.onClickHandle}
                        langText={this.props.langText}
                        extensionIds={this.props.state.extension_ids}
                        extensionIdSelect={this.props.state.extensionIdSelect}
                        omniphoneLoginStatusSearch={this.props.omniphoneLoginStatusSearch}
                        reactContainerPath={this.props.reactContainerPath}
                    />    
                }
            </>
        );
    }
}
