import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Alert, Button, Col, Row } from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import Select from "react-select";

/**
 * コール結果・ランク表示
 */
export class ViewCallResultElement extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            call_result_data: this.props.state.call_result_data ? { ...this.props.state.call_result_data } : null,
            call_rank_1_data: this.props.state.call_rank_1_data ? { ...this.props.state.call_rank_1_data } : null,
            call_rank_2_data: this.props.state.call_rank_2_data ? { ...this.props.state.call_rank_2_data } : null,
            call_result_rank_alert_flag: false
        }
    }

    onClickEditBtn = async () => {
        let {
            state,
            propSetState,
            updateCallResultRankData
        } = this.props;
        let call_result_data = this.state.call_result_data ? { ...this.state.call_result_data } : null;
        let call_rank_1_data = this.state.call_rank_1_data ? { ...this.state.call_rank_1_data } : null;
        let call_rank_2_data = this.state.call_rank_2_data ? { ...this.state.call_rank_2_data } : null;
        let call_result = call_result_data ? call_result_data.value : null;
        let call_rank_1 = call_rank_1_data ? call_rank_1_data.value : null;
        let call_rank_2 = call_rank_2_data ? call_rank_2_data.value : null;
        let body = {};

        await updateCallResultRankData({
            id: state.ct601_id,
            ct60_id: state.ct60_id,
            cm12_id: state.cm12_id,
            call_result: call_result,
            call_rank_1: call_rank_1,
            call_rank_2: call_rank_2
        });

        // 表示変更のため
        let data = [...state.data];
        let find_index = data.findIndex(row => row.id === state.ct60_id);
        
        if (find_index !== -1) {
            if (data[find_index].ct601_call_rank_results) {
                data[find_index].ct601_call_rank_results.cm64_call_results = call_result;
                data[find_index].ct601_call_rank_results.cm65_call_ranks = call_rank_1;
                data[find_index].ct601_call_rank_results.cm65_call_ranks_2 = call_rank_2;
            } else {
                data[find_index].ct601_call_rank_results = {
                    cm64_call_results: call_result,
                    cm65_call_ranks: call_rank_1,
                    cm65_call_ranks_2: call_rank_2
                }
            }
        }
        
        body = {
            data,
            call_result_data,
            call_rank_1_data,
            call_rank_2_data,
        };

        this.setState({call_result_rank_alert_flag: true});
        
        propSetState({
            ...body,
        });
    }

    onClickHandle = async (value, param) => {
        let {
            getCallRankList,
            propSetState
        } = this.props;
        let body = {[param]: value};

        if (param === "call_result_data") {
            body = {
                call_rank_1_data: null,
                call_rank_2_data: null,
                ...body
            };
            let [call_rank_1_list, call_rank_2_list] = await getCallRankList(value.value.id);

            propSetState({ call_rank_1_list, call_rank_2_list });
        }

        this.setState(body);
    }

    // アラート閉じる
    closeAlert = () => {
        this.setState({call_result_rank_alert_flag: false});
    }

    // コール結果・ランクバリデーション
    validationHandle = (param) => {
        let {
            call_result_list,
            call_rank_1_list,
            call_rank_2_list
        } = this.props.state;
        let {
            call_result_data,
            call_rank_1_data,
            call_rank_2_data
        } = this.state;
        let nowState = null;

        switch (param) {
            case "call_result_data":
                if (call_result_list && call_result_list.length > 0) {
                    nowState = this.state[param] ? null : 'error';
                }
                break;
            case "call_rank_1_data":
                if (call_rank_1_list && call_rank_1_list.length > 0) {
                    nowState = this.state[param] ? null : 'error';
                }
                break;
            case "call_rank_2_data":
                if (call_rank_2_list && call_rank_2_list.length > 0) {
                    nowState = this.state[param] ? null : 'error';
                }
                break;
            case "insert":
                nowState = !(
                    call_result_data &&
                    (call_rank_1_list && (call_rank_1_list.length === 0 || call_rank_1_data)) &&
                    (call_rank_2_list && (call_rank_2_list.length === 0 || call_rank_2_data))
                );
                break;
            default:
                break;
        }
        return nowState;
    }

    render() {
        let {
            state,
            langText,
            currentPermission
        } = this.props;

        return (
            <div>
                {
                    this.state.call_result_rank_alert_flag &&
                    <Alert bsStyle="info" onDismiss={this.closeAlert}>
                        {langText.Message.CallResultRankSaved}
                    </Alert>
                }
                <Row>
                    {
                        currentPermission && currentPermission.edit
                        ?
                        <>
                        <Col sm={12} md={3} lg={3} className="padding-0">
                            <InlineForm
                                key="callResult"
                                controlId="callResult"
                                className="padding-left-20 padding-top-05"
                                label={langText.Body.CallResult}
                                validationState={this.validationHandle("call_result_data")}
                            >
                                <Select
                                    className="call-result-rank-select-form"
                                    value={this.state.call_result_data}
                                    onChange={(e) => { this.onClickHandle(e, "call_result_data") }}
                                    options={state.call_result_list}
                                    placeholder={langText.SelectOption.Placeholder}
                                />
                            </InlineForm>
                        </Col>
                        <Col sm={12} md={3} lg={3} className="padding-0">
                            <InlineForm
                                key="callRank1"
                                controlId="callRank1"
                                className="padding-left-20 padding-top-05"
                                label={`${langText.Body.CallRank}1`}
                                validationState={this.validationHandle("call_rank_1_data")}
                            >
                                <Select
                                    className="call-result-rank-select-form"
                                    value={this.state.call_rank_1_data}
                                    onChange={(e) => { this.onClickHandle(e, "call_rank_1_data") }}
                                    options={state.call_rank_1_list}
                                    placeholder={langText.SelectOption.Placeholder}
                                    isClearable
                                />
                            </InlineForm>
                        </Col>
                        <Col sm={12} md={3} lg={3} className="padding-0">
                            <InlineForm
                                key="callRank2"
                                controlId="callRank2"
                                className="padding-left-20 padding-top-05"
                                label={`${langText.Body.CallRank}2`}
                                validationState={this.validationHandle("call_rank_2_data")}
                            >
                                <Select
                                    className="call-result-rank-select-form"
                                    value={this.state.call_rank_2_data}
                                    onChange={(e) => { this.onClickHandle(e, "call_rank_2_data") }}
                                    options={state.call_rank_2_list}
                                    placeholder={langText.SelectOption.Placeholder}
                                    isClearable
                                />
                            </InlineForm>
                        </Col>
                        <Col sm={12} md={3} lg={3} className="text-right margin-left-auto margin-top-40">
                            <Button
                                id="call-result-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={() => this.onClickEditBtn()}
                                disabled={this.validationHandle("insert")}
                            >
                                {langText.Body.SaveCallResultRank}
                            </Button>
                        </Col>
                        </>
                        :
                        <>
                        <Col sm={12} md={3} lg={3} className="padding-0">
                            <InlineForm.static
                                key="callResult"
                                controlId="callResult"
                                className="padding-left-20 padding-top-05"
                                label={langText.Body.CallResult}
                            >

                                {
                                    state.call_result_data ?
                                    state.call_result_data.label :
                                    langText.Body.Unregistered
                                }
                            </InlineForm.static>
                        </Col>
                        <Col sm={12} md={3} lg={3} className="padding-0">
                            <InlineForm.static
                                key="callRank1"
                                controlId="callRank1"
                                className="padding-left-20 padding-top-05"
                                label={`${langText.Body.CallRank}1`}
                            >
                                {
                                    state.call_rank_1_data ?
                                    state.call_rank_1_data.label :
                                    langText.Body.Unregistered
                                }
                            </InlineForm.static>
                        </Col>
                        <Col sm={12} md={3} lg={3} className="padding-0">
                            <InlineForm.static
                                key="callRank2"
                                controlId="callRank2"
                                className="padding-left-20 padding-top-05"
                                label={`${langText.Body.CallRank}2`}
                            >
                                {
                                    state.call_rank_2_data ?
                                    state.call_rank_2_data.label :
                                    langText.Body.Unregistered
                                }
                            </InlineForm.static>
                        </Col>
                        </> 
                    }
                </Row>
            </div>
        );
    }
}