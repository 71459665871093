import React, { Component } from 'react'
import AscModal from '../../Elements/Modal/Modal'
import InlineForm from '../../Elements/FromGroup/InlineForm';
import { Panel } from 'react-bootstrap';

export default class PredictvieCallStatisticsModal extends Component {
    getPredictiveCallInfoItem = () => {
        let {
            state,
            langText
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "task_name"
                controlId = "task_name"
                label = {langText.Body.AutoCallTaskName}
            >
                {state.task_name}
            </InlineForm.static>
        );

        if (state.list) {
            itemArr.push(
                <InlineForm.static
                    key = "list_name"
                    controlId = "list_name"
                    label = {langText.Body.List}
                >
                    {state.list_name}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    // getAutoCallRealTimeInfoItem = () => {
    //     let {
    //         state,
    //         langText
    //     } = this.props;
    //     let itemArr = [];

    //     itemArr.push(
    //         <InlineForm.static
    //             key = "wait_extension_number_count"
    //             controlId = "wait_extension_number_count"
    //             label = {langText.Body.AutoCallWaitExtensionNumber}
    //         >
                
    //         </InlineForm.static>
    //     );
    //     itemArr.push(
    //         <InlineForm.static
    //             key = "call_extension_number_count"
    //             controlId = "call_extension_number_count"
    //             label = {langText.Body.AutoCallExtensionNumber}
    //         >

    //         </InlineForm.static>
    //     );

    //     return itemArr;
    // }

    getAutoCallTotalInfoItem = () => {
        let {
            state,
            langText
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "call_count"
                controlId = "call_count"
                label = {langText.Body.AutoCallTotalOutboundCnt}
            >
                {state.call_count}
            </InlineForm.static>
        );
        itemArr.push(
            <InlineForm.static
                key = "connect_count"
                controlId = "connect_count"
                label = {langText.Body.AutoCallConnectCount}
            >
                {state.connect_count}
            </InlineForm.static>
        );
        itemArr.push(
            <InlineForm.static
                key = "disconnect_count"
                controlId = "disconnect_count"
                label = {langText.Body.AutoCallGiveUp}
            >
                {state.disconnect_count}
            </InlineForm.static>
        );
        // itemArr.push(
        //     <InlineForm.static
        //         key = "total_call_time"
        //         controlId = "total_call_time"
        //         label = {langText.Body.TotalCallTime}
        //     >
        //         {state.call_time}
        //     </InlineForm.static>
        // );

        return itemArr;
    }
    
    getAutoCallStatisticsInfoItem = () => {
        let {
            state,
            langText
        } = this.props;
        let itemArr =[];
        
        itemArr.push(
            <InlineForm.static
                key = "connect_rate"
                controlId = "connect_rate"
                label = {langText.Body.AutoCallConnectRate}
            >
                {state && state.connect_rate && !isNaN(state.connect_rate) ? `${state.connect_rate}%` : ""}
            </InlineForm.static>
        );
        itemArr.push(
            <InlineForm.static
                key = "disconnect_rate"
                controlId = "disconnect_rate"
                label = {langText.Body.AutoCallDisconnectRate}
            >
                {state && state.disconnect_rate && !isNaN(state.disconnect_rate) ? `${state.disconnect_rate}%` : ""}
            </InlineForm.static>
        );
        // itemArr.push(
        //     <InlineForm.static
        //         key = "avg_call_time"
        //         controlId = "avg_call_time"
        //         label = {langText.Body.AutoCallAvgCallTime}
        //     >
        //         {state.avg_call_time}
        //     </InlineForm.static>
        // );
        // itemArr.push(
        //     <InlineForm.static
                
        //         key = "avg_connect_time"
        //         controlId = "avg_connect_time"
        //         label = {langText.Body.AutoCallAvgWaitTime}
        //     >
        //         {state.avg_connect_time}
        //     </InlineForm.static>
        // );
        // itemArr.push(
        //     <InlineForm.static
        //         key = "avg_disconnect_time"
        //         controlId = "avg_disconnect_time"
        //         label = {langText.Body.AutoCallAvgGiveUpTime}
        //     >
        //         {state.avg_disconnect_time}
        //     </InlineForm.static>
        // );

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                onClick = {this.props.onClick}
                validationHandle = {this.props.validationHandle}
            >
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.PredictiveCall}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getPredictiveCallInfoItem()}
                    </Panel.Body>
                </Panel>
                {/* {
                    this.props.state && this.props.state.modalType === "statistics" &&
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.langText.Body.AutoCallRealTimeInfo}
                            </Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            {this.getAutoCallRealTimeInfoItem()}
                        </Panel.Body>
                    </Panel>
                } */}
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.AutoCallTotal}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getAutoCallTotalInfoItem()}
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.AutoCallStatisticsInfo}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getAutoCallStatisticsInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        )
    }
}
