import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Col, ControlLabel, Glyphicon} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import ASSelect from "../Elements/Select/Select";
import InlineForm from "../Elements/FromGroup/InlineForm"
import DatePicker from "react-datepicker";
import "../Elements/FromGroup/form_select.css"
import moment from "moment";


export default class ExternalNumberScheduleExtensionModal extends Component {

    constructor(props) {
        super(props);
        this.hour_list = [
            {value :"00",label:"00"},
            {value :"01",label:"01"},
            {value :"02",label:"02"},
            {value :"03",label:"03"},
            {value :"04",label:"04"},
            {value :"05",label:"05"},
            {value :"06",label:"06"},
            {value :"07",label:"07"},
            {value :"08",label:"08"},
            {value :"09",label:"09"},
            {value :"10",label:"10"},
            {value :"11",label:"11"},
            {value :"12",label:"12"},
            {value :"13",label:"13"},
            {value :"14",label:"14"},
            {value :"15",label:"15"},
            {value :"16",label:"16"},
            {value :"17",label:"17"},
            {value :"18",label:"18"},
            {value :"19",label:"19"},
            {value :"20",label:"20"},
            {value :"21",label:"21"},
            {value :"22",label:"22"},
            {value :"23",label:"23"},
        ];
        // 分
        this.min_list = [
            {value :"00",label:"00"},
            {value :"05",label:"05"},
            {value :"10",label:"10"},
            {value :"15",label:"15"},
            {value :"20",label:"20"},
            {value :"25",label:"25"},
            {value :"30",label:"30"},
            {value :"35",label:"35"},
            {value :"40",label:"40"},
            {value :"45",label:"45"},
            {value :"50",label:"50"},
            {value :"55",label:"55"},
        ];
        // 時間と分のセレクトボックスのサイズ設定
        this.customStyles = {
            menuList: (provided) => ({
                ...provided,
                maxHeight: '200px',
                overflowY: 'auto'
            })
        };
    }

    getScheduleExtensionInfoItem() {
        let {
            state,
            langText,
            systemParameters,
            validationHandle,
            onDateChangeHandle
        } = this.props;

        let itemArr = [];
        if(!state.date_validation){
            itemArr.push(
                <div
                    key = "schedule-extension-validation"
                    className = "schedule-extension-validation"
                >
                    {langText.Message[state.validate_msg]}
                </div>
            );
        }
        itemArr.push(
            <InlineForm
                key = "start-datetime"
                label = {langText.Body.ExtensionStartDateTime}
                componentClass = {ControlLabel}
                validationState = {validationHandle("start_datetime")}
            >
                <Col xs={11} sm={11} md={4} lg={4}>
                    <span className="start-datetime">
                        <DatePicker
                            onChange = {e => onDateChangeHandle(e, "start_datetime")}
                            selected = {state.start_datetime ? moment(state.start_datetime) : null}
                            dateFormat = "YYYY-MM-DD"
                            className = "form-control"
                            todayButton = {this.props.langText.Body.Today}
                            placeholderText = {this.props.langText.Body.StartDate}
                            minDate = {moment({h:0, m:0, s:0, ms:0})}
                            isClearable = {true}
                        />
                    </span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="start_time_hour"
                        validationState = {validationHandle("start_time_hour")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            value={state.start_time_hour}
                            options={this.hour_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, "start_time_hour")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="start_time_minutes"
                        validationState = {validationHandle("start_time_minutes")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="minutes-select"
                            value={state.start_time_minutes}
                            options={this.min_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, "start_time_minutes")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                </Col>
            </InlineForm>
        );

        itemArr.push(
            <InlineForm
                key = "end-datetime"
                label = {langText.Body.ExtensionEndDateTime}
                componentClass = {ControlLabel}
                validationState = {validationHandle("end_datetime")}
            >
                <Col xs={11} sm={11} md={4} lg={4}>
                    <DatePicker
                        onChange = {e => onDateChangeHandle(e, "end_datetime")}
                        selected = {state.end_datetime ? moment(state.end_datetime) : null}
                        dateFormat = "YYYY-MM-DD"
                        className = "form-control"
                        todayButton = {this.props.langText.Body.Today}
                        placeholderText = {this.props.langText.Body.EndDate}
                        minDate = {state.start_datetime ? moment(state.start_datetime) : null}
                        maxDate = {moment(state.start_datetime).add(1,'weeks')}
                        isClearable = {true}
                    />
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="end_time_hour"
                        validationState = {validationHandle("end_time_hour")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            styles={this.customStyles}
                            value={state.end_time_hour}
                            options={this.hour_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, "end_time_hour")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Hour}</span>
                </Col>
                <Col xs={5} sm={5} md={2} lg={2}>
                    <ASFieldGroup
                        key="end_time_minutes"
                        validationState = {validationHandle("end_time_minutes")}
                    >
                        <ASSelect
                            readOnly={this.read}
                            className="hour-select"
                            styles={this.customStyles}
                            value={state.end_time_minutes}
                            options={this.min_list}
                            placeholder="---"
                            onChange = {e => onDateChangeHandle(e, "end_time_minutes")}
                            isDisabled={this.read}/>
                    </ASFieldGroup>
                </Col>
                <Col xs={1} sm={1} md={1} lg={1}>
                    <span className="calendar-Modal_TimeCss">{this.props.langText.Body.Minute}</span>
                </Col>
            </InlineForm>
        );

        itemArr.push(
            <InlineForm
                key = "schedule_extenxion_group"
                controlId = "schedule_extenxion_group"
                label = {langText.Body.InboundGroupName}
                type = "select"
                onChange = {e => this.props.onMultiSelectChangeExternal(e, "schedule_extenxion_group")}
                options = {state.inboundGroupSelect}
                value = {state.schedule_extenxion_group}
                isClearable = {true}
                validationState = {validationHandle("schedule_extenxion_group")}
                isMulti = {true}
                closeMenuOnSelect={false}
                placeholder = {this.props.propState.buttondisabledArr.inbound_group_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                isDisabled = {!this.props.propState.buttondisabledArr.inbound_group_id[0]}
                />
        );
        let drawdata = '';
        let totalExtensionCount = 0;
        if (
                ( state.modalType === "update" || state.modalType === "copy" ) &&
                state.schedule_extenxion_group.length > 0 &&
                state.inboundGroupcount === 0
        ) {
            // 編集orコピーモーダルかつ初期表示の場合、選択済み内線グループの合計内線数を設定
            state.schedule_extenxion_group.forEach(selectedGroupValue => {
                totalExtensionCount += state.inboundGroupSelect.find(allGroup => allGroup.value === selectedGroupValue).count;
            });
        } else {
            totalExtensionCount = state.inboundGroupcount;
        }

        let drawcolor = 'black';
        if(totalExtensionCount > systemParameters.EXTEN_COUNT)
        {
            drawdata = totalExtensionCount + " / " + systemParameters.EXTEN_COUNT + " " + langText.Body.ExtenCountOver_1 + systemParameters.EXTEN_COUNT + langText.Body.ExtenCountOver_2;
            drawcolor = 'red';
        }
        else
        {
            drawdata = totalExtensionCount + " / " + systemParameters.EXTEN_COUNT;
            drawcolor = 'black';
        }
        itemArr.push(
            <InlineForm.static
                key = "exten_count"
                controlId = "exten_count"
                label = {langText.Body.ExtenCount}>
                    <font color={drawcolor}>
                        {drawdata}
                    </font>
            </InlineForm.static>
        )

        return itemArr
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ScheduleExtensionGroupSettings}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getScheduleExtensionInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
