import React, {Component} from "react";
import {Panel, Button} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css";

import {ViewVersionMemo, ViewVersionCreated} from "../Elements/AscElements/ScriptElements"

export default class ScriptSettingModal extends Component {
    render() {
        let {
            state,
            propSetState,
            langText,
            validationHandle,
            scope_code,
            onSelectChange,
            onTextChange,
            onClick
        }=this.props

        return (
            <AscModal
                state = {state}
                propSetState = {propSetState}
                langText = {langText}
                validationHandle = {validationHandle}
                onClick = {onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {langText.Body.ScriptInfo}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <InlineForm
                            label = {this.props.langText.Body.ScriptName}
                            type = "text"
                            value = {this.props.state.script_name}
                            onChange = {e => this.props.onTextChange(e, "script_name", this.props.charaLimit.Script_name)}
                            validationState = {this.props.state.script_name ? null : "error"}/>
                        <InlineForm
                            label = {this.props.langText.Body.Description}
                            type = "text"
                            value = {this.props.state.description}
                            onChange = {e => this.props.onTextChange(e, "description", this.props.charaLimit.Script_description)}/>
                        <div className="form-inline" style={{marginBottom: "15px"}}>
                            <InlineForm
                                label={langText.Body.Version}
                                labelStyle={{paddingLeft: 0}}
                                type="select"
                                value={this.props.state.version}
                                options={this.props.state.version_list}
                                onChange={e => onSelectChange(e, 'version')}
                                placeholder = {langText.SelectOption.Placeholder_None_Select}/>
                            <Button 
                                bsStyle="info"
                                style={{float: "right", marginTop: "25px"}}
                                onClick={this.props.templateShow(this.props.state)}>
                                {this.props.langText.Body.ScriptTemplateCreate}
                            </Button>
                        </div>
                        <ViewVersionMemo
                            state={state}
                            langText={langText}/>
                        <ViewVersionCreated
                            state={state}
                            langText={langText}/>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
