import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import CompanyModal from "./CompanyModal";

export default class Company extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            // pbxSelect: this.getPbxSelect(),
            pbxSelect: [],
            outboundPrefixSelect: this.getSelectOption('outbound_prefix', this.props.langText.SelectOption),

            //会社パラメータ
            companyControlParameters:[],
            //全ての追加オプションパラメータ
            addingOptionParametersAll: this.getCompanyControlParametersOption('ADDING_OPTIONS', this.props.langText.CompanyControlParametersOption),
            //追加オプションのラジオボタン設定値の情報
            addingOptionSettingValueInfos: this.getCompanyControlParametersOption('ADDING_OPTION_SETTING_VALUE_INFOS', this.props.langText.CompanyControlParametersOption),
            //各追加オプションの使用中データ
            addingOptionUsingData: {},

            // data item
            companyId: "",
            companyName: "",
            prefix: "",
            outboundPrefix: "",
            pbx: [],
            password: "",
            autoPass: false,
            memo: "",
            omniURL: "",
            cm12_floor_flg: "",
            lineTypeSelectOriginal: [],
            lineTypeSelect: [],
            lineTypeInfoSelected: [],
            lineTypeInfos: [],

            // button_disabled
            buttondisabledArr: {
                "pbx" : []
            },
            voice_log_expire_select: this.getSelectOption("voice_log_expire", this.props.langText.SelectOption),
            isSetloginPasswordExpireDays: false,
            loginPasswordExpireDays: "",
            call_analysis_api_select: this.props.langText.CompanyControlParametersOption["call_analysis_api_select"],
            passreset_flag : false,
            prefixDuplicateFlag : false,
            companyNameDuplicateFlag : false
        };


    }

    getColumnsData() {
        return [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                filterable: false,
                width: this.props.boardWidth.id,
            }, {
                Header: this.props.langText.Body.CompanyName,
                accessor: "company_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "company_name")} />
            }, {
                Header: this.props.langText.Body.Prefix,
                accessor: "company_prefix",
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "company_prefix")} />
            }, {
                Header: this.props.langText.Body.OutboundPrefix,
                accessor: "outbound_prefix",
                width: this.props.boardWidth.small,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "outbound_prefix")} />
            }, {
                Header: this.props.langText.Body.Pbx,
                accessor: "ct92_assign_pbx_infos.cm92_pbx_infos.pbx_name",
                filterable: true,
                Filter: () => <FormControl onChange={e =>
                    this.onFilterChange(e.target.value, "ct92_assign_pbx_infos.cm92_pbx_infos.pbx_name")} />,
                width: this.props.boardWidth.medium,
                Cell: data => {
                    let pbxInfos = data.row._original.cm92_pbx_infos;
                    let elemArr = [];

                    pbxInfos.forEach((pbxData, key) => {
                        elemArr.push(
                            <p key={key}>
                                {pbxData.pbx_name}
                            </p>
                        );
                    });

                    return elemArr;
                }
            }, {
                Header: this.props.langText.Body.Password,
                accessor: "common_password",
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "common_password")} />
            }, {
                Header: this.props.langText.Body.Memo,
                accessor: "memo",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
            }, {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];

                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <Button
                                key={0}
                                bsSize="xsmall"
                                onClick={this.modalShow('update', data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="pencil"/>
                            </Button>
                        );
                    } else {
                        rowData.push(
                            <Button
                                key={1}
                                bsSize="xsmall"
                                onClick={this.modalShow('read', data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="eye-open"/>
                            </Button>
                        );
                    }

                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <Button
                                key={2}
                                bsSize="xsmall"
                                onClick={this.modalShow('delete', data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="minus"/>
                            </Button>
                        );
                    }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            }
        ]
    }

    getPbxSelect = () => {
        this
            .ascAxios('post', `Common/pbxSelect`, {
                container: this.reactContainerPath,
            })
            .then(result => {
                this.common_buttondisabledLengthCheck(`pbx`,`push`);
                this.setState({pbxSelect: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };

    getCompanyControlParameters = (cm12_id) => {
        this
            .ascAxios('post', `Common/getCompanyControlParameters`, {
                cm12_id,
            })
            .then(result => {
                this.setState({companyControlParameters: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };


    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    // 会社名の重複チェック
    checkCompanyNameDuplicate = async () => {
        if (this.state.companyName === "") {
            //未入力の場合はチェックしない
            this.setState({companyNameDuplicateFlag: false});
            return;
        }
        try{
            let res = await this.ascAxios(
                "post",
                `${this.reactContainerPath}/checkCompanyNameDuplicate`,
                {
                    companyName: this.state.companyName,
                    companyId: this.state.companyId
                }
            );
            this.setState({companyNameDuplicateFlag: res.data.isDuplicate});
        } catch (err) {
            this.setState({companyName: ""});
            this.showErrorObjectMesssage(err);
        }
    }

    checkDuplicatePrefix = () => {
        if (this.state.prefix === "") {
            //未入力の場合はチェックしない
            this.propSetState({prefixDuplicateFlag: false});
            return;
        }

        this.ascAxios('post', `${this.reactContainerPath}/checkDuplicatePrefix`, {
            prefix: this.state.prefix
        })
        .then(res => {
            this.setState({prefixDuplicateFlag: res.data.isDuplicate})
        })
        .catch(err => {
            this.setState({prefix: ""})
            alert(this.getErrorString(err.response.data));
        })
    }

    onIntChange = (param, maxlength) => (event) => {
        if (event.target.value.length <= maxlength) {
            let value = event ? event.target.value : "";
            if (value.length === 1 && value === "0") {
                return;
            }
            value = value.replace(/[^0-9]+/i, "");
            if (value !== "") {
                value = Number(value);
            }
            this.setState({ [param]: value });
        }
    };
    getLineTypeSelectData = async () => {
        try {
            let result = await this.ascAxios('post', `Common/getLineTypes`, {parameterId: "LINE_TYPE"});
            
            this.setState({
                lineTypeSelect: result.data,
                lineTypeSelectOriginal: result.data
            });
        } catch (err) {
            alert(this.getErrorString(err.response.data));
        }
    }

    lineTypeSelectSet = (lineTypeInfos) => {
        let selectArr = [],
            template = this.state.lineTypeSelectOriginal,
            oriLineTypeSelect = template.slice(),
            selectValues = lineTypeInfos.map(row => {
                return row.line_type;
            });

        // オリジナル選択欄から選択されたもの削除
        lineTypeInfos.forEach(selected => {
            oriLineTypeSelect = oriLineTypeSelect.filter(row => row.value !== selected.line_type);
        });

        selectValues.forEach((row, index) => {
            let nowTemplate = [...template],
                nowSetSelect = [...selectValues];

            // 現在選択されたArrから今のデータ削除
            nowSetSelect.splice(index, 1);

            // Templateから選択されたデータ削除
            nowSetSelect.forEach(selected => {
                if (selected) {
                    let selectedIndex = nowTemplate.findIndex(data => data.value === selected);

                    if (selectedIndex !== -1) {
                        nowTemplate.splice(selectedIndex, 1);
                    }
                }
            });

            selectArr.push(nowTemplate);
        });

        this.propSetState({
            lineTypeInfoSelected: selectArr,
            lineTypeSelect: oriLineTypeSelect
        });
    }

    lineTypeInfoSet = (event, param, index) => {
        let {
            lineTypeInfos
        } = this.state;

        let myValue = null;

        if(event != null){
            myValue = event.value || event.target.value;
        }

        if (param === "line_type") {
            lineTypeInfos[index].line_type = myValue;
            lineTypeInfos[index].channel_number = "";
            
            this.lineTypeSelectSet(lineTypeInfos);
            this.propSetState({lineTypeInfos});
        }
    }

    lineTypeInfos_onTextChange_Limit = (event, param, index, limitcount) => {
        let myValue = null;

        if(event != null){
            myValue = event.value || event.target.value;
        }

        if (myValue.length <= limitcount) {
            this.state.lineTypeInfos[index][param] = myValue.replace(/^0+|[^0-9]+/g,'');
        }

        this.propSetState({lineTypeInfos: this.state.lineTypeInfos});
    }

    getLineTypeInfoData = async (companyId) => {
        try {
            let result = await this.ascAxios('post', `${this.reactContainerPath}/getLineTypeInfoData`, {
                companyId: companyId
            });

            this.lineTypeSelectSet(result.data);
            this.setState({lineTypeInfos: result.data});
        } catch (err) {
            alert(this.getErrorString(err));
        }
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();
        //会社システムパラメータ取得
        //デフォルト設定
        let setData = {
            companyId: param && param.id
                ? param.id
                : "",
            companyName: param && param.company_name
                ? param.company_name
                : "",
            companyNameDuplicateFlag: false,
            prefix: param && param.company_prefix
                ? param.company_prefix
                : "",
            prefixDuplicateFlag : false,
            outboundPrefix: param && param.outbound_prefix
                ? param.outbound_prefix
                : "",
            outboundPrefixView: param && param.outbound_prefix
                ? this.state.outboundPrefixSelect.find(row => row.value === param.outbound_prefix)
                : "",
            pbx: param && param.cm92_pbx_infos
                ? param.cm92_pbx_infos.map(row => {return row.id})
                : [],
            password: param && param.common_password
                ? param.common_password
                : "",
            memo: param && param.memo
                ? param.memo
                : "",
            omniURL: param && param.omni_contact_url
                ? param.omni_contact_url
                : "",
            cm12_floor_flg: param && param.floor_flg
                ? param.floor_flg
                : "",
            voice_log_expire: param && param.voice_log_expire
                ? param.voice_log_expire
                : modalType === "insert" ? 24 : 999,
            isSetloginPasswordExpireDays: param && param.login_password_expire_days,
            loginPasswordExpireDays: param && param.login_password_expire_days
                ? param.login_password_expire_days
                : "",
            autoPass: false,
            companyControlParameters: [],
            addingOptionUsingData: {},
            companyUsingData: "",
            companyDeleteFlag: true,
            modalType,
            show: true
        };

        if(modalType === "update"){
            // 各追加オプションの使用中データ一覧を取得
            try{
                let result = await this.ascAxios('post', `${this.reactContainerPath}/getAddingOptionUsingData`, {
                    companyId: setData.companyId
                })
                setData.addingOptionUsingData = result.data;
            } catch(err) {
                alert(this.getErrorString(err));
            }
        }

        //PBX管理画面のOEM設定で使用中の会社かを確認して、使用中の場合会社削除を不可にするバリデーション追加
        if(modalType === "delete"){
            try {
                let getPbxOemUsing = await this.ascAxios('post', `${this.reactContainerPath}/getPbxOemUsing`, {companyId : param.id});
                let getPbxOemUsingData = getPbxOemUsing.data;
                let getPbxOemUsingNull = getPbxOemUsingData.every(element => element.length === 0);
                let pbxNameArr = [];
                if(!getPbxOemUsingNull){
                    setData.companyDeleteFlag = false;
                    if(getPbxOemUsingData){
                        getPbxOemUsingData.forEach(element => {
                            pbxNameArr.push(element.pbx_name);
                        });
                    }
                    let pbxNameArrString = pbxNameArr.join(",　");
                    setData.companyUsingData = pbxNameArrString;
                }
            } catch (err) {
                console.error(err)
                this.showErrorObjectMesssage(err, "GetDataError");
            }
        }

        this.setState(setData);
        this.getPbxSelect();
        await this.getLineTypeSelectData();
        await this.getLineTypeInfoData(setData.companyId);
        if (modalType !== "insert") {
            this.getCompanyControlParameters(setData.companyId);
        } else {
            // 新規登録の場合、state.companyControlParametersに全オプションパラメーターの値を設定
            this.setState({
              companyControlParameters: this.getCompanyControlParametersOption(
                "ADDING_OPTIONS",
                this.props.langText.CompanyControlParametersOption
              )
            });
        }
    };

    onClickHandle = modalType => event => {
        let {
            companyId,
            companyName,
            prefix,
            outboundPrefix,
            pbx,
            password,
            autoPass,
            memo,
            omniURL,
            companyControlParameters,
            voice_log_expire,
            loginPasswordExpireDays,
            lineTypeInfos,
            companyDeleteFlag
        } = this.state;

        //floor_flgはシステムパラメーターを採用
        const floor_flg = this.props.systemParameters.SYSTEM_FLOOR_FLG;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        companyId,
                        companyName,
                        prefix,
                        outboundPrefix,
                        pbx,
                        password,
                        autoPass,
                        memo,
                        omniURL,
                        companyControlParameters,
                        floor_flg,
                        voice_log_expire,
                        loginPasswordExpireDays,
                        lineTypeInfos
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    })
                    .catch(err => {
                        this.showErrorObjectMesssage(err)
                        if(err.response.data.code === "Company_PrefixDuplicate"){
                            // プレフィックス重複でエラーとなった場合、プレフィックスは空白状態にする
                            this.setState({ prefix: "" });
                        }
                    });
                break;

            case "update":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        companyId,
                        companyName,
                        prefix,
                        outboundPrefix,
                        pbx,
                        password,
                        memo,
                        omniURL,
                        companyControlParameters,
                        voice_log_expire,
                        loginPasswordExpireDays,
                        lineTypeInfos
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "delete":
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/delete`, {
                        companyId,
                        companyName,
                        prefix,
                        outboundPrefix,
                        pbx,
                        password,
                        memo,
                        omniURL,
                        lineTypeInfos,
                        companyDeleteFlag
                    })
                    .then(result => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                        this.setState({ show: false });
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;
            default:
                alert(this.getErrorString({code: 'modal'}));
                this.setState({
                    show: false
                });
                return;
        }
    };

    validationHandle = (param, index = null) => {
        let {
            outboundPrefix,
            companyId,
            companyName,
            companyNameDuplicateFlag,
            prefix,
            prefixDuplicateFlag,
            pbx,
            password,
            autoPass,
            cm12_floor_flg,
            isSetloginPasswordExpireDays,
            loginPasswordExpireDays,
            companyDeleteFlag
        } = this.state;

        let nowState = null;
        let floor_flg = this.props.systemParameters.SYSTEM_FLOOR_FLG;
        let prefixLength = floor_flg === "Y" ? 2 : 3;
        let updatePrefixLength = cm12_floor_flg === "Y" ? 2 : 3;

        switch (param) {
            case "companyName":
                nowState = this.state[param] && !companyNameDuplicateFlag
                    ? null
                    : "error";
                break;

            case "memo":
            case "outboundPrefix":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;

            case "prefix":
                // プレフィックスの桁数が規定値と合わない または
                // すでに使用されているプレフィックスが存在する場合
                if(this.state[param].length !== prefixLength || prefixDuplicateFlag || 
                    !this.isSystemUsedPrefix(this.state[param])){
                    nowState = "error";
                }
                break;

            case "pbx":
                nowState = this.state[param] && this.state[param].length
                    ? null
                    : "error";
                break;

            case "password":
                nowState = this.state[param] || autoPass
                    ? null
                    : "error";
                break;

            case "loginPasswordExpireDays":
                nowState = isSetloginPasswordExpireDays && this.state[param]
                    ? null
                    : "error";
                break;

            case "line_type":
            case "channel_number":
                if (this.state.lineTypeInfos[index] && this.state.lineTypeInfos[index][param]) {
                    nowState = null;
                } else {
                    nowState = "error";
                }
                break;

            case "insert":
                nowState = (
                    companyName
                    && !companyNameDuplicateFlag
                    && prefix.length === prefixLength
                    && !prefixDuplicateFlag
                    && this.isSystemUsedPrefix(prefix)
                    && pbx
                    && pbx.length
                    && (autoPass == true || password)
                    && outboundPrefix
                    && (isSetloginPasswordExpireDays ? loginPasswordExpireDays : true)
                );
                this.state.lineTypeInfos.forEach(row => {
                    if (!row.line_type || !row.channel_number) {
                        nowState = false;
                    }
                });
                break;

            case "update":
                nowState = (
                    companyName
                    && !companyNameDuplicateFlag
                    && pbx
                    && pbx.length
                    && (autoPass == true || password)
                    && (isSetloginPasswordExpireDays ? loginPasswordExpireDays : true)
                );
                this.state.lineTypeInfos.forEach(row => {
                    if (!row.line_type || !row.channel_number) {
                        nowState = false;
                    }
                });
                break;

            case "delete":
                nowState = companyDeleteFlag;
                break;
            default:
        }

        return nowState;
    };

    render() {
        return (
            <div className="Company">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="company-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <CompanyModal
                    systemFloorFlg = {this.props.systemParameters.SYSTEM_FLOOR_FLG}
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onIntChange = {this.onIntChange}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange_Init = {this.onCheckBoxChange_Init}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    validationHandle = {this.validationHandle}
                    getCompanyControlParametersOption = {this.getCompanyControlParametersOption}
                    checkDuplicatePrefix = {this.checkDuplicatePrefix}
                    checkCompanyNameDuplicate = {this.checkCompanyNameDuplicate}
                    onLineTypeChange = {this.lineTypeInfoSet}
                    lineTypeInfos_onTextChange_Limit = {this.lineTypeInfos_onTextChange_Limit}
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    lineTypeSelectSet = {this.lineTypeSelectSet}
                    />
            </div>
        );
    }
}
