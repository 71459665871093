import React from "react";
import {Panel} from "react-bootstrap";
import Component from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";


export default class Summary extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            show: false,
            eval_flag: false,
        };
    }

    componentDidMount = async () => {
        await this.getCompanyControlByCompanyId("CALL_EVAL", "CALL_EVAL_OPTION");
    }

    panelOnClick = (path) => {
        this.props.historyPush({
            pathname: path,
        });
    };

    getCompanyControlByCompanyId = async (function_id, parameter_id) => {
        const company_id = this.props.userInfo.company_id;

        try {
            const result = await this.ascAxios('post', `${this.reactContainerPath}/getCompanyControlCallEvalByCompanyId`, {
                company_id,
                function_id,
                parameter_id
            });

            let flag = result.data && result.data.parameter_value === "Y" ? true : false;

            this.setState({
                eval_flag: flag
            });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }


    render() {
        const monitoringPermission= this.props.userInfo.permission_json_data.find(
            (permission) => permission.controller_id === "Monitoring"
        );
        const callAnalysisSummaryPermission = (this.props.userInfo.permission_json_data.find(
            (permission) => permission.controller_id === "Summary"
        )).scope_code === "system" || this.state.eval_flag;

        return (
            <React.Fragment>
                <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.Summary }]} />
                <div className="flex-style">
                    <Panel
                        bsStyle="info"
                        bsSize="large"
                        className="summary-select"
                        onClick={() => this.panelOnClick("Summary/ChUsedSummary")}
                    >
                        <Panel.Heading>
                            <Panel.Title componentClass="h1">
                                {this.props.langText.Body.ChUsedSummary}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className="body-summary-select">
                            {this.props.langText.Body.ChUsedSummaryDescription}
                        </Panel.Body>
                    </Panel>

                    {monitoringPermission && monitoringPermission.read &&
                        <Panel
                            bsStyle="info"
                            bsSize="large"
                            className="summary-select"
                            onClick={() => this.panelOnClick("Summary/MonitoringSummary")}
                        >
                            <Panel.Heading>
                                <Panel.Title componentClass="h1">
                                    {this.props.langText.Body.MonitoringSummary}
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body className="body-summary-select">
                                {this.props.langText.Body.MonitoringSummaryDescription}
                            </Panel.Body>
                        </Panel>
                    }

                    <Panel
                        bsStyle="info"
                        bsSize="large"
                        className="summary-select"
                        onClick={() => this.panelOnClick("Summary/CallCountSummary")}
                    >
                        <Panel.Heading>
                            <Panel.Title componentClass="h1">
                                {this.props.langText.Body.CallCountSummary}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className="body-summary-select">
                            {this.props.langText.Body.CallCountSummaryDescription}
                        </Panel.Body>
                    </Panel>
                </div>
                <div className="flex-style">
                    <Panel
                        bsStyle="info"
                        bsSize="large"
                        className="summary-select"
                        onClick={() => this.panelOnClick("Summary/WaitCallSummary")}
                    >
                        <Panel.Heading>
                            <Panel.Title componentClass="h1">
                                {this.props.langText.Body.WaitCallSummary}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className="body-summary-select">
                            {this.props.langText.Body.WaitCallSummaryDescription}
                        </Panel.Body>
                    </Panel>
                    {callAnalysisSummaryPermission &&
                        <Panel
                            bsStyle="info"
                            bsSize="large"
                            className="summary-select"
                            onClick={() => this.panelOnClick("Summary/CallAnalysisSummary")}
                        >
                            <Panel.Heading>
                                <Panel.Title componentClass="h1">
                                    {this.props.langText.Body.CallAnalysisSummary}
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body className="body-summary-select">
                                {this.props.langText.Body.CallAnalysisSummaryDescription}
                            </Panel.Body>
                        </Panel>
                    }
                </div>
            </React.Fragment>
        );
    }
}
