import React from "react";
import {Button, Row, Col, Dropdown} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../Elements/Table/CommonTable";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import * as GlobalConst from "../../components/AscConstants";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import InlineForm from "../Elements/FromGroup/InlineForm";


export default class WaitStatuses extends AscComponent {
    constructor(props) {
        super(props);
        const now = moment();
        this.toggleBlocking = this.toggleBlocking.bind(this);

        const defaultFilter = [
            {
                id: "created",
                value: moment({h:0, m:0, s:0, ms:0})
            },
            {
                id: "disposition",
                value: {value:"WAIT", label:this.props.langText.Body.StatusCallWaiting}
            }
        ]

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            count: null,
            loading: false,
            maxDate: now,
            filtered: defaultFilter,
            hidden_filtered: defaultFilter,

            externalarr: [],
            display_number:[],

            blocking: false,

            // 自動更新間隔のデフォルトを5秒に設定
            interval: setInterval(() => {
                this.onSearch(defaultFilter);
            }, 5000),

            autoUpdateInterval: 5000,
            callWaitingAlertTime: 30
        };

        if (this.props.isAuthenticated && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            switch (this.props.currentPermission.scope_code) {
                case GlobalConst.SCOPE_OBJECT.company.name:
                    this.getExternal(this.props.userInfo.company_id)

                    break;
                case GlobalConst.SCOPE_OBJECT.department.name:
                    let cm13_id = this.props.userInfo.department_id,
                        body = {
                            company_id: this.props.userInfo.company_id,
                            department_prefix: this.props.userInfo.department_prefix,
                            floor_flg: this.props.userInfo.floor_flg
                        };
                    this.getExternal(cm13_id, body);    

                    break;
                case GlobalConst.SCOPE_OBJECT.floor.name:
                    this.getExternal(this.props.userInfo.department_id);

                    break;
                default:
                    break;
            }
        }
        this.callResultSelect = this.getSelectOption("call_monitoring_result", this.props.langText.SelectOption);

        this.table = React.createRef();
    }

    // 初期作業
    async componentDidMount() {
        // 待ち呼集計画面からきたか
        let state = this.props.location.state ? this.props.location.state : [];
        let res = null;

        try {
            switch (this.props.currentPermission.scope_code) {
                case GlobalConst.SCOPE_OBJECT.system.name:
                case GlobalConst.SCOPE_OBJECT.oem.name:
                    // 会社選択肢セット
                    res = await this.getCommonCompanySelect(this.reactContainerPath.split("/")[1]);

                    this.setState({
                        companySelect: res.data,
                        floor_flg: true
                    });
    
                    break;
                case GlobalConst.SCOPE_OBJECT.company.name:
                    // 拠点選択肢セット
                    res = await this.ascAxios("post", `Common/departmentSelect`, {
                        container: this.reactContainerPath.split("/")[1],
                        company_id: this.props.userInfo.company_id,
                        floor_flg: this.props.userInfo.floor_flg
                    });

                    this.setState({
                        departmentSelect: res.data,
                        floor_flg: this.props.userInfo.floor_flg
                    });
    
                    break;
                case GlobalConst.SCOPE_OBJECT.department.name:
                    // フロア選択肢セット
                    if (this.props.userInfo.floor_flg) {
                        res = await this.ascAxios("post", `Common/floorSelect`, {
                            company_id: this.props.userInfo.company_id,
                            department_prefix: this.props.userInfo.department_prefix
                        });

                        this.setState({
                            floorSelect: res.data,
                            floor_flg: this.props.userInfo.floor_flg
                        });
                    }

                    break;
                default:
                    break;
            }
        } catch (err) {
            this.showErrorObjectMesssage(err)
            console.error(err);
        }
        


        // 待ち呼集計画面からきたか確認
        if (state.length === 0) return;

        // フィルター欄表示
        state.forEach(row => {
            this.onChange(row.value, row.id);
        });
    }

    // 他の画面に移動の時、Interval削除
    componentWillUnmount() {
        if (this.state.interval) clearInterval(this.state.interval);
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    getColumnsData() {
        let columns = [];
        // 会社名
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            columns.push({
                Header: this.props.langText.Body.CompanyName,
                sortable: true,
                accessor: "cm12_companies.company_name",
            });
        }
        // 外線番号名称
        columns.push({
            Header: this.props.langText.Body.ExternalNumberName,
            sortable: true,
            accessor: "cm61_external_numbers.memo",
        });
        // 外線番号
        columns.push({
            Header: this.props.langText.Body.ExternalNumber,
            sortable: true,
            accessor: "cm61_external_numbers.display_number",
        });
        // 相手先番号
        columns.push({
            Header: this.props.langText.Body.DestUserNumber,
            sortable: true,
            accessor: "dest_tel_no",
            Cell: data => {
                return data && data.value ? data.value : "-";
            }
        });
        // 待ち呼滞在時間
        columns.push( {
            Header: this.props.langText.Body.CallWaitingTime,
            sortable: true,
            accessor: "diff_sec",
            Cell: data => {
                let diffSec = this.secToTime(data.value || 0);
                if (data.value > this.state.callWaitingAlertTime) {
                    // 待ち呼アラート時間を超過している場合、赤字で表示
                    return <div className="colorRed">{diffSec}</div>
                } else {
                    return diffSec;
                }
            }
        });
        // 着信日時
        columns.push({
            Header: this.props.langText.Body.IncomingCallDate,
            sortable: true,
            accessor: "created",
            Cell: data => {
                return data.value ? this.getMomentTime({date: data.value, format: "HH:mm:ss"}) : "";
            }
        });
        // 待ち呼離脱日時
        columns.push({
            Header: this.props.langText.Body.CallWaitingLeaveDate,
            sortable: true,
            accessor: "deleted",
            Cell: data => {
                return data && data.value ? this.getMomentTime({date: data.value, format: "HH:mm:ss"}) : "-";
            }
        });
        //結果
        columns.push({
            Header: this.props.langText.Body.CallHistory,
            sortable: true,
            accessor: "ct60_call_histories.disposition",
            Cell: data => {
                return data && data.value ? data.value : "-";
            }
        });

        return columns;
    }

    getExternal = async (id, event, param = null) => {
        const caseList = {
            cm12_id: "cm12_id",
            cm13_id: "cm13_id",
            floor_id: "floor_id"
        };
        let body = {};
        
        // 検索条件による外線番号リスト範囲
        switch (param) {
            case caseList.cm13_id:
                body = {
                    cm13_id: id,
                    company_id: event.company_id,
                    department_prefix: event.department_prefix,
                    floor_flg: event.floor_flg,
                    param: param
                }
                break;
            case caseList.floor_id:
                body = {
                    floor_id: id,
                    param: param
                }
                break;
            case caseList.cm12_id:
            default:
                body = {
                    cm12_id: id,
                    param: param
                }
                break;
        }

        try {
            const res = await this.ascAxios("post", `${this.reactContainerPath}/getAllExternal`, body);

            this.setState({externalarr: res.data});
        } catch (err) {
            alert(this.getErrorString(err.response.data));
        }
    }

    getFilterData = (param) => {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    getDepartment = async (event) => {
        const floor_flg = event.floor_flg === "Y" ? true : false;
        
        try {
            const res = await this.ascAxios("post", `Common/departmentSelect`, {
                container: this.reactContainerPath.split("/")[1],
                company_id: event.value,
                floor_flg: floor_flg
            });
            
            this.setState({departmentSelect: res.data});
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    getFloor = async (cm12_id, event) => {
        try {
            const res = await this.ascAxios("post", `Common/floorSelect`, {
                company_id: cm12_id,
                department_prefix: event.department_prefix
            });
            
            this.setState({floorSelect: res.data});
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    onChange = (event, param) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";

        // セレクトボックス用。event無い場合はvalue空のまま
        if(event) {
            value = event.target ? event.target.value : event;
        }

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        // 変更する項目に関連があるものクリア
        switch (param) {
            case "cm12_id":
                target_index = my_filtered.findIndex(row => row.id === "cm13_id");

                if (target_index !== -1) my_filtered.splice(target_index, 1);
            case "cm13_id":
                target_index = my_filtered.findIndex(row => row.id === "floor_id");
                
                if (target_index !== -1) my_filtered.splice(target_index, 1);
            case "floor_id":
                target_index = my_filtered.findIndex(row => row.id === "display_number");

                if (target_index !== -1) my_filtered.splice(target_index, 1); 
                break;
            default:
                break;
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }

        // 会社選択
        if (param === "cm12_id" && event && event.value) {
            this.getDepartment(event);
            this.getExternal(event.value, event, param);
            this.setState({
                display_number: [],
                floor_flg: event.floor_flg === "Y" ? true : false
            });
        } else if (param === "cm12_id") {
            // クリアした時
            this.setState({
                externalarr: [],
                display_number: [],
                departmentSelect: [],
                floorSelect: [],
                floor_flg: true
            });
        }

        if (param === "cm13_id" && event && event.value) {
            let floor_flg = my_filtered.find(row => row.id === "cm12_id") 
                ? my_filtered.find(row => row.id === "cm12_id").value.floor_flg
                : this.props.userInfo.floor_flg;
            event.floor_flg = floor_flg;
            
            if (event.floor_flg && event.floor_flg !== "N") {
                this.getFloor(event.company_id, event);
            }

            this.getExternal(event.value, event, param)
            this.setState({
                display_number: [],
            });
        } else if (param === "cm13_id") {
            // クリアした時
            // 外線番号リスト変更
            const reset = "cm12_id";
            let cm12_id = my_filtered.find(row => row.id === "cm12_id") 
            ? my_filtered.find(row => row.id === "cm12_id").value.value
            : this.props.userInfo.company_id;
            

            this.getExternal(cm12_id, event, reset);
            this.setState({
                display_number: [],
                floorSelect: [],
            });
        }

        if (param === "floor_id" && event && event.value) {
            this.getExternal(event.value, event, param);
            this.setState({
                display_number: [],
            });
        } else if (param === "floor_id") {
            // クリアした時
            // 外線番号リスト変更
            let cm13 = my_filtered.find(row => row.id === "cm13_id") 
                ? my_filtered.find(row => row.id === "cm13_id")
                : {
                    id: "cm13_id",
                    value: {
                        company_id: this.props.userInfo.company_id,
                        department_prefix: this.props.userInfo.department_prefix,
                        floor_flg: this.props.userInfo.floor_flg,
                        value: this.props.userInfo.department_id
                    }
                };
            
            this.getExternal(cm13.value.value, cm13.value, cm13.id);
            this.setState({
                display_number: [],
            });
        }

        if (param === "display_number" && event) {
            this.setState({[param]: event});
            if (this.state.externalarr.length === event.length) {
                let index = my_filtered.findIndex(row => row.id === "display_number");

                my_filtered.splice(index, 1);
            }
        }

        if (param === "callWaitingAlertTime") {
            this.setState({
                callWaitingAlertTime: event.value
            });
        }

        let returnValue = my_filtered;

        this.setState({
            filtered: returnValue
        });

        this.getValidationCheck();
    }

    onSearch = (event) => {
        let filter =[];

        this.state.filtered.forEach((row, index) => {
            filter.push(row);
        })

        this.setState({
            hidden_filtered: filter
        },()=>{
            //検索時にpageを1ページする
            this.table.current.state.page = 0;
            this.table.current.state.hidden_filtered = filter;
            this.fetchData(this.table.current.state, this.table.current.instance);
        });
    }

    // 秒をHH:mm:ssフォーマットに変更
    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    onIntervalChange = (e) => {
        let stateInterval;
        this.setState({autoUpdateInterval: e.value});

        if (e && e.value === 1) {
            //自動更新無しが選択された場合は自動更新を止める
            this.onSearch(this.state.filtered);

            return clearInterval(this.state.interval);
        } else if(e && e.value !== 1) {
            //自動更新間隔が選択されたら一回画面更新後、自動更新
            clearInterval(this.state.interval);
            this.onSearch(this.state.filtered);

            stateInterval = setInterval(() => {
                this.onSearch(this.state.filtered);
            }, e.value);
            this.setState({interval : stateInterval});
        }
    }

    getValidationCheck = () => {
        let message = "";

        // 検索日付があるかないか確認
        if (!this.getFilterData("created")) {
            message = this.props.langText.Message.CallMonitoring_FindDataError;
            this.setState({
                validation: true,
                message
            }, () => {
                if (this.state.interval) clearInterval(this.state.interval);
            });
        } else {
            this.setState({
                validation: false,
                message: ""
            });
        }
    }

    render() {
        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/CallMonitoring", name: this.props.langText.Body.CallMonitoring },
                        { name: this.props.langText.Body.WaitStatuses },
                    ]}
                />
                <div className="waitstatuses">
                    <Row>
                        {/* OEM以上は会社選択表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col xs={12} md={12}>
                                <Col sm={4} md={2} lg={1} className="body-waitstatuses-title">
                                    <div className="margin-top-05">{this.props.langText.Body.CompanyName}</div>
                                </Col>
                                <Col sm={12} md={12} lg={3}>
                                    <Select
                                        value = {this.getFilterData("cm12_id")}
                                        onChange = {
                                            e => this.onChange(e, "cm12_id")
                                        }
                                        options = {this.state.companySelect}
                                        isClearable = {true}
                                        placeholder = {this.props.langText.SelectOption.Placeholder}/>
                                </Col>
                            </Col>
                            :
                            null
                        }
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1} className="body-waitstatuses-title">
                                <div className="margin-top-05">{this.props.langText.Body.CallWaitingSearchDate}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="body-waitstatuses-body">
                                <DatePicker
                                    onChange = {e => this.onChange(e, "created")}
                                    selected = {this.getFilterData("created")}
                                    dateFormat = "YYYY-MM-DD"
                                    className = "form-control"
                                    todayButton = {this.props.langText.Body.Today}
                                    placeholderText = {this.props.langText.Body.CallWaitingSearchDate}
                                    maxDate = {this.state.maxDate}
                                />
                            </Col>
                            {/* 会社管理者以上は表示 */}
                            {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) ?
                                <>
                                <Col sm={4} md={2} lg={1} className="body-waitstatuses-title">
                                    <div className="margin-top-05">{this.props.langText.Body.DepartmentName}</div>
                                </Col>
                                <Col sm={12} md={4} lg={2} className="body-waitstatuses-body">
                                    <Select 
                                        value = {this.getFilterData("cm13_id")}
                                        onChange = {
                                            e => this.onChange(e, "cm13_id")
                                        }
                                        options = {this.state.departmentSelect}
                                        isClearable = {true}
                                        placeholder = {this.props.langText.SelectOption.Placeholder_defaultDepartment}/>
                                </Col>
                                </>
                                :
                                null
                            }
                            {/* 拠点管理者以上およびフロア有は表示 */}
                            {this.state.floor_flg 
                                    && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)
                                ?
                                <>
                                <Col sm={4} md={2} lg={1} className="body-waitstatuses-title">
                                    <div className="margin-top-05">{this.props.langText.Body.FloorName}</div>
                                </Col>
                                <Col sm={12} md={4} lg={2} className="body-waitstatuses-body">
                                    <Select 
                                        value = {this.getFilterData("floor_id")}
                                        onChange = {
                                            e => this.onChange(e, "floor_id")
                                        }
                                        options = {this.state.floorSelect}
                                        isClearable = {true}
                                        placeholder = {this.props.langText.SelectOption.Placeholder_defaultfloor}/>
                                </Col>
                                </>
                                :
                                null
                            }
                            <Col>
                                <div className="pull-right">
                                    <Col className="margin-top-05" xs={1} sm={1} md={1} lg={1}>
                                        <Dropdown>
                                            <Dropdown.Toggle bsStyle="primary">
                                                {this.props.langText.Body.Set}
                                            </Dropdown.Toggle>
                                            <SettingMenu bsRole="menu">
                                                <Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="margin-top-03 margin-bottom-05">
                                                        <Col xs={7} sm={7} md={6} lg={6} className="text-align-left margin-top-05">
                                                            {this.props.langText.Body.AutoUpdateInterval}
                                                        </Col>
                                                        <Col xs={5} sm={5} md={6} lg={6}>
                                                            <InlineForm
                                                                type="select"
                                                                options={this.props.langText.SelectOption.WaitStatusesAutoUpdateIntervalList}
                                                                value={this.state.autoUpdateInterval}
                                                                onChange={(e) => this.onIntervalChange(e, "autoUpdateInterval")}/>
                                                        </Col>
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="margin-top-03 margin-bottom-07">
                                                        <Col xs={7} sm={7} md={6} lg={6} className="text-align-left margin-top-05">
                                                            {this.props.langText.Body.CallWaitingAlertTime}
                                                        </Col>
                                                        <Col xs={5} sm={5} md={6} lg={6}>
                                                            <InlineForm
                                                                type="select"
                                                                options={this.props.langText.SelectOption.WaitStatusesAlertTimeList}
                                                                value={this.state.callWaitingAlertTime}
                                                                onChange={(e) => this.onChange(e,"callWaitingAlertTime")}/>
                                                        </Col>
                                                    </Col>
                                                </Col>
                                            </SettingMenu>
                                        </Dropdown>
                                    </Col>
                                </div>
                            </Col>
                        </Col>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1} className="body-waitstatuses-title">
                                <div className="margin-top-05">{this.props.langText.Body.ExternalNumber}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="body-waitstatuses-body">
                                <MultiSelect
                                    hasSelectAll = {true}
                                    key = "display_number"
                                    value = {this.state.display_number}
                                    onChange = {
                                        e => this.onChange(e, "display_number")
                                    }
                                    options = {this.state.externalarr}
                                    overrideStrings = {{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder_defaultexternal,
                                        allItemsAreSelected: this.props.langText.SelectOption.Placeholder_defaultexternal,
                                        selectAll: this.props.langText.SelectOption.Placeholder_defaultexternal,
                                        selectAllFiltered: this.props.langText.SelectOption.Placeholder_searchAllResult,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="body-waitstatuses-title">
                                <div className="margin-top-05">{this.props.langText.Body.CallHistory}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="body-waitstatuses-body">
                                <Select
                                    value = {this.getFilterData("disposition")}
                                    onChange = {
                                        e => this.onChange(e, "disposition")
                                    }
                                    options = {this.callResultSelect}
                                    isClearable = {true}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_allResult}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="margin-top-03 body-waitstatuses-title">
                                    <Button
                                        id="customer-insert"
                                        className="searchBtn"
                                        bsStyle="primary"
                                        bsSize="sm"
                                        onClick={e => this.onSearch(e)}
                                        disabled = {this.state.validation || this.state.loading}
                                    >
                                        {this.props.langText.Body.Search}
                                    </Button>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="width-max-content">
                                <label className="margin-top-07">{this.props.langText.Body.SearchResult}</label>
                                <label className="margin-top-07 result-count">{this.state.count ? this.state.count: 0}{this.props.langText.Body.Count}</label>
                            </Col>
                            <Col sm={12} md={4} lg={4}>
                                <label className="margin-top-07 search-message">{this.state.message}</label>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-10">
                            <CommonTable
                                talbeRef={this.table}
                                style={{
                                    height: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) 
                                    ? this.props.tableHeight-110
                                    : this.props.tableHeight-70
                                }}
                                manual="manual"
                                columns={this.state.columns}
                                data={this.state.data}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                defaultSorted={[{id: "diff_sec", desc: true}]}
                                onFetchData={(state, instance) => {
                                    this.fetchData(state, instance);
                                }}
                                filtered={this.state.filtered}
                                hidden_filtered={this.state.hidden_filtered}
                                previousText={this.props.langText.Table.PreviousText}
                                nextText={this.props.langText.Table.NextText}
                                loadingText={this.props.langText.Table.LoadingText}
                                noDataText={this.props.langText.Table.NoDataText}
                                rowsText={this.props.langText.Table.RowsText}/>
                        </Col>
                    </Row>
                </div>
            </BlockUi>
        );
    }
}

// 「設定」押下時に表示するメニュー
class SettingMenu extends React.Component {

    render() {
        const { children } = this.props;

        return (
            <Dropdown.Menu className="dropdown-menu-right wait-statuses-dropdown-menu">
                {React.Children.toArray(children)}
            </Dropdown.Menu>
        );
    }
}