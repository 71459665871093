import * as React from 'react';
import { ASNodeWidget } from './NodeWidget';
import { ASNodeModel } from './NodeModel';
import { AbstractReactFactory } from '@projectstorm/react-canvas-core';

export class ASNodeFactory extends AbstractReactFactory {
	constructor() {
		super('ivr');
	}

	generateReactWidget(event) {
		return <ASNodeWidget engine={this.engine} node={event.model} />;
	}

	generateModel(event) {
		return new ASNodeModel();
	}
}