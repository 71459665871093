import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Row, Col, Button} from "react-bootstrap";
import "./HistoryDetailSearchMenu.css";
import InlineForm from "../../Elements/FromGroup/InlineForm";

export default class HistoryDetailSearchMenu extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            fixedSearchMenuFilter : [
                {
                    id: "start_datetime_ji_from",
                    value: ""
                },
                {
                    id: "start_datetime_hun_from",
                    value: ""
                },
                {
                    id: "start_datetime_ji_to",
                    value: ""
                },
                {
                    id: "start_datetime_hun_to",
                    value: ""
                },
                {
                    id: "calling_time_hun_from",
                    value: ""
                },
                {
                    id: "calling_time_byo_from",
                    value: ""
                },
                {
                    id: "calling_time_hun_to",
                    value: ""
                },
                {
                    id: "calling_time_byo_to",
                    value: ""
                },
                {
                    id: "connected_time_hun_from",
                    value: ""
                },
                {
                    id: "connected_time_byo_from",
                    value: ""
                },
                {
                    id: "connected_time_hun_to",
                    value: ""
                },
                {
                    id: "connected_time_byo_to",
                    value: ""
                },
                {
                    id: "answered_time_hun_from",
                    value: ""
                },
                {
                    id: "answered_time_byo_from",
                    value: ""
                },
                {
                    id: "answered_time_hun_to",
                    value: ""
                },
                {
                    id: "answered_time_byo_to",
                    value: ""
                },
                {
                    id: "disconnected_time_hun_from",
                    value: ""
                },
                {
                    id: "disconnected_time_byo_from",
                    value: ""
                },
                {
                    id: "disconnected_time_hun_to",
                    value: ""
                },
                {
                    id: "disconnected_time_byo_to",
                    value: ""
                }
            ],
            searchMenuFilter : [
                {
                    id: "start_datetime_ji_from",
                    value: ""
                },
                {
                    id: "start_datetime_hun_from",
                    value: ""
                },
                {
                    id: "start_datetime_ji_to",
                    value: ""
                },
                {
                    id: "start_datetime_hun_to",
                    value: ""
                },
                {
                    id: "calling_time_hun_from",
                    value: ""
                },
                {
                    id: "calling_time_byo_from",
                    value: ""
                },
                {
                    id: "calling_time_hun_to",
                    value: ""
                },
                {
                    id: "calling_time_byo_to",
                    value: ""
                },
                {
                    id: "connected_time_hun_from",
                    value: ""
                },
                {
                    id: "connected_time_byo_from",
                    value: ""
                },
                {
                    id: "connected_time_hun_to",
                    value: ""
                },
                {
                    id: "connected_time_byo_to",
                    value: ""
                },
                {
                    id: "answered_time_hun_from",
                    value: ""
                },
                {
                    id: "answered_time_byo_from",
                    value: ""
                },
                {
                    id: "answered_time_hun_to",
                    value: ""
                },
                {
                    id: "answered_time_byo_to",
                    value: ""
                },
                {
                    id: "disconnected_time_hun_from",
                    value: ""
                },
                {
                    id: "disconnected_time_byo_from",
                    value: ""
                },
                {
                    id: "disconnected_time_hun_to",
                    value: ""
                },
                {
                    id: "disconnected_time_byo_to",
                    value: ""
                }
            ]
        }
    }

    getSearchBtn = () => {
        let {
            onSearch,
            langText
        } = this.props;

        let searchMenuFilter = this.state.searchMenuFilter;

        return (
            <Button
                className="ok-execute-btn history-detail-search-button-size"
                bsStyle="info"
                onClick={e => onSearch(searchMenuFilter)}
                disabled={!this.validationHandle("search")}>
                {langText.Body.Search}
            </Button>
        );
    }

    getClearBtn = () => {
        let {
            langText
        } = this.props;

        return (
            <Button
                className="default-button history-detail-search-button-size history-detail-search-clear-button"
                onClick={e => this.onClear()}>
                {langText.Body.Clear}
            </Button>
        );
    }

    getConditionInfo = () => {
        let {
            state,
            langText,
        } = this.props;
        let itemArr = [];

        // 架電開始日時
        itemArr.push(
            <InlineForm
                key="search-condition-out-time"
                controlId="search-condition-out-time"
                label={langText.Body.AutoCallStartDateTime}
                validationState={this.validationHandle("start_datetime_ji_from")}
            >
                <Col sm={12} md={12} className="history-detail-search-condition-time-period">
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="start_datetime_ji_from"
                            controlId="start_datetime_ji_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "start_datetime_ji_from", "ji")}
                            value={this.getFilterData("start_datetime_ji_from")}
                            validationState={this.validationHandle("start_datetime_ji_from")}
                            placeholder={langText.Body.Hour}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="start_datetime_hun_from"
                            controlId="start_datetime_hun_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "start_datetime_hun_from", "hun")}
                            value={this.getFilterData("start_datetime_hun_from")}
                            validationState={this.validationHandle("start_datetime_hun_from")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-swung">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="start_datetime_ji_to"
                            controlId="start_datetime_ji_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "start_datetime_ji_to", "ji")}
                            value={this.getFilterData("start_datetime_ji_to")}
                            validationState={this.validationHandle("start_datetime_ji_to")}
                            placeholder={langText.Body.Hour}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="start_datetime_hun_to"
                            controlId="start_datetime_hun_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "start_datetime_hun_to", "hun")}
                            value={this.getFilterData("start_datetime_hun_to")}
                            validationState={this.validationHandle("start_datetime_hun_to")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        // 通話結果
        itemArr.push(
            <InlineForm
                key="search-condition-result"
                controlId="search-condition-result"
                label={langText.Body.CallHistory}
            >
                <Col className="history-detail-search-padding-bottom-35 history-detail-search-inline">
                    <InlineForm
                        key="search-condition-result"
                        controlId="search-condition-result"
                        type="select"
                        value={this.getFilterData("resultSelected")}
                        onChange={e => this.onMultiSelectChange(e, "resultSelected")}
                        options={state.resultSelectOptions}
                        isMulti={true}
                        placeholder={langText.SelectOption.Placeholder_All_Select}
                        colWidth={12}
                    />
                </Col>
            </InlineForm>
        )

        // 内線番号
        itemArr.push(
            <InlineForm
                key="search-condition-extension-number"
                controlId="search-condition-extension-number"
                label={langText.Body.ExtensionNumber}
            >
                <Col className="history-detail-search-padding-bottom-35 history-detail-search-inline">
                    <InlineForm
                        key="search-condition-extension-number"
                        controlId="search-condition-extension-number"
                        type="text"
                        value={this.getFilterData("extension_number")}
                        onChange={e => this.onChange(e, "extension_number")}
                        placeholder={langText.Body.ForwardMatch}
                        colWidth={12}
                    />
                </Col>
            </InlineForm>
        );

        // 通話時間
        itemArr.push(
            <InlineForm
                key="search-condition-calling-time"
                controlId="search-condition-calling-time"
                label={langText.Body.TalkingTime}
                validationState={this.validationHandle("calling_time_hun_from")}
            >
                <Col sm={12} md={12} className="history-detail-search-condition-time-period">
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="calling_time_hun_from"
                            controlId="calling_time_hun_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "calling_time_hun_from", "hun")}
                            value={this.getFilterData("calling_time_hun_from")}
                            validationState={this.validationHandle("calling_time_hun_from")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="calling_time_byo_from"
                            controlId="calling_time_byo_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "calling_time_byo_from", "byo")}
                            value={this.getFilterData("calling_time_byo_from")}
                            validationState={this.validationHandle("calling_time_byo_from")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-swung">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="calling_time_hun_to"
                            controlId="calling_time_hun_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "calling_time_hun_to", "hun")}
                            value={this.getFilterData("calling_time_hun_to")}
                            validationState={this.validationHandle("calling_time_hun_to")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="calling_time_byo_to"
                            controlId="calling_time_byo_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "calling_time_byo_to", "byo")}
                            value={this.getFilterData("calling_time_byo_to")}
                            validationState={this.validationHandle("calling_time_byo_to")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        // 顧客応答時間
        itemArr.push(
            <InlineForm
                key="search-condition-connect-time"
                controlId="search-condition-connect-time"
                label={langText.Body.AutoCallConnectTime}
                validationState={this.validationHandle("connected_time_hun_from")}
            >
                <Col sm={12} md={12} className="history-detail-search-condition-time-period">
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="connected_time_hun_from"
                            controlId="connected_time_hun_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "connected_time_hun_from", "hun")}
                            value={this.getFilterData("connected_time_hun_from")}
                            validationState={this.validationHandle("connected_time_hun_from")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="connected_time_byo_from"
                            controlId="connected_time_byo_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "connected_time_byo_from", "byo")}
                            value={this.getFilterData("connected_time_byo_from")}
                            validationState={this.validationHandle("connected_time_byo_from")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-swung">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="connected_time_hun_to"
                            controlId="connected_time_hun_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "connected_time_hun_to", "hun")}
                            value={this.getFilterData("connected_time_hun_to")}
                            validationState={this.validationHandle("connected_time_hun_to")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="connected_time_byo_to"
                            controlId="connected_time_byo_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "connected_time_byo_to", "byo")}
                            value={this.getFilterData("connected_time_byo_to")}
                            validationState={this.validationHandle("connected_time_byo_to")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        // OP応答時間
        itemArr.push(
            <InlineForm
                key="search-condition-answered-time"
                controlId="search-condition-answered-time"
                label={langText.Body.AutoCallOperatorAnsweredTime}
                validationState={this.validationHandle("answered_time_hun_from")}
            >
                <Col sm={12} md={12} className="history-detail-search-condition-time-period">
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="answered_time_hun_from"
                            controlId="answered_time_hun_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "answered_time_hun_from", "hun")}
                            value={this.getFilterData("answered_time_hun_from")}
                            validationState={this.validationHandle("answered_time_hun_from")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="answered_time_byo_from"
                            controlId="answered_time_byo_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "answered_time_byo_from", "byo")}
                            value={this.getFilterData("answered_time_byo_from")}
                            validationState={this.validationHandle("answered_time_byo_from")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-swung">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="answered_time_hun_to"
                            controlId="answered_time_hun_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "answered_time_hun_to", "hun")}
                            value={this.getFilterData("answered_time_hun_to")}
                            validationState={this.validationHandle("answered_time_hun_to")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="answered_time_byo_to"
                            controlId="answered_time_byo_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "answered_time_byo_to", "byo")}
                            value={this.getFilterData("answered_time_byo_to")}
                            validationState={this.validationHandle("answered_time_byo_to")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        // 接続放棄時間
        itemArr.push(
            <InlineForm
                key="search-condition-disconnected-time"
                controlId="search-condition-disconnected-time"
                label={langText.Body.AutoCallDisConnectTime}
                validationState={this.validationHandle("disconnected_time_hun_from")}
            >
                <Col sm={12} md={12} className="history-detail-search-condition-time-period">
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="disconnected_time_hun_from"
                            controlId="disconnected_time_hun_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "disconnected_time_hun_from", "hun")}
                            value={this.getFilterData("disconnected_time_hun_from")}
                            validationState={this.validationHandle("disconnected_time_hun_from")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="disconnected_time_byo_from"
                            controlId="disconnected_time_byo_from"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "disconnected_time_byo_from", "byo")}
                            value={this.getFilterData("disconnected_time_byo_from")}
                            validationState={this.validationHandle("disconnected_time_byo_from")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-swung">
                        <span>{langText.Body.FromTo}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="disconnected_time_hun_to"
                            controlId="disconnected_time_hun_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "disconnected_time_hun_to", "hun")}
                            value={this.getFilterData("disconnected_time_hun_to")}
                            validationState={this.validationHandle("disconnected_time_hun_to")}
                            placeholder={langText.Body.Minute}
                        />
                    </Col>
                    <Col sm={12} md={2} lg={1} className="padding-left-0 padding-right-0 history-detail-search-colon">
                        <span>{langText.Body.Colon}</span>
                    </Col>
                    <Col sm={12} md={2} lg={2} className="padding-left-0 padding-right-0">
                        <InlineForm
                            key="disconnected_time_byo_to"
                            controlId="disconnected_time_byo_to"
                            type="number"
                            onKeyDown={e => this.onKeyDownIntCheck(e)}
                            onChange={e => this.onTimeChange(e, "disconnected_time_byo_to", "byo")}
                            value={this.getFilterData("disconnected_time_byo_to")}
                            validationState={this.validationHandle("disconnected_time_byo_to")}
                            placeholder={langText.Body.Second}
                        />
                    </Col>
                </Col>
            </InlineForm>
        );

        return itemArr;
    }

    getFilterData(param) {
        let my_param_data = this.state.searchMenuFilter.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    onChange = (event, param) => {
        let my_filtered = this.state.searchMenuFilter,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";

        if (event) value = event.target ? event.target.value : event;
        if (target_index !== -1) my_filtered.splice(target_index, 1);

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }

        let returnValue = [];

        returnValue = my_filtered;

        this.setState({
            searchMenuFilter: returnValue
        });
    }

    onTimeChange = (event, param, type) => {
        let max = 100;
        let value = event ? event.target.value : "";
        if (event.target.value < 0) {
            return
        };
        value = value.replace(/[^0-9]+/i, "");

        if (value !== "") {
            if (value.length > 1 && value.charAt(0) === "0") value = value.substr(1);
            Number(value);
        }
        switch (type) {
            case "ji":
                max = 23;
                break;
            case "hun":
            case "byo":
                max = 59;
                break;
            default:
                break;
        }

        if (value > max) return;

        this.onChange(value, param);
    }

    onMultiSelectChange = (event, param) => {
        super.onMultiSelectChange(event, param);
        let value_arr = (event.length > 0) ? [] : "";

        event.forEach((row, index) => {
            value_arr[index] = row['value'];
        });

        this.onChange(value_arr, param);
    }

    onClear = async () => {
        let myFiltered = this.state.fixedSearchMenuFilter.concat();
        this.setState({searchMenuFilter: myFiltered});
    }

    onKeyDownIntCheck = (e) => {
        if (
            (e.keyCode >= 48 && e.keyCode <= 57) ||
            (e.keyCode >= 96 && e.keyCode <= 105) ||
            e.keyCode === 8 ||
            e.keyCode === 46 ||
            e.keyCode === 9 ||
            e.keyCode === 16 ||
            (e.keyCode >= 37 && e.keyCode <= 40)
        ) {
            return true;
        } else if (e.ctrlKey) {
            if (e.keyCode === 67 || e.keycode === 86) {
                return true;
            }
        } else {
            e.preventDefault();
            return false;
        }
    };

    validationCheckTimeFromTo = (from, to, checkType) => {
        let result = false;

        result =
            // 時(分)が入力されている場合、分(秒)も入力されていなければNG
            (from[0] !== "" && from[0] >= 0
                ? from[1] !== "" && from[1] >= 0
                : true
            ) &&
            (to[0] !== "" && to[0] >= 0
                ? to[1] !== "" && to[1] >= 0
                : true
            ) &&
            (
                // 日時型の場合、時・分いずれも入力されていなければNGとする
                // (時間型は秒のみでも検索可能)
                checkType === "datetime"
                ?
                    (from[1] !== "" && from[1] >= 0
                        ? from[0] !== "" && from[0] >= 0
                        : true
                    ) &&
                    (to[1] !== "" && to[1] >= 0
                        ? to[0] !== "" && to[0] >= 0
                        : true
                    )
                : true
            )

            if (result) {
                // FROM < TO であることのチェック
                switch(checkType) {
                    case "datetime":
                        // 日付型の場合、時分すべてに項目が入力されている場合のみチェック
                        // (時・分の両方が入力されていないと検索不可のため)
                        result =
                            (
                                from[0] !== "" && from[0] >= 0 &&
                                from[1] !== "" && from[1] >= 0 &&
                                to[0] !== "" && to[0] >= 0 &&
                                to[1] !== "" && to[1] >= 0
                            )
                                ? ((to[0] * 60) + to[1]) >= ((from[0] * 60) + from[1])
                                : true
                        break;
                    case "time":
                        // 時間型の場合、秒項目が入力されている場合チェック
                        // (秒のみの入力でも検索可能であるため)
                        result =
                            (
                                from[1] !== ""  && from[1] >= 0 &&
                                to[1] !== ""  && to[1] >= 0
                            )
                                ? ((to[0] * 60) + to[1]) >= ((from[0] * 60) + from[1])
                                : true
                        break;
                    default:
                        break;
                }
            }

        return result;
    }

    validationHandle = (param) => {
        let nowState = true;

        // 検索条件取得
        // 入力されている場合は数値型でその値を返す。入力されていない場合は""を返す
        let start_datetime_ji_from = this.getFilterData("start_datetime_ji_from") ? Number(this.getFilterData("start_datetime_ji_from")) : "";
        let start_datetime_hun_from = this.getFilterData("start_datetime_hun_from") ? Number(this.getFilterData("start_datetime_hun_from")) : "";
        let start_datetime_ji_to = this.getFilterData("start_datetime_ji_to") ? Number(this.getFilterData("start_datetime_ji_to")) : "";
        let start_datetime_hun_to = this.getFilterData("start_datetime_hun_to") ? Number(this.getFilterData("start_datetime_hun_to")) : "";
        let calling_time_hun_from = this.getFilterData("calling_time_hun_from") ? Number(this.getFilterData("calling_time_hun_from")) : "";
        let calling_time_byo_from = this.getFilterData("calling_time_byo_from") ? Number(this.getFilterData("calling_time_byo_from")) : "";
        let calling_time_hun_to = this.getFilterData("calling_time_hun_to") ? Number(this.getFilterData("calling_time_hun_to")) : "";
        let calling_time_byo_to = this.getFilterData("calling_time_byo_to") ? Number(this.getFilterData("calling_time_byo_to")) : "";
        let connected_time_hun_from = this.getFilterData("connected_time_hun_from") ? Number(this.getFilterData("connected_time_hun_from")) : "";
        let connected_time_byo_from = this.getFilterData("connected_time_byo_from") ? Number(this.getFilterData("connected_time_byo_from")) : "";
        let connected_time_hun_to = this.getFilterData("connected_time_hun_to") ? Number(this.getFilterData("connected_time_hun_to")) : "";
        let connected_time_byo_to = this.getFilterData("connected_time_byo_to") ? Number(this.getFilterData("connected_time_byo_to")) : "";
        let answered_time_hun_from = this.getFilterData("answered_time_hun_from") ? Number(this.getFilterData("answered_time_hun_from")) : "";
        let answered_time_byo_from = this.getFilterData("answered_time_byo_from") ? Number(this.getFilterData("answered_time_byo_from")) : "";
        let answered_time_hun_to = this.getFilterData("answered_time_hun_to") ? Number(this.getFilterData("answered_time_hun_to")) : "";
        let answered_time_byo_to = this.getFilterData("answered_time_byo_to") ? Number(this.getFilterData("answered_time_byo_to")) : "";
        let disconnected_time_hun_from = this.getFilterData("disconnected_time_hun_from") ? Number(this.getFilterData("disconnected_time_hun_from")) : "";
        let disconnected_time_byo_from = this.getFilterData("disconnected_time_byo_from") ? Number(this.getFilterData("disconnected_time_byo_from")) : "";
        let disconnected_time_hun_to = this.getFilterData("disconnected_time_hun_to") ? Number(this.getFilterData("disconnected_time_hun_to")) : "";
        let disconnected_time_byo_to = this.getFilterData("disconnected_time_byo_to") ? Number(this.getFilterData("disconnected_time_byo_to")) : "";

        switch(param){

            case "start_datetime_ji_from":
            case "start_datetime_hun_from":
            case "start_datetime_ji_to":
            case "start_datetime_hun_to":
                nowState = this.validationCheckTimeFromTo(
                    [start_datetime_ji_from, start_datetime_hun_from], [start_datetime_ji_to, start_datetime_hun_to], "datetime"
                )
                break;

            case "calling_time_hun_from":
            case "calling_time_byo_from":
            case "calling_time_hun_to":
            case "calling_time_byo_to":
                nowState = this.validationCheckTimeFromTo(
                    [calling_time_hun_from, calling_time_byo_from], [calling_time_hun_to, calling_time_byo_to], "time"
                )
                break;

            case "connected_time_hun_from":
            case "connected_time_byo_from":
            case "connected_time_hun_to":
            case "connected_time_byo_to":
                nowState = this.validationCheckTimeFromTo(
                    [connected_time_hun_from, connected_time_byo_from], [connected_time_hun_to, connected_time_byo_to], "time"
                )
                break;

            case "answered_time_hun_from":
            case "answered_time_byo_from":
            case "answered_time_hun_to":
            case "answered_time_byo_to":
                nowState = this.validationCheckTimeFromTo(
                    [answered_time_hun_from, answered_time_byo_from], [answered_time_hun_to, answered_time_byo_to], "time"
                )
                break;

            case "disconnected_time_hun_from":
            case "disconnected_time_byo_from":
            case "disconnected_time_hun_to":
            case "disconnected_time_byo_to":
                nowState = this.validationCheckTimeFromTo(
                    [disconnected_time_hun_from, disconnected_time_byo_from], [disconnected_time_hun_to, disconnected_time_byo_to], "time"
                )
                break;

            case "search":
                nowState =
                    this.validationCheckTimeFromTo(
                        [start_datetime_ji_from, start_datetime_hun_from], [start_datetime_ji_to, start_datetime_hun_to], "datetime"
                    ) &&
                    this.validationCheckTimeFromTo(
                        [calling_time_hun_from, calling_time_byo_from], [calling_time_hun_to, calling_time_byo_to], "time"
                    ) &&
                    this.validationCheckTimeFromTo(
                        [connected_time_hun_from, connected_time_byo_from], [connected_time_hun_to, connected_time_byo_to], "time"
                    ) &&
                    this.validationCheckTimeFromTo(
                        [answered_time_hun_from, answered_time_byo_from], [answered_time_hun_to, answered_time_byo_to], "time"
                    ) &&
                    this.validationCheckTimeFromTo(
                        [disconnected_time_hun_from, disconnected_time_byo_from], [disconnected_time_hun_to, disconnected_time_byo_to], "time"
                    )
                ;
                break;

            default:
                break;
        }

        return nowState;

    }

    render() {
        return (
            <Row className="history-detail-search-menu-row">
                <Col className="history-detail-search-title-col" xs={12} sm={12}>
                    <div className="font-weight-bold">
                        {this.props.langText.Body.SearchCondition}
                    </div>
                    <div className="margin-left-05 margin-top-05">
                        {this.getClearBtn()}
                        {this.getSearchBtn()}
                    </div>
                </Col>
                <hr className="history-detail-search-menu-hr"/>
                <Col className="content-col" xs={12} sm={12}>
                    {this.getConditionInfo()}
                </Col>
            </Row>
        );
    }
}
