import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import CommonLineChart from "./CommonLineChart";
import DirectionRadarChart from "./DirectionRadarChart";

export default class CallAnalysisCharts extends AscComponent {
    constructor(props) {
        super(props);
    
        this.state = {
        };
    }

    render() {
        return (
            <div className="call-analysis-charts">
                <Row>
                    <Col sm={12} md={12} className="chart-title margin-top-2">
                        <span>{this.props.langText.Body.CallAnalysis2}</span>
                    </Col>
                    <Col sm={12} md={8}>
                        <CommonLineChart
                            description={this.props.langText.Message.ScoreDescription}
                            title={this.props.langText.Body.Score}
                            data={this.props.compareFlag && Array.isArray(this.props.data) ? this.props.data 
                                : this.props.data.score && this.props.data.score.data_by_date ? this.props.data.score.data_by_date : []}
                            dataLabel={this.props.compareFlag ? "score" 
                                : this.props.data && this.props.data.score && this.props.data.score.summary_total_score 
                                ? Number(this.props.data.score.summary_total_score).toLocaleString() : 0}
                            langText={this.props.langText}
                            height={350}
                            unit={this.props.langText.Body.ScoreAll}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <DirectionRadarChart
                            description={this.props.langText.Message.DirectionDescription}
                            title={this.props.langText.Body.SpeakingDirection}
                            data={this.props.compareFlag && Array.isArray(this.props.data) && (this.props.data[0] && this.props.data[1])
                                ? [this.props.data[0].direction, this.props.data[1].direction]
                                : this.props.data && this.props.data.direction 
                                    ? this.props.data.direction 
                                    : []}
                            langText={this.props.langText}
                            height={385}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                </Row>
                <Row className="margin-top-2">
                    <Col sm={12} md={4}>
                        <CommonLineChart
                            description={this.props.langText.Message.CallTimeDescription}
                            title={this.props.langText.Body.TalkingTime}
                            data={this.props.compareFlag && Array.isArray(this.props.data) ? this.props.data
                                : this.props.data.call_time && this.props.data.call_time.data_by_date 
                                ? this.props.data.call_time.data_by_date : []}
                            dataLabel={this.props.compareFlag ? "call_time"
                                : this.props.data.call_time && this.props.data.call_time.summary_total_call_time 
                                ? this.props.secToTime(this.props.data.call_time.summary_total_call_time) : 0}
                            langText={this.props.langText}
                            unit={this.props.langText.Body.PerCount}
                            secToTime={this.props.secToTime}
                            height={250}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <CommonLineChart
                            description={this.props.langText.Message.SilenceDescription}
                            title={this.props.langText.Body.SilenceCount}
                            state={this.state}
                            data={this.props.compareFlag && Array.isArray(this.props.data) ? this.props.data
                                : this.props.data.silence_count && this.props.data.silence_count.data_by_date 
                                ? this.props.data.silence_count.data_by_date : []}
                            dataLabel={this.props.compareFlag ? "silence_count"
                                : this.props.data.silence_count && this.props.data.silence_count.summary_total_silence_count 
                                ? Number(this.props.data.silence_count.summary_total_silence_count).toLocaleString() : 0}
                            langText={this.props.langText}
                            unit={this.props.langText.Body.CountPerMinute}
                            height={250}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <CommonLineChart
                            description={this.props.langText.Message.TalkRateDescription}
                            title={this.props.langText.Body.TalkRate}
                            data={this.props.compareFlag && Array.isArray(this.props.data) ? this.props.data
                                : this.props.data.talk_per && this.props.data.talk_per.data_by_date 
                                ? this.props.data.talk_per.data_by_date : []}
                            dataLabel={this.props.compareFlag ? "talk_per"
                                : this.props.data.talk_per && this.props.data.talk_per.summary_total_talk_per 
                                ? Number(this.props.data.talk_per.summary_total_talk_per).toLocaleString() : 0}
                            langText={this.props.langText}
                            unit={this.props.langText.Body.Percent}
                            height={250}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                </Row>
                <Row className="margin-top-2">
                    <Col sm={12} md={4}>
                        <CommonLineChart
                            description={this.props.langText.Message.TalkConflictDescription}
                            title={this.props.langText.Body.TalkConflictCount}
                            data={this.props.compareFlag && Array.isArray(this.props.data) ? this.props.data
                                : this.props.data.talk_conflict_count && this.props.data.talk_conflict_count.data_by_date 
                                ? this.props.data.talk_conflict_count.data_by_date : []}
                            dataLabel={this.props.compareFlag ? "talk_conflict_count"
                                : this.props.data.talk_conflict_count && this.props.data.talk_conflict_count.summary_total_talk_conflict_count 
                                ? Number(this.props.data.talk_conflict_count.summary_total_talk_conflict_count).toLocaleString() : 0}
                            langText={this.props.langText}
                            unit={this.props.langText.Body.CountPerMinute}
                            height={250}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <CommonLineChart
                            description={this.props.langText.Message.TalkSpeedDifferenceDescription}
                            title={this.props.langText.Body.TalkSpeedDifference}
                            data={this.props.compareFlag && Array.isArray(this.props.data) ? this.props.data 
                                : this.props.data.speech_speed_diff && this.props.data.speech_speed_diff.data_by_date 
                                ? this.props.data.speech_speed_diff.data_by_date : []}
                            dataLabel={this.props.compareFlag ? "speech_speed_diff" 
                                : this.props.data.speech_speed_diff && this.props.data.speech_speed_diff.summary_total_speech_speed_diff 
                                ? Number(this.props.data.speech_speed_diff.summary_total_speech_speed_diff).toLocaleString() : 0}
                            langText={this.props.langText}
                            unit={this.props.langText.Body.Percent}
                            height={250}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                    <Col sm={12} md={4}>
                        <CommonLineChart
                            description={this.props.langText.Message.RallyingDescription}
                            title={this.props.langText.Body.RallyingCount}
                            data={this.props.compareFlag && Array.isArray(this.props.data)? this.props.data 
                                : this.props.data.rallying_count && this.props.data.rallying_count.data_by_date 
                                ? this.props.data.rallying_count.data_by_date : []}
                            dataLabel={this.props.compareFlag ? "rallying_count" 
                                : this.props.data.rallying_count && this.props.data.rallying_count.summary_total_rallying_count 
                                ? Number(this.props.data.rallying_count.summary_total_rallying_count).toLocaleString() : 0}
                            langText={this.props.langText}
                            unit={this.props.langText.Body.CountPerMinute}
                            height={250}
                            compareFlag={this.props.compareFlag}
                        />
                    </Col>
                </Row>
            </div>
        );
    }
}
