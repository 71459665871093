import React from "react";
import Component from '../../components/AscComponent';
import {Panel, FormGroup, Button, Checkbox, Row, Col, Glyphicon , Radio} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css";
import * as GlobalConst from "../../components/AscConstants";

export default class CompanyModal extends Component {
    getPbxToStr = () => {
        let str = "";

        this.props.state.pbxSelect.forEach(row => {
            if (this.props.state.pbx.find(value => value === row.value)) {
                if (str !== "") str += ",";
                str += row.label;
            }
        });

        return str;
    }

    getGatewayInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiSelectChange,
            onCheckBoxChange,
            onCheckBoxChange_Init,
            validationHandle,
            onRadioChange,
            systemFloorFlg,
            checkDuplicatePrefix,
            checkCompanyNameDuplicate
        } = this.props;
        let itemArr = [];
        let pbxStr = (state.modalType === "delete" || state.modalType === "read")
            ? this.getPbxToStr()
            : "";

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "companyName"
                    controlId = "companyName"
                    label = {langText.Body.CompanyName}
                    type = "text"
                    value = {state.companyName}
                    onChange = {e => onTextChange_Limit(e, "companyName",charaLimit.Company_companyName)}
                    onBlur = {() => checkCompanyNameDuplicate()}
                    validationState = {validationHandle("companyName")}/>
            );
            if(state.companyNameDuplicateFlag){
                itemArr.push(
                    <p key = "companyNameDuplicate" className="text-danger">
                        {langText.Body.CompanyNameDuplicate}
                    </p>
                );
            }
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "companyName"
                    controlId = "companyName"
                    label = {langText.Body.CompanyName}
                >
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "pbx"
                    controlId = "pbx"
                    label = {langText.Body.Pbx}
                    type = "select"
                    value = {state.pbx}
                    onChange = {e => onMultiSelectChange(e, "pbx")}
                    options = {state.pbxSelect}
                    isMulti = {true}
                    closeMenuOnSelect = {false}
                    validationState = {validationHandle("pbx")}
                    placeholder = {!state.buttondisabledArr.pbx[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.pbx[0]}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "pbx"
                    controlId = "pbx"
                    label = {langText.Body.Pbx}>
                    {pbxStr}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "prefix"
                    controlId = "prefix"
                    label = {langText.Body.Prefix}
                    type = "text"
                    maxLength ={systemFloorFlg === "Y" ? "2" : "3"}
                    value = {state.prefix}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={e => onTextChange_Limit(e, "prefix",charaLimit.Company_prefix)}
                    onBlur={() => checkDuplicatePrefix()}
                    placeholder = {systemFloorFlg === "Y" ? langText.Body.Length2 : langText.Body.Length3}
                    validationState = {validationHandle("prefix")}/>
            );
            if(state.prefixDuplicateFlag){
                itemArr.push(
                    <p key = "prefixDuplicate" className="text-danger">
                        {langText.Body.PrefixDuplicate}
                    </p>
                );
            }
            if(!this.isSystemUsedPrefix(state.prefix)){
                itemArr.push(
                    <p key = "prefixSystemUseNumber" className="text-danger">
                        {langText.Body.PrefixSystemUseNumber}
                    </p>
                );    
            }
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "prefix"
                    controlId = "prefix"
                    label = {langText.Body.Prefix}>
                    {state.prefix}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "outboundPrefix"
                    controlId = "outboundPrefix"
                    label = {langText.Body.OutboundPrefix}
                    type = "select"
                    value = {state.outboundPrefix}
                    onChange = {e => onSelectChange(e, "outboundPrefix")}
                    options = {state.outboundPrefixSelect}
                    placeholder = {langText.SelectOption.Placeholder}
                    validationState = {validationHandle("outboundPrefix")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "outboundPrefix"
                    controlId = "outboundPrefix"
                    label = {langText.Body.OutboundPrefix}>
                    {state.outboundPrefixView ? state.outboundPrefixView.label : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "password"
                    controlId = "password"
                    label = {langText.Body.Password}
                    type = "text"
                    value = {state.password}
                    onChange = {e => onTextChange_Limit(e, "password",charaLimit.Company_password)}
                    disabled = {state.autoPass}
                    validationState = {validationHandle("password")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "password"
                    controlId = "password"
                    label = {langText.Body.Password}>
                    {state.password}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <FormGroup
                    key = "autoPass"
                    controlId="autoPass">
                    <InlineForm>
                        <Col smOffset = {0} sm = {3}>
                            <Checkbox
                            className="company_checkbox"
                                checked={state.autoPass}
                                onChange={e => onCheckBoxChange_Init(e, "autoPass","password")}
                            >
                                {langText.Body.AutoCreate}
                            </Checkbox>
                        </Col>
                    </InlineForm>
                </FormGroup>
            );
        }

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId="memo"
                    label={langText.Body.Memo}
                    type="text"
                    value={state.memo}
                    onChange={e => onTextChange_Limit(e, "memo",charaLimit.Company_memo)}
                    disabled={state.modalType === "delete"}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        if (state.modalType === "update" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "omniURL"
                    controlId = "omniURL"
                    label = {langText.Body.OmniURL}
                    type = "text"
                    value = {state.omniURL}
                    onChange = {e => onTextChange_Limit(e, "omniURL",charaLimit.Company_omniURL)}
                    disabled = {state.modalType === "delete"}/>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key = "omniURL"
                    controlId = "omniURL"
                    label = {langText.Body.OmniURL}
                >
                    {state.omniURL ? state.omniURL : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        if (state.modalType === "update" || state.modalType === "copy" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key="voiceLogExpire"
                    controlId="voiceLogExpire"
                    label={langText.Body.VoiceLogExpire}
                    type="select"
                    options = {state.voice_log_expire_select}
                    value={state.voice_log_expire}
                    onChange={e => onSelectChange(e, "voice_log_expire")}
                    placeholder={langText.SelectOption.Placeholder}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "VoiceLogExpire"
                    controlId = "VoiceLogExpire"
                    label={langText.Body.VoiceLogExpire}
                >
                    {state.voice_log_expire_select
                    && state.voice_log_expire_select.find(row => row.value === state.voice_log_expire)
                        ? state.voice_log_expire_select.find(row => row.value === state.voice_log_expire).label
                        : langText.SelectOption.None}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    label={langText.Body.LoginPasswordExpireDays}
                >
                    <Checkbox
                        className="isSetloginPasswordExpireDays"
                        checked={state.isSetloginPasswordExpireDays}
                        onChange={e => onCheckBoxChange_Init(e, "isSetloginPasswordExpireDays", "loginPasswordExpireDays")}
                    >
                        {langText.Body.IsSetLoginPasswordExpireDays}
                    </Checkbox>
                </InlineForm>
            )

            if (state.isSetloginPasswordExpireDays) {
                itemArr.push(
                    <InlineForm
                        key = "loginPasswordExpireDays"
                        controlId = "loginPasswordExpireDays"
                        type = "text"
                        maxLength = "3"
                        value = {state.loginPasswordExpireDays}
                        placeholder = {langText.Message.Company_PlaceHolder_LoginPasswordExpireDays}
                        onKeyDown = {e => onKeyDownIntCheck(e)}
                        onChange = {onIntChange("loginPasswordExpireDays", charaLimit.User_loginPasswordExpireDays)}
                        validationState = {validationHandle("loginPasswordExpireDays")}
                    />
                );
            }
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "loginPasswordExpireDays"
                    controlId = "loginPasswordExpireDays"
                    label={langText.Body.LoginPasswordExpireDays}
                >
                    {state.isSetloginPasswordExpireDays ? state.loginPasswordExpireDays : langText.Body.NoExpire}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    onClickPlusHandle = () => {
        let {
            state,
            propSetState
        } = this.props;

        if (state.lineTypeInfos.length < state.lineTypeSelectOriginal.length) {
            state.lineTypeInfos.push({});
            propSetState({lineTypeInfos: state.lineTypeInfos});
        }
    }

    onClickMinusHandle = async (index) => {
        let {
            state,
            propSetState,
            lineTypeSelectSet
        } = this.props;

        state.lineTypeInfos.splice(index, 1);
        lineTypeSelectSet(state.lineTypeInfos);
        propSetState({lineTypeInfos: state.lineTypeInfos});
    }

    // 会社回線のチャネル数設定
    getLineChannelInfo = () => {
        let {
            state,
            langText,
            charaLimit,
            onKeyDownIntCheck,
            validationHandle,
            onLineTypeChange,
            lineTypeInfos_onTextChange_Limit,
            getScopeGreaterEqual
        } = this.props;

        let mainitemArr = [];
        
        state.lineTypeInfos.forEach((row, index) => {
            let itemArr = [];

            if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                itemArr.push(
                    <Button
                        key="minus"
                        className="multiValueFormMinus"
                        onClick={e => this.onClickMinusHandle(index)}
                    >
                        <Glyphicon glyph="minus"/>
                    </Button>
                );

                itemArr.push(
                    <Row key={`row-${index}`}>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <InlineForm
                                key="line_type"
                                controlId="line_type"
                                label={langText.Body.LineType}
                                type="select"
                                onChange={e => onLineTypeChange(e, "line_type", index)}
                                options={state.lineTypeInfoSelected[index] || state.lineTypeSelect}
                                value={row.line_type || ""}
                                isClearable={true}
                                validationState={validationHandle("line_type", index)}
                                placeholder={langText.SelectOption.Placeholder}
                            />
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <InlineForm 
                                key="channel_number"
                                controlId="channel_number"
                                label={langText.Body.LineChannelNumber}
                                type="number"
                                onKeyDown = {e => onKeyDownIntCheck(e)}
                                onChange={e => lineTypeInfos_onTextChange_Limit(e, "channel_number", index, charaLimit.Company_LineType_Channel)}
                                value={row.channel_number || ""}
                                validationState={validationHandle("channel_number", index)}
                                placeholder={langText.Message.Validation_NoZero_Number_input}
                                disabled={!row.line_type}
                            />
                        </Col>
                    </Row>
                );
            } else {
                let label = null;
                state.lineTypeSelectOriginal.forEach(line => {
                    if (line.value === row.line_type) label = line.label;
                });

                itemArr.push(
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <InlineForm.static
                                key="line_type"
                                controlId="line_type"
                                label={langText.Body.LineType}
                            >
                                {label}
                            </InlineForm.static>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <InlineForm.static
                                key="channel_number"
                                controlId="channel_number"
                                label={langText.Body.LineChannelNumber}
                            >
                                {row.channel_number}
                            </InlineForm.static>
                        </Col>
                    </Row>
                );
            }

            mainitemArr.push(
                <div key={index}>
                    {itemArr}
                </div>
            );
        });

        if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
            if (state.lineTypeInfos.length < state.lineTypeSelectOriginal.length) {
                mainitemArr.push(
                    <Button
                        key="plus"
                        className="multiValueFormPlus"
                        onClick={e => this.onClickPlusHandle()}
                    >
                        <Glyphicon glyph="plus"/>
                    </Button>
                );
            }
        }

        if (!mainitemArr.length) {
            mainitemArr.push(
                <InlineForm.static
                    key = "line_type_setting_unset"
                    controlId = "line_type_setting_unset">
                    {langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        return <div>{mainitemArr}</div>;
    }

    // 会社パラメータオプション選択
    onRadioChangeByParameters (event, param) {
        let {
            state,
            propSetState,
        } = this.props;
        // 選択した値
        let value = (event && event.target && event.target.value) ? event.target.value : "";
        // 会社パラメータ全体から検索(CompanyControlParametersOptionの定義オブジェクトをコピー)
        let all_parameter_search_function_key = Object.assign({},state.addingOptionParametersAll.find(row => row.parameter_id === param));
        // DBで検索した値を確認する
        let param_data = state.companyControlParameters.find(row => row.parameter_id === param);

        switch (param) {
            case "CALL_ANALYSIS_API_OPTION": 
                value = (event && event.value) ? event.value : "";
                break;
            default:
                break;
        }

        // DBの値がある場合
        if (typeof param_data != "undefined") {
            param_data.parameter_value = value;
        // DBの値がない場合
        }  else {
            // 選択した値を定義のデータに入れる（defalut）
            all_parameter_search_function_key.parameter_value = value;
            //会社パラメータに追加する
            state["companyControlParameters"].push(all_parameter_search_function_key);
            param_data = all_parameter_search_function_key;
        }

        if (param_data && param_data.parameter_id === "SCRIPT_SELECT" && param_data.parameter_value === "N") {
            // IVRオプションのOFFが選択された場合、多言語翻訳機能オプションも強制的にOFFとする
            let param_data_translate = state.companyControlParameters.find(
                (row) => row.parameter_id === "TRANSLATE_OPTION"
            );
            param_data_translate.parameter_value = "N";
        } else if (param_data && param_data.parameter_id === "CALL_ANALYSIS_OPTION" && param_data.parameter_value === "N") {
            // 通話履歴オプションのOFFが選択された場合、APIオプションも初期化する
            let param_data_call_analysis_api = state.companyControlParameters.find(
                (row) => row.parameter_id === "CALL_ANALYSIS_API_OPTION"
            );
            let param_data_call_auto_tag = state.companyControlParameters.find(
                (row) => row.parameter_id === "CALL_AUTO_TAG_OPTION"
            );
            let param_data_call_eval = state.companyControlParameters.find(
                (row) => row.parameter_id === "CALL_EVAL_OPTION"
            );
            param_data_call_analysis_api.parameter_value = state.call_analysis_api_select[0].value;
            param_data_call_eval.parameter_value = "N";
            if (param_data_call_auto_tag) param_data_call_auto_tag.parameter_value = "N";
        } else if ((param_data.parameter_id === "CALL_ANALYSIS_API_OPTION" && param_data.parameter_value !== "ascend_whisper")) {
            // APIオプションがascend_whisperじゃない場合、応対品質オプションも初期化する
            let param_data_call_eval = state.companyControlParameters.find(
                (row) => row.parameter_id === "CALL_EVAL_OPTION"
            );
            param_data_call_eval.parameter_value = "N";
        }

        propSetState({companyControlParameters: state["companyControlParameters"]});
    }
    setcompanyControlParameterValue (parameter_id) {
        let {
            state,
        } = this.props;
        let return_value = null;

        if (state.companyControlParameters.length > 0){
            let company_control_parameter = state.companyControlParameters.find(row => row.parameter_id === parameter_id);

            if (company_control_parameter && company_control_parameter.parameter_value) {
                return_value = company_control_parameter.parameter_value;
            }
        }
        return return_value;
    }
    getControlParametersItem = () => {
        let {
            state,
            langText
        } = this.props;
        let itemArr = [];
        // IVR多言語翻訳機能オプション表示フラグ
        let ivrTranslateOptionShowFlag = false;
        let callAnalysisApiOptionShowFlag = false;
        let callEvalOptionShowFlag = false;
        let selectOptionFlag = false;

        // 追加オプション表示
        state.addingOptionParametersAll.forEach((optInfo) => {
            if((optInfo.name === "ivr_translate_option" && !ivrTranslateOptionShowFlag) ||
                (optInfo.name === "call_analysis_api_option" && !callAnalysisApiOptionShowFlag) ||
                (optInfo.name === "call_auto_tag_option" && !callAnalysisApiOptionShowFlag) ||
                (optInfo.name === "call_eval_option" && !callEvalOptionShowFlag)){
                // 多言語翻訳・音声品質分析API選択・通話分類タグオプション・応対品質オプションかつ表示フラグがOFFの場合、処理を飛ばす
                return;
            }
            // ラジオボタンの設定値(ON/OFF)
            let addingOptionValue = this.setcompanyControlParameterValue(
                optInfo.parameter_id
            );
            if (addingOptionValue === null) {
                addingOptionValue = optInfo.parameter_value;
            }
            if (optInfo && optInfo.select_option) {
                selectOptionFlag = true;
            }

            if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = {optInfo.name}
                        controlId = {optInfo.name}
                        >
                        <div>
                            <Col xs={3}>
                                <label
                                className = "set_control_parameter">
                                    {optInfo.label}
                                </label>
                            </Col>
                        </div>
                        {selectOptionFlag 
                        ? <div key={optInfo.name+"select"}>
                            <Col xs={4}>
                                <InlineForm
                                    key         = {optInfo.name}
                                    controlId   = {optInfo.name}
                                    type        = "select"
                                    value       = {addingOptionValue ? addingOptionValue : ""}
                                    onChange    = {e => this.onRadioChangeByParameters(e, optInfo.parameter_id)}
                                    options     = {optInfo.select_option}
                                    isClearable = {false}
                                    isDisabled = {state.addingOptionUsingData["CALL_EVAL_OPTION"]}
                                    placeholder = {langText.SelectOption.Placeholder}
                                /> 
                            </Col>
                        </div>
                        : state.addingOptionSettingValueInfos.map((settingInfoRow, key) =>
                            <div key={key}>
                                <Col xs={2}>
                                <Radio
                                    name     = {optInfo.name}
                                    key      = {key}
                                    onChange = {e => this.onRadioChangeByParameters(e, optInfo.parameter_id)}
                                    checked  = {settingInfoRow.value === addingOptionValue}
                                    value    = {settingInfoRow.value}
                                    disabled = {
                                        // 使用中データが存在する追加オプションは「ON」⇒「OFF」に変更できないよう制御
                                        // また、IVRオプションは多言語翻訳オプションが使用中の場合でも「OFF」に変更できないよう制御
                                        addingOptionValue === "Y" &&
                                        (state.addingOptionUsingData[optInfo.parameter_id] ||
                                        optInfo.parameter_id === "SCRIPT_SELECT" && state.addingOptionUsingData["TRANSLATE_OPTION"]) ?
                                        true :false}
                                    style = {
                                        // disabled設定を入れると「display: 'none'」になってしまう処理が
                                        // Header.cssに存在するため、手動で「display: 'block'」を設定
                                        addingOptionValue === "Y" &&
                                        (state.addingOptionUsingData[optInfo.parameter_id] ||
                                        optInfo.parameter_id === "SCRIPT_SELECT" && state.addingOptionUsingData["TRANSLATE_OPTION"]) ?
                                        {display:'block'} : {}}
                                >
                                    {settingInfoRow.label}
                                </Radio> 
                                </Col>
                            </div>
                        )}
                    </InlineForm>
                );
            } else {
                itemArr.push(
                    selectOptionFlag 
                    ? <InlineForm.static
                        key={optInfo.name}
                        controlId={optInfo.name}
                        label={optInfo.label}>
                        {optInfo.select_option.map((row) =>
                            row.value === addingOptionValue ? row.label : ""
                        )}
                    </InlineForm.static>
                    : <InlineForm.static
                        key={optInfo.name}
                        controlId={optInfo.name}
                        label={optInfo.label}
                    >
                        {state.addingOptionSettingValueInfos.map((settingInfoRow) =>
                            settingInfoRow.value === addingOptionValue ? settingInfoRow.label : ""
                        )}
                    </InlineForm.static>
                );
            }
            if (optInfo.name === "ivr_option" && addingOptionValue === "Y"){
                // IVRオプションがONに設定されている場合、多言語翻訳機能オプション表示フラグをONとする
                ivrTranslateOptionShowFlag = true;
            }
            if (optInfo.name === "call_analysis_option" && addingOptionValue === "Y"){
                // 通話履歴オプションがONに設定されている場合、多言語翻訳機能オプション表示フラグをONとする
                callAnalysisApiOptionShowFlag = true;
            }
            if (optInfo.name === "call_analysis_api_option" && callAnalysisApiOptionShowFlag && addingOptionValue === "ascend_whisper") {
                // 通話品質分析オプションがONに設定されていて通話品質分析APIがAscend Whisperの場合、応対品質オプション表示フラグをONとする
                callEvalOptionShowFlag = true;
            }
            selectOptionFlag = false;
        });

        // 使用中データがあるため「ON」⇒「OFF」に変更できない追加オプションがある場合、
        // 追加オプションごとにメッセージと使用中データ一覧を表示
        if(Object.keys(state.addingOptionUsingData).length !== 0){
            // ループ処理を行うため、state.addingOptionUsingDataを配列形式へ変換
            const addingOptionErrArr = Object.entries(state.addingOptionUsingData);
            addingOptionErrArr.forEach( row => {
                // メッセージ文言(前半部分)
                let messageCause = "";
                // 使用中データで使用するラベル
                let usingDataLabel = "";
                // メッセージ文言(後半部分。Noteは必要に応じて設定)
                let messageOperation = "";
                let messageOperationNote = "";

                switch (row[0]) {
                    case "SCRIPT_SELECT":
                        messageCause = langText.Message.Company_AddingOptionTurnOffErr_Ivr_Cause +
                                       langText.CompanyControlParametersOption.IvrOptionLabel;
                        usingDataLabel = langText.Body.AddingOptionUsingName;
                        messageOperation = langText.Message.Company_AddingOptionTurnOffErr_Ivr_Operation;
                        break;
                    case "TRANSLATE_OPTION":
                        messageCause = langText.Message.Company_AddingOptionTurnOffErr_IvrTranslation_Cause +
                                       langText.CompanyControlParametersOption.IvrTranslateOptionLabel;
                        usingDataLabel = langText.Body.AddingOptionUsingName;
                        messageOperation = langText.Message.Company_AddingOptionTurnOffErr_IvrTranslation_Operation;
                        messageOperationNote = langText.Message.Company_AddingOptionTurnOffErr_IvrTranslation_Operation_Note;
                        break;
                    case "CALL_ANALYSIS_OPTION":
                        messageCause = langText.Message.Company_AddingOptionTurnOffErr_CallAnalysis_Cause +
                                       langText.CompanyControlParametersOption.CallAnalysisOptionLabel;
                        usingDataLabel = langText.Body.AddingOptionUsingNumber;
                        messageOperation = langText.Message.Company_AddingOptionTurnOffErr_External_Operation;
                        break;
                    case "ACD_FUNCTION_OPTION":
                        messageCause = langText.Message.Company_AddingOptionTurnOffErr_Acd_Cause +
                                       langText.CompanyControlParametersOption.AcdOptionLabel;
                        usingDataLabel = langText.Body.AddingOptionUsingNumber;
                        messageOperation = langText.Message.Company_AddingOptionTurnOffErr_External_Operation;
                        break;
                    case "CALL_EVAL_OPTION":
                        messageCause = langText.Message.Company_AddingOptionTurnOffErr_CallEval_Cause +
                                       langText.CompanyControlParametersOption.CallEvalOptionLabel;
                        usingDataLabel = langText.Body.AddingOptionUsingNumber;
                        messageOperation = langText.Message.Company_AddingOptionTurnOffErr_External_Operation;
                        break;
                    default:
                        break;
                }
                messageCause += langText.Message.Company_AddingOptionTurnOffErr;

                const usingData = row[1].map((items) => langText.Body.AddingOptionUsingId + items.id + usingDataLabel + items.name);
                const usingDataList = usingData.map((row, index) => <li key={index}>{row}</li>);

                itemArr.push(
                    <React.Fragment key={"AddingOptionError_"+ row[0]}>
                        <br />
                        <p key = {"AddingOptionErrorCause_"+ row[0]} className="text-danger">
                            {messageCause}
                        </p>
                        <ul>{usingDataList}</ul>
                        <p key = {"AddingOptionErrorOperation_"+ row[0]} className="text-danger">
                            {messageOperation}
                        </p>
                    </React.Fragment>
                );

                if(messageOperationNote){
                    itemArr.push(
                        <React.Fragment key={"AddingOptionErrorNote_"+ row[0]}>
                            <p key = {"AddingOptionErrorOperationNote_"+ row[0]} className="text-danger">
                                {messageOperationNote}
                            </p>
                        </React.Fragment>
                    );
                }
            });
        }

        return itemArr;
    }
    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.CompanySetting}
                        </Panel.Title>
                        {(this.props.state.modalType === "delete"
                        && this.props.state.companyDeleteFlag === false
                        && this.props.state.companyUsingData.length > 0)
                        && <>
                            <div className="company-using">
                                {this.props.langText.Message.CompanyDeleteError_PbxOem}
                            </div>
                            <div className="company-using-list">
                                {this.props.state.companyUsingData}
                            </div>
                        </>
                        }
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getGatewayInfoItem()}
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.LineTypeChannel}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getLineChannelInfo()}
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.addingOption}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getControlParametersItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
