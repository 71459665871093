import React from "react";
import {Panel, FormControl, Col} from "react-bootstrap";
import BusinessSetting from "./BusinessSetting";
import Component from "../../components/AscComponent";
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import Type from "./Type.json";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/ja";
moment.locale('ja');


export default class BusinessCalendarIrrBatchCheckModal extends Component {
    constructor(props) {
        super(props);

        this.day_string = this.props.dayString;
        this.action_string = this.props.actionString;
        this.business_string = this.props.businessString;
        this.voice_select = this.props.voiceSelect;
        
        this.state = {
            irr_calendar_data: [],
            irr_start_date_formatted: this.getMomentTime({date: this.props.state.irregular_start_date, format: this.props.langText.Body.DateFormat}),
            irr_end_date_formatted: this.getMomentTime({date: this.props.state.irregular_end_date, format: this.props.langText.Body.DateFormat}),
        };
    }

    async componentDidMount() {
        try {
            this.blockUI();
            this.setState({
                irr_calendar_data: (await this.getIrrCalendarData()).data
            });
        } catch (err) {
            console.error(err);
            alert(this.showErrorObjectMesssage(err.response.data));
            this.propSetState({show: false});
        }
    }

    getIrrCalendarData = () => {
        return this.ascAxios("post", `BusinessCalendar/getIrrCalendar`, {
            calendar_id: this.props.state.irr_calendar, 
            start_date: this.state.irr_start_date_formatted,
            end_date: this.state.irr_end_date_formatted
        });
    }

    validationHandle = (param) => {
        return true;
    }

    render() {
        return (
            <>
                <AscModal
                    key="business-calendar-asc-modal"
                    state={this.props.state}
                    propSetState={this.props.propSetState}
                    langText={this.props.langText}
                    validationHandle={this.validationHandle}
                    customMsg={this.props.langText.Body.Set}
                    customFunc={() => {
                        this.blockUI();
                        this
                            .ascAxios("post", `BusinessCalendar/batchIrr`, this.props.state)
                            .then(res => {
                                if (this.props.state.type === Type.BUSINESS.DEL) {
                                    alert(this.props.langText.Message.IrrDeleteSuccess);
                                } else {
                                    alert(this.props.langText.Message.IrrInsertSuccess);
                                }
                                this.props.onClick();
                            })
                            .catch(err => {
                                // 失敗時
                                alert(this.getErrorString(err.response.data));
                                this.props.onClick();
                            });
                    }}>
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.langText.Body.CheckInput}
                            </Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            <div>
                                <p className="preview_add">
                                    {this.props.langText.Message.Preview_add}
                                </p>
                                <p>
                                    {this.props.langText.Message.Preview_message1}
                                </p>
                                <p className="preview_message">
                                    {this.props.langText.Message.Preview_message2}
                                </p>
                                <hr color="#999"/>
                            </div>

                            <Col sm={12}>
                                <ASFieldGroup
                                    key="company_name"
                                    label={this.props.langText.Body.CompanyName}>
                                    <FormControl.Static>{this.props.state.company_name}</FormControl.Static>
                                </ASFieldGroup>

                                <ASFieldGroup
                                    key="irregular_date"
                                    label={this.props.langText.Body.Date}>
                                    <FormControl.Static>
                                        {this.state.irr_start_date_formatted + " ～ " + this.state.irr_end_date_formatted}
                                    </FormControl.Static>
                                </ASFieldGroup>

                                <ASFieldGroup
                                    key="calendar_data"
                                    label={this.props.langText.Body.BusinessCalendar}>
                                    <Col>{this.props.state.irr_calendar.map((calendar_id, key) => {
                                        const irr_selected = this.props.irrSelect.find(select => select.value === parseInt(calendar_id));
                                        const calendar_data = this.state.irr_calendar_data.filter(data => data.cm31_id === irr_selected.value);
                                        const days = this.props.state.irregular_dates_arr;

                                        let irr_change_infos = [];
                                        days.forEach(date => {
                                            let crr_data = calendar_data.find(row => row.irregular_date === date);
                                            if (calendar_data) {
                                                irr_change_infos.push(
                                                    <p key={"calendar_change_info_"+`${date}`} style={{marginBottom: "2px"}}>
                                                        &nbsp;&nbsp;{`${date}`}【
                                                            {crr_data
                                                                ? `${this.props.businessString[crr_data.type]}➞${this.props.businessString[this.props.state.type]}` 
                                                                : this.props.state.type !== Type.BUSINESS.DEL 
                                                                    ? `${this.props.langText.Body.Add}(${this.props.businessString[this.props.state.type]})` 
                                                                    : this.props.langText.Body.None}】
                                                    </p>
                                                )
                                            }
                                        })
                                        return <Col key={key}><span className="spanData" style={{marginBottom: "5px"}}>{irr_selected ? irr_selected.label : ""}</span>{irr_change_infos}<br /></Col>;
                                    })}
                                    </Col>
                                </ASFieldGroup>

                                <BusinessSetting
                                    key="business_setting"
                                    state={this.props.state}
                                    propSetState={this.propSetState}
                                    dayString={this.day_string}
                                    actionString={this.action_string}
                                    businessString={this.business_string}
                                    scriptSelect={this.state.script_select}
                                    read={true}
                                    voiceSelect={this.voice_select}
                                    langText={this.props.langText}
                                    charaLimit={this.props.charaLimit}
                                />

                                
                            </Col>
                        </Panel.Body>
                    </Panel>
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.langText.Body.exceptionPlaySetting}
                            </Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            <Col sm={12}>
                                <BusinessSetting
                                    key="business_setting_exception"
                                    state={this.props.state}
                                    propSetState={this.propSetState}
                                    exceptionPlaySetting={true}
                                    dayString={this.day_string}
                                    actionString={this.action_string}
                                    businessString={this.business_string}
                                    scriptSelect={this.state.script_select}
                                    read={true}
                                    voiceSelect={this.voice_select}
                                    langText={this.props.langText}
                                    charaLimit={this.props.charaLimit}
                                />
                            </Col>
                        </Panel.Body>
                    </Panel>
                </AscModal>
            </>
        );
    }
}