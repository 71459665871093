import React from 'react';
import { Col, FormControl, Button } from "react-bootstrap";

import AscMenu from "./Menu";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";

export default class Sms extends AscMenu {
    constructor(props) {
        super(props);
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptSmsText}
                    groupStyle={{marginBottom: 0}}
                    validationState={this.validation.menu.message(this.state.data)}>
                    <FormControl
                        componentClass="textarea"
                        value={this.state.data.message}
                        onChange={e => {
                            this.state.data.message = e.target.value;
                            this.setState({data: this.state.data});
                        }} />
                </ASFieldGroup>
                {/* <ASFieldGroup
                    label={this.props.langText.Body.ScriptCustomColumn}
                    groupStyle={{paddingLeft: "10%"}}
                    labelStyle={{fontSize: "20%"}}>
                    <Col xs={12} sm={12}>
                        <Col xs={9} sm={9}>
                            <ASSelect
                                value={this.state.screen_data.audio_custom_column[0]}
                                options={this.selectOptions.audio_custom_column}
                                onChange={e => {
                                    this.state.screen_data.audio_custom_column[0] = e.value;
                                    this.setState({screen_data: this.state.screen_data});
                                }}/>
                        </Col>
                        <Col xs={3} sm={3}>
                            <Button
                                bsStyle="default"
                                bsSize="sm"
                                style={{marginTop: "3px"}}
                                onClick={e => {
                                    console.log(e);
                                }}>
                                {this.props.langText.Body.Insert2}
                            </Button>
                        </Col>
                    </Col>
                </ASFieldGroup> */}
            </>
        );
    }
}