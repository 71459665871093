import React from 'react'
import {Button, Row, Col, Glyphicon, FormControl} from 'react-bootstrap'
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import InboundRejectModal from './InboundRejectModal'
import * as GlobalConst from "../../components/AscConstants";

export default class InboundReject extends AscComponent {
    constructor(props) {
        super(props)
        this.state = {
            // table item
            columns:          this.getColumnsData(),
            data:             [],
            filtered: [],
            pages:            null,
            loading:          false,

            // modal item
            show:             false,
            modalType:        null,

            // select item
            companySelect:    [],
            departmentSelect: [],
            floorSelect: [],

            // data item
            inbound_reject_id:   '',
            company_id:          '',
            company_name:        '',
            department_id:       '',
            department_name:     '',
            department_prefix:   '',
            floor_id:            '',
            floor_name:          '',
            floor_flg:           null,
            all_department_flag: false,
            telno:               '',
            memo:                '',
            // button_disabled
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load" : [],
                "floor_load" : []
            },
        }
        this.floor_load_flag = false;
    }

    getColumnsData() {
        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: 'id',
            filterable: false,
            width: this.props.boardWidth.id,
        });

        
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
            });
        }
        
        itemArr.push({
            Header: this.props.langText.Body.DepartmentName,
            accessor: 'cm13_departments.department_name',
            width: this.props.boardWidth.medium,
            Cell: data => {
                let str =
                    (data.row._original.all_department_flag === 'Y')
                    ?
                    this.props.langText.Body.AllDepartment
                    :
                    data.row._original.cm13_departments.department_name

                return (
                    <div>{str}</div>
                )
            },
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")} />
        });

        if (((this.props.systemParameters.SYSTEM_FLOOR_FLG
            && this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y")
            && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system))
            || this.props.userInfo.floor_flg){
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: 'cm13_departments.floor_name',
                width: this.props.boardWidth.medium,
                Cell: data => {
                    let str =
                        (data.row._original.all_department_flag === 'Y')
                        ?
                        this.props.langText.Body.AllFloor
                        :
                        data.row._original.cm13_departments.floor_name

                    return (
                        <div>{str}</div>
                    )
                },
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.floor_name")} />
            });
        }

        itemArr.push({
            Header: this.props.langText.Body.InboundRejectRule,
            accessor: 'telno',
            width: this.props.boardWidth.CallNumber,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "telno")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.Memo,
            accessor: 'memo',
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.EntryUserName,
            accessor: 'entry_user_name',
            width: this.props.boardWidth.UserName,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "entry_user_name")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.Created,
            accessor: 'created',
            width: this.props.boardWidth.Date,
            Cell: ({
                value
            }) => value ? this.getMomentTime({
                format: this.props.langText.Body.DateFormat,
                date: value
            }) : "",
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "created")} />,
            filterable: false,
        });

        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.Control,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize='xsmall'
                            onClick={this.modalShow('update', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='pencil'/>
                        </Button>
                    )
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize='xsmall'
                            onClick={this.modalShow('read', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='eye-open'/>
                        </Button>
                    )
                }
                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize='xsmall'
                            onClick={this.modalShow('delete', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='minus'/>
                        </Button>
                    )
                }

                return (
                    <Row className='text-center'>
                        {rowData}
                    </Row>
                )
            }
        });

        return itemArr;
    }

    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load" : [],
                "floor_load" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    modalShow = (modalType, param) => event => {
        event.preventDefault()

        this.floor_load_flag = false;

        this.modalshow_commonInit();

        let setData = {
            inbound_reject_id: param && param.id ? param.id : '',
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : '',
            company_name: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : '',
            department_id: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            department_name: param && param.cm13_departments && param.cm13_departments.department_name
                ? param.cm13_departments.department_name
                : '',
            department_prefix: param && param.cm13_departments && param.cm13_departments.department_prefix 
                ? param.cm13_departments.department_prefix
                : '',
            floor_id: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            floor_name: param && param.cm13_departments && param.cm13_departments.floor_name
                ? param.cm13_departments.floor_name
                : '',
            floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                ? param.cm12_companies.floor_flg === "Y"
                : null,
            all_department_flag: param && (param.all_department_flag === "Y") ? true : false,
            telno: param && param.telno ? param.telno : '',
            memo: param && param.memo ? param.memo : '',
        };
        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            setData.company_id = this.props.userInfo.company_id;
            setData.company_name = this.props.userInfo.company_name;
            setData.floor_flg = this.props.userInfo.floor_flg;
        }
        // 新規作成時にdepartment以下なら拠点固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
            if (this.props.userInfo.floor_flg) {
                setData.department_id = this.props.userInfo.parent_department_id;
                setData.department_name = this.props.userInfo.parent_department_name;
                setData.department_prefix = this.props.userInfo.department_prefix;
            } else {
                setData.department_id = this.props.userInfo.department_id;
                setData.department_name = this.props.userInfo.department_name;
                setData.department_prefix = this.props.userInfo.department_prefix;
            }
        }
        // 新規作成時にfloor以下ならフロア固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
            setData.department_id = this.props.userInfo.department_id;
            setData.department_name = this.props.userInfo.department_name;
            setData.floor_id = this.props.userInfo.department_id;
            setData.floor_name = this.props.userInfo.floor_name;
        }
        this.setState(setData);

        this.getCompanySelect();
        this.getDepartmentSelect(setData, "update");
        this.getFloorSelect(setData);

        this.setState({show: true, modalType});
    }

    onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let department_prefix = (event && event.department_prefix) ? event.department_prefix : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : "";

        if (param === "company_id") {
            this.setState({
                department_id: '',
                floor_id: '',
                floor_flg: floor_flg
            });
            this.getDepartmentSelect({company_id: value, floor_flg});
            this.getFloorSelect({company_id: this.state.company_id, floor_flg, department_prefix});
        } else if (param === "department_id") {
            this.floor_load_flag = true;
            this.setState({floor_id: ''});
            this.getFloorSelect({company_id: this.state.company_id, floor_flg: this.state.floor_flg, department_prefix});
        }
    }

    onCheckBoxChange (event, param) {
        super.onCheckBoxChange(event, param);
        if (param === "all_department_flag") {
            this.setState({department_id: ''});
            this.setState({floor_id: ''});
        }
    }

    getCompanySelect = () => {
        this.ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
                this.setState({
                    companySelect: res.data
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
    }

    getDepartmentSelect = ({company_id, floor_flg, department_id, department_prefix}, type) => {
        if(company_id)
        {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        this
            .ascAxios('post', `Common/departmentSelect`, {container: this.reactContainerPath, company_id, floor_flg})
            .then(res => {
                if (type === "update" && floor_flg && department_id && department_prefix) {
                    let my_prefix = department_prefix.slice(0, 2);
                    res.data.forEach(row => {
                        if (row.department_prefix && my_prefix === row.department_prefix) {
                            this.setState({department_id: row.value});
                        }
                    });
                }

                if(company_id)
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`push`);
                    this.common_buttondisabledLengthCheck(`department_load`,`pop`);
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`pop`);
                }

            this.setState({departmentSelect: res.data})
        }).catch(err => {
            alert(this.getErrorString(err.response.data))
        })
    }

    getFloorSelect = ({company_id, department_prefix, floor_flg}) => {
        if(this.floor_load_flag === true && department_prefix && company_id)
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
        }
        if (floor_flg) {
            this.ascAxios('post', `Common/floorSelect`, {container: this.reactContainerPath, company_id, department_prefix})
            .then(res => {
                if(this.state.department_id)
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                    this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                    this.floor_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
                }
                this.setState({
                    floorSelect: res.data
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
    }

    onClickHandle = modalType => event => {
        let {
            inbound_reject_id,
            company_id,
            department_id,
            floor_id,
            all_department_flag,
            telno,
            memo
        } = this.state;

        switch (modalType) {
            case 'insert':
                this.blockUI();
                this.ascAxios('post', `${this.reactContainerPath}/insert`, {
                    company_id,
                    department_id: floor_id || department_id,
                    all_department_flag,
                    telno,
                    memo
                })
                .then(res => {
                    alert(this.props.langText.Message.DataInsertSuccess);
                    this.reactTableRefresh();
                })
                .catch(err => {
                    alert(this.getErrorString(err.response.data));
                })
                break;

            case 'update':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        inbound_reject_id,
                        department_id: floor_id || department_id,
                        all_department_flag,
                        telno,
                        memo
                    })
                    .then(res => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })
                break;

            case 'delete':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/delete`, {inbound_reject_id})
                    .then(res => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })
                break;

            default:
                alert(this.getErrorString({code: 'modal'}));
        }

        this.setState({show: false});
    }

    validationHandle = param => {
        let nowState = null

        // at least one number, * is option
        let validTelno = /^([0-9]{1,19})[*]?$/

        switch (param) {
            case 'company_id':
                nowState = this.state[param] ? null : 'error'
                break

            case 'department_id':
                nowState = (this.state.department_id || this.state.all_department_flag) ? null : 'error'
                break

            case 'telno':
                nowState = (validTelno.test(this.state[param])) ? null : 'error'
                break

            case 'floor_id':
                nowState = (this.state.floor_id || this.state.all_department_flag) ? null : 'error'
                break

            case 'insert':
            case 'update':
            case 'delete':
                let {company_id, department_id, floor_id, all_department_flag, telno, floor_flg} = this.state

                nowState = (
                    company_id
                    && (department_id || all_department_flag)
                    && (floor_id || all_department_flag || !floor_flg)
                    && validTelno.test(telno)
                    )
                break

            default:
        }

        return nowState
    }

    render() {
        return (
            <div className='InboundReject'>
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="inboundReject-insert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>

                <InboundRejectModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    validationHandle = {this.validationHandle}
                    getScopeGreaterEqual={this.getScopeGreaterEqual}
                
                />
            </div>
        )
    }
}