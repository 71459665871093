export default {
    InboundGroupTypeNormal: "オムニコンタクト・ソフト/ハードフォン",
    InboundGroupTypeAndroid: "オムニフォン",
    InboundGroupTypeMulti: "同時着信",
    InboundGroupTypeNormalSearch: "オムニコンタクト・ソフト/ハードフォン",
    InboundGroupTypeAndroidSearch: "オムニフォン",
    InboundGroupTypeMultiSearch: "オムニコンタクト・オムニフォン・ソフト/ハードフォン",
    InboundTypeSoftphone: "オムニコンタクト用内線番号",
    InboundTypeHardPhone: "ソフトフォン・ハードフォン用内線番号",
    InboundTypeAndroid: "オムニフォン用内線番号",
    Placeholder: "選択してください。",
    Kddi: "KDDI",
    Rakuten: "楽天",
    Prodelight: "プロディライト",
    Softbank: "ソフトバンク",
    Sonus: "sonus",
    Hikari: "ひかり電話",
    Santu: "三通",
    SystemAuth: "システム管理者",
    CompanyAuth: "会社管理者",
    DepartmentAuth: "拠点管理者",
    FloorAuth: "フロア管理者",
    GeneralAuth: "一般",
    Extension: "内線番号",
    InboundGroup: "着信グループ",
    None: "無し",
    MonitoringFlgY: "モニタリングができる",
    MonitoringFlgN: "モニタリングができない",
    MonitoringFlgListY: "◯",
    MonitoringFlgListN: "×",
    SkillStatusCreated: "作成",
    SkillStatusModified: "編集",
    SkillStatusDeleted: "削除",
    External_Prefix: "自動生成します。",
    Placeholder_defaultSound: "デフォルト音声を使用します。",
    Placeholder_defaultexternal: "全番号",
    Placeholder_allResult: "全結果",
    Placeholder_searchAllResult: "検索結果を全選択",
    Placeholder_defaultDepartment: "全拠点",
    Placeholder_defaultfloor: "全フロア",
    Placeholder_none: "未選択",
    Placeholder_allOperator: "全オペレーター",
    Placeholder_2_number: "数字2桁",
    Placeholder_4_number: "数字4桁",
    Placeholder_None_Select: "初期から作成",
    Placeholder_All_Select: "全選択されています",
    Placeholder_Weekday_All_Select: "曜日全選択",
    Placeholder_Comment_Search: "コメント内容の検索",
    Placeholder_Keyword_Search: "テキスト化内容の検索",
    Placeholder_Display_Item_All_Select: "表示項目全選択",
    Placeholder_Template: "テンプレート選択",
    Download_type: "ダウンロードタイプ",
    IncomingNoAnswer: "不在入電",
    Anonymous: "非通知",
    Clear: "初期化",
    LangJapan: "日本語",
    LangEnglish: "英語",
    AudioClassMizuki: "Mizuki（女性）",
    AudioClassTakumi: "Takumi（男性）",
    CustomColumnTelNo: "電話番号",
    CustomColumnAddress: "住所",
    AudioFileUpload: "音声ファイルアップロード",
    AudioSelect: "音声選択",
    AudioSynthetic: "音声合成",
    Yes: "あり",
    No: "なし",
    AudioNonePlay: "再生しない",
    EndCall: "切電",
    PlayBack: "音声再生",
    NoAns: "NO ANSWER",
    Busy: "BUSY",
    Timeout: "タイムアウト",
    TimeoutPlaybackSet: "応答待ち時間超過時に音声を流す",
    TimeoutPlaybackUnset: "応答待ち時間超過時に音声を流さない",
    InboundUnset: "着信させない",
    InboundSet: "着信させる",
    Unset: "設定なし",
    SelectSkill: "スキルレベル選択",
    Use: "使用する",
    Disuse: "使用しない",
    SpeechSynthesis: "音声合成",
    SpeechSynthesisTranslate: "音声合成（翻訳）",
    OthersNumberInput: "その他の番号入力",
    OneYear: "1年",
    TwoYears: "2年",
    ThreeYears: "3年",
    FourYears: "4年",
    FiveYears: "5年",
    NoExpire: "有効期限なし",
    CompanyOverwrite: "会社設定を使用",
    AllExtension: "全内線",

    TotalCallConnectCount: "接続数（接続率）",
    TotalCallTime: "総通話時間",
    Score: "総合評価",
    CallConnectCount: "接続数",
    TalkingTime: "通話時間",
    SilenceCount: "無音回数",
    TalkRate: "OP会話比率",
    TalkConflictCount: "重なり回数",
    TalkSpeed: "会話速度",
    TalkSpeedDifference: "会話速度差",
    RallyingCount: "会話の往復回数",
    Frequency: "基本周波数",
    FrequencyDifference: "基本周波数の差",
    Accent: "音声のメリハリ",
    AccentDifference: "音声のメリハリの差",

    kubunList: [
        { value: 1, label: "営業日" },
        { value: 2, label: "休日" },
        { value: 3, label: "削除" }
    ],
    hourShowList: [
        { value: "00", label: "00" },
        { value: "01", label: "01" },
        { value: "02", label: "02" },
        { value: "03", label: "03" },
        { value: "04", label: "04" },
        { value: "05", label: "05" },
        { value: "06", label: "06" },
        { value: "07", label: "07" },
        { value: "08", label: "08" },
        { value: "09", label: "09" },
        { value: "10", label: "10" },
        { value: "11", label: "11" },
        { value: "12", label: "12" },
        { value: "13", label: "13" },
        { value: "14", label: "14" },
        { value: "15", label: "15" },
        { value: "16", label: "16" },
        { value: "17", label: "17" },
        { value: "18", label: "18" },
        { value: "19", label: "19" },
        { value: "20", label: "20" },
        { value: "21", label: "21" },
        { value: "22", label: "22" },
        { value: "23", label: "23" }
    ],
    // 分
    minuteShowList: [
        { value: "00", label: "00" },
        { value: "05", label: "05" },
        { value: "10", label: "10" },
        { value: "15", label: "15" },
        { value: "20", label: "20" },
        { value: "25", label: "25" },
        { value: "30", label: "30" },
        { value: "35", label: "35" },
        { value: "40", label: "40" },
        { value: "45", label: "45" },
        { value: "50", label: "50" },
        { value: "55", label: "55" }
    ],

    actionFlag: [
        { value: 1, label: "着信" },
        { value: 2, label: "BUSY" },
        // {value :3,label:"転送"}, AGI修正後に戻す
        { value: 4, label: "音声再生" }
    ],

    Floor: "フロア内",
    Department: "拠点内",
    Company: "会社内",
    Oem: "OEM内",
    System: "システム内",

    autoUpdateIntervalList: [
        { value: 1, label: "自動更新無し" },
        { value: 60000, label: "1分更新" },
        // {value :180000, label:"3分更新"},
        { value: 300000, label: "5分更新" }
    ],

    WaitStatusesAutoUpdateIntervalList: [
        { value: 5000, label: "5秒更新" },
        { value: 15000, label: "15秒更新" },
        { value: 1, label: "自動更新無し" }
    ],

    WaitStatusesAlertTimeList: [
        { value: 30, label: "30秒" },
        { value: 60, label: "1分" },
        { value: 120, label: "2分" },
        { value: 180, label: "3分" },
        { value: 240, label: "4分" },
        { value: 300, label: "5分" }
    ],

    SummaryTimeOption: [
        { value: "Unset", label: "指定しない", disabled: true },
        { value: "00", label: "0時" },
        { value: "01", label: "1時" },
        { value: "02", label: "2時" },
        { value: "03", label: "3時" },
        { value: "04", label: "4時" },
        { value: "05", label: "5時" },
        { value: "06", label: "6時" },
        { value: "07", label: "7時" },
        { value: "08", label: "8時" },
        { value: "09", label: "9時" },
        { value: "10", label: "10時" },
        { value: "11", label: "11時" },
        { value: "12", label: "12時" },
        { value: "13", label: "13時" },
        { value: "14", label: "14時" },
        { value: "15", label: "15時" },
        { value: "16", label: "16時" },
        { value: "17", label: "17時" },
        { value: "18", label: "18時" },
        { value: "19", label: "19時" },
        { value: "20", label: "20時" },
        { value: "21", label: "21時" },
        { value: "22", label: "22時" },
        { value: "23", label: "23時" }
    ],

    InoutTypeOption: [
        { value: "Unset", label: "指定しない", disabled: true },
        { value: "in", label: "IN" },
        { value: "out", label: "OUT" }
    ],

    CallResultOption: [
        { value: "Unset", label: "指定しない", disabled: true },
        { value: "ANSWERED", label: "ANSWERED" },
        { value: "NO ANSWER", label: "NO ANSWER" },
        { value: "BUSY", label: "BUSY" },
        { value: "PLAYBACK", label: "PLAYBACK" },
        { value: "FAILED", label: "FAILED" }
    ],

    UnsetOption: {
        value: "Unset",
        label: "指定しない",
        disabled: false
    },

    Language: {
        Arabic: "アラビア語",
        Chinese: "中国語",
        Danish: "デンマーク語",
        Dutch: "オランダ語",
        English: "英語",
        French: "フランス語",
        German: "ドイツ語",
        Icelandic: "アイスランド語",
        Italian: "イタリア語",
        Japanese: "日本語",
        Korean: "韓国語",
        Norwegian: "ノルウェー語",
        Polish: "ポーランド語",
        Portugal: "ポルトガル語",
        Romanian: "ルーマニア語",
        Russian: "ロシア語",
        Spanish: "スペイン語",
        Swedish: "スウェーデン語",
        Turkish: "トルコ語",
        Welsh: "ウェールズ語",
    },

    KeywordTermOption: [
        { value: "AND", label: "すべて含む"},
        { value: "OR", label: "いずれかを含む"}
    ],
    CallResultUnRegistered: "コール結果未登録",

    SummaryBase: [
        { value: "default", label: "基本集計"},
        { value: "callCategory", label: "通話分類集計"}
    ]
};
