import React, {Component} from "react"
import {Panel, Col, Glyphicon, Checkbox} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from '../../components/AscConstants';

export default class OperatorInsertModal extends Component {
    getUserInfoItem = () => {
        let {
            getScopeGreaterEqual,
            floor_flg,
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onSelectChange,
            validationHandle,
            checkDuplicateOperatorId,
            passwordValidationHandle
        } = this.props;
        let itemArr = [];

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))
        {
            itemArr.push(
                <InlineForm
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.companyId}
                    onChange = {e => onSelectChange(e, "companyId")}
                    options = {state.companySelect}
                    validationState = {validationHandle("companyId")}
                    placeholder = {!state.buttondisabledArr.companyId[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.companyId[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company))
        {
            itemArr.push(
                <InlineForm
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.departmentId}
                    onChange = {e => onSelectChange(e, "departmentId")}
                    options = {state.departmentSelect}
                    validationState = {validationHandle("departmentId")}
                    placeholder = {state.buttondisabledArr.departmentLoad[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.departmentId[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}>
                    {state.departmentName}
                </InlineForm.static>
            );
        }

        if (floor_flg) {
            if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
            {
                itemArr.push(
                    <InlineForm
                        key = "floorId"
                        controlId = "floorId"
                        label = {langText.Body.FloorName}
                        type = "select"
                        value = {state.floorId}
                        onChange = {e => onSelectChange(e, "floorId")}
                        options = {state.floorSelect}
                        validationState = {validationHandle("floorId")}
                        placeholder = {state.buttondisabledArr.floorLoad[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.floorId[0]}
                        />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "floorId"
                        controlId = "floorId"
                        label = {langText.Body.FloorName}>
                        {state.floorName}
                    </InlineForm.static>
                );
            }
        }

        itemArr.push(
            <InlineForm
                key = "operatorId"
                controlId = "operatorId"
                label = {langText.Body.OperatorId}
                type = "text"
                value = {state.operatorId}
                onChange = {e => onTextChange_Limit(e, "operatorId", charaLimit.Operator_Id)}
                onBlur={e => checkDuplicateOperatorId(e)}
                validationState = {validationHandle("operatorId")}
            />
        );
        if(state.operatorIdDuplicateFlag){
            itemArr.push(
                <p key = "operatorIdDuplicate" className="text-danger">
                    {langText.Body.OperatorIdDuplicate}
                </p>
            );
        }

        itemArr.push(
            <InlineForm
                key = "operatorName"
                controlId = "operatorName"
                label = {langText.Body.OperatorName}
                type = "text"
                value = {state.operatorName}
                onChange = {e => onTextChange_Limit(e, "operatorName", charaLimit.Operator_Name)}
                validationState = {validationHandle("operatorName")}
            />
        );



            itemArr.push(
                <InlineForm
                    key = "password"
                    controlId = "password"
                    label = {langText.Body.Password}
                    type = "password"
                    value = {state.password}
                    onChange = {e => onTextChange_Limit(e, "password",charaLimit.Operator_Password)}
                    validationState = {passwordValidationHandle("password", state)}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );



            itemArr.push(
                <InlineForm
                    key = "passwordRe"
                    controlId = "passwordRe"
                    label = {langText.Body.PasswordRe}
                    type = "password"
                    value = {state.passwordRe}
                    onChange = {e => onTextChange_Limit(e, "passwordRe",charaLimit.Operator_PasswordRe)}
                    validationState = {passwordValidationHandle("passwordRe", state)}
                    placeholder = {langText.Message.Password_Validation}
                    autoComplete="new-password"/>
            );
            if((state.password !== state.passwordRe) && (state.password.length > 0 && state.passwordRe.length > 0)){
                itemArr.push(
                    <p key = "passwordMismatch" className="text-danger">
                        {langText.Body.PasswordMismatch}
                    </p>
                );
            }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.UserInsert}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getUserInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
