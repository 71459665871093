import React from "react";
import { Panel, FormControl, Checkbox } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css";
import AscModal from "../Elements/Modal/Modal";
import AscComponent from "../../components/AscComponent";
import { CSVReader } from "react-papaparse";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import { CSVLink } from "react-csv";

export default class SkillCsvImportModal extends AscComponent {
    setCsvImportModalItem = () => {
        let { state, langText, onCheckBoxChange } = this.props;
        let itemArr = [];

        itemArr.push(
            <CSVReader
                onDrop={this.props.handleOnDrop}
                onError={this.props.handleOnError}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={this.props.handleOnRemoveFile}
                isReset={true}>
                <span>{langText.Message.CsvUpload}</span>
            </CSVReader>
        );

        if (state.csvFile !== false) {
            itemArr.push(
                <CommonTable
                    showPagination={true}
                    columns={state.insert_modal_column}
                    data={state.insert_csv_data}
                    showPaginationBottom={true}
                    pageSize={state.insert_csv_data.length}
                    sortable={false}
                    previousText={langText.Table.PreviousText}
                    nextText={langText.Table.NextText}
                    loadingText={langText.Table.LoadingText}
                    noDataText={langText.Table.NoDataText}
                    rowsText={langText.Table.RowsText}
                />
            );

            itemArr.push(
                <Checkbox
                    key="exchange_flag"
                    className="exchange_flag"
                    checked={state.exchange_flag}
                    onChange={(e) => onCheckBoxChange(e, "exchange_flag")}>
                    {langText.Body.ExchangeTableDataToCsv}
                    <p className="csvImportMsg text-danger">
                        {this.props.langText.Message.TableDataExchangeMessage}
                    </p>
                </Checkbox>
            );

            // エラーメッセージ出力用のtextareaパーツ
            itemArr.push(
                <FormControl
                    id="errTextarea"
                    componentClass="textarea"
                    rows="8"
                    value={state.error_message}
                    readOnly={true}
                />
            );
        }

        let headers = [];
        let datas = [[]];

        this.props.state.columns.forEach((header) => {
            if (header.Header !== langText.Body.SkillStatus &&
                header.Header !== langText.Body.OperatorName &&
                header.Header !== langText.Body.Control
            ) {
                headers.push(header.Header);
            }
        });

        // 入力例
        datas[0].push("example_operator_id", "1", langText.Body.CsvMemoSample);

        itemArr.push(
            <CSVLink
                className="csv-format-download-link"
                data={datas}
                headers={headers}
                filename={langText.Body.CsvTemplateFileName}>
                <b>{langText.Message.FormatDownloadLink}</b>
            </CSVLink>
        );

        return itemArr;
    };

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Body>{this.setCsvImportModalItem()}</Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
