import React from "react";
import {Row, Col, Checkbox} from "react-bootstrap";
import InlineForm from "../FromGroup/InlineForm";
import Component from "../../../components/AscComponent";
import CommonTable from "../Table/CommonTable";
import * as GlobalConst from "../../../components/AscConstants";


/**
 * 権限名入力アイテム
 * state.permission_nameが必要
 */
export class EditPermissionName extends Component {

    render() {
        return (
            <InlineForm
                key = "permission_name"
                controlId = "permission_name"
                label = {this.props.langText.Body.PermissionName}
                type = "text"
                value = {this.props.state.permission_name}
                onChange = {e => this.props.onTextChange_Limit(
                    e, "permission_name",this.props.charaLimit.Permission_permissionName)}
                validationState = {this.props.state.permission_name ? null : "error"} />
        );
    }
}

/**
 * 権限名表示アイテム
 * state.permission_nameが必要
 */
export class ViewPermissionName extends Component {

    render() {
        return (
            <InlineForm
                key="permission_name"
                controlId="permission_name"
                label={this.props.langText.Body.PermissionName}>
                <Col sm={5}>
                    <InlineForm.static
                        key="permission_name"
                        controlId="permission_name">
                        {this.props.state.permission_name}
                    </InlineForm.static>
                </Col>
            </InlineForm>
        )
    }
}

/**
 * ユーザー数表示アイテム
 * state.user_countが必要
 */
export class ViewPermissionUserCount extends Component {
    render() {
        return (
            <InlineForm
                key="user_count"
                controlId="user_count"
                label={this.props.langText.Body.UserCount}>
                <Col sm={5}>
                    <InlineForm.static
                        key="user_count"
                        controlId="user_count">
                        {this.props.state.user_count}
                        <Row className="text-danger fa-pull-right">
                            {this.props.annotation}
                        </Row>
                    </InlineForm.static>
                </Col>
            </InlineForm>
        )
    }
}

/**
 * 権限詳細表示アイテム
 * state[controller_id]が必要
 */
export class ViewPermissionDetail extends Component {

    render() {
        let modal_columns = [
            {
                Header :this.props.langText.Body.PermissionContent,
                accessor: "SettingColumn_PermissionContent",
            },
            {
                Header :this.props.langText.Body.PermissionScope,
                accessor: "SettingColumn_PermissionScope",
                width: this.props.boardWidth.small,
            },
            {
                Header :this.props.langText.Body.PermissionAll,
                accessor: "SettingColumn_PermissionAll",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionRead,
                accessor: "SettingColumn_PermissionRead",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionCreate,
                accessor: "SettingColumn_PermissionCreate",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionEdit,
                accessor: "SettingColumn_PermissionEdit",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionDelete,
                accessor: "SettingColumn_PermissionDelete",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionExport,
                accessor: "SettingColumn_PermissionExport",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionPlayback,
                accessor: "SettingColumn_PermissionPlayback",
                width: this.props.boardWidth.xxsmall,
            }
        ]
        let returnValue =[];
        GlobalConst.CONTROLLER_LIST.forEach(row => {
            returnValue.push(
                {
                    SettingColumn_PermissionContent:
                        this.props.langText.Body.PermissionContentList[row],
                    SettingColumn_PermissionScope:
                        this.props.langText.Body.PermissionScopeList[this.props.state[row].scope_code],
                    SettingColumn_PermissionAll:
                        <Checkbox checked={this.props.state[row].all} />,
                    SettingColumn_PermissionRead:
                        <Checkbox checked={this.props.state[row].read} />,
                    SettingColumn_PermissionCreate:
                        <Checkbox checked={this.props.state[row].create} />,
                    SettingColumn_PermissionEdit:
                        <Checkbox checked={this.props.state[row].edit} />,
                    SettingColumn_PermissionDelete:
                        <Checkbox checked={this.props.state[row].delete} />,
                    SettingColumn_PermissionExport:
                        <Checkbox checked={this.props.state[row].export} />,
                    SettingColumn_PermissionPlayback:
                        <Checkbox checked={this.props.state[row].playback} />,
                }
            );
        });
        return (
            <InlineForm
                key = "permission_detail"
                controlId = "permission_detail"
                label = {this.props.langText.Body.permissionDetail}>
                <CommonTable
                    label = {this.props.langText.Body.PermissionName}
                    showPagination = {false}
                    columns = {modal_columns}
                    data = {returnValue}
                    showPaginationBottom = {false}
                    pageSize = {returnValue.length}
                    sortable = {false}
                    style={{height:"200px"}}
                    previousText={this.props.langText.Table.PreviousText}
                    nextText={this.props.langText.Table.NextText}
                    loadingText={this.props.langText.Table.LoadingText}
                    noDataText={this.props.langText.Table.NoDataText}
                    rowsText={this.props.langText.Table.RowsText}
                />
            </InlineForm>
        );
    }
}

/**
 * 権限詳細入力アイテム
 * state[controller_id]が必要
 */
export class EditPermissionDetail extends Component {

    render() {
        let modal_columns = [
            {
                Header :this.props.langText.Body.PermissionContent,
                accessor: "SettingColumn_PermissionContent",
            },
            {
                Header :this.props.langText.Body.PermissionScope,
                accessor: "SettingColumn_PermissionScope",
                width: this.props.boardWidth.small,
            },
            {
                Header :this.props.langText.Body.PermissionAll,
                accessor: "SettingColumn_PermissionAll",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionRead,
                accessor: "SettingColumn_PermissionRead",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionCreate,
                accessor: "SettingColumn_PermissionCreate",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionEdit,
                accessor: "SettingColumn_PermissionEdit",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionDelete,
                accessor: "SettingColumn_PermissionDelete",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionExport,
                accessor: "SettingColumn_PermissionExport",
                width: this.props.boardWidth.xxsmall,
            },
            {
                Header :this.props.langText.Body.PermissionPlayback,
                accessor: "SettingColumn_PermissionPlayback",
                width: this.props.boardWidth.xxsmall,
            }
        ]
        let scopeList = this.getSelectOption("scope", this.props.langText.SelectOption);
        scopeList = scopeList.filter(item => {
            return GlobalConst.SCOPE_OBJECT[item.value].value
                >= GlobalConst.SCOPE_OBJECT[this.props.currentPermission.scope_code].value;
        });
        let returnValue =[];
        GlobalConst.CONTROLLER_LIST.forEach(row => {
            if (GlobalConst.SCOPE_OBJECT[this.props.state[row].scope_code].value
                < GlobalConst.SCOPE_OBJECT[this.props.currentPermission.scope_code].value) {
                returnValue.push(
                    {
                        SettingColumn_PermissionContent:
                            this.props.langText.Body.PermissionContentList[row],
                        SettingColumn_PermissionScope:
                            this.props.langText.Body.PermissionScopeList[this.props.state[row].scope_code],
                        SettingColumn_PermissionAll:
                            <Checkbox checked={this.props.state[row].all} />,
                        SettingColumn_PermissionRead:
                            <Checkbox checked={this.props.state[row].read} />,
                        SettingColumn_PermissionCreate:
                            <Checkbox checked={this.props.state[row].create} />,
                        SettingColumn_PermissionEdit:
                            <Checkbox checked={this.props.state[row].edit} />,
                        SettingColumn_PermissionDelete:
                            <Checkbox checked={this.props.state[row].delete} />,
                        SettingColumn_PermissionExport:
                            <Checkbox checked={this.props.state[row].export} />,
                        SettingColumn_PermissionPlayback:
                            <Checkbox checked={this.props.state[row].playback} />,
                    }
                )
            } else {
                returnValue.push(
                    {
                        SettingColumn_PermissionContent:
                            this.props.langText.Body.PermissionContentList[row],
                        SettingColumn_PermissionScope:
                            <select
                                value = {this.props.state[row].scope_code}
                                onChange = {e => this.props.onNestSetState(e, row, "scope_code")} >
                                {scopeList.map(scope_row =>
                                    <option value={scope_row.value}>{scope_row.label}</option>)}
                            </select>,
                        SettingColumn_PermissionAll:
                            <Checkbox checked={this.props.state[row].all}
                                      onChange={e => this.props.onNestCheckBoxChange(e, row, "all")} />,
                        SettingColumn_PermissionRead:
                            <Checkbox checked={this.props.state[row].read}
                                      onChange={e => this.props.onNestCheckBoxChange(e, row, "read")} />,
                        SettingColumn_PermissionCreate:
                            <Checkbox checked={this.props.state[row].create}
                                      onChange={e => this.props.onNestCheckBoxChange(e, row, "create")} />,
                        SettingColumn_PermissionEdit:
                            <Checkbox checked={this.props.state[row].edit}
                                      onChange={e => this.props.onNestCheckBoxChange(e, row, "edit")} />,
                        SettingColumn_PermissionDelete:
                            <Checkbox checked={this.props.state[row].delete}
                                      onChange={e => this.props.onNestCheckBoxChange(e, row, "delete")} />,
                        SettingColumn_PermissionExport:
                            <Checkbox checked={this.props.state[row].export}
                                      onChange={e => this.props.onNestCheckBoxChange(e, row, "export")} />,
                        SettingColumn_PermissionPlayback:
                            <Checkbox checked={this.props.state[row].playback}
                                      onChange={e => this.props.onNestCheckBoxChange(e, row, "playback")} />,
                    }
                );
            }
        });
        return (
            <InlineForm
                key = "permission_detail"
                controlId = "permission_detail"
                label = {this.props.langText.Body.permissionDetail}>
                <CommonTable
                    label={this.props.langText.Body.PermissionName}
                    showPagination={false}
                    columns={modal_columns}
                    data={returnValue}
                    showPaginationBottom={false}
                    pageSize={returnValue.length}
                    sortable={false}
                    style={{height: "200px"}}
                    previousText={this.props.langText.Table.PreviousText}
                    nextText={this.props.langText.Table.NextText}
                    loadingText={this.props.langText.Table.LoadingText}
                    noDataText={this.props.langText.Table.NoDataText}
                    rowsText={this.props.langText.Table.RowsText}
                />
            </InlineForm>
        );
    }
}
