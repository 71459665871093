import React from "react";
import { Button, Row, Col, Glyphicon} from 'react-bootstrap'
import CommonTable from '../Elements/Table/CommonTable'
import AscComponent from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import { MultiSelect} from "react-multi-select-component";
import { CSVLink } from "react-csv";
import 'react-block-ui/style.css';

export default class MonitoringSummary extends AscComponent {
    constructor(props) {
        super(props);
        const now = moment();
        const startDate = moment(now).subtract(1, 'days').startOf("days");
        const endDate =  moment(now).subtract(1, 'days').endOf("days");
        const minDate = moment(now).subtract(1, 'days').endOf("days");

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            searchResultCsvData: [],
            detailResultCsvData: [],

            filtered: [
                { id: "pbx_id", value: []},
                { id: "date", value: [startDate, endDate]},
                { id: "isDownload", value: false}
            ],
            pages: null,
            show: false,
            loading: false,

            startDate: startDate,
            endDate: endDate,
            minDate: minDate,

            pbxList: [],
            pbxListSelected: [],
            message: [],
        };
        this.searchCsvLink = React.createRef();
        this.detailCsvLink = React.createRef();

        this.searchResultCsvHeader = [
            this.props.langText.Body.PbxName,
            this.props.langText.Body.Date,
            this.props.langText.Body.CpuUsageMax,
            this.props.langText.Body.CpuUsageMin,
            this.props.langText.Body.CpuUsageAvg,
            this.props.langText.Body.MemoryUsageMax,
            this.props.langText.Body.MemoryUsageMin,
            this.props.langText.Body.MemoryUsageAvg,
        ];

        this.getPbxListData();
    }

    getPbxListData = async () =>{
        try {
            const pbx_list = await this.ascAxios(
                "post", `Monitoring/getPbxForMonitoring`
            );
            this.setState({pbxList : pbx_list.data, pbxListSelected : pbx_list.data});
            return pbx_list;
        } catch (err) {
            alert(this.getErrorString(err));
        }
    }

    getColumnsData() {
        const columnsDataList = [
            {
                Header: this.props.langText.Body.PbxName,
                accessor: "pbx_name",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.Date,
                accessor: "date",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.CpuUsageMax,
                accessor: "cpu_max",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.CpuUsageMin,
                accessor: "cpu_min",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.CpuUsageAvg,
                accessor: "cpu_avg",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.MemoryUsageMax,
                accessor: "memory_max",
                sortable: false,
                filterable: false,
            },
            {
                Header: this.props.langText.Body.MemoryUsageMin,
                accessor: "memory_min",
                filterable: false,
                sortable: false,
            },
            {
                Header: this.props.langText.Body.MemoryUsageAvg,
                accessor: "memory_avg",
                filterable: false,
                sortable: false,
            }
        ];

        return columnsDataList;
    }

    onChange = (event, param) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";

        if (event) {
            value = event.target ? event.target.value : event;
        }

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }

        this.setState({
            filtered: my_filtered
        });
    }

    onChangeDate = stateKey => event => {
        if (event == null) return false;
        let isOneMonth = false;
        let days = null;
        let message = this.state.message;

        switch (stateKey) {
            case "startDate":
                const startDay = moment(this.state.endDate).subtract(1, 'months').format('YYYY/MM/DD');
                if (event.isAfter(startDay)) isOneMonth = true;
                days = this.calcDateDays(event, this.state.endDate);
                this.setState({[stateKey]: event.startOf("days")});
                break;
            case "endDate":
                const endDay = moment(this.state.startDate).add(1, 'months').format('YYYY/MM/DD');
                if (event.isBefore(endDay)) isOneMonth = true;
                days = this.calcDateDays(this.state.startDate, event);
                this.setState({[stateKey]: event.endOf("days")});
                break;
            default:
                break;
        }

        if (!isOneMonth) {
            if(!message.find(row => row == this.props.langText.Message.GreaterThanOneMonthError)) {
                message.push(this.props.langText.Message.GreaterThanOneMonthError);
            }
        } else {
            message = message.filter(row => row != this.props.langText.Message.GreaterThanOneMonthError);
        }
    
        if (days < 0) {
            if(!message.find(row => row == this.props.langText.Message.DateSpanError)){
                message.push(this.props.langText.Message.DateSpanError);
            }
        } else {
            message = message.filter(row => row != this.props.langText.Message.DateSpanError);
        }

        this.setState({message});
    }

    onSearch = async () => {
        try {
            const filter = [
                {   
                    id: "pbx", 
                    value: this.state.pbxListSelected.map(pbx => pbx.value)
                },
                { 
                    id: "date", 
                    value: [this.state.startDate, this.state.endDate] 
                },
                {   
                    id: "isDownload", 
                    value: false 
                }
            ];

            this.setState({
                filtered: filter,
            }, () => {
                this.table.current.state.page = 0;
            });

        } catch(err) {
            this.showErrorObjectMesssage(err);
        }
    }

    onDownload = async (param) => {
        const filter = [
            {   
                id: "pbx", 
                value: this.state.pbxListSelected.map(pbx => pbx.value)
            },
            { 
                id: "date", 
                value: [this.state.startDate, this.state.endDate] 
            },
            {   
                id: "isDownload", 
                value: true 
            }
        ];

        try {
            if(param == "search_result_csv_download"){
                this.setState({
                    loading: true
                });

                const csv_data = await this.ascAxios(
                    'post', `${this.reactContainerPath}/board`,
                    {filtered: filter}
                );

                if (csv_data.data.rows && csv_data.data.rows.length > 0) {
                    let csv_arr = [];
                    csv_data.data.rows.forEach((row,index)=>{
                        let values = Object.values(row);
                        csv_arr.push(values);
                    })
        
                    this.setState({
                        searchResultCsvData: csv_arr,
                        loading: false
                    });

                    this.searchCsvLink.current.link.click();
                } else {
                    this.setState({
                        loading: false
                    });
                    throw new Error("Download_Failed");
                }
    
            } else if (param == "detail_result_csv_download"){
                this.getValidationCheck("detailDownload");

                this.setState({
                    loading: true
                });

                const csv_data = await this.ascAxios(
                    'post', `${this.reactContainerPath}/detailResultCsvDL`,
                    {filtered: filter}
                );

                if (csv_data.data.rows && csv_data.data.rows.length > 0) {
                    let headerArr = [
                        this.props.langText.Body.DateTime,
                        this.props.langText.Body.Class
                    ].concat(csv_data.data.rows[0]);
        
                    csv_data.data.rows.shift();
                    csv_data.data.rows.forEach((row)=> {
                        let monitoring_type_index = 1;
                        if(row[monitoring_type_index]){
                            row[monitoring_type_index] = this.props.langText.Body.MonitoringTypeList[row[monitoring_type_index]];
                        }
                    });
    
                    csv_data.data.rows.unshift(headerArr);
                    this.setState({
                        detailResultCsvData: csv_data.data.rows,
                        loading: false
                    });

                    this.detailCsvLink.current.link.click();
                } else {
                    this.setState({
                        loading: false
                    });
                    throw new Error("Download_Failed");
                }
            } 
        } catch(err) {
            this.showErrorObjectMesssage(err);
        }
    }

    onMultiSelectChange = param => event => {
        this.setState({[param]: event});
        this.getValidationCheck(param, event);
    }

    getValidationCheck = (param, event) =>{
        let message = this.state.message;
        let days = null;

        switch (param) {
            case "pbxListSelected":
                if(event.length == 0){
                    message.push(this.props.langText.Message.NoSelectedPbxError);
                    this.setState({message});
                } else {
                    message = message.filter(row => row != this.props.langText.Message.NoSelectedPbxError);
                    this.setState({message});
                }
                break;
            case "detailDownload":
                days = this.calcDateDays(this.state.startDate, this.state.endDate);
                if (days > 0) {
                    throw new Error("DetailDownloadFailed");
                } 
                break;
            default:
                this.setState({message});
                break;
        }
    }

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/Summary", name: this.props.langText.Body.Summary },
                        { name: this.props.langText.Body.MonitoringSummary },
                    ]}
                />
                <Row>
                    <Col xs={12} md={12}>
                        <Col sm={4} md={2} lg={1}>
                            <div className="margin-top-1">{this.props.langText.Body.Pbx}</div>
                        </Col>
                        <Col sm={12} md={12} lg={3}>
                            <MultiSelect
                                className="margin-top-03"
                                hasSelectAll={true}
                                options={this.state.pbxList}
                                value={this.state.pbxListSelected}
                                onChange={this.onMultiSelectChange("pbxListSelected")}
                                overrideStrings={{
                                    selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                    allItemsAreSelected: this.props.langText.Body.AllSelected,
                                    selectAll: this.props.langText.MultiSelect.AllSelect,
                                    search: this.props.langText.Body.Search,
                                }}/>
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <label className="margin-top-05 search-message">
                                {this.state.message.map((row, key) =>
                                    <p key={key}>{row}</p>
                                )}
                            </label>
                        </Col>
                    </Col>

                    <Col xs={12} md={12} className="margin-top-05">
                        <Col sm={4} md={2} lg={1}>
                            <div className="margin-top-05">{this.props.langText.Body.CustomerBoardStart}</div>
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                            <DatePicker
                                onChange = {this.onChangeDate("startDate")}
                                selected = {this.state.startDate}
                                dateFormat = "YYYY-MM-DD"
                                className = "form-control"
                                todayButton = {this.props.langText.Body.Today}
                                maxDate={this.state.minDate}
                            />
                        </Col>
                        <Col sm={4} md={2} lg={1}>
                            <div className="margin-top-05">{this.props.langText.Body.CustomerBoardEnd}</div>
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                        <DatePicker
                                onChange = {this.onChangeDate("endDate")}
                                selected = {this.state.endDate}
                                dateFormat = "YYYY-MM-DD"
                                className = "form-control"
                                todayButton = {this.props.langText.Body.Today}
                                maxDate={this.state.minDate}
                            />
                        </Col>
                        <Col  sm={1} md={1} lg={1}>
                            <Button
                                className="monitoring-summary-search"
                                bsStyle="primary"
                                bsSize="sm"
                                onClick={e=>this.onSearch()}
                                disabled = {this.state.message.length > 0 || this.state.loading}
                            >
                                {this.props.langText.Body.Search}
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12} className="margin-top-10">
                        <Button
                            className="table-button"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => this.onDownload("search_result_csv_download")}
                            disabled = {this.state.message.length > 0 || this.state.loading}
                        >
                            {this.props.langText.Body.SearchDownload}
                        </Button>
                        <CSVLink
                            headers={this.searchResultCsvHeader}
                            data={this.state.searchResultCsvData}
                            filename={
                                "monitoring_search_result_download" +
                                "_" +
                                this.getMomentTime({
                                    date: this.state.startDate,
                                    format: "YYYYMMDD",
                                }) +
                                "-" +
                                this.getMomentTime({
                                    date: this.state.endDate,
                                    format: "YYYYMMDD",
                                }) +
                                ".csv"
                            }
                            className="csv-download-link-format hidden"
                            ref={this.searchCsvLink}
                        />
                        <Button
                            className="table-button"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => this.onDownload("detail_result_csv_download")}
                            disabled = {this.state.message.length > 0 || this.state.loading}
                        >
                            {this.props.langText.Body.DetailDownload}
                        </Button>
                        <CSVLink
                            data={this.state.detailResultCsvData}
                            filename={
                                "monitoring_detail_result_download" +
                                "_" +
                                this.getMomentTime({
                                    date: this.state.startDate,
                                    format: "YYYYMMDD",
                                }) +
                                "-" +
                                this.getMomentTime({
                                    date: this.state.endDate,
                                    format: "YYYYMMDD",
                                }) +
                                ".csv"
                            }
                            className="csv-download-link-format hidden"
                            ref={this.detailCsvLink}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{ id: "id", desc: true }]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                            showPagination={true}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
