import React from "react";
import {Button, Row, Col, Glyphicon} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import * as GlobalConst from "../../components/AscConstants";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import AutoTagRuleModal from "./AutoTagRuleModal";

export default class AutoTagRule extends Component {
    constructor(props) {
        super(props);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // data item
            companySelect: [],
            tagSelect: [],
            businessSelect: [],
            inputValue: "",
            count_disabled: true,
            keywords: []
        };

        this.countConditionSelect = this.getSelectOption('count_condition', this.props.langText.SelectOption);
    }

    getColumnsData() {
        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: 'id',
            filterable: false,
            width: this.props.boardWidth.id,
        });

        
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                filterable: false,
                sortable: false,
                accessor: 'cm12_companies.company_name',
                width: this.props.boardWidth.xlarge
            });
        }
        
        itemArr.push({
            Header: this.props.langText.Body.RuleName,
            filterable: false,
            sortable: false,
            accessor: 'name',
            width: this.props.boardWidth.large
        });

        itemArr.push({
            Header: this.props.langText.Body.AutoTagRule,
            accessor: 'condition',
            filterable: false,
            sortable: false,
            Cell: data => {
                if (data && data.value) {
                    let auto_rule = data.row._original.auto_rule === "keyword" ? `【${this.props.langText.Body.Keyword}】` : "";
                    let keyword_association = data.value.keyword_association === "and" 
                        ? this.props.langText.Body.And
                        : this.props.langText.Body.Or
                    let count_condition = data.value.count_condition === "less" ? this.props.langText.Body.CountLess : this.props.langText.Body.CountMore;
                    let speech_person = data.value.speech_person === "operator"
                        ? this.props.langText.Body.SpeechOperator
                        : data.value.speech_person === "dest"
                        ? this.props.langText.Body.Customer
                        : this.props.langText.Body.AllSpeechPeople;
                    let keywords = data.value.keywords.map((row, idx) => {
                        let label = row.label;
                        let style = {
                            backgroundColor: '#D8D8D8',
                            padding: '2px 4px',
                            borderRadius: '4px',
                            marginRight: '8px'
                        };

                        if (row.isNot) style.backgroundColor = '#FF8787';
                        else if (row.isRegExp) style.backgroundColor = '#F5F5DC';
                        else label += `(${data.value.rule_count + count_condition})`;

                        return (<span
                            key={"condition" + idx}
                            style={style}
                        >
                            {label}
                        </span>);
                    });
                    let str = this.sprintf(this.props.langText.Message.AutoTagRuleDescriptionMessage, keyword_association, speech_person);
                    return (
                        <div>
                            {auto_rule}
                            「{keywords}」
                            {str}
                        </div>
                    );
                } else {
                    return "";
                }
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.BusinessName,
            accessor: 'ct606_assign_call_tag_auto_rule_task_infos',
            width: this.props.boardWidth.xlarge,
            filterable: false,
            sortable: false,
            Cell: data => {
                let display_business = data.value && data.value.map((row, idx) => {
                    return <span key={"display_business" + idx}>【{row.cm21_task.business_name}】</span>
                });

                return display_business;
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.CallCategoryTag,
            accessor: 'ct604_assign_call_tag_call_tag_auto_rule_infos',
            width: this.props.boardWidth.xlarge,
            filterable: false,
            sortable: false,
            Cell: data => {
                let display_tag = data.value && data.value.map((row, idx) => {
                    return (<span 
                        key={"display_tag" + idx}
                        style={{
                            backgroundColor: row.cm66_call_tags.rgb,
                            color: row.cm66_call_tags.font_rgb,
                            padding: '2px 4px',
                            borderRadius: '4px',
                            marginRight: '8px'
                        }}>
                            {row.cm66_call_tags.name}
                        </span>);
                });

                return display_tag;
            }
        });

        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.Control,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize='xsmall'
                            onClick={this.modalShow('update', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='pencil'/>
                        </Button>
                    )
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize='xsmall'
                            onClick={this.modalShow('read', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='eye-open'/>
                        </Button>
                    )
                }

                if (this.props.currentPermission.create) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("copy", data.row._original)}
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize='xsmall'
                            onClick={this.modalShow('delete', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='minus'/>
                        </Button>
                    )
                }

                return (
                    <Row className='text-center'>
                        {rowData}
                    </Row>
                )
            }
        });

        return itemArr;
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();

        let setData = {
            id: param && param.id
                ? param.id
                : '',
            company_id: param && param.cm12_id
                ? param.cm12_id
                : '',
            company_name: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : '',
            name: param && param.name && modalType !== "copy"
                ? param.name
                : '',
            auto_rule: param && param.auto_rule
                ? param.auto_rule
                : 'keyword',
            tags: param && param.ct604_assign_call_tag_call_tag_auto_rule_infos
                ? param.ct604_assign_call_tag_call_tag_auto_rule_infos.map(row => {
                    return {
                        value: row.cm66_call_tags.id,
                        label: row.cm66_call_tags.name,
                        rgb: row.cm66_call_tags.rgb,
                        font_rgb: row.cm66_call_tags.font_rgb
                    }
                })
                : '',
            business: param && param.ct606_assign_call_tag_auto_rule_task_infos
                ? param.ct606_assign_call_tag_auto_rule_task_infos.map(row => row.cm21_task.id)
                : '',
            condition: param && param.condition
                ? param.condition
                : '',
            dispaly_tags: param && param.ct604_assign_call_tag_call_tag_auto_rule_infos
                ? param.ct604_assign_call_tag_call_tag_auto_rule_infos.map(row => row.cm66_call_tags)
                : '',
            display_business: param && param.ct606_assign_call_tag_auto_rule_task_infos
                ? param.ct606_assign_call_tag_auto_rule_task_infos.map(row => row.cm21_task)
                : '',
            modalType,
            show: true
        };

        try {
            if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) && modalType === "insert") {
                await this.setCommonCompanySelect(this.reactContainerPath.split("/")[1]);
                setData.tagSelect = [];
                setData.businessSelect = [];
            } else if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company) && modalType === "insert") {
                setData.company_id = this.props.userInfo.company_id;
                setData.company_name = this.props.userInfo.company_name;
            } 

            if (setData.company_id) {
                await this.setTagsInfoList(setData.company_id, ["tagSelect"]);
                await this.getAllTaskInfo(setData.company_id);
            }
            
            if (modalType !== "insert") await this.getNotCallAnalysisExternalListByBusiness(setData.business);
            else setData.external_infos = [];
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }

        if (setData.condition) setData = this.convertConditionJsonToDataType(setData, setData.auto_rule);
        else setData = this.initConditionData(setData);

        let keyword_idx = setData.keywords.findIndex(row => row.__isNew__ === true);
        
        if (keyword_idx !== -1) setData.count_disabled = false;

        this.setState(setData);
    };

    
    // ルール条件のデータ加工json → dataType
    convertConditionJsonToDataType = (data, auto_rule) => {
        switch (auto_rule) {
            case "keyword":
                let condition = data.condition;
                data.keywords = condition.keywords;
                data.rule_count = condition.rule_count;
                data.speech_person = condition.speech_person;
                data.count_condition = condition.count_condition;
                data.keyword_association = condition.keyword_association;
                break;
            default:
                break;
        }

        return data;
    }

    // ルール条件のデータ加工dataType → json
    convertConditionDataTypeToJson = (data, auto_rule) => {
        let condition = {};
        switch (auto_rule) {
            case "keyword":
                condition = {...data};
                break;
            default:
                break;
        }

        return condition;
    }

    // ルール条件のstatus初期化
    initConditionData = (data) => {
        let param = ["keywords", "rule_count", "speech_person", "count_condition", "keyword_association"];

        param.forEach(row => {
            switch (row) {
                case "rule_count":
                    data[row] = 1;
                    break;

                case "count_condition":
                    data[row] = this.countConditionSelect[0].value;
                    break;
                
                case "keyword_association":
                    data[row] = "or";
                    break;

                case "speech_person":
                    data[row] = "operator";
                    break;

                case "keywords":
                    data[row] = [];
                    break;
                
                default:
                    data[row] = '';
                    break;
            }
        });

        return data;
    }

    onClickHandle = modalType => async event => {
        let {
            id,
            company_id,
            name,
            auto_rule,
            tags,
            business,
            keywords,
            rule_count,
            speech_person,
            count_condition,
            keyword_association
        } = this.state;
        let condition = this.convertConditionDataTypeToJson({
            keywords,
            rule_count,
            speech_person,
            count_condition,
            keyword_association
        }, auto_rule);

        let tag_ids = tags && tags.length > 0 ? tags.map(row => row.value) : [];

        this.blockUI();
        switch (modalType) {
            case "insert":
            case "copy":
                await this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                    company_id,
                    name,
                    auto_rule,
                    tags: tag_ids,
                    business,
                    condition
                });
                    
                alert(this.props.langText.Message.DataInsertSuccess);
                break;
            case "update":
                await this.ascAxios("post", `${this.reactContainerPath}/update`, {
                    id,
                    company_id,
                    name,
                    auto_rule,
                    tags: tag_ids,
                    business,
                    condition
                });
                
                alert(this.props.langText.Message.DataUpdateSuccess);
                break;
            case "delete":
                await this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    id
                });
                    
                alert(this.props.langText.Message.DataDeleteSuccess);
                break;
            default:
                break;
        }
        
        this.reactTableRefresh();
        this.setState({
            show: false
        });
    };

    validationHandle = param => {
        let nowState = null;
        let {
            company_id,
            name,
            keywords,
            tags,
        } = this.state;
        let regExpKeyword = keywords.filter(row => row.isRegExp);

        switch (param) {
            case "company_id":
                nowState = this.state[param]
                    ? null
                    : "error"; 
                break;
            case "name":
                nowState = this.state[param]
                    ? null
                    : "error"; 
                break;
            case "keywords":
                nowState = this.state[param] && this.state[param].length > 0
                    ? null
                    : "error"; 
                break;
            case "tags":
                nowState = this.state[param] && this.state[param].length > 0
                    ? null
                    : "error"; 
                break;
            case "insert":
            case "copy":
            case "update":
                nowState = (
                    company_id &&
                    name &&
                    (keywords && keywords.length > 0 && (
                        !regExpKeyword.some(row => !row.isValid)
                    )) &&
                    (tags && tags.length > 0)
                );
                break;
            case "delete":
                nowState = true;
                break;

            default:
                break;
        }

        return nowState;
    };

    onSelectChange = async (event, param) => {
        super.onSelectChange(event, param);
    
        let value = event && event.value ? event && event.value : "";
        
        switch (param) {
            case "company_id":
                await this.setTagsInfoList(value, ["tagSelect"]);
                await this.getAllTaskInfo(value);
                this.setState({ tags: [] });
                break;
        
            default:
                break;
        }
    }

    /**
     * 正規表現オブジェクトを生成
     * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/RegExp
     */
    getRegexpKeywordValidationCheck = (regexp_value) => {
        let return_flag = true;
        let regex = null;

        try {
            regex = new RegExp(regexp_value);
        } catch(error) {
            //正規表現の作成が失敗した場合、エラーメッセージを出力し有効フラグをfalseにする
            alert(this.props.langText.Message.KeywordRegExpInvalidMessage);
            return_flag = false;
        }
        
        return return_flag;
    }

    onMultiSelectChange = async (event, param) => {
        if (param !== "tags") super.onMultiSelectChange(event, param);
        
        switch (param) {
            case "keyword":
                let keyword_idx = event.findIndex(row => row.__isNew__ === true);
                const prev_keyword_arr = this.state.keywords;
                const new_input_keyword = event.filter(keyword_obj => prev_keyword_arr && !prev_keyword_arr.includes(keyword_obj));

                if (new_input_keyword.length > 0 && new_input_keyword[0].isRegExp) {
                    event.forEach(row => {
                        if (row.value === new_input_keyword[0].value) {
                            row.isValid = this.getRegexpKeywordValidationCheck(new_input_keyword[0].value);
                        }
                    });
                }

                this.setState({
                    keywords: event,
                    count_disabled: keyword_idx !== -1 ? false : true
                });
                break;
            case "business":
                await this.getNotCallAnalysisExternalListByBusiness(event.map((row) => {
                    return row.value;
                }));
                break;
            case "tags":
                this.setState({ [param]: event });
            default:
                break;
        }
    }

    onNumberChange = (event, param) => {
        let max = 99;
        let min = 1;
        let value = event ? event.target.value : "";
        value = value.replace(/[^0-9]+/i, "");

        if (value !== "") {
            if (value.length > 1 && value.charAt(0) === "0") value = value.substr(1);
            Number(value);
        }

        if (value > max || value < min) return;

        this.setState({[param]: value});
    }

    getAllTaskInfo = async (company_id) => {
        try {
            const result = await this.ascAxios("post", `${this.reactContainerPath}/getAllTaskInfo`, { company_id });

            this.setState({ businessSelect: result.data });
        } catch (err) {
            console.error(err);   
            this.showErrorObjectMesssage(err); 
        }
    }

    getNotCallAnalysisExternalListByBusiness = async (business_ids) => {
        try {
            if (business_ids.length > 0) {
                const result = await this.ascAxios("post", `${this.reactContainerPath}/getNotCallAnalysisExternalListByBusiness`, { business_ids });
                
                this.setState({ external_infos: result.data });
            } else {
                this.setState({ external_infos: [] });
            }
        } catch (err) {
            console.error(err);   
            this.showErrorObjectMesssage(err); 
        }
    }

    onInputChangeHandle = (input) => {
        this.setState({
            inputValue: input
        });
    }

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/CallCategory", name: this.props.langText.Body.CallCategory },
                        { name: this.props.langText.Body.AutoTagRuleV2 },
                    ]}
                />
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="auto-tag-rule-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>
                <AutoTagRuleModal
                    state={this.state}
                    propSetState={this.propSetState}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                    onClick={this.onClickHandle}
                    onTextChange_Limit={this.onTextChange_Limit}
                    onSelectChange={this.onSelectChange}
                    onMultiSelectChange={this.onMultiSelectChange}
                    validationHandle={this.validationHandle}
                    getScopeGreaterEqual={this.getScopeGreaterEqual}
                    onKeyDownIntCheck={this.onKeyDownIntCheck}
                    onNumberChange={this.onNumberChange}
                    countConditionSelect={this.countConditionSelect}
                    onRadioChange={this.onRadioChange}
                    onInputChangeHandle={this.onInputChangeHandle}
                />
            </React.Fragment>
        );
    }
}
