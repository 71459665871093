import React from 'react'
import { Button, Row, Col, Glyphicon, FormControl } from 'react-bootstrap'
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import AutoCallDetailListUpdateModal from "./AutoCallDetailListUpdateModal";
import AutoCallDetailListInsertModal from "./AutoCallDetailListInsertModal";
import AutoCallDetailListDeleteModal from "./AutoCallDetailListDeleteModal";
import AutoCallDetailListCsvInsertModal from "./AutoCallDetailListCsvInsertModal";
import AutoCallListManagementModal from "./AutoCallListManagementModal";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import ErrorMessageModal from "../Elements/AscElements/ErrorMessageModal";
import * as GlobalConst from '../../components/AscConstants';

export default class AutoCallDetailList extends AscComponent {
    constructor(props) {
        super(props);
        this.modalShow = this.modalShow.bind(this);
        //直リンクで遷移してきた場合、不正操作としてForbidden画面を表示
        if (this.props.location.state === undefined) {
            this.props.historyPush({ pathname: "/Forbidden" });
        }
        this.state = {
            // table item
            data: [],
            filtered: [],
            pages: null,
            loading: false,
            header_json: [],
            errorMessageModalShow: false,
            columns: [],
            breadcrumb_display_name: '',
            cm76_id: this.props.location.state ? this.props.location.state.displayData.id : '',
            cm12_id: this.props.location.state ? this.props.location.state.displayData.cm12_companies.id : '',
        }
    }

    componentDidMount() {
        if (this.props.location.state === undefined) return false;
        this.getAutoCallData(this.state.cm76_id)
            .then(autoCallData => {
                let header_json = autoCallData.json_data ? autoCallData.json_data : [];
                this.setState({
                    columns: this.getColumnsData(header_json),
                    header_json: header_json,
                    list_name: autoCallData.list_name,
                    breadcrumb_display_name: autoCallData.list_name,
                });
            }).catch(err => {
                alert(this.props.langText.Message.GetDataError);
                this.props.historyPush({ pathname: '/AutoCallList' });
            });
    }

    /**
     * 自動発信リスト（cm76）を取得する
     * @return {array} autoCallData ['id', 'list_name', 'json_data']
     */
    async getAutoCallData(cm76_id) {
        try {
            let autoCallListData = await this.ascAxios("post", `AutoCallList/getAutoCallList`, {
                cm76_id,
            });
            if (autoCallListData.data.length > 0) {
                return autoCallListData.data[0];
            } else {
                throw new Error("GetDataError");
            }
        } catch(err) {
            this.showErrorObjectMesssage(err, "GetDataError");
        }
    }

    getColumnsData(header_json) {
        let displayDataList = [
            {
                Header: this.props.langText.Body.Id,
                accessor: 'id',
                filterable: false,
                width: this.props.boardWidth.id,
            }, {
                Header: this.props.langText.Body.Telno,
                accessor: 'tel_number',
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, 'tel_number')} />
            }
        ];

        header_json.forEach((header) => {
            if (header.disabled === 'Y') return;//無効ヘッダは表示しない
            displayDataList.push({
                Header: header.header_name,
                filterable: false,
                sortable: false,
                accessor: 'column_json',
                Cell: data => {
                    if (data.row._original.column_json != null && data.row._original.column_json.length > 0) {
                        return (
                            <Row> {data.row._original.column_json[0][header.column_name]}</Row>
                        );
                    } else {
                        //項目関連データが登録されていない場合（リスト新規登録時など）
                        return <Row> { } </Row>
                    }
                },
            });
        });

        if (this.props.currentPermission.edit) {
            displayDataList.push({
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];
                    rowData.push(
                        <Button
                            key={0}
                            bsSize='xsmall'
                            onClick={(event) => this.modalShow(event, 'update', data.row._original)}
                            className='control-button'
                        >
                            <Glyphicon glyph='pencil' />
                        </Button>
                    );
                    rowData.push(
                        <Button
                            key={2}
                            bsSize='xsmall'
                            onClick={(event) => this.modalShow(event, 'delete', data.row._original, true)}
                            className='control-button'
                        >
                            <Glyphicon glyph='minus' />
                        </Button>
                    );
                    return (
                        <Row className='text-center'>
                            {rowData}
                        </Row>
                    )
                }
            });
        }
        return displayDataList;
    }

    async modalShow(event, modalType, param, loadingFlag = false) {
        event.preventDefault();
        let headerItems = [];
        let detail_json = [];
        let delFlag = true;
        let showFlag = true;

        switch (modalType) {
            case "insert":
                detail_json.push({});
                break;
            case "autoCallListManagement":
                detail_json.push({});
                let headerItemsTmp = this.state.header_json;
                headerItemsTmp.forEach((header, id) => {
                    headerItems.push({ id: id, tag: null, value: header.header_name, disabled: header.disabled });
                });
                break;
            case "delete":
                try {
                    //TODO: 未リリースのため、コメントアウト。スケジュール機能を追加時にアンコメントする（#1190）
                    //ローディング画面を表示
                    // if (loadingFlag) this.blockUI();
                    // const arrayStatus = await this.getStatusUsingAutoCallList(this.state.cm76_id);
                    // arrayStatus.some((status) => {
                    //     if (status.status !== 'finished') {
                    //         delFlag = false;
                    //         return true;
                    //     }
                    // });
                    if (param && param.column_json) {
                        //保存せずに閉じると、値が初期化されないため、新規オブジェクトを作成
                        detail_json.push(Object.assign({}, param.column_json[0]));
                    } else {
                        detail_json.push({});
                    }
                } catch (err) {
                    this.showErrorObjectMesssage(err, "GetDataError");
                    showFlag = false;
                    this.unblockUI();
                }
                break;
            case "update":
                if (param && param.column_json) {
                    //保存せずに閉じると、値が初期化されないため、新規オブジェクトを作成
                    detail_json.push(Object.assign({}, param.column_json[0]));
                } else {
                    detail_json.push({});
                }
                break;
            default:
                break;
        }
        let setData = {
            autocalllist_id: this.state.cm76_id,
            autocalldetaillist_id: param && param.id
                ? param.id
                : "",
            tel_number: param && param.tel_number
                ? param.tel_number
                : "",
            detail_json: detail_json,
            headerItems: headerItems,
            list_name: this.state.breadcrumb_display_name,//DBのデータを保持しているため
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : "",
            autoCallDelFlag: delFlag,
            loading: false,
            duplicateFlag: false,
            duplicateTelFlag: false,
            blankFlag: false,
            autoCallCsvFile: false,
            modalType,
            show: showFlag
        };
        this.setState(setData);
    };

    /**
     * 各画面先でのボタン押下時の動作内容
     */
    onClickHandle = modalType => event => {
        try{
            this.setState({ loadingDisplayFlag: true });
            let updateHeaderItems = [];
            switch (modalType) {
                case "insert":
                    this.blockUI();
                    this.ascAxios("post", `/AutoCallList/detailInsert`, {
                        cm76_id: this.state.autocalllist_id,
                        tel_number: this.state.tel_number,
                        //全項目を空白で追加した場合はnullで登録する
                        column_json: Object.keys(this.state.detail_json[0]).length > 0 ? this.state.detail_json : null
                    })
                        .then(result => {
                            this.reactTableRefresh();
                            this.setState({ show: false, loadingDisplayFlag: false });
                            alert(this.props.langText.Message.DataInsertSuccess);
                        })
                        .catch(err => {
                            this.setState({loadingDisplayFlag: false });
                            this.showErrorObjectMesssage(err, "DataInsertError");
                        });
                    break;
                case "delete":
                    this.blockUI();
                    //削除処理実行
                    this.ascAxios("post", `/AutoCallList/detailDelete`, {
                        cm76_id: this.state.autocalllist_id,
                        cm77_id: this.state.autocalldetaillist_id,
                    })
                        .then(result => {
                            this.reactTableRefresh();
                            this.setState({ show: false, loadingDisplayFlag: false });
                            alert(this.props.langText.Message.DataDeleteSuccess);
                        })
                        .catch(err => {
                            this.setState({loadingDisplayFlag: false });
                            this.showErrorObjectMesssage(err, "DataDeleteError");
                        });
                    break;
                case "update":
                    this.blockUI();
                    this.ascAxios("post", `/AutoCallList/detailUpdate`, {
                        cm76_id: this.state.autocalllist_id,
                        cm77_id: this.state.autocalldetaillist_id,
                        tel_number: this.state.tel_number,
                        //項目キーがない場合（後から項目を追加）に、空白での更新を考慮
                        column_json: Object.keys(this.state.detail_json[0]).length > 0 ? this.state.detail_json : null
                    })
                        .then(result => {
                            this.reactTableRefresh();
                            this.setState({ show: false, loadingDisplayFlag: false });
                            alert(this.props.langText.Message.DataUpdateSuccess);
                        })
                        .catch(err => {
                            this.setState({loadingDisplayFlag: false });
                            this.showErrorObjectMesssage(err, "DataUpdateError");
                        });
                    break;
                case "autoCallListManagement":
                    this.blockUI();
                    let itemCount = 1;
                    this.state.headerItems.forEach(header => {
                        if (header.value !== '') {
                            //未入力の項目は登録しない
                            updateHeaderItems.push({ column_name: 'customize' + itemCount, header_name: header.value.trim(), disabled: header.disabled });
                            itemCount++;
                        }
                    });
                    this.ascAxios("post", `/AutoCallList/listUpdate`, {
                        autocalllist_id: this.state.autocalllist_id,
                        company_id: this.state.company_id,
                        list_name: this.state.list_name,
                        json_data: updateHeaderItems,
                    })
                        .then(result => {
                            alert(this.props.langText.Message.DataUpdateSuccess);
                            //表を再描画する必要があるため、最新のヘッダーを取得後にリフレッシュ
                            this.getAutoCallData(this.state.cm76_id)
                                .then(autoCallData => {
                                    let header_json = autoCallData.json_data
                                        ? autoCallData.json_data
                                        : [];
                                    this.setState({
                                        columns: this.getColumnsData(header_json),
                                        header_json: autoCallData.json_data,
                                        list_name: autoCallData.list_name,
                                        breadcrumb_display_name: autoCallData.list_name,
                                    });
                                    this.reactTableRefresh();
                                    this.setState({ show: false, loadingDisplayFlag: false });
                                })
                                .catch(err => {
                                    throw new Error(err);
                                });
                        })
                        .catch(err => {
                            this.setState({loadingDisplayFlag: false });
                            this.showErrorObjectMesssage(err, "DataUpdateError");
                        });
                    break;
                case "csvInsert":
                    this.blockUI();
                    this.getCsvData(this.state.autoCallCsvFile)
                    .then(res => {
                        let column_json = res.insertArray;
                        let errData = res.errorData;
                        let errMessage = [];

                        if (errData && errData.length > 0) {
                            errMessage.push(this.getErrorString({code: errData[0]}));
                            //エラーがある場合のメッセージ作成処理
                            switch(errData[0]){
                                case "NotExistTelNo":
                                    //電話番号カラムなし
                                    break;
                                case "CsvDuplicateItemName":
                                    //カラム名の重複
                                    errData[1].forEach(duplicateItem => {
                                        errMessage.push(duplicateItem);
                                    });
                                    break;
                                case "CsvNotAvailable":
                                    //電話番号のフォーマットエラー
                                    errMessage.push(this.sprintf(this.props.langText.Message.FailedDataCount, errData[1].length));
                                    errData[1].forEach(err => {
                                        errMessage.push(
                                            this.sprintf(this.props.langText.Message.Line, err.row) +
                                                err.tel_no +
                                                "　" +
                                                this.getErrorString({code: err.errCode})
                                        );
                                    });
                                    break;
                                default:
                                    throw new Error("ProcessingFailedError");
                            }
                            this.setState({
                                errorMessageModalShow: true,
                                errText: errMessage.join("\r\n"),
                                loadingDisplayFlag: false,
                            });
                            this.reactTableRefresh();
                        } else {
                            //insert処理
                            this.ascAxios("post", `/AutoCallList/csvInsert`, {
                                autocalllist_id: this.state.autocalllist_id,
                                company_id: this.state.cm12_id,
                                json_data: column_json,
                            }).then(result => {
                                if (result.data.successCount > 0) {
                                    //全件成功した場合のみ成功メッセージを表示
                                    alert(
                                        this.props.langText.Message.DataUpdateSuccess +
                                            "(" +
                                            this.sprintf(
                                                this.props.langText.Message.SuccessDataCount,
                                                result.data.successCount
                                            ) +
                                            ")"
                                    );
                                    this.getAutoCallData(this.state.cm76_id)
                                        .then(autoCallData => {
                                            let header_json = autoCallData.json_data
                                                ? autoCallData.json_data
                                                : [];
                                            this.setState({
                                                columns: this.getColumnsData(header_json),
                                                header_json: autoCallData.json_data,
                                                list_name: autoCallData.list_name,
                                                breadcrumb_display_name: autoCallData.list_name,
                                            });
                                        })
                                        .catch(err => {
                                            //cm76の取得に失敗した場合は自動発信リスト一覧画面へ戻る
                                            alert(this.props.langText.Message.GetDataError);
                                            this.props.historyPush({ pathname: "/AutoCallList" });
                                        });
                                } else {
                                    //サーバー側でのエラー検知
                                    errMessage.push(this.getErrorString({ code: result.data.errorCode }));
                                    this.setState({
                                        errorMessageModalShow: true,
                                        errText: errMessage.join("\r\n"),
                                    });
                                }
                            }).catch(err => {
                                this.showErrorObjectMesssage(err, "FailedImportCsv");
                            })
                            .finally(() => {
                                this.reactTableRefresh();
                                this.setState({ show: false, loadingDisplayFlag: false });
                            });
                        }
                    }).catch(err => {
                            this.setState({loadingDisplayFlag: false });
                            alert(this.props.langText.Message.FailedImportCsv);
                    });
                    break;
                default:
                    break;
            }
        }catch (err) {
            this.showErrorObjectMesssage(err, "ProcessingFailedError");
        }
    };


/**
 * ボタンの活性状態を決定する
 * @param {string} param
 * @return {boolean} true:活性/false:非活性
 */
    validationHandle = param => {
        let validation_flag = false;
        switch (param) {
            case "insert":
            case "update":
                validation_flag = !this.state.duplicateTelFlag && this.validateTelNumber(this.state.tel_number);
                break;
            case "autoCallListManagement":
                    validation_flag = !this.state.duplicateFlag && this.state.list_name && !this.state.blankFlag;
                break;
            case "csvInsert":
                validation_flag = !!this.state.autoCallCsvFile;
                break;
            case "delete":
                validation_flag = this.state.autoCallDelFlag;
                break;
            case "headerItems":
                validation_flag = true;
                break;
            default:
                break;
        }
        return validation_flag;
    };

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "delete":
                return (
                    <AutoCallDetailListDeleteModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        validationHandle={this.validationHandle}
                    />
                )
            case "insert":
                return (
                    <AutoCallDetailListInsertModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick={this.onClickHandle}
                        onTextChange_Limit={this.onTextChange_Limit}
                        onChangeValue={this.onChangeValue}
                        validationHandle={this.validationHandle}
                        onFocusOut={this.onFocusOut}
                    />
                )
            case "update":
                return (
                    <AutoCallDetailListUpdateModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick={this.onClickHandle}
                        onTextChange_Limit={this.onTextChange_Limit}
                        onChangeValue={this.onChangeValue}
                        validationHandle={this.validationHandle}
                        onFocusOut={this.onFocusOut}
                    />
                )
            case "autoCallListManagement":
                return (
                    <AutoCallListManagementModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick={this.onClickHandle}
                        onMultiValueFormTextChange={this.onMultiValueFormTextChange}
                        onMultiValueFormSizeChange={this.onMultiValueFormSizeChange}
                        onTextChange_Limit={this.onTextChange_Limit}
                        validationHandle={this.validationHandle}
                    />
                )
            case "csvInsert":
                return (
                    <AutoCallDetailListCsvInsertModal
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        onClick={this.onClickHandle}
                        onFileChange={this.onFileChange}
                        handleOnDrop={this.handleOnDrop}
                        handleOnError={this.handleOnError}
                        handleOnRemoveFile={this.handleOnRemoveFile}
                        validationHandle={this.validationHandle}
                    />
                )
            default:
                break
        }
    }

    /**
     * フォーカスアウト時のイベント処理
     */
    onFocusOut = (event) => {
        try{
            let value = event && event.target && event.target.value ? event.target.value : "";
            let duplicateTelFlag = true;
            let telCount = 0;
            this.ascAxios("post", `/AutoCallList/callGetDuplicatTelnoCount`, {
                cm76_id: this.state.cm76_id,
                cm77_id: this.state.autocalldetaillist_id,
                tel_number: value,
            })
                .then(result => {
                    telCount = result.data;
                    if (telCount === 0) {
                        duplicateTelFlag = false;
                    }
                    this.setState({ duplicateTelFlag: duplicateTelFlag });
                })
                .catch(err => {
                    alert(
                        this.sprintf(
                            this.props.langText.Message.ExecutionFailedError_Placeholder,
                            this.props.langText.Body.DuplicateTelno
                        )
                    );
                });
        }catch (err) {
            alert(this.props.langText.Message.ProcessingFailedError);
        }
    }

    /**
     * リスト管理の入力処理
     * @param {string} param state名
     * @param {integer} index 変更イベント駆動箇所
     * @param {string} content 入力内容
     */
    onMultiValueFormTextChange = async (param, index, content) => {
        const ArrayTemp = this.state[param];
        ArrayTemp[index].value = content;

        //重複チェック
        const itemArray = this.state[param].map((element) => element.value.trim());
        itemArray[index] = content.trim();
        let duplicateItem = this.pickUpDuplicate(itemArray);
        //空白の重複は無視する
        duplicateItem = duplicateItem.filter(item => {
            return item !== '';
        });

        //空白チェック
        //既存項目は空白不可。※新規追加項目が空白の場合は登録しない
        let blankFlag = false;
        ArrayTemp.some(data => {
            if (data.value.trim() === '' && data.id != null) {
                blankFlag = true;
                return true;
            }
        });

        // 入力内容を更新
        const duplicateFlag = duplicateItem.length > 0;
        this.setState({
            [param]: ArrayTemp,
            duplicateFlag: duplicateFlag,
            duplicateItem: duplicateItem,
            blankFlag: blankFlag
        });
    };

    /**
     * リスト管理の項目増減処理
     * @param {*} param state名（可変フォームを保持している値）
     * @param {*} handle イベント（追加：plus／有効無効：toggle）を管理
     * @param {*} index 追加：null／有効無効：イベントポジション
     */
    onMultiValueFormSizeChange = (param, handle, index) => {
        let ArryTemp = this.state[param]

        if (handle === 'plus') {
            ArryTemp.push({ id: null, tag: '', value: '' });
        } else if (handle === 'toggle') {
            ArryTemp[index].disabled = ArryTemp[index].disabled === "Y" ? "N" : "Y";
        }

        this.setState({ [param]: ArryTemp })
    }

    /**
     * 詳細データの値を更新
     * @param {*} event
     * @param {string} stateName state名
     * @param {string} stateKey 要素名
     */
    onChangeValue = (event, stateName, stateKey) => {
        let itemJson = {};
        itemJson = this.state[stateName];
        let value =
            event && event.target && event.target.value
                ? event.target.value
                : "";
        itemJson[0][stateKey] = value;
        this.setState({ [stateName]: itemJson });
    };

    /**
     * エラーモーダル（CSV投入処理）のクローズ処理
     */
    errorModalClose = () => {
        this.setState({ errorMessageModalShow: false });
    }

    /**
     * 発信リストのステータスを取得
     * @param {string} cm76_id
     * @returns {promise <string>} status
     */
    getStatusUsingAutoCallList = async (cm76_id) => {
        try{
            let status = await this.ascAxios("post", `/AutoCallList/getStatus`, {
                cm76_id,
            });
            return status.data.length > 0 ? status.data : [];
        } catch (err) {
            throw new Error(err);
        }
    };

    /**
     * CSVファイルをアップロードまたはドラッグした際の読み込み処理
     * @param {} data
     */
    handleOnDrop = (data, file) => {
        let jsonData = data;
        let alert_size = this.props.langText.Message.CsvSizeLimitAutoCallList;
        let alert_message = '';
        alert_message = this.sprintf(this.props.langText.Message.Upload_sizeLimit,alert_size);

        //ファイル名チェック
        if (!file.name.match(/.csv$/i)) {
            alert(this.props.langText.Message.NotCsvFile);
            return false;
        }

        if (file.size >= GlobalConst.CSV_IMPORT_SIZE_LIMIT.autoCallList) {
            alert(alert_message);
            this.setState({ autoCallCsvFile: false });
        } else {
            this.setState({ autoCallCsvFile: jsonData });
        }
    };

    /**
     * CSVファイルのエラー処理
     */
    handleOnError = () => {
        this.setState({autoCallCsvFile: false});
    };

    /**
     * アップロード済みのCSVファイルの削除処理
     */
    handleOnRemoveFile = () => {
        this.setState({autoCallCsvFile: false});
    };

    /**
     * CSVデータをjsonカラムへ変換
     * @param {file} importFile 
     * @return {array} 
     *  insertArray [0]:cm76_autocall_list.json_data, [1]:cm77_autocall_list_details.column_json
     *  errorData [0]:エラーコード, [1]:エラー内容
     */
    async getCsvData(importFile) {
        try {
            let headers = this.state.header_json;
            let insertDetail = [];
            let newHeader = [];
            let insertHeader = [];
            let detailKey = [];
            let customizeMax = this.state.header_json.length;
            let telPos = "";
            let insertArray = [];
            let errorData = [];
            let errorValidateTelno = [];

            const csvHeaders = importFile[0].data.map(header => header.trim());
            const list = importFile.filter((row, index) => {
                if (index != 0) return row.data;
            });

            //電話番号カラムが含まれているかチェック
            if (csvHeaders.indexOf(this.props.langText.Body.Telno) == -1) {
                errorData.push("NotExistTelNo");
                return { errorData: errorData };
            }

            //重複カラムのチェック
            const Array = this.pickUpDuplicate(csvHeaders);
            if (Array.length > 0) {
                errorData.push("CsvDuplicateItemName");
                errorData.push(Array);
                return { errorData: errorData };
            }

            //headerの作成
            csvHeaders.forEach((csvHeader, pos) => {
                if (csvHeader === this.props.langText.Body.Telno) {
                    telPos = pos; //電話番号のポジション特定
                    return; //電話番号はスキップする
                }

                let headerUsedFlag = false;
                headers.forEach(header => {
                    if (csvHeader === header.header_name) {
                        headerUsedFlag = true; //登録済みヘッダーの選別
                        detailKey.push(header.column_name);
                    }
                });

                //登録のないヘッダーは新規追加する
                if (!headerUsedFlag) {
                    customizeMax++;
                    detailKey.push("customize" + customizeMax);
                    newHeader.push({ column_name: "customize" + customizeMax, header_name: csvHeader });
                }
            });
            insertHeader = headers.concat(newHeader); //既存ヘッダーと新規追加ヘッダーの結合

            //電話番号の重複チェック用データ取得・作成
            const autoCallTelnoList = await this.ascAxios("post", `/AutoCallList/callGetAutoCallTelnoList`, {
                cm76_id: this.state.cm76_id
            });
            const checkTelnoList = autoCallTelnoList.data.map(telno => telno.tel_number);
            const csvTelnoList = [];

            //detailの作成
            list.forEach((detailRow, index) => {
                let i = 0;
                let detail = {};
                let tmpDetail = detailRow.data;
                //改行のみはスキップする
                if (tmpDetail.length == 1 && tmpDetail[0] == "") return;
                tmpDetail.forEach((item, key) => {
                    if (telPos == key) {
                        //電話番号は整形する
                        detail.tel_number = item.replace(/-|ー/g, "");
                        csvTelnoList.push(detail.tel_number);
                        //電話番号の形式チェック
                        if (!this.validateTelNumber(detail.tel_number)){
                            errorValidateTelno.push({ row: index + 2, tel_no: detail.tel_number, errCode: "TelNoFormatErr"});
                        }
                        //既存の電話番号重複チェック
                        if (checkTelnoList.indexOf(detail.tel_number) >= 0){
                            errorValidateTelno.push({ row: index + 2, tel_no: detail.tel_number, errCode: "CsvDuplicateTelno" });
                        }
                    } else {
                        //csv1行ずつオブジェクト形式で作成
                        detail[detailKey[i]] = item;
                        i++;
                    }
                });
                insertDetail.push([detail]);
            });

            csvTelnoList.forEach((telno, index) => {
                //リスト内の電話番号重複チェック
                if (csvTelnoList.indexOf(telno) !== csvTelnoList.lastIndexOf(telno))
                    errorValidateTelno.push({ row: index + 2, tel_no: telno, errCode: "CsvDuplicateTelnoInlist"});
            });



            if(errorValidateTelno.length > 0){
                errorData[0] = "CsvNotAvailable";
                errorData[1] = errorValidateTelno;
            }
            insertArray.push(insertHeader);
            insertArray.push(insertDetail);
            return { insertArray: insertArray, errorData: errorData };
        } catch (err) {
            throw new Error(err);
        }
    }

    render() {
        return (
            <div className='AutoCallDetailList'>
                <SetBreadCrumb
                    displayItems={[
                        {link: '/AutoCallList', name: this.props.langText.Body.AutoCallList},
                        {name:this.state.breadcrumb_display_name}
                    ]}
                />
                <Row>
                    {
                        this.props.currentPermission.edit
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="autoCallList-insert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={(event) => this.modalShow(event, 'insert')}
                            >
                                {this.props.langText.Body.InsertTelNo}
                            </Button>
                            <Button
                                id="autoCallDetailList-csvInsert"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={(event) => this.modalShow(event, 'csvInsert')}
                            >
                                {this.props.langText.Body.CsvImport}
                            </Button>
                            <Button
                                id="autoCallDetailList-itemManagement"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={(event) => this.modalShow(event, 'autoCallListManagement')}
                            >
                                {this.props.langText.Body.AutoCallListManagement}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight - 30 }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{ id: "id", desc: true }]}
                            searchData={this.props.location.state === undefined ? null : this.props.location.state.displayData.id} //直リンク対策
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                    {
                        this.state.errorMessageModalShow
                        &&
                        <ErrorMessageModal
                            errorModalClose={this.errorModalClose}
                            state={this.state}
                            langText={this.props.langText}
                            errText={this.state.errText}
                        />
                    }
                </Row>
                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        )
    }
}
