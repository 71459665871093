import React, { Component } from "react";
import { Panel } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import { ViewAutoCallDetailList } from "../Elements/AscElements/AutoCallListElements";

/**
 * 自動発信リスト詳細削除画面
 */
export default class AutoCallDetailListDeleteModal extends Component {
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            <div className="text-danger modal-checked-message">
                                {!this.props.state.autoCallDelFlag ? this.props.langText.Message.AutoCallListInUse : false}
                            </div>
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        <ViewAutoCallDetailList
                            state={this.props.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}>
                        </ViewAutoCallDetailList>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
