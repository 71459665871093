import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import Component from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import GatewayModal from "./GatewayModal";

export default class Gateway extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            gatewayTypeSelect: this.getSelectOption('gateway_type', this.props.langText.SelectOption),

            // data item
            getewayId: "",
            gatewayName: "",
            gatewayType: "",
            ipaddress: "",
            port: "",
            memo: "",
            backNumberDial: false
        };
    }

    getColumnsData() {
        let gatewayType = this.getSelectOption('gateway_type', this.props.langText.SelectOption);

        return [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                filterable: false,
                width: this.props.boardWidth.id,
            }, {
                Header: this.props.langText.Body.GatewayType,
                accessor: "type",
                Cell: ({value}) => {
                    let findObj = gatewayType.find(row => row.value === value);
                    return findObj ? findObj.label : "";
                },
                Filter: () => <FormControl onChange={(e) => { 
                    let findObj = gatewayType.find(row => row.label === e.target.value);
                    if (findObj != undefined) {
                        this.onFilterChange(findObj.value, "type");
                    } else {
                        this.onFilterChange(e.target.value, "type");
                    }
                }} />
            }, {
                Header: this.props.langText.Body.GatewayName,
                accessor: "gw_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "gw_name")} />
            }, {
                Header: this.props.langText.Body.GatewayIpaddress,
                accessor: "ipaddress",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "ipaddress")} />
            }, {
                Header: this.props.langText.Body.GatewayPort,
                accessor: "port",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "port")} />
            }, {
                Header: this.props.langText.Body.Memo,
                accessor: "memo",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
            }, {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.Control,
                Cell: data => {
                    let rowData = [];

                        if (this.props.currentPermission.edit) {
                            rowData.push(
                                <Button
                                    key={0}
                                    bsSize="xsmall"
                                    onClick={this.modalShow("update", data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="pencil" />
                                </Button>
                            );
                        } else {
                            rowData.push(
                                <Button
                                    key={1}
                                    bsSize="xsmall"
                                    onClick={this.modalShow("read", data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="eye-open" />
                                </Button>
                            );
                        }

                        if (this.props.currentPermission.delete) {
                            rowData.push(
                                <Button
                                    key={2}
                                    bsSize="xsmall"
                                    onClick={this.modalShow("delete", data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="minus" />
                                </Button>
                            );
                        }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            }
        ]
    }

    modalShow = (modalType, param) => event => {
        event.preventDefault();

        let setData = {
            getewayId: param && param.id
                ? param.id
                : "",
            gatewayName: param && param.gw_name
                ? param.gw_name
                : "",
            gatewayType: param && param.type
                ? param.type
                : "",
            ipaddress: param && param.ipaddress
                ? param.ipaddress
                : "",
            port: param && param.port
                ? param.port
                : "",
            backNumberDial: param && param.back_number_dial_flag && param.back_number_dial_flag === "Y"
                ? true
                : false,
            memo: param && param.memo
                ? param.memo
                : "",
            modalType,
            show: true
        };

        this.setState(setData);
    };

    onClickHandle = modalType => event => {
        let {
            getewayId,
            gatewayName,
            gatewayType,
            ipaddress,
            port,
            backNumberDial,
            memo
        } = this.state;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                    getewayId,
                    gatewayName,
                    gatewayType,
                    ipaddress,
                    port,
                    backNumberDial,
                    memo,
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "update":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/update`, {
                    getewayId,
                    gatewayName,
                    gatewayType,
                    ipaddress,
                    port,
                    backNumberDial,
                    memo,
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "delete":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    getewayId
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            default:
                alert(this.getErrorString({ code: "modal"}))
        }

        this.setState({
            show: false
        });
    };

    validationHandle = param => {
        let nowState = null;

        switch (param) {
            case "gatewayName":
            case "gatewayType":
            //case "gatewayRegisterId":
            //case "gatewayRegisterPw":
            case "ipaddress":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;

            case "insert":
            case "update":
            case "delete":
                let {gatewayName, gatewayType, ipaddress} = this.state;

                nowState = (
                    gatewayName && gatewayType && ipaddress
                );
                break;

            default:
        }

        return nowState;
    };

    render() {
        return (
            <div className="Gateway">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="gateway-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <GatewayModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    validationHandle = {this.validationHandle}
                    onIntChange = {this.onIntChange}
                    onCheckBoxChange = {this.onCheckBoxChange}/>
            </div>
        );
    }
}
