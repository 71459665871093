import React from "react";
import { Button, Row, Col } from 'react-bootstrap'
import ColumnFixTable from '../../containers/Elements/Table/ColumnFixTable'
import AscComponent from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import { SelectCompanyElement } from "../Elements/AscElements/CompanyElements";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import { MultiSelect } from "react-multi-select-component";
import { CSVLink } from "react-csv";
import * as GlobalConst from '../../components/AscConstants';

export default class CallCountSummary extends AscComponent {
    constructor(props) {
        super(props);
        const now = moment();
        const startDate = moment(now).startOf("months");
        const endDate =  moment(now).endOf("months");
        const dateColumns = this.getDisplayColumns(startDate, endDate);
        
        this.state = {
            data: [],
            csvData: [],
            filtered: [
                { id: "cm12_id", value: this.props.userInfo.company_id || 0 },
                { id: "summary_date", value: [startDate, endDate] },
                { id: "hourly", value: this.props.langText.SelectOption.SummaryTimeOption.map(hour => hour.value) },
                { id: "inout_type", value: this.props.langText.SelectOption.InoutTypeOption.map(inout_type => inout_type.value) },
                { id: "disposition", value: this.props.langText.SelectOption.CallResultOption.map(department => department.value) },
                { id: "join_word", value: ["department_id", "hourly", "inout_type", "disposition"] },
                { id: "hourly_flg", value: true },
                { id: "inout_type_flg", value: true },
                { id: "disposition_flg", value: true },
                { id: "columns", value: dateColumns },
                { id: "summary_columns", value: ["hourly", "inout_type", "disposition"] }
            ],
            dateColumns: dateColumns,
            summaryColumns: [],
            pages: null,
            show: false,
            loading: false,
            startDate: startDate,
            endDate: endDate,
            validationMessage: null,

            company_id: this.props.userInfo.company_id || 0,
            company_name: this.getScopeGreaterEqual(
                GlobalConst.SCOPE_OBJECT.oem
            )
                ? this.props.userInfo.company_name
                : "",
            department_id: [],
            floor_id: [],
            floor_flg: this.props.userInfo.floor_flg,
            floor_column_flg: this.props.userInfo.floor_flg,

            companySelect: [],
            departmentSelect: [],
            floorSelect: [],
            hourlySelect: this.props.langText.SelectOption.SummaryTimeOption,
            inoutTypeSelect: this.props.langText.SelectOption.InoutTypeOption,
            dispositionSelect: this.props.langText.SelectOption.CallResultOption,
            unsetOption: this.props.langText.SelectOption.UnsetOption,
            hourly_flg: true,
            inout_type_flg: true,
            disposition_flg: true,

            hourlySelectOption: this.props.langText.SelectOption.SummaryTimeOption,
            inoutTypeSelectOption: this.props.langText.SelectOption.InoutTypeOption,
            dispositionSelectOption: this.props.langText.SelectOption.CallResultOption,
        };
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
            this.setCommonCompanySelect("/Summary");
            this.setCommonDepartmentSelect(this.props.userInfo.company_id, this.props.userInfo.floor_flg);
        }
        this.csvLink = React.createRef();
    }

    getDisplayColumns = (start, end) => {
        const startDate = moment(start).add(-1, "days");
        const endDate = moment(end);
        const dateCount = this.calcDateDays(startDate, endDate);
        const columns = [];

        for (let i = 0; i < dateCount; i++) {
            columns.push("day_" + startDate.add(1, "days").format("DD"));
        }
        return columns;
    }

    setCommonCompanySelect = async (reactContainerPath) => {
        try {
            await super.setCommonCompanySelect(reactContainerPath);
        } catch (err) {
            alert(this.getErrorString(err));
        }
    }

    setCommonDepartmentSelect = async (company_id, floor_flg) => {
        try {
            const department_list = await super.setCommonDepartmentSelect({
                container: "/Summary",
                company_id: company_id, 
                floor_flg: floor_flg == true || floor_flg == "Y" ? true : false
            });
            this.setCommonFloorSelect(department_list);
        } catch (err) {
            alert(this.getErrorString(err));
        }
    }

    setCommonFloorSelect = async (departmentIds) => {
        try {
            await super.setCommonFloorSelect({
                container: "/Summary",
                departmentIds
            });
        } catch (err) {
            alert(this.getErrorString(err));
        }
    };

    getColumnsData() {
        let {
            hourly_flg,
            inout_type_flg,
            disposition_flg,
            floor_column_flg,
            dateColumns
        } = this.state

        let columns = []; 
        const date = moment(this.state.startDate).add(-1, "days");

        columns.push({
            Header: this.props.langText.Body.Department,
            accessor: "department_name",
            filterable: false,
            width: this.props.boardWidth.xxlarge,
            sortable: false,
            fixed: "left",
        });

        if (floor_column_flg) {
            columns.push({
                Header: this.props.langText.Body.FloorName,
                accessor: "floor_name",
                filterable: false,
                sortable: false,
                fixed: "left",
            });
        }

        if(hourly_flg){
            columns.push({
                Header: this.props.langText.Body.SummaryTimeZone,
                accessor: "hourly",
                filterable: false,
                sortable: false,
                fixed: "left",
            });
        }

        if(inout_type_flg){
            columns.push({
                Header: this.props.langText.Body.InOutType,
                accessor: "inout_type",
                filterable: false,
                width: this.props.boardWidth.smedium,
                sortable: false,
                fixed: "left",
            });
        }

        if(disposition_flg){
            columns.push({
                Header: this.props.langText.Body.Result,
                accessor: "disposition",
                filterable: false,
                sortable: false,
                fixed: "left",
            });
        }

        dateColumns.forEach((data) => {
            columns.push(
                {
                    Header: date.add(1, "days").format("MM/DD"),
                    accessor: data,
                    filterable: false,
                    sortable: false,
                    className: "text-align",
                    Cell: data => {
                        let rowData = data.value ? data.value : 0;
                        return (<div>{parseInt(rowData).toLocaleString()}</div>)
                    }
                }
            );
        });

        columns.push({
            Header: this.props.langText.Body.SummaryTotal,
            accessor: "sum",
            filterable: false,
            sortable: false,
            fixed: "right",
            className: "text-align",
            Cell: data => {
                let rowData = data.value ? data.value : 0;
                return (<div>{parseInt(rowData).toLocaleString()}</div>)
            }
        });

        return columns;
    }

    onSelectChange = stateKey => event => {
        super.onSelectChange(event, stateKey);
        if (stateKey === "company_id") {
            const message = event.value
                ? null
                : this.props.langText.Message.CompanySelectError;
            this.setState({
                company_name: event.label,
                department_id: [],
                floor_id: [],
                floor_flg : event.floor_flg == "Y" ? true : false,
                validationMessage: message || this.state.validationMessage,
            });
        }
        this.setCommonDepartmentSelect(event.value, event.floor_flg);
    }

    onMultiCheckChange = stateKey => event => {
        let {
            hourlySelectOption,
            inoutTypeSelectOption,
            dispositionSelectOption,
        } = this.state

        switch (stateKey) {
            case "hourlySelect":
                event = this.noSummaryCheck(event, hourlySelectOption);
                break;
            case "inoutTypeSelect":
                event = this.noSummaryCheck(event, inoutTypeSelectOption);
                break;
            case "dispositionSelect":
                event = this.noSummaryCheck(event, dispositionSelectOption);
                break;
            case "department_id":
                this.setCommonFloorSelect(event.map(row => {return row.value}));
                break;
            default:
                break;
        }

        this.setState({[stateKey]: event})
    }

    noSummaryCheck(event_arr, option) {
        let no_summary_row = event_arr.find(row => row.value == "Unset");
        let no_summary_row_disabled_true = event_arr.find(row => row.value == "Unset" && row.disabled == true);
        let no_summary_row_disabled_false = event_arr.find(row => row.value == "Unset" && row.disabled == false);

        if (event_arr.length == option.length) { //全選択の場合「指定しない」を選択できないようにする
            no_summary_row.disabled = true;
        } else if (event_arr.length == 0) { //「指定しない」選択→解除の場合disabledされた選択肢を初期化
            option.forEach(row => {
                row.disabled = false;
            });
        } else if (event_arr.length == 1) { 
            if (no_summary_row_disabled_true) { //全選択解除の場合disabledされた「指定しない」と選択肢を初期化
                no_summary_row.disabled = false;
                event_arr = [];
            } else if (no_summary_row_disabled_false) { //未選択の状態で「指定しない」だけ選択して場合他の選択肢をdisabled処理
                option.forEach(row => { 
                    if(row.value == "Unset"){
                        row.disabled = false;
                    } else {
                        row.disabled = true;
                    }
                });
            } 
        } else if (event_arr.length > 1) { 
            if (no_summary_row_disabled_true) { //全選択→「指定しない」ではない任意の選択肢解除の場合、disabledされていた「指定しない」の初期化
                no_summary_row.disabled = false;
                event_arr = event_arr.filter(row => row.value !== "Unset");
            } else if (no_summary_row_disabled_false) { //既存に値が選択されている状態で「指定しない」を選択した場合、「指定しない」以外の選択肢を選択解除+disabled処理
                event_arr = [this.state.unsetOption];
                option.forEach(row => {
                    if(row.value == "Unset"){
                        row.disabled = false;
                    } else {
                        row.disabled = true;
                    }
                });
            }
        }   

        return event_arr;
    }

    onChangeDate = stateKey => event => {
        if (event == null) return false;
        let isOneMonth = false;
        let days = null;
        let message = null;

        switch (stateKey) {
            case "startDate":
                const startDay = moment(this.state.endDate).subtract(1, 'months').format('YYYY/MM/DD');
                if (event.isAfter(startDay)) isOneMonth = true;
                days = this.calcDateDays(event, this.state.endDate);
                this.setState({[stateKey]: event.startOf("days")});
                break;
            case "endDate":
                const endDay = moment(this.state.startDate).add(1, 'months').format('YYYY/MM/DD');
                if (event.isBefore(endDay)) isOneMonth = true;
                days = this.calcDateDays(this.state.startDate, event);
                this.setState({[stateKey]: event.endOf("days")});
                break;
            default:
                break;
        }

        if (!isOneMonth) {
            message = this.props.langText.Message.GreaterThanOneMonthError;
        } else if (days < 0) {
            message = this.props.langText.Message.DateSpanError;
        }

        this.setState({validationMessage: message});
    };

    onUnset(select_arr, option) {
        if(select_arr.length == 0) {
            select_arr.push(this.state.unsetOption);
            option.forEach(row => {
                if(row.value == "Unset"){
                    row.disabled = false;
                } else {
                    row.disabled = true;
                }
            });
        }
    }

    onSearch = async (isDownload = null) => {
        let {
            hourlySelect,
            inoutTypeSelect,
            dispositionSelect,
            departmentSelect,
            hourlySelectOption,
            inoutTypeSelectOption,
            dispositionSelectOption,
            department_id,
            floor_id,
        } = this.state

        let hourly_flg = hourlySelect.find(hourly => hourly.value == "Unset") 
                        && hourlySelect.find(hourly => hourly.value == "Unset").disabled == false 
                        || hourlySelect.length == 0
                        ? false : true;
        let inout_type_flg = inoutTypeSelect.find(inout_type => inout_type.value == "Unset") 
                        && inoutTypeSelect.find(inout_type => inout_type.value == "Unset").disabled == false 
                        || inoutTypeSelect.length == 0
                        ? false : true;
        let disposition_flg = dispositionSelect.find(disposition => disposition.value == "Unset") 
                        && dispositionSelect.find(disposition => disposition.value == "Unset").disabled == false 
                        || dispositionSelect.length == 0
                        ? false : true;
        let floor_column_flg = this.state.floor_id.length > 0 
                        || (this.state.floor_flg && this.state.floor_id.length == 0) 
                        ? true : false;

        //時間帯・発着信区分・通話結果が未選択の時、「指定しない」選択状態にする
        this.onUnset(hourlySelect, hourlySelectOption);
        this.onUnset(inoutTypeSelect, inoutTypeSelectOption);
        this.onUnset(dispositionSelect, dispositionSelectOption);

        //拠点・フロアが未選択の場合、「全選択」の設定に変更
        if(department_id.length == 0) {
            this.setState({
                department_id: departmentSelect
            });
            this.setCommonFloorSelect(departmentSelect.map(row => {return row.value}));
        } else if (this.state.floor_flg && floor_id.length == 0) {
            this.setCommonFloorSelect(departmentSelect.map(row => {return row.value}));
        }

        try {
            this.blockUI();
            const dateColumns = this.getDisplayColumns(this.state.startDate, this.state.endDate);
            
            let joinWord = [];
            const summary_columns = [];
            if (hourly_flg && inout_type_flg && disposition_flg) {
                //時間帯・発着信区分・通話結果を集計する
                joinWord = ["department_id", "hourly", "inout_type", "disposition"];
                summary_columns.push(
                    "hourly",
                    "inout_type",
                    "disposition",
                );
            } else if (!hourly_flg && !inout_type_flg && !disposition_flg) {
                //集計なし
                joinWord.push("department_id");
            } else {
                joinWord.push("department_id");
                if (hourly_flg) {
                    joinWord.push("hourly");
                    summary_columns.push("hourly");
                }
                if (inout_type_flg) {
                    joinWord.push("inout_type");
                    summary_columns.push("inout_type");
                }
                if (disposition_flg) {
                    joinWord.push("disposition");
                    summary_columns.push("disposition");
                }
            }

            if (this.state.floor_flg) joinWord.push("floor_id");

            const filter = [
                { 
                    id: "summary_date", 
                    value: [this.state.startDate, this.state.endDate] 
                },
                { 
                    id: "hourly",
                    value: this.state.hourlySelect.map(hour => hour.value),
                },
                { 
                    id: "inout_type",
                    value: this.state.inoutTypeSelect.map(inout_type => inout_type.value),
                },
                { 
                    id: "disposition",
                    value: this.state.dispositionSelect.map(department => department.value),
                },
                { 
                    id: "columns", 
                    value: dateColumns 
                },
                { 
                    id: "join_word", 
                    value: joinWord
                },
                { 
                    id: "hourly_flg", 
                    value: hourly_flg
                },
                { 
                    id: "inout_type_flg", 
                    value: inout_type_flg
                },
                { 
                    id: "disposition_flg", 
                    value: disposition_flg
                },
                { 
                    id: "summary_columns", 
                    value: summary_columns
                }
            ];

            if(this.state.company_id) {
                filter.push({ 
                    id: "cm12_id", 
                    value: this.state.company_id 
                });
            }

            if(this.state.department_id.length > 0) {
                filter.push({ 
                    id: "department_id",
                    value: this.state.department_id.map(department => department.value),
                });
            }

            if(this.state.floor_id.length > 0) {
                filter.push({ 
                    id: "floor_id",
                    value: this.state.floor_id.map(floor => floor.value),
                });
            }

            this.setState({
                filtered: filter,
            }, () => {
                this.table.current.state.page = 0;
            });

            if(isDownload) {
                const summaryData = await this.ascAxios("post", `${this.reactContainerPath}/CsvDL`, {
                    filtered: filter,
                });

                this.setState({
                    csvData : summaryData.data,
                    dateColumns: dateColumns,
                    summaryColumns : summary_columns,
                    hourly_flg,
                    inout_type_flg,
                    disposition_flg,
                    floor_column_flg
                });

                if (summaryData.data && summaryData.data.length > 0) {
                    this.csvLink.current.link.click();
                } else {
                    throw new Error("Download_Failed");
                }
            } else {
                this.setState({
                    dateColumns: dateColumns,
                    summaryColumns : summary_columns,
                    hourly_flg,
                    inout_type_flg,
                    disposition_flg,
                    floor_column_flg
                });
            }
        } catch(err) {
            console.error(err)
            this.showErrorObjectMesssage(err);
        } finally {
            this.unblockUI();
        }
    }

    createCsvHeader() {
        let headerArr = [];
        headerArr = this.getColumnsData().map(row => {return row.Header});
        return headerArr
    }

    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/Summary", name: this.props.langText.Body.Summary },
                        { name: this.props.langText.Body.CallCountSummary },
                    ]}
                />
                {this.state.validationMessage && (
                    <label className="search-message">
                        <p>{this.state.validationMessage}</p>
                    </label>
                )}
                <Row>
                    <Col xs={12} md={12} className="margin-top-05">
                        <Col sm={4} md={2} lg={1} className="padding-top-05">
                            {this.props.langText.Body.CompanyName}
                        </Col>
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col sm={12} md={4} lg={2}>
                                <SelectCompanyElement 
                                    colWidth={13}
                                    state={this.state}
                                    langText={this.props.langText}
                                    onSelectChange={this.onSelectChange("company_id")}
                                    labelDisabled={true}
                                ></SelectCompanyElement>
                            </Col>
                            : 
                            <Col xs={2} md={2} className="padding-left-30 padding-top-05">
                                {this.props.userInfo.company_name}
                            </Col>
                        }
                        <Col sm={4} md={2} lg={1} className="padding-top-05">
                            {this.props.langText.Body.DepartmentName}
                        </Col>
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) ? (
                            <Col sm={12} md={4} lg={2}>
                                <MultiSelect
                                    hasSelectAll={true}
                                    options={this.state.departmentSelect}
                                    value = {this.state.department_id}
                                    onChange={this.onMultiCheckChange("department_id")}
                                    overrideStrings={{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                        allItemsAreSelected: this.props.langText.Body.AllSelected,
                                        selectAll: this.props.langText.MultiSelect.AllSelect,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>
                        ) : (
                            <Col xs={2} md={2} className="padding-left-30 padding-top-05">
                                {this.props.userInfo.department_name ? this.props.userInfo.department_name : ""}
                            </Col>
                        )}
                        {((this.props.userInfo.floor_flg && this.state.floor_flg) || this.state.floor_flg) &&
                            <Col sm={4} md={2} lg={1} className="padding-top-05">
                                {this.props.langText.Body.FloorName}
                            </Col>
                        }
                        {(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) && this.state.floor_flg) &&
                            <Col sm={12} md={4} lg={2}>
                                <MultiSelect
                                    hasSelectAll={true}
                                    options={this.state.floorSelect}
                                    value = {this.state.floor_id}
                                    onChange={this.onMultiCheckChange("floor_id")}
                                    overrideStrings={{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                        allItemsAreSelected: this.props.langText.Body.AllSelected,
                                        selectAll: this.props.langText.MultiSelect.AllSelect,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>
                        }
                        {(!this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) && this.props.userInfo.floor_flg) &&
                            <Col xs={2} md={2} className="padding-left-30 padding-top-05">
                                {this.props.userInfo.floor_name ? this.props.userInfo.floor_name : ""}
                            </Col>
                        }
                    </Col>

                    <Col xs={12} md={12} className="margin-top-07">
                        <Col sm={4} md={2} lg={1} className="padding-top-05">
                            <div>{this.props.langText.Body.CustomerBoardStart}</div>
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                            <DatePicker
                                onChange={this.onChangeDate("startDate")}
                                selected={this.state.startDate}
                                dateFormat="YYYY-MM-DD"
                                className="form-control"
                                todayButton={this.props.langText.Body.Today}
                            />
                        </Col>
                        <Col sm={4} md={2} lg={1} className="padding-top-05">
                            <div>{this.props.langText.Body.CustomerBoardEnd}</div>
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                            <DatePicker
                                onChange={this.onChangeDate("endDate")}
                                selected={this.state.endDate}
                                dateFormat="YYYY-MM-DD"
                                className="form-control"
                                todayButton={this.props.langText.Body.Today}
                            />
                        </Col>

                        <Col sm={12} md={4} lg={4}>
                                <label className="margin-top-05 search-message">{this.state.message}</label>
                        </Col>
                    </Col>

                    <Col xs={12} md={12} className="margin-top-07">
                        <Col sm={4} md={2} lg={1} className="padding-top-05">
                            {this.props.langText.Body.SummaryTimeZone}
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                            <MultiSelect
                                hasSelectAll={true}
                                options={this.state.hourlySelectOption}
                                value={this.state.hourlySelect}
                                onChange={this.onMultiCheckChange("hourlySelect")}
                                overrideStrings={{
                                    selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                    allItemsAreSelected: this.props.langText.Body.AllSelected,
                                    selectAll: this.props.langText.MultiSelect.AllSelect,
                                    search: this.props.langText.Body.Search,
                                }}
                            />
                        </Col>
                        <Col sm={4} md={2} lg={1} className="padding-top-05">
                            {this.props.langText.Body.InOutType}
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                            <MultiSelect
                                hasSelectAll={true}
                                options={this.state.inoutTypeSelectOption}
                                value={this.state.inoutTypeSelect}
                                onChange={this.onMultiCheckChange("inoutTypeSelect")}
                                overrideStrings={{
                                    selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                    allItemsAreSelected: this.props.langText.Body.AllSelected,
                                    selectAll: this.props.langText.MultiSelect.AllSelect,
                                    search: this.props.langText.Body.Search,
                            }}
                            />
                        </Col>
                        <Col sm={4} md={2} lg={1} className="padding-top-05">
                            {this.props.langText.Body.CallHistory}
                        </Col>
                        <Col sm={12} md={4} lg={2}>
                            <MultiSelect
                                hasSelectAll={true}
                                options={this.state.dispositionSelectOption}
                                value={this.state.dispositionSelect}
                                onChange={this.onMultiCheckChange("dispositionSelect")}
                                overrideStrings={{
                                    selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                    allItemsAreSelected: this.props.langText.Body.AllSelected,
                                    selectAll: this.props.langText.MultiSelect.AllSelect,
                                    search: this.props.langText.Body.Search,
                                }}
                            />
                        </Col>
                        <Col  sm={1} md={1} lg={1}>
                            <Button
                                className="monitoring-summary-search"
                                bsStyle="primary"
                                bsSize="sm"
                                onClick={e=>this.onSearch()}
                                disabled = {!!this.state.validationMessage||this.state.loading}
                            >
                                {this.props.langText.Body.Search}
                            </Button>
                        </Col>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={12} className="margin-top-05">
                        <Button
                            id="callCountSummary-download"
                            className="table-button"
                            bsStyle="default"
                            bsSize="sm"
                            onClick={() => this.onSearch(true)}
                            disabled = {!!this.state.validationMessage||this.state.loading}
                        >
                            {this.props.langText.Body.SearchDownload}
                        </Button>
                        <CSVLink
                            headers={this.createCsvHeader()}
                            data={this.state.csvData}
                            filename={
                                this.state.company_name +
                                "_" +
                                "call_count_result_download" +
                                "_" +
                                this.getMomentTime({
                                    date: this.state.startDate,
                                    format: "YYYYMMDD",
                                }) +
                                "-" +
                                this.getMomentTime({
                                    date: this.state.endDate,
                                    format: "YYYYMMDD",
                                }) +
                                ".csv"
                            }
                            className="csv-download-link-format hidden"
                            ref={this.csvLink}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <ColumnFixTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.getColumnsData()}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                            showPagination={true}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}
