import React, {Component} from "react";
import {Button, Modal, Form, Row, Col, Panel, Glyphicon} from "react-bootstrap";
import ASFieldGroup from "../../Elements/FieldGroup/FieldGroup";

export default class ScriptTemplateSelectModal extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            description: "",
            timeout_seconds: this.props.timeout_seconds
        };
    }

    render() {
        let {
            show,
            langText,
            onClick,
            onHide,
        }=this.props

        return (
            <Modal
                scrollable
                show={show}
                bsSize="large"
                aria-labelledby="contained-modal-title-lg">
                <Form horizontal={true}>
                    <Modal.Header>
                        <Modal.Title>
                            <Row>
                                <Col className="pull-left">
                                    {langText.Body.ScriptNewVersionCreate}
                                </Col>
                                <Col className="pull-right">
                                    <Button
                                        className="x-execute"
                                        onClick={e => onHide()}>
                                        <Glyphicon glyph="remove"/>
                                    </Button>
                                </Col>
                            </Row>

                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Panel bsStyle="info">
                            <Panel.Body>
                                <ASFieldGroup
                                    type="text"
                                    label={langText.Body.Description}
                                    value={this.state.description}
                                    onChange={e => this.setState({description: e.target.value})}/>
                                <ASFieldGroup
                                    type="number"
                                    label={langText.Body.ScriptTimeoutSetting}
                                    value={this.state.timeout_seconds}
                                    onChange={e => {
                                        let num_value = parseInt(e.target.value);
                                        if (isNaN(num_value)) {
                                            num_value = 5;
                                        }
                                        this.setState({timeout_seconds: num_value});
                                    }}
                                    min="5"/>
                            </Panel.Body>
                        </Panel>
                    </Modal.Body>

                    <Modal.Footer>
                        <Row>
                            <Col className="panel-button pull-right">
                                <Button
                                    className="default-button"
                                    bsStyle="danger"
                                    onClick={e => onHide()}
                                    variant="outline-secondary">
                                    {this.props.langText.Body.Cancel}
                                </Button>
                                <Button
                                    className="ok-execute-btn"
                                    onClick={e => onClick(this.state)}
                                    bsStyle="primary">
                                    {this.props.langText.Body.Insert}
                                </Button>
                            </Col>
                        </Row>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}
