import React from "react";
import Component from "../../../components/AscComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidfaIcon from "@fortawesome/free-solid-svg-icons";
import * as regularfaIcon from "@fortawesome/free-regular-svg-icons";
import {Link} from "react-router-dom";
import {Nav, Navbar, NavItem, Button, Glyphicon, ButtonToolbar, Overlay, OverlayTrigger, Popover} from "react-bootstrap";
import "./Header.css";
import UserChangeSelfPasswordModal from "../../User/UserChangeSelfPasswordModal"
import moment from "moment";

// Reactで画像表示する場合css上でのbackgroundimage使用不可(画像が呼べない)
// headerとAppのみ特例でStyle直書きしてます

export default class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            overlay_show: false,
            oldPassword:    '',
            newPassword:    '',
            newPasswordRe:  '',
            bookmark_flag: false
        };

        this.notification_button = React.createRef();
    }

    componentDidMount = () => {
        this.bookmarkPermission();
    }

    handleMouseOver = event => {
        this.setState({disabled: false});
    };

    handleMouseOut = event => {
        this.setState({disabled: true});
    };

    handleLogout = async event => {
        if (window.confirm("ログアウトしますか?")) {
            await this.ascAxios("post", "/Sign/logout");
            this
                .props
                .childProps
                .userHasAuthenticated(false);
            this
                .props
                .childProps
                .historyPush("/SignIn");
        }
    };

    handleChangePassword = () => {
        this.setState({
            show: true,
            modalType: "updateSelfPassword",
            oldPassword:    '',
            newPassword:    '',
            newPasswordRe:  ''
        });
    }

    downloadHistory = async (path, nt01_id) => {
        let {
            userInfo,
            historyPush
        } = this.props.childProps;
        let cm12_id = userInfo.company_id;
        let company_name = userInfo.company_name;
        let cm13_id = userInfo.department_id;
        let cm15_id = userInfo.cm15_id;
        let floor_flg = userInfo.floor_flg ? "Y" : "N";
    
        try {
            await this.ascAxios("post", "Common/readNotification", {id: nt01_id});
        } catch (err) {
            console.error(err)
        }

        this.setShowOverlay()

        historyPush({
            pathname: path,
            state: [
                {
                    id: "cm12_id",
                    value: {
                        floor_flg: floor_flg,
                        label: company_name,
                        value: cm12_id
                    }
                },
                {
                    id: "cm15_id",
                    value: {
                        label: userInfo.user_name_sei + userInfo.user_name_mei,
                        value: {
                            id: cm15_id,
                            cm12_id: cm12_id,
                            cm13_id: cm13_id
                        }
                    }
                }
            ]
        });
    }

    setShowOverlay = () => { this.setState({ overlay_show: !this.state.overlay_show }); }

    bookmarkPermission = async () => {
        const bookmarkPermission = this.props.childProps.userInfo.permission_json_data.find(
            permission => permission.controller_id === "Bookmark"
        );
        let flag = false;
        try {
            const result = await this.ascAxios("post", "Common/getCompanyControlCallEval");
            
            if (bookmarkPermission && bookmarkPermission.read && result.data && result.data.parameter_value === "Y") flag = true;

            this.setState({ bookmark_flag: flag });
        } catch (err) {
            console.error(err);
            alert(err);
        }
    }

    render() {
        let navbarHeaderElem = <Navbar.Header>
            <Navbar.Brand>
                {/*<Link to="/" className="hover-black margin-left-05 margin-top-03">{this.props.childProps.langText.Header.MainLink}</Link>*/}
                <a className="hover-black margin-left-05 margin-bottom-10"><img src = {this.props.childProps.systemParameters["HEADERLOGO"]} onClick={e => this.props.childProps.historyPush("/")}></img></a>
            </Navbar.Brand>
            <Navbar.Toggle/>
        </Navbar.Header>;

        let navbarCollapseElem = null;
        let user_info = (
            <Popover id="popover-User-Info" title={this.props.childProps.langText.Body.UserInfo}>
                <strong>{this.props.childProps.langText.Body.EmailAddress}:</strong> &nbsp; {this.props.childProps.userInfo.user_id}<br/>
                <strong>{this.props.childProps.langText.Body.UserName}:</strong> &nbsp; {this.props.childProps.userInfo.user_name_sei + this.props.childProps.userInfo.user_name_mei}<br/>
                <strong>{this.props.childProps.langText.Body.Auth}:</strong> &nbsp; {this.props.childProps.userInfo.permission_name}<hr/>
                <div class="user-info-footer">
                    <span className="change-password-button"><Button onClick={() => this.handleChangePassword()}>{this.props.childProps.langText.Body.ChangePassword}</Button></span>
                    <span className="logout-button"><Button onClick={this.handleLogout}>{this.props.childProps.langText.Body.LogOut}</Button></span>
                </div>
            </Popover>
        );

        // 通知表示
        let notification_flag = this.props.childProps.notification_flag;
        let notifications = this.props.childProps.notifications;
        let notification_title = (
            <div className="popover-notification-title">
                <span className="left padding-top-08">{this.props.childProps.langText.Body.NotificationTitle}</span>
                <span className="right">
                    <Button 
                        onClick={() => { 
                            this.props.childProps.getNotifications();
                        }}
                    >
                        <Glyphicon glyph="refresh"></Glyphicon>
                    </Button>
                </span>
            </div>
        );
        let notification_info = (
            <Popover id="popover-notification-info" title={notification_title} className="notification-overlay">
                {
                    notification_flag ? 
                    notifications.map(row => {
                        return (
                            <div>
                                <Button
                                    bsStyle="link"
                                    onClick={() => this.downloadHistory("/DownloadHistory", row.id)}
                                >
                                    {`${moment(row.notification_datetime).format("YYYY/MM/DD HH:mm:ss")} ${row.message}`}
                                </Button>
                            </div>  
                        );
                    }) :
                    <div>
                        {this.props.childProps.langText.Message.NotNotificationMessage}
                    </div>
                }
            </Popover>
        );
        let notification_icon = notification_flag ? solidfaIcon.faBell : regularfaIcon.faBell;
        let notification_icon_style = notification_flag ? {color: "rgb(233, 182, 0)"} : {}

        if (this.props.childProps.isAuthenticated) {
            navbarCollapseElem = <Navbar.Collapse>
                <Nav pullRight={true} className="header-nav">
                    {
                        this.state.bookmark_flag && 
                        <NavItem className="Header-Bookmark-Icon">
                            <Button
                                onClick={() => {
                                    const bookmarkPermission = this.props.childProps.userInfo.permission_json_data.find(
                                        permission => permission.controller_id === "Bookmark"
                                    );
                                    if (bookmarkPermission && bookmarkPermission.read) {
                                        this.props.childProps.historyPush("/Bookmark");
                                    } else {
                                        alert(this.props.childProps.langText.Message.PathAuthCheckError);
                                    }
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={solidfaIcon.faBookmark}
                                    size="lg"
                                />
                            </Button>
                        </NavItem>
                    }
                    <NavItem className="Header-notification-icon">
                        <ButtonToolbar>
                            <Button ref={this.notification_button} onClick={() => {this.setShowOverlay()}}>
                                <FontAwesomeIcon
                                    icon={notification_icon}
                                    size="lg"
                                    style={notification_icon_style}
                                />    
                            </Button>
                            <Overlay
                                target={this.notification_button.current}
                                placement="left"
                                show={this.state.overlay_show}
                            >
                                {notification_info}
                            </Overlay>
                        </ButtonToolbar>
                    </NavItem>
                    <NavItem className="Header-User-Icon">
                        <ButtonToolbar>
                            <OverlayTrigger trigger="focus" placement="left" overlay={user_info}>
                            <Button><Glyphicon glyph="user"></Glyphicon></Button>
                            </OverlayTrigger>
                        </ButtonToolbar>
                    </NavItem>
                    {this.props.children || null}
                </Nav>
            </Navbar.Collapse>
        }

        return (
            <>
                <Navbar fluid={true} collapseOnSelect={true} className="Header">
                    {navbarHeaderElem}
                    {navbarCollapseElem}
                </Navbar>

                <UserChangeSelfPasswordModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    userId = {this.props.childProps.userInfo.cm15_id}
                    mailAddress = {this.props.childProps.userInfo.user_id}
                    firstName = {this.props.childProps.userInfo.user_name_sei}
                    lastName = {this.props.childProps.userInfo.user_name_mei}
                    langText = {this.props.childProps.langText}
                    charaLimit = {this.props.childProps.charaLimit}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    passwordValidationHandle = {this.passwordValidationHandle}
                />
            </>
        );
    }
}
