import React, {Component} from "react"
import {Panel, Col, Glyphicon, Checkbox} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"

export default class OperatorReadModal extends Component {
    getUserInfoItem = () => {
        let {
            floor_flg,
            state,
            langText
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "companyId"
                controlId = "companyId"
                label = {langText.Body.CompanyName}>
                {state.companyName}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key = "departmentId"
                controlId = "departmentId"
                label = {langText.Body.DepartmentName}>
                {state.departmentName}
            </InlineForm.static>
        );

        if (floor_flg) {
            itemArr.push(
                <InlineForm.static
                    key = "floorId"
                    controlId = "floorId"
                    label = {langText.Body.FloorName}>
                    {state.floorName}
                </InlineForm.static>
            );
        }

        itemArr.push(
            <InlineForm.static
                key = "operatorId"
                controlId = "operatorId"
                label = {langText.Body.OperatorId}>
                {state.operatorId}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm.static
                key = "operatorName"
                controlId = "operatorName"
                label = {langText.Body.OperatorName}>
                {state.operatorName}
            </InlineForm.static>
        );

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.UserInsert}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getUserInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
