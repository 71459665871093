import React, {Component} from 'react'
import {
    Col,
    Button,
    FormGroup,
    ControlLabel,
    FormControl,
    Glyphicon
} from 'react-bootstrap'

export default class MultiValueForm extends Component {
    onFormTextChange = param => event => {
        this
            .props
            .onTextChange(
                this.props.controlId,
                param.index,
                param.form,
                event.target.value
            )
    }

    onClickHandlePlus = event => {
        this.props.onSizeChange(this.props.controlId, 'plus', null)
    }

    onClickHandleMinus = param => event => {
        this.props.onSizeChange(this.props.controlId, 'minus', param)
    }

    multiValueFormRendering({
        controlId,
        labelCol,
        value,
        isStatic,
        placeholderTag,
        placeholderValue,
        validationState,
    }) {
        if (!isStatic) {
            let labelCol  = 0,
                buttonCol = 1,
                valueCol  = 11,
                multiValueFormBody = [];

            value.forEach((row, index) => {
                if (!row.del_flag || row.del_flag !== "Y") {
                    multiValueFormBody.push(
                        <div className='multiValueFormRow' key={index}>
                            <Col md={labelCol}>
                            </Col>
                            <Col md={buttonCol}>
                                <Button
                                    className = 'multiValueFormMinus'
                                    onClick   = {this.onClickHandleMinus(index)}
                                >
                                    <Glyphicon glyph='minus'/>
                                </Button>
                            </Col>
                            <Col md={valueCol}>
                                <FormControl
                                    className   = 'multiValueFormValue'
                                    onChange    = {this.onFormTextChange({index:index, form:'value'})}
                                    value       = {row.value}
                                    placeholder = {placeholderValue}
                                    disabled    = {isStatic}
                                />
                            </Col>
                        </div>
                    );
                }
            });

            if(validationState===true){
                validationState=null
            }else if(validationState===false){
                validationState='error'
            }

            return (
                <FormGroup
                    controlId={controlId}
                    className='multiValueFormBody'
                    validationState={validationState}
                >
                    {multiValueFormBody}
                </FormGroup>
            )
        } else if (isStatic) {
            let tagCol   = 2,
                valueCol = 12 - (labelCol + tagCol),
                multiValueFormBody = value.map((row, index) => {
                return (
                    <div className='multiValueFormRow' key={index}>
                        {/* <Col md={labelCol}>
                        </Col>
                        <Col md={tagCol}>
                            <FormControl.Static>
                                {row.tag}
                            </FormControl.Static>
                        </Col> */}

                        <Col md={valueCol}>
                            <FormControl.Static>
                                {row.value}
                            </FormControl.Static>
                        </Col>
                    </div>
                )
            })
            return (
                <FormGroup
                    controlId={controlId}
                    className='multiValueFormBody'
                >
                    {multiValueFormBody}
                </FormGroup>
            )
        }
    }

    render() {
        let {
            controlId,
            labelText,
            labelCol = 3,
            formCol = 9,
            isStatic,
            value,
            validationState,
            placeholderTag,
            placeholderValue,
        } = this.props

        let multiValueElement = this
                .multiValueFormRendering({
                    controlId,
                    labelCol,
                    isStatic,
                    value,
                    validationState,
                    placeholderTag,
                    placeholderValue,
                })

        return (
            <div>
                <FormGroup>
                    <Col componentClass={ControlLabel} md={labelCol}>
                        {labelText}
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Col md={formCol}>
                        {
                            !isStatic
                            &&
                            <Button
                            className = 'multiValueFormPlus'
                            onClick   = {this.onClickHandlePlus}>
                            <Glyphicon glyph='plus'/>
                        </Button>
                        }
                    </Col>
                </FormGroup>

                {multiValueElement}
            </div>
        )
    }
}
