import React from 'react';
import { Row, Col, Checkbox, Radio } from "react-bootstrap";
import AscMenu from "./Menu";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";
import * as GlobalConst from "../../../../components/AscConstants";
import AscAudio from "../../../Elements/Audio/Audio";

export default class Incoming extends AscMenu {
    constructor(props) {
        super(props);

        this.state.extension_group_data = null;
        this.audio_select = [{value: "none", label: this.props.langText.SelectOption.AudioNonePlay}, ...this.selectOptions.audio_type];
        this.state.call_over_action_select = this.selectOptions.call_over_action;
        this.state.callOverActionControlSelect = this.selectOptions.call_over_action_control;
        this.state.call_over_action_control = this.state.data.setting_value 
            && this.state.data.setting_value.call_over_action_control
            ? this.state.data.setting_value.call_over_action_control
            : "playback";

        if (this.state.data.queue_audio_type === "file" && !this.state.data.queue_audio_file.length) {
            this.state.data.queue_audio_type = this.audio_select[0].value;
        }
        this.getExtensionGroup();
        this.getAudioSelect();
        this.getDefaultAudioSelect();
    }

    async getExtensionGroup() {
        try {
            let result = await this.ascAxios("post", "Common/inboundGroupAllSelect", {company_id: this.props.company_id})
            this.setState({extension_group_data: result.data});
            if(result.data.groups){
                this.getExtensionCount();
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }


    //内線グループの内線数の確認
    getExtensionCount() {
        let extensionArr = [];
        let {extension_group_data, data} = this.state;

        for (let i = 0; i < extension_group_data.length; i++) {
            if(data.groups.indexOf(extension_group_data[i].value)!= -1){
                extensionArr.push(...extension_group_data[i].extension_numbers);
            }
        }
        //内線の重複チェック
        extensionArr = Array.from(new Set(extensionArr));
        this.state.data.count = extensionArr.length;
        let textColor = this.validation.menu.count(this.state.data)? "#A94442": "black";
        this.setState({data: data, color: textColor});
    }

    getSoundURL = async (param) => {
        try {
            const sound_url = await this.ascAxios("post", "Script/getSoundURL", {
                parameter_id: param
            });
            if (sound_url.data && sound_url.data.parameter_value) {
                return sound_url.data.parameter_value;
            } else {
                return null;
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    async onCheckBoxChange(e, param) {
        let {
            data,
            cm35_id_default,
            cm35_id_call_over_sound_default,
            before_dial_flag_default,
            wait_call_timeout_flag
        } = this.state;

        switch(param){
            case "isSetting":
                data.isSetting = !data.isSetting;
                data.setting_value = {
                    channel_number: 0,
                    wait_number: 0,
                    cm35_id: null,
                    cm35_id_call_over_sound: null,
                    before_dial_flag: "N",
                    cm35_id_before_dial: null,
                    wait_call_timeout_flag: "N",
                    wait_call_timeout_sec: null,
                    cm35_id_wait_call_timeout_sound: null, 
                    call_over_action: this.state.call_over_action_select[0].value,
                    
                };
                break;
            case "before_dial_flag":
                data.setting_value.before_dial_flag = data.setting_value.before_dial_flag === "Y"? "N": "Y";
                data.setting_value.cm35_id_before_dial = null;
                break;
            case "wait_call_timeout_flag":
                data.setting_value.wait_call_timeout_flag = data.setting_value.wait_call_timeout_flag === "Y"? "N":"Y";
                if (data.setting_value.wait_call_timeout_flag === "Y"){
                    data.setting_value.wait_call_timeout_sec = 0;
                } else{
                    data.setting_value.wait_call_timeout_sec = null;
                    data.setting_value.cm35_id_wait_call_timeout_sound = await this.getSoundURL("WAIT_CALL_TIMEOUT_SOUND");
                }
                break;
            default: 
                break;
        }
        this.setState({
            data: {...data}
        });
    }

    onRadioChange = (event, param) => {
        super.onRadioChange(event, param);
        
        let {
            data,
            call_over_action_control
        } = this.state;
        let value = event && event.target && event.target.value ? event.target.value : "";

        switch (param) {
            case "call_over_action_control":
                if (value === "busy") data.setting_value.call_over_action = value;
                data.setting_value.call_over_action_control = value;
                data.setting_value.cm35_id_call_over_sound = null;
                break;
            default:
                break;
        }

        this.setState({
            [param]: value,
            data: {...data}
        });
    }

    getAscAudioElem(audio, default_audio) {
        return (
            <Row>
                <Col xs={6} sm={6} md={11} lg={11}>
                    <ASSelect
                        value={this.state.data.setting_value[audio]}
                        options={this.state.audio_select}
                        placeholder={this.props.langText.SelectOption.Placeholder_defaultSound}
                        onChange={e => {
                            let value = e && e.value ? e && e.value : null;
                            this.state.data.setting_value[audio] = value;
                            this.setState({data: this.state.data});
                        }}
                        isClearable={true}
                    />
                </Col>
                <Col xs={6} sm={6} md={1} lg={1}>
                    <AscAudio
                        className="set_audio_button margin-top-01 margin-left--08"
                        type="url"
                        data={
                            this.state.audio_select && this.state.audio_select[this.state.audio_select.findIndex((v) => v.value === this.state.data.setting_value[audio])]
                            ? this.state.audio_select[this.state.audio_select.findIndex((v) => v.value === this.state.data.setting_value[audio])].url
                            : this.state[default_audio]
                        }
                    />
                </Col>
            </Row>
        );
    }

    getDefaultAudioSelect = async () => {
        let cm35_id_default = await this.getSoundURL("WAIT_NUMBER_SOUND"),
            cm35_id_call_over_sound_default = await this.getSoundURL("CALL_OVER_SOUND"),
            before_dial_flag_default = await this.getSoundURL("BEFORE_DIAL_SOUND"),
            cm35_id_wait_call_timeout_sound = await this.getSoundURL("WAIT_CALL_TIMEOUT_SOUND");
        
        this.setState({
            cm35_id_default,
            cm35_id_call_over_sound_default,
            before_dial_flag_default,
            cm35_id_wait_call_timeout_sound
        });
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                {this.getAudioElem({
                    key: 0,
                    audio_type_select: this.audio_select,
                    audio_label: this.props.langText.Body.Audio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "queue_audio_type",
                    audio_file: "queue_audio_file",
                    audio_file_id: "queue_audio_file_id",
                    audio_gen_language_original: "queue_audio_gen_language_original",
                    audio_gen_language: "queue_audio_gen_language",
                    audio_gen_person: "queue_audio_gen_person",
                    audio_gen_text_original: "queue_audio_gen_text_original",
                    audio_gen_text: "queue_audio_gen_text",
                    synthetic_type: "queue_synthetic_type"
                })}
                <ASFieldGroup
                    label={this.props.langText.Body.InboundGroupName}
                    validationState={this.validation.menu.groups(this.state.data)}>
                    <ASSelect
                        value={this.state.data.groups}
                        options={this.state.extension_group_data}
                        placeholder={this.props.langText.SelectOption.Placeholder}
                        isClearable={true}
                        closeMenuOnSelect={false}
                        isMulti={true}
                        onChange={e => {
                            this.state.data.groups = e.map(row => row.value);
                            this.setState({data: this.state.data});
                            this.getExtensionCount(e)
                        }}/>
                </ASFieldGroup>
                <ASFieldGroup
                    label={this.props.langText.Body.ExtenCount}
                    validationState={this.validation.menu.count(this.state.data)}
                >
                    <div>
                        <font color={this.state.color}>
                            {this.state.data.count+'/'+this.props.systemParameters.EXTEN_COUNT}
                        </font>
                    </div>
                </ASFieldGroup>
                <ASFieldGroup
                    label={this.props.langText.Body.UseScriptSetting}
                >
                    <Checkbox
                        checked = {this.state.data.isSetting || false}
                        onChange={e => this.onCheckBoxChange(e, "isSetting")} 
                    >
                        {this.props.langText.Body.UseScriptSettingCheckBox}
                    </Checkbox>
                </ASFieldGroup>
                {this.state.data.isSetting &&
                    <>
                        <div
                            className="script-setting-msg"
                        >
                            {this.props.langText.Message.UseScriptSettingMsg}
                        </div>
                        <ASFieldGroup
                            type="number"
                            min={0}
                            label={this.props.langText.Body.ChannelNumber}
                            value={this.state.data.setting_value.channel_number}
                            onChange={e => {
                                let value = this.state.data.setting_value.channel_number;
                                if(e.target.value.length <= this.props.charaLimit.External_channel_number){
                                    value = e.target.value;
                                }
                                this.state.data.setting_value.channel_number = parseInt(value);
                                this.setState({data: this.state.data});
                            }}
                            validationState={this.validation.menu.channel_number(this.state.data)}
                        />
                        <ASFieldGroup
                            type="number"
                            min={0}
                            label={this.props.langText.Body.WaitNumber}
                            value={this.state.data.setting_value.wait_number}
                            onChange={e => {
                                let value = this.state.data.setting_value.wait_number;
                                if(e.target.value.length <= this.props.charaLimit.External_wait_number){
                                    value = e.target.value;
                                }
                                this.state.data.setting_value.wait_number = parseInt(value);
                                this.setState({data: this.state.data});
                            }}
                                validationState={this.validation.menu.wait_number(this.state.data)}
                        />
                        <ASFieldGroup
                            label={this.props.langText.Body.SoundNumber}
                        >
                            {this.getAscAudioElem("cm35_id", "cm35_id_default")}
                        </ASFieldGroup>
                        <ASFieldGroup
                            label={this.props.langText.Body.WaitCallTimeOutSeconds}
                        >
                            <Checkbox
                                checked = {this.state.data.setting_value.wait_call_timeout_flag === "Y"}
                                onChange={e => this.onCheckBoxChange(e, "wait_call_timeout_flag")} 
                            >
                                {this.props.langText.Body.WaitCallTimeOutCheckBox}
                            </Checkbox>
                            {this.state.data.setting_value.wait_call_timeout_flag === "Y" &&
                            <>
                                <ASFieldGroup
                                    type="number"
                                    min={0}
                                    value={this.state.data.setting_value.wait_call_timeout_sec || 0}
                                    placeholder={this.props.langText.SelectOption.waitCallTimeoutSeconds}
                                    onChange={e =>  {
                                        let value = this.state.data.setting_value.wait_call_timeout_sec;
                                        if(e.target.value.length <= this.props.charaLimit.External_wait_call_timeout_seconds){
                                            value = e.target.value;
                                        }
                                        this.state.data.setting_value.wait_call_timeout_sec = parseInt(value);
                                        this.setState({data: this.state.data});
                                    }}
                                    validationState={this.validation.menu.wait_call_timeout_sec(this.state.data)}
                                />
                                <ASFieldGroup
                                    label={this.props.langText.Body.WaitCallTimeOutSound}
                                >
                                    {this.getAscAudioElem("cm35_id_wait_call_timeout_sound", "cm35_id_wait_call_timeout_sound")}
                                </ASFieldGroup>
                            </>
                            }
                        </ASFieldGroup>
                        <ASFieldGroup
                            label={this.props.langText.Body.ControlCallOverAction}
                        >
                            {
                                this.state.callOverActionControlSelect.map((row, index) => (
                                    <Radio
                                        key = {index}
                                        value = {row.value}
                                        checked = {row.value === this.state.call_over_action_control}
                                        onChange={e => this.onRadioChange(e, "call_over_action_control")} 
                                    >
                                        {row.label}
                                    </Radio>
                                ))
                            }
                        </ASFieldGroup>
                        {
                            this.state.call_over_action_control === "playback" &&
                            <>
                                <ASFieldGroup
                                    label={this.props.langText.Body.CallOverSound}
                                >
                                    {this.getAscAudioElem("cm35_id_call_over_sound", "cm35_id_call_over_sound_default")}
                                </ASFieldGroup>
                            
                                <ASFieldGroup
                                    label={this.props.langText.Body.CallOverAction}
                                    validationState={this.validation.menu.call_over_action(this.state.data)}
                                >
                                    <ASSelect  
                                        value={this.state.data.setting_value.call_over_action}
                                        options={this.state.call_over_action_select}
                                        placeholder={this.props.langText.SelectOption.Placeholder}
                                        onChange={e => {
                                            let value = e && e.value ? e && e.value : this.state.call_over_action_select[0].value;
                                            this.state.data.setting_value.call_over_action = value;
                                            this.setState({data: this.state.data});
                                        }}
                                    />
                                </ASFieldGroup>
                            </>
                        }
                        <ASFieldGroup
                            label={this.props.langText.Body.BeforeDialSoundPlay}
                        >
                            <Checkbox
                                checked = {this.state.data.setting_value.before_dial_flag === "Y"}
                                onChange={e => this.onCheckBoxChange(e, "before_dial_flag")} 
                            >
                                {this.props.langText.Body.BeforeDialSoundPlay}
                            </Checkbox>
                            {this.state.data.setting_value.before_dial_flag === "Y" &&
                                this.getAscAudioElem("cm35_id_before_dial", "before_dial_flag_default")
                            }
                        </ASFieldGroup>
                    </>
                }
            </>
        );
    }
}