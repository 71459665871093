/**
 * システムで利用する重要なグローバル定数群
 */

// オムニフォン表示カラー
export const omni_phone_back_ground_color = "#FFFFFF";
export const omni_phone_font_color = "#8B7E7D";
export const omni_phone_border_color = "#e2dace";

// コントローラー一覧 cm73_permissionsのjson_dataの順と合わせる必要がある
export const CONTROLLER_LIST = [
    "Gateway",
    "Pbx",
    "ExternalNumber",
    "ExtensionNumber",
    "InboundGroup",
    "GroupExtensionNumber",
    "OutboundReject",
    "InboundReject",
    "BusinessCalendar",
    "VoiceFile",
    "Company",
    "Department",
    "Floor",
    "User",
    "Customer",
    "CallHistory",
    "Permission",
    "Script",
    "SeatView",
    "AutoCallList",
    "Summary",
    "Monitoring",
    "CallAnalysis",
    "BusinessManagement",
    "CallMonitor",
    "Operator",
    "CallMonitoring",
    "AutoCall",
    "DownloadHistory",
    "Bookmark",
    "CallCategory",
    "CallResult",
    "KeywordGroup"
];

// scope_code一覧
export const SCOPE_OBJECT = {
    system: { name: "system", value: 100 },
    oem: { name: "oem", value: 200 },
    company: { name: "company", value: 300 },
    department: { name: "department", value: 400 },
    floor: { name: "floor", value: 500 }
};

// CSVインポートサイズ制限
export const CSV_IMPORT_SIZE_LIMIT = {
    autoCallList: 5000000, //自動発信リスト
    customer: 90000, //顧客管理
    outBound: 10000, //発信規制管理
    default: 9000000
};

// 音声ファイルアップロードサイズ制限
export const AUDIO_UPLOAD_SIZE_LIMIT = 1024 * 1024 * 4.5;

export const COUNT_LIMIT = {
    ExtenCount: 200 //最大内線数
};
