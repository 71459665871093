export default {
    AllSelect: "全選択",
    AllSelected: "全選択されています",
    AllDelete: "全解除",
    ExtensionAndMemoSearch: "内線番号・名称検索",
    OperatorIdAndOperatorNameSearch: "オペレーターID・オペレーター名検索",
    InboundGroupSearch: "着信グループ名検索",
    BusinessCalendarSearch: "営業カレンダー検索",
    ExtensionSelect: "内線選択",
    DepartmentSelect: "業務割り当て",
    DepartmentAndFloorSearch: "拠点・フロア検索",
    SelectDepartmentDataNumStr_NDelete: "拠点が選択されました。",
    SelectDepartmentDataNumStr_Delete: "拠点が所属しています。",
    InboundGroup: "着信グループ",
    SelectedNum: "件が選択されています。",
    SelectedNum_Delete: "件が所属しています。",
    NoneData: "データが存在しません。",
    SelectDataNumStr_NDelete: "内線番号が選択されました。",
    SelectDataNumStr_Delete: "内線番号が所属しています。",
    OperatorSelect: "オペレーター選択",
    SelectDataOperatorStr_NDelete: "オペレーターが選択されました。"
};
