import React from "react";
import {Panel} from "react-bootstrap";
import Component from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";


export default class AutoCall extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            show: false,
        };
    }

    panelOnClick = (path) => {
        this.props.historyPush({
            pathname: path,
        });
    };


    render() {
        return (
            <React.Fragment>
                <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.PredictiveCall }]} />
                <div className="flex-style">
                    <Panel
                        bsStyle="info"
                        bsSize="large"
                        className="auto-call-select"
                        onClick={() => this.panelOnClick("AutoCall/PredictiveCall")}
                    >
                        <Panel.Heading>
                            <Panel.Title componentClass="h1">
                                {this.props.langText.Body.PredictiveCall}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body className="body-auto-call-select">
                            {this.props.langText.Body.PredictiveCallDescription}
                        </Panel.Body>
                    </Panel>
                </div>
            </React.Fragment>
        );
    }
}

