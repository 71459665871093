import React, {Component} from "react";
import {
    Panel,
    ListGroup,
    ListGroupItem,
    Col,
    Button,
    ButtonGroup,
    FormGroup,
    ControlLabel,
    FormControl,
} from "react-bootstrap";
import "./MultiSelect.css";

export default class MultiSelect extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchValue: ""
        }
    }

    onSearchInputHandle = event => {
        let charalimit_count = 20;
        if(event.target.value.length <= charalimit_count)
        {
            this.setState({searchValue: event.target.value});
        }
    };

    onItemClickHandle = event => {
        let {value, controlId, max} = this.props;
        let result = [];

        let myEventTarget = event.target;

        if (myEventTarget.nodeName === "SPAN") {
            myEventTarget = myEventTarget.parentElement;
        }

        if (myEventTarget.nodeName === "DIV") {
            myEventTarget = myEventTarget.parentElement;
        }
        if (myEventTarget.className.includes('active')) {
            value.forEach(row => {
                if(row !== myEventTarget.value && parseInt(row) !== parseInt(myEventTarget.value))
                {
                    result.push(row);
                }
            });
        } else {
            if (max && value.length >= max) return
            
            result = value;
            result.push(myEventTarget.value);
        }

        this
            .props
            .propSetState({[controlId]: result});
    };

    onAllClickHandle = event => {
        let {searchValue} = this.state;

        let {options, value, controlId, max} = this.props;

        if (searchValue !== "") {
            options.forEach(option => {
                if (max && value.length >= max) return

                let labelData = option.search || option.label;

                if (labelData.includes(searchValue) && !value.find(v => v === option.value)) {
                    value.push(option.value);
                }
            });
        } else {
            options.forEach(option => {
                if (max && value.length >= max) return

                value.push(option.value);
            });
        }

        this
            .props
            .propSetState({[controlId]: value});
    };

    onRemoveClickHandle = event => {
        let {searchValue} = this.state;

        let {options, value, controlId} = this.props;

        let result = [];

        if (searchValue !== "") {
            options.forEach(option => {
                let labelData = option.search || option.label;

                if (!labelData.includes(searchValue) && value.find(v => v === option.value)) {
                    result.push(option.value);
                }
            });
        }

        this
            .props
            .propSetState({[controlId]: result});
    };

    multiSelectRendering(
        {searchValue, options, value, isDisabled, multiSelectText}
    ) {
        if (!isDisabled && options.length) {
            return (
                <ListGroup>
                    {
                        options.map((option, key) => {
                            let labelData = option.search || option.label;

                            if (searchValue === "" || labelData.includes(searchValue)) {
                                return (
                                    <ListGroupItem
                                        key={key}
                                        className={value.find(data => data === option.value || parseInt(data) === option.value)
                                            ? "active"
                                            : ""}
                                        value={option.value}
                                        onClick={this.onItemClickHandle}>
                                        {option.label}
                                    </ListGroupItem>
                                );
                            } else {
                                return null;
                            }
                        })
                    }
                </ListGroup>
            );
        } else if (isDisabled && options.length && value.length) {
            return (
                <ListGroup>
                    {
                        options.map((option, key) => {
                            if (value.find(data => data === option.value || parseInt(data) === option.value)) {
                                return (
                                    <ListGroupItem key={key} className="active" value={option.value}>
                                        {option.label}
                                    </ListGroupItem>
                                );
                            } else {
                                return null;
                            }
                        })
                    }
                </ListGroup>
            );
        } else {
            return (
                <ListGroup>
                    <ListGroupItem>
                        {multiSelectText.NoneData}
                    </ListGroupItem>
                </ListGroup>
            );
        }
    }

    render() {
        let {
            controlId,
            options,
            multiSelectText,
            searchText,
            leftCol = 3,
            rightCol = 9,
            value = [],
            isDisabled,
            // 文字列分岐用変数追加
            type,
            multiSelectMessage,
            multiSelectDeleteMessage,
        } = this.props;
        let {searchValue} = this.state;

        let multiSelectElement = this.multiSelectRendering(
            {searchValue, value, options, isDisabled, multiSelectText}
        );

        return (
            <div>
                <FormGroup>
                    <Col md={12}>
                        <FormControl
                            className="multiSelectSearchForm"
                            onChange={this.onSearchInputHandle}
                            value={searchValue}
                            placeholder={searchText}
                            disabled={isDisabled}/>
                    </Col>
                </FormGroup>
                <FormGroup controlId={controlId}>
                    <Col md={12}>
                        <Col className="multiSelectAllButton pull-left" componentClass={ControlLabel} md={leftCol}>
                            {type !== "delete" ?
                                <Col>
                                    <ButtonGroup>
                                        <Button
                                            className="allSelect"
                                            onClick={this.onAllClickHandle}
                                            disabled={isDisabled}
                                        >
                                            {multiSelectText.AllSelect}
                                        </Button>
                                        <Button
                                            className="allReset"
                                            onClick={this.onRemoveClickHandle}
                                            disabled={isDisabled}
                                        >
                                            {multiSelectText.AllDelete}
                                        </Button>
                                    </ButtonGroup>
                                </Col>
                            : ""}
                        </Col>  
                        <Col md={rightCol} className="z-index-0 pull-right">
                            <Panel className="multiSelectPanel">
                                {multiSelectElement}
                            </Panel>
                        </Col>
                    </Col>
                    <Col md={12} className="text-right">
                        {/* Typeがdeleteの場合(削除画面の場合)装飾文字列変更 */}
                        {type === "delete"
                        ?
                        value.length + multiSelectDeleteMessage
                        :
                        value.length + multiSelectMessage
                        }
                    </Col>
                </FormGroup>
            </div>
        );
    }
}