import React, { Component } from "react";
import { Glyphicon, FormControl } from "react-bootstrap";
import Select from "react-select";

export default class ASSelect extends Component {
    render() {
        const {
            key,
            value,
            options,
            placeholder,
            className,
            readOnly,
            ...props
        } = this.props;

        const select_options = options || [];
        let selected_value;

        if (Array.isArray(value)) {
            selected_value = [];
            value.forEach(row => {
                const find_value = select_options.find(option => option.value === row);
                if (find_value) selected_value.push(find_value);
            });
        } else {
            selected_value = select_options.find(option => option.value === value);
            selected_value = selected_value || "";
        }

        if (readOnly === true) {
            selected_value = Array.isArray(selected_value) ? selected_value : [selected_value];
            return <FormControl.Static>
                {selected_value.map((row, key) => {
                    if (row && row.label) {
                        return <span key={key}>{row.label}</span>
                    } else {
                        return null;
                    }
                })}
            </FormControl.Static>;
        } else {
            return (
                <Select
                    className={className || "select-width"}
                    value={selected_value}
                    options={select_options}
                    placeholder={options ? placeholder : <Glyphicon glyph="refresh" className="spinning"/>}
                    isDisabled={options ? false : true}
                    {...props} />
            );
        }
    }
}