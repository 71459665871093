import React from "react";
import {Button, Row, Col, Glyphicon, FormControl, DropdownButton, MenuItem} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import AscAudio from "../../containers/Elements/Audio/Audio";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import Select from "react-select";
import { MultiSelect } from "react-multi-select-component";
import CallHistoryModal from './CallHistoryModal';
import BlockUi from 'react-block-ui';
import { CSVLink } from "react-csv";
import 'react-block-ui/style.css';
import * as GlobalConst from "../../components/AscConstants";
import ButtonSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as solidfaIcon from "@fortawesome/free-solid-svg-icons";
import * as regularfaIcon from "@fortawesome/free-regular-svg-icons";
import ASToast from "../Elements/Toast/Toast";
import SetButtonWithSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";

export default class CallHistory extends AscComponent {
    constructor(props) {
        super(props);
        this.toggleBlocking = this.toggleBlocking.bind(this);

        const searchFilter = {};
        if (sessionStorage.getItem("callHistoryFilter") && sessionStorage.getItem("callHistoryHash")) {
            // セッションストレージの検索条件を利用して検索
            const callHistoryFilter = JSON.parse(sessionStorage.getItem('callHistoryFilter'));
            const startDatetimeItem = callHistoryFilter.filter.find(item => item.id === "start_datetime");
            startDatetimeItem.value = moment(startDatetimeItem.value);
            const endDatetimeItem = callHistoryFilter.filter.find(item => item.id === "end_datetime");
            endDatetimeItem.value = moment(endDatetimeItem.value);
            searchFilter.filtered = callHistoryFilter.filter
            searchFilter.hidden_filtered = callHistoryFilter.filter
            searchFilter.page = callHistoryFilter.page
            searchFilter.pageSize = callHistoryFilter.pageSize
            searchFilter.callHistoryHash = {"callHistoryHash": sessionStorage.getItem("callHistoryHash")}
        } else {
            // 通常検索(初期表示)
            searchFilter.filtered = [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: moment(new Date()).format("HH"), label: moment(new Date()).format("HH") },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                },
            ];
            searchFilter.hidden_filtered = [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: moment(new Date()).format("HH"), label: moment(new Date()).format("HH") },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                },
            ];
        }

        this.state = {
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            count: null,
            loading: false,
            ...searchFilter,
            externalarr: [],
            display_number:[],
            disposition: [],
            bookmark_flag: {},
            bookmark_id: {},
            bookmark_btn_flag: {},
            call_result_rank: [],
            call_result_rank_select: [],

            // modal item
            show:               false,
            modalType:          null,
            download_link :     null,
            csvdata:[]              ,
            blocking:           false,
            download_interval_id:         null,
            download_end_flag:      false,
            companySelect: [],
            operatorArr: [],
            operator_name: []
        };

        if (this.props.isAuthenticated && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            this.getAllExternal(this.props.userInfo.company_id);
            this.getAllOperator(this.props.userInfo.company_id);
            this.getCallResultRankListInfo(this.props.userInfo.company_id);
        }

        this.inoutSelect = this.getSelectOption('inout_type', this.props.langText.SelectOption);
        this.callResultSelect = this.getSelectOption('call_result', this.props.langText.SelectOption);
        this.downloadFileType = this.getSelectOption('download_file_type', this.props.langText.SelectOption);
        this.downloadFileTypeNoMp3 = this.getSelectOption('download_file_type_no_mp3', this.props.langText.SelectOption);


        this.hourShowList = this.getSelectOption('hour_show_list', this.props.langText.SelectOption);
        this.minuteShowList = this.getSelectOption('minute_show_list', this.props.langText.SelectOption);


        this.table = React.createRef();
        this.csvLink = React.createRef();
        this.myAudio = document.createElement("AUDIO");

        // CSVヘッダー定義
        this.headers = [
            {
                label: this.props.langText.Body.DepartmentName,
                key: 'cm13_departments.department_name'
            },
            {
                label: this.props.langText.Body.InOutType,
                key: 'inout_type'
            },{
                label: this.props.langText.Body.ExtensionNumber,
                key: 'extension_number'
            },{
                label: this.props.langText.Body.ExternalNumber,
                key: 'ct62_assgin_external_department_infos.cm61_external_numbers.display_number'
            },{
                label: this.props.langText.Body.ExternalNumberMemo,
                key: 'ct62_assgin_external_department_infos.cm61_external_numbers.memo'
            },{
                label: this.props.langText.Body.DestUserNumber,
                key: 'dest_tel_no'
            },{
                label: this.props.langText.Body.DestUserName,
                key: 'dest_tel_no_user_name'
            },{
                label: this.props.langText.Body.StartDateTime,
                key: 'start_datetime'
            },{
                label: this.props.langText.Body.AnswerDateTime,
                key: 'answer_datetime'
            },{
                label: this.props.langText.Body.EndDateTime,
                key: 'end_datetime'
            },{
                label: this.props.langText.Body.BillSec,
                key: 'billsec'
            },{
                label: this.props.langText.Body.Result,
                key: 'disposition'
            },{
                label: this.props.langText.Body.CallResult,
                key: "call_result"
            }
        ];

        // 会社選択肢セット
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            this.getCommonCompanySelect(this.reactContainerPath)
                .then(res => {
                    this.setState({companySelect: res.data})
                }).catch(err => this.showErrorObjectMesssage(err))
        }
    }
    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }
    async componentDidMount(){
        let search_company_parameter_call_result_rank = await this.getCompanyControlCallResultRank();
        this.setState({
            search_company_parameter_call_result_rank,
        }, () => {
            this.setState({
                columns: this.getColumnsData()
            });
        });

        if(sessionStorage.getItem("callHistoryFilter")){
            //画面遷移してきた時
            const callHistoryFilter = JSON.parse(sessionStorage.getItem('callHistoryFilter'));
            const disposition = callHistoryFilter.filter.find(item => item.id === "disposition");
            const display_number = callHistoryFilter.filter.find(item => item.id === "display_number");
            const operator_name = callHistoryFilter.filter.find(item => item.id === "operator_name");
            const cm12_id = callHistoryFilter.filter.find(item => item.id === "cm12_id");
            const call_result_rank = callHistoryFilter.filter.find(item => item.id === "call_result_rank");

            // 初期化完了後にセレクトボックスの値をセットする
            if (cm12_id && cm12_id.value && cm12_id.value.value) {
                await this.onChange(cm12_id.value, "cm12_id");
            }
            if (disposition && disposition.value && disposition.value.length > 0) {
                this.onChange(disposition.value, "disposition");
            }
            if (display_number && display_number.value && display_number.value.length > 0) {
                this.onChange(display_number.value, "display_number");
            }
            if (operator_name && operator_name.value && operator_name.value.length > 0) {
                this.onChange(operator_name.value, "operator_name");
            }
            if (call_result_rank && call_result_rank.value && call_result_rank.value.length > 0) {
                this.onChange(call_result_rank.value, "call_result_rank", true);
            }

            // セッションストレージから検索条件を削除する
            sessionStorage.removeItem("callHistoryFilter");
            sessionStorage.removeItem("callHistoryHash");
        }
    };
    //画面遷移の時に polling処理を止める
    componentWillUnmount() {
        clearInterval(this.state.download_interval_id);
    }

    downloadData(path){
        const startDate = new Date();

        //5秒ごとに実行
        let download_interval = setInterval(()=> {
            this.ascAxios('get', this.baseURL + `/Download/s3CallHistoryDownload/${encodeURIComponent(path)}`)
                .then(res => {
                    let download_flag = true;
                    //サーバーから結果が処理中の場合（lockファイルがあるか、変換中か）
                    if (res.data != null && (res.data.download_status == "lock_exist" || res.data.download_status == "converting")) {
                        download_flag = false;
                        //サーバーから結果がエラーの場合
                    } else if (res.data != null && (res.data.download_status == "err" || res.data.download_status == "not_exist_path"|| res.data.download_status == "lambda_status_err")) {
                        throw new Error();
                    } else {
                        download_flag = true;
                    }
                    //成功場合
                    if (download_flag && res.config.url){
                        clearInterval(download_interval);
                        //ダウンロード中に時間がかかってbackgroundで動いた結果が戻ってきたら1回だけダウンロードさせる、
                        if (!this.state.download_end_flag) {
                            this.setState({download_end_flag: true});
                            this.toggleBlocking();
                            const downLoadLink = document.createElement("a");
                            downLoadLink.href = res.data;
                            downLoadLink.click();
                            downLoadLink.remove();
                        }
                    }
                    // 30秒タイムアウト
                    if (new Date() - startDate > 30 * 1000) {
                        clearInterval(download_interval);
                        this.toggleBlocking();
                        alert(this.getErrorString({code : "File_Download_Failed"}));
                    }
                })
                //失敗の場合
                .catch(err => {
                    clearInterval(download_interval);
                    this.toggleBlocking();
                    alert(this.getErrorString({code : "File_Download_Failed"}));
                })
        }, 3 * 1000);

        let {
            cm12_companies,
            cm13_departments,
            extension_number,
            cm16_operators,
            inout_type,
            display_number,
            dest_tel_no,
            start_datetime,
            ct62_assgin_external_department_infos
        } = this.state.param.original;

        let externalInfo = ""
        if(display_number && ct62_assgin_external_department_infos.cm61_external_numbers.memo != "") {
            externalInfo = display_number + "(" + ct62_assgin_external_department_infos.cm61_external_numbers.memo + ")";
        } else if(display_number) {
            externalInfo = display_number;
        }
        this.saveAccessLogs({
            "resource" : `${this.reactContainerPath}/voiceLogDownload`, 
            "body" : {
                "voice_log_url" : path,
                "company_name" : cm12_companies.company_name,
                "department_name" : cm13_departments.department_name,
                "floor_name" : cm13_departments.floor_name || "",
                "extension_number" : extension_number,
                "operator_name" : cm16_operators && cm16_operators.operator_name ? cm16_operators.operator_name : "",
                "inout_type" : inout_type,
                "display_number" : externalInfo,
                "dest_tel_no" : dest_tel_no,
                "start_datetime" : moment(start_datetime).format("YYYY-MM-DD HH:mm:ss"),
            }
        });

        this.setState({download_interval_id: download_interval});

    };
    // funcion_idでパラメータを持ってくる
    getUserCompanyControlParametersData(param) {
        let data = null
        if (this.props.userCompanyControlParameters.length > 0) {
            data = this.props.userCompanyControlParameters.find(function(item) {return item.function_id === param})
        }
        return data;
    }

    onPlayHandle = (audio) => {
        try {
            if (audio.played["length"] === 0 && audio.currentTime === 0) {
                this.saveAccessLogs({
                    "resource" : `${this.reactContainerPath}/voiceLogPlay`, 
                    "body" : {
                        "voice_log_url" : audio.currentSrc
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    getColumnsData() {
        let columns = [];
        //ID
        columns.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            sortable: false,
            width: this.props.boardWidth.id,
        });
        //会社名
        columns.push({
            Header: this.props.langText.Body.CompanyName,
            sortable: false,
            accessor: "cm12_companies.company_name",
            width: this.props.boardWidth.CallHistoryLarge,
        });
        //発着区分
        columns.push({
            Header: this.props.langText.Body.InOutType,
            sortable: false,
            accessor: "inout_type",
            width: this.props.boardWidth.xxsmall,
        });
        //内線番号
        columns.push({
            Header: this.props.langText.Body.ExtensionNumber,
            sortable: false,
            accessor: "extension_number",
            width: this.props.boardWidth.CallNumber,
        });
        //オペレーター名
        //オペレーター管理の閲覧権限があればオペレーター名のカラムを表示する
        if(this.checkOperatorPermission()){ 
            columns.push({
                Header: this.props.langText.Body.OperatorName,
                sortable: false,
                accessor: "cm16_operators.operator_name",
                width: this.props.boardWidth.CallNumber,
                Cell: data => {
                    let returnValue = "---";
                    returnValue = data.original && data.original.cm16_operators? data.original.cm16_operators.operator_name: "---";
                    return returnValue;
                }
            });
        }   
        //外線番号
        columns.push({
            Header: this.props.langText.Body.ExternalNumber,
            sortable: false,
            accessor: "ct62_assgin_external_department_infos.cm61_external_numbers.display_number",
            // accessor: "cm61_external_numbers.display_number",
            width: this.props.boardWidth.CallHistoryLarge,
            Cell: data => {
                let returndata = "";
                if(data.value && data.row._original.ct62_assgin_external_department_infos.cm61_external_numbers.memo != "")
                {
                    returndata = data.value + "(" + data.row._original.ct62_assgin_external_department_infos.cm61_external_numbers.memo + ")";
                }
                else if(data.value)
                {
                    returndata = data.value;
                }
                return returndata;
            }
        });
        //相手先番号
        columns.push({
            Header: this.props.langText.Body.DestUserNumber,
            sortable: false,
            accessor: "dest_tel_no",
            width: this.props.boardWidth.CallHistoryLarge,
        });
        //開始時間
        columns.push({
            Header: this.props.langText.Body.StartTime,
            sortable: false,
            accessor: "start_datetime",
            width: this.props.boardWidth.large,
            Cell: data => {
                return data.value ? this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"}) : "";
            }
        });
        //通話時間
        columns.push( {
            Header: this.props.langText.Body.TalkingTime,
            sortable: false,
            accessor: "billsec",
            width: this.props.boardWidth.xsmall,
            Cell: data => {
                return this.secToTime(data.value || 0);
            }
        });


        let call_record_operation_flag = true;
        let search_company_parameter_call_record = this.getUserCompanyControlParametersData("CALL_RECORD");

        let result_width = this.props.boardWidth.small;

        // 会社範囲以下は会社パラメタテーブルから操作フラグを決める
        if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
            && (search_company_parameter_call_record !== null &&
                typeof search_company_parameter_call_record !== "undefined")) {
            //コール履歴「操作」表示フラグ
            if (search_company_parameter_call_record.parameter_value === "N") {
                call_record_operation_flag = false;
            }
            result_width = this.props.boardWidth.medium;
        }

        //結果
        columns.push({
            Header: this.props.langText.Body.Result,
            sortable: false,
            accessor: "disposition",
            width: result_width,
        });

        // コール結果・ランク
        if (this.state && this.state.search_company_parameter_call_result_rank && this.state.search_company_parameter_call_result_rank.parameter_value === "Y") {
            columns.push({
                Header: this.props.langText.Body.CallResultRank,
                sortable: false,
                accessor: "ct601_call_rank_results",
                width: this.props.boardWidth.CallRank,
                Cell: data => {
                    let return_string = "";
                    let cm64_call_results = data.value && data.value.cm64_call_results;
                    let cm65_call_ranks = data.value && data.value.cm65_call_ranks;
                    let cm65_call_ranks_2 = data.value && data.value.cm65_call_ranks_2;
                    if (cm64_call_results) return_string += cm64_call_results.call_result_name;
                    if (cm65_call_ranks) return_string += `・${cm65_call_ranks.rank_name}`;
                    if (cm65_call_ranks_2) return_string += `・${cm65_call_ranks_2.rank_name}`;
                    return return_string; 
                }
            });
        }

        //表示
        //システム管理者かパラメータがない場合
        //パラメータがあり、Yの場合
        if (call_record_operation_flag){
            //操作
            columns.push({
                Header: this.props.langText.Body.Control,
                accessor: "voice_log_url",
                sortable: false,
                width: this.props.boardWidth.smedium,
                Cell: data => {
                    const bookmarkPermission = this.props.userInfo.permission_json_data.find(
                        permission => permission.controller_id === "Bookmark"
                    );
                    let rowData = [];
                    if (data.value && data.row.disposition === "ANSWERED") {
                        if(this.props.currentPermission.playback && data.value !== "expired") {
                            if (this.state.search_company_parameter_call_result_rank && this.state.search_company_parameter_call_result_rank.parameter_value === "Y") {
                                rowData.push(
                                    <Button
                                        key={"audio" + data.index}
                                        bsSize="xsmall"
                                        className="control-button"
                                        onClick={this.modalShow("callRecordingPlay", data)}
                                    >
                                        <FontAwesomeIcon
                                            icon={faIcon.faPlay}
                                        />
                                    </Button>
                                );
                            } else {
                                rowData.push(
                                    <AscAudio
                                        key = {"audio" + data.index}
                                        type = "url"
                                        data = {data.value}
                                        className = "control-button"
                                        onPlayHandle={this.onPlayHandle}/>
                                );
                            }
                        } else if (this.props.currentPermission.playback && data.value === "expired") {
                            rowData.push(<ButtonSpeechBubble
                                key = {"audio" + data.index}
                                bsSize="xsmall"
                                className="control-button-disabled"
                                DisplayIcon="play"
                                speechBubble={this.props.langText.Body.CallHistoryExpired}
                            />);
                        }
                        if(this.props.currentPermission.export && data.value !== "expired") {
                            rowData.push(
                                <Button
                                    key={"dl-btn" + data.index}
                                    bsSize="xsmall"
                                    onClick={this.modalShow("download",data)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="download-alt"/>
                                </Button>
                            );
                        } else if (this.props.currentPermission.export && data.value === "expired") {
                            rowData.push(<ButtonSpeechBubble
                                key = {"dl-btn" + data.index}
                                bsSize="xsmall"
                                className="control-button-disabled"
                                DisplayIcon="download-alt"
                                speechBubble={this.props.langText.Body.CallHistoryExpired}
                            />);
                        }
                        if(this.props.currentPermission.playback && data.value !== "expired" && data.original.ct87_voice_log_to_text && data.original.ct86_call_result_eval && data.original.call_eval_flg === "Y") {
                            rowData.push(
                                <Button
                                    key={"search-btn" + data.index}
                                    bsSize="xsmall"
                                    onClick={() => {
                                        this.getCallHistoryAnalysis(data.row._original, data.index);
                                    }}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="search"/>
                                </Button>
                            );
                        } else if (this.props.currentPermission.playback && data.value === "expired" && data.original.ct87_voice_log_to_text 
                            && data.original.ct86_call_result_eval && data.original.call_eval_flg === "Y") {
                            rowData.push(<ButtonSpeechBubble
                                key = {"search-btn" + data.index}
                                bsSize="xsmall"
                                className="control-button-disabled"
                                DisplayIcon="search"
                                speechBubble={this.props.langText.Body.CallHistoryExpired}
                            />);
                        }
                    } else {
                        if (
                            this.state.search_company_parameter_call_result_rank 
                            && this.state.search_company_parameter_call_result_rank.parameter_value === "Y"
                            && this.props.currentPermission.edit
                        ) {
                            data.isNotAnswered = true;
                            rowData.push(
                                <Button
                                    key={"audio" + data.index}
                                    bsSize="xsmall"
                                    className="control-button"
                                    onClick={this.modalShow("callRecordingPlay", data)}
                                >
                                    <Glyphicon glyph="pencil"/>
                                </Button>
                            );
                        }
                    }

                    if (data.original.call_eval_flg === "Y" && bookmarkPermission && bookmarkPermission.read) {
                        let flag = this.state.bookmark_flag[data.index] || this.state.bookmark_flag[data.index] === false
                            ? this.state.bookmark_flag[data.index] 
                            : data.row._original.ct03_user_bookmarks.length > 0
                            ? true
                            : false;
                        let bookmark_id = null;

                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={"bookmark-btn" + data.index}
                                bsSize="xsmall"
                                onClick={async () => {
                                    try {
                                        this.setState({
                                            bookmark_btn_flag: {
                                                ...this.state.bookmark_btn_flag,
                                                [data.index]: true
                                            }
                                        });
                                        let path = flag ? "Common/deleteBookmark" : "Common/insertBookmark";
                                        let body = flag ? {
                                            id: this.state.bookmark_id[data.index]
                                                ? this.state.bookmark_id[data.index]
                                                : data.row._original.ct03_user_bookmarks[0].id
                                        } : {
                                            ct60_id: data.row._original.id
                                        };
                                        let result = await this.ascAxios("post", path, body);
                                        if (result.data.id) bookmark_id = result.data.id;  
                                        
                                        this.toast(
                                            flag
                                            ? this.props.langText.Message.BookmarkDelete
                                            : this.props.langText.Message.BookmarkRegister
                                        );

                                        this.setState({
                                            bookmark_flag: {
                                                ...this.state.bookmark_flag,
                                                [data.index]: !flag
                                            },
                                            bookmark_id: {
                                                ...this.state.bookmark_id, 
                                                [data.index]: bookmark_id
                                            }
                                        });
                                    } catch (err) {
                                        console.error(err);
                                        this.showErrorObjectMesssage(err);
                                    } finally {
                                        this.setState({ 
                                            bookmark_btn_flag: {
                                                ...this.state.bookmark_btn_flag,
                                                [data.index]: false
                                            }
                                        });
                                    }
                                }}
                                className="control-button"
                                disabled={this.state.bookmark_btn_flag[data.index] ? this.state.bookmark_btn_flag[data.index] : false}
                                DisplayFontAwesomeIcon={ flag ? solidfaIcon.faBookmark : regularfaIcon.faBookmark }
                                iconSize="1x"
                                speechBubble={ flag ? this.props.langText.Body.Bookmarked : this.props.langText.Body.BookmarkRegister}
                            />
                        );
                    }
                    return (
                        <Row className="text-center" key = {"row"+data.index}>
                            {rowData}
                        </Row>
                    );
                }
            });
        }


        return columns;
    }

    getAllExternal(cm12_id) {
        this
            .ascAxios('post', `${this.reactContainerPath}/getallExternal`, {cm12_id: cm12_id})
            .then(result => {
                this.setState({externalarr: result.data});
                return result.data;
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    }

    getAllOperator = async(cm12_id) => {
        try {
            let result = await this.ascAxios("post", `${this.reactContainerPath}/getAllOperator`, {cm12_id: cm12_id});
            //valueがnullの「オペレータ情報なし」をオペレーター名の選択欄の先頭に追加する
            let additionalOption = { value: null , label: this.props.langText.Body.OperatorNull };
            result.data.unshift(additionalOption);

                //stateの更新
                this.setState({operatorArr: result.data});
                return result.data;
            
            } catch (err) {
                alert(this.getErrorString(err.response.data));
            }
    }

    getAnalysisInfo(ct60_id, ct87_id) {
        return this.ascAxios('post', `${this.reactContainerPath}/getEvalDataByCt60Id`, {ct60_id, ct87_id});
    }

    getCallHistoryAnalysis = async (ct60_call_history_datas, idx) => {
        try {
            let ct87_voice_log_to_text_data = [];
            let ct86_call_result_eval_data = [];
            let ct601_call_rank_results_data = [];
            let ct03_bookmark_flag = null;
            let tag_infos = [];

            if (this.state.bookmark_flag[idx]) {
                ct03_bookmark_flag = this.state.bookmark_id[idx];
            } else if (this.state.bookmark_flag[idx] === false) {
                ct03_bookmark_flag = this.state.bookmark_flag[idx];
            } else {
                ct03_bookmark_flag = ct60_call_history_datas.ct03_user_bookmarks.length > 0 && ct60_call_history_datas.ct03_user_bookmarks[0].id;
            }

            if(ct60_call_history_datas){
                let res = await this.getAnalysisInfo(ct60_call_history_datas.id, ct60_call_history_datas.ct87_voice_log_to_text.id);
                let ct601_res = await this.getCallResultRankInfo(ct60_call_history_datas.id);
                tag_infos = (await this.ascAxios('post', `Common/getTagDataByCt60Id`, { ct60_id: ct60_call_history_datas.id })).data;
                ct87_voice_log_to_text_data = res.data.ct87_voice_log_to_text_data;
                ct86_call_result_eval_data = res.data.ct86_call_result_eval_data;
                ct601_call_rank_results_data = ct601_res.data[0];
            }
            let operator_talk_speed = ct86_call_result_eval_data.operator_talk_speed;
            let customer_talk_speed = ct86_call_result_eval_data.customer_talk_speed;
    
            const OP_SPEED_RATIO = (Math.abs((operator_talk_speed - customer_talk_speed) / customer_talk_speed) * 100).toFixed(1);
            const CUSTOMER_SPEED_RATIO = (Math.abs((customer_talk_speed - operator_talk_speed) / operator_talk_speed) * 100).toFixed(1);
            const OP_LABEL = this.props.langText.Body.Op;
            const CUSTOMER_LABEL = this.props.langText.Body.Customer;

            if (operator_talk_speed === 0 || customer_talk_speed === 0){
                ct86_call_result_eval_data.operator_speed_message = "";
                ct86_call_result_eval_data.customer_speed_message = "";
            } else if (operator_talk_speed > customer_talk_speed) {
                ct86_call_result_eval_data.operator_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedFast, CUSTOMER_LABEL, OP_SPEED_RATIO);
                ct86_call_result_eval_data.customer_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedSlow, OP_LABEL, CUSTOMER_SPEED_RATIO);
            } else if (operator_talk_speed < customer_talk_speed) {
                ct86_call_result_eval_data.operator_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedSlow, CUSTOMER_LABEL, OP_SPEED_RATIO);
                ct86_call_result_eval_data.customer_speed_message = this.sprintf(this.props.langText.Message.TalkSpeedFast, OP_LABEL, CUSTOMER_SPEED_RATIO);
            } else {
                ct86_call_result_eval_data.operator_speed_message = "";
                ct86_call_result_eval_data.customer_speed_message = "";
            }

            let stateData = {
                ct60_call_history_datas,
                ct87_voice_log_to_text_data,
                ct86_call_result_eval_data,
                ct03_bookmark_flag,
                ct601_call_rank_results_data,
                tag_infos
            }

            // 画面遷移前にセッションストレージに検索条件を保管する
            const callHistoryFilter = {
                page: this.table.current.state.page,
                pageSize: this.table.current.state.pageSize,
                filter: this.state.filtered.sort((a, b) => {
                    if (a.id < b.id) {
                        return -1;
                    }
                    if (a.id > b.id) {
                        return 1;
                    }
                    return 0;
                })
            };
            const hash = await this.createHash(JSON.stringify(callHistoryFilter.filter));

            sessionStorage.setItem('callHistoryFilter', JSON.stringify(callHistoryFilter));
            sessionStorage.setItem('callHistoryHash', hash);

            this.props.historyPush({
                pathname: '/CallHistory/CallHistoryAnalysis',
                state: stateData
            });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }
    getFilterData(param) {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    getValidationCheck = () =>{
        let message = "";
        if (
            this.getFilterData("id")
        ){
            message = "";
            this.setState({searchValidation : false, message});
            return ;
        } else if (
            !this.getFilterData("start_datetime") && !this.getFilterData("dest_tel_no") ||
            !this.getFilterData("end_datetime") && !this.getFilterData("dest_tel_no")
        ){
            message = this.props.langText.Message.CallHistory_FindDataError;
            this.setState({searchValidation : true, message});
        } else if(
            moment.duration(moment(this.getFilterData("end_datetime")).diff(moment(this.getFilterData("start_datetime")))).asDays()<0
        ){
            message = this.props.langText.Message.CallHistory_DayTermError;
            this.setState({searchValidation : true, message});
        } else if (!this.setTimeCheck()) {
            message = this.props.langText.Message.StartDateTimeCheckError;
            this.setState({searchValidation : true, message});
        } else {
            this.setState({searchValidation: false, message: ""});
        }
    }

    onChange = async (event, param, mount=false) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";
        // セレクトボックス用。event無い場合はvalue空のまま
        if(event)
        {
            value = event.target ? event.target.value : event;
        }

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (param === "call_result_rank") {
            // コール結果ランクが選択されたものに紐づくランクも一緒に選択される
            if (this.state.call_result_rank_select.length !== event.length && this.state[param].length < event.length) {
                let filter_data = event[event.length - 1];
                let associate_data = [];
                // levelは項目のdepthになります。0:大項目(コール結果) 1:ランク１orランク２(ランクを分けるための項目) 2:コールランク(実際のランク項目)
                switch (filter_data.value.level) {
                    case 1:
                        associate_data = this.state.call_result_rank_select.filter(row => 
                            row.value.rank_type === filter_data.value.rank_type && row.value.cm64_id === filter_data.value.cm64_id && row.value.level !== 1
                        );
                        break;
                    default:
                        break;
                }
                
                associate_data.forEach(row => {
                    event.push(row);
                });
                event = [...new Set(event)];
            } else if (this.state[param].length > event.length && event.length !== 0) {
                // コール結果ランクが除外されたものに紐づくランクも一緒に除外される
                let diff = this.state[param].filter(row => event.indexOf(row) === -1);
                let associate_data = null;

                // levelは項目のdepthになります。0:大項目(コール結果) 1:ランク１orランク２(ランクを分けるための項目) 2:コールランク(実際のランク項目)
                switch (diff[0].value.level) {
                    case 1:
                        associate_data = event.filter(row => 
                            (row.value.rank_type !== diff[0].value.rank_type || row.value.cm64_id !== diff[0].value.cm64_id) &&
                            (row.value.cm64_id !== diff[0].value.cm64_id || row.value.level !== 0)
                        );
                        break;
                    case 2:
                        associate_data = event.filter(row => 
                            (row.value.rank_type !== diff[0].value.rank_type ||
                            row.value.cm64_id !== diff[0].value.cm64_id ||
                            row.value.level !== 1) &&
                            (row.value.cm64_id !== diff[0].value.cm64_id || row.value.level !== 0)
                        );
                        break;
                    default:
                        break;
                }

                event = associate_data ? associate_data : event;
            }

            if (mount) {
                let call_result_rank_data = [];
                event.forEach(row => {
                    let call_result_rank = this.state.call_result_rank_select.find(item => 
                        item.value.cm64_id === row.value.cm64_id 
                            && item.value.cm65_id === row.value.cm65_id 
                            && item.value.level === row.value.level
                            && item.value.rank_type === row.value.rank_type
                    );
                    call_result_rank_data.push(call_result_rank);
                });
                event = call_result_rank_data;
            }

            value = event;
            this.setState({[param]: event});
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }
        // 会社選択
        if (param === "cm12_id" && event && event.value) {
            this.getAllExternal(event.value)
            this.getAllOperator(event.value)
            await this.getCallResultRankListInfo(event.value);
            this.setState({
                display_number: [],
                operator_name: [],
                call_result_rank: [],
            })
        } else if (param === "cm12_id") {
            // クリアした時
            this.setState({
                externalarr: [],
                display_number: [],
                operatorArr: [],
                operator_name: [],
                call_result_rank: [],
                call_result_rank_select: [],
            })
        }

        if ((param === "disposition" || param === "display_number" || param === "operator_name") && event) {
            this.setState({[param]: event});
            if (param === "display_number" && this.state.externalarr.length === event.length) {
                let index = my_filtered.findIndex(row => row.id === "display_number");

                my_filtered.splice(index, 1);
            }
        }

        let returnValue = [];
        if (param === "start_datetime" && event === null) {
            returnValue = my_filtered.filter(row => {
                return row.id !== "start_ji" && row.id !== "start_hun";
            });
            returnValue.push({id: "start_ji", value: { value: '00', label: '00' }});
            returnValue.push({id: "start_hun", value: { value: '00', label: '00' }});
        } else if (param === "end_datetime" && event === null) {
            returnValue = my_filtered.filter(row => {
                return row.id !== "end_ji" && row.id !== "end_hun";
            });
            returnValue.push({id: "end_ji", value: { value: '23', label: '23' }});
            returnValue.push({id: "end_hun", value: { value: '59', label: '59' }});
        } else {
            returnValue = my_filtered
        }

        this.setState({
            filtered: returnValue
        });

        this.getValidationCheck();
    }

    onSearchDataChange = (event) => {
        // 既存データ初期化
        this.setState({
            filtered: [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: moment(new Date()).format("HH"), label: moment(new Date()).format("HH") },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                }
            ],
            hidden_filtered: [
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: moment(new Date()).format("HH"), label: moment(new Date()).format("HH") },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                }
            ]
        })

        switch (event) {
            case "incoming_no_answer":
                // in_out
                this.onChange(this.inoutSelect.find(row => row.value === "in"), "inout_type");

                // call_result
                let call_result = [];
                this.callResultSelect.forEach(row => {
                    if (row.value === "NO ANSWER" || row.value === "BUSY" || row.value === "PLAYBACK" || row.value === "FAILED") {
                        call_result.push(row);
                    }
                });
                this.onChange(call_result, "disposition");
                break;
            case "anonymous":
                this.onChange("anonymous","dest_tel_no");
                break;
            case "clear":
                this.setState({
                    searchValidation: false, 
                    message: "",
                    display_number: [],
                    disposition: [],
                    operator_name: [],
                    call_result_rank: []
                });
                break;
            default:
        }
    }

    onSearch = (event) => {
        let filter =[];

        this.state.filtered.forEach((row, index) => {
            filter.push(row);
        })

        this.setState({
            hidden_filtered: filter,
            bookmark_flag: {},
            bookmark_id: {}
        }, ()=>{
            //検索時にpageを1ページする
            this.table.current.state.page = 0;
            this.table.current.state.hidden_filtered = filter;
            this.fetchData(this.table.current.state, this.table.current.instance);
        });
    }

    secToTime = (secs) => {
        let hour = Math.floor(secs / 3600),
            minutes = Math.floor(secs / 60) % 60,
            sec = secs % 60;

        return `${hour.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${sec.toString().padStart(2, "0")}`;
    }

    // CVSヘッダーの成型
    moldCsvHeader() {
        let moldHeader = this.headers;
        if(this.checkOperatorPermission()){
            moldHeader = [
                    {
                        label: this.props.langText.Body.DepartmentName,
                        key: 'cm13_departments.department_name'
                    },
                    {
                        label: this.props.langText.Body.InOutType,
                        key: 'inout_type'
                    },{
                        label: this.props.langText.Body.ExtensionNumber,
                        key: 'extension_number'
                    },{
                        label: this.props.langText.Body.OperatorName,
                        key: 'cm16_operators.operator_name'
                    },{
                        label: this.props.langText.Body.ExternalNumber,
                        key: 'ct62_assgin_external_department_infos.cm61_external_numbers.display_number'
                    },{
                        label: this.props.langText.Body.ExternalNumberMemo,
                        key: 'ct62_assgin_external_department_infos.cm61_external_numbers.memo'
                    },{
                        label: this.props.langText.Body.DestUserNumber,
                        key: 'dest_tel_no'
                    },{
                        label: this.props.langText.Body.DestUserName,
                        key: 'dest_tel_no_user_name'
                    },{
                        label: this.props.langText.Body.StartDateTime,
                        key: 'start_datetime'
                    },{
                        label: this.props.langText.Body.AnswerDateTime,
                        key: 'answer_datetime'
                    },{
                        label: this.props.langText.Body.EndDateTime,
                        key: 'end_datetime'
                    },{
                        label: this.props.langText.Body.BillSec,
                        key: 'billsec'
                    },{
                        label: this.props.langText.Body.Result,
                        key: 'disposition'
                    },{
                        label: this.props.langText.Body.CallResult,
                        key: "call_result"
                    }
                ]
        }
        if (
            !this.state.search_company_parameter_call_result_rank ||
            this.state.search_company_parameter_call_result_rank.parameter_value !== "Y"
        ) {
            // コール結果オプションがNの場合はヘッダーから取り除く
            moldHeader = moldHeader.filter(item => item.key !== "call_result");
        }
        return moldHeader
    }

    callHistoryCsvDL = async () => {
        try {
            this.blockUI();
            let result = await this.ascAxios('post', `${this.reactContainerPath}/board`,
                {
                    csvFlag: true,
                    sorted: [{id: "start_datetime", desc: true}],
                    filtered: this.state.filtered,
                }
            );
            if(result.data.args && result.data.args[0] > 10000){
                // 1万件超はbatchにてダウンロード
                this.downloadModalShow(result.data.args[0]);
            } else if (result.data.rows && result.data.rows.length > 0) {
                // 1万件以下はクライアントダウンロード
                this.formatCsvCallRank(result)
                this.setState({csvdata: result.data.rows});
                return this.csvLink.current.link.click();
            } else {
                return this.showErrorObjectMesssage({message: "Download_Failed"});
            }
        } catch (e) {
            this.downloadModalShow("timeout");
        } finally {
            this.unblockUI();
        }
    }

    formatCsvCallRank = async(result) => {
        result.data.rows.forEach(item => {
            let return_string = "";
            let cm64_call_result_name =
                item.ct601_call_rank_results &&
                item.ct601_call_rank_results.cm64_call_results &&
                item.ct601_call_rank_results.cm64_call_results.call_result_name;
            let cm65_call_rank_name =
                item.ct601_call_rank_results &&
                item.ct601_call_rank_results.cm65_call_ranks &&
                item.ct601_call_rank_results.cm65_call_ranks.rank_name;
            let cm65_call_rank_name2 =
                item.ct601_call_rank_results &&
                item.ct601_call_rank_results.cm65_call_ranks_2 &&
                item.ct601_call_rank_results.cm65_call_ranks_2.rank_name;
            if (cm64_call_result_name) return_string += cm64_call_result_name;
            if (cm65_call_rank_name) return_string += `・${cm65_call_rank_name}`;
            if (cm65_call_rank_name2) return_string += `・${cm65_call_rank_name2}`;
            item.call_result = return_string; 
        })
        return result

    }


    onRadioChange (event, param) {
        super.onRadioChange(event, param);
        // let value = (event && event.target && event.target.value) ? event.target.value : "";

    }

    // 通話履歴大量ダウンロード用モーダル表示
    async downloadModalShow (searchCount) {
        try {
            const setData = {
                modalType: "callHistoryDownload",
                fileName: this.getCsvFileName(),
                searchCount: searchCount,
                show: true
            }
            
            this.setState(setData);
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault()
        let setData = null;
        try {
            switch (modalType) {
                case "download":
                    setData = {
                        param: param && param
                            ? param
                            :"",
                        download_link: param && param.value
                            ? param.value
                            :"",
                        downloadFileType: this.downloadFileType,
                        downloadFileTypeNoMp3: this.downloadFileTypeNoMp3,
                    }
                    if (param) {
                        setData.file_type = param.file_type
                            ? param.file_type
                            : this.downloadFileType[0].value
                    }
                    break;
                case "callRecordingPlay":
                    let ct87_id = param.original.ct87_voice_log_to_text && param.original.ct87_voice_log_to_text.id;
                    let ct601_id = param.original.ct601_call_rank_results && param.original.ct601_call_rank_results.id;
                    let ct60_id = param.original.id;
                    let voice_log_url = param.original.voice_log_url;
                    let selected = null;
                    let call_result_list = (await this.getCallResultList(param.original.cm12_companies.id, param.original.ct62_assgin_external_department_infos.cm61_external_numbers.id)).data;
                    let [call_rank_1_list, call_rank_2_list] = param.row.ct601_call_rank_results && param.row.ct601_call_rank_results.cm64_id 
                        ? await this.getCallRankList(param.row.ct601_call_rank_results.cm64_id) 
                        : [[], []];
                    let call_result_rank_data = (await this.getCallResultRankInfo(ct60_id)).data;
                    
                    let call_result_data = call_result_rank_data.length > 0 &&
                        call_result_rank_data[0].cm64_call_results ?
                        {
                            label: call_result_rank_data[0].cm64_call_results.call_result_name,
                            value: call_result_rank_data[0].cm64_call_results
                        } :
                        null;
                    let call_rank_1_data = call_result_rank_data.length > 0 &&
                        call_result_rank_data[0].cm65_call_ranks ?
                        {
                            label: call_result_rank_data[0].cm65_call_ranks.rank_name,
                            value: call_result_rank_data[0].cm65_call_ranks
                        } :
                        null;
                    let call_rank_2_data = call_result_rank_data.length > 0 &&
                        call_result_rank_data[0].cm65_call_ranks_2 ?
                        {
                            label: call_result_rank_data[0].cm65_call_ranks_2.rank_name,
                            value: call_result_rank_data[0].cm65_call_ranks_2
                        } :
                        null;
    
                    if (ct87_id) {
                        const res = await this.getModalData(ct87_id);
                        selected = res.data;
                    }
                    
                    setData = {
                        ct60_id,
                        cm12_id: param.original.cm12_companies.id,
                        ct601_id,
                        voice_log_url,
                        call_result_list,
                        call_rank_1_list,
                        call_rank_2_list,
                        call_result_data,
                        call_rank_1_data,
                        call_rank_2_data,
                        voice_alert_flag: false,
                        voice_alert_message: "",
                        voice_edit_flag: [],
                        message_box_data: [],
                        message_box_index: 0, 
                        auto_scroll_flag: false,
                        keyword_index_arr: [],
                        searching_index: 0,
                        element_index: 0,
                        selected,
                        keywordHitCount: 0,
                        voice_text_null_flag: false,
                        isNotAnswered: param.isNotAnswered ? true : false
                    }
                    break;
                default:
                    break;
            }
    
            setData = {
                ...setData,
                show: true,
                modalType
            }
            
            this.setState(setData);
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    onClickHandle = modalType => async(event) => {
        let {
            download_link,
            file_type
        } = this.state

        switch (modalType) {
            case 'download':
                // 音声ダウンロード
                // ダウンロードする時に画面内で他の動作ができないようにする
                this.toggleBlocking();
                this.setState({download_end_flag: false});
                try{

                    let download_link_file_type = download_link.slice(0,-3).concat(file_type);
                    this.downloadData(download_link_file_type);
                    this.setState({
                        show: false
                    })
                } catch (e) {
                    alert(this.getErrorString(e.response.data));
                }
                break
            case "callHistoryDownload":
                // 通話履歴ダウンロード
                this.setState({show: false})
                this.blockUI();
                this.setState({download_end_flag: false});
                try {
                    const conditionDisplay = this.formatDisplayConditions(
                        this.state.filtered
                    );

                    const defaultFileName = this.getCsvFileName();

                    // 通話履歴ダウンロードを非同期実行
                    await this.ascAxios('post', `${this.reactContainerPath}/startDownload`,
                        {
                            company_id: this.props.userInfo.company_id,
                            department_id: this.props.userInfo.department_id,
                            user_id: this.props.userInfo.cm15_id,
                            download_type: "call_history",
                            file_name: this.state.fileName || defaultFileName,
                            condition: this.state.filtered,
                            condition_display: conditionDisplay
                        }
                    );

                    alert(this.props.langText.Message.CallContentDownloadStartMessage);
                } catch (e) {
                    console.log(e)
                    return this.showErrorObjectMesssage(e, "CreateFileFailed");
                }
                break
            default:
                alert(this.getErrorString({code: 'modal'}))
        }

    }
    validationHandle = param => {
        let nowState = null
        let {
            file_type,
        } = this.state

        switch(param) {
            case "download":
                nowState = (
                    file_type )
                break
            case "callHistoryDownload":
                nowState = this.state.searchCount !== 0;
                break;
            default:
        }
        return nowState
    }

    getCsvFileName() {
        let date = this.getFilterData("start_datetime");
        let file_name = "";
        if (!!date) {
            file_name = moment(this.getFilterData("start_datetime")).format("YYYYMMDD") + "_";
        }
        let stringArr = [];
        stringArr.push(this.state.filtered.find(row => {return row.id === "dest_tel_no";}));
        stringArr.forEach(row => {
            if (row) {
                let value = row.value.value ? row.value.value : row.value;
                file_name = file_name + value + "_";
            }
        })

        let nowTime = moment().utc().add(9, 'h').format("YYYYMMDDhhmmss");

        return file_name + this.props.langText.Body.CallHistoryCsvName + "_" + nowTime;
    }
 
    setTimeCheck() {
        let flag = true;
        let start_datetime = this.getFilterData("start_datetime"),
            end_datetime = this.getFilterData("end_datetime"),
            start_ji = this.getFilterData("start_ji").value,
            start_hun = this.getFilterData("start_hun").value,
            end_ji = this.getFilterData("end_ji").value,
            end_hun = this.getFilterData("end_hun").value;
        
        if(start_datetime && end_datetime && start_datetime.format("YYYYMMDD") === end_datetime.format("YYYYMMDD")){
            if (start_ji > end_ji) {
                flag = false;
            } else if (start_ji === end_ji && (start_hun > end_hun)) {
                flag = false;
            }
        }
        
        return flag;
    }

    // オペレーター名の検索欄の表示・非表示設定をするため、オペレーター管理の閲覧権限を確認する
    checkOperatorPermission() {
        
        let operatorPermission = null
        let show = null;

        if(this.props.userInfo && this.props.userInfo.permission_json_data){
            operatorPermission = this.props.userInfo.permission_json_data.find(
                (permission) => permission.controller_id === "Operator"
            );
            if(operatorPermission && operatorPermission.read){
                show = true
            }
        }

        return show;

    }

    // モーダルデータ取得
    getModalData = async (ct87_id) => {
        return await this.ascAxios("post", `${this.reactContainerPath}/modal`, { id: ct87_id });
    }

    // フィルターのキーワード加工
    // 表示する条件文字加工
    formatDisplayConditions = (filtered) => {
        const displayOrder = [
            "id",
            "cm12_id",
            "start_datetime",
            "start_ji",
            "start_hun",
            "end_datetime",
            "end_ji",
            "end_hun",
            "operator_name",
            "dest_tel_no",
            "inout_type",
            "disposition",
            "extension_number",
            "display_number",
            "call_result_rank"
        ];

        let filter = _.cloneDeep(filtered);
        let display = "";


        const sorted = filter.sort((a, b) => {
            const indexA = displayOrder.indexOf(a.id);
            const indexB = displayOrder.indexOf(b.id);
            return indexA - indexB;
        });

        for (const row of sorted) {
            switch (row.id) {
                case "id":
                    display += `${this.props.langText.Body.CallHistoryId}：${row.value}\n`;
                    break;
                case "cm12_id":
                    display += `${this.props.langText.Body.CompanyName}：${row.value.label}\n`;
                    break;
                case "display_number":
                    const displayNumbers = row.value.map(number => number.value)
                    display += `${this.props.langText.Body.ExternalNumber}：${[...displayNumbers]}\n`;
                    break;
                case "dest_tel_no":
                    display += `${this.props.langText.Body.DestUserNumber}：${row.value}\n`;
                    break;
                case "start_datetime":
                    display += `${this.props.langText.Body.ExtensionStartDateTime}：${this.getMomentTime({date: row.value, format: "YYYY/MM/DD"})}`;
                    break;
                case "start_ji":
                    display += ` ${row.value.label}:`;
                    break;
                case "start_hun":
                    display += `${row.value.label}\n`;
                    break;
                case "end_datetime":
                    display += `${this.props.langText.Body.ExtensionEndDateTime}：${this.getMomentTime({date: row.value, format: "YYYY/MM/DD"})}`;
                    break;
                case "end_ji":
                    display += ` ${row.value.label}:`;
                    break;
                case "end_hun":
                    display += `${row.value.label}\n`;
                    break;
                case "operator_name":
                    const operators = row.value.map(operator => operator.label)
                    display += `${this.props.langText.Body.OperatorName}：${[...operators]}\n`;
                    break;
                case "disposition":
                    const dispositions = row.value.map(disposition => disposition.label)
                    display += `${this.props.langText.Body.CallHistory}：${[...dispositions]}\n`;
                    break;
                case "extension_number":
                    display += `${this.props.langText.Body.ExtensionNumber}：${row.value}\n`;
                    break;
                case "inout_type":
                    let inoutValue = row.value === "all" ? this.props.langText.Body.All 
                        : row.value.value === "in" ? this.props.langText.Body.Inbound
                        : this.props.langText.Body.Call;
                    display += `${this.props.langText.Body.InOutType}：${inoutValue}\n`;
                    break;
                case "call_result_rank":
                    const callResultLabel = [];
                    const callRank1Label = [];
                    const callRank2Label = [];
                    row.value.forEach(result => {
                        switch(result.value.rank_type) {
                            case 0:
                                callResultLabel.push(result.label)
                                break;
                            case 1:
                                callRank1Label.push(result.label)
                                break;
                            case 2:
                                callRank2Label.push(result.label)
                                break;
                            default:
                                break;
                        }
                    });

                    if (callResultLabel.length > 0) {
                        display += `${this.props.langText.Body.CallResult}：${callResultLabel.join(",")}\n`;
                    }
                    if (callRank1Label.length > 0) {
                        display += `${this.props.langText.Body.CallRankFirst}：${callRank1Label.join(",")}\n`;
                    }
                    if (callRank2Label.length > 0) {
                        display += `${this.props.langText.Body.CallRankSecond}：${callRank2Label.join(",")}\n`;
                    }
                    break;
                default:
                    break;
            }
            // idが指定されている場合は他の条件を無視する
            if (row.id === "id") return display;
        };
        return display;
    }

    // コール結果・ランクリスト取得
    getCallResultRankListInfo = async (company_id) => {
        try {
            const result = await this.ascAxios("post", `${this.reactContainerPath}/getCallResultListByCompanyId`, {
                company_id
            });
            const default_select = {
                value: {cm64_id: 0, cm65_id: 0, level: 0, rank_type: 0},
                label: this.props.langText.SelectOption.CallResultUnRegistered
            }

            this.setState({ call_result_rank_select: [default_select, ...result.data]});
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    render() {
        // コール結果・ランク
        const customOptionRenderer = ({ checked, option, onClick }) => {
            let margin = "";
            if (option.value.level === 1) margin = "10px";
            else if (option.value.level === 2) margin = "20px";
            
            return (
                <div
                    style={{
                        marginLeft: margin,
                        display: 'flex',
                        alignItems: 'center', 
                    }}
                >
                    <input type="checkbox" onClick={onClick} checked={checked} readOnly style={{ marginRight: '10px', cursor: "pointer" }} />
                    {option.label}
                </div>
            );
        };
        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <ASToast />
                <div className="CallHistory">
                    <Row>
                        {/* OEM以上は会社選択表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col xs={12} md={12}>
                                <Col sm={4} md={2} lg={1}>
                                    <div className="margin-top-05">{this.props.langText.Body.CompanyName}</div>
                                </Col>
                                <Col sm={12} md={12} lg={3}>
                                    <Select
                                        value = {this.getFilterData("cm12_id")}
                                        onChange = {
                                            e => this.onChange(e, "cm12_id")
                                        }
                                        options={this.state.companySelect}
                                        isClearable = {true}
                                        placeholder = {!this.state.companySelect[0] ?
                                            <Glyphicon glyph="refresh" className="spinning"/> : this.props.langText.SelectOption.Placeholder}/>
                                </Col>
                            </Col>
                            :
                            null
                        }
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.StartDate}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <DatePicker
                                    onChange = {e => this.onChange(e, "start_datetime")}
                                    selected = {this.getFilterData("start_datetime")? this.getFilterData("start_datetime"): null}
                                    dateFormat = "YYYY-MM-DD"
                                    className = "form-control"
                                    todayButton = {this.props.langText.Body.Today}
                                    isClearable = {true}
                                    placeholderText = {this.props.langText.Body.StartDate}
                                    maxDate={this.getFilterData("end_datetime")?this.getFilterData("end_datetime"):null}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.StartTime}</div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "start_ji"
                                    value = {this.getFilterData("start_ji")}
                                    onChange = {
                                        e => this.onChange(e, "start_ji")
                                    }
                                    options={this.hourShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "start_hun"
                                    value = {this.getFilterData("start_hun")}
                                    onChange = {
                                        e => this.onChange(e, "start_hun")
                                    }
                                    options={this.minuteShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.Template}</div>
                            </Col>
                            <Col sm={12} md={3} lg={2} className="margin-top-03 template-dropdown">
                                <DropdownButton
                                    id = 'dropdown'
                                    title={<span className="btn-text">{this.props.langText.Body.Template}</span>}
                                    className="margin-right-05"
                                    onSelect={this.onSearchDataChange}>
                                    <MenuItem eventKey="incoming_no_answer">{this.props.langText.SelectOption.IncomingNoAnswer}</MenuItem>
                                    <MenuItem eventKey="anonymous">{this.props.langText.SelectOption.Anonymous}</MenuItem>
                                    <MenuItem eventKey="clear">{this.props.langText.SelectOption.Clear}</MenuItem>
                                </DropdownButton>
                            </Col>
                            {/* ID検索追加 */}
                            <Col sm={4} md={2} lg={1} className="padding-right-0 id-search">
                                <div className="margin-top-05">
                                    <SetDataWithSpeechBubble
                                    displayData={this.props.langText.Body.Id}
                                    speechBubbleData = {this.props.langText.Message.IdOnlySearch}
                                    infoIconDisplayFlag = {true}
                                    infoIconType = {faIcon.faInfoCircle}
                                    infoIconClassName = "margin-left-05"
                                    infoIconColor = "silver"
                                    infoIconSize = "lg"/>
                                </div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <FormControl
                                    onChange = {
                                        e => this.onChange(e, "id")
                                    }
                                    value = {this.getFilterData("id")}
                                />
                            </Col>
                            
                        </Col>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.EndDate}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <DatePicker
                                    onChange = {e => this.onChange(e, "end_datetime")}
                                    selected = {this.getFilterData("end_datetime")? this.getFilterData("end_datetime"): null}
                                    dateFormat = "YYYY-MM-DD"
                                    className = "form-control"
                                    todayButton = {this.props.langText.Body.Today}
                                    isClearable = {true}
                                    placeholderText = {this.props.langText.Body.EndDate}
                                    minDate = {this.getFilterData("start_datetime")?this.getFilterData("start_datetime"):null}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.EndTime}</div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "end_ji"
                                    value = {this.getFilterData("end_ji")}
                                    onChange = {
                                        e => this.onChange(e, "end_ji")
                                    }
                                    options={this.hourShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "end_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "end_hun"
                                    value = {this.getFilterData("end_hun")}
                                    onChange = {
                                        e => this.onChange(e, "end_hun")
                                    }
                                    options={this.minuteShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "end_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            {/* オペレーター管理の閲覧権限があればオペレーター名の検索欄を表示する*/}
                            {this.checkOperatorPermission()
                            ?
                            <Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.OperatorName}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <MultiSelect
                                    hasSelectAll = {true}
                                    key = "operator_name"
                                    value = {this.state.operator_name}
                                    onChange = {
                                        e => this.onChange(e, "operator_name")
                                    }
                                    options = {this.state.operatorArr}
                                    overrideStrings = {{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder_allOperator,
                                        allItemsAreSelected: this.props.langText.SelectOption.Placeholder_allOperator,
                                        selectAll: this.props.langText.SelectOption.Placeholder_allOperator,
                                        selectAllFiltered: this.props.langText.SelectOption.Placeholder_searchAllResult,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>
                            </Col>
                            :
                            null
                        }
                        </Col>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardPartner}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <FormControl
                                    onChange = {
                                        e => this.onChange(e, "dest_tel_no")
                                    }
                                    value = {this.getFilterData("dest_tel_no")}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.InOutType}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <Select
                                    value = {this.getFilterData("inout_type")}
                                    onChange = {
                                        e => this.onChange(e, "inout_type")
                                    }
                                    options = {this.inoutSelect}
                                    isClearable = {true}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_allResult}
                                />
                            </Col>

                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CallHistory}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <MultiSelect
                                    hasSelectAll = {true}
                                    key = "disposition"
                                    value = {this.state.disposition}
                                    onChange = {
                                        e => this.onChange(e, "disposition")
                                    }
                                    options = {this.callResultSelect}
                                    overrideStrings = {{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder_allResult,
                                        allItemsAreSelected: this.props.langText.SelectOption.Placeholder_allResult,
                                        selectAll: this.props.langText.SelectOption.Placeholder_allResult,
                                        selectAllFiltered: this.props.langText.SelectOption.Placeholder_searchAllResult,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>

                        </Col>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardExternal}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <FormControl
                                    onChange={e => this.onChange(e, "extension_number")}
                                    value = {this.getFilterData("extension_number")}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardExtension}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <MultiSelect
                                    hasSelectAll = {true}
                                    key = "display_number"
                                    value = {this.state.display_number}
                                    onChange = {
                                        e => this.onChange(e, "display_number")
                                    }
                                    options = {this.state.externalarr}
                                    overrideStrings = {{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder_defaultexternal,
                                        allItemsAreSelected: this.props.langText.SelectOption.Placeholder_defaultexternal,
                                        selectAll: this.props.langText.SelectOption.Placeholder_defaultexternal,
                                        selectAllFiltered: this.props.langText.SelectOption.Placeholder_searchAllResult,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>
                            {
                                this.state.search_company_parameter_call_result_rank && this.state.search_company_parameter_call_result_rank.parameter_value === "Y" &&
                                <>
                                    <Col sm={4} md={2} lg={1}>
                                        <div className="margin-top-05">{this.props.langText.Body.CallResult}</div>
                                    </Col>
                                    <Col sm={12} md={4} lg={2}>
                                        <MultiSelect
                                            key="call_result_rank"
                                            value={this.state.call_result_rank}
                                            onChange={
                                                e => this.onChange(e, "call_result_rank")
                                            }
                                            options={this.state.call_result_rank_select}
                                            overrideStrings={{
                                                selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                                allItemsAreSelected: this.props.langText.SelectOption.Placeholder_allResult,
                                                selectAll: this.props.langText.SelectOption.Placeholder_allResult,
                                                selectAllFiltered: this.props.langText.SelectOption.Placeholder_searchAllResult,
                                                search: this.props.langText.Body.Search,
                                            }}
                                            ItemRenderer={customOptionRenderer}
                                            disableSearch
                                        />
                                    </Col>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-03 flex-style">
                            <Col sm={12} md={4} lg={2}>
                                <Button
                                    id="customer-insert"
                                    className="searchBtn"
                                    bsStyle='primary'
                                    bsSize='sm'
                                    onClick={e => this.onSearch(e)}
                                    disabled = {this.state.searchValidation||this.state.loading}
                                >
                                    {this.props.langText.Body.Search}
                                </Button>
                                <label className="margin-top-05 padding-left-10">{this.props.langText.Body.SearchResult}</label>
                                <label className="margin-top-05 result-count">{this.state.count ? this.state.count: 0}{this.props.langText.Body.Count}</label>
                            </Col>
                            <Col sm={12} md={4} lg={4}>
                                <label className="margin-top-05 search-message">{this.state.message}</label>
                            </Col>
                            <Col sm={12} md={4} lg={3} className="text-right margin-left-auto">
                                <CSVLink
                                    data={this.state.csvdata}
                                    headers={this.moldCsvHeader()}
                                    filename={this.getCsvFileName()}
                                    className="hidden"
                                    ref={this.csvLink}
                                />
                                <Button
                                    id="callhistory-download"
                                    className='table-button'
                                    bsStyle='default'
                                    bsSize='sm'
                                    onClick={this.callHistoryCsvDL}
                                    disabled = {this.state.searchValidation||this.state.loading}
                                >
                                    {this.props.langText.Body.CallHistoryDownload}
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05">
                            <CommonTable
                                talbeRef={this.table}

                                style={{height: this.props.tableHeight-210}}
                                manual="manual"
                                columns={this.state.columns}
                                data={this.state.data}
                                pages={this.state.pages}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                searchData={{"callHistoryHash": sessionStorage.getItem("callHistoryHash")}}
                                loading={this.state.loading}
                                defaultSorted={[{id: "start_datetime", desc: true}]}
                                onFetchData={(state, instance) => {
                                    this.setState({ bookmark_flag: {}, bookmark_id: {} });
                                    this.fetchData(state, instance);
                                }}
                                filtered={this.state.filtered}
                                hidden_filtered={this.state.hidden_filtered}
                                previousText={this.props.langText.Table.PreviousText}
                                nextText={this.props.langText.Table.NextText}
                                loadingText={this.props.langText.Table.LoadingText}
                                noDataText={this.props.langText.Table.NoDataText}
                                rowsText={this.props.langText.Table.RowsText}/>
                        </Col>
                    </Row>
                    <CallHistoryModal
                        state = {this.state}
                        userName = {this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onIntChange = {this.onIntChange}
                        onKeyDownIntCheck = {this.onKeyDownIntCheck}
                        onSelectChange = {this.onSelectChange}
                        onMultiSelectChange = {this.onMultiSelectChange}
                        onCheckBoxChange = {this.onCheckBoxChange}
                        onRadioChange = {this.onRadioChange}
                        validationHandle   = {this.validationHandle}
                        getinboundGroupName = {this.getinboundGroupName}
                        currentPermission = {this.props.currentPermission}
                        onPlayHandle = {this.onPlayHandle}
                        updateVoiceData = {this.updateVoiceData}
                        copyData = {this.copyData}
                        getCallRankList = {this.getCallRankList}
                        updateCallResultRankData = {this.updateCallResultRankData}
                    />
                </div>
            </BlockUi>
        );
    }
}
