import React from "react";
import {Panel, FormControl, Col, Row} from "react-bootstrap";
import BusinessSetting from "./BusinessSetting";
import Component from "../../components/AscComponent";
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import ASSelect from "../Elements/Select/Select";
import DatePicker from "react-datepicker";
import MultiSelect from "../Elements/Select/MultiSelect";
import Type from "./Type.json";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/ja";
import BusinessCalendarIrrBatchCheckModal from "./BusinessCalendarIrrBatchCheckModal";
import * as GlobalConst from "../../components/AscConstants";
moment.locale('ja');


export default class BusinessCalendarIrrBatchModal extends Component {
    constructor(props) {
        super(props);
        this.day_string = this.props.dayString;
        this.action_string = this.props.actionString;
        this.business_string = this.props.businessString;

        this.state = {
            show: false,
            modalType: "irregularBatchSetting",
            action_string: {...this.action_string},
            company_select: [],
            script_select: [],
            voice_select: [],
            irr_select: [],

            irr_calendar: [],

            company_id: this.props.state.company_id,
            company_name: this.props.state.company_name,
            irregular_start_date: moment(),
            irregular_end_date: moment(),
            irregular_dates_arr: [this.getMomentTime({date: moment(), format: this.props.langText.Body.DateFormat})],
            irregular_dates_limit_cnt: 7,
            irregular_date_valid_flag: true,
            type: null,
            business_start_hour: null,
            business_end_hour: null,
            business_hour_action_flag: null,
            business_hour_action_detail: null,
            business_hour_voice_file_id: null,
            non_business_hour_action_flag: null,
            non_business_hour_action_detail: null,
            non_business_hour_voice_file_id: null,
            exception_play_start_hour: null,
            exception_play_end_hour: null,
            exception_play_voice_file_id: null,
            exception_play_flag: false,
            setting_exception_time_err_flag: false,
        };
    }

    async componentDidMount(id) {
        try {
            this.blockUI();
            let company_id = id || this.state.company_id;
            let script_flag = false;
            let action_string = {...this.action_string};

            if (company_id) {
                let param = (await this.getParamenter(company_id)).data;
                let script_obj = param.find(row => row.function_id === "BUSINESS_CALENDAR" && row.parameter_id === "SCRIPT_SELECT");
                if (script_obj && script_obj.parameter_value === "Y") script_flag = true;
            }

            if (!script_flag) delete action_string[Type.ACTION.SCRIPT];

            this.setState({
                company_select: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) ? (await this.getCommonCompanySelect(this.props.reactContainerPath)).data : [],
                script_select: script_flag && company_id ? (await this.getScriptSelect(company_id)).data : [],
                voice_select: company_id ? (await this.getVoiceSelect(company_id)).data.map(row => {
                    if (row.update_user_name) {
                        row.label += `(${this.props.langText.Body.VoiceListUpdate}${row.update_user_name})`;
                    } else if (row.entry_user_name) {
                        row.label += `(${this.props.langText.Body.VoiceListCreate}${row.entry_user_name})`;
                    }
                    return row;
                }) : [],
                irr_select: company_id ? (await this.getBusinessCalendarSelect(company_id)).data : [],
                action_string
            });
        } catch (err) {
            console.error(err);
            alert(this.showErrorObjectMesssage(err.response.data));
            this.propSetState({show: false});
        }
    }

    getParamenter = (company_id) => {
        return this.ascAxios('post', `Common/getCompanyControlParameters`, {cm12_id: company_id})
    }

    getScriptSelect = (company_id) => {
        return this.ascAxios("post", "Common/getScriptList", {container: '/BusinessCalendar', cm12_id: company_id});
    }

    getVoiceSelect = (company_id) => {
        return this.ascAxios("post", `Common/voiceFileSelectAllType`, {company_id});
    }
    
    getBusinessCalendarSelect = (company_id) => {
        return this.ascAxios("post", `BusinessCalendar/selectIrrCalendar`, {company_id});
    }

    getModalData = (param) => {
        let elems = [];

        if(param === "exception_play_setting") {
            elems.push(
                <BusinessSetting
                    key="business-setting"
                    state={this.state}
                    propSetState={this.propSetState}
                    exceptionPlaySetting={true}
                    dayString={this.day_string}
                    actionString={this.state.action_string}
                    businessString={this.business_string}
                    scriptSelect={this.state.script_select}
                    voiceSelect={this.state.voice_select}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                />
            );
        } else {
            // 会社
            if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
                elems.push(
                    <ASFieldGroup
                        key="company_id"
                        label={this.props.langText.Body.CompanyName}
                        validationState={this.validationHandle("company_id")}>
                        <ASSelect
                            value={this.state.company_id}
                            options={this.state.company_select}
                            placeholder={this.props.langText.SelectOption.Placeholder}
                            onChange={e => {
                                this.componentDidMount(e.value);
                                this.setState({
                                    company_id: e.value,
                                    company_name: e.label,
                                    type: null,
                                    business_start_hour: null,
                                    business_end_hour: null,
                                    business_hour_action_flag: null,
                                    business_hour_action_detail: null,
                                    business_hour_voice_file_id: null,
                                    non_business_hour_action_flag: null,
                                    non_business_hour_action_detail: null,
                                    non_business_hour_voice_file_id: null,
                                    irr_calendar: []
                                });
                            }}/>
                    </ASFieldGroup>
                );
            } else {
                elems.push(
                    <ASFieldGroup
                        key="company_id"
                        label={this.props.langText.Body.CompanyName}>
                        <FormControl.Static>{this.state.company_name}</FormControl.Static>
                    </ASFieldGroup>
                );
            }
    
            elems.push(
                <ASFieldGroup
                    key="irregular_date"
                    label={this.props.langText.Body.Date}
                    validationState={this.validationHandle("irregular_date")}>
                    <Row>
                        <Col xs={12} md={12}>
                            <Col md={3} lg={3}>
                                <DatePicker
                                    selected={this.state.irregular_start_date}
                                    dateFormat={this.props.langText.Body.DateFormat}  
                                    minDate = {new Date()}
                                    readOnly={false}
                                    onChange = {e => {
                                        this.setState({
                                            irregular_start_date: e,
                                            irregular_dates_arr: this.getDatesStartToLast(
                                                moment(e).format("YYYY-MM-DD"), 
                                                moment(this.state.irregular_end_date).format("YYYY-MM-DD")
                                            )
                                        });
                                    }}
                                    onKeyDown = {e => {
                                        e.preventDefault();
                                        return false;
                                    }} 
                                />
                            </Col>
                            <Col xs={1} sm={1} md={1} lg={1}>
                                <span className="calendar-Modal_TimeCss">～</span>
                            </Col>
                            <Col md={3} lg={3}>
                                <DatePicker
                                    selected={this.state.irregular_end_date}
                                    dateFormat={this.props.langText.Body.DateFormat}
                                    minDate = {this.state.irregular_start_date}
                                    maxDate = {moment(this.state.irregular_start_date).add(6, 'days')}  
                                    readOnly={false}
                                    onChange = {e => {
                                        this.setState({
                                            irregular_end_date: e,
                                            irregular_dates_arr: this.getDatesStartToLast(
                                                moment(this.state.irregular_start_date).format("YYYY-MM-DD"),
                                                moment(e).format("YYYY-MM-DD")
                                            )
                                        });
                                    }}
                                    onKeyDown = {e => {
                                        e.preventDefault();
                                        return false;
                                    }} 
                                />
                            </Col>
                        </Col>
                    </Row>
                    {this.state.validationMessage && (
                        <label className="search-message">
                            <p>{this.state.validationMessage}</p>
                        </label>
                    )}
                </ASFieldGroup>
            );
    
            elems.push(
                <ASFieldGroup
                    key="irr_calendar"
                    label={this.props.langText.Body.BusinessCalendarSelect}
                    validationState={this.validationHandle("irr_calendar")}>
                    <MultiSelect
                        key="irr_calendar"
                        controlId="irr_calendar"
                        propSetState = {this.propSetState}
                        multiSelectText = {this.props.langText.MultiSelect}
                        searchText={this.props.langText.MultiSelect.BusinessCalendarSearch}
                        options={this.state.irr_select}
                        value={this.state.irr_calendar}
                        multiSelectMessage = {this.props.langText.MultiSelect.SelectedNum}
                        multiSelectDeleteMessage = {this.props.langText.MultiSelect.SelectedNum_Delete}/>
                </ASFieldGroup>
            );
    
            elems.push(
                <BusinessSetting
                    key="business-setting"
                    state={this.state}
                    propSetState={this.propSetState}
                    dayString={this.day_string}
                    actionString={this.state.action_string}
                    businessString={this.business_string}
                    scriptSelect={this.state.script_select}
                    voiceSelect={this.state.voice_select}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                />
            );
        }
        
        return <Col sm={12}>{elems}</Col>;
    }

    onClickHandle = () => {
        this.setState({
            show: false,
        });
        this.props.onClick(this.state);
    }

    validationHandle = (param) => {
        const validTelNo = new RegExp(`[0-9]{10,11}`);
        switch(param) {
            case "company_id":
                return this.state[param] ? null : this.props.langText.Message.Validation_Select_input;
            case "irr_calendar":
                return this.state[param] && this.state[param].length ? null : this.props.langText.Message.Validation_Select_input;
            case "irregularBatchSetting":
                return this.state.company_id &&
                    this.state.irr_calendar.length &&
                    (
                        (this.state.type === Type.BUSINESS.END || this.state.type === Type.BUSINESS.DEL) || 
                        (
                            this.state.business_start_hour &&
                            this.state.business_end_hour &&
                            this.state.business_hour_action_flag &&
                            (this.state.business_hour_action_flag !== Type.ACTION.VOICE || this.state.business_hour_voice_file_id) &&
                            (this.state.business_hour_action_flag !== Type.ACTION.SCRIPT || this.state.business_hour_action_detail) &&
                            (this.state.business_hour_action_flag !== Type.ACTION.TRANSFER || validTelNo.test(this.state.business_hour_action_detail)) 
                        )
                    ) &&
                    (
                        this.state.type === Type.BUSINESS.DEL ||
                        (
                            this.state.non_business_hour_action_flag &&
                            (this.state.non_business_hour_action_flag !== Type.ACTION.VOICE || this.state.non_business_hour_voice_file_id) &&
                            (this.state.non_business_hour_action_flag !== Type.ACTION.SCRIPT || this.state.non_business_hour_action_detail) &&
                            (this.state.non_business_hour_action_flag !== Type.ACTION.TRANSFER || validTelNo.test(this.state.non_business_hour_action_detail)) 
                        )
                    ) && 
                    (
                        !this.state.exception_play_flag ||
                        (
                            this.state.exception_play_flag &&
                            this.state.exception_play_start_hour &&
                            this.state.exception_play_end_hour &&
                            this.state.exception_play_voice_file_id &&
                            this.state.setting_exception_time_err_flag
                        )
                    ) && this.state.irregular_date_valid_flag & this.checkDatesLimit(
                        this.state.irregular_start_date,
                        this.state.irregular_end_date,
                        this.state.irregular_dates_limit_cnt
                    );
            case "irregular_date":
                return this.checkDatesLimit(
                    this.state.irregular_start_date, 
                    this.state.irregular_end_date, 
                    this.state.irregular_dates_limit_cnt
                    ) ? null : this.props.langText.Message.IrrDateSetError;
            default:
                return false;
        }
    }

    render() {
        return (
            <>
                <AscModal
                    key="business-calendar-asc-modal"
                    state={this.props.state}
                    propSetState={this.props.propSetState}
                    langText={this.props.langText}
                    validationHandle={this.validationHandle}
                    customFunc={() => {
                        this.setState({
                            show: true
                        })
                    }}>
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.langText.Body.BusinessCalendarSetting}
                            </Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>
                            {this.getModalData()}
                        </Panel.Body>
                    </Panel>

                    {this.state.type !== "3" &&
                        <Panel bsStyle="info">
                            <Panel.Heading>
                                <Panel.Title>
                                    {this.props.langText.Body.exceptionPlaySetting}
                                </Panel.Title>
                            </Panel.Heading>

                            <Panel.Body>
                                {this.getModalData("exception_play_setting")}
                            </Panel.Body>
                        </Panel>
                    }
                    
                </AscModal>
                {this.state.show &&
                    <BusinessCalendarIrrBatchCheckModal
                        state={this.state}
                        propSetState={this.propSetState}
                        reactContainerPath={this.reactContainerPath}
                        currentPermission={this.props.currentPermission}
                        dayString={this.day_string}
                        actionString={this.state.action_string}
                        businessString={this.business_string}
                        voiceSelect={this.state.voice_select}
                        irrSelect={this.state.irr_select}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        boardWidth={this.props.boardWidth}
                        onClick={this.onClickHandle}/>
                }
            </>
        );
    }
}