import React, {Component} from "react"
import {
    Col,
    Button,
    FormGroup,
    FormControl,
    Glyphicon
} from "react-bootstrap"

/**
 * 複数行の入力項目(表示順・値１・値２)を表示・入力するコンポーネント
 */
export default class MultiValueDualForm extends Component {
    onFormTextChange = param => event => {
        this
            .props
            .onTextChange(
                this.props.controlId,
                param.index,
                param.form,
                event.target.value
            )
    }

    /**
     * 行の追加
     * propsのonSizeChangeに指定された関数を呼び出す
     * @param {*} event
     */
    onClickHandlePlus = event => {
        this.props.onSizeChange(this.props.controlId, "plus", null);
    }

    /**
     * 行の削除
     * 削除された行はグレーアウトされ、非活性化される
     * propsのonSizeChangeに指定された関数を呼び出す
     * @param {*} param
     */
    onClickHandleMinus = param => event => {
        this.props.onSizeChange(this.props.controlId, "minus", param);
    }

    /**
     * 削除された行を活性化状態に戻す
     * propsのonSizeChangeに指定された関数を呼び出す
     * @param {*} param
     */
    onClickHandleUndo = param => event => {
        this.props.onSizeChange(this.props.controlId, "undo", param);
    }

    multiValueFormRendering({
        controlId,
        labelCol,
        value,
        isStatic,
        placeholderOrderNum,
        placeholderValue,
        placeholderValue2,
        validationState,
        unsetValue,
        onKeyDownIntCheck
    }) {
        if (!isStatic) {
            // 各行の情報をテキストボックスとして表示する場合
            let labelCol  = 0,
                buttonCol = 1,
                orderCol = 1,
                valueCol  = 5,
                multiValueFormBody = [];

            // 各行の情報をループし、1行ずつ表示
            value.forEach((row, index) => {
                if (!row.del_flag || row.del_flag === "N") {
                    // del_flag = "N"である行の場合
                    multiValueFormBody.push(
                        <>
                            <div className="multiValueFormRow" key={index}>
                                <Col md={labelCol}>
                                </Col>
                                {/* -ボタンを表示 */}
                                <Col md={buttonCol}>
                                    <Button
                                        className = "multiValueFormMinus"
                                        onClick   = {this.onClickHandleMinus(index)}
                                    >
                                        <Glyphicon glyph="minus"/>
                                    </Button>
                                </Col>
                            </div>
                            {/* 活性化状態の行を表示 */}
                            {/* 表示順テキストボックス */}
                            <Col md={orderCol} className="multi-value-form-dual-order">
                                <FormControl
                                    className   = "multiValueFormValue"
                                    onChange    = {this.onFormTextChange({index, form:"order_num"})}
                                    value       = {row.order_num}
                                    placeholder = {placeholderOrderNum}
                                    disabled    = {false}
                                    type        = "number"
                                    onKeyDown   = {e => onKeyDownIntCheck(e)}
                                />
                            </Col>
                            {/* 値１テキストボックス */}
                            <Col md={valueCol} className="multi-value-form-dual-1">
                                <FormControl
                                    className   = "multiValueFormValue"
                                    onChange    = {this.onFormTextChange({index, form:"value1"})}
                                    value       = {row.value.firstValue}
                                    placeholder = {placeholderValue}
                                    disabled    = {false}
                                />
                            </Col>
                            {/* 値２テキストボックス */}
                            <Col md={valueCol} className="multi-value-form-dual-2">
                                <FormControl
                                    className   = "multiValueFormValue"
                                    onChange    = {this.onFormTextChange({index, form:"value2"})}
                                    value       = {row.value.secondValue}
                                    placeholder = {placeholderValue2}
                                    disabled    = {false}
                                />
                            </Col>
                        </>
                    );
                } else if ( (row.del_flag && row.del_flag) === "Y" && (!row.invisible_flag || row.invisible_flag === "N") ){
                    // del_flag = "Y" かつ、invisible_flag = "N"である行の表示処理
                    multiValueFormBody.push(
                        <>
                            <div className="multiValueFormRow" key={index}>
                                <Col md={labelCol}>
                                </Col>
                                {/* +ボタンを表示(削除状態を解除するボタン) */}
                                <Col md={buttonCol}>
                                    <Button
                                        className = "multiValueFormUndo"
                                        onClick   = {this.onClickHandleUndo(index)}
                                    >
                                        <Glyphicon glyph="plus"/>
                                    </Button>
                                </Col>
                            </div>
                            {/* 活性化状態の行を表示 */}
                            {/* 表示順テキストボックス */}
                            <Col md={orderCol} className="multi-value-form-dual-order">
                                <FormControl
                                    className   = "multiValueFormValue"
                                    onChange    = {this.onFormTextChange({index, form:"order_num"})}
                                    value       = {row.order_num}
                                    placeholder = {placeholderOrderNum}
                                    disabled    = {true}
                                    type        = "number"
                                    onKeyDown   = {e => onKeyDownIntCheck(e)}
                                />
                            </Col>
                            {/* 値１テキストボックス */}
                            <Col md={valueCol} className="multi-value-form-dual-1">
                                <FormControl
                                    className   = "multiValueFormValue"
                                    onChange    = {this.onFormTextChange({index, form:"value1"})}
                                    value       = {row.value.firstValue}
                                    placeholder = {placeholderValue}
                                    disabled    = {true}
                                />
                            </Col>
                            {/* 値２テキストボックス */}
                            <Col md={valueCol} className="multi-value-form-dual-2">
                                <FormControl
                                    className   = "multiValueFormValue"
                                    onChange    = {this.onFormTextChange({index, form:"value2"})}
                                    value       = {row.value.secondValue}
                                    placeholder = {placeholderValue2}
                                    disabled    = {true}
                                />
                            </Col>
                        </>
                    );
                }
                // del_flag = "Y" かつ invisible_flag = "Y"である行は非表示となる
            });

            return (
                <FormGroup
                    controlId={controlId}
                    className="multiValueFormBody"
                    validationState={validationState}
                >
                    {multiValueFormBody}
                </FormGroup>
            )
        } else if (isStatic) {
            // 各行の情報を固定値として表示する場合
            let tagCol   = 2,
                valueCol = 12 - (labelCol + tagCol),
                multiValueFormBody = value.length > 0
                ?
                    value.map((row, index) => {
                        return (
                            <div className="multiValueFormRow" key={index}>
                                <Col md={valueCol}>
                                    <FormControl.Static>
                                        {placeholderValue + " : " + row.value.firstValue}
                                        {" / "}
                                        {placeholderValue2 + " : " + row.value.secondValue}
                                    </FormControl.Static>
                                </Col>
                            </div>
                        )
                    })
                :
                    <Col md={valueCol}>
                        <FormControl.Static>
                            {unsetValue}
                        </FormControl.Static>
                    </Col>
            return (
                <FormGroup
                    controlId={controlId}
                    className="multiValueFormBody"
                >
                    {multiValueFormBody}
                </FormGroup>
            )
        }
    }

    render() {
        let {
            controlId,
            labelCol = 3,
            formCol = 9,
            isStatic,
            value,
            validationState,
            placeholderOrderNum,
            placeholderValue,
            placeholderValue2,
            unsetValue,
            onKeyDownIntCheck
        } = this.props

        let multiValueElement = this.multiValueFormRendering({
            controlId,
            labelCol,
            isStatic,
            value,
            validationState,
            placeholderOrderNum,
            placeholderValue,
            placeholderValue2,
            unsetValue,
            onKeyDownIntCheck
        });

        return (
            <div>
                <FormGroup>
                    <Col md={formCol}>
                        {
                            !isStatic
                            &&
                            // +ボタンを表示
                            <Button
                                className = "multiValueFormPlus"
                                onClick   = {this.onClickHandlePlus}
                            >
                            <Glyphicon glyph="plus"/>
                        </Button>
                        }
                    </Col>
                </FormGroup>

                {multiValueElement}
            </div>
        )
    }
}
