import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import "../Elements/FromGroup/form_select.css";
import { DeleteAutoCallList } from "../Elements/AscElements/AutoCallListElements";

/**
 * 自動発信リスト削除画面
 */
export default class AutoCallListDeleteModal extends Component {
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            <div className="text-danger modal-checked-message">{!this.props.state.autoCallDelFlag ? this.props.langText.Message.AutoCallListInUse : false}</div>
                        </Panel.Title>
                        {
                            (!this.props.state.autoCallDelFlag)
                            && this.props.state.listUsingData.length > 0
                            && <>
                                <div className="voice-using-list">
                                    {this.props.state.listUsingData}
                                </div>
                            </>
                        }
                    </Panel.Heading>

                    <Panel.Body>
                        <DeleteAutoCallList
                            state={this.props.state}
                            langText={this.props.langText}
                        />
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
