import { DefaultLinkWidget } from '@projectstorm/react-diagrams';
import { LinkWidget } from '@projectstorm/react-diagrams-core';
import * as React from 'react';

export class ASLinkWidget extends DefaultLinkWidget {
	render() {
		//ensure id is present for all points on the path
		var points = this.props.link.getPoints();
		var paths = [];
		this.refPaths = [];

		//draw the multiple anchors and complex line instead
		for (let j = 0; j < points.length - 1; j++) {
			paths.push(
				this.generateLink(
					LinkWidget.generateLinePath(points[j], points[j + 1]),
					{
						'data-linkid': this.props.link.getID(),
						'data-point': j,
						onMouseDown: (event) => {
                            if (event.button === 0) {
                                this.props.link.getSourcePort().getParent().linkEvent(this.props.link.getTargetPort().getParent().getOptions().number);
                            }
                            // if (event.button === 0) {
                            //     this.addPointToLink(event, j + 1);
                            // } else {
                            //     event.preventDefault();
                            // }
						}
					},
					j
				)
			);
		}

		//render the circles
		for (let i = 1; i < points.length - 1; i++) {
			paths.push(this.generatePoint(points[i]));
		}

		if (this.props.link.getTargetPort() === null) {
			paths.push(this.generatePoint(points[points.length - 1]));
		}

		return <g data-default-link-test={this.props.link.getOptions().testName}>{paths}</g>;
	}
}