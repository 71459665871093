import React from 'react';

import AscMenu from "./Menu";

export default class Play extends AscMenu {
    constructor(props) {
        super(props);
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                {this.getAudioElem({
                    key: 0,
                    audio_label: this.props.langText.Body.Audio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "audio_type",
                    audio_file: "audio_file",
                    audio_file_id: "audio_file_id",
                    audio_gen_language_original: "audio_gen_language_original",
                    audio_gen_language: "audio_gen_language",
                    audio_gen_person: "audio_gen_person",
                    audio_gen_text_original: "audio_gen_text_original",
                    audio_gen_text: "audio_gen_text",
                    synthetic_type: "synthetic_type"
                })}
            </>
        );
    }
}