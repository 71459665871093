import React from "react";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import { Button, Row, Col, Glyphicon, FormControl } from "react-bootstrap";
import * as GlobalConst from "../../components/AscConstants";
import BusinessManagementModal from "./BusinessManagementModal";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";

export default class BusinessManagement extends AscComponent {
    constructor(props) {
        super(props);
        this.modalShow = this.modalShow.bind(this);
        // モーダルに紐づける外線番号リストを取得する
        this.state = Object.assign(
            {
                // テーブルデータ
                columns: this.getColumnsData(),
                data: [],
                filtered: [],
                pages: null,
                loading: false
            },
            this.initState()
        );
        this.getAllExternalNumbers();
        this.getAllOperators();
        this.getAllDepartmentByCompanyId(
            this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)
                ? null
                : this.props.userInfo.company_id
        );
    }

    componentDidUpdate(prevProps, prevState) {
        // モーダルを閉じた際、stateデータを初期化する
        if (this.state.show === false && prevState.show === true) {
            this.setState(this.initState());
        }
    }

    initState() {
        return {
            // insert modal data
            show: false,
            modalType: "",
            business_name: "",
            company_id: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)
                ? null
                : this.props.userInfo.company_id,
            company_name: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)
                ? null
                : this.props.userInfo.company_name,
            memo: "",
            companySelect: [],
            external_numbers: [],
            externalarr: [],
            // delete modal data
            task_id: "",
            task_company_id: "",
            task_name: "",
            external_number_count: null,
            task_memo: "",
            task_external_numbers: [],
            task_operators: [],
            department_select: [],
            selected_departments: [],
            task_departments: [],
            floor_flg: this.props.userInfo.floor_flg
        };
    }

    getAllExternalNumbers() {
        this.ascAxios("post", `${this.reactContainerPath}/getAllExternalNumbers`)
            .then((res) => {
                this.setState({ all_external_numbers: res.data });
                return res.data;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAllOperators() {
        this.ascAxios("post", `${this.reactContainerPath}/getAllOperators`)
            .then((res) => {
                this.setState({ all_operators: res.data });
                return res.data;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    getAllDepartmentByCompanyId(company_id) {
        this.ascAxios("post", `${this.reactContainerPath}/getAllDepartmentByCompanyId`, {
            company_id: company_id
        })
            .then((res) => {
                this.setState({ all_task_department: res.data });
                return res.data;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    // to do: システム管理者の場合、会社情報を確認できるように仕様変更
    getColumnsData = () => {
        let itemArr = [];

        itemArr.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            width: this.props.boardWidth.id,
            Filter: () => (
                <FormControl onChange={(e) => this.onFilterChange(e.target.value, "id")} />
            )
        });
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                Filter: () => (
                    <FormControl
                        onChange={(e) =>
                            this.onFilterChange(e.target.value, "cm12_companies.company_name")
                        }
                    />
                )
            });
        }
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push({
                id: "ct23_assign_task_departments.task_department",
                Header: this.props.langText.Body.AssignDepartmentAndFloor,
                sortable: false,
                accessor: (data) => {
                    let task_departments_info = data.task_departments;
                    let row_data = [];

                    task_departments_info.forEach((data, key) => {
                        if (data !== undefined && data !== null) {
                            row_data.push(data);
                        }
                    });

                    return row_data;
                },
                Filter: () => (
                    <Select
                        menuPortalTarget={document.body}
                        menuPosition={"fixed"}
                        isMulti={true}
                        onChange={(e) => {
                            let select_options = [].slice.call(e).map((e_row) => {
                                return e_row.value;
                            });
                            this.onFilterChange(
                                select_options,
                                "ct23_assign_task_departments.cm13_departments.id"
                            );
                        }}
                        options={this.state.all_task_department}
                        placeholder=""
                    />
                ),
                Cell: (data) => {
                    let task_departments_info = data.row._original.task_departments;
                    let row_data = [];

                    task_departments_info.forEach((data, key) => {
                        if (data !== undefined && data !== null) {
                            row_data.push(<p key={key}>{data}</p>);
                        }
                    });

                    return row_data;
                }
            });
        }
        itemArr.push({
            Header: this.props.langText.Body.BusinessName,
            accessor: "business_name",
            Filter: () => (
                <FormControl
                    onChange={(e) => this.onFilterChange(e.target.value, "business_name")}
                />
            )
        });
        itemArr.push({
            id: "ct21_assign_task_external_numbers.external_number",
            Header: this.props.langText.Body.ExternalNumberCount,
            width: this.props.boardWidth.large,
            accessor: (data) => {
                let accessor_slice = [].slice
                    .call(data.task_external_numbers)
                    .map(
                        (row) =>
                            row.ct21_assign_task_external_number.cm61_external_numbers
                                .display_number
                    );
                return accessor_slice;
            },
            Filter: () => (
                <Select
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isMulti={true}
                    onChange={(e) => {
                        let select_options = [].slice.call(e).map((e_row) => {
                            return e_row.value;
                        });
                        this.onFilterChange(
                            select_options,
                            "ct21_assign_task_external_numbers.cm61_external_numbers.id"
                        );
                    }}
                    options={this.state.all_external_numbers}
                    placeholder=""
                />
            ),
            Cell: (data) => data.row._original.external_number_count
        });
        itemArr.push({
            id: "ct22_assign_task_extension_numbers.operator_id",
            Header: this.props.langText.Body.BusinessOperatorCount,
            width: this.props.boardWidth.large,
            accessor: (data) => {
                let accessor_slice = [].slice
                    .call(data.task_operators)
                    .map(
                        (row) =>
                            row.ct22_assign_task_extension_number.cm16_operators
                                .operator_id
                    );
                return accessor_slice;
            },
            Filter: () => (
                <Select
                    menuPortalTarget={document.body}
                    menuPosition={"fixed"}
                    isMulti={true}
                    onChange={(e) => {
                        let select_options = [].slice.call(e).map((e_row) => {
                            return e_row.value;
                        });
                        this.onFilterChange(
                            select_options,
                            "ct22_assign_task_extension_numbers.cm16_operators.id"
                        );
                    }}
                    options={this.state.all_operators}
                    placeholder=""
                />
            ),
            Cell: (data) => data.row._original.operator_count
        });
        itemArr.push({
            Header: this.props.langText.Body.Memo,
            accessor: "memo",
            Filter: () => (
                <FormControl onChange={(e) => this.onFilterChange(e.target.value, "memo")} />
            )
        });
        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.LargeControl,
            Cell: (data) => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            onClick={(event) => this.modalShow(event, "update", data.row._original)}
                            className="control-button">
                            <Glyphicon glyph="pencil" />
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={(event) => this.modalShow(event, "read", data.row._original)}
                            className="control-button">
                            <Glyphicon glyph="eye-open" />
                        </Button>
                    );
                }
                rowData.push(
                    <Button
                        key={3}
                        bsSize="xsmall"
                        onClick={this.displayShow(data.row._original)}
                        className="control-button">
                        <Glyphicon glyph="star-empty" />
                    </Button>
                );

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            onClick={(event) => this.modalShow(event, "delete", data.row._original)}
                            className="control-button">
                            <Glyphicon glyph="minus" />
                        </Button>
                    );
                }

                return <Row className="text-center">{rowData}</Row>;
            }
        });

        return itemArr;
    };

    // to do: 文字の順番通り探すのではなく、全体文書に含まれているのかを判断するようにロジックを変更
    onFilterChange = (value, accessor) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex((row) => row.id === accessor);

        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }
        if (
            accessor === "id" ||
            accessor === "cm12_companies.company_name" ||
            accessor === "business_name"
        ) {
            value = this.escapeHtml(value);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: accessor,
                value
            });
        }
        this.table.current.state.page = 0;

        this.setState({
            filtered: my_filtered
        });

        setTimeout(() => {
            this.reactTableRefresh();
        }, 300);
    };

    async modalShow(event, modalType, param) {
        try {
            event.preventDefault();
            this.setState({
                modalType: modalType,
                show: true
            });

            switch (modalType) {
                case "insert":
                    if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                        await this.setCommonCompanySelect(this.reactContainerPath);
                    }
                    if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                        await this.setCommonDepartmentSelect(this.state.company_id);
                    }
                    if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
                        if (this.state.floor_flg) {
                            // フロアがある拠点管理者の場合配下のフロアを選択できるようにする
                            if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                                await this.setDepartmentselectByDepartmentId(
                                    this.props.userInfo.department_id
                                );
                            } else {
                                await this.setCommonFloorSelect({
                                    parent_department_id: this.props.userInfo.parent_department_id
                                });
                            }
                        } else {
                            await this.setDepartmentselectByDepartmentId(
                                this.props.userInfo.department_id
                            );
                        }
                    }
                    await this.getExternalNumbers(this.state.company_id);
                    break;
                case "update":
                    await this.getExternalNumbers(param.cm12_id, param.id);
                    await this.getTaskExternalNumbers(param, modalType);
                    await this.getTaskDepartments(param);
                    this.setState({
                        company_id: param.cm12_id,
                        company_name: param.cm12_companies.company_name,
                        business_name: param.business_name,
                        memo: param.memo,
                        task_id: param.id,
                        task_company_id: param.cm12_id
                    });
                    if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
                        if (this.state.floor_flg) {
                            // フロアがある拠点管理者の場合配下のフロアを選択できるようにする
                            if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
                                await this.setDepartmentselectByDepartmentId(
                                    this.props.userInfo.department_id
                                );
                            } else {
                                await this.setCommonFloorSelect({
                                    parent_department_id: this.props.userInfo.parent_department_id
                                });
                            }
                        } else {
                            await this.setDepartmentselectByDepartmentId(
                                this.props.userInfo.department_id
                            );
                        }
                    } else {
                        await this.setCommonDepartmentSelect(param.cm12_id);
                    }
                    break;
                case "read":
                    await this.getTaskExternalNumbers(param, modalType);
                    await this.getTaskOperatorIds(param);
                    await this.getTaskDepartments(param);
                    this.setState({
                        task_id: param.id,
                        task_company_id: param.cm12_id,
                        company_name: param.cm12_companies.company_name,
                        task_name: param.business_name,
                        task_memo: param.memo
                    });
                    break;
                case "delete":
                    await this.getTaskExternalNumbers(param, modalType);
                    await this.getTaskOperatorIds(param);
                    await this.getTaskDepartments(param);
                    this.setState({
                        task_id: param.id,
                        task_company_id: param.cm12_id,
                        company_name: param.cm12_companies.company_name,
                        task_name: param.business_name,
                        external_number_count: param.external_number_count,
                        task_memo: param.memo
                    });
                    break;
                default:
                    break;
            }
        } catch (err) {
            this.unblockUI();
            this.showErrorObjectMesssage(err, "ProcessingFailedError");
        }
    }

    // 業務名、会社情報、外線番号リストの有効チェック
    validationHandle = (param) => {
        let validation_flag = false;

        switch (param) {
            case "insert":
                validation_flag = this.state.business_name && this.state.company_id;
                break;
            case "update":
                validation_flag =
                    this.state.business_name &&
                    this.state.company_id &&
                    this.state.task_id &&
                    this.state.task_company_id;
                break;
            case "delete":
                validation_flag = this.state.task_id && this.state.task_company_id;
                break;
            default:
                break;
        }

        return validation_flag;
    };

    // モーダルに紐づける外線番号リストを取得する
    getExternalNumbers = (cm12_id, task_id) => {
        this.ascAxios("post", `Common/getallExternal`, {
            cm12_id: cm12_id,
            container: this.reactContainerPath
        })
            .then((result) => {
                let state_data = [...this.state.data];
                let filtered_data = [];
                let task_external_arr = [];

                state_data.map((data_row) => {
                    if (task_id) {
                        if (task_id !== data_row.id) {
                            data_row.task_external_numbers.map((task_external) => {
                                task_external_arr.push(task_external.dist_cm61_id);
                            });
                        }
                    } else {
                        data_row.task_external_numbers.map((task_external) => {
                            task_external_arr.push(task_external.dist_cm61_id);
                        });
                    }
                });

                filtered_data = result.data.filter(
                    (result_row) =>
                        task_external_arr.findIndex(
                            (task_external_row) => task_external_row === result_row.id
                        ) === -1
                );

                const data = filtered_data.map((row) => {
                    let label = (
                        <div>
                            <span>{row.label}</span>
                            {
                                row.analysis &&
                                <FontAwesomeIcon 
                                    className="margin-left-05"
                                    icon={faIcon.faChartPie}
                                />
                            }
                        </div>
                    );

                    return {
                        label,
                        value: row.id,
                        analysis: row.analysis
                    };
                });
                this.setState({ externalarr: data });
                return data;
            })
            .catch((err) => {
                alert(this.getErrorString(err.response.data));
            });
    };

    displayShow = (display_data) => (event) => {
        this.props.historyPush({
            pathname: "BusinessManagement/SkillManagement",
            state: { display_data: display_data }
        });
    };

    // 閲覧、削除の際は外線番号を表示し、編集の際はもともと選択された外線番号情報を取得する
    getTaskExternalNumbers(param, modalType) {
        this.ascAxios("post", `${this.reactContainerPath}/getTaskExternalNumbers`, {
            task_id: param.id,
            task_company_id: param.cm12_id
        })
            .then((res) => {
                if (modalType === "read" || modalType === "delete") {
                    this.setState({
                        task_external_numbers: res.data.map((row) => {
                            return {
                                id: row.id,
                                tag: row.tag,
                                value: row.value
                            };
                        })
                    });
                } else if (modalType === "update") {
                    this.setState({
                        external_numbers: res.data.map((row) => {
                            return row.id;
                        })
                    });
                    this.setState({
                        old_external_numbers: this.state.external_numbers
                    });
                }
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // 業務に割り当てられた拠点情報を取得
    getTaskDepartments(param) {
        this.ascAxios("post", `${this.reactContainerPath}/getTaskDepartments`, {
            task_id: param.id
        })
            .then((res) => {
                res.data.forEach((row) => {
                    this.state.selected_departments.push(row.id);
                    this.state.task_departments.push({
                        id: row.id,
                        tag: null,
                        value: row.floor_name ? row.floor_name : row.department_name
                    });
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    // 拠点リスト取得
    setCommonDepartmentSelect = async (company_id) => {
        try {
            this.ascAxios("post", `${this.reactContainerPath}/getAllDepartment`, {
                task_company_id: company_id
            }).then((res) => {
                this.setState({ department_select: res.data, department_id: res.data });
            });
        } catch (err) {
            alert(this.getErrorString(err));
        }
    };

    // 拠点管理者権限で新規登録の際、自分の拠点情報を取得する
    setDepartmentselectByDepartmentId = async (department_id) => {
        try {
            this.ascAxios("post", `${this.reactContainerPath}/getDepartmentByCm13Id`, {
                department_id: department_id
            }).then((res) => {
                this.setState({ department_select: res.data, department_id: res.data });
            });
        } catch (err) {
            alert(this.getErrorString(err));
        }
    };

    // 拠点管理者権限で新規登録の際、自分の拠点情報を取得する
    async setCommonFloorSelect({ parent_department_id }) {
        try {
            await this.ascAxios("post", `Common/getFloorsByDepartmentIds`, {
                container: "/BusinessManagement",
                departmentIds: parent_department_id
            }).then((res) => {
                this.setState({ department_select: res.data, floor_id: res.data });
            });
        } catch (err) {
            throw err;
        }
    }

    getTaskOperatorIds(param) {
        this.ascAxios("post", `${this.reactContainerPath}/getTaskOperatorIds`, {
            task_id: param.id,
            task_company_id: param.cm12_id
        })
            .then((res) => {
                this.setState({
                    task_operators: res.data.map((row) => {
                        return {
                            id: row.id,
                            tag: row.tag,
                            value: row.value
                        };
                    })
                });
            })
            .catch((err) => {
                console.error(err);
            });
    }

    onClickHandle = (modalType) => (event) => {
        let {
            company_id,
            selected_departments, // 選択された拠点・フロアリスト
            business_name,
            external_numbers, // 保存の際選択された外線番号リスト
            old_external_numbers, // 修正前のオリジナル外線番号リスト
            memo,
            task_id,
            task_company_id
        } = this.state;

        switch (modalType) {
            case "insert":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                    company_id,
                    selected_departments,
                    business_name,
                    external_numbers,
                    memo
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataInsertSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        this.showErrorObjectMesssage(err, "DataInsertError");
                    });
                break;
            case "update":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/update`, {
                    company_id,
                    selected_departments,
                    business_name,
                    external_numbers,
                    old_external_numbers,
                    memo,
                    task_id,
                    task_company_id
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        this.showErrorObjectMesssage(err, "DataUpdateError");
                    });
                break;
            case "delete":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    task_id,
                    task_company_id
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        this.showErrorObjectMesssage(err, "DataDeleteError");
                    });
                break;
            default:
                break;
        }
        this.setState({ show: false });
    };

    onChange = (event, param) => {
        if (param === "company_id" && event && event.value) {
            this.setState({ external_numbers: [], selected_departments: [] });
            this.getExternalNumbers(event.value);
            this.setCommonDepartmentSelect(event.value);
        } else if (param === "company_id") {
            // クリアした時
            this.setState({ externalarr: [], external_numbers: [], selected_departments: [] });
        }
    };

    render() {
        return (
            <div className="TaskList">
                <Row>
                    <Col xs={12} md={12}>
                        {this.props.currentPermission.create && (
                            <Button
                                className="table-button"
                                id="board_InsertButton"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={(event) => this.modalShow(event, "insert")}>
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        )}
                    </Col>
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            defaultSorted={[{ id: "id", desc: true }]}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            filterable={true}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>

                <BusinessManagementModal
                    state={this.state}
                    propSetState={this.propSetState}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                    onClick={this.onClickHandle}
                    onChange={this.onChange}
                    onTextChange_Limit={this.onTextChange_Limit}
                    validationHandle={this.validationHandle}
                    onSelectChange={this.onSelectChange}
                    currentPermission={this.props.currentPermission}
                    onMultiSelectChange={this.onMultiSelectChange}
                />
            </div>
        );
    }
}
