import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Col, Radio} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import chara_limit from "../../chara_limit";
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";
import KeywordSelect from "../Elements/Select/KeywordSelect";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import CallTagSelect from "../Elements/Select/CallTagSelect";

export default class AutoTagRuleModal extends Component {
    getRuleInfoItem = () => {
        let {
            state,
            langText,
            onTextChange_Limit,
            onSelectChange,
            validationHandle,
            getScopeGreaterEqual,
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key="company_id"
                    controlId="company_id"
                    label={langText.Body.CompanyName}
                    type="select"
                    onChange={e => onSelectChange(e, "company_id")}
                    options={state.companySelect}
                    value={state.company_id}
                    isClearable={true}
                    validationState={validationHandle("company_id")}
                    placeholder={langText.SelectOption.Placeholder}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="company_name"
                    controlId="company_name"
                    label={langText.Body.CompanyName}>
                    {state.company_name}
                </InlineForm.static>
            );
        }

        // ルール名
        if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
            itemArr.push(
                <InlineForm
                    key="name"
                    controlId="name"
                    label={langText.Body.RuleName}
                    type="text"
                    onChange={e => onTextChange_Limit(e, "name", chara_limit.Tag_Name)}
                    validationState={validationHandle("name")}
                    value={state.name}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key="name"
                    controlId="name"
                    label={langText.Body.RuleName}>
                    {state.name}
                </InlineForm.static>
            );
        }

        // 自動付与ルール
        itemArr.push(
            <InlineForm.static
                key="autoRule"
                controlId="autoRule"
                label={langText.Body.AutoTagRule}
            >
                {langText.Body.Keyword}
            </InlineForm.static>
        );

        return itemArr;
    }

    getDetailRuleInfoItem = () => {
        let {
            state,
            langText,
            onSelectChange,
            onMultiSelectChange,
            validationHandle,
            onKeyDownIntCheck,
            onNumberChange,
            countConditionSelect,
            onRadioChange,
            onInputChangeHandle,
            propSetState
        } = this.props;
        let itemArr = [];

        switch (state.auto_rule) {
            case "keyword":
                // キーワード
                if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
                    itemArr.push(
                        <InlineForm
                            key="keywords"
                            controlId="keywords"
                            label={langText.Body.Keyword}
                            validationState={validationHandle("keywords")}
                        >
                            <KeywordSelect
                                state={state}
                                onChange={onMultiSelectChange}
                                langText={langText}
                                onInputChangeHandle={onInputChangeHandle}
                                propSetState={propSetState}
                            />
                        </InlineForm>
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key="keywords"
                            controlId="keywords"
                            label={langText.Body.Keyword}
                            className="margin-top-03">
                            {state && state.keywords && state.keywords.map((row, idx) => {
                                let style = {
                                    backgroundColor: '#D8D8D8',
                                    padding: '2px 4px',
                                    borderRadius: '4px',
                                    marginRight: '8px'
                                };

                                if (row.isNot) style.backgroundColor = '#FF8787';
                                else if (row.isRegExp) style.backgroundColor = '#F5F5DC';
                                
                                return (
                                    <span 
                                        key={"keyword" + idx}
                                        style={style}
                                    >
                                        {row.label}
                                    </span>
                                );
                            })}
                        </InlineForm.static>
                    );
                }
        
                // 回数
                if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
                    itemArr.push(
                        <InlineForm
                            key="ruleCountCondition"
                            controlId="ruleCountCondition"
                            label={<SetDataWithSpeechBubble 
                                displayData = {langText.Body.RuleCount} 
                                speechBubbleData = {langText.Message.CallConditionRuleCountMessage}
                                infoIconDisplayFlag = {true}
                                infoIconType = {faIcon.faInfoCircle}
                                infoIconClassName = "modal-info-icon"
                                infoIconColor = "silver"
                                infoIconSize = "lg"/>}
                        >
                            <Col>
                                <Col xs={2} md={2} className="padding-left-0">
                                    <InlineForm
                                        key="ruleCount"
                                        controlId="ruleCount"
                                        type="number"
                                        onKeyDown={e => onKeyDownIntCheck(e)}
                                        onChange={e => onNumberChange(e, "rule_count")}
                                        value={state.rule_count}
                                        validationState={validationHandle("rule_count")}
                                        disabled={state.count_disabled}
                                    />
                                </Col>
                                <Col xs={3} md={3} className="padding-left-0">
                                    <InlineForm 
                                        key="countCondition"
                                        controlId="countCondition"
                                        type="select"
                                        options={countConditionSelect}
                                        onChange={e => onSelectChange(e, "count_condition")}
                                        value={state.count_condition}
                                        validationState={validationHandle("count_condition")}
                                        isDisabled={state.count_disabled}
                                    />
                                </Col>
                            </Col>
                        </InlineForm>
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key="ruleCountCondition"
                            controlId="ruleCountCondition"
                            label={langText.Body.RuleCount}>
                            {`${state.rule_count} ${state.count_condition === "more" ? langText.Body.CountMore : langText.Body.CountLess}`}
                        </InlineForm.static>
                    );
                }

                // キーワード組み合わせ
                if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
                    itemArr.push(
                        <InlineForm
                            key="keywordAssociation"
                            controlId="keywordAssociation"
                            label={langText.Body.KeywordAssociation}
                        >
                            <Col>
                                <Col xs={2} md={2} className="padding-left-0">
                                    <Radio
                                        name="or"
                                        key="or"
                                        onChange={e => onRadioChange(e, "keyword_association")}
                                        checked={state.keyword_association === "or"}
                                        value="or"
                                    >
                                        {langText.Body.Or}
                                    </Radio>
                                </Col>
                                <Col xs={2} md={2} className="padding-left-0">
                                    <Radio
                                        name="and"
                                        key="and"
                                        onChange={e => onRadioChange(e, "keyword_association")}
                                        checked={state.keyword_association === "and"}
                                        value="and"
                                    >
                                        {langText.Body.And}
                                    </Radio>
                                </Col>
                            </Col>
                        </InlineForm>
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key="keywordAssociation"
                            controlId="keywordAssociation"
                            label={langText.Body.KeywordAssociation}>
                            {state.keyword_association === "and" ? langText.Body.And : langText.Body.Or}
                        </InlineForm.static>
                    );
                }

                // 発話者
                if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
                    itemArr.push(
                        <InlineForm
                            key="speechPerson"
                            controlId="speechPerson"
                            label={langText.Body.SpeechPerson}
                        >
                            <Col>
                                <Col xs={2} md={2} className="padding-left-0">
                                    <Radio
                                        name="operator"
                                        key="operator"
                                        onChange={e => onRadioChange(e, "speech_person")}
                                        checked={state.speech_person === "operator"}
                                        value="operator"
                                    >
                                        {langText.Body.SpeechOperator}
                                    </Radio>
                                </Col>
                                <Col xs={2} md={2} className="padding-left-0">
                                    <Radio
                                        name="dest"
                                        key="dest"
                                        onChange={e => onRadioChange(e, "speech_person")}
                                        checked={state.speech_person === "dest"}
                                        value="dest"
                                    >
                                        {langText.Body.Customer}
                                    </Radio>
                                </Col>
                                <Col xs={2} md={2} className="padding-left-0">
                                    <Radio
                                        name="all"
                                        key="all"
                                        onChange={e => onRadioChange(e, "speech_person")}
                                        checked={state.speech_person === "all"}
                                        value="all"
                                    >
                                        {langText.Body.AllSpeechPeople}
                                    </Radio>
                                </Col>
                            </Col>
                        </InlineForm>
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key="speechPerson"
                            controlId="speechPerson"
                            label={langText.Body.SpeechPerson}>
                            {
                                state.speech_person === "operator" 
                                ? langText.Body.SpeechOperator
                                : state.speech_person === "dest"
                                ? langText.Body.Customer
                                : langText.Body.AllSpeechPeople
                            }
                        </InlineForm.static>
                    );
                }
                
                // 付与するタグ
                if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
                    itemArr.push(
                        <InlineForm
                            key="givetags"
                            controlId="givetags"
                            label={langText.Body.GiveTags}
                            validationState={validationHandle("tags")}
                        >
                            <CallTagSelect
                                options={state.tagSelect}
                                onChange={onMultiSelectChange}
                                value={state.tags}
                                langText={langText}
                                param={"tags"}
                            />
                        </InlineForm>
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key="givetags"
                            controlId="givetags"
                            label={langText.Body.GiveTags}
                            className="margin-top-03">
                            {
                                state.dispaly_tags && state.dispaly_tags.map((row, idx) => {
                                    return (
                                        <span
                                            key={"givetags" + idx}
                                            style={{
                                                backgroundColor: row.rgb,
                                                color: row.font_rgb,
                                                padding: '2px 4px',
                                                borderRadius: '4px',
                                                marginRight: '8px'
                                            }}
                                        >
                                            {row.name}
                                        </span>
                                    )
                                })
                            }
                        </InlineForm.static>
                    );
                }

                // 業務
                if (state.modalType === "insert" || state.modalType === "update" || state.modalType === "copy") {
                    itemArr.push(
                        <InlineForm
                            key="business"
                            controlId="business"
                            label={langText.Body.Business}
                            type="select"
                            isMulti={true}
                            options={state.businessSelect}
                            value={state.business}
                            onChange={e => onMultiSelectChange(e, "business")}
                            validationState={validationHandle("business")}
                            placeholder={langText.SelectOption.Placeholder}
                        />
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key="business"
                            controlId="business"
                            label={langText.Body.Business}>
                            {
                                state.display_business && state.display_business.map((row, idx) => {
                                    return (
                                        <span key={"business" + idx}>{`【${row.business_name}】`}</span>
                                    )
                                })
                            }
                        </InlineForm.static>
                    );
                }

                if (state.external_infos && state.external_infos.length > 0) {
                    let usingDataList = state.external_infos.map((row, idx) => <li key={idx}>{langText.Body.AddingOptionUsingId + row.value + langText.Body.NotCallAnalysisExternal + row.label}</li>)
                    itemArr.push(
                        <React.Fragment>
                            <p className="text-danger">{langText.Message.NotCallAnalysisExternalNumberCause1}</p>
                            <p className="text-danger">{langText.Message.NotCallAnalysisExternalNumberCause2}</p>
                            <ul>{usingDataList}</ul>
                        </React.Fragment>
                    )
                }

                break;
        
            default:
                break;
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.AutoTagRuleSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getRuleInfoItem()}
                        {this.getDetailRuleInfoItem()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
