import React from "react";
import {Button, Row, Col, Checkbox} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import Select from "react-select";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import { MultiSelect} from "react-multi-select-component";
import { Line } from "react-chartjs-2";
import { CSVLink } from "react-csv";


export default class Monitoring extends AscComponent {
    constructor(props) {
        super(props);
        const now = moment();
        const initialStartHour = moment(now).subtract(2, "hours").format("HH");
        const minDate = moment().subtract(2, 'months');
        const noDataText = this.props.langText.Message.NoDataText;

        this.state = {
            loading: false,
            searchDate: moment().startOf('day'),
            minDate: minDate,
            maxDate: now,
            startHour: {
                label: now.hours() < 2 ? "00" : initialStartHour,
                value: now.hours() < 2 ? "00" : initialStartHour
            },
            endHour: {
                label: now.hours() < 2 ? "02" : now.format("HH"),
                value: now.hours() < 2 ? "02" : now.format("HH")
            },

            operationTimeSearchFlag: false,

            data: [],
            lineData: [],
            cpuData : {},
            memoryData : {},
            csvData: [],

            pbxList: [],
            pbxListSelected: [],
            message: [],

            options : {
                maintainAspectRatio: true,
                tooltips: {
                    enabled: true,
                    mode: "nearest",
                    position: "average",
                    intersect: false,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            if (data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] === "noData") {
                                return false;
                            } else {
                                return data.datasets[tooltipItem.datasetIndex].label
                                    + ": " + tooltipItem.value + "%";
                            }
                        }
                    }
                },
                scales: {
                    xAxes: [
                    {
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: "",
                            fontFamily: "Montserrat",
                            fontColor: "black",
                        },
                        ticks: {
                            maxTicksLimit: 46,
                        },
                        },
                    ],
                    yAxes: [
                        {
                        display: true,
                        scaleLabel: {
                            display: false,
                            labelString: "percent",
                            fontFamily: "Montserrat",
                            fontColor: "black",
                        },
                        ticks: {
                            beginAtZero: true,
                            stepSize: 20,
                            min: 0,
                            max: 100,
                            callback: function (value) {
                            return value;
                            },
                        },
                        },
                    ],
                    },
            },
            legend : {
                    background: "white",
                    display: true,
                    labels: {
                    fontColor: "black",
                    },
                    position: "bottom",
            },
            plugins : {
                afterDraw : function (chart) {
                    if (!chart.data.datasets.find(row => row.data.length > 0)) {
                        let ctx = chart.ctx;
                        let width = chart.width;
                        let height = chart.height;
                        ctx.textAlign = "center";
                        ctx.textBaseline = "middle";
                        ctx.font = "30px Arial";
                        ctx.fillStyle = "#d3d3d3";
                        ctx.fillText(noDataText, width / 2, height / 2.5);
                        ctx.restore();
                    }
                },
            }
        };

        this.csvLink = React.createRef();
        this.hourShowList = this.getSelectOption('hour_show_list', this.props.langText.SelectOption);
        this.endHourShowList = this.getSelectOption('end_hour_show_list', this.props.langText.SelectOption);

        this.getPbxListData();
        this.onSearch();
    }
    
    getPbxListData = async () =>{
        try {
            const pbx_list = await this.ascAxios(
                "post", `${this.reactContainerPath}/getPbxForMonitoring`
            );
            this.setState({
                pbxList : pbx_list.data,
                pbxListSelected : pbx_list.data.map(row => {
                    row.randomColor = Math.floor(Math.random()*16777215).toString(16);
                    return row;
                })
            });
            return pbx_list;
        } catch (err) {
            alert(this.getErrorString(err));
        }
    }

    setLineData() {
        let line_rows = [];

        this.state.pbxListSelected.forEach(pbx_data => {
            let row = {
                label: pbx_data.label,
                data: [],
                lineTension: 0,
                borderWidth: 1,
                borderColor: "#" + pbx_data.randomColor,
                fill: false,
            };
            line_rows.push(row);
        });

        return line_rows;
    }

    moldData(type) {
        try {
            const result_obj = {};
            const line_result_data = this.setLineData();

            let metricsName = null;
            let stateName = null;
            switch (type) {
                case "cpu":
                    metricsName = "CPUUtilization";
                    stateName = "cpuData";
                    break;
                case "memory":
                    metricsName = "mem_used_percent";
                    stateName = "memoryData";
                    break;
                default:
                    break;
            }

            //グラフ表示用に少数第2位を切り捨てる
            this.state.data.forEach(obj => {
                line_result_data.forEach(line => {
                    if (obj.Label === metricsName && line.label === obj.PbxName) {
                        line.data = obj.data.map(
                            (row) => Math.floor(row.value * 10) / 10
                        );
                    }
                })
            });

            result_obj.labels = this.getLabelData();
            result_obj.datasets = line_result_data;
            this.setState({[stateName]: result_obj});

            return result_obj;
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    getLabelData = () => {
        const startHour = this.state.startHour.value;
        const endHour = this.state.endHour.value;
        let label_arr = [];

        for (let hour=Number(startHour); hour<Number(endHour) ;hour++){
            for(let min=0; min<60; min=min+5){
                if(hour < 10 && min < 10){
                    label_arr.push("0"+hour+":"+"0"+min);
                }else if (hour < 10){
                    label_arr.push("0"+hour+":"+min);
                }else if (min < 10){
                    label_arr.push(hour+":"+"0"+min);
                }else {
                    label_arr.push(hour+":"+min);
                }
            }
        }
        if (endHour < 24) {
            label_arr.push(endHour+":"+"00");
        }
        return label_arr
    }

    onChange = (event, param) => {
        if (event == null) return false;
        this.setState({[param]: event});
        this.getValidationCheck(event, param);
    }

    getValidationCheck = (event, param) => {
        let message = this.state.message;

        switch (param) {
            case 'searchDate':
                if (moment(event) < moment(this.state.minDate) || moment(event) > moment(this.state.maxDate)){
                    if(!message.find(row => row == this.props.langText.Message.SelectedDataError)) {
                        message.push(this.props.langText.Message.SelectedDataError);
                    }
                    this.setState({message});
                } 
                else {
                    message = message.filter(row => row != this.props.langText.Message.SelectedDataError);
                    this.setState({message});
                }
                break;
            case 'startHour':
                if (event.value >= this.state.endHour.value){
                    if(!message.find(row => row == this.props.langText.Message.TimeSettingMiss)) {
                        message.push(this.props.langText.Message.TimeSettingMiss);
                    }
                    this.setState({message});
                } 
                else {
                    message = message.filter(row => row != this.props.langText.Message.TimeSettingMiss);
                    this.setState({message});
                }
                break;
            case 'endHour':
                if (event.value <= this.state.startHour.value){
                    if(!message.find(row => row == this.props.langText.Message.TimeSettingMiss)) {
                        message.push(this.props.langText.Message.TimeSettingMiss);
                    }
                    this.setState({message});
                } else {
                    message = message.filter(row => row != this.props.langText.Message.TimeSettingMiss);
                    this.setState({message});
                }
                break;
            case 'pbx':
                if (event.length == 0) {
                    message.push(this.props.langText.Message.NoSelectedPbxError);
                    this.setState({message});
                } else {
                    message = message.filter(row => row != this.props.langText.Message.NoSelectedPbxError);
                    this.setState({message});
                }
                break;
            case 'operationTimeSearchFlag' :
                if (event){
                    if(message.find(row => row == this.props.langText.Message.TimeSettingMiss)) {
                        message = message.filter(row => row != this.props.langText.Message.TimeSettingMiss);
                    }
                    this.setState({message});
                } 
                break;
            default:
                this.setState({message});
                break;
        }
    }

    onMultiSelectChange = (event, param) => {
        this.setState({[param]: event});
        this.getValidationCheck(event, "pbx");
    }

    onCheckBoxChange = param => {
        this.setState({
            [param]: !this.state[param],
            startHour: { label: "09", value: 9 },
            endHour: { label: "18", value: 18 },
        });
        this.getValidationCheck(!this.state[param], param);
    }

    onSearch = async (downloadFlag) => {
        try {
            this.blockUI();
            const startHour = this.state.startHour.value;
            const endHour = this.state.endHour.value;
            const addTime = endHour == 24 ? 0 : 5;
            const filter = [
                {
                    id: "startDateUTC",
                    value: moment(this.state.searchDate)
                    .add(startHour, "hours")
                    .utc()
                    .format(),
                },
                {
                    id: "endDateUTC",
                    value: moment(this.state.searchDate)
                    .add(endHour, "hours")
                    .add(addTime, "m")
                    .utc()
                    .format(),
                },
                {
                    id: "pbx",
                    value: this.state.pbxListSelected.map((pbx) => pbx.value),
                },
            ];

            //画面表示用の時間リスト
            const baseTimes = [];
            //CSV表示用の時間リスト
            const csvOutPutTimes = [];
            for (let hour=Number(startHour); hour<Number(endHour); hour++){
                for(let min=0; min<60; min=min+5){
                    baseTimes.push((hour.toString().length < 2 ? '0' + String(hour) : String(hour)) 
                        + (min.toString().length < 2 ? '0' + String(min) : String(min)));
                    csvOutPutTimes.push(moment(this.state.searchDate).add(hour, "hours").add(min, "m"));
                }
            }
            if (endHour < 24) {
                csvOutPutTimes.push(moment(this.state.searchDate).add(endHour, "hours"));
            }

            const search_data = await this.ascAxios(
                'post', `${this.reactContainerPath}/board`,
                {filtered: filter}
            );

            const displayData = this.formatMetricsData(search_data, baseTimes);

            this.setState({
                data: displayData.data,
            });

            if(downloadFlag) {
                if(displayData.data.length == 0){
                    throw new Error("Download_Failed");
                }
                // detail作成
                const cpuUsed = [];
                const memoryUsed = [];
                //CPU使用率とメモリ使用率で分けて管理する
                search_data.data.forEach(metrics => {
                    switch (metrics.Label) {
                        case "CPUUtilization":
                            cpuUsed.push(metrics);
                            break;
                        case "mem_used_percent":
                            memoryUsed.push(metrics);
                            break;
                        default:
                            break;
                    }
                });
                

                const resultCpuUsed = this.createDownloadData(cpuUsed, csvOutPutTimes);
                const resultMemoryUsed = this.createDownloadData(memoryUsed, csvOutPutTimes);

                this.setState({
                    csvData: resultCpuUsed.concat(resultMemoryUsed)
                });

                this.csvLink.current.link.click();
            }

            this.moldData("cpu");
            this.moldData("memory");
        } catch(err) {
            this.showErrorObjectMesssage(err);
        } finally {
            this.unblockUI();
        }
    }

    /*
     * 欠落データに空文字を挿入して長さを調整する
     */
    formatMetricsData = (metricsData, baseTimes) => {
        //チェック対象の時間リスト
        const metricsTimeArray = [];
        metricsData.data.forEach((metrics, metricsIndex) => {
            const tmpMetricsData = [];
            metricsTimeArray.push([]);
            metrics.Timestamps.forEach((time, index) => {
                metricsTimeArray[metricsIndex].push(moment(time).format("HHmm"));
                tmpMetricsData.push({
                    timestamp: time,
                    value: metrics.Values[index]
                });
            });

            // 時間を基準に昇順に並び替える
            metricsData.data[metricsIndex].data = tmpMetricsData.sort((a, b) => {
                return a.timestamp > b.timestamp ? 1 : -1;
            });
        });

        //metricsTimeArrayとbaseTimesを比較して
        //欠落データがある場合はダミーを挿入してデータとX軸の表示を合わせる
        metricsData.data.forEach((arr, metricsIndex) => {
            for (let index=0; index < baseTimes.length; index++) {
                if (!arr.data[index]) break;
                if (
                    index > 0 &&
                    moment(arr.data[index - 1].timestamp).format("HHmm") ===
                    moment(arr.data[index].timestamp).format("HHmm")
                ) {
                    /*
                     *メトリクス変更のタイミングによって
                     *時間が重複する場合があるため重複データを削除する
                     *※重複した場合は変更前のデータが優先
                     */
                    metricsData.data[metricsIndex].data.splice(index, 1);
                    continue;
                }
                if (moment(arr.data[index].timestamp).format("HHmm") !== baseTimes[index]) {
                    //欠落データの補正
                    metricsData.data[metricsIndex].Timestamps.splice(index, 0, "noData");
                    metricsData.data[metricsIndex].Values.splice(index, 0, "noData");
                    metricsData.data[metricsIndex].data.splice(index, 0, {
                        timestamp: "noData",
                        value: "noData"
                    });
                }
            }
        })
        return metricsData;
    }

    createDownloadData = (metricsData, csvOutPutTimes) => {
        const csvDetail = [];
        //時間、区分データの作成
        csvOutPutTimes.forEach(time => {
            csvDetail.push({
                [this.props.langText.Body.DateTime]:
                moment(time).format("YYYY/MM/DD HH:mm"),
                [this.props.langText.Body.Class]:
                this.props.langText.Body.MonitoringTypeList[metricsData[0].Label],
            });
        });

        //detailの結合
        metricsData.forEach(row => {
            row.data.forEach((val, index) => {
                if (val.value !== "noData") {
                    csvDetail[index][row.PbxName] =
                        (Math.floor(Number(val.value) * 10) / 10).toFixed(1) + "%";
                }
            });
        });

        return csvDetail;
    }

    render() {
        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <div className="Monitoring">
                    <Row>
                        <Col xs={12} md={12}>
                            <Col sm={2} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.Pbx}</div>
                            </Col>
                            <Col sm={4} md={4} lg={3}>
                                <MultiSelect
                                    className="margin-top-05"
                                    hasSelectAll={true}
                                    options={this.state.pbxList}
                                    value={this.state.pbxListSelected}
                                    onChange={e => this.onMultiSelectChange(e,"pbxListSelected")}
                                    overrideStrings={{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                        allItemsAreSelected: this.props.langText.Body.AllSelected,
                                        selectAll: this.props.langText.MultiSelect.AllSelect,
                                        search: this.props.langText.Body.Search,
                                    }}/>
                            </Col>
                            <Col sm={3} md={3} lg={2} xs={4}>
                                <Checkbox
                                    checked={this.state.operationTimeSearchFlag}
                                    onChange={() => {
                                        this.onCheckBoxChange("operationTimeSearchFlag");
                                    }}
                                >
                                    {this.props.langText.Body.OperationTimeSearch}
                                </Checkbox>
                            </Col>
                            <Col sm={3} md={3} lg={4} xs={4}>
                                <label className="margin-top-05 search-message">
                                    {this.state.message.map((row, key) =>
                                        <p key={key}>{row}</p>
                                    )}
                                </label>
                            </Col>
                        </Col>

                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={2} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.SearchDate}</div>
                            </Col>
                            <Col sm={4} md={4} lg={2}>
                                <DatePicker
                                    key = "searchDate"
                                    onChange = {e => this.onChange(e, "searchDate")}
                                    selected = {this.state.searchDate}
                                    dateFormat = "YYYY-MM-DD"
                                    className = "form-control"
                                    todayButton = {this.props.langText.Body.Today}
                                    placeholderText = {this.props.langText.Body.SearchDate}
                                    minDate={this.state.minDate}
                                    maxDate={this.state.maxDate}
                                />
                            </Col>
                            <Col sm={2} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.StartTime}</div>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <Select
                                    key = "startHour"
                                    value = {this.state.startHour}
                                    onChange = {e => this.onChange(e, "startHour")}
                                    options={this.hourShowList}
                                    isDisabled = {(this.state.operationTimeSearchFlag)}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={2} md={2} lg={1}>
                                <div className="margin-top-05">{this.props.langText.Body.EndTime}</div>
                            </Col>
                            <Col sm={4} md={4} lg={1}>
                                <Select
                                    key = "endHour"
                                    value = {this.state.endHour}
                                    onChange = {e => this.onChange(e, "endHour")}
                                    options={this.endHourShowList}
                                    isDisabled = {(this.state.operationTimeSearchFlag)}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={1} md={1} lg={1}>
                                <Button
                                    className="monitoring-search"
                                    bsStyle="primary"
                                    bsSize="sm"
                                    onClick={() => this.onSearch()}
                                    disabled = {this.state.message.length > 0 || this.state.loading}
                                >
                                    {this.props.langText.Body.Search}
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-10">
                            <Button
                                id="monitoring-history-download"
                                className='table-button'
                                bsStyle='default'
                                bsSize='sm'
                                onClick={() => this.onSearch(true)}
                                disabled = {this.state.message.length > 0 || this.state.loading}
                            >
                                {this.props.langText.Body.MonitoringDownload}
                            </Button>
                            <CSVLink
                                data={this.state.csvData}
                                filename={
                                    "Monitoring_result" +
                                    "_" +
                                    this.getMomentTime({
                                        date: this.state.searchDate,
                                        format: "YYYYMMDD",
                                    }) +
                                    ".csv"
                                }
                                className="csv-download-link-format hidden"
                                ref={this.csvLink}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.CpuUsage }]} />
                        </Col>
                        <Line data={this.state.cpuData} legend={this.state.legend} options={this.state.options} plugins={this.state.plugins} width={350} height={95}/>
                    </Row>
                    <Row>
                        <Col sm={4} md={4} lg={4}>
                            <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.MemoryUsage }]} />
                        </Col>
                        <Line data={this.state.memoryData} legend={this.state.legend} options={this.state.options} plugins={this.state.plugins} width={350} height={95}/>
                    </Row>
                </div>
            </BlockUi>
        );
    }
}
