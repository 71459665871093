import * as RDG from '@projectstorm/react-diagrams';

// DiagramのITEM管理
export class Application {
    constructor() {
        this.diagramEngine = RDG.default({ registerDefaultDeleteItemsAction: false });
        this.newModel();

        // this.activeModel.addListener({
        //     linksUpdated: e => console.log(e)
        // });
    }

    // setLockCanvasAndNode(flg = true) {
    //     // NODEをロック
    //     this.activeModel.setLocked(flg);

    //     // TODO
    //     // CANVASをロック
    //     // this.diagramEngine.getStateMachine().getCurrentState().dragCanvas.config.allowDrag = !flg;       
    // }

    newModel() {
        this.activeModel = new RDG.DiagramModel();
        this.diagramEngine.setModel(this.activeModel);
        this.diagramEngine.number = 0;
    }

    getActiveDiagram() {
        return this.activeModel;
    }

    getDiagramEngine() {
        return this.diagramEngine;
    }
}