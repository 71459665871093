import React from "react";
import {Button, Row, Col, FormControl, ButtonGroup, Glyphicon, Dropdown} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment-timezone";
import Select from "react-select";
import { CSVLink } from "react-csv";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as GlobalConst from "../../components/AscConstants";
import CallAnalysisModal from './CallAnalysisModal';
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import {components} from 'react-select';
import CallAnalysisTemplateModal from "./CallAnalysisTemplateModal";
import CallAnalysisTemplateCheckModal from "./CallAnalysisTemplateCheckModal";
import _ from "lodash";
import KeywordSelect from "../Elements/Select/KeywordSelect";
import CallTagSelect from "../Elements/Select/CallTagSelect";

export default class CallAnalysis extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            // columns: this.getColumnsData(),
            data: [],
            pages: null,
            count: null,
            count_limit: 10000,
            loading: false,
            default_filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "check_flag",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg
                    }
                },
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                },
                {
                    id: "keyword_term",
                    value: this.props.langText.SelectOption.KeywordTermOption[0]
                }
            ],
            filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "check_flag",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg
                    }
                },
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                },
                {
                    id: "keyword_term",
                    value: this.props.langText.SelectOption.KeywordTermOption[0]
                }
            ],
            hidden_filtered: [
                {
                    id: "inout_type",
                    value: "all"
                },
                {
                    id: "check_flag",
                    value: "all"
                },
                {
                    id: "cm12_id",
                    value: {
                        value: this.props.userInfo.company_id, 
                        label:this.props.userInfo.company_name, 
                        floor_flg: this.props.userInfo.floor_flg
                    }
                },
                {
                    id: "start_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "end_datetime",
                    value: moment({h:0, m:0, s:0, ms:0})
                },
                {
                    id: "start_ji",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "start_hun",
                    value: { value: '00', label: '00' },
                },
                {
                    id: "end_ji",
                    value: { value: '23', label: '23' },
                },
                {
                    id: "end_hun",
                    value: { value: '59', label: '59' },
                },
                {
                    id: "keyword_term",
                    value: this.props.langText.SelectOption.KeywordTermOption[0]
                }
            ],
            cm12_id: this.props.userInfo.company_id,
            companySelect: [],
            externalArr: [],
            insert_comment: "",
            comment_edit_flag: [],
            voice_edit_flag: [],
            templateSelect: [],
            templateSelected: null,
            templateName: "",
            inputValue: "",
            type: "call_analysis",
            tagSelect: [],
            keywords: [],

            // modal item
            show: false,
            modalType: null,
            csvdata: [],
            blocking: false,
            disableFlg: false
        };

        this.hourShowList = this.getSelectOption('hour_show_list', this.props.langText.SelectOption);
        this.minuteShowList = this.getSelectOption('minute_show_list', this.props.langText.SelectOption);
        this.TemplatePeriodOption = this.getSelectOption('template_period_option', this.props.langText.SelectOption);

        this.table = React.createRef();
        this.csvLink = React.createRef();

        // 会社選択肢セット
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            let {filtered, hidden_filtered} = this.state;
            
            this.getCommonCompanySelect(this.reactContainerPath).then(res => {
                let cm12_id_idx = 0;
                if (res && res.data && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                    cm12_id_idx = res.data.findIndex(row => {
                        return row.value === this.props.userInfo.company_id
                    });
                }
                filtered[2].value = {
                    value: (res.data && res.data[cm12_id_idx].value) || 0, 
                    label:(res.data && res.data[cm12_id_idx].label) || "", 
                    floor_flg: (res.data && res.data[cm12_id_idx].floor_flg) || ""
                }
                hidden_filtered[2].value = {
                    value: (res.data && res.data[cm12_id_idx].value) || 0, 
                    label:(res.data && res.data[cm12_id_idx].label) || "", 
                    floor_flg: (res.data && res.data[cm12_id_idx].floor_flg) || ""
                }
                this.setTagsInfoList((res.data && res.data[cm12_id_idx].value) || 0, ["tagSelect"]);
                this.setState({
                    companySelect: res.data,
                    cm12_id: (res.data && res.data[cm12_id_idx].value) || 0,
                    filtered,
                    hidden_filtered
                })
            }).catch(err => {
                this.showErrorObjectMesssage(err, "DataSelectError");
            });
        }
    }

    async componentDidMount() {
        // 外線リストセット
        if (this.props.isAuthenticated) {
            await this.getExternalList(this.state.cm12_id);
            if (this.state.externalArr.length > 0) this.onSearch(this.state.filtered);
        }

        // テンプレートリストセット
        await this.getTemplateList(this.state.type);
        
        if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            await this.setTagsInfoList(this.props.userInfo.company_id, ["tagSelect"]);
        }

        // 通話分類タグ利用会社
        let search_company_parameter_call_auto_tag = false;
        let company_parameter = await this.getCompanyControlParameters(this.props.userInfo.company_id);
        let call_auto_tag_idx = company_parameter.findIndex(row => row.function_id === "CALL_AUTO_TAG");
        if (call_auto_tag_idx !== -1) search_company_parameter_call_auto_tag = company_parameter[call_auto_tag_idx].parameter_value === "Y" ? true : false; 

        this.setState({ search_company_parameter_call_auto_tag });
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    getColumnsData() {
        let columns = [];
        //内線番号
        columns.push({
            Header: this.props.langText.Body.ExtensionNumber,
            sortable: false,
            accessor: "extension_number",
            width: this.props.boardWidth.smedium
        });
        //発着区分
        columns.push({
            Header: this.props.langText.Body.InOutType,
            sortable: false,
            accessor: "inout_type",
            width: this.props.boardWidth.small,
        });
        // //外線番号
        columns.push({
            Header: this.props.langText.Body.ExternalNumber,
            sortable: false,
            accessor: "display_number",
            Cell: data => {
                let returnData = "";
                if(data && data.row && data.row._original) {
                    returnData =
                        data.row._original.display_number +
                        (data.row._original.external_number_memo? "(" +data.row._original.external_number_memo +")": "")
                }
                return returnData;
            }
        });
        //相手先番号
        columns.push({
            Header: this.props.langText.Body.DestUserNumber,
            sortable: false,
            accessor: "dest_tel_no",
            width: this.props.boardWidth.smedium
        });
        //開始時間
        columns.push({
            Header: this.props.langText.Body.StartTime,
            accessor: "start_datetime",
            width: this.props.boardWidth.xxlarge,
            Cell: data => {
                return data.value ? this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"}) : "";
            }
        });
        //通話時間
        columns.push( {
            Header: this.props.langText.Body.TalkingTime,
            sortable: false,
            accessor: "billsec",
            width: this.props.boardWidth.smedium,
            Cell: data => {
                return this.secToTime(data.value || 0);
            }
        });
        //キーワードヒット回数
        if(this.state.keyword_flag){
            columns.push({
                Header: this.props.langText.Body.KeywordCount,
                accessor: "keyword_count",
                width: this.props.boardWidth.xsmall
            });
        }
        //確認
        columns.push( {
            Header: this.props.langText.Body.Check,
            sortable: false,
            accessor: "flag_data_json",
            width: this.props.boardWidth.xsmall,
            Cell: data => {
                if (data.value && data.value.check_flag === "Y") {
                    return "〇";
                } else {
                    return "×";
                }
            }
        });
        //ダウンロード
        columns.push( {
            Header: this.props.langText.Body.Download,
            sortable: false,
            accessor: "flag_data_json",
            width: this.props.boardWidth.small,
            Cell: data => {
                if (data.value && data.value.download_flag === "Y") {
                    return "〇";
                } else {
                    return "×";
                }
            }
        });

        // 通話分類タグ
        if (this.state && this.state.search_company_parameter_call_auto_tag) {
            columns.push({
                Header: this.props.langText.Body.CallCategoryTag,
                sortable: false,
                accessor: "cm66_tags",
                Cell: data => {
                    let display_tag = data.value && data.value.map((row, idx) => {
                        return (<span 
                            key={"display_tag" + idx}
                            style={{
                                backgroundColor: row.rgb,
                                color: row.font_rgb,
                                padding: '2px 4px',
                                borderRadius: '4px',
                                marginRight: '8px'
                            }}>
                                {row.name}
                            </span>);
                    });
    
                    return display_tag;
                }
            });
        }

        columns.push({
            Header: this.props.langText.Body.Control,
            width: this.props.boardWidth.xsmall,
            sortable: false,
            Cell: data => {
                    return(
                        <Row className="text-center">
                            <Button
                                key = {1}
                                bsSize="xsmall"
                                onClick={this.modalShow(data.row._original.id)}
                                className="control-button"
                            >
                                <Glyphicon glyph="eye-open"/>
                            </Button>
                        </Row>
                    )
                }
        })

        return columns;
    }

    getExternalList = async(cm12_id, template_change = null) => {
        try {
            let result = await this.ascAxios('post', `${this.reactContainerPath}/getExternalList`, {cm12_id});
            this.setState({externalArr: result.data}, () => {
                if (!template_change) {
                    let filtered = this.state.filtered;
                    let target_index = filtered.findIndex(row => row.id === "display_number");
                    if (target_index !== -1) filtered.splice(target_index, 1);
    
                    if (this.state.externalArr.length > 0) {
                        filtered.push({
                            id: "display_number",
                            value: this.state.externalArr[0]
                        });
        
                        this.setState({
                            disableFlg: false,
                            filtered: filtered,
                            hidden_filtered: filtered
                        });
                    } else {
                        clearInterval(this.state.interval);
                        // 検索条件に通話履歴IDが入っていれば検索できるようにする（バリデーションをOFFにする）
                        if (this.getFilterData("ct60_id")) {
                            this.setState({
                                searchValidation: false,
                                disableFlg: true,
                                filtered: filtered,
                                hidden_filtered: filtered
                            });
                        } else {
                            this.setState({
                                searchValidation: true,
                                disableFlg: true,
                                filtered: filtered,
                                hidden_filtered: filtered
                            });
                        }
                    }
                }

                this.getValidationCheck();
            });
        } catch (err) {
            return this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    getTemplateList = async (type) => {
        try {
            let result = await this.ascAxios('post', `${this.reactContainerPath}/getTemplateList`, {type});

            this.setState({
                templateSelect: result.data
            })
        } catch (err) {
            console.error(err)
            return this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    getFilterData = (param) => {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    getKeywordData(param) {
        let my_param_data = this.state.hidden_filtered.find(row => row.id === param);
        let return_data = my_param_data ? my_param_data.value.filter(row => !row.isNot) : [];
        return return_data.length > 0 ? return_data.map(row => row.value) : "";
    }

    onChange = async (event, param, template_change) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";
        // セレクトボックス用。event無い場合はvalue空のまま
        if(event)
        {
            value = event.target ? event.target.value : event;
        }
        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }

        if (param === "keyword") {
            const keyword_index = my_filtered.findIndex(row => row.id === param);
            if (keyword_index !== -1) {
                my_filtered[keyword_index].value =
                    event.length === 0
                        ? []
                        : my_filtered[keyword_index].value.map((keyword) => keyword.value);
            }

            //新しい入力値が正規表現キーワードの場合、有効確認してisValidフラグを設定
            const prev_keyword_arr = this.state.keywords;
            const new_input_keyword = event.filter(keyword_obj => prev_keyword_arr && !prev_keyword_arr.includes(keyword_obj));
            if (new_input_keyword.length > 0 && new_input_keyword[0].isRegExp) {
                event.forEach(row => {
                    if (row.value === new_input_keyword[0].value) {
                        row.isValid = this.getRegexpKeywordValidationCheck(new_input_keyword[0].value);
                    }
                });
            }
            await this.setState({keywords: event});
        }
        // 会社選択
        if (param === "cm12_id" && event && event.value) {
            let tag_index = my_filtered.findIndex(row => row.id === "tag");
            if (tag_index !== -1) my_filtered.splice(tag_index, 1);
            this.setTagsInfoList(event.value, ["tagSelect"]);
            this.getExternalList(event.value, template_change);
        } else if (param === "cm12_id") {
            // クリアした時
            this.setState({
                externalArr: [],
                tagSelect: []
            })
        } else if (param === "autoUpdateInterval") {
            this.setState({
                autoUpdateInterval: event
            });
        }

        let returnValue = [];
        returnValue = my_filtered;

        this.setState({
            filtered: returnValue,
            templateSelected: null
        });

        this.getValidationCheck();
    }

    //会社や集計期間未選択時、検索できないようにする
    getValidationCheck = () =>{
        let message = "";
        // 検索条件に通話履歴IDが入っている場合は他の条件を無視して検索する
        if (this.getFilterData("ct60_id")) {
            message = "";
            this.setState({searchValidation : false, message});
        
        } else if(!this.getFilterData("start_datetime") || !this.getFilterData("end_datetime")){
            message = this.props.langText.Message.CallAnalysis_FindDataError;
            this.setState({searchValidation : true, message});
        } else if(moment.duration(moment(this.getFilterData("end_datetime")).diff(moment(this.getFilterData("start_datetime")))).asDays()<0){
            message = this.props.langText.Message.CallHistory_DayTermError;
            this.setState({searchValidation : true, message});
        } else if (!this.getFilterData("cm12_id")) {
            this.setState({searchValidation : true});
        } else if (!this.getFilterData("display_number")){
            message = this.props.langText.Message.ExtenalNumberSelectedError;
            this.setState({searchValidation: true, message});
        } else if (!this.setTimeCheck(
            this.getFilterData("start_datetime"),
            this.getFilterData("end_datetime"),
            this.getFilterData("start_ji").value,
            this.getFilterData("start_hun").value,
            this.getFilterData("end_ji").value,
            this.getFilterData("end_hun").value
        )) {
            message = this.props.langText.Message.StartDateTimeCheckError;
            this.setState({searchValidation : true, message});
        } else if (this.getFilterData("keyword").length > 0 
            && this.state.keywords.find(row => row.isValid === false)) {
            this.setState({searchValidation: true});
        } else {
            this.setState({searchValidation: false, message: ""});
        }
    }

    getDateValidationCheck = () => {
        let flag = true;
        let {
            start_datetime,
            start_hun,
            start_ji,
            end_datetime,
            end_hun,
            end_ji
        } = this.state;

        if (!start_datetime || !end_datetime){
            flag = false;
        } else if(moment.duration(moment(end_datetime).diff(moment(start_datetime))).asDays()<0){
            flag = false;
        } else if (!this.setTimeCheck(
            start_datetime,
            end_datetime,
            start_ji.value,
            start_hun.value,
            end_ji.value,
            end_hun.value
        )) {
            flag = false;
        }

        return flag;
    }

    /**
     * 正規表現オブジェクトを生成
     * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/RegExp
     */
    getRegexpKeywordValidationCheck = (regexp_value) => {
        let return_flag = true;
        let regex = null;
        let valid_regexp = /[.?+*|\{\}\[\]\(\)]/gi;
        let invalid_regexp = /[#@&<>~^$\\"]/gi;

        try {
            regex = new RegExp(regexp_value);
        } catch(error) {
            //正規表現の作成が失敗した場合、エラーメッセージを出力し有効フラグをfalseにする
            alert(this.props.langText.Message.KeywordRegExpInvalidMessage);
            return_flag = false;
            return return_flag;
        }
        //サポートされていない演算子が含まれている場合、エラーメッセージを出力し有効フラグをfalseにする
        if (regex && (!valid_regexp.test(regex) || invalid_regexp.test(regex))) {
            alert(this.props.langText.Message.KeywordRegExpInvalidMessage);
            return_flag = false;
        }
        return return_flag;
    }

    setTimeCheck = (start_datetime, end_datetime, start_ji, start_hun, end_ji, end_hun) => {
        let flag = true;

        if(start_datetime && end_datetime && start_datetime.format("YYYYMMDD") === end_datetime.format("YYYYMMDD")){
            if (start_ji > end_ji) {
                flag = false;
            } else if (start_ji === end_ji && (start_hun > end_hun)) {
                flag = false;
            }
        }
        
        return flag;
    }

    /**
     * OpenSearchへの検索
     * @param {Object} filter
     */
    onSearch = async(filter, download_search_flag) => {
        try {
            const cm12Id = filter.filter(data => data.id === 'cm12_id')[0].value.value;
            // キーワードでソートするとタイムアウトが発生する場合があるため、検索時は開始時間でソートする
            if(!download_search_flag){
                this.table.current.state.sorted[0] = {id: 'start_datetime', desc: false};
            }
            // 通話履歴IDが選択されていない、かつ外線番号が選択されていない場合は検索できない（どちらも選択されていなければ検索できない）
            if (!this.getFilterData("ct60_id") && !this.getFilterData("display_number")) throw new Error();
            this.table.current.state.page = 0;
            await this.execFullTextSearch(
                cm12Id,
                filter,
                this.formatForTableDisplay,
                this.state.keywords
            );

            // 検索時のデータ保存
            let hidden_filter = [];
            filter.forEach((row, index) => {
                hidden_filter.push(row);
            });

            this.keywordColumnHandle()
            this.setState({ 
                hidden_filtered: hidden_filter
            });
        } catch (err) {
            return this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    /**
     * 全文検索結果をグリッド表示形式に整形する
     * @param {Object} fullTextSearchData
     * @return {Object | boolean}
     */
    formatForTableDisplay = (fullTextSearchData) => {
        const tableDisplayData = [];
        fullTextSearchData.forEach(row => {
            const keywordHitCount = row.fields && row.fields.phrase_count;
            tableDisplayData.push (
                {
                    id: row._id,
                    extension_number: row._source.extension_number,
                    extension_number_memo: row._source.extension_number_memo,
                    inout_type: row._source.inout_type,
                    display_number: row._source.display_number,
                    external_number_memo: row._source.external_number_memo,
                    dest_tel_no: row._source.dest_tel_no,
                    start_datetime: row._source.start_datetime,
                    billsec: row._source.billsec,
                    flag_data_json: row._source.flag_data_json,
                    keyword_count: keywordHitCount || 0,
                    cm66_tags: row._source.cm66_tags,
                    regexp_hits: row.fields && row.fields.regexp_hits ? row.fields.regexp_hits : []
                }
            );
        });

        return tableDisplayData;
    }

    CallAnalysisExcelDownload = async () => {
        try {
            const maxRows = 10000;
            let download_search_flag = true
            // 画面表示用の検索を実行
            await this.onSearch(this.state.filtered, download_search_flag);

            const error = new Error();

            if (this.state.timeout) {
                error.message = "CallAnalysisTimeOut";
                throw error;
            }
            if (this.state.data.length <= 0) {
                error.message = "Download_Failed";
                throw error;
            }

            if (this.state.count > maxRows) {
                error.message = "TooManyResultsError";
                error.args = [this.state.count, maxRows];
                throw error;
            }

            this.setState({downloadWaitTime: 5});
            const downloadFileName =
                this.props.userInfo.cm15_id +
                "_" +
                this.props.langText.Body.VoiceQualityAnalysis +
                "_" +
                moment().utc().add(9, "h").format("YYYYMMDDHHmmss");
            let filterKeywords = this.state.filtered.filter(data => data.id === "keyword")[0];    
            let searchKeyWords = [];
            
            if (filterKeywords && filterKeywords.value.length > 0) {
                filterKeywords.value.forEach(row => {
                    if (row && !row.isNot) searchKeyWords.push(row.value)
                })
            }
            let sort = this.table.current && this.table.current.state && this.table.current.state.sorted[0]
                ? this.table.current.state.sorted[0]
                : null;
            const lambdaParams = {
                conditions: this.state.filtered,
                downloadFileName,
                searchKeyWords,
                userEmail: this.props.userInfo.user_id,
                sort
            };

            //5秒ごとに実行
            this.toggleBlocking();
            const intervalId = setInterval(() => {
                this.createDownloadFile(
                    lambdaParams,
                    intervalId
                );
            }, 5000);
        } catch (e) {
            this.showErrorObjectMesssage(e);
        }
    }

    /**
     * ダウンロードファイル作成
     * @param {Object} lambdaParams
     * @param {Integer} intervalId
     */
    async createDownloadFile (lambdaParams, intervalId) {
        const intervalSecond = 5;
        const maxWaitTime = 330;

        try {
            const res = await this.ascAxios("post", `/Download/voiceQualityAnalysis`, {
                lambdaParams
            });

            switch (res.data.download_status) {
                case "success":
                    clearInterval(intervalId);
                    this.toggleBlocking();
                    const downLoadLink = document.createElement("a");
                    downLoadLink.href = res.data.url;
                    downLoadLink.click();
                    downLoadLink.remove();
                    break;
                case "lock_exist":
                case "converting":
                    // 作成開始または作成中のため処理を行わない
                    break;
                default:
                    //サーバーから結果がエラーの場合
                    throw new Error("CreateFileFailed");
            }

            //タイムアウト処理
            this.setState({downloadWaitTime: this.state.downloadWaitTime + intervalSecond});
            if (this.state.downloadWaitTime > maxWaitTime) {
                throw new Error("CreateFileTimeOut");
            }
        } catch (err) {
            clearInterval(intervalId);
            this.toggleBlocking();
            this.showErrorObjectMesssage(err);
        }
    }
    
    //モーダル開くときにモーダル内の情報取得
    getModalInfo(id) {
        return this.ascAxios('post', `${this.reactContainerPath}/modal`, { id });
    }

    /**
     * ヒットしたキーワード数取得
     * 
     * @param {Array} keywords
     * @param {Object} textData
     * @return {Integer}
     */
    getKeywordCount (keywords, textData) {
        let keywordHitCount = 0;
        // キーワードヒット回数を取得
        if(keywords){
            keywords.forEach((keyword) => {
                let hitCount = 0;
                textData.forEach((text) => {
                    if (text.transcript) {
                        const splitArr = text.transcript.split(keyword);
                        hitCount = splitArr.length - 1;
                    }
                    keywordHitCount += hitCount;
                });
            });
        }

        return keywordHitCount;
    }

/**
     * del_flagがNのコメント情報取得
     * 
     * @param {Array} comments
     * @return {(Array|null)} 
     */
    getCommentData(comments) {
        let existComments = comments.filter(
            (comment) => comment.del_flag === "N"
        );

        if (existComments.length === 0) {
            existComments = null;
        }

        return existComments;
    }

    getTemplateFilterData = (param) => {
        let {
            templateSelected
        } = this.state;

        let template_json = templateSelected ? templateSelected.template_json : null;
        let template_filtered = template_json ? template_json.filtered : null;
        let result = template_filtered 
            ? param === "start_datetime" || param === "end_datetime" 
                ? moment.tz(template_filtered.find(row => row.id === param).value, "Asia/Tokyo")
                : template_filtered.find(row => row.id === param).value
            : this.getFilterData(param);
        
        return result;
    }

    // idは通話品質分析の詳細モーダル情報取得のため必要
    // 以外のモーダルは必要なしnull
    modalShow = (id, modalType="analysis") => async event => {
        event.preventDefault();
        let {
            templateSelected,
            data
        } = this.state;

        let start_datetime = this.getTemplateFilterData("start_datetime");
        let start_ji = this.getTemplateFilterData("start_ji");
        let start_hun = this.getTemplateFilterData("start_hun");
        let end_datetime = this.getTemplateFilterData("end_datetime");
        let end_ji = this.getTemplateFilterData("end_ji");
        let end_hun = this.getTemplateFilterData("end_hun");

        try {
            let setData = {
                show: true,
                modalType,
                start_datetime,
                start_ji,
                start_hun,
                end_datetime,
                end_ji,
                end_hun
            };

            switch (modalType) {
                case "analysis":
                    let selected = [];
                    let keywordHitCount = 0;
                    let keywords = this.getKeywordData("keyword")
                    let modal_tags = null;

                    if(id){
                        let res = await this.getModalInfo(id);
                        if (res.data && res.data.comment_data_json) {
                            res.data.comment_data_json = this.getCommentData(res.data.comment_data_json);
                        }
                        res.data.regexp_hits = data.find(row => {return row.id === id}).regexp_hits;
                        selected = res.data
                        keywordHitCount = this.getKeywordCount(
                            keywords,
                            res.data.text_data_json
                        );
                        modal_tags = res.data.ct60_call_histories.ct605_call_tag_results.length > 0 ? res.data.ct60_call_histories.ct605_call_tag_results.map(row => { return row.cm66_call_tags }) : null;
                        await this.setTagsInfoList(res.data.ct60_call_histories.cm12_id, ["modal_tag_select"]);
                    }
        
                    setData = {
                        comment_alert_flag: false,
                        comment_alert_message: "",
                        insert_comment: "",
                        comment_edit_flag: [],
                        voice_alert_flag: false,
                        voice_alert_message: "",
                        voice_edit_flag: [],
                        message_box_data: [],
                        message_box_index: 0, 
                        auto_scroll_flag: false,
                        keyword_index_arr: [],
                        searching_index: 0,
                        element_index: 0,
                        show: true,
                        modalType,
                        selected,
                        keywordHitCount,
                        comment_null_flag: false,
                        voice_text_null_flag: false,
                        modal_tags
                    }
                    break;
                case "templateInsert":
                    setData = {
                        ...setData,
                        periodSetting: this.TemplatePeriodOption[0],
                        templateName: ""
                    }
                    break;
                case "templateCopy":
                    setData = {
                        ...setData,
                        periodSetting: templateSelected.template_json.period,
                        templateName: ""
                    }
                    break;
                case "templateUpdate":
                case "templateDelete":
                    setData = {
                        ...setData,
                        periodSetting: templateSelected.template_json.period,
                        templateName: templateSelected.template_name
                    }
                    break;
                case "callContentDownload":
                    setData = {
                        fileName: null,
                        show: true,
                        modalType
                    }
                    break;
                default:
                    break;
            }
            
            this.setState(setData);
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    /**
     * コメント作成
     * @param {String} value
     * @return {Object}
     */
    insertCommentData = async(value) => {
        if(!value.trim()){
            //入力内容がない場合、アラートが出る
            return alert(this.props.langText.Message.CommentInputPlaceholder);
        } else{
            try {
                let { selected } = this.state;
                this.state.selected.comment_data_json = this.state.selected.comment_data_json || "";
                //既存コメントに新コメント追加してコメントカラムアップデート
                let comment_data = [
                    ...this.state["selected"]["comment_data_json"],
                    ...[{
                        data: value,
                        created: moment().tz("Asia/Tokyo").format(),
                        entry_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                        entry_user_email: this.props.userInfo.user_id,
                        del_flag: "N"
                    }]
                ];
                let result = await this.ascAxios("post", `${this.reactContainerPath}/updateComment`, {id: selected.id, comment_data, type:"insert"});
                selected.comment_data_json = comment_data;

                this.setState({
                    selected,
                    comment_alert_flag: true,
                    comment_alert_message: this.props.langText.Message.CommentInserted,
                    insert_comment: ""
                });
                return result;
            } catch (err) {
                console.error(err);
                this.showErrorObjectMesssage(err, "DataInsertError");
            }
        }
    }

    /**
     * コメント更新
     * @param {Integer} index
     * @param {String} value
     * @return {Object}
     */
    updateCommentData = async(index, value) => {
        try {
            if(!value.trim()){
                //入力内容がない場合、アラートが出る
                return alert(this.props.langText.Message.CommentInputPlaceholder);
            }
            let { selected } = this.state;
            let comment_data = [...this.state["selected"]["comment_data_json"]];
            comment_data[index] = {
                ...comment_data[index],
                data: value,
                modified: moment().tz("Asia/Tokyo").format(),
                update_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                update_user_email: this.props.userInfo.user_id,
                del_flag: "N"
            }
            let result = await this.ascAxios("post", `${this.reactContainerPath}/updateComment`, {id: selected.id, comment_data, type:"update"});
            //更新するコメントの内容を入力したコメントに変えてアップデート
            selected.comment_data_json = comment_data;

            this.setState({
                selected,
                comment_alert_flag: true,
                comment_alert_message: this.props.langText.Message.CommentUpdated
            });
            return result;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataUpdateError");;
        }
    }

    /**
    * コメント削除
    * @param {Integer} index
    * @return {Object}
    */
    deleteCommentData = async(index) => {
        try {
            let { selected } = this.state;
            let comment_data = [...this.state["selected"]["comment_data_json"]];
            comment_data[index] = {
                ...comment_data[index],
                modified: moment().tz("Asia/Tokyo").format(),
                delete_user_name: this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei,
                delete_user_email: this.props.userInfo.user_id,
                del_flag: "Y"
            }
            let result = await this.ascAxios("post", `${this.reactContainerPath}/updateComment`, {id: selected.id, comment_data, index, type:"delete"});
            //削除されたコメントの内容をアップデート
            selected.comment_data_json = this.getCommentData(comment_data);

            this.setState({
                selected,
                comment_alert_flag: true,
                comment_alert_message: this.props.langText.Message.CommentDeleted
            });
            return result;
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err, "DataDeleteError");
        }
    }

    closeModal = () => {
        this.setState({show: false});
    }
    
    onClickHandle = async() => {
        let {
            selected,
            filtered,
            fileName,
        } = this.state;
        const cm12_id = filtered.filter(data => data.id === 'cm12_id')[0].value.value;
        switch (this.state.modalType) {
            case "analysis":
                let check_flag = (selected.flag_data_json.check_flag === "Y")? "N" : "Y";
                let message = check_flag ==="Y"? this.props.langText.Message.ConfirmMessage: this.props.langText.Message.UnconfirmMessage
                if(window.confirm(message)){
                    let check_modified = moment().tz("Asia/Tokyo").format();
                    try {
                        let result = await this.ascAxios("post", `${this.reactContainerPath}/updateCheckFlag`, {id: selected.id, check_flag, check_modified})
                        selected.flag_data_json.check_flag = check_flag
                        this.setState(
                            selected
                        );
                        check_flag === "Y"
                        ? alert(this.props.langText.Message.DataCheckSuccess)
                        : alert(this.props.langText.Message.DataUnconfirmedSuccess);

                        //一覧更新
                        this.execFullTextSearch(
                            cm12_id,
                            filtered,
                            this.formatForTableDisplay,
                            this.state.keywords
                        );
                        this.keywordColumnHandle()
                    } catch (err) {
                        console.error(err);
                        return this.showErrorObjectMesssage(err, "DataSelectError");
                    }
                }
                break;
            
            case "callContentDownload":
                let create_datetime = moment().tz("Asia/Tokyo").format("YYYYMMDDHHmmss");
                let {filter, display} = this.createFilteredInDownloadHistory(filtered);

                try {
                    this.setState({ show: false });
                    this.blockUI();

                    if (this.state.count) {
                        
                        await this.ascAxios("post", `${this.reactContainerPath}/startDownload`, {
                            company_id: this.props.userInfo.company_id,
                            department_id: this.props.userInfo.department_id,
                            user_id: this.props.userInfo.cm15_id,
                            download_type: "call_analysis_call_content",
                            file_name: fileName ? fileName : this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei + create_datetime,
                            condition: filter,
                            condition_display: display
                        });

                        alert(this.props.langText.Message.CallContentDownloadStartMessage);
                    } else {
                        alert(this.props.langText.Message.Download_Failed);
                    }


                    //一覧更新
                    this.execFullTextSearch(
                        cm12_id,
                        filtered,
                        this.formatForTableDisplay,
                        this.state.keywords
                    );
                    this.keywordColumnHandle();
                } catch (err) {
                    console.error(err);
                    return this.showErrorObjectMesssage(err, "DataInsertError");
                }
                
                break;
            default:
                break;
        }
    }

    // フィルターのキーワード加工
    // 表示する条件文字加工
    createFilteredInDownloadHistory = (filtered) => {
        const displayOrder = [
            "cm12_id",
            "display_number",
            "dest_tel_no",
            "start_datetime",
            "start_ji",
            "start_hun",
            "end_datetime",
            "end_ji",
            "end_hun",
            "keyword",
            "keyword_term",
            "comment",
            "inout_type",
            "check_flag",
            "tag"
        ];

        let keywords = this.state.keywords ? this.state.keywords : [];
        let filter = _.cloneDeep(filtered);
        let display = "";
        let keywordInFilter = null;

        if (keywords && keywords.length > 0) {
            for (let i = 0; i < filter.length; i++) {
                if (filter[i].id === "keyword") {
                    filter[i].value = keywords;
                    break;
                }
            }
        }

        let sorted = filter.sort((a, b) => {
            const indexA = displayOrder.indexOf(a.id);
            const indexB = displayOrder.indexOf(b.id);
            return indexA - indexB;
        });

        sorted.forEach(row => {
            switch (row.id) {
                case "cm12_id":
                    display += `${this.props.langText.Body.CompanyName}：${row.value.label}\n`;
                    break;
                case "display_number":
                    display += `${this.props.langText.Body.ExternalNumber}：${row.value.label}\n`;
                    break;
                case "dest_tel_no":
                    display += `${this.props.langText.Body.DestUserNumber}：${row.value}\n`;
                    break;
                case "start_datetime":
                    display += `${this.props.langText.Body.ExtensionStartDateTime}：${this.getMomentTime({date: row.value, format: "YYYY/MM/DD"})}`;
                    break;
                case "start_ji":
                    display += ` ${row.value.label}:`;
                    break;
                case "start_hun":
                    display += `${row.value.label}\n`;
                    break;
                case "end_datetime":
                    display += `${this.props.langText.Body.ExtensionEndDateTime}：${this.getMomentTime({date: row.value, format: "YYYY/MM/DD"})}`;
                    break;
                case "end_ji":
                    display += ` ${row.value.label}:`;
                    break;
                case "end_hun":
                    display += `${row.value.label}\n`;
                    break;
                case "keyword":
                    keywordInFilter = row;
                    break;
                case "keyword_term":
                    if (keywordInFilter) {
                        let keywordDisplay = "";
                        keywordInFilter.value.forEach(row => {
                            let isNot = row.isNot ? true : false
                            keywordDisplay += `${row.label};${isNot} `;
                        });
                        display += `${this.props.langText.Body.Keyword}(${row.value.label})：${keywordDisplay}\n`;
                    }
                    break;
                case "comment":
                    display += `${this.props.langText.Body.Comment}：${row.value}\n`;
                    break;
                case "inout_type":
                    let inoutValue = row.value === "all" ? this.props.langText.Body.All 
                        : row.value === "in" ? this.props.langText.Body.Inbound
                        : this.props.langText.Body.Call;
                    display += `${this.props.langText.Body.InOutType}：${inoutValue}\n`;
                    break;
                case "check_flag":
                    let checkValue = row.value === "all" ? this.props.langText.Body.All 
                        : row.value === "in" ? this.props.langText.Body.Confirmed
                        : this.props.langText.Body.Unconfirmed;
                    display += `${this.props.langText.Body.Check}：${checkValue}\n`;
                    break;
                case "tag":
                    let tagDisplay = "";
                    row.value.forEach(tag => {
                        tagDisplay += `${tag.label};${tag.rgb};${tag.font_rgb} `;
                    });
                    display += `${this.props.langText.Body.CallCategoryTag}：${tagDisplay}`;
                    break;
                default:
                    break;
            }
        });
        return {filter, display};
    }

    onClickHandleByTemplate = async (modalType) => {
        let {
            filtered,
            templateSelected,
            templateName,
            type,
            periodSetting,
            keywords,

        } = this.state;

        // template json作成
        for (let i = 0; i < filtered.length; i++) {
            if (
                filtered[i].id === "start_datetime" || filtered[i].id === "end_datetime"
                || filtered[i].id === "start_ji" || filtered[i].id === "end_ji"
                || filtered[i].id === "start_hun" || filtered[i].id === "end_hun"
            ) {
                filtered[i].value = this.state[filtered[i].id]
            }
        }

        let template_json = {
            filtered: filtered,
            period: periodSetting,
            keywords: keywords
        }

        try {
            switch (modalType) {
                case "templateInsert":
                case "templateCopy":
                    this.blockUI();
                    const insertResult = await this.ascAxios("post", `${this.reactContainerPath}/insertTemplate`, {
                        type,
                        template_name: templateName,
                        template_json
                    });

                    if (insertResult) {
                        alert(this.props.langText.Message.DataInsertSuccess);
                    }
                    break;
                case "templateUpdate":
                    this.blockUI();
                    const updateResult = await this.ascAxios("post", `${this.reactContainerPath}/updateTemplate`, {
                        id: templateSelected.id,
                        template_name: templateName,
                        template_json
                    });
    
                    if (updateResult) {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                    }
                    break;
                case "templateDelete":
                    this.blockUI();
                    const deleteResult = await this.ascAxios("post", `${this.reactContainerPath}/deleteTemplate`, {
                        id: templateSelected.id
                    });
    
                    if (deleteResult) {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                    }
                    break;
            
                default:
                    break;
            }

            await this.getTemplateList(this.state.type);
            let template = null;
            
            if (modalType === "templateCopy" || modalType === "templateUpdate") {
                template = this.state.templateSelect.filter(row => row.id === this.state.templateSelected.id)[0];
            }
            this.onSelectChange(template, "templateSelected");
            if (modalType === "templateDelete") this.clearSearchCondition();
            this.setState({ show: false });
        } catch (err) {
            console.error(err);
            this.showErrorObjectMesssage(err)
        }
    }

    validationHandle = param => {
        let {
            templateName,
            periodSetting
        } = this.state;
        let nowState = null;
        switch(param) {
            case "analysis":
                nowState = this.props.currentPermission.edit && true
                break;

            case "templateName":
                nowState = this.state[param] ? null : 'error';
                break;
            case "date":
                nowState = (
                    this.getDateValidationCheck() ||
                    periodSetting.value !== "fixed"
                )
                break;

            case "templateInsert":
            case "templateCopy":
            case "templateUpdate":
                nowState = (
                    templateName &&
                    (this.getDateValidationCheck() || 
                    periodSetting.value !== "fixed")
                );
                break;

            case "templateDelete":
                nowState = true;
                break;

            case "callContentDownload":
                nowState = true;
                break;
            default:
                break;
        }
        return nowState;
    }

    // キーワードでソートする時、キーワードが空欄の場合、テーブルのカラム調整
    keywordColumnHandle = () => {
        let keywordColumnFlag = false;
        let keywords = this.getFilterData("keyword");

        if (keywords && keywords.length > 0) {
            keywordColumnFlag = keywords.some(row => !row.isNot)
        }

        this.setState({ keyword_flag: keywordColumnFlag })
    }

    onIntervalChange = (e) => {
        let {autoUpdateInterval} = this.state;
        let stateInterval;
        if(autoUpdateInterval.value == 1){
            //自動更新無しが選択された場合は自動更新を止める
            this.onSearch(this.state.filtered);
            return clearInterval(this.state.interval);
        }else if(autoUpdateInterval.value != 1 && e){
            //自動更新間隔が選択されたら一回画面更新後、自動更新
            clearInterval(this.state.interval);
            this.onSearch(this.state.filtered);
            stateInterval = setInterval(() => {
                this.onSearch(this.state.filtered);
            }, autoUpdateInterval.value);
            this.setState({interval : stateInterval, disableFlg: true});
        }
    }

    onSelectChange = (event, param) => {
        switch (param) {
            case "autoUpdateInterval":
                this.onChange(event, "autoUpdateInterval");
                clearInterval(this.state.interval);
                this.setState({disableFlg: false});
                break;
            
            case "templateSelected":
                if (event) {
                    let template_json = _.cloneDeep(event.template_json);
                    let template_filtered = template_json.filtered;
                    let period = template_json.period.value;
                    let keywords = template_json.keywords ? template_json.keywords : [];

                    // 文字から検索フィルターに変更
                    for (let i = 0; i < template_filtered.length; i++) {
                        if (template_filtered[i].id === "cm12_id" && this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) this.onChange(template_filtered[i].value, template_filtered[i].id, true);
                        if (period === "fixed") {
                            if (template_filtered[i].id === "start_datetime" || template_filtered[i].id === "end_datetime") {
                                template_filtered[i].value = moment.tz(template_filtered[i].value, "Asia/Tokyo");
                            }
                        } else {
                            if (
                                template_filtered[i].id === "start_datetime" || template_filtered[i].id === "end_datetime"
                                || template_filtered[i].id === "start_ji" || template_filtered[i].id === "end_ji"
                                || template_filtered[i].id === "start_hun" || template_filtered[i].id === "end_hun"
                            ) {
                                template_filtered[i].value = this.onPeriodChange(period, template_filtered[i].id)
                            }
                        }
                        if (template_filtered[i].id === "tag") {
                            template_filtered[i].value = template_filtered[i].value.map(row => {
                                return {
                                    ...row,
                                    label: (
                                        <span
                                            style={row.label.props.style}
                                        >
                                            {row.label.props.children}
                                        </span>
                                    )
                                };
                            })
                        }
                    }

                    this.setState({
                        filtered: template_filtered,
                        hidden_filter: template_filtered,
                        keywords
                    });
                }
                this.setState({
                    templateSelected: event
                });
                break;

            default:
                this.setState({[param]: event});
                break;
        }
    }

    onPeriodChange = (value, param) => {
        if (value === "fixed") return;

        let return_obj = {
            start_datetime: moment().startOf("day"),
            end_datetime: moment().endOf("day"),
            start_ji: {value: '00', label: '00'},
            start_hun: {value: '00', label: '00'},
            end_ji: {value: '23', label: '23'},
            end_hun: {value: '59', label: '59'},
        }

        switch (value) {
            case "yesterday":
                return_obj.start_datetime = moment().subtract(1, "days").startOf("day");
                return_obj.end_datetime = moment().subtract(1, "days").endOf("day");
                break;
            case "this_week":
                return_obj.start_datetime = moment().startOf("week");
                return_obj.end_datetime = moment().endOf("week");
                break;
            case "last_week":
                return_obj.start_datetime = moment().subtract(1, "week").startOf("week");
                return_obj.end_datetime = moment().subtract(1, "week").endOf("week");
                break;
            case "this_month":
                return_obj.start_datetime = moment().startOf('month');
                return_obj.end_datetime = moment().endOf('month');
                break;
            case "last_month":
                return_obj.start_datetime = moment().subtract(1, 'month').startOf('month');
                return_obj.end_datetime = moment().subtract(1, 'month').endOf('month');
                break;
            default:
                break;
        }

        return return_obj[param]
    }

    onPlayHandle = (audio) => {
        try {
            if (audio.played["length"] === 0 && audio.currentTime === 0) {
                this.saveAccessLogs({
                    "resource" : `${this.reactContainerPath}/voiceLogPlay`, 
                    "body" : {
                        "voice_log_url" : audio.currentSrc
                    }
                });
            }
        } catch (err) {
            console.log(err);
        }
    }

    getModalBranch = () => {
        switch (this.state.modalType) {
            case "analysis":
            case "callContentDownload":
                return (
                    <CallAnalysisModal
                        userInfo = {this.props.userInfo}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                        boardWidth = {this.props.boardWidth}
                        copyData = {this.copyData}
                        insertCommentData = {this.insertCommentData}
                        updateCommentData = {this.updateCommentData}
                        deleteCommentData = {this.deleteCommentData}
                        updateVoiceData = {this.updateVoiceData}
                        getFilterData = {this.getFilterData}
                        getKeywordData = {this.getKeywordData}
                        currentPermission = {this.props.currentPermission}
                        customBtn1Func = {this.onClickHandle}
                        customBtn2Func = {this.closeModal}
                        onPlayHandle = {this.onPlayHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        updateCallCategoryTag = {this.updateCallCategoryTag}
                    />
                );
                
            case "templateInsert":
            case "templateUpdate":
            case "templateCopy":
                return (
                    <CallAnalysisTemplateModal
                        userInfo = {this.props.userInfo}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandleByTemplate}
                        getFilterData = {this.getFilterData}
                        currentPermission = {this.props.currentPermission}
                        validationHandle = {this.validationHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onChange = {this.onChange}
                        hourShowList = {this.hourShowList}
                        minuteShowList = {this.minuteShowList}
                        TemplatePeriodOption = {this.TemplatePeriodOption}
                        getScopeGreaterEqual = {this.getScopeGreaterEqual}
                        reactTableRefresh={this.reactTableRefresh}
                        reactContainerPath={this.reactContainerPath}
                        getTitle={this.getTitle}
                    />
                );
            
            case "templateDelete":
                return (
                    <CallAnalysisTemplateCheckModal
                        userInfo={this.props.userInfo}
                        state={this.state}
                        propState={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandleByTemplate}
                        getFilterData={this.getFilterData}
                        validationHandle={this.validationHandle}
                        onChange={this.onChange}
                        TemplatePeriodOption={this.TemplatePeriodOption}
                        getScopeGreaterEqual={this.getScopeGreaterEqual}
                        reactContainerPath={this.reactContainerPath}
                        getTitle={this.getTitle}
                    />
                );
        
            default:
                break;
        }
    }

    clearSearchCondition = async () => {
        let filter = _.cloneDeep(this.state.default_filtered);
        let hidden_filter = _.cloneDeep(this.state.default_filtered);
        
        this.setState({
            filtered: filter,
            hidden_filtered: hidden_filter,
            keywords: [],
            templateSelected: null,
            autoUpdateInterval: this.props.langText.SelectOption.autoUpdateIntervalList[0]
        })
        clearInterval(this.state.interval);
        await this.getExternalList(this.props.userInfo.company_id);
    }

    onInputChangeHandle = (input) => {
        this.setState({
            inputValue: input
        });
    }

    getTitle = () => {
        let {
            modalType
        } = this.state;
        let title = null;

        switch (modalType) {
            case "templateInsert":
            case "templateCopy":
                title = this.props.langText.Body.CallAnalysisTemplateInsert;
                break;
            case "templateUpdate":
                title = this.props.langText.Body.CallAnalysisTemplateUpdate;
                break;
            case "templateDelete":
                title = this.props.langText.Body.CallAnalysisTemplateDelete;
                break;
        
            default:
                break;
        }

        return title
    }

    downloadHistory = (path) => {
        const downloadHistoryPermission= this.props.userInfo.permission_json_data.find(
            (permission) => permission.controller_id === "DownloadHistory"
        );
        if (downloadHistoryPermission && downloadHistoryPermission.read) {
            this.props.historyPush({
                pathname: path
            });
        } else {
            alert(this.props.langText.Message.PathAuthCheckError);
        }
    }

    updateCallCategoryTag = async (ct60_id, tag_ids) => {
        try {
            const result = await this.ascAxios("post", `${this.reactContainerPath}/updateCallCategoryTag`, {ct60_id, tag_ids});
            return true;
        } catch (err) {
            console.error(err);   
            this.showErrorObjectMesssage(err); 
        }
    }

    createTooltipContent = (type) => {
        let {
            langText
        } = this.props;
        let text = "";

        switch (type) {
            case "KeywordRegExpDescription":
                text = (
                    <>
                        <p>
                            {langText.Message[type][0]}<br />
                            {langText.Message[type][1]}
                        </p>
                    </>
                );
                break;
            default:
                break;
        }

        return (
            <div>
                {text}
            </div>
        );
    }

    render() {
        const DropdownIndicator = props => {
            return (
                components.DropdownIndicator && (
                    <components.DropdownIndicator {...props} className="dropdown-indicator-container">
                        <Button
                            key={0}
                            bsSize="xsmall"
                            onClick={this.modalShow(null, "templateUpdate")}
                            className="control-button"
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow(null, "templateCopy")}
                            className="control-button"
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                        <Button
                            key={2}
                            bsSize="xsmall"
                            onClick={this.modalShow(null, "templateDelete")}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    </components.DropdownIndicator>
                )
            );
        }

        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <div className="CallAnalysis">
                    <Row>
                    <Col xs={12} md={12}>
                        {/* OEM以上は会社選択表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col>
                                <Col sm={4} md={2} lg={1} className="analysis-label">
                                    <div className="margin-top-05">{this.props.langText.Body.CompanyName}</div>
                                </Col>
                                <Col sm={12} md={12} lg={3}>
                                    <Select
                                        defaultValue = {this.state.companySelect[3]}
                                        value = {this.getFilterData("cm12_id")}
                                        onChange = {
                                            e => this.onChange(e, "cm12_id")
                                        }
                                        options={this.state.companySelect}
                                        placeholder = {!this.state.companySelect[0] ?
                                            <Glyphicon glyph="refresh" className="spinning"/> : this.props.langText.SelectOption.Placeholder}/>
                                </Col>
                            </Col>
                            :
                            null
                        }
                        <Col sm={6} md={2}>
                        </Col>
                        {/* システム管理者は通話履歴ID検索表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)
                            ?
                            <Col>
                                <Col sm={4} md={2} lg={1} className="analysis-label">
                                    <div className="margin-top-05">
                                        <SetDataWithSpeechBubble
                                            displayData={this.props.langText.Body.CallHistoryId}
                                            speechBubbleData = {this.props.langText.Message.IdOnlySearch}
                                            infoIconDisplayFlag = {true}
                                            infoIconType = {faIcon.faInfoCircle}
                                            infoIconClassName = "info-icon margin-left-01"
                                            infoIconColor = "silver"
                                            infoIconSize = "lg"
                                        />
                                    </div>
                                </Col>
                                <Col sm={12} md={4} lg={2}>
                                    <FormControl
                                        onChange = {e => this.onChange(e, "ct60_id")}
                                        value = {this.getFilterData("ct60_id")}
                                    />
                                </Col>
                            </Col>
                            :
                            null
                        }
                    </Col>
                        <Col xs={12} md={12} className="margin-top-03 flex-style">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.CustomerBoardExtension}</div>
                            </Col>
                            <Col sm={12} md={3} lg={2} className="padding-right-3">
                                <Select
                                    key = "display_number"
                                    value = {this.getFilterData("display_number")}
                                    onChange = {
                                        e => this.onChange(e, "display_number")
                                    }
                                    options = {this.state.externalArr}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_defaultexternal}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.StartDate}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                    <DatePicker
                                        onChange = {e => this.onChange(e, "start_datetime")}
                                        selected = {this.getFilterData("start_datetime")? this.getFilterData("start_datetime"): null}
                                        dateFormat = "YYYY/MM/DD"
                                        className = "form-control"
                                        todayButton = {this.props.langText.Body.Today}
                                        placeholderText = {this.props.langText.Body.StartDate}
                                        maxDate={this.getFilterData("end_datetime")?this.getFilterData("end_datetime"):null}
                                    />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.StartTime}</div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "start_ji"
                                    value = {this.getFilterData("start_ji")}
                                    onChange = {
                                        e => this.onChange(e, "start_ji")
                                    }
                                    options={this.hourShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "start_hun"
                                    value = {this.getFilterData("start_hun")}
                                    onChange = {
                                        e => this.onChange(e, "start_hun")
                                    }
                                    options={this.minuteShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "start_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col className = "seatview-form margin-left-auto call-analysis-interval" sm={4} md={4} lg={3}>
                                <Col sm={9} md={9} lg={9}>
                                    <Select
                                        defaultValue = {
                                            this.props.langText.SelectOption.autoUpdateIntervalList[0]
                                        }
                                        onChange = {e => this.onSelectChange(e, "autoUpdateInterval")}
                                        options = {this.props.langText.SelectOption.autoUpdateIntervalList}
                                        value = {this.state.autoUpdateInterval}
                                        isDisabled = {(!this.state.filtered.some(row => row.id === "display_number"))}
                                    />
                                </Col>
                                <Col sm={1} md={1} lg={1}>
                                    <Button
                                        className = "auto-update-btn"
                                        bsStyle = "primary"
                                        bsSize = "sm"
                                        onClick = {e => this.onIntervalChange(e)}
                                        disabled = {this.state.disableFlg}
                                    >
                                        <Glyphicon glyph="refresh"/>
                                    </Button>
                                </Col>
                            </Col>
                        </Col>
                        <Col xs={12} md={12} className="margin-top-03 flex-style">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.DestUserNumber}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="padding-right-3">
                                <FormControl
                                    onChange = {e => this.onChange(e, "dest_tel_no")}
                                    value = {this.getFilterData("dest_tel_no")}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.EndDate}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2}>
                                <DatePicker
                                    onChange = {e => this.onChange(e, "end_datetime")}
                                    selected = {this.getFilterData("end_datetime")? this.getFilterData("end_datetime"): null}
                                    dateFormat = "YYYY/MM/DD"
                                    className = "form-control"
                                    todayButton = {this.props.langText.Body.Today}
                                    placeholderText = {this.props.langText.Body.EndDate}
                                    minDate = {this.getFilterData("start_datetime")?this.getFilterData("start_datetime"):null}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.EndTime}</div>
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "end_ji"
                                    value = {this.getFilterData("end_ji")}
                                    onChange = {
                                        e => this.onChange(e, "end_ji")
                                    }
                                    options={this.hourShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "end_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col sm={4} md={2} lg={1}>
                                <Select
                                    key = "end_hun"
                                    value = {this.getFilterData("end_hun")}
                                    onChange = {
                                        e => this.onChange(e, "end_hun")
                                    }
                                    options={this.minuteShowList}
                                    isDisabled = {(!this.state.filtered.some(row => row.id === "end_datetime"))}
                                    placeholder = "---"
                                />
                            </Col>
                            <Col className="seatview-form margin-left-auto call-analysis-interval" sm={4} md={4} lg={3}>
                            </Col>
                        </Col>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05 call-analysis-label">{this.props.langText.Body.InOutType}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="call-analysis-button-group">
                                <ButtonGroup className="analysis-btn">
                                    <Button
                                        value = "all"
                                        onClick = {e => this.onChange(e , "inout_type")}
                                        className = {this.state.filtered.map(row => row.id === "inout_type" && row.value === "all" ? "analysis-btn-focus":"")}
                                    >
                                        {this.props.langText.Body.All}
                                    </Button>
                                    <Button
                                        value = "in"
                                        onClick = {e => this.onChange(e , "inout_type")}
                                        className = {`call-analysis-button ${this.state.filtered.some(row => row.id === "inout_type" && row.value === "in") ? "analysis-btn-focus":""}`}
                                    >
                                        {this.props.langText.Body.Inbound}
                                    </Button>
                                    <Button
                                        value = "out"
                                        onClick = {e => this.onChange(e , "inout_type")}
                                        className = {`call-analysis-button ${this.state.filtered.some(row => row.id === "inout_type" && row.value === "out") ? "analysis-btn-focus":""}`}
                                    >
                                        {this.props.langText.Body.Call}
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{
                                    <SetDataWithSpeechBubble 
                                        displayData={this.props.langText.Body.Keyword}
                                        speechBubbleData={this.createTooltipContent("KeywordRegExpDescription")}
                                        infoIconDisplayFlag={true}
                                        infoIconType={faIcon.faInfoCircle}
                                        infoIconClassName="info-icon"
                                        infoIconColor="silver"
                                        infoIconSize="lg"
                                        placement="top"
                                        custom="custom"/>
                                }</div>
                            </Col>
                            <Col sm={12} md={4} lg={3} className="keyword-col">
                                <KeywordSelect
                                    state={this.state}
                                    onChange={this.onChange}
                                    langText={this.props.langText}
                                    onInputChangeHandle={this.onInputChangeHandle}
                                    propSetState={this.propSetState}
                                />
                            </Col>
                            <Col sm={4} md={2} lg={2} className="keyword-term-col">
                                <Select
                                    onChange = {e => this.onChange(e, "keyword_term")}
                                    options = {this.props.langText.SelectOption.KeywordTermOption}
                                    value = {this.getFilterData("keyword_term")}
                                />
                            </Col>
                            {
                                this.state && this.state.search_company_parameter_call_auto_tag &&
                                <>
                                    <Col sm={4} md={2} lg={1} className="padding-left-0">
                                        <div className="margin-top-05">{this.props.langText.Body.CallCategoryTag}</div>
                                    </Col>
                                    <Col sm={4} md={4} lg={2} className="padding-left-0 keyword-term-col">
                                        <CallTagSelect
                                            options={this.state.tagSelect}
                                            onChange={this.onChange}
                                            value={this.getFilterData("tag")}
                                            langText={this.props.langText}
                                            param={"tag"}
                                        />
                                    </Col>
                                </>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-03">
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.Check}</div>
                            </Col>
                            <Col sm={12} md={4} lg={2} className="call-analysis-button-group">
                                <ButtonGroup className="analysis-btn">
                                    <Button
                                        value = "all"
                                        onClick = {e => this.onChange(e , "check_flag")}
                                        className = {this.state.filtered.map(row => row.id === "check_flag" && row.value === "all" ? "analysis-btn-focus":"")} 
                                    >
                                        {this.props.langText.Body.All}
                                    </Button>
                                    <Button
                                        value = "true"
                                        onClick = {e => this.onChange(e , "check_flag")}
                                        className = {this.state.filtered.map(row => row.id === "check_flag" && row.value === "true" ? "analysis-btn-focus":"")} 
                                    >
                                        {this.props.langText.Body.Confirmed}
                                    </Button>
                                    <Button
                                        value = "false"
                                        onClick = {e => this.onChange(e , "check_flag")}
                                        className = {this.state.filtered.map(row => row.id === "check_flag" && row.value === "false" ? "analysis-btn-focus":"")} 
                                    >
                                        {this.props.langText.Body.Unconfirmed}
                                    </Button>
                                </ButtonGroup>
                            </Col>
                            <Col sm={4} md={2} lg={1} className="analysis-label">
                                <div className="margin-top-05">{this.props.langText.Body.Comment}</div>
                            </Col>
                            <Col sm={12} md={4} lg={5}>
                                <FormControl
                                    onChange = {e => this.onChange(e, "comment")}
                                    value = {this.getFilterData("comment")}
                                />
                            </Col>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05 flex-style">
                            <Col sm={1} md={1} lg={1} className="search-button-col">
                                <Button
                                    id="customer-insert"
                                    className="searchBtn"
                                    bsStyle='primary'
                                    bsSize='sm'
                                    onClick={() => this.onSearch(this.state.filtered)}
                                    disabled = {this.state.loading || this.state.searchValidation}
                                >
                                    {this.props.langText.Body.Search}
                                </Button>
                            </Col>
                            <Col sm={1} md={1} lg={1} className="clear-button-col">
                                <Button
                                    id="clear-button"
                                    className="clearBtn"
                                    bsSize='sm'
                                    onClick={() => this.clearSearchCondition()}
                                >
                                    {this.props.langText.Body.Clear}
                                </Button>
                            </Col>
                            <Col sm={12} md={4} lg={3}>
                                <Select
                                    key = "templateSelect"
                                    value = {this.state.templateSelected}
                                    isClearable
                                    onChange = {
                                        e => this.onSelectChange(e, "templateSelected")
                                    }
                                    components = {this.state.templateSelected ? { DropdownIndicator } : null}
                                    options = {this.state.templateSelect}
                                    placeholder = {this.props.langText.SelectOption.Placeholder_Template}
                                />
                            </Col>
                            <Col sm={1} md={1} lg={1}>
                                <Button
                                    id="template-button"
                                    className="template-btn"
                                    bsStyle='primary'
                                    bsSize='sm'
                                    onClick={this.modalShow(null, "templateInsert")}
                                    disabled = {this.state.loading || this.state.searchValidation}
                                >
                                    {this.props.langText.Body.CallAnalysisTemplateInsert}
                                </Button>
                            </Col>
                            {
                                this.props.currentPermission.export &&
                                <Col sm={12} md={4} lg={3} className="text-right margin-left-auto">
                                    <div>
                                        <Dropdown
                                            onClick={e => {}}
                                        >
                                            <Dropdown.Toggle 
                                                className='table-button'
                                                bsStyle='default'
                                                bsSize='sm'>
                                                {this.props.langText.Body.Download}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className="dropdown-menu-right call-analysis-dropdown-menu">
                                                <Button
                                                    id="callanalysis-download"
                                                    className='table-button'
                                                    bsStyle='default'
                                                    bsSize='sm'
                                                    onClick={this.CallAnalysisExcelDownload}
                                                    disabled = {this.state.loading || this.state.searchValidation}
                                                >
                                                    <SetDataWithSpeechBubble
                                                        displayData={this.props.langText.Body.CallAnalysisDownload}
                                                        speechBubbleData = {this.props.langText.Message.MaxDownloadDataCount}
                                                        infoIconDisplayFlag = {true}
                                                        infoIconType = {faIcon.faInfoCircle}
                                                        infoIconClassName = "info-icon margin-left-05"
                                                        infoIconColor = "white"
                                                        infoIconSize = "lg"/>
                                                </Button>
                                                <Button
                                                    id="callanalysis-content-download"
                                                    className='table-button'
                                                    bsStyle='default'
                                                    bsSize='sm'
                                                    onClick={this.modalShow(null, "callContentDownload")}
                                                    disabled = {this.state.loading || this.state.searchValidation}
                                                >
                                                    <SetDataWithSpeechBubble
                                                        displayData={this.props.langText.Body.CallContentDownload}
                                                        speechBubbleData = {this.props.langText.Message.CallContentDownloadButtonMessage}
                                                        infoIconDisplayFlag = {true}
                                                        infoIconType = {faIcon.faInfoCircle}
                                                        infoIconClassName = "info-icon margin-left-05"
                                                        infoIconColor = "white"
                                                        infoIconSize = "lg"/>
                                                </Button>
                                                <Button
                                                    bsStyle="link"
                                                    onClick={() => this.downloadHistory("DownloadHistory")}
                                                >
                                                    {this.props.langText.Body.DownloadHistory}
                                                </Button>
                                                <CSVLink
                                                    data={this.state.csvdata}
                                                    className="hidden"
                                                    ref={this.csvLink}
                                                />
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Col>
                            }
                        </Col>
                        <Col xs={12} md={12}>
                            <Col sm={12} md={4} lg={2} className="search-count">
                                {
                                    this.state.count <= this.state.count_limit &&
                                    <label>{this.props.langText.Body.SearchResult}</label>
                                }
                                {
                                    this.state.count 
                                    ? this.state.count > this.state.count_limit 
                                        ? <SetDataWithSpeechBubble
                                            displayData={(
                                                <>
                                                    <label>{this.props.langText.Body.SearchResult}</label>
                                                    <label className="result-count-limit">{this.state.count_limit.toLocaleString()}+{this.props.langText.Body.Count}</label>
                                                </>
                                            )}
                                            speechBubbleData={this.sprintf(this.props.langText.Message.SearchCountLimitMessage, this.state.count.toLocaleString())}
                                            infoIconDisplayFlag={true}
                                            infoIconType={faIcon.faExclamationCircle}
                                            infoIconClassName="info-icon"
                                            infoIconColor="red"
                                            infoIconSize="lg"
                                        />
                                        : <label className="result-count">{this.state.count.toLocaleString()}{this.props.langText.Body.Count}</label>
                                    : <label className="result-count">0{this.props.langText.Body.Count}</label>
                                }
                            </Col>
                            {
                                this.state.message &&
                                <Col sm={12} md={4} lg={3}>
                                    <label className="search-message">{this.state.message}</label>
                                </Col>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05">
                            <CommonTable
                                tableState = {this.state}
                                propSetState = {this.propSetState}
                                talbeRef={this.table}
                                style={{height: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)? this.props.tableHeight-180: this.props.tableHeight-140}}
                                manual="manual"
                                columns = {this.getColumnsData()}
                                data={this.state.data}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                defaultSorted={[{id: "start_datetime", desc: false}]}
                                onFetchData={async (state) => {
                                    if (!this.getFilterData("ct60_id") && !this.getFilterData("display_number")) return;
                                    let cm12Id = this.state.filtered.filter(
                                        data => data.id === "cm12_id"
                                    );
                                    if (
                                        cm12Id &&
                                        cm12Id[0].value &&
                                        cm12Id[0].value.value
                                    ) {
                                        cm12Id = cm12Id[0].value.value;
                                    } else {
                                        cm12Id = this.state.cm12_id;
                                    }
                                    await this.execFullTextSearch(
                                        cm12Id,
                                        this.state.filtered,
                                        this.formatForTableDisplay,
                                        this.state.keywords,
                                        state
                                    );
                                    this.keywordColumnHandle();
                                }}
                                filtered={this.state.filtered}
                                hidden_filtered={this.state.hidden_filtered}
                                previousText={this.props.langText.Table.PreviousText}
                                nextText={this.props.langText.Table.NextText}
                                loadingText={this.props.langText.Table.LoadingText}
                                noDataText={this.props.langText.Table.NoDataText}
                                rowsText={this.props.langText.Table.RowsText}/>
                        </Col>
                    </Row>
                    {this.getModalBranch()}
                </div>
            </BlockUi>
        );
    }
}
