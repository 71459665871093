import { ApolloClient, InMemoryCache, ApolloLink, HttpLink } from "@apollo/client/core";
import { createAuthLink, AuthOptions } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";

export default function getClient({ url, region, apiKey }) {
    const auth = {
        type: "API_KEY",
        apiKey: apiKey
    };
    const link = ApolloLink.from([
        createAuthLink({ url, region, auth }),
        createSubscriptionHandshakeLink({ url, region, auth })
    ]);
    const client = new ApolloClient({
        link: link,
        cache: new InMemoryCache()
    });
    return client;
}
