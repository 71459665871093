import React, {Component} from "react"
import {Panel, Col, FormControl, Glyphicon, Button, Row} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import MultiValueForm from "../Elements/FromGroup/MultiValueForm"
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";


export default class CustomerModal extends Component {
    getCustomerInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onSelectChange,
            onMultiValueFormTextChange,
            onMultiValueFormSizeChange,
            validationHandle,
            getScopeGreaterEqual
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            )
        }

        if (state.modalType === "insert" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    key = "customerName"
                    controlId = "customerName"
                    label = {langText.Body.CustomerNameCc}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm
                            key = "firstNameCc"
                            controlId = "firstNameCc"
                            label = {langText.Body.FirstNameCc}
                            type = "text"
                            value = {state.firstNameCc}
                            onChange = {e => onTextChange_Limit(e, "firstNameCc",charaLimit.Customer_firstNameCc)}
                            validationState = {validationHandle("firstNameCc")}/>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm
                            key = "lastNameCc"
                            controlId = "lastNameCc"
                            label = {langText.Body.LastNameCc}
                            type = "text"
                            value = {state.lastNameCc}
                            onChange = {e => onTextChange_Limit(e, "lastNameCc",charaLimit.Customer_lastNameCc)}
                            validationState = {validationHandle("lastNameCc")}/>
                    </Col>
                </InlineForm>
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm
                    key = "customerName"
                    controlId = "customerName"
                    label = {langText.Body.CustomerNameCc}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm.static
                            key = "firstNameCc"
                            controlId = "firstNameCc"
                            label = {langText.Body.FirstNameCc}>
                            {state.firstNameCc}
                        </InlineForm.static>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm.static
                            key = "lastNameCc"
                            controlId = "lastNameCc"
                            label = {langText.Body.LastNameCc}>
                            {state.lastNameCc}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
            );
        }

        if (state.modalType === "insert" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    key = "customerNameKana"
                    controlId = "customerNameKana"
                    label = {langText.Body.CustomerNameKana}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm
                            key = "firstNameKana"
                            controlId = "firstNameKana"
                            label = {langText.Body.FirstNameKana}
                            type = "text"
                            value = {state.firstNameKana}
                            onChange = {e => onTextChange_Limit(e, "firstNameKana",charaLimit.Customer_firstNameKana)}
                            validationState = {validationHandle("firstNameKana")}/>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm
                            key = "lastNameKana"
                            controlId = "lastNameKana"
                            label = {langText.Body.LastNameKana}
                            type = "text"
                            value = {state.lastNameKana}
                            onChange = {e => onTextChange_Limit(e, "lastNameKana",charaLimit.Customer_lastNameKana)}
                            validationState = {validationHandle("lastNameKana")}/>
                    </Col>
                </InlineForm>
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm
                    key = "customerNameKana"
                    controlId = "customerNameKana"
                    label = {langText.Body.CustomerNameKana}>
                    <Col sm={5} className="firstName-form">
                        <InlineForm.static
                            key = "firstNameKana"
                            controlId = "firstNameKana"
                            label = {langText.Body.FirstNameKana}>
                            {state.firstNameKana}
                        </InlineForm.static>
                    </Col>

                    <Col sm={5} className="lastName-form">
                        <InlineForm.static
                            key = "lastNameKana"
                            controlId = "lastNameKana"
                            label = {langText.Body.LastNameKana}>
                            {state.lastNameKana}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
            );
        }

        if (state.modalType === "insert" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    key = "customerCompanyName"
                    controlId = "customerCompanyName"
                    label = {langText.Body.CustomerCompanyName}
                    type = "text"
                    value = {state.customerCompanyName}
                    onChange = {e => onTextChange_Limit(e, "customerCompanyName",charaLimit.Customer_ComnpanyName)}/>
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key = "customerCompanyName"
                    controlId = "customerCompanyName"
                    label = {langText.Body.CustomerCompanyName}>
                    {state.customerCompanyName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    key = "mailAddress"
                    controlId = "mailAddress"
                    label = {langText.Body.EmailAddress}
                    type = "text"
                    value = {state.mailAddress}
                    onChange = {e => onTextChange_Limit(e, "mailAddress",charaLimit.Customer_mailAddress)}/>
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key = "mailAddress"
                    controlId = "mailAddress"
                    label = {langText.Body.EmailAddress}>
                    {state.mailAddress}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    label = {langText.Body.Telno}>
                    <MultiValueForm
                    key = "telNo"
                    controlId = "telNo"
                    value = {state.telNo}
                    isStatic = {state.modalType === "delete"}
                    onTextChange = {onMultiValueFormTextChange}
                    onSizeChange = {onMultiValueFormSizeChange}
                    placeholderTag = {langText.Body.Tag}
                    placeholderValue = {langText.Body.Telno}
                    validationState  = {validationHandle}/>
                </InlineForm>
                
            )
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm
                    label = {langText.Body.Telno}>
                    <MultiValueForm
                    key = "telNo"
                    controlId = "telNo"
                    value = {state.telNo}
                    isStatic = {state.modalType}
                    onTextChange = {onMultiValueFormTextChange}
                    onSizeChange = {onMultiValueFormSizeChange}
                    placeholderTag = {langText.Body.Tag}
                    placeholderValue = {langText.Body.Telno}
                    validationState  = {validationHandle}/>
                </InlineForm>
                
            )
        }

        return itemArr;
    }

    getCsvImportInfoItem = () => {
        let {
            langText,
            state,
            onFileChange,
            onSelectChange,
            validationHandle,
            getbtnValue,
            getScopeGreaterEqual
        } = this.props;
        let itemArr = [];

        if (state.modalType === "csvInsert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            )
        }

        itemArr.push(
            <Row>
                <Col sm={5}> 
                    <Button
                        key = "template"
                        value='CUSTOMER_IMPORT_TEMPLATE'
                        onClick={getbtnValue}>
                        {langText.Body.CustomerFormatDownload}
                    </Button>
                </Col>
                <Col sm={7}>
                    <FormControl
                        key = "csvFile"
                        type = "file"
                        onChange = {e => onFileChange(e, "csvFile")}
                        accept = ".csv"/>
                </Col>
            </Row>
        );

        return itemArr;
    }

    render() {
        let panelElem = null;

        if (this.props.state.modalType === "csvInsert") {
            panelElem = (
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.FileUpload}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getCsvImportInfoItem()}
                    </Panel.Body>
                </Panel>
            );
        } else {
            panelElem = (
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.CustomerInsert}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getCustomerInfoItem()}
                    </Panel.Body>
                </Panel>
            );
        }

        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                {panelElem}
            </AscModal>
        );
    }
}