import React from "react";
import { Button, Row, Col, FormControl, Glyphicon, Dropdown, MenuItem } from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import InlineForm from "../Elements/FromGroup/InlineForm";
import * as GlobalConst from "../../components/AscConstants";
import { ApolloProvider } from "@apollo/client";
import client from "../CallMonitor/ApolloClient";
import ExternalMonitor from "./ExternalMonitor";
import ExtensionMonitor from "./ExtensionMonitor";
import fscreen from "fscreen";

// ACDモニタリング画面
export default class CallMonitor extends AscComponent {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filtered: [],
            pages: null,
            loading: false,
            autoUpdateInterval: 10,
            client: undefined,
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],
            // 会社管理者の権限の場合、会社情報をセット
            company_id: !this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                ? this.props.userInfo.company_id
                : null,
            // 拠点管理者の場合、拠点情報をセット
            cm13_id: !this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)
                ? this.props.userInfo.parent_department_id
                : null,
            select_department_id:
                !this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)
                    ? this.props.userInfo.parent_department_id || this.props.userInfo.department_id
                    : null,
            // フロア管理者でフロアがある場合、フロア情報をセット
            select_floor_id:
                !this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.floor) &&
                this.props.userInfo.floor_flg
                    ? this.props.userInfo.department_id
                    : null,
            select_floor_flg: false,
            floor_flg: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                ? false
                : this.props.userInfo.floor_flg,
            external_number_list: [],
            select_external_number: [],

            CallMonitorPermission: this.props.currentPermission,

            arg_department_id: [],
            isFullscreen: false,
            isExternalSearchMode: false,

            giveup_interval_time: 30,
            inbound_alert_time: 10,
            giveup_interval_list: [
                { value: 30, label: "30分" },
                { value: 60, label: "1時間" }
            ],
            inbound_alert_interval_list: [
                { value: 10, label: "10秒" },
                { value: 30, label: "30秒" },
                { value: 60, label: "1分" },
                { value: 120, label: "2分" },
                { value: 180, label: "3分" },
                { value: 300, label: "5分" },
                { value: 600, label: "10分" }
            ],

            extension_list_interval_list: [
                { value: 10, label: "10秒" },
                { value: 30, label: "30秒" },
                { value: 60, label: "1分" }
            ],
            extension_list_interval: 10
        };
    }

    componentDidMount() {
        this.setAppsyncClient();
        fscreen.addEventListener("fullscreenchange", this.handleFsChange, false);

        // システム管理者・oem管理者の場合、会社選択肢セット
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            this.getCommonCompanySelect(this.reactContainerPath)
                .then((res) => {
                    this.setState({
                        companySelect: res.data
                    });
                })
                .catch((err) => {
                    this.showErrorObjectMesssage(err);
                });
        }
        // 会社管理者の場合、拠点選択肢セット
        else if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            this.setCommonDepartmentSelect(this.state.company_id, this.state.floor_flg);
            this.getExternalNumByCompanyId({ company_id: this.state.company_id });
        }
        // 拠点管理者・フロアなしの場合
        else if (
            !this.state.floor_flg &&
            this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)
        ) {
            this.setState({
                arg_department_id: this.state.select_department_id
            });
            this.getExternalNumByDepartmentId({ department_id: this.state.select_department_id });
        }
        // 拠点管理者・フロアありの場合、フロア選択肢セット
        else if (
            this.state.floor_flg &&
            this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)
        ) {
            this.setCommonFloorSelect({ departmentIds: this.state.select_department_id });
            this.setState({
                select_floor_flg: true
            });
        }
        // フロア管理者の場合
        else if (
            this.state.floor_flg &&
            this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.floor)
        ) {
            this.setState({
                arg_department_id: this.state.select_floor_id
            });
            this.getExternalNumByDepartmentId({ department_id: this.state.select_floor_id });
        }

    }

    componentWillUnmount() {
        // ESC全体画面解除のイベント削除
        fscreen.addEventListener("fullscreenchange", this.handleFsChange, false);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.company_id !== prevState.company_id) {
            this.setCommonDepartmentSelect(this.state.company_id, this.state.floor_flg);
            this.getExternalNumByCompanyId({ company_id: this.state.company_id });
            this.setState({
                select_external_number: [],
                external_department_id: []
            });
        } else if (
            this.state.select_department_id !== prevState.select_department_id &&
            this.state.floor_flg
        ) {
            this.setCommonFloorSelect({ departmentIds: this.state.select_department_id });
        } else if (
            this.state.select_floor_id !== prevState.select_floor_id &&
            this.state.floor_flg
        ) {
            this.setCommonFloorSelect({ departmentIds: this.state.select_department_id });
        }
        if (prevState.acd_external_id !== this.state.acd_external_id) {
            this.setDepartmentIdListByExternalId({ external_id_list: this.state.acd_external_id });
        }
    }

    setCommonDepartmentSelect = (company_id, floor_flg) => {
        super
            .setCommonDepartmentSelect({
                container: "/CallMonitor",
                company_id: company_id,
                floor_flg: floor_flg
            })
            .then((res) => {
                this.setState({
                    arg_department_id: res,
                    department_id_list: res
                });
                if (floor_flg) {
                    this.setCommonFloorSelect({ departmentIds: res });
                }
            })
            .catch((err) => {
                alert(this.getErrorString(err));
            });
    };

    setCommonFloorSelect = ({ departmentIds }) => {
        super
            .setCommonFloorSelect({
                container: "/CallMonitor",
                departmentIds
            })
            .then((res) => {
                this.setState({
                    arg_department_id: res,
                    floor_id_list: res
                });
                this.getExternalNumByDepartmentId({ department_id: res });
            })
            .catch((err) => {
                alert(this.getErrorString(err));
            });
    };

    setAppsyncClient = () => {
        this.ascAxios("post", "CallMonitor/getAppsyncInfo").then((res) => {
            this.setState({ client: client(res.data) });
            // client
        });
    };

    getExternalNumByCompanyId = ({ company_id }) => {
        this.ascAxios("post", "CallMonitor/getExternalNumByCompanyId", {
            company_id: company_id
        }).then((res) => {
            this.setExternalSelectList({ external_num_list: res });
        });
    };

    getExternalNumByDepartmentId = ({ department_id }) => {
        this.ascAxios("post", "CallMonitor/getExternalNumByDepartmentId", {
            department_id: department_id
        }).then((res) => {
            this.setExternalSelectList({ external_num_list: res });
        });
    };

    setDepartmentIdListByExternalId = ({ external_id_list }) => {
        this.ascAxios("post", "CallMonitor/getDepartmentIdByExternalNum", {
            cm61_id: external_id_list
        }).then((res) => {
            let department_id = [];
            const temp = res.data.department_id;
            temp.forEach((row) => {
                department_id.push(row.cm13_id);
            });

            this.setState({ acd_department_id: department_id });
        });
    };

    setExternalSelectList = ({ external_num_list }) => {
        const cm61_id = [];
        external_num_list.data.forEach((row) => {
            cm61_id.push(row.id);
        });
        this.ascAxios("post", "CallMonitor/getDepartmentIdByExternalNum", {
            cm61_id: cm61_id
        }).then((res) => {
            const result = {};
            const temp = res.data.department_id;
            const external_id_temp = {};
            let assign_external_id_list = [];
            temp.forEach((row) => {
                if (row.cm61_external_numbers) {
                    if (!external_id_temp[row.cm61_id]) {
                        external_id_temp[row.cm61_id] = [];
                    }
                    external_id_temp[row.cm61_id].push(row.cm13_id);
                }
            });
            temp.forEach((row) => {
                if (row.cm61_external_numbers) {
                    result[row.cm61_external_numbers.display_number] = {
                        value: row.cm61_external_numbers.id,
                        label:
                            row.cm61_external_numbers.memo +
                            "  (" +
                            row.cm61_external_numbers.display_number +
                            ")",
                        display_number: row.cm61_external_numbers.display_number,
                        cm13_id: row.cm13_id
                    };

                    assign_external_id_list.push(row.cm13_id);
                }
            });
            Object.values(result).forEach((row) => {
                if (external_id_temp[row.value]) {
                    row.cm13_id = external_id_temp[row.value];
                }
            });
            this.setState({
                external_number_list: Object.values(result),
                external_department_id: []
            });
        });
    };

    // 全体画面
    toggleFullscreen = () => {
        if (this.state.isFullscreen) {
            fscreen.exitFullscreen();
        } else {
            fscreen.requestFullscreen(this.appElement);
        }
    };

    handleRef = (element) => {
        this.appElement = element;
    };

    handleFsChange = (e) => {
        if (fscreen.fullscreenElement !== null) {
            this.setState({ isFullscreen: true });
        } else {
            this.setState({ isFullscreen: false });
        }
    };

    onChange = (event, param) => {
        switch (param) {
            case "company":
                this.setState({
                    cm13_id: [],
                    floor_flg: event.floor_flg === "Y",
                    company_id: event.value,
                    select_department_id: [],
                    select_floor_id: [],
                    floor_id_list: [],
                    floor_id: [],
                    select_floor_flg: false
                });
                break;
            case "department":
                this.setState({
                    select_department_id: event.value,
                    arg_department_id: event.value,
                    select_floor_id: [],
                    floor_id_list: [],
                    floor_id: [],
                    select_floor_flg: true
                });
                break;
            case "floor":
                this.setState({
                    arg_department_id: event.value,
                    floor_id: event.value
                });
                break;
        }
    };

    onMultiSelectChange = (event, param) => {
        super.onMultiSelectChange(event, param);
        switch (param) {
            case "external_id_list":
                let temp = [];
                event.forEach((row) => {
                    row.cm13_id.forEach((external_row) => {
                        temp.push(external_row);
                    });
                });
                this.setState({
                    select_external_number: event.value,
                    external_department_id: temp
                });
                break;
        }
    };

    changeMonitoringMode = () => {
        if (this.state.isExternalSearchMode) {
            this.setState({
                isExternalSearchMode: false
            });
        } else {
            this.setState({
                isExternalSearchMode: true
            });
        }
    };

    setDepartmentListACD = ({ department_id }) => {
        this.setState({
            acd_department_id: department_id
        });
    };

    setACDExternalListByDepartmentSelect = ({ acd_external_id }) => {
        this.setState({
            acd_external_id: acd_external_id
        });
    };

    render() {
        if (this.state.client) {
            return (
                <ApolloProvider client={this.state.client}>
                    <div className="CallMonitor">
                        <div
                            ref={this.handleRef}
                            className={this.state.isFullscreen ? "full-screen-mode" : ""}>
                            <Row>
                                <div xs={12} md={12} lg={12}>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Col
                                            style={{
                                                visibility: this.getScopeGreaterEqual(
                                                    GlobalConst.SCOPE_OBJECT.oem
                                                )
                                                    ? "visible"
                                                    : "hidden",
                                                display:
                                                    (this.state.isExternalSearchMode &&
                                                        this.getScopeLessThanEqual(
                                                            GlobalConst.SCOPE_OBJECT.oem
                                                        )) ||
                                                    this.getScopeLessThanEqual(
                                                        GlobalConst.SCOPE_OBJECT.company
                                                    )
                                                        ? "none"
                                                        : "block"
                                            }}>
                                            <Col xs={3} sm={2} md={2} lg={1}>
                                                <div className="margin-top-07">
                                                    {this.props.langText.Body.CompanyName}
                                                </div>
                                            </Col>
                                            <Col xs={9} sm={10} md={10} lg={2}>
                                                <InlineForm
                                                    className="callmonitor-company-select-area"
                                                    id="company-select"
                                                    type="select"
                                                    value={this.state.company_id}
                                                    onChange={(e) => this.onChange(e, "company")}
                                                    options={this.state.companySelect}
                                                    placeholder={""}
                                                    isDisabled={
                                                        !this.getScopeGreaterEqual(
                                                            GlobalConst.SCOPE_OBJECT.oem
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Col>
                                        <Col
                                            style={{
                                                visibility: this.getScopeGreaterEqual(
                                                    GlobalConst.SCOPE_OBJECT.company
                                                )
                                                    ? "visible"
                                                    : "hidden",
                                                display:
                                                    this.state.isExternalSearchMode ||
                                                    !this.getScopeGreaterEqual(
                                                        GlobalConst.SCOPE_OBJECT.company
                                                    )
                                                        ? "none"
                                                        : "block"
                                            }}>
                                            <Col xs={3} sm={2} md={2} lg={1}>
                                                <div className="margin-top-07">
                                                    {this.props.langText.Body.DepartmentName}
                                                </div>
                                            </Col>
                                            <Col xs={9} sm={10} md={10} lg={2}>
                                                <InlineForm
                                                    className="callmonitor-department-select-area"
                                                    id="department-select"
                                                    type="select"
                                                    value={this.state.select_department_id}
                                                    onChange={(e) => this.onChange(e, "department")}
                                                    options={this.state.departmentSelect}
                                                    placeholder={""}
                                                    isDisabled={!this.state.company_id}
                                                />
                                            </Col>
                                        </Col>
                                        <Col
                                            style={{
                                                visibility:
                                                    this.state.floor_flg &&
                                                    this.getScopeGreaterEqual(
                                                        GlobalConst.SCOPE_OBJECT.department
                                                    )
                                                        ? "visible"
                                                        : "hidden",
                                                display:
                                                    this.state.isExternalSearchMode ||
                                                    !this.state.floor_flg ||
                                                    !this.getScopeGreaterEqual(
                                                        GlobalConst.SCOPE_OBJECT.department
                                                    )
                                                        ? "none"
                                                        : "block"
                                            }}>
                                            <Col xs={3} sm={2} md={2} lg={1}>
                                                <div className="margin-top-05">
                                                    {this.props.langText.Body.FloorName}
                                                </div>
                                            </Col>
                                            <Col xs={9} sm={10} md={10} lg={2}>
                                                <InlineForm
                                                    className="callmonitor-floor-select-area"
                                                    id="floor-select"
                                                    type="select"
                                                    value={this.state.floor_id}
                                                    onChange={(e) => this.onChange(e, "floor")}
                                                    options={this.state.floorSelect}
                                                    placeholder={""}
                                                    isDisabled={!this.state.select_floor_flg}
                                                />
                                            </Col>
                                        </Col>
                                        <Col
                                            style={{
                                                display: this.state.isExternalSearchMode
                                                    ? "block"
                                                    : "none"
                                            }}>
                                            <Col xs={3} sm={2} md={2} lg={1}>
                                                <div className="margin-top-07">
                                                    {this.props.langText.Body.ExternalNumber}
                                                </div>
                                            </Col>
                                            <Col
                                                xs={9}
                                                sm={10}
                                                md={10}
                                                lg={
                                                    this.getScopeGreaterEqual(
                                                        GlobalConst.SCOPE_OBJECT.oem
                                                    )
                                                        ? 6
                                                        : 7
                                                }>
                                                <InlineForm
                                                    className="callmonitor-external-select-area"
                                                    type="select"
                                                    isMulti={true}
                                                    isClearable={false}
                                                    value={this.state.select_external_number}
                                                    onChange={(e) =>
                                                        this.onMultiSelectChange(
                                                            e,
                                                            "external_id_list"
                                                        )
                                                    }
                                                    options={this.state.external_number_list}
                                                    placeholder={""}
                                                />
                                            </Col>
                                        </Col>
                                        <Col>
                                            <div className="pull-right">
                                                <Col className="margin-top-05" xs={1} sm={1} md={1} lg={1}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle bsStyle="primary">
                                                            {this.props.langText.Body.Set}
                                                        </Dropdown.Toggle>
                                                        <CustomMenu bsRole="menu">
                                                            <Col>
                                                                <Col xs={12} sm={12} md={12} lg={12} 
                                                                    className="margin-top-07 margin-bottom-07 text-align-center margin-right-1">
                                                                    <Col className="pull-right margin-left-05">
                                                                        <Button
                                                                            className="seatview-btn"
                                                                            onClick={this.toggleFullscreen}>
                                                                            {this.state.isFullscreen
                                                                                ? this.props.langText.Body.OriginalScreen
                                                                                : this.props.langText.Body.FullScreen}
                                                                        </Button>
                                                                    </Col>
                                                                    <Col className="pull-right">
                                                                        <Button
                                                                            className="seatview-btn"
                                                                            onClick={this.changeMonitoringMode}>
                                                                            {this.state.isExternalSearchMode
                                                                                ? this.props.langText.Body.DepartmentSelect
                                                                                : this.props.langText.Body.ExternalNumberSelect}
                                                                        </Button>
                                                                    </Col>
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={12} className="margin-top-05 margin-bottom-07">
                                                                    <Col xs={7} sm={7} md={6} lg={6} className="text-align-left margin-top-05">
                                                                        {this.props.langText.Body.GiveUpTimeRange}
                                                                    </Col>
                                                                    <Col xs={5} sm={5} md={6} lg={6}>
                                                                        <InlineForm
                                                                            type="select"
                                                                            options={this.state.giveup_interval_list}
                                                                            value={this.state.giveup_interval_time}
                                                                            onChange={(e) => this.onSelectChange(e, "giveup_interval_time")}/>
                                                                    </Col>
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={12} className="margin-top-03 margin-bottom-05">
                                                                    <Col xs={7} sm={7} md={6} lg={6} className="text-align-left margin-top-05">
                                                                        {this.props.langText.Body.CallMonitorInboundAlertTime}
                                                                    </Col>
                                                                    <Col xs={5} sm={5} md={6} lg={6}>
                                                                        <InlineForm
                                                                            type="select"
                                                                            options={this.state.inbound_alert_interval_list}
                                                                            value={this.state.inbound_alert_time}
                                                                            onChange={(e) => this.onSelectChange(e, "inbound_alert_time")}/>
                                                                    </Col>
                                                                </Col>
                                                                <Col xs={12} sm={12} md={12} lg={12} className="margin-top-03 margin-bottom-07">
                                                                    <Col xs={7} sm={7} md={6} lg={6} className="text-align-left margin-top-05">
                                                                        {this.props.langText.Body.CallMonitorRefreshTime}
                                                                    </Col>
                                                                    <Col xs={5} sm={5} md={6} lg={6}>
                                                                        <InlineForm
                                                                            type="select"
                                                                            options={this.state.extension_list_interval_list}
                                                                            value={this.state.extension_list_interval}
                                                                            onChange={(e) => this.onSelectChange(e,"extension_list_interval")}/>
                                                                    </Col>
                                                                </Col>
                                                            </Col>
                                                        </CustomMenu>
                                                    </Dropdown>
                                                </Col>
                                            </div>
                                        </Col>
                                    </Col>
                                </div>
                            </Row>

                            <ExternalMonitor
                                company_id={this.state.company_id}
                                department_id={this.state.arg_department_id}
                                langText={this.props.langText}
                                tableHeight={this.props.tableHeight}
                                isExternalSearchMode={this.state.isExternalSearchMode}
                                external_id_list={this.state.external_id_list}
                                giveup_interval_time={this.state.giveup_interval_time}
                                setDepartmentListACD={(e) => this.setDepartmentListACD(e)}
                                setACDExternalListByDepartmentSelect={(e) =>
                                    this.setACDExternalListByDepartmentSelect(e)
                                }
                            />
                            <ExtensionMonitor
                                company_id={this.state.company_id}
                                department_id={this.state.arg_department_id}
                                acd_external_id={this.state.acd_external_id}
                                acd_department_id={this.state.acd_department_id}
                                external_department_id={this.state.external_department_id}
                                external_id_list={this.state.external_id_list}
                                langText={this.props.langText}
                                boardWidth={this.props.boardWidth}
                                tableHeight={this.props.tableHeight}
                                isExternalSearchMode={this.state.isExternalSearchMode}
                                inbound_alert_time={this.state.inbound_alert_time}
                                extension_list_interval={this.state.extension_list_interval}
                            />
                        </div>
                    </div>
                </ApolloProvider>
            );
        } else {
            return null;
        }
    }
}

class CustomMenu extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.handleChange = this.handleChange.bind(this);

        this.state = {
            value: ""
        };
    }

    handleChange(e) {
        this.setState({ value: e.target.value });
    }

    render() {
        const { children } = this.props;
        const { value } = this.state;

        return (
            <Dropdown.Menu className="dropdown-menu-right callmonitor-dropdown-menu">
                {React.Children.toArray(children).filter(
                    (child) => !value.trim() || child.props.children.indexOf(value) !== -1
                )}
            </Dropdown.Menu>
        );
    }
}
