import React, { Component } from 'react'
import {
    Col,
    Button,
    FormGroup,
    ControlLabel,
    FormControl,
    Glyphicon
} from 'react-bootstrap'

export default class MultiValueDisableForm extends Component {
    onFormTextChange = param => event => {
        this.props.onTextChange(
            this.props.controlId,
            param.index,
            event.target.value
        )
    }

    onClickHandlePlus = event => {
        this.props.onSizeChange(this.props.controlId, 'plus', null)
    }

    onClickHandleToggle = param => event => {
        this.props.onSizeChange(this.props.controlId, 'toggle', param)
    }

    multiValueFormRendering({
        value,
        placeholderValue,
    }) {
        let buttonCol = 1,
            valueCol = 11,
            multiValueFormBody = [];
        value.forEach((row, index) => {
            const duplicateCount = value.findIndex((item, pos) =>
                (item.value.trim() === row.value.trim() && pos !== index && row.value.trim() !== '' )
            );
            const blankFlag = (row.value.trim() === '' && row.id !== null);
            if (row.disabled === "Y") {
                multiValueFormBody.push(
                    <div className='multiValueFormRow' key={index}>
                        <Col md={buttonCol}>
                            <Button
                                className='multiValueFormToggle remove-button-color'
                                onClick={this.onClickHandleToggle(index)}
                            >
                                <Glyphicon glyph='remove' />
                            </Button>
                        </Col>
                        <Col md={valueCol} key={index}>
                            <FormGroup className='multiValueFormBody'>
                                <FormControl
                                    onChange={this.onFormTextChange({ index: index, form: 'value' })}
                                    value={row.value}
                                    placeholder={placeholderValue}
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                    </div>
                );
            } else {
                multiValueFormBody.push(
                    <div className='multiValueFormRow' key={index}>
                        <Col md={buttonCol}>
                            <Button
                                className='multiValueFormToggle'
                                onClick={this.onClickHandleToggle(index)}
                            >
                                <Glyphicon glyph='ok' className='ok-button-color'/>
                            </Button>
                        </Col>
                        <Col md={valueCol} key={index}>
                            <FormGroup
                                className='multiValueFormBody'
                                validationState={(duplicateCount >= 0 || blankFlag) ? 'error' : null}
                            >
                                <FormControl
                                    onChange={this.onFormTextChange({ index: index, form: 'value' })}
                                    value={row.value}
                                    placeholder={placeholderValue}
                                    disabled={false}
                                />
                            </FormGroup>
                        </Col>
                    </div>
                );
            }
        });

        return (
            <FormGroup className='multiValueFormBody' >
                {multiValueFormBody}
            </FormGroup>
        )
    }

    render() {
        let {
            labelText,
            labelCol = 3,
            formCol = 9,
            isStatic,
            value,
            placeholderValue,
        } = this.props

        let multiValueElement = this.multiValueFormRendering({
            value,
            placeholderValue,
        });

        return (
            <div>
                <FormGroup>
                    <Col
                        componentClass={ControlLabel}
                        md={labelCol}
                    >
                        {labelText}
                    </Col>
                </FormGroup>
                {multiValueElement}
                <FormGroup>
                    <Col md={formCol}>
                        <Button
                            className='multiValueFormPlus'
                            onClick={this.onClickHandlePlus}
                            disabled={isStatic}
                        >
                            <Glyphicon glyph='plus' />
                        </Button>
                    </Col>
                </FormGroup>
            </div>
        )
    }
}
