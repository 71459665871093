import { PortModel, PortModelAlignment, LinkModel } from '@projectstorm/react-diagrams';
import { ASLinkModel } from '../Link/LinkModel';

export class ASPortModel extends PortModel {
	constructor(alignment) {
		super({
			type: "ivr",
			name: alignment,
			alignment: alignment,
			locked: true
		});
	}

	createLinkModel() {
		return new ASLinkModel();
	}

	canLinkToPort(target) {
		return false;
	}

	// canLinkToPort(target) {
	// 	const node = this.getParent();
	// 	const node_links = Object.values(this.getLinks() || {});

	// 	if (this.getName() === PortModelAlignment.RIGHT
	// 		&& target.getName() === PortModelAlignment.LEFT
	// 		&& this.getParent().getID() !== target.getParent().getID()) {
	// 		if (node.multiple_link) {
	// 			// 複数選択可能なNODEの場合複数選択用MENUを表示
	// 			node.multipleLinkEvent();
	// 		} else {
	// 			if (node_links.length > 1) {
	// 				// すでにLINKがある場合削除
	// 				node.removeLink(node_links[0]);
	// 			}
	// 			node.c_data.nexts.push({
	// 				condition: "all",
	// 				block_no: target.getParent().getOptions().number
	// 			});
	// 			return true;
	// 		}
	// 	}
	// 	return false;
	// }
}