import React from 'react'
import Component from '../../components/AscComponent'
import {FormGroup, FormControl, NavItem} from 'react-bootstrap'
import LoadingButton from '../Elements/Button/LoadingButton'
import SignInModal from './SignInModal'
import './SignIn.css'

export default class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // modal item
            show: false,
            modalType: null,

            // loading button item
            isLoading:         false,

            // data item
            emailLogin:        '',
            emailReset:        '',
            password:          '',
            resetPasswordCode: '',
            newPassword:       '',
            newPasswordRe:     '',
            oldPassword:       ''
        }
    }

    modalShow = (modalType) => event => {
        event.preventDefault()

        let setData = {
            emailReset: '',
            resetPasswordCode: '',
            newPassword: '',
            newPasswordRe: '',
            show: true,
            modalType
        }

        this.setState(setData);
    }

    // イベントハンドルを伴わないモーダル画面表示処理
    modalShowNoEvent = (modalType) => {

        let setData = {
            oldPassword: '',
            newPassword: '',
            newPasswordRe: '',
            show: true,
            modalType
        }

        this.setState(setData);
    }

    validationHandle = param => {
        let nowState = null

        let {
            emailReset,
            resetPasswordCode
        } = this.state

        let exp = {
            // 英数字それぞれ 1 文字ずつ含む 8 文字以上
            oldPassword: /(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
            password: /^(?=.*?[a-z])(?=.*?[0-9])[\x20-\x7e]{8,}$/,
            email: /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/
        }

        switch (param) {
            case 'resetPasswordCode':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break;

            case 'emailReset':
                nowState = (
                    this.state[param]
                    && exp.email.test(this.state[param]))
                    ? null
                    : 'error'
                break;

            case 'resetPassword':
                nowState = (
                    emailReset
                    && exp.email.test(emailReset)
                );
                break;

            case 'submitNewPassword':
                nowState = (
                    emailReset
                    && resetPasswordCode
                    && this.passwordValidationHandle(param, this.state)
                )
                break;

            case 'updateSelfPassword':
                nowState = this.passwordValidationHandle(param, this.state);
                break;

            default:
                break;
        }

        return nowState;
    }

    validationForm = () => {
        return this.state.emailLogin.length > 0 && this.state.password.length > 0
    }

    onClickHandle = modalType => async event => {
        let {
            emailReset,
            resetPasswordCode,
            newPassword,
            emailLogin,
            oldPassword
        } = this.state
        try {
            switch (modalType) {
                case 'resetPassword':
                    await this.ascAxios('post', '/Sign/resetPassword', {
                        emailReset
                    });
                    alert(this.props.langText.Message.SignIn_SentVerificationCode)
                    this.setState({modalType: 'submitNewPassword'});
                    break;
                case 'submitNewPassword':
                    await this.ascAxios('post', '/Sign/submitNewPassword', {
                        emailReset,
                        resetPasswordCode,
                        newPassword
                    });
                    this.setState({show: false});
                    alert(this.props.langText.Message.SignIn_ResetPasswordSuccess);
                    break;
                case 'updateSelfPassword':
                    await this.ascAxios('post', '/Sign/changePassword', {
                        mailAddress: emailLogin,
                        oldPassword,
                        newPassword
                    })
                    alert(this.props.langText.Message.User_ChangeSelfPasswordSuccess);
                    this.setState({ show: false, isLoading: false, password: '' });
                    break;
                default:
                    alert(this.getErrorString({code: 'modal'}))
                    break;
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.setState({isLoading: true})

        try {
            let response = await this.ascAxios('post', '/Sign/login', this.state)

            if (response.status === 200) {
                this.props.userHasAuthenticated(true, response.data)
                this.props.historyPush('/')
            } else {
                alert(this.props.langText.Message.SignIn_SignInFailed)
                this.setState({isLoading: false})
            }
        } catch (err) {
            if (err.response.data.code === 'SignIn_PasswordExpired') {
                // パスワードの期限が切れている場合、変更モーダルを表示して変更を促す
                this.modalShowNoEvent("updateSelfPassword");
            } else if (err.response.data.code === 'NotAuthorizedException') {
                // ログイン情報を間違えた場合、cm15.login_failure_countを+1
                await this.ascAxios('post', '/Sign/incrementLoginFailureCount', {
                    mailAddress: this.state.emailLogin
                })
                if (err.response.data.failureCount > 0) {
                    err.response.data.code = "SignIn_SignInFailed";
                    err.response.data.args = [err.response.data.failureCount];
                } else {
                    err.response.data.code = "SignIn_SignInFailedAndLocked";
                }
                this.showErrorObjectMesssage(err);
            } else {
                this.showErrorObjectMesssage(err);
            }
            this.setState({isLoading: false});

            //ユーザーの所属がない場合、エラー出した後リロード
            if(err.response.data.code == "NoDepartmentError"){
                window.location.reload();
            }
        }
    }

    render() {
        return (
            <div className='signIn'>
                <form class="form-block" onSubmit={this.handleSubmit}>
                    <FormGroup controlId='emailLogin' bsSize='large'>
                        <FormControl
                            autoFocus
                            type        = 'text'
                            value       = {this.state.emailLogin}
                            onChange    = {e => this.onTextChange(e, "emailLogin")}
                            placeholder = {this.props.langText.Body.EmailAddressLogIn}
                        />
                    </FormGroup>
                    <FormGroup controlId='password' bsSize='large'>
                        <FormControl
                            value       = {this.state.password}
                            onChange    = {e => this.onTextChange(e, "password")}
                            type        = 'password'
                            placeholder = {this.props.langText.Body.PasswordLogIn}
                        />
                    </FormGroup>
                    <div className='signIn-box'>
                    <NavItem
                        className      = 'signIn-link'
                        onClick        = {this.modalShow('resetPassword')}
                    >
                        {this.props.langText.Message.SignIn_ResetPasswordLink}
                    </NavItem>
                    <LoadingButton
                        className   = 'signIn-button'
                        disabled    = {!this.validationForm()}
                        type        = 'submit'
                        isLoading   = {this.state.isLoading}
                        text        = {this.props.langText.Body.LogIn}
                        loadingText = 'loging in ......'
                    >
                    </LoadingButton>
                    </div>
                </form>

                <SignInModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    onClick = {this.onClickHandle}
                    onTextChange = {this.onTextChange}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    validationHandle = {this.validationHandle}
                    charaLimit = {this.props.charaLimit}
                    passwordValidationHandle = {this.passwordValidationHandle}
                />

            </div>
        )
    }
}
