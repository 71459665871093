import React, {Component} from "react"
import {Panel, Glyphicon} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import MultiValueDualForm from "../Elements/FromGroup/MultiValueDualForm"
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";


export default class CallResultModal extends Component {
    getCallResultInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onKeyDownIntCheck,
            onSelectChange,
            onMultiValueFormTextChange,
            onMultiValueFormSizeChange,
            validationHandle,
            getScopeGreaterEqual,
            checkDuplicate
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "copy") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            )
        }

        if (state.modalType === "insert" || state.modalType === "copy" ||state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    key = "callResultCode"
                    controlId = "callResultCode"
                    label = {langText.Body.CallResultCode}
                    type ="text"
                    value = {state.callResultCode}
                    onChange = {e => onTextChange_Limit(e, "callResultCode", charaLimit.CallResult_Code)}
                    onBlur = {() => checkDuplicate("callResultCode")}
                    placeholder = {langText.Message.CallResultCodeRequirements}
                    validationState = {validationHandle("callResultCode")}
                />
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key = "callResultCode"
                    controlId = "callResultCode"
                    label = {langText.Body.CallResultCode}
                >
                    {state.callResultCode}
                </InlineForm.static>
            );
        }
        if (state.callResultCodeDuplicateFlag) {
            itemArr.push(
                <p key = "callResultCodeDuplicateFlag" className="text-danger">
                    {langText.Body.CallResultCodeDuplicated}
                </p>
            );
        }

        if (state.modalType === "insert" || state.modalType === "copy" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    key = "callResultName"
                    controlId = "callResultName"
                    label = {langText.Body.CallResultName}
                    type = "text"
                    value = {state.callResultName}
                    onChange = {e => onTextChange_Limit(e, "callResultName",charaLimit.CallResult_Name)}
                    onBlur={() => checkDuplicate("callResultName")}
                    placeholder = {langText.Message.CallResultNameRequirements}
                    validationState = {validationHandle("callResultName")}
                />
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key = "callResultName"
                    controlId = "callResultName"
                    label = {langText.Body.CallResultName}
                >
                    {state.callResultName}
                </InlineForm.static>
            );
        }
        if(state.callResultNameDuplicateFlag){
            itemArr.push(
                <p key = "callResultNameDuplicateFlag" className="text-danger">
                    {langText.Body.CallResultNameDuplicated}
                </p>
            );
        }

        if (state.modalType === "insert" || state.modalType === "copy" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    key = "callResultOrderNum"
                    controlId = "callResultOrderNum"
                    label = {langText.Body.CallResultOrderNum}
                    type = "number"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    value = {state.callResultOrderNum}
                    onChange = {e => onTextChange_Limit(e, "callResultOrderNum", charaLimit.CallResult_Order_Num)}
                />
            );
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key = "callResultOrderNum"
                    controlId = "callResultOrderNum"
                    label = {langText.Body.CallResultOrderNum}
                >
                    {state.callResultOrderNum ? state.callResultOrderNum : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "copy" ||state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    label = {langText.Body.CallRankFirst}>
                    <MultiValueDualForm
                        key = "callRankFirstModal"
                        controlId = "callRankFirstModal"
                        value = {state.callRankFirstModal}
                        isStatic = {false}
                        onTextChange = {onMultiValueFormTextChange}
                        onSizeChange = {onMultiValueFormSizeChange}
                        onKeyDownIntCheck = {onKeyDownIntCheck}
                        placeholderOrderNum = {langText.Body.CallRankOrderPlaceHolder}
                        placeholderValue = {langText.Body.CallRankCodePlaceHolder}
                        placeholderValue2 = {langText.Body.Name}
                        validationState  = {validationHandle("callRankFirstModal")}
                    />
                </InlineForm>
            )
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm
                    label = {langText.Body.CallRankFirst}>
                    <MultiValueDualForm
                        key = "callRankFirstModal"
                        controlId = "callRankFirstModal"
                        value = {state.callRankFirstModal}
                        isStatic = {true}
                        onTextChange = {onMultiValueFormTextChange}
                        onSizeChange = {onMultiValueFormSizeChange}
                        placeholderOrderNum = {langText.Body.CallRankOrderPlaceHolder}
                        placeholderValue = {langText.Body.Code}
                        placeholderValue2 = {langText.Body.Name}
                        unsetValue = {langText.SelectOption.Unset}
                    />
                </InlineForm>
            )
        }

        if (state.modalType === "insert" || state.modalType === "copy" || state.modalType == "update") {
            itemArr.push(
                <InlineForm
                    label = {langText.Body.CallRankSecond}>
                    <MultiValueDualForm
                        key = "callRankSecondModal"
                        controlId = "callRankSecondModal"
                        value = {state.callRankSecondModal}
                        isStatic = {false}
                        onTextChange = {onMultiValueFormTextChange}
                        onSizeChange = {onMultiValueFormSizeChange}
                        onKeyDownIntCheck = {onKeyDownIntCheck}
                        placeholderOrderNum = {langText.Body.CallRankOrderPlaceHolder}
                        placeholderValue = {langText.Body.CallRankCodePlaceHolder}
                        placeholderValue2 = {langText.Body.Name}
                        validationState  = {validationHandle("callRankSecondModal")}
                    />
                </InlineForm>
            )
        } else if (state.modalType === "read" || state.modalType === "delete") {
            itemArr.push(
                <InlineForm
                    label = {langText.Body.CallRankSecond}>
                    <MultiValueDualForm
                        key = "callRankSecondModal"
                        controlId = "callRankSecondModal"
                        value = {state.callRankSecondModal}
                        isStatic = {true}
                        onTextChange = {onMultiValueFormTextChange}
                        onSizeChange = {onMultiValueFormSizeChange}
                        placeholderOrderNum = {langText.Body.CallRankOrderPlaceHolder}
                        placeholderValue = {langText.Body.Code}
                        placeholderValue2 = {langText.Body.Name}
                        unsetValue = {langText.SelectOption.Unset}
                    />
                </InlineForm>
            )
        }

        return itemArr;
    }

    render() {

        let panelElem = (
            <Panel bsStyle="info">
                <Panel.Heading>
                    <Panel.Title>
                        {this.props.langText.Body.CallResultInfo}
                    </Panel.Title>
                    {(this.props.state.modalType === "delete"
                        && this.props.state.usingCallResults.length > 0)
                        && <>
                            <div className="text-danger modal-checked-message">
                                <p>{this.props.langText.Message.CallRankDeleteError}</p>
                                <ul>{this.props.state.usingCallResults}</ul>
                            </div>
                        </>
                    }
                </Panel.Heading>

                <Panel.Body>
                    {this.getCallResultInfoItem()}
                </Panel.Body>
            </Panel>
        );

        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                {panelElem}
            </AscModal>
        );
    }
}