export default (column_name, lang) => {
    let selectOptions = {
        inbound_group_type_by_search: [
            {
                value: "normal",
                label: lang.InboundGroupTypeNormal
            },
            {
                value: "android",
                label: lang.InboundGroupTypeAndroid
            }
        ],
        external_inbound_group_type: [ 
            {
                value: "normal",
                label: `${lang.InboundGroupTypeNormalSearch}`
            },
            {
                value: "all",
                label: `${lang.InboundGroupTypeMulti}(${lang.InboundGroupTypeMultiSearch})`
            }
        ],
        inbound_group_type: [ 
            {
                value: "normal",
                label: `${lang.InboundGroupTypeNormalSearch}`
            },
            {
                value: "android",
                label: `${lang.InboundGroupTypeAndroid}`
            },
            {
                value: "all",
                label: `${lang.InboundGroupTypeMulti}(${lang.InboundGroupTypeMultiSearch})`
            }
        ],
        inbound_type: [
            {
                value: "hardphone",
                label: lang.InboundTypeHardPhone
            },
            {
                value: "softphone",
                label: lang.InboundTypeSoftphone
            },
            {
                value: "android",
                label: lang.InboundTypeAndroid
            }
        ],
        inbound_all_type: [
            {
                value: "hardphone",
                label: lang.InboundTypeHardPhone
            },
            {
                value: "softphone",
                label: lang.InboundTypeSoftphone
            },
            {
                value: "android",
                label: lang.InboundTypeAndroid
            },
            {
                value: "all",
                label: lang.AllExtension
            }
        ],
        external_setting_type: [
            {
                value: "extension",
                label: lang.Extension
            },
            {
                value: "inboundGroup",
                label: lang.InboundGroup
            }
        ],
        gateway_type: [
            {
                value: "kddi",
                label: lang.Kddi
            },
            {
                value: "rakuten",
                label: lang.Rakuten
            },
            {
                value: "prodelight",
                label: lang.Prodelight
            },
            {
                value: "softbank",
                label: lang.Softbank
            },
            {
                value: "sonus",
                label: lang.Sonus
            },
            {
                value: "hikari",
                label: lang.Hikari
            },
            {
                value: "santu",
                label: lang.Santu
            },
            {
                value: "hikari",
                label: lang.Hikari
            }
        ],
        outbound_prefix: [
            {
                value: "0",
                label: "0"
            },
            {
                value: "1",
                label: "1"
            },
            {
                value: "2",
                label: "2"
            },
            {
                value: "3",
                label: "3"
            },
            {
                value: "4",
                label: "4"
            },
            {
                value: "5",
                label: "5"
            },
            {
                value: "6",
                label: "6"
            },
            {
                value: "7",
                label: "7"
            },
            {
                value: "8",
                label: "8"
            },
            {
                value: "9",
                label: "9"
            }
        ],
        monitoring_flg: [
            {
                value: "Y",
                label: lang.MonitoringFlgY
            },
            {
                value: "N",
                label: lang.MonitoringFlgN
            }
        ],
        monitoring_flg_list: [
            {
                value: "Y",
                label: lang.MonitoringFlgListY
            },
            {
                value: "N",
                label: lang.MonitoringFlgListN
            }
        ],
        region: [
            {
                value: "ap-northeast-1",
                label: "東京"
            },
            {
                value: "ap-northeast-3",
                label: "大阪"
            }
        ],
        skill_status_list: [
            {
                value: "created",
                label: lang.SkillStatusCreated
            },
            {
                value: "modified",
                label: lang.SkillStatusModified
            },
            {
                value: "deleted",
                label: lang.SkillStatusDeleted
            }
        ],
        inout_type: [
            {
                value: "in",
                label: "IN"
            },
            {
                value: "out",
                label: "OUT"
            }
        ],
        call_result: [
            {
                value: "ANSWERED",
                label: "ANSWERED"
            },
            {
                value: "NO ANSWER",
                label: "NO ANSWER"
            },
            {
                value: "BUSY",
                label: "BUSY"
            },
            {
                value: "PLAYBACK",
                label: "PLAYBACK"
            },
            {
                value: "FAILED",
                label: "FAILED"
            }
        ],
        call_monitoring_result: [
            {
                value: "WAIT",
                label: "待ち呼中"
            },
            {
                value: "ANSWERED",
                label: "ANSWERED"
            },
            {
                value: "NO ANSWER",
                label: "NO ANSWER"
            },
            {
                value: "BUSY",
                label: "BUSY"
            },
            {
                value: "PLAYBACK",
                label: "PLAYBACK"
            },
            {
                value: "FAILED",
                label: "FAILED"
            }
        ],
        download_file_type: [
            {
                value: "ogg",
                label: "OGG"
            },
            {
                value: "mp3",
                label: "MP3"
            },
            {
                value: "m4a",
                label: "M4a"
            },
            {
                value: "wav",
                label: "WAV"
            }
        ],
        download_file_type_no_mp3: [
            {
                value: "ogg",
                label: "OGG"
            },
            {
                value: "wav",
                label: "WAV"
            }
        ],
        hour_show_list: [
            { value: "00", label: "00" },
            { value: "01", label: "01" },
            { value: "02", label: "02" },
            { value: "03", label: "03" },
            { value: "04", label: "04" },
            { value: "05", label: "05" },
            { value: "06", label: "06" },
            { value: "07", label: "07" },
            { value: "08", label: "08" },
            { value: "09", label: "09" },
            { value: "10", label: "10" },
            { value: "11", label: "11" },
            { value: "12", label: "12" },
            { value: "13", label: "13" },
            { value: "14", label: "14" },
            { value: "15", label: "15" },
            { value: "16", label: "16" },
            { value: "17", label: "17" },
            { value: "18", label: "18" },
            { value: "19", label: "19" },
            { value: "20", label: "20" },
            { value: "21", label: "21" },
            { value: "22", label: "22" },
            { value: "23", label: "23" }
        ],
        end_hour_show_list: [
            { value: "01", label: "01" },
            { value: "02", label: "02" },
            { value: "03", label: "03" },
            { value: "04", label: "04" },
            { value: "05", label: "05" },
            { value: "06", label: "06" },
            { value: "07", label: "07" },
            { value: "08", label: "08" },
            { value: "09", label: "09" },
            { value: "10", label: "10" },
            { value: "11", label: "11" },
            { value: "12", label: "12" },
            { value: "13", label: "13" },
            { value: "14", label: "14" },
            { value: "15", label: "15" },
            { value: "16", label: "16" },
            { value: "17", label: "17" },
            { value: "18", label: "18" },
            { value: "19", label: "19" },
            { value: "20", label: "20" },
            { value: "21", label: "21" },
            { value: "22", label: "22" },
            { value: "23", label: "23" },
            { value: "24", label: "24" }
        ],
        minute_show_list: [
            { value: "00", label: "00" },
            { value: "01", label: "01" },
            { value: "02", label: "02" },
            { value: "03", label: "03" },
            { value: "04", label: "04" },
            { value: "05", label: "05" },
            { value: "06", label: "06" },
            { value: "07", label: "07" },
            { value: "08", label: "08" },
            { value: "09", label: "09" },
            { value: "10", label: "10" },
            { value: "11", label: "11" },
            { value: "12", label: "12" },
            { value: "13", label: "13" },
            { value: "14", label: "14" },
            { value: "15", label: "15" },
            { value: "16", label: "16" },
            { value: "17", label: "17" },
            { value: "18", label: "18" },
            { value: "19", label: "19" },
            { value: "20", label: "20" },
            { value: "21", label: "21" },
            { value: "22", label: "22" },
            { value: "23", label: "23" },
            { value: "24", label: "24" },
            { value: "25", label: "25" },
            { value: "26", label: "26" },
            { value: "27", label: "27" },
            { value: "28", label: "28" },
            { value: "29", label: "29" },
            { value: "30", label: "30" },
            { value: "31", label: "31" },
            { value: "32", label: "32" },
            { value: "33", label: "33" },
            { value: "34", label: "34" },
            { value: "35", label: "35" },
            { value: "36", label: "36" },
            { value: "37", label: "37" },
            { value: "38", label: "38" },
            { value: "39", label: "39" },
            { value: "40", label: "40" },
            { value: "41", label: "41" },
            { value: "42", label: "42" },
            { value: "43", label: "43" },
            { value: "44", label: "44" },
            { value: "45", label: "45" },
            { value: "46", label: "46" },
            { value: "47", label: "47" },
            { value: "48", label: "48" },
            { value: "49", label: "49" },
            { value: "50", label: "50" },
            { value: "51", label: "51" },
            { value: "52", label: "52" },
            { value: "53", label: "53" },
            { value: "54", label: "54" },
            { value: "55", label: "55" },
            { value: "56", label: "56" },
            { value: "57", label: "57" },
            { value: "58", label: "58" },
            { value: "59", label: "59" }
        ],

        scope: [
            {
                value: "floor",
                label: lang.Floor
            },
            {
                value: "department",
                label: lang.Department
            },
            {
                value: "company",
                label: lang.Company
            },
            {
                value: "oem",
                label: lang.Oem
            },
            {
                value: "system",
                label: lang.System
            }
        ],
        audio_type: [
            { value: "file", label: lang.AudioFileUpload },
            { value: "select", label: lang.AudioSelect },
            { value: "synthetic", label: lang.AudioSynthetic }
        ],
        audio_gen_language: [
            { value: "ja-JP", label: lang.Language.Japanese },
            { value: "en-EN", label: lang.Language.English }
        ],
        audio_gen_person: [
            { value: "Mizuki", label: lang.AudioClassMizuki },
            { value: "Takumi", label: lang.AudioClassTakumi }
        ],
        audio_custom_column: [
            { value: "tel_no", label: lang.CustomColumnTelNo },
            { value: "address", label: lang.CustomColumnAddress }
        ],
        select_number: [
            { value: 0, label: "0" },
            { value: 1, label: "1" },
            { value: 2, label: "2" },
            { value: 3, label: "3" },
            { value: 4, label: "4" },
            { value: 5, label: "5" },
            { value: 6, label: "6" },
            { value: 7, label: "7" },
            { value: 8, label: "8" },
            { value: 9, label: "9" }
        ],
        push_number: [
            { value: "1", label: "1" },
            { value: "2", label: "2" },
            { value: "3", label: "3" },
            { value: "4", label: "4" },
            { value: "5", label: "5" },
            { value: "6", label: "6" },
            { value: "7", label: "7" },
            { value: "8", label: "8" },
            { value: "9", label: "9" },
            { value: "0", label: "0" },
            { value: "*", label: "*" },
            { value: "#", label: "#" }
        ],
        next_session_event: [{ value: "end", label: lang.EndCall }],
        inbound_action: [
            { value: "playback", label: lang.PlayBack },
            { value: "busy", label: lang.Busy }
        ],
        call_over_action_control: [
            { value: "busy", label: lang.Busy },
            { value: "playback", label: lang.PlayBack }
        ],
        call_over_action: [
            { value: "noans", label: lang.NoAns },
            { value: "busy", label: lang.Busy }
        ],
        timeout_playback: [
            { value: "Y", label: lang.TimeoutPlaybackSet },
            { value: "N", label: lang.TimeoutPlaybackUnset }
        ],
        unconnected_action: [
            { value: "busy", label: lang.Busy },
            { value: "playback", label: lang.PlayBack }
        ],
        dial_restriction: [
            { value: "N", label: lang.InboundSet },
            { value: "Y", label: lang.InboundUnset }
        ],
        anonymous_action: [
            { value: "unset", label: lang.Unset },
            { value: "busy", label: lang.Busy },
            { value: "playback", label: lang.PlayBack }
        ],
        script_audio_synthetic_type: [
            { value: "nomal", label: lang.SpeechSynthesis},
            { value: "translate", label: lang.SpeechSynthesisTranslate}
        ],
        script_additional_event: [
            { value: "timeout_event", label: lang.Timeout},
            { value: "next_other_num_select_event", label: lang.OthersNumberInput}
        ],
        language_code_list: [
            {
                code_translate: "en",
                code_polly: "en-US",
                LanguageName: "English",
                label: lang.Language.English
            },
            {
                code_translate: "ja",
                code_polly: "ja-JP",
                LanguageName: "Japanese",
                label: lang.Language.Japanese
            },
            {
                code_translate: "ko",
                code_polly: "ko-KR",
                LanguageName: "Korean",
                label: lang.Language.Korean
            },
            {
                code_translate: "ar",
                code_polly: "arb",
                LanguageName: "Arabic",
                label: lang.Language.Arabic
            },
            {
                code_translate: "zh-TW",
                code_polly: "cmn-CN",
                LanguageName: "Chinese",
                label: lang.Language.Chinese
            },
            {
                code_translate: "da",
                code_polly: "da-DK",
                LanguageName: "Danish",
                label: lang.Language.Danish
            },
            {
                code_translate: "nl",
                code_polly: "nl-NL",
                LanguageName: "Dutch",
                label: lang.Language.Dutch
            },
            {
                code_translate: "fr",
                code_polly: "fr-FR",
                LanguageName: "French",
                label: lang.Language.French
            },
            {
                code_translate: "de",
                code_polly: "de-DE",
                LanguageName: "German",
                label: lang.Language.German
            },
            {
                code_translate: "is",
                code_polly: "is-IS",
                LanguageName: "Icelandic",
                label: lang.Language.Icelandic
            },
            {
                code_translate: "it",
                code_polly: "it-IT",
                LanguageName: "Italian",
                label: lang.Language.Italian
            },
            {
                code_translate: "no",
                code_polly: "nb-NO",
                LanguageName: "Norwegian",
                label: lang.Language.Norwegian
            },
            {
                code_translate: "pl",
                code_polly: "pl-PL",
                LanguageName: "Polish",
                label: lang.Language.Polish
            },
            {
                code_translate: "pt-PT",
                code_polly: "pt-PT",
                LanguageName: "Portugal",
                label: lang.Language.Portugal
            },
            {
                code_translate: "ro",
                code_polly: "ro-RO",
                LanguageName: "Romanian",
                label: lang.Language.Romanian
            },
            {
                code_translate: "ru",
                code_polly: "ru-RU",
                LanguageName: "Russian",
                label: lang.Language.Russian
            },
            {
                code_translate: "es",
                code_polly: "es-ES",
                LanguageName: "Spanish",
                label: lang.Language.Spanish
            },
            {
                code_translate: "sv",
                code_polly: "sv-SE",
                LanguageName: "Swedish",
                label: lang.Language.Swedish
            },
            {
                code_translate: "tr",
                code_polly: "tr-TR",
                LanguageName: "Turkish",
                label: lang.Language.Turkish
            },
            {
                code_translate: "cy",
                code_polly: "cy-GB",
                LanguageName: "Welsh",
                label: lang.Language.Welsh
            }
        ],
        softphone_codec_type: [
            { value: "opus", label: "Opus"},
            { value: "narrowopus", label: "Narrowopus"}
        ],
        voice_log_expire: [
            {value: 12, label: lang.OneYear},
            {value: 24, label: lang.TwoYears},
            {value: 36, label: lang.ThreeYears},
            {value: 48, label: lang.FourYears},
            {value: 60, label: lang.FiveYears},
            {value: 999, label: lang.NoExpire}
        ],
        voice_log_expire_external_number: [
            {value: -1, label: lang.CompanyOverwrite},
            {value: 12, label: lang.OneYear},
            {value: 24, label: lang.TwoYears},
            {value: 36, label: lang.ThreeYears},
            {value: 48, label: lang.FourYears},
            {value: 60, label: lang.FiveYears},
            {value: 999, label: lang.NoExpire}
        ],
        summary_period: [
            {value: "custom", label: "カスタム"},
            {value: "today", label: "今日"},
            {value: "yesterday", label: "昨日"},
            {value: "this_week", label: "今週"},
            {value: "last_week", label: "先週"},
            {value: "previous_2_week", label: "2週間前"},
            {value: "previous_3_week", label: "3週間前"},
            {value: "previous_4_week", label: "4週間前"},
            {value: "previous_5_week", label: "5週間前"},
            {value: "previous_6_week", label: "6週間前"},
            {value: "previous_30_day", label: "過去30日"},
            {value: "this_month", label: "今月"},
            {value: "last_month", label: "先月"},
            {value: "previous_2_month", label: "2か月前"},
            {value: "previous_3_month", label: "3か月前"},
            {value: "now_quarter", label: "今四半期"},
            {value: "previous_quarter", label: "前四半期"}
        ],
        weekday: [
            {value: "Sun", label: "日"},
            {value: "Mon", label: "月"},
            {value: "Tue", label: "火"},
            {value: "Wed", label: "水"},
            {value: "Thu", label: "木"},
            {value: "Fri", label: "金"},
            {value: "Sat", label: "土"},
        ],
        template_period_option: [
            { value: "fixed", label: "固定"},
            { value: "today", label: "今日"},
            { value: "yesterday", label: "昨日"},
            { value: "this_week", label: "今週"},
            { value: "last_week", label: "先週"},
            { value: "this_month", label: "今月"},
            { value: "last_month", label: "先月"},
        ],
        predictive_call_result: [
            {value: "answered", label: "通話"},
            {value: "disconnected", label: "接続放棄"},
            {value: "no_answered", label: "不在"},
            {value: "busy", label: "ビジー"},
            {value: "error", label: "エラー"}
        ],
        call_analysis_summary_col_list: [
            {
                value: "total_connection_count",
                label: lang.TotalCallConnectCount, //接続数（接続率）
            },
            {
                value: "total_billsec",
                label: lang.TotalCallTime, //総通話時間
            },
            {
                value: "total_score",
                label: lang.Score, //総合評価
            },
            {
                value: "billsec",
                label: lang.TalkingTime, //通話時間
            },
            {
                value: "silence_count",
                label: lang.SilenceCount, //無音回数
            },
            {
                value: "talk_per",
                label: lang.TalkRate, //OP会話比率
            },
            {
                value: "talk_conflict_count",
                label: lang.TalkConflictCount, //重なり回数
            },
            {
                value: "operator_talk_speed",
                label: lang.TalkSpeed, //会話速度
            },
            {
                value: "operator_talk_speed_diff",
                label: lang.TalkSpeedDifference, //会話速度差
            },
            {
                value: "rallying_count",
                label: lang.RallyingCount, //会話の往復回数
            },
            {
                value: "operator_frequency_average",
                label: lang.Frequency, //基本周波数
            },
            {
                value: "operator_frequency_average_diff",
                label: lang.FrequencyDifference, //基本周波数の差
            },
            {
                value: "operator_accent",
                label: lang.Accent, //音声のメリハリ
            },
            {
                value: "operator_accent_diff",
                label: lang.AccentDifference, //音声のメリハリの差
            }
        ],
        count_condition: [
            {value: "more", label: "回以上"},
            {value: "less", label: "回未満"},
        ]
    };

    return column_name ? selectOptions[column_name] : selectOptions;
};
