import React from 'react';
import { FormControl } from "react-bootstrap";

import AscMenu from "./Menu";
import ASFieldGroup from "../../../Elements/FieldGroup/FieldGroup";
import ASSelect from "../../../Elements/Select/Select";

export default class Transfer extends AscMenu {
    constructor(props) {
        super(props);
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
                {this.getAudioElem({
                    key: 0,
                    audio_label: this.props.langText.Body.ScriptCallAudio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "audio_type",
                    audio_file: "audio_file",
                    audio_file_id: "audio_file_id",
                    audio_gen_language_original: "audio_gen_language_original",
                    audio_gen_language: "audio_gen_language",
                    audio_gen_person: "audio_gen_person",
                    audio_gen_text_original: "audio_gen_text_original",
                    audio_gen_text: "audio_gen_text",
                    synthetic_type: "synthetic_type"
                })}
                {this.getAudioElem({
                    key: 1,
                    audio_label: this.props.langText.Body.ScriptTimeoutAudio,
                    synthetic_label: this.props.langText.Body.ScriptAudioSyntheticSetting,
                    audio_type: "transfer_audio_type",
                    audio_file: "transfer_audio_file",
                    audio_file_id: "transfer_audio_file_id",
                    audio_gen_language_original: "transfer_audio_gen_language_original",
                    audio_gen_language: "transfer_audio_gen_language",
                    audio_gen_person: "transfer_audio_gen_person",
                    audio_gen_text_original: "transfer_audio_gen_text_original",
                    audio_gen_text: "transfer_audio_gen_text",
                    synthetic_type: "transfer_synthetic_type"
                })}
                <ASFieldGroup
                    type="text"
                    label={this.props.langText.Body.ScriptTransferTelNumber}
                    value={this.state.data.transfer_tel_number}
                    onChange={e => {
                        if (!isNaN(e.target.value)) {
                            this.state.data.transfer_tel_number = e.target.value;
                            this.setState(this.state.data);
                        }
                    }}
                    validationState={this.validation.menu.transfer_tel_number(this.state.data)}/>
                <ASFieldGroup
                    label={this.props.langText.Body.ScriptTransferTimeout}>
                    <div className="form-inline">
                        <FormControl
                            type="number"
                            value={this.state.data.transfer_timeout_seconds}
                            onChange={e => {
                                let num_value = parseInt(e.target.value);
                                if (isNaN(num_value)) {
                                    num_value = 1;
                                }
                                this.state.data.transfer_timeout_seconds = num_value;
                                this.setState(this.state.data);
                            }}
                            min="1"
                            placeholder={this.props.langText.Message.Default_value_auto}/>
                        秒
                    </div>
                </ASFieldGroup>
            </>
        );
    }
}