import React from 'react';

import AscMenu from "./Menu";

export default class Count extends AscMenu {
    constructor(props) {
        super(props);
    }

    getOptionElem() {
        return (
            <>
                {this.getTitleElem()}
            </>
        );
    }
}