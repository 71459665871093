import React, { Component } from "react";
import { Panel, Glyphicon } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import { CSVLink } from "react-csv";
import { CSVReader } from 'react-papaparse';
import * as GlobalConst from '../../components/AscConstants';

/**
 * オペレーター情報CSVインポートコンポーネント
 */
export default class OperatorCsvInsertModal extends Component {

    /**
     * 会社、拠点、フロア設定箇所
     */
    getUserBelongInfo = () => {
        let {
            getScopeGreaterEqual,
            floor_flg,
            state,
            langText,
            onSelectChange,
            validationHandle,
        } = this.props;
        let itemArr = [];

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))
        {
            itemArr.push(
                <InlineForm
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.companyId}
                    onChange = {e => onSelectChange(e, "companyId")}
                    options = {state.companySelect}
                    validationState = {validationHandle("companyId")}
                    placeholder = {!state.buttondisabledArr.companyId[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.companyId[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company))
        {
            itemArr.push(
                <InlineForm
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.departmentId}
                    onChange = {e => onSelectChange(e, "departmentId")}
                    options = {state.departmentSelect}
                    validationState = {validationHandle("departmentId")}
                    placeholder = {state.buttondisabledArr.departmentLoad[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.departmentId[0]}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "departmentId"
                    controlId = "departmentId"
                    label = {langText.Body.DepartmentName}>
                    {state.departmentName}
                </InlineForm.static>
            );
        }

        if (floor_flg) {
            if (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
            {
                itemArr.push(
                    <InlineForm
                        key = "floorId"
                        controlId = "floorId"
                        label = {langText.Body.FloorName}
                        type = "select"
                        value = {state.floorId}
                        onChange = {e => onSelectChange(e, "floorId")}
                        options = {state.floorSelect}
                        validationState = {validationHandle("floorId")}
                        placeholder = {state.buttondisabledArr.floorLoad[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.floorId[0]}
                        />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "floorId"
                        controlId = "floorId"
                        label = {langText.Body.FloorName}>
                        {state.floorName}
                    </InlineForm.static>
                );
            }
        }
        return itemArr;
    }

    /**
     * CSV投入箇所
     */
    csvInsert = () => {
        let {
            handleOnDrop,
            removeCsvFile,
            langText,
            headers,
            charaLimit,
            getSelectOption,
            sprintf,
            csvRowCountMaxLimit
        } = this.props;
        let itemArr = [];

        // CSVアップロード箇所
        itemArr.push(
            <CSVReader
                onDrop={handleOnDrop}
                onError={removeCsvFile}
                addRemoveButton
                removeButtonColor="#659cef"
                onRemoveFile={removeCsvFile}
                isReset={true}>
                <span>{langText.Message.CsvUpload}</span>
            </CSVReader>
        )

        // テンプレートDL箇所
        itemArr.push(
            <CSVLink
                className="csv-format-download-link"
                headers={headers}
                data={[]}
                filename={langText.Body.OperatorCsvTemplateFileName}
            >
                <b>{langText.Message.FormatDownloadLink}</b>
            </CSVLink>
        );

        // 注意書き
        itemArr.push(
            <>
                <p className="csvImportMsgCount">
                    {sprintf(langText.Message.CsvNoteCount, csvRowCountMaxLimit)}
                </p>
                <p className="csvImportMsgItem">
                    {langText.Message.OperatorCsvNoteItem}
                </p>
                <ul>
                    <li>
                        {sprintf(
                            langText.Message.OperatorCsvNoteId,
                            langText.Body.OperatorId,
                            charaLimit.Operator_Id
                        )}
                    </li>
                    <li>
                        {sprintf(
                            langText.Message.CsvNoteCharaLimit,
                            langText.Body.OperatorName,
                            charaLimit.Operator_Name
                        )}
                    </li>
                    <li>
                        {sprintf(
                            langText.Message.CsvNotePassword,
                            langText.Body.Password,
                            charaLimit.Operator_Password
                        )}
                    </li>
                </ul>
            </>
        )

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>{this.props.langText.Body.OperatorCsvBelongInfo}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getUserBelongInfo()}
                    </Panel.Body>
                </Panel>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>{this.props.langText.Body.OperatorCsvUpload}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.csvInsert()}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
