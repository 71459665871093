import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import {SelectCompanyElement, ViewCompanyElement} from "../Elements/AscElements/CompanyElements";
import {EditPermissionName, EditPermissionDetail} from "../Elements/AscElements/PermissionElements";


export default class PermissionCopyModal extends Component {

    render() {

        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.PermissionInfo}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.props.companySelectFlag
                            ? <SelectCompanyElement
                                state={this.props.state}
                                langText={this.props.langText}
                                onSelectChange={this.props.onSelectChange}>
                            </SelectCompanyElement>
                            : <ViewCompanyElement
                                state={this.props.state}
                                langText={this.props.langText}>
                            </ViewCompanyElement>}
                        <EditPermissionName
                            state={this.props.state}
                            langText={this.props.langText}
                            onTextChange_Limit = {this.props.onTextChange_Limit}
                            charaLimit = {this.props.charaLimit}>
                        </EditPermissionName>
                        <EditPermissionDetail
                            state={this.props.state}
                            langText={this.props.langText}
                            boardWidth={this.props.boardWidth}
                            currentPermission={this.props.currentPermission}
                            onNestCheckBoxChange = {this.props.onNestCheckBoxChange}
                            onNestSetState = {this.props.onNestSetState}>
                        </EditPermissionDetail>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
