import React from "react";
import AscComponent from "../../../components/AscComponent";
import { Button, ButtonGroup, Col, Row } from "react-bootstrap";
import Chart from "react-apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as faIcon from "@fortawesome/free-solid-svg-icons";

export default class TotalCallLineChart extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            series: [{
                data: []
            }],
            options: {
                chart: {
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                    background: '#fff'
                },
                colors: ['#0080FF'],
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                stroke: {
                    curve: 'straight',
                    width: 1
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: [],
                    tickAmount: 4,
                    labels: {
                        rotate: 0
                    },
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    tickAmount: 4
                },
                markers: {
                    size: 3,
                    colors: ['#0080FF'],
                    hover: {
                        size: 5
                    }
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return `
                            <div class="total-call-tooltip">
                                <div>${w.globals.categoryLabels[dataPointIndex]}</div>
                                <div>${series[seriesIndex][dataPointIndex]}回</div>
                            </div>
                        `;
                    }
                }
            },
            total_billsec: 0,
            daily_billsec: 0,
            total_call_count: 0,
            daily_call_count: 0,
            method: "average"
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.state.total_call_view_level !== this.props.state.total_call_view_level ||
            prevProps.data !== this.props.data 
        ) {
            if (this.props.compareFlag) {
                this.onChartDataCompareChange(this.props.state.total_call_view_level);
            } else {
                this.onChartDataChange(this.props.state.total_call_view_level);
            }
        }
    }

    onChartDataChange = (level) => {
        let {
            data,
            langText,
            secToTime,
            hiddenFiltered
        } = this.props;
        let category = [];
        let series_data = [];
        let series_name = level === "count" ? langText.Body.CallCount : langText.Body.TalkingTime;
        let unit = level === "count" ? langText.Body.TimeCount : "";
        let method = hiddenFiltered.find(row => row.id === "summary_method");

        if (data && data.buckets && data.buckets.length > 0) {
            data.buckets.forEach(row => {
                category.push(row.key_as_string);
                if (level === "count") series_data.push(row.total_call_count.value);
                else series_data.push(row.total_billsec.value);
            });
        }

        this.setState({
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: category,
                    tickAmount: 4
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return `
                            <div class="total-call-tooltip">
                                <div>${w.globals.categoryLabels[dataPointIndex]}</div>
                                <div>${unit === langText.Body.TimeCount ? series[seriesIndex][dataPointIndex] : secToTime(series[seriesIndex][dataPointIndex])}${unit}</div>
                            </div>
                        `;
                    }
                }
            },
            series:[{
                ...this.state.series,
                name: series_name,
                data: series_data
            }],
            total_billsec: data && data.search_total_billsec ? data.search_total_billsec : 0,
            daily_billsec: data && data.search_daily_billsec ? data.search_daily_billsec : 0,
            total_call_count: data && data.search_total_call_count ? data.search_total_call_count.toLocaleString() : 0,
            daily_call_count: data && data.search_daily_call_count ? data.search_daily_call_count.toLocaleString() : 0,
            method: method.value
        });
    }

    onChartDataCompareChange = (level) => {
        let {
            data,
            langText,
            secToTime,
            hiddenFiltered
        } = this.props;
        let series = [];
        let series_name = level === "count" ? langText.Body.CallCount : langText.Body.TalkingTime;
        let unit = level === "count" ? langText.Body.TimeCount : "";
        let method = hiddenFiltered.find(row => row.id === "summary_method");

        const a_date_arr = data && data[0] && data[0].buckets ? data[0].buckets.map(row => {return row.key_as_string}) : [];
        const b_date_arr = data && data[1] && data[1].buckets ? data[1].buckets.map(row => {return row.key_as_string}) : [];
        const total_length = data && data[0] && data[1] && data[0].buckets && data[1].buckets 
            ? Math.max(data[0].buckets.length, data[1].buckets.length) : 0;

        if (data.length > 0) {
            data.forEach((row, row_index) => {
                let series_temp = {};
                let series_data = [];
    
                for (let i = 0; i < total_length; i++) {
                    let value = 0;
                    if (level === "count" && row.buckets[i] && row.buckets[i].total_call_count.value) 
                        value = row.buckets[i].total_call_count.value;
                    else if (row.buckets[i] && row.buckets[i].total_billsec.value) 
                        value = row.buckets[i].total_billsec.value;
    
                    if (i < row.buckets.length) series_data.push(value); 
                    else series_data.push(null);
                }
    
                series_temp.name = row_index === 0 ? `${this.props.langText.Body.CompareA} ${series_name}` 
                    : `${this.props.langText.Body.CompareB} ${series_name}`;
                series_temp.data = series_data;
                series.push(series_temp);
            });
        }

        this.setState({
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: a_date_arr,
                    tickAmount: 4
                },
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        let data_a = series[0][dataPointIndex] || 0;
                        let data_b = series[1][dataPointIndex] || 0;

                        return `
                            <div class="common-line-chart-tooltip">
                                <div class="common-line-chart-tooltip-date">
                                    ${series[0][dataPointIndex] != null ? a_date_arr[dataPointIndex] : ""}</div>
                                <div class="common-line-chart-tooltip-padding">
                                    <span>${series[0][dataPointIndex] != null ? langText.Body.CompareA + " : " : ""}</span>
                                    <span class="font-color-0080FF">
                                        ${series[0][dataPointIndex] != null ? unit === langText.Body.TimeCount 
                                        ? data_a : secToTime(data_a) : ""}${series[0][dataPointIndex] != null ? unit : ""}</span>
                                </div>
                                <div class="common-line-chart-tooltip-date">
                                    ${series[1][dataPointIndex] != null ? b_date_arr[dataPointIndex] : ""}</div>
                                <div class="common-line-chart-tooltip-padding">
                                    <span>${series[1][dataPointIndex] != null ? langText.Body.CompareB + " : " : ""}</span>
                                    <span class="font-color-FFB366">
                                    ${series[1][dataPointIndex] != null ? unit === langText.Body.TimeCount 
                                        ? data_b : secToTime(data_b) : ""}${series[1][dataPointIndex] != null ? unit : ""}</span>
                                </div>
                            </div>
                        `
                    }
                },
                colors: ['#0080FF', '#FFB366'],
                markers: {
                    size: 3,
                    colors: ['#0080FF', '#FFB366'],
                    hover: {
                        size: 5
                    }
                }
            },
            series,
            total_billsec_a: data && data[0] ? data[0].search_total_billsec : 0,
            total_billsec_b: data && data[1] ? data[1].search_total_billsec : 0,
            daily_billsec_a: data && data[0] ? data[0].search_daily_billsec : 0,
            daily_billsec_b: data && data[1] ? data[1].search_daily_billsec : 0,
            total_call_count_a: data && data[0] ? data[0].search_total_call_count.toLocaleString() : 0,
            total_call_count_b: data && data[1] ? data[1].search_total_call_count.toLocaleString() : 0,
            daily_call_count_a: data && data[0] ? data[0].search_daily_call_count.toLocaleString() : 0,
            daily_call_count_b: data && data[1] ? data[1].search_daily_call_count.toLocaleString() : 0,
            method: method.value
        });
    }

    render() {
        return (
            <div className="total-call-line-chart">
                <Col sm={12} md={12} className="line-chart-data">
                    {this.props.compareFlag ?
                        <Col className="flex-direction-row">
                            <Col className="flex-direction-column margin-right-1">
                                <span className="font-weight-bold fa-12 font-color-0080FF">{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.state.total_call_count_a :
                                    this.props.secToTime(this.state.total_billsec_a)
                                }{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.props.langText.Body.TimeCount :
                                    ""
                                }</span>
                                <span className="fa-09">{this.props.langText.Body.CompareA}</span>
                            </Col>
                            <Col className="flex-direction-column">
                                <span className="font-weight-bold fa-12 font-color-FFB366">{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.state.total_call_count_b :
                                    this.props.secToTime(this.state.total_billsec_b)
                                }{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.props.langText.Body.TimeCount :
                                    ""
                                }</span>
                                <span className="fa-09">{this.props.langText.Body.CompareB}</span> 
                            </Col>
                        </Col> :
                        <Col sm={6} md={6} className="font-weight-bold fa-12">
                            <span className="font-color-0080FF">{
                                this.props.state.total_call_view_level === "count" ?
                                this.state.total_call_count :
                                this.props.secToTime(this.state.total_billsec)
                            }{
                                this.props.state.total_call_view_level === "count" ?
                                this.props.langText.Body.TimeCount :
                                ""
                            }</span>
                        </Col>}

                    <Col sm={7} md={7} className="text-align">
                        {this.props.compareFlag ? 
                            <Col>
                                <span className="margin-right-05">{this.props.state.total_call_view_level === "count" ?
                                    this.props.langText.Body.DayCallCount :
                                    this.props.langText.Body.DayTalkingTime
                                }</span>
                                <FontAwesomeIcon
                                    className="sidebar-icon fa-fw font-color-0080FF"
                                    icon={faIcon.faChartArea}
                                    size="lg"
                                />
                                <span className="margin-right-02">{this.props.langText.Body.CompareA}</span>
                                <span className="margin-right-02">{
                                    this.state.method === "average" ?
                                    this.props.langText.Body.Average :
                                    this.props.langText.Body.MedianValue
                                }</span>
                                <span className="font-color-0080FF margin-right-05">{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.state.daily_call_count_a :
                                    this.props.secToTime(this.state.daily_billsec_a)    
                                }{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.props.langText.Body.TimeCount :
                                    ""
                                }</span>

                                <FontAwesomeIcon
                                    className="sidebar-icon fa-fw font-color-FFB366"
                                    icon={faIcon.faChartArea}
                                    size="lg"
                                />
                                <span className="margin-right-02">{this.props.langText.Body.CompareB}</span>
                                <span className="margin-right-02">{
                                    this.state.method === "average" ?
                                    this.props.langText.Body.Average :
                                    this.props.langText.Body.MedianValue
                                }</span>
                                <span className="font-color-FFB366">{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.state.daily_call_count_b :
                                    this.props.secToTime(this.state.daily_billsec_b)    
                                }{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.props.langText.Body.TimeCount :
                                    ""
                                }</span>
                            </Col> :
                            <Col>
                                <span className="margin-right-05">{
                                    this.state.method === "average" ?
                                    this.props.langText.Body.DayAvg :
                                    this.props.langText.Body.DayMed
                                }</span>
                                <span className="font-color-0080FF">{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.state.daily_call_count :
                                    this.props.secToTime(this.state.daily_billsec)    
                                }{
                                    this.props.state.total_call_view_level === "count" ?
                                    this.props.langText.Body.TimeCount :
                                    ""
                                }</span>
                            </Col>}
                    </Col>
                </Col>
                <Chart options={this.state.options} series={this.state.series} type="line" height={350} />
            </div>
        );
    }
}
