import React from "react";
import {Button, Row, Col, FormControl} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../Elements/Table/CommonTable";
import InlineForm from "../Elements/FromGroup/InlineForm";
import ScriptCreateModal from "./ScriptCreateModal";
import ScriptDeleteModal from "./ScriptDeleteModal";
import ScriptSettingModal from "./ScriptSettingModal"
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import SetButtonWithSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import ScriptTemplate from "./Template/Template";
import * as GlobalConst from "../../components/AscConstants";
import * as faIcon from "@fortawesome/free-solid-svg-icons";


export default class Script extends AscComponent {
    constructor(props) {
        super(props);

        //非同期処理入れたから関数バインディング
        this.modalShow = this.modalShow.bind(this);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            pages: null,
            loading: false,
            filtered: [],

            version_list: [],
            company_list: [],

            // modal item
            show: false,

            template_show: false,
            template_elem: null
        };

        if (this.props.currentPermission.scope_code === "system") {
            this.getCompanyList();
        }
    }

    /**
     * 一覧表作成
     */
    getColumnsData() {
        let elem = [];

        elem.push({
            Header: this.props.langText.Body.Id,
            accessor: "id",
            filterable: false,
            width: this.props.boardWidth.xxsmall,
        });

        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            elem.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                Cell: data => {
                    return <SetDataWithSpeechBubble displayData = {data.row._original.cm12_companies.company_name} />
                },
                width: this.props.boardWidth.CompanyName,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
            });
        }

        elem.push(
            {
                Header: this.props.langText.Body.ScriptName,
                accessor: "name",
                Cell: data => {
                    return <SetDataWithSpeechBubble displayData = {data.row._original.name} />
                },
                width: this.props.boardWidth.ScriptName,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "name")} />,
            }, {
                Header: this.props.langText.Body.Description,
                accessor: "description",
                Cell: data => {
                    return <SetDataWithSpeechBubble displayData = {data.row._original.description} />
                },
                width: this.props.boardWidth.description,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "description")} />
            }, {
                Header: this.props.langText.Body.CurrentVersionHeader,
                Cell: data => {
                    let speechBubbleData = data.row._original.current_description ? data.row._original.current_description : data.row._original.current_version;
                    return <SetDataWithSpeechBubble
                        displayData = {data.row._original.current_version || this.props.langText.Body.None}
                        speechBubbleData = {speechBubbleData}
                        infoIconDisplayFlag = {true}
                        infoIconType = {faIcon.faInfoCircle}
                        infoIconClassName = "info-icon"
                        infoIconColor = "silver"
                        infoIconSize = "lg"/>
                },
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.xxsmall,
            }, {
                Header: this.props.langText.Body.LatestVersionHeader,
                Cell: data => {
                    let speechBubbleData = data.row._original.latest_description ? data.row._original.latest_description : data.row._original.latest_version;
                    return <SetDataWithSpeechBubble
                        displayData = {data.row._original.latest_version || this.props.langText.Body.None}
                        speechBubbleData = {speechBubbleData}
                        infoIconDisplayFlag = {true}
                        infoIconType = {faIcon.faInfoCircle}
                        infoIconClassName = "info-icon"
                        infoIconColor = "silver"
                        infoIconSize = "lg"/>
                },
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.xxsmall,
            }, {
                Header: this.props.langText.Body.UpdateUserName,
                accessor: "update_user_name",
                width: this.props.boardWidth.Date,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "update_user_name")} />
            }, {
                Header: this.props.langText.Body.Modified,
                accessor: "modified",
                filterable: false,
                width: this.props.boardWidth.Date,
                Cell: ({value}) => value ?
                    this.getMomentTime({format: this.props.langText.Body.DateFormat, date: value}) : "",
            }, {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.XLargeControl,
                Cell: data => {
                    // 権限に応じて各アイコン表示
                    let rowData = [];
                    // 編集権限あれば編集ボタン、編集権限無ければ閲覧ボタン
                    if (this.props.currentPermission.edit) {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={0}
                                bsSize="xsmall"
                                className="control-button displaying-button"
                                onClick={(event) => this.modalShow("setting", data.row._original, false, event)}
                                DisplayIcon="pencil"
                                speechBubble={this.props.langText.Body.UpdateTitle}
                            />
                        );
                    } else {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={1}
                                bsSize="xsmall"
                                className="control-button displaying-button"
                                onClick={(event) => this.modalShow("read", data.row._original, false, event)}
                                DisplayIcon="eye-open"
                                speechBubble={this.props.langText.Body.ReadTitle}
                            />
                        );
                    }
                    if (this.props.currentPermission.delete) {
                        rowData.push(
                            <SetButtonWithSpeechBubble
                                key={4}
                                bsSize ="xsmall"
                                className="control-button displaying-button"
                                onClick={(event) => this.modalShow("delete", data.row._original, true, event)}
                                DisplayIcon="minus"
                                speechBubble={this.props.langText.Body.DeleteTitle}
                            />
                        );
                    }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            }
        );

        return elem;
    }

    /**
     * 押下ボタンに応じたデータをmodal表示する
     * @param {string} modalType モーダルタイプ
     * @param {array} param 表示データ
     * @param {boolean} loadingDisplayFlag ローディング画面表示フラグ ture:表示する/false:表示しない
     */

    async modalShow (modalType, param, loadingDisplayFlag = false, event){
        event.preventDefault();

        //stateのセット
        let setData = {
            cm70_id: param && param.id
                ? param.id
                : "",
            script_name: param && param.name
                ? param.name
                : "",
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : "",
            company_name: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : "",
            description: param && param.description
                ? param.description
                : "",
            current_version: param && param.current_version
                ? param.current_version
                : "",
            current_description: param && param.current_description
                ? param.current_description
                : "",
            latest_version: param && param.latest_version
                ? param.latest_version
                : "",
            latest_description: param && param.latest_description
                ? param.latest_description
                : "",
            version: param && param.version
                ? param.version
                : 0,
            version_memo: param && param.version_memo
                ? param.version_memo
                : "",
            version_created: param && param.version_created
                ? param.version_created
                : "",
            version_check_tel_no: param && param.version_check_tel_no
                ? param.version_check_tel_no
                : "",
            usingCalendarItems: null,
            loadingDisplayFlag: loadingDisplayFlag,
            show: true,
            modalType
        };

        this.setState(setData);
        switch (modalType) {
            case "delete":
                try {
                    let version_list = await this.getVersionList(param.id);
                    let current_info = version_list.find(row => row.value === parseInt(param.current_version));
                    let latest_info = version_list.find(row => row.value === parseInt(param.latest_version));

                    let settingData = {
                        current_description: current_info ? current_info.description : "",
                        latest_description: latest_info ? latest_info.description : "",
                    };

                    //使用中のスクリプトをチェック
                    this.getScriptUsingCalendarName(param.id)
                    .then(res => {
                        if (res.length > 0) {
                            //エラーメッセージの作成
                            calendarItems = res.map(calendarItem =>
                                calendarItem.business_calendar_name + "(ID: "
                                + calendarItem.cm31_id + ", " + this.props.langText.Body.Type + ": " + calendarItem.type + ")"
                            );
                        }
                        Object.assign(settingData, { usingCalendarItems: calendarItems, loadingDisplayFlag: false });
                        //使用中のスクリプトをセット、ロード画面の解除
                        this.setState(settingData);
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                        this.setState({ show: false, loadingDisplayFlag: false });
                    });

                } catch (err) {
                    //関数呼び出すとき以外エラー発生したら、エラーコード２出力
                    err = err.response.data || {code : 2};
                    alert(this.getErrorString(err));
                    this.setState({show:false,modalType:""});
                }
                let calendarItems = Array();
                
                break;
            case "setting":
                //バージョンリストと現在のバージョンをレスポンスで持ってきてstateに設定
                try {
                    let version_list = await this.getVersionList(param.id);
                    let current_info = await this.getCurrentVersion(param.id);
                    let created;
                    
                    if(current_info.current_created){
                        created = this.getMomentTime({date: current_info.current_created});
                    }

                    let {current_version,current_description} = current_info;
                    
                    //バージョンリストに現在適用バージョンのラベルを付ける
                    version_list.some(list => {
                        if(list.value == current_version){
                            list.label = list.label + this.props.langText.Body.CurrentVersionLabel;
                            return true;
                        }
                    });

                    //データ初期化・現在のデータ格納・モーダルを開ける
                    let settingData = {
                        version: current_version ? current_version : 0,
                        version_memo: current_info.current_description ? current_info.current_description : "",
                        version_created: created ? created : "",
                        version_list: version_list,
                        current_version: current_version,
                        current_description: current_description,
                        loadingDisplayFlag: false
                    };
                    this.setState(settingData);
                } catch (err) {

                    //関数呼び出すとき以外エラー発生したら、エラーコード２出力
                    err = err.response.data || {code : 2};
                    alert(this.getErrorString(err));
                    this.setState({show:false,modalType:""});
                }
                
                break;
            default:
                break
        }
    };

    templateShow = ({cm70_id, script_name, company_id, version}) => event => {
        if (cm70_id && company_id) {
            this.blockUI();
            this
                .ascAxios(
                    "post",
                    `${this.reactContainerPath}/getTemplateJson`,
                    {cm70_id, version}
                )
                .then(result => {
                    let template_id = null;
                    let template_json = null;
                    let template_description = null;
                    let template_timeout_seconds = 30;

                    if (result.data) {
                        template_id = result.data.id;
                        template_description = result.data.description;
                        template_json = result.data.json_data;
                        template_timeout_seconds = result.data.timeout_seconds;
                    }

                    this.setState({
                        show: false,
                        template_show: true,
                        template_elem: (
                            <ScriptTemplate
                                company_id={company_id}
                                script_id={cm70_id}
                                script_name={script_name}
                                version={version}
                                template_id={template_id}
                                template_description={template_description}
                                template_timeout_seconds={template_timeout_seconds}
                                template_json={template_json}
                                onHide={e => this.templateHide()}
                                sprintf = {this.sprintf}
                                {...this.props}/>
                        )
                    });
                    this.props.setHistoryLock(true);
                })
                .catch(err => {
                    console.log(err);
                    alert(this.getErrorString(err.response.data.code));
                });
        } else {
            // バグ
            alert(this.props.langText.Message.DB_Update_Data_Search_None);
        }
    }

    templateHide() {
        this.setState({
            template_show: false,
            template_elem: null
        });
        this.props.setHistoryLock(false);
    }

    /**
     * 各画面先でのボタン押下時の動作内容
     */
    onClickHandle = modalType => event => {
        let {
            company_id,
            script_name,
            description,
            cm70_id,
            version,
            version_memo
        } = this.state;

        switch (modalType) {
            case "create":
                this.ascAxios('post', `${this.reactContainerPath}/create`, {company_id, script_name, description})
                .then(res => {
                    alert(this.props.langText.Message.DataInsertSuccess);
                    this.reactTableRefresh();
                })
                .catch(err => {
                    alert(this.getErrorString(err.response.data.code));
                });
                this.setState({show: false});
                break;
            case "delete":
                this.blockUI();
                this
                    .ascAxios("post", `${this.reactContainerPath}/delete`, {
                        cm70_id,
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataDeleteSuccess
                        );
                        this.reactTableRefresh();
                    })
                    .catch(err => {
                        alert(this.getErrorString(err.response.data));
                    })
                this.setState({show: false});
                break;
            case "setting":
                this.ascAxios('post', `${this.reactContainerPath}/update`, {cm70_id, script_name, description, version})
                .then(res => {
                    alert(this.props.langText.Message.DataUpdateSuccess);
                    this.reactTableRefresh();
                })
                .catch(err => {
                    alert(this.getErrorString(err.response.data.code));
                });
                this.setState({show: false});
                break;

            default:
                // データ取得失敗エラー
                alert(this.getErrorString({code: 45}));
        }
    };

    /**
     * 各画面のボタン押下条件
     */
    validationHandle = param => {
        let validation_flag = false;
        let {version} = this.state;

        switch (param) {
            case "create":
                validation_flag = ((this.props.currentPermission.scope_code !== "system" || this.state.company_id) && this.state.script_name);
                break;

            case "delete":
                if (this.state.usingCalendarItems && this.state.usingCalendarItems.length === 0){
                    validation_flag = true;
                }
                break;
            case "setting":
                validation_flag = this.state.script_name && (this.state.version_list.length <= 1 || version) ? true : false;
                break;
            default:
                validation_flag = false;
        }

        return validation_flag;
    };

    getCompanyList (){
        this.ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(result => {
                this.setState({company_list: result.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data.code));
            });
    }

    async getVersionList(cm70_id){
        let version_list=[
            {
                label: this.props.langText.Body.NewInsert,
                value: 0,
                description: ""
            }
        ];

        //該当スクリプトのバージョンリストを持ってきてデータ加工
        await this.ascAxios("post", `${this.reactContainerPath}/getVersionList`, {cm70_id})
            .then(result=>{
                let array_data = result.data.versionList;
                for(let count = 0; count < array_data.length; count++){
                    let data = array_data[count];
                    version_list.push({
                        label: data.version,
                        value: data.version,
                        description: data.description,
                        created: data.created
                    });
                }
            })
            .catch(err=>{
                throw err;
            });

            return version_list;
    }

    async getCurrentVersion(cm70_id){
        let current_info;

        //現在適用バージョンの情報取得
        await this.ascAxios("post", `${this.reactContainerPath}/getCurrentVersion`, {cm70_id})
            .then(result=>{
                current_info = result.data;
            })
            .catch(err=>{
                throw err;
            });
            
        return current_info;
    }

    onSelectChange(event,param){
        super.onSelectChange(event, param);
        let memo = (event && event.description) ? event.description : "";
        let created = (event && event.created) ? this.getMomentTime({date:event.created}) : "";

        switch(param){
            case 'version':
                //バージョンを選択したら、メモと登録日もバージョンに合わせて変更される
                this.setState({version_memo:memo, version_created:created});
                break;
            default:
                break;
        }
    }

    /**
     * モダールアイテム取得
     */
    getModalScriptItem = () => {
        return (
            <div>
                <InlineForm
                    label={this.props.langText.Body.CompanyName}>
                    <Col className="company_name">
                        <InlineForm.static
                            key="company_name"
                            controlId="company_name">
                            {this.state.company_name}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
                <InlineForm
                    label={this.props.langText.Body.ScriptName}>
                    <Col className="script_name">
                        <InlineForm.static
                            key="script_name"
                            controlId="script_name">
                            {this.state.script_name}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
                <InlineForm
                    label={this.props.langText.Body.Description}>
                    <Col className="description">
                        <InlineForm.static
                            key="description"
                            controlId="description">
                            {this.state.description}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
                <InlineForm
                    label={this.props.langText.Body.CurrentVersion}>
                    <Col className="current_version">
                        <InlineForm.static
                            key="current_version"
                            controlId="current_version">
                            {this.state.current_version}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
                <InlineForm
                    label={this.props.langText.Body.CurrentDescription}>
                    <Col sm={5} className="current_description" style={{paddingLeft: "0px"}}>
                        <InlineForm.static
                            key="current_description"
                            controlId="current_description">
                            {this.state.current_description}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
                <InlineForm
                    label={this.props.langText.Body.LatestVersion}>
                    <Col className="latest_version">
                        <InlineForm.static
                            key="latest_version"
                            controlId="latest_version">
                            {this.state.latest_version}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
                <InlineForm
                    label={this.props.langText.Body.LatestDescription}>
                    <Col className="latest_description">
                        <InlineForm.static
                            key="latest_description"
                            controlId="latest_description">
                            {this.state.latest_description}
                        </InlineForm.static>
                    </Col>
                </InlineForm>
            </div>
        )
    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "create":
                return (
                    <ScriptCreateModal
                        state = {this.state}
                        scope_code = {this.props.currentPermission.scope_code}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        charaLimit = {this.props.charaLimit}
                        validationHandle = {this.validationHandle}
                        onSelectChange = {this.onSelectChange}
                        onTextChange = {this.onTextChange_Limit}/>
                )
                break;
            case "delete":
            case "read":
                return (
                    <ScriptDeleteModal
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                        getModalScriptItem = {this.getModalScriptItem}/>
                )
                break;
            case "setting":
                return(
                    <ScriptSettingModal
                        state = {this.state}
                        scope_code = {this.props.currentPermission.scope_code}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        onClick = {this.onClickHandle}
                        charaLimit = {this.props.charaLimit}
                        validationHandle = {this.validationHandle}
                        onSelectChange = {this.onSelectChange}
                        onTextChange = {this.onTextChange_Limit}
                        templateShow = {this.templateShow}
                    />
                )
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <>
                {!this.state.template_show ?
                    <div className="Script">
                        <Row>
                            <Col xs={12} md={12}>
                                {
                                    this.props.currentPermission.create
                                    &&
                                    <Button
                                        id = "script-insert"
                                        className = "table-button"
                                        bsStyle = "default"
                                        bsSize = "sm"
                                        onClick={(event) => this.modalShow("create", null, false, event)}
                                    >
                                        {this.props.langText.Body.InsertTitle}
                                    </Button>
                                }
                            </Col>
                            <Col xs={12} md={12}>
                                <CommonTable
                                    talbeRef={this.table}
                                    style={{height: this.props.tableHeight}}
                                    manual="manual"
                                    columns={this.state.columns}
                                    data={this.state.data}
                                    pages={this.state.pages}
                                    loading={this.state.loading}
                                    defaultSorted={[{id: "id", desc: true}]}
                                    onFetchData={this.fetchData}
                                    filtered={this.state.filtered}
                                    filterable={true}
                                    previousText={this.props.langText.Table.PreviousText}
                                    nextText={this.props.langText.Table.NextText}
                                    loadingText={this.props.langText.Table.LoadingText}
                                    noDataText={this.props.langText.Table.NoDataText}
                                    rowsText={this.props.langText.Table.RowsText}/>
                            </Col>
                        </Row>
                            {/*モーダル表示分岐*/}
                            {this.getModalBranch()}
                    </div>
                    :
                    <>
                        {this.state.template_elem}
                    </>
                }
            </>
        );
    }
}
