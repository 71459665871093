import React from "react";
import { Panel } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css"
import AscModal from "../Elements/Modal/Modal";
import { InsertAutoCallList } from "../Elements/AscElements/AutoCallListElements";
import { SelectCompanyElement } from "../Elements/AscElements/CompanyElements";
import AscComponent from '../../components/AscComponent';
import * as GlobalConst from '../../components/AscConstants';

/**
 * 自動発信リスト新規登録コンポーネント
 */
export default class AutoCallListInsertModal extends AscComponent {
    /**
     * SystemとOem権限の場合に会社名セレクトボックスをセット
     */
    setSelectCompanyElement = () => {
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            return (
                <SelectCompanyElement
                    state={this.props.state}
                    langText={this.props.langText}
                    onSelectChange={this.props.onSelectChange}>
                </SelectCompanyElement>
            )
        }
    }
    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Body>
                        {this.setSelectCompanyElement()}
                        <InsertAutoCallList
                            state={this.props.state}
                            langText={this.props.langText}
                            onTextChange_Limit={this.props.onTextChange_Limit}
                            charaLimit={this.props.charaLimit}
                            boardWidth={this.props.boardWidth}>
                        </InsertAutoCallList>
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
