import React from "react";
import { Panel, Glyphicon } from "react-bootstrap";
import "../Elements/FromGroup/form_select.css";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import AscComponent from "../../components/AscComponent";
import MultiSelect from "../Elements/Select/MultiSelect";
import * as GlobalConst from "../../components/AscConstants";

export default class SkillManagementModal extends AscComponent {
    setSkillModalItem = () => {
        let {
            state,
            langText,
            charaLimit,
            propSetState,
            onTextChange_Limit,
            onSelectChange,
            validationHandle,
            onChangeDepartment,
            setFloorSelects
        } = this.props;

        let itemArr = [];

        if (state.modalType === "insert" || state.modalType === "multi_delete") {
            // 業務名ラベル
            itemArr.push(
                <InlineForm.static
                    key="business_name"
                    controlId="business_name"
                    label={langText.Body.BusinessName}>
                    {state.business_name}
                </InlineForm.static>
            );

            if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                itemArr.push(
                    <InlineForm
                        key="department_id"
                        controlId="department_id"
                        label={
                            this.props.labelDisabled
                                ? null
                                : this.props.langText.Body.DepartmentName
                        }
                        type="select"
                        onChange={(e) => {
                            this.props.onSelectChange(e, "selected_department");
                            if (state.modalType === "insert" && !state.task_company_floor_flg) {
                                onChangeDepartment(e, "department_id");
                            } else if (
                                state.modalType === "multi_delete" &&
                                !state.task_company_floor_flg
                            ) {
                                onChangeDepartment(e, "department_id_for_del");
                            }
                            if (state.task_company_floor_flg) {
                                setFloorSelects(e);
                            }
                        }}
                        options={state.departmentSelect}
                        value={state.selected_department}
                        isClearable={true}
                        placeholder={
                            !state.departmentSelect[0] ? (
                                <Glyphicon glyph="refresh" className="spinning" />
                            ) : (
                                this.props.langText.SelectOption.Placeholder
                            )
                        }
                    />
                );
            }

            if (state.task_company_floor_flg) {
                if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)) {
                    itemArr.push(
                        <InlineForm
                            key="floor_id"
                            controlId="floor_id"
                            label={langText.Body.FloorName}
                            type="select"
                            value={state.selected_floor}
                            onChange={(e) => {
                                this.props.onSelectChange(e, "selected_floor");
                                if (state.modalType === "insert") {
                                    onChangeDepartment(e, "department_id");
                                } else if (state.modalType === "multi_delete") {
                                    onChangeDepartment(e, "department_id_for_del");
                                }
                            }}
                            options={state.floorSelect}
                            isClearable={true}
                            // validationState={validationHandle("floor_id")}
                            placeholder={this.props.langText.SelectOption.Placeholder}
                            isDisabled={state.floorSelect.length === 0}
                        />
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key="floor_id"
                            controlId="floor_id"
                            label={langText.Body.FloorName}>
                            {state.floor_name}
                        </InlineForm.static>
                    );
                }
            }

            itemArr.push(
                <InlineForm key="operator_id" label={langText.MultiSelect.OperatorSelect}>
                    <MultiSelect
                        key="operator_id"
                        controlId="operator_id"
                        propSetState={propSetState}
                        multiSelectText={langText.MultiSelect}
                        searchText={langText.MultiSelect.OperatorIdAndOperatorNameSearch}
                        options={state.operatorIdSelect}
                        value={state.operator_id}
                        type={state.modalType}
                        isDisabled={state.modalType === "delete" || state.modalType === "read"}
                        multiSelectMessage={langText.MultiSelect.SelectDataOperatorStr_NDelete}
                    />
                </InlineForm>
            );

            if (state.modalType === "insert") {
                itemArr.push(
                    <InlineForm
                        key="skill_rank"
                        controlId="skill_rank"
                        label={langText.Body.SkillRank}
                        type="select"
                        value={state.selected_skill}
                        onChange={(e) => onSelectChange(e, "selected_skill")}
                        options={state.skill_ranks}
                        isClearable={true}
                        placeholder={this.props.langText.SelectOption.Placeholder}
                        isDisabled={state.operator_id.length === 0}
                    />
                );

                // メモ入力のテキストボックス
                itemArr.push(
                    <InlineForm
                        key="memo"
                        controlId="memo"
                        label={langText.Body.Memo}
                        type="text"
                        value={state.memo}
                        onChange={(e) => onTextChange_Limit(e, "memo", charaLimit.Task_Memo)}
                    />
                );
            }
        } else if (state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key="target_operator_id"
                    controlId="target_operator_id"
                    label={langText.Body.OperatorId}>
                    {state.target_operator.operator_id}
                </InlineForm.static>
            );
            itemArr.push(
                <InlineForm.static
                    key="target_skill_rank"
                    controlId="target_skill_rank"
                    label={langText.Body.SkillRank}>
                    {state.target_skill_rank}
                </InlineForm.static>
            );
            itemArr.push(
                <InlineForm.static
                    key="target_memo"
                    controlId="target_memo"
                    label={langText.Body.Memo}>
                    {state.target_memo}
                </InlineForm.static>
            );
        }

        return itemArr;
    };

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Body>{this.setSkillModalItem()}</Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}
