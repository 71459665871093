import React from "react";
import {Button, Row, Col, Glyphicon} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import * as GlobalConst from "../../components/AscConstants";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import * as solidfaIcon from "@fortawesome/free-solid-svg-icons";
import * as regularfaIcon from "@fortawesome/free-regular-svg-icons";
import DownloadHistoryModal from "./DownloadHistoryModal";
import moment from "moment";
import SetButtonWithSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";

export default class DownloadHistory extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            pages: null,
            count: null,
            loading: false,
            filtered: [],
            hidden_filtered: [],
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],
            userSelect: [],
            
            // modal item
            show: false,
            modalType: null,
            blocking: false,
            disableFlg: false
        };

        this.table = React.createRef();
        this.detailTable = React.createRef();
    }

    // 初期作業
    async componentDidMount() {
        // 待ち呼集計画面からきたか
        let state = this.props.location.state ? this.props.location.state : [];
        let res = null;

        try {
            switch (this.props.currentPermission.scope_code) {
                case GlobalConst.SCOPE_OBJECT.system.name:
                case GlobalConst.SCOPE_OBJECT.oem.name:
                    // 会社選択肢セット
                    res = await this.getCommonCompanySelect(this.reactContainerPath.split("/")[1]);

                    this.setState({
                        companySelect: res.data,
                        floor_flg: true
                    });

                    break;
                case GlobalConst.SCOPE_OBJECT.company.name:
                    // 拠点選択肢セット
                    await this.getUser(this.props.userInfo.company_id);
                    await this.getDepartment();

                    this.setState({
                        floor_flg: this.props.userInfo.floor_flg
                    });

                    break;
                case GlobalConst.SCOPE_OBJECT.department.name:
                    // フロア選択肢セット
                    let cm13_id = this.props.userInfo.department_id,
                        body = {
                            company_id: this.props.userInfo.company_id,
                            department_prefix: this.props.userInfo.department_prefix,
                            floor_flg: this.props.userInfo.floor_flg
                        };
                    await this.getUser(cm13_id, body, "cm13_id");

                    if (this.props.userInfo.floor_flg) {
                        await this.getFloor();

                        this.setState({
                            floor_flg: this.props.userInfo.floor_flg
                        });
                    }

                    break;
                case GlobalConst.SCOPE_OBJECT.floor.name:
                    await this.getUser(this.props.userInfo.department_id);

                    break;
                default:
                    break;
            }
        } catch (err) {
            this.showErrorObjectMesssage(err)
            console.error(err);
        }

        // 通話品質分析or通知からきたか確認
        if (state.length === 0) return;

        // フィルター欄表示
        state.forEach(row => {
            if (row.id === "cm12_id") {
                if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) return;
            }
            this.onChange(row.value, row.id);
        });

        this.onSearch();
    }

    toggleBlocking() {
        this.setState({blocking: !this.state.blocking});
    }

    getColumnsData = () => {
        let columns = [];
        // ID
        columns.push({
            Header: this.props.langText.Body.Id,
            sortable: false,
            accessor: "id",
            width: this.props.boardWidth.xxsmall,
        });
        // ステータス
        columns.push({
            Header: this.props.langText.Body.Status,
            sortable: false,
            accessor: "status",
            width: this.props.boardWidth.smedium,
            Cell: data => {
                let returnStr = null;
                let returnStyle = `download-file-status ${data.value.toLowerCase()}`;
                if (data.value.toLowerCase() === "creating") returnStr = this.props.langText.Body.FileCreating;
                else if (data.value.toLowerCase() === "created") returnStr = this.props.langText.Body.FileCreated;
                else if (data.value.toLowerCase() === "failed") returnStr = this.props.langText.Body.FileFailed;

                return (
                    <div
                        className={returnStyle}
                    >{returnStr}</div>
                );
            }
        });
        // ダウンロード
        columns.push({
            Header: this.props.langText.Body.Download,
            sortable: false,
            accessor: "download_type",
            width: this.props.boardWidth.smedium,
            Cell: data => {
                let type = null;

                switch (data.value) {
                    case "call_analysis_call_content":
                        type = this.props.langText.Body.CallContent;
                        break;
                    case "call_history":
                        type = this.props.langText.Body.CallHistoryName;
                        break;
                    default:
                        break;
                }

                return type;
            }
        });
        // 実行日時
        columns.push({
            Header: this.props.langText.Body.ExecTime,
            sortable: true,
            accessor: "execution_start_datetime",
            Cell: data => {
                return data.value ? this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"}) : "";
            }
        });
        // ファイル名
        columns.push({
            Header: this.props.langText.Body.FileName,
            sortable: false,
            accessor: "file_name",
        });
        // 有効期限
        columns.push( {
            Header: this.props.langText.Body.ExpireTime,
            sortable: false,
            accessor: "download_expire",
            Cell: data => {
                let expire_flag = this.isFutureDate(data.value);
                let date = null;
                if (expire_flag) {
                    date = this.getMomentTime({date: data.value, format: "YYYY/MM/DD HH:mm:ss"})
                } else {
                    date = data.value ? this.props.langText.Body.DownloadHistoryExpired : null;
                }
                return date;
            }
        });
        // 作成者
        columns.push({
            Header: this.props.langText.Body.Creator,
            sortable: false,
            accessor: "cm15_users",
            width: this.props.boardWidth.smedium,
            Cell: data => {
                return data.value ? `${data.value.user_name_sei} ${data.value.user_name_mei}` : null;
            }
        });
        // 操作
        columns.push({
            Header: this.props.langText.Body.Control,
            width: this.props.boardWidth.smedium,
            sortable: false,
            Cell: data => {
                let rowData = [];
                let status = data.row._original.status && data.row._original.status.toLowerCase();
                let expire_flag = this.isFutureDate(data.row._original.download_expire);
                if (status === "created" && expire_flag) {
                    rowData.push(
                        <SetButtonWithSpeechBubble
                            key={0}
                            bsSize='xsmall'
                            onClick={async () => await this.downloadFile(data.row._original)}
                            className='control-button'
                            DisplayFontAwesomeIcon={solidfaIcon.faFileDownload}
                            iconSize="lg"
                            speechBubble={this.props.langText.Body.Download}
                        />
                    );
                }
                if (data.row._original.download_count) {
                    rowData.push(
                        <SetButtonWithSpeechBubble
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow("downloadHistoryDetail", data.row._original)}
                            className="control-button"
                            DisplayIcon="list"
                            speechBubble={this.props.langText.Body.HistoryDetail}
                        />
                    );
                }
                if (status === "created") {
                    rowData.push(
                        <SetButtonWithSpeechBubble
                            key={2}
                            bsSize="xsmall"
                            onClick={this.modalShow("redo", data.row._original)}
                            className="control-button"
                            DisplayFontAwesomeIcon={regularfaIcon.faFile}
                            iconSize="lg"
                            speechBubble={this.props.langText.Body.ReCreate}
                        />
                    );
                }
                if (status === "failed") {
                    rowData.push(
                        <SetButtonWithSpeechBubble
                            key={2}
                            bsSize="xsmall"
                            onClick={this.modalShow("errorContent", data.row._original)}
                            className="control-button"
                            DisplayFontAwesomeIcon={solidfaIcon.faExclamationTriangle}
                            iconSize="1x"
                            speechBubble={this.props.langText.Body.ErrorMessage}
                        />
                    );
                }
                return (
                    <Row className="text-center">
                        {rowData}      
                    </Row>
                );
            }
        })

        return columns;
    }
    
    // 日時検査
    isFutureDate = (expire) => {
        const now = moment();
        expire = moment(expire);

        return expire.isAfter(now);
    }

    // 作成者選択肢セット
    getUser = async (id, event, param) => {
        let body = {};

        // 検索条件による作成者リスト範囲
        switch (param) {
            case "cm13_id":
                body = {
                    cm13_id: id,
                    company_id: event.company_id,
                    department_prefix: event.department_prefix,
                    floor_flg: event.floor_flg,
                }
                break;
            case "floor_id":
                body = {
                    floor_id: id,
                }
                break;
            case "cm12_id":
                body = {
                    cm12_id: id,
                }
                break;
            default:
                break;
        }

        try {
            const result = await this.ascAxios("post", `${this.reactContainerPath}/getUser`, body);

            this.setState({
                userSelect: result.data
            });
        } catch (err) {
            this.showErrorObjectMesssage(err)
            console.error(err);
        }
    }

    getDepartment = async (event) => {
        const floor_flg = event && event.floor_flg === "Y" ? true : false;

        try {
            const res = await this.ascAxios("post", `Common/departmentSelect`, {
                container: this.reactContainerPath.split("/")[1],
                company_id: event ? event.value : this.props.userInfo.company_id,
                floor_flg: event ? floor_flg : this.props.userInfo.floor_flg
            });
            
            this.setState({departmentSelect: res.data});
        } catch (err) {
            this.showErrorObjectMesssage(err);
            console.error(err);
        }
    }

    getFloor = async (cm12_id, event) => {
        try {
            const res = await this.ascAxios("post", `Common/floorSelect`, {
                company_id: cm12_id ? cm12_id : this.props.userInfo.company_id,
                department_prefix: event ? event.department_prefix : this.props.userInfo.department_prefix
            });
            
            this.setState({floorSelect: res.data});
        } catch (err) {
            this.showErrorObjectMesssage(err);
            console.error(err);
        }
    }

    getFilterData = (param) => {
        let my_param_data = this.state.filtered.find(row => row.id === param);
        return my_param_data ? my_param_data.value : "";
    }

    onChange = (event, param) => {
        let my_filtered = this.state.filtered,
            target_index = my_filtered.findIndex(row => row.id === param),
            value = "";
        // セレクトボックス用。event無い場合はvalue空のまま
        if(event)
        {
            value = event.target ? event.target.value : event;
        }
        if (target_index !== -1) {
            my_filtered.splice(target_index, 1);
        }

        // 変更する項目に関連があるものクリア
        switch (param) {
            case "cm12_id":
                target_index = my_filtered.findIndex(row => row.id === "cm13_id");

                if (target_index !== -1) my_filtered.splice(target_index, 1);
            case "cm13_id":
                target_index = my_filtered.findIndex(row => row.id === "floor_id");
                
                if (target_index !== -1) my_filtered.splice(target_index, 1);
            case "floor_id":
                target_index = my_filtered.findIndex(row => row.id === "cm15_id");

                if (target_index !== -1) my_filtered.splice(target_index, 1); 
                break;
            default:
                break;
        }

        if (value && (!Array.isArray(value) || value.length)) {
            my_filtered.push({
                id: param,
                value
            });
        }

        // 会社選択
        if (param === "cm12_id" && event && event.value) {
            this.getUser(event.value, event, param);
            this.getDepartment(event);
            this.setState({
                floor_flg: event.floor_flg === "Y" ? true : false
            });
        } else if (param === "cm12_id") {
            // クリアした時
            this.setState({
                departmentSelect: [],
                floorSelect: [],
                userSelect: [],
                floor_flg: true
            });
        }

        if (param === "cm13_id" && event && event.value) {
            let floor_flg = my_filtered.find(row => row.id === "cm12_id") 
                ? my_filtered.find(row => row.id === "cm12_id").value.floor_flg
                : this.props.userInfo.floor_flg;
            event.floor_flg = floor_flg;
            
            if (event.floor_flg && event.floor_flg !== "N") {
                this.getFloor(event.company_id, event);
            }

            this.getUser(event.value, event, param)
        } else if (param === "cm13_id") {
            // クリアした時
            // 作成者リスト変更
            const reset = "cm12_id";
            let cm12_id = my_filtered.find(row => row.id === "cm12_id") 
                ? my_filtered.find(row => row.id === "cm12_id").value.value
                : this.props.userInfo.company_id;
            
            this.getUser(cm12_id, event, reset);
            this.setState({
                floorSelect: [],
            });
        }

        if (param === "floor_id" && event && event.value) {
            this.getUser(event.value, event, param);
        } else if (param === "floor_id") {
            // クリアした時
            // 作成者リスト変更
            let cm13 = my_filtered.find(row => row.id === "cm13_id") 
                ? my_filtered.find(row => row.id === "cm13_id")
                : {
                    id: "cm13_id",
                    value: {
                        company_id: this.props.userInfo.company_id,
                        department_prefix: this.props.userInfo.department_prefix,
                        floor_flg: this.props.userInfo.floor_flg,
                        value: this.props.userInfo.department_id
                    }
                };
            
            this.getUser(cm13.value.value, cm13.value, cm13.id);
        }

        let returnValue = [];
        returnValue = my_filtered;

        this.setState({
            filtered: returnValue
        });
    }

    onSearch = () => {
        try {
            let filter =[];

            // 検索時のデータ保存
            this.state.filtered.forEach(row => {
                filter.push(row);
            })

            this.setState({ 
                hidden_filtered: filter
            }, () => {
                // 検索時にpageを1ページする
                this.table.current.state.page = 0;
                this.table.current.state.hidden_filtered = filter;
                this.fetchData(this.table.current.state, this.table.current.instance);
            });
        } catch (err) {
            return this.showErrorObjectMesssage(err, "DataSelectError");
        }
    }

    modalShow = (modalType, param) => async event => {
        event.preventDefault();
        let setData = {
            downloadHistoryId: param && param.id
                ? param.id
                : "",
            userId: param && param.cm15_id 
                ? param.cm15_id
                : "",
            userInfo: param && param.cm15_users 
                ? param.cm15_users
                : "",
            condition: param && param.condition 
                ? param.condition
                : "",
            conditionDisplay: param && param.condition_display 
                ? param.condition_display
                : "",
            downloadType: param && param.download_type
                ? param.download_type
                : "",
            downloadCount: param && param.download_count 
                ? param.download_count
                : "",
            downloadExpire: param && param.download_expire 
                ? param.download_expire
                : "",
            downloadHistory: param && param.download_history 
                ? param.download_history
                : "",
            downloadUrl: param && param.download_url 
                ? param.download_url
                : "",
            executionStartDatetime: param && param.execution_start_datetime 
                ? param.execution_start_datetime
                : "",
            executionEndDatetime: param && param.execution_end_datetime 
                ? param.execution_end_datetime
                : "",
            fileName: param && param.file_name 
                ? param.file_name
                : "",
            filePath: param && param.file_path 
                ? param.file_path
                : "",
            notificationId: param && param.nt01_id 
                ? param.nt01_id
                : "",
            status: param && param.status 
                ? param.status
                : "",
            errorMessage: param && param.error_message
                ? param.error_message
                : "",
            show: true,
            modalType
        }

        switch (modalType) {
            case "downloadHistoryDetail":
                // ダウンロードしたユーザーの情報取得
                let userIds = [];
                let downloadUserInfos = [];
                if (setData.downloadHistory && setData.downloadHistory.length > 0) {
                    setData.downloadHistory.forEach(row => {
                        if (!(userIds.some(id => id === row.download_user_id))) userIds.push(row.download_user_id)
                    });
                } 
                if (userIds.length > 0) downloadUserInfos = await this.getDownloadHistoryModalInfo(userIds);
                else setData.downloadHistory = [];

                downloadUserInfos.forEach(row => {
                    setData.downloadHistory.forEach((history, idx) => {
                        if (history.download_user_id === row.id) {
                            setData.downloadHistory[idx] = {
                                ...history,
                                name: row.name
                            }
                        }
                    });
                });
                setData.downloadHistory.sort((a, b) => moment(b.download_datetime) - moment(a.download_datetime));
                
                break;
            case "redo":
                break;
            case "errorContent":
                break;
            default:
                break;
        }
        
        this.setState(setData);
    }

    getDownloadHistoryModalInfo = async (userIds) => {
        try {
            const result = await this.ascAxios("post", `${this.reactContainerPath}/getDownloadHistoryModalInfo`, {
                user_ids: userIds
            });

            return result.data;
        } catch (err) {
            this.showErrorObjectMesssage(err);
            console.error(err);
        }
    }

    onClickHandle = async () => {
        let {
            condition,
            conditionDisplay,
            fileName,
            downloadType
        } = this.state;

        switch (this.state.modalType) {
            case "redo":
                let create_datetime = moment().tz("Asia/Tokyo").format("YYYYMMDDHHmmss");

                try {
                    this.setState({ show: false });
                    this.blockUI();
                    
                    await this.ascAxios("post", `${this.reactContainerPath}/startDownload`, {
                        company_id: this.props.userInfo.company_id,
                        department_id: this.props.userInfo.department_id,
                        user_id: this.props.userInfo.cm15_id,
                        download_type: downloadType,
                        file_name: fileName ? fileName : this.props.userInfo.user_name_sei + this.props.userInfo.user_name_mei + create_datetime,
                        condition: condition,
                        condition_display: conditionDisplay
                    });

                    alert(this.props.langText.Message.CallContentDownloadStartMessage);
                    this.onSearch();
                } catch (err) {
                    console.error(err);
                    return this.showErrorObjectMesssage(err, "CreateFileFailed");
                }

                break;

            case "errorContent":
                navigator.clipboard.writeText(this.state.errorMessage).then(() => {
                    alert(this.props.langText.Message.ErrorTextCopied);
                }).catch(err => {
                    console.error(err);
                });
                this.setState({ show: false });
                break;
            default:
                break;
        }
    }

    validationHandle = (param) => {
        let nowState = null;
        
        switch(param) {
            case "redo":
                nowState = true;
                break;
            case "errorContent":
                nowState = true;
                break;
            default:
                break;
        }
        return nowState;
    }

    onSelectChange = (event, param) => {
        switch (param) {
            default:
                this.setState({[param]: event});
                break;
        }
    }

    modalTableHandle = (state) => {
        // モーダルテーブル調整
        let {
            page,
            pageSize,
        } = state;
        let downloadHistory = this.state.downloadHistory;
        let limit = pageSize;
        let offset = page * pageSize;
        let pages = 1;
        let returnData = [];

        // テーブルデータ加工
        for (let i = offset; i < offset + limit; i++) {
            if (i >= downloadHistory.length) break;

            returnData.push(downloadHistory[i]);
        }

        // テーブル総ページ加工
        if (downloadHistory.length > 0) pages = Math.ceil(downloadHistory.length / pageSize);

        this.setState({
            modalTablePages: pages,
            modalTableData: returnData
        });
    }

    downloadFile = async (param) => {
        try {
            if (this.isFutureDate(param.download_expire)) {
                const link = document.createElement("a");
                link.href = param.download_url;
                // link.target = "_blank"
                document.body.appendChild(link);
                link.click();
                link.remove();

                let downloadArr = param.download_history ? param.download_history : [];
                downloadArr.push({
                    download_user_id: this.props.userInfo.cm15_id,
                    download_datetime: moment().tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm:ss")
                });
                await this.ascAxios("post", `${this.reactContainerPath}/insertDownloadUser`, {
                    id: param.id,
                    download_count: param.download_count ? param.download_count + 1 : 1,
                    download_history: downloadArr
                });
                this.onSearch();
            } else {
                throw new Error("DataSelectError")
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
            console.error(err);
        }
    }

    render() {
        return(
            <BlockUi tag="div" blocking={this.state.blocking} message={this.props.langText.Body.Wait} keepInView>
                <div className="DownloadHistory">
                    <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.DownloadHistory }]} />
                    {
                        this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) &&
                        <Row>
                            {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                                ?
                                <Col xs={12} md={12}>
                                    <Col sm={4} md={2} lg={1}>
                                        <div className="margin-top-05">{this.props.langText.Body.CompanyName}</div>
                                    </Col>
                                    <Col sm={12} md={12} lg={3}>
                                        <Select
                                            value={this.getFilterData("cm12_id")}
                                            onChange={
                                                e => this.onChange(e, "cm12_id")
                                            }
                                            isClearable={true}
                                            options={this.state.companySelect}
                                            placeholder={this.props.langText.SelectOption.Placeholder}/>
                                    </Col>
                                </Col>
                                :
                                null
                            }
                            <Col xs={12} md={12} className="margin-top-03 flex-style">
                                {
                                    this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) &&
                                    <>
                                        <Col sm={4} md={2} lg={1}>
                                            <div className="margin-top-05">{this.props.langText.Body.DepartmentName}</div>
                                        </Col>
                                        <Col sm={12} md={4} lg={2} className="padding-right-3">
                                            <Select
                                                value={this.getFilterData("cm13_id")}
                                                onChange={
                                                    e => this.onChange(e, "cm13_id")
                                                }
                                                isClearable={true}
                                                options={this.state.departmentSelect}
                                                placeholder={this.props.langText.SelectOption.Placeholder}/>
                                        </Col>
                                    </>
                                }
                                {
                                    this.state.floor_flg &&
                                    this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) &&
                                    <>
                                        <Col sm={4} md={2} lg={1}>
                                            <div className="margin-top-05">{this.props.langText.Body.FloorName}</div>
                                        </Col>
                                        <Col sm={12} md={4} lg={2}>
                                            <Select
                                                value={this.getFilterData("floor_id")}
                                                onChange={
                                                    e => this.onChange(e, "floor_id")
                                                }
                                                isClearable={true}
                                                options={this.state.floorSelect}
                                                placeholder={this.props.langText.SelectOption.Placeholder}/>
                                        </Col>
                                    </>
                                }
                                <Col sm={4} md={2} lg={1}>
                                    <div className="margin-top-05">{this.props.langText.Body.Creator}</div>
                                </Col>
                                <Col sm={4} md={2} lg={2}>
                                    <Select
                                        value={this.getFilterData("cm15_id")}
                                        onChange={
                                            e => this.onChange(e, "cm15_id")
                                        }
                                        isClearable={true}
                                        options={this.state.userSelect}
                                        placeholder={this.props.langText.SelectOption.Placeholder}/>
                                </Col>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05 flex-style">
                            <Col sm={1} md={1} lg={1} className="margin-top-01">
                                <Button
                                    id="customer-insert"
                                    className="searchBtn"
                                    bsStyle='primary'
                                    bsSize='sm'
                                    onClick={() => this.onSearch()}
                                    disabled={this.state.loading}
                                >
                                    {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) ? this.props.langText.Body.Search : (<Glyphicon glyph="refresh"/>)}
                                </Button>
                            </Col>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12} className="margin-top-05">
                            <CommonTable
                                tableState={this.state}
                                propSetState={this.propSetState}
                                talbeRef={this.table}
                                style={{height: this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem) ? this.props.tableHeight-110 : this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) ? this.props.tableHeight-70 : this.props.tableHeight-30}}
                                manual="manual"
                                columns={this.getColumnsData()}
                                data={this.state.data}
                                pages={this.state.pages}
                                loading={this.state.loading}
                                defaultSorted={[{id: "execution_start_datetime", desc: true}]}
                                onFetchData={(state, instance) => {
                                    this.fetchData(state, instance);
                                }}
                                filtered={this.state.filtered}
                                hidden_filtered={this.state.hidden_filtered}
                                previousText={this.props.langText.Table.PreviousText}
                                nextText={this.props.langText.Table.NextText}
                                loadingText={this.props.langText.Table.LoadingText}
                                noDataText={this.props.langText.Table.NoDataText}
                                rowsText={this.props.langText.Table.RowsText}/>
                        </Col>
                    </Row>
                    <DownloadHistoryModal
                        userInfo={this.props.userInfo}
                        state={this.state}
                        table={this.detailTable}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        charaLimit={this.props.charaLimit}
                        onClick={this.onClickHandle}
                        validationHandle={this.validationHandle}
                        currentPermission={this.props.currentPermission}
                        onTextChange_Limit={this.onTextChange_Limit}
                        tableHeight={this.props.tableHeight}
                        modalTableHandle={this.modalTableHandle}
                    />
                </div>
            </BlockUi>
        );
    }
}
