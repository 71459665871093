import React, { ReactDOM } from "react";
import { Col, FormControl } from "react-bootstrap";
import InlineForm from "../Elements/FromGroup/InlineForm";
import AscComponent from "../../components/AscComponent";
import * as GlobalConst from "../../components/AscConstants";
import { gql, useSubscription } from "@apollo/client";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import Appsync from "../../Omni-Cuscon-AppSync";
import moment from "moment";
import _, { isNull } from "lodash";

export default class ExternalMonitor extends AscComponent {
    constructor(props) {
        super(props);
        this.state = {
            company_id: this.props.company_id,
            department_id: this.props.department_id,
            externalList: [],
            externalIdList: [],
            render_item: {},
            defaultExternalTableLength: 5,
            currentExternalTableLength: 5,
            external_interval_time: 5,
            giveup_interval_time: this.props.giveup_interval_time,
            giveUpIntervalList: [
                { value: 30, label: "30分" },
                { value: 60, label: "1時間" }
            ],
            isExternalSearchMode: this.props.isExternalSearchMode,
            external_id_list: this.props.external_id_list,
            external_error_count: 0
        };
    }

    componentDidMount() {
        if (this.state.company_id) {
            this.getExternalDataByDepartmentId({
                department_id: this.props.department_id
            });
            let ExternalInterval = setInterval(
                this.getExternalDataByDepartmentId,
                this.state.external_interval_time * 1000,
                {
                    department_id: this.props.department_id
                }
            );
            this.setState({
                ExternalInterval: ExternalInterval
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // 会社・拠点・フロアで検索する場合
        if (
            (prevProps.isExternalSearchMode !== this.props.isExternalSearchMode ||
                prevProps.department_id !== this.props.department_id ||
                prevState.external_interval_time !== this.state.external_interval_time ||
                prevState.giveup_interval_time !== this.state.giveup_interval_time) &&
            !this.props.isExternalSearchMode
        ) {
            clearInterval(this.state.ExternalInterval);
            this.getExternalDataByDepartmentId({
                department_id: this.props.department_id
            });
            let ExternalInterval = setInterval(
                this.getExternalDataByDepartmentId,
                this.state.external_interval_time * 1000,
                {
                    department_id: this.props.department_id
                }
            );
            this.setState({
                company_id: this.props.company_id,
                department_id: this.props.department_id,
                ExternalInterval: ExternalInterval,
                externalList: [],
                render_item: {}
            });
        }

        // 外線番号で検索する場合
        if (
            (prevProps.external_id_list !== this.props.external_id_list ||
                prevState.external_interval_time !== this.state.external_interval_time ||
                prevProps.isExternalSearchMode !== this.props.isExternalSearchMode ||
                prevState.giveup_interval_time !== this.state.giveup_interval_time) &&
            this.props.isExternalSearchMode
        ) {
            clearInterval(this.state.ExternalInterval);
            this.getExternalDataByExternalId({ external_id: this.props.external_id_list });
            let ExternalInterval = setInterval(
                this.getExternalDataByExternalId,
                this.state.external_interval_time * 1000,
                {
                    external_id: this.props.external_id_list
                }
            );
            this.setState({
                company_id: this.props.company_id,
                department_id: this.props.department_id,
                ExternalInterval: ExternalInterval,
                externalList: [],
                render_item: {}
            });
        }

        if (prevProps.giveup_interval_time !== this.props.giveup_interval_time) {
            this.setState({
                giveup_interval_time: this.props.giveup_interval_time
            });
        }

        if (prevProps.company_id !== this.props.company_id) {
            this.setState({
                render_item: []
            });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.ExternalInterval);
    }

    // 拠点IDでデータ取得
    getExternalDataByDepartmentId = ({ department_id }) => {
        this.ascAxios("post", "CallMonitor/getExternalDataByDepartmentId", {
            department_id: department_id,
            time_range: this.state.giveup_interval_time
        })
            .then((res) => {
                const result = {};
                const temp = res.data.externalData;
                let acd_department_id = [];
                let acd_external_id = [];
                temp.forEach((row) => {
                    result[row.display_number] = row;
                    result[row.display_number].incoming_count = row.am10_call_statuses.filter(
                        (callStatusRow) => callStatusRow.answered_extension
                    ).length;
                    result[row.display_number].call_give_up_count = row.ct67_call_drops.length;
                    result[row.display_number].wait_count = row.am11_wait_satuses.length;
                    if (row.am11_wait_satuses) {
                        result[row.display_number].wait_count_time = row.am11_wait_satuses[0];
                    }
                    if (row.ct21_assign_task_external_numbers) {
                        result[row.display_number].task = [];
                        row.ct21_assign_task_external_numbers.forEach((taskRow) => {
                            result[row.display_number].task.push(taskRow.cm21_task.business_name);
                        });
                    }
                    row.ct62_assgin_external_department_infos.forEach((ct62_row) => {
                        acd_department_id.push(ct62_row.cm13_id);
                    });
                    acd_external_id.push(row.id);
                });
                this.props.setACDExternalListByDepartmentSelect({
                    acd_external_id: acd_external_id
                });
                this.setState({
                    render_item: result
                });
            })
            .catch((err) => {
                this.externalErrorAlert({
                    error_count: this.state.external_error_count,
                    error_message: err
                });
            });
    };

    // 外線番号のIDでデータ取得
    getExternalDataByExternalId = ({ external_id }) => {
        this.ascAxios("post", `CallMonitor/getExternalDataByExternalId`, {
            external_id: external_id,
            time_range: this.state.giveup_interval_time
        })
            .then((res) => {
                const result = {};
                const temp = res.data.externalData;
                let acd_department_id = [];
                temp.forEach((row) => {
                    result[row.display_number] = row;
                    result[row.display_number].incoming_count = row.am10_call_statuses.filter(
                        (callStatusRow) => callStatusRow.answered_extension
                    ).length;
                    result[row.display_number].call_give_up_count = row.ct67_call_drops.length;
                    result[row.display_number].wait_count = row.am11_wait_satuses.length;
                    if (row.am11_wait_satuses) {
                        result[row.display_number].wait_count_time = row.am11_wait_satuses[0];
                    }
                    if (row.ct21_assign_task_external_numbers) {
                        result[row.display_number].task = [];
                        row.ct21_assign_task_external_numbers.forEach((taskRow) => {
                            result[row.display_number].task.push(taskRow.cm21_task.business_name);
                        });
                    }
                    row.ct62_assgin_external_department_infos.forEach((ct62_row) => {
                        acd_department_id.push(ct62_row.cm13_id);
                    });
                });
                this.props.setDepartmentListACD({ department_id: acd_department_id });
                this.setState({
                    render_item: result
                });
            })
            .catch((err) => {
                this.externalErrorAlert({
                    error_count: this.state.external_error_count,
                    error_message: err
                });
            });
    };

    externalErrorAlert = ({ error_count, error_message }) => {
        if (error_count > 3) {
            alert(this.getErrorString(error_message));
            this.setState({ external_error_count: 0 });
        } else {
            this.setState({
                external_error_count: error_count + 1
            });
        }
    };

    getColumnsData(param) {
        let itemArr = [];

        switch (param) {
            case "external":
                itemArr.push({
                    Header: this.props.langText.Body.ExternalNumber,
                    accessor: "display_number",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "display_number")}
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData = externalList.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ExternalMemo,
                    accessor: "memo",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "memo")}
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData = externalList.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.BusinessMemo,
                    accessor: "task",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "task")}
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData = [];
                        let externalTaskData = externalList.value;
                        if (externalTaskData) {
                            externalTaskData.forEach((row, key) => {
                                rowData.push(<p key={key}>{row}</p>);
                            });
                        }
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.MaxWaitCount,
                    accessor: "wait_number",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "wait_number")}
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData = externalList.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.WaitCount,
                    accessor: "wait_count",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "wait_count")}
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData = externalList.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.GiveUp,
                    accessor: "call_give_up_count",
                    Filter: () => (
                        <FormControl
                            onChange={(e) =>
                                this.onFilterChange(e.target.value, "call_give_up_count")
                            }
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData = externalList.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.MaxWaitTime,
                    accessor: "wait_count_time ",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "wait_count_time")}
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData;
                        if (externalList.original.wait_count_time) {
                            const currentDate = moment(new Date());
                            const updateDate = moment(
                                new Date(externalList.original.wait_count_time.created)
                            );
                            const time = currentDate - updateDate;
                            rowData = moment(time).utc().format("mm:ss");
                        } else {
                            rowData = "00:00";
                        }
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.AvgGiveUpTime,
                    accessor: "give_up_avg",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "give_up_avg")}
                        />
                    ),
                    Cell: (externalList) => {
                        let total_time = 0;
                        // 通話開始時間と終了時間がnullのデータはは計算しない
                        let temp = externalList.original.ct67_call_drops.filter(
                            (row) => row.start_datetime && row.end_datetime
                        );
                        if (temp.length) {
                            temp.forEach((row) => {
                                let start_time = moment(new Date(row.start_datetime));
                                let end_time = moment(new Date(row.end_datetime));
                                total_time += end_time - start_time;
                            });
                            total_time = total_time / temp.length;
                        }
                        let rowData = moment(total_time).utc().format("mm:ss");

                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ACDCall,
                    accessor: "incoming_count",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "incoming_count")}
                        />
                    ),
                    Cell: (externalList) => {
                        let rowData = externalList.value;
                        return <div>{rowData}</div>;
                    }
                });
                break;
        }
        return itemArr;
    }

    render() {
        return (
            <Col>
                <div>
                    <Col xs={12} md={12}>
                        <Col xs={10} sm={10} md={11} lg={11}>
                            <h3>{this.props.langText.Body.ExternalNumberInfo}</h3>
                        </Col>
                    </Col>
                    <Col xs={3} md={6} lg={12} className="margin-top-03 callmonitor-table-col">
                        <CommonTable
                            columns={this.getColumnsData("external")}
                            data={Object.values(this.state.render_item)}
                            style={{ height: 210, zIndex: 1 }}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                            showPagination={false}
                            pageSize={
                                this.state.currentExternalTableLength >
                                this.state.defaultExternalTableLength
                                    ? this.state.currentExternalTableLength
                                    : this.state.defaultExternalTableLength
                            }
                            minRows={5}
                        />
                    </Col>
                </div>
            </Col>
        );
    }
}
