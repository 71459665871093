import React from "react";
import AscComponent from '../../components/AscComponent';
import {Panel, Col} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css";
import DatePicker from "react-datepicker";
import Select from "react-select";
import CallAnalysisTemplateCheckModal from "./CallAnalysisTemplateCheckModal";


export default class CallAnalysisTemplateModal extends AscComponent {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        }
    }

    componentDidMount() {
        this.setState({ modalType: this.props.state.modalType })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.state.modalType !== this.props.state.modalType) {
            this.setState({ modalType: this.props.state.modalType })
        }
    }

    getTemplateInfoItem = () => {
        let {
            state,
            langText,
            onTextChange_Limit,
            validationHandle,
            onSelectChange,
            TemplatePeriodOption,
            charaLimit
        } = this.props;

        let itemArr = [];

        // テンプレート名
        itemArr.push(
            <InlineForm
                key="templateName"
                controlId="templateName"
                label={langText.Body.TemplateName}
                type="text"
                value={state.templateName}
                onChange={e => onTextChange_Limit(e, "templateName", charaLimit.Template_Name)}
                validationState={validationHandle("templateName")}/>
        );

        // 期間指定
        itemArr.push(
            <InlineForm
                key="periodSetting"
                controlId="periodSetting"
                label={langText.Body.PeriodSettring}
                validationState={validationHandle("periodSetting")}
            >
                <Select
                    value={state.periodSetting}
                    onChange={e => onSelectChange(e, "periodSetting")}
                    options={TemplatePeriodOption}
                />
            </InlineForm>
        );

        return itemArr;
    }

    getTemplateDateItem = () => {
        let {
            state,
            langText,
            getFilterData,
            validationHandle,
            hourShowList,
            minuteShowList,
            onSelectChange
        } = this.props;

        let itemArr = [];

        // 時間
        itemArr.push(
            <InlineForm
                key="date"
                controlId="date"
                validationState={validationHandle("date")}
            >
                <Col className="padding-left-0" xs={12} md={12}>
                    <Col className="padding-left-0" sm={12} md={4} lg={2}>
                        <DatePicker
                            popperPlacement="top"
                            onChange={e => onSelectChange(e, "start_datetime")}
                            selected={state.start_datetime ? state.start_datetime : null}
                            dateFormat="YYYY/MM/DD"
                            className="form-control"
                            todayButton={langText.Body.Today}
                            placeholderText={langText.Body.StartDate}
                            maxDate={getFilterData("end_datetime") ? getFilterData("end_datetime") : null}
                            disabled={state.periodSetting.value !== "fixed"}
                        />
                    </Col>
                    <Col sm={4} md={2} lg={2}>
                            <Select
                                key="start_ji"
                                value={state.start_ji}
                                onChange={
                                    e => onSelectChange(e, "start_ji")
                                }
                                options={hourShowList}
                                isDisabled={state.periodSetting.value !== "fixed" || (!state.filtered.some(row => row.id === "start_datetime"))}
                                placeholder="---"
                            />
                    </Col>
                    <Col sm={4} md={2} lg={2}>
                        <Select
                            key="start_hun"
                            value={state.start_hun}
                            onChange={
                                e => onSelectChange(e, "start_hun")
                            }
                            options={minuteShowList}
                            isDisabled={state.periodSetting.value !== "fixed" || (!state.filtered.some(row => row.id === "start_datetime"))}
                            placeholder="---"
                        />
                    </Col>
                    <Col className="padding-left-0" sm={12} md={4} lg={2}>
                        <DatePicker
                            popperPlacement="top"
                            onChange={e => onSelectChange(e, "end_datetime")}
                            selected={state.end_datetime ? state.end_datetime : null}
                            dateFormat="YYYY/MM/DD"
                            className="form-control"
                            todayButton={langText.Body.Today}
                            placeholderText={langText.Body.EndDate}
                            minDate={state.start_datetime ? state.start_datetime : null}
                            disabled={state.periodSetting.value !== "fixed"}
                        />
                    </Col>
                    <Col sm={4} md={2} lg={2}>
                            <Select
                                key="end_ji"
                                value={state.end_ji}
                                onChange={
                                    e => onSelectChange(e, "end_ji")
                                }
                                options={hourShowList}
                                isDisabled={state.periodSetting.value !== "fixed" || (!state.filtered.some(row => row.id === "end_datetime"))}
                                placeholder="---"
                            />
                    </Col>
                    <Col sm={4} md={2} lg={2}>
                        <Select
                            key="end_hun"
                            value={state.end_hun}
                            onChange={
                                e => onSelectChange(e, "end_hun")
                            }
                            options={minuteShowList}
                            isDisabled={state.periodSetting.value !== "fixed" || (!state.filtered.some(row => row.id === "end_datetime"))}
                            placeholder="---"
                        />
                    </Col>
                </Col>
            </InlineForm>
        )

        return itemArr;
    }

    onClickHandle = () => {
        this.props.onClick(this.state.modalType);
        this.setState({
            show: false
        });
    }

    render() {
        return (
            <div className="CallAnalysisTemplateModal">
                <AscModal
                    state={this.props.state}
                    propSetState={this.props.propSetState}
                    langText={this.props.langText}
                    validationHandle={this.props.validationHandle}
                    customMsg={this.props.langText.Body.Check}
                    customFunc={() => {
                        this.setState({
                            show: true
                        });
                    }}    
                >
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>
                                {this.props.getTitle()}
                            </Panel.Title>
                        </Panel.Heading>
                        <Panel.Body>
                            {this.getTemplateInfoItem()}
                            {
                                this.props.state.periodSetting.value === "fixed" &&
                                this.getTemplateDateItem()
                            }
                        </Panel.Body>
                    </Panel>
                </AscModal>
                <CallAnalysisTemplateCheckModal
                    userInfo={this.props.userInfo}
                    state={this.state}
                    propState={this.props.state}
                    propSetState={this.propSetState}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                    onClick={this.onClickHandle}
                    getFilterData={this.props.getFilterData}
                    currentPermission={this.props.currentPermission}
                    validationHandle={this.props.validationHandle}
                    onChange={this.props.onChange}
                    TemplatePeriodOption={this.props.TemplatePeriodOption}
                    getScopeGreaterEqual={this.props.getScopeGreaterEqual}
                    reactContainerPath={this.props.reactContainerPath}
                    getTitle={this.props.getTitle}
                />
            </div>
        );
    }
}
