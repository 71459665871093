import React, {Component} from "react";
import {Panel, Button, Glyphicon, Checkbox} from "react-bootstrap";
import Select from "react-select"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";

export default class PbxModal extends Component {
    getPbxInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onIntChange,
            onKeyDownIntCheck,
            validationHandle,
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "pbxName"
                    controlId = "pbxName"
                    label = {langText.Body.PbxName}
                    type = "text"
                    value = {state.pbxName}
                    onChange = {e => onTextChange_Limit(e, "pbxName",charaLimit.PBX_pbx_name)}
                    validationState = {validationHandle("pbxName")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "pbxName"
                    controlId = "pbxName"
                    label = {langText.Body.PbxName}>
                    {state.pbxName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "hostName"
                    controlId = "hostName"
                    label = {langText.Body.HostName}
                    type = "text"
                    value = {state.hostName}
                    onChange = {e =>onTextChange_Limit(e, "hostName",charaLimit.PBX_hostName)}
                    validationState = {validationHandle("hostName")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "hostName"
                    controlId = "hostName"
                    label = {langText.Body.HostName}>
                    {state.hostName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "publicIpAddress"
                    controlId = "publicIpAddress"
                    label = {langText.Body.PublicIP}
                    type = "text"
                    value = {state.publicIpAddress}
                    onChange = {e =>onTextChange_Limit(e, "publicIpAddress",charaLimit.PBX_publicIpAddress)}
                    validationState = {validationHandle("publicIpAddress")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "publicIpAddress"
                    controlId = "publicIpAddress"
                    label = {langText.Body.PublicIP}>
                    {state.publicIpAddress}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "privateIpAddress"
                    controlId = "privateIpAddress"
                    label = {langText.Body.PrivateIP}
                    type = "text"
                    value = {state.privateIpAddress}
                    onChange = {e =>onTextChange_Limit(e, "privateIpAddress",charaLimit.PBX_privateIpAddress)}
                    validationState = {validationHandle("privateIpAddress")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "privateIpAddress"
                    controlId = "privateIpAddress"
                    label = {langText.Body.PrivateIP}>
                    {state.privateIpAddress}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "wssPort"
                    controlId = "wssPort"
                    label = {langText.Body.WssPort}
                    type = "text"
                    value = {state.wssPort}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("wssPort",charaLimit.PBX_wssPort)}
                    validationState = {validationHandle("wssPort")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "wssPort"
                    controlId = "wssPort"
                    label = {langText.Body.WssPort}>
                    {state.wssPort}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "bindPort"
                    controlId = "bindPort"
                    label = {langText.Body.BindPort}
                    type = "text"
                    value = {state.bindPort}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("bindPort",charaLimit.PBX_bindPort)}
                    validationState = {validationHandle("bindPort")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "bindPort"
                    controlId = "bindPort"
                    label = {langText.Body.BindPort}>
                    {state.bindPort}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "ftpPort"
                    controlId = "ftpPort"
                    label = {langText.Body.FtpPort}
                    type = "text"
                    value = {state.ftpPort}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("ftpPort",charaLimit.PBX_ftpPort)}
                    validationState = {validationHandle("ftpPort")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "ftpPort"
                    controlId = "ftpPort"
                    label = {langText.Body.FtpPort}>
                    {state.ftpPort}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}
                    componentClass = "textarea"
                    value = {state.memo}
                    onChange = {e =>onTextChange_Limit(e, "memo",charaLimit.PBX_memo)}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = 'memo'
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    onClickPlusHandle() {
        let {
            state,
            propSetState,
            getGwSelect
        } = this.props;

        state["gwInfos"].push({});
        propSetState({gwInfos: state["gwInfos"]});
        getGwSelect(state["gwInfos"]);
    }

    onClickMinusHandle(index) {
        let {
            state,
            propSetState,
            getGwSelect
        } = this.props;

        state["gwInfos"].splice(index, 1);
        propSetState({gwInfos: state["gwInfos"]});
        getGwSelect(state["gwInfos"]);
    }

    getGwInfoItem() {
        let {
            state,
            onGwChange,
            validationHandle,
            langText
        } = this.props;

        let itemArr = [];

        itemArr.push(
            <InlineForm.static
                key = "pbxName"
                controlId = "pbxName"
                label = {langText.Body.PbxName}>
                {state.pbxName}
            </InlineForm.static>
        );

        state.gwInfos.forEach((row, index) => {
            itemArr.push(
                <div key={index}>
                    <Button
                        className="multiValueFormMinus"
                        onClick={e => this.onClickMinusHandle(index)}>
                        <Glyphicon glyph="minus"/>
                    </Button>

                    <InlineForm
                        key = "gateway_id"
                        controlId = "gateway_id"
                        label = {langText.Body.GatewayName}
                        type = "select"
                        value = {row.gateway_id}
                        onChange = {e => onGwChange(e, "gateway_id", index)}
                        options = {state.gatewaySelectArr[index] || state.gatewaySelect}
                        placeholder = {langText.SelectOption.Placeholder}
                        validationState = {validationHandle("gateway_id", index)}/>

                    <InlineForm
                        key = "register_id"
                        controlId = "register_id"
                        label = {langText.Body.GatewayRegisterId}
                        type = "text"
                        value = {row.register_id || ""}
                        onChange={e => onGwChange(e, "register_id", index)}
                        validationState = {validationHandle("register_id", index)}/>

                    <InlineForm
                        key = "register_pw"
                        controlId = "register_pw"
                        label = {langText.Body.GatewayRegisterPW}
                        type = "text"
                        value = {row.register_pw || ""}
                        onChange={e => onGwChange(e, "register_pw", index)}
                        validationState = {validationHandle("register_pw", index)}/>
                </div>
            );
        });

        return <div>
            {itemArr}

            <Button
                className="multiValueFormPlus"
                onClick={e => this.onClickPlusHandle()}>
                <Glyphicon glyph="plus"/>
            </Button>
        </div>;
    }

    createOemForm = () => {
        let {
            state,
            langText,
            onSelectChange,
            validationHandle,
            onCheckBoxChange_Init
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm key="oemCheckBox">
                    <Checkbox
                        className="oemFlag"
                        checked={state.oemFlag}
                        onChange={(e) => onCheckBoxChange_Init(e, "oemFlag", "oemId")}
                        >
                        {langText.Body.OemUsed}
                    </Checkbox>
                </InlineForm>
            );
            if (state.oemFlag) {
                itemArr.push(
                    <InlineForm
                        key="oemSelect"
                        controlId="oemSelect"
                        label={langText.Body.CompanyName}
                        type="select"
                        value={state.oemId}
                        onChange={(e) => onSelectChange(e, "oemId")}
                        options={state.oemSelectList}
                        validationState={validationHandle("oemSelect")}
                        placeholder={langText.SelectOption.Placeholder}
                    />
                );
            }
        } else if (state.modalType === "delete" || state.modalType === "read") {
            if (state.oemFlag) {
                const oemName = state.oemSelectList.filter((oem) => oem.value === state.oemId);
                itemArr.push(
                    <InlineForm.static
                        key="oemSelect"
                        controlId="oemSelect"
                        label={langText.Body.CompanyName}
                    >
                        {oemName[0].label}
                    </InlineForm.static>
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key="oem_unset"
                        controlId="oem_unset"
                    >
                        {langText.SelectOption.Unset}
                    </InlineForm.static>
                );
            }
        }

        return itemArr;
    }

    createMonitoringForm = () => {
        let {
            state,
            langText,
            onCheckBoxChange_Init,
            charaLimit,
            onTextChange_Limit,
            validationHandle,
            onSelectChange
        } = this.props;
        let itemArr = [];

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm key="monitoringCheckBox">
                    <Checkbox
                        className="monitoringFlag"
                        checked={state.monitoringFlag}
                        onChange={(e) => onCheckBoxChange_Init(e, "monitoringFlag")}
                        >
                        {langText.Body.UseMonitoring}
                    </Checkbox>
                </InlineForm>
            );
        } else if (state.modalType === "delete" || state.modalType === "read") {
            itemArr.push(
                <InlineForm.static
                    key="monitoring_flg"
                    controlId="monitoring_flg"
                >
                    {state.monitoringFlag ? langText.Body.UseMonitoring : langText.Body.NotUseMonitoring}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <>
                    <InlineForm key = "instanceId"
                        controlId = "instanceId"
                        label = {langText.Body.InstanceId}
                        type = "text"
                        value = {state.instanceId}
                        onChange = {e => onTextChange_Limit(e, "instanceId",charaLimit.instanceId)}
                        validationState = {validationHandle("instanceId")}/>
                    <InlineForm
                        key="region"
                        controlId="region"
                        label={langText.Body.region}
                        type="select"
                        options={state.regionList}
                        value = {state.region}
                        onChange={(e) => onSelectChange(e, "region")}
                        placeholder = {langText.SelectOption.Placeholder}
                        validationState = {validationHandle("region")}/>
                </>
            );
        } else {
            const region = state.regionList.find(region => region.value === state.region);
            itemArr.push(
                <>
                    <InlineForm.static
                        key = "instanceId"
                        controlId = "instanceId"
                        label = {langText.Body.InstanceId}>
                        {state.instanceId ? state.instanceId : langText.SelectOption.Unset}
                    </InlineForm.static>
                    <InlineForm.static
                        key = "region"
                        controlId = "region"
                        label = {langText.Body.region}>
                        {region && region.label}
                    </InlineForm.static>
                </>
            );
        }

        return itemArr;
    }

    render() {
        return (
            <AscModal
                state={this.props.state}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.props.validationHandle}
                onClick={this.props.onClick}
            >
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>{this.props.langText.Body.PbxSetting}</Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.props.state.modalType !== "gwAssignPbx"
                            ? this.getPbxInfoItem()
                            : this.getGwInfoItem()}
                    </Panel.Body>
                </Panel>
                {(this.props.state.modalType === "insert" ||
                    this.props.state.modalType === "update" ||
                    this.props.state.modalType === "delete" ||
                    this.props.state.modalType === "read") && (
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>{this.props.langText.Body.OemSetting}</Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>{this.createOemForm()}</Panel.Body>
                    </Panel>
                )}
                {(this.props.state.modalType === "insert" ||
                    this.props.state.modalType === "update" ||
                    this.props.state.modalType === "delete" ||
                    this.props.state.modalType === "read") && (
                    <Panel bsStyle="info">
                        <Panel.Heading>
                            <Panel.Title>{this.props.langText.Body.MonitoringSetting}</Panel.Title>
                        </Panel.Heading>

                        <Panel.Body>{this.createMonitoringForm()}</Panel.Body>
                    </Panel>
                )}
            </AscModal>
        );
    }
}
