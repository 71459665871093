import React from "react";
import {Panel, Col, Row} from "react-bootstrap";
import Component from "../../components/AscComponent";
import AscModal from "../Elements/Modal/Modal";
import ASFieldGroup from "../Elements/FieldGroup/FieldGroup";
import BusinessSetting from "./BusinessSetting";
import Type from "./Type.json";
import DatePicker from "react-datepicker";
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";
import "moment/locale/ja";
moment.locale('ja');

export default class BusinessCalendarHourSettingModal extends Component {
    constructor(props) {
        super(props);
        this.day_string = this.props.dayString;
        this.action_string = this.props.actionString;
        this.business_string = this.props.businessString;

        this.script_select = this.props.scriptSelect;
        this.voice_select = this.props.voiceSelect;
        
        this.state = {
            irregular_start_date: this.props.detail.irregular_start_date || moment(),
            irregular_end_date: this.props.detail.irregular_end_date || moment(),
            irregular_dates_arr: [this.getMomentTime({date: moment(), format: this.props.langText.Body.DateFormat})],
            irregular_dates_limit_cnt: 7,
            irregular_date_valid_flag: true,
            type: this.props.detail.type || null,
            business_start_hour: this.props.detail.business_start_hour || null,
            business_end_hour: this.props.detail.business_end_hour || null,
            business_hour_action_flag: this.props.detail.business_hour_action_flag || null,
            business_hour_action_detail: this.props.detail.business_hour_action_detail || null,
            business_hour_voice_file_id: this.props.detail.business_hour_voice_file_id || null,
            non_business_hour_action_flag: this.props.detail.non_business_hour_action_flag || null,
            non_business_hour_action_detail: this.props.detail.non_business_hour_action_detail || null,
            non_business_hour_voice_file_id: this.props.detail.non_business_hour_voice_file_id || null,
            exception_play_start_hour: this.props.detail.exception_play_start_hour || null,
            exception_play_end_hour: this.props.detail.exception_play_end_hour || null,
            exception_play_voice_file_id: this.props.detail.exception_play_voice_file_id || null,
            exception_play_flag: this.props.detail.exception_play_flag || false,

            setting_time_err_flag: this.props.detail.business_start_hour && (this.props.detail.business_start_hour.length === 4 && this.props.detail.business_end_hour.length === 4) ? true : false,
            setting_exception_time_err_flag: this.props.detail.exception_play_start_hour && (this.props.detail.exception_play_start_hour.length === 4 && this.props.detail.exception_play_end_hour.length === 4) ? true : false,
        };
    }

    validationHandle = (param) => {
        let returnFlag = true;
        const validTelNo = new RegExp(`[0-9]{10,11}`);
        switch(param) {
            case "calendar_ire":
                returnFlag = returnFlag && this.checkDatesLimit(
                    this.state.irregular_start_date,
                    this.state.irregular_end_date,
                    this.state.irregular_dates_limit_cnt
                );
            case "calendar_set":
                returnFlag = returnFlag && (
                    this.state.type === Type.BUSINESS.END ||
                    (
                        this.state.business_start_hour &&
                        this.state.business_end_hour &&
                        this.state.business_hour_action_flag &&
                        this.state.setting_time_err_flag &&
                        (this.state.business_hour_action_flag !== Type.ACTION.VOICE || this.state.business_hour_voice_file_id) &&
                        (this.state.business_hour_action_flag !== Type.ACTION.SCRIPT || this.state.business_hour_action_detail) &&
                        (this.state.business_hour_action_flag !== Type.ACTION.TRANSFER ||  validTelNo.test(this.state.business_hour_action_detail)) 
                    )
                ) && (
                    this.state.non_business_hour_action_flag &&
                    (
                        this.state.non_business_hour_action_flag !== Type.ACTION.VOICE || this.state.non_business_hour_voice_file_id) &&
                    (this.state.non_business_hour_action_flag !== Type.ACTION.SCRIPT || this.state.non_business_hour_action_detail) &&
                    (this.state.modalType !== "calendar_ire" || this.state.irregular_date) &&
                    (this.state.non_business_hour_action_flag !== Type.ACTION.TRANSFER || validTelNo.test(this.state.non_business_hour_action_detail)) 
                ) && (
                    !this.state.exception_play_flag ||
                    (
                        this.state.exception_play_flag &&
                        this.state.exception_play_start_hour &&
                        this.state.exception_play_end_hour &&
                        this.state.exception_play_voice_file_id &&
                        this.state.setting_exception_time_err_flag
                    )
                ) && this.state.irregular_date_valid_flag
                break;
            case "irregular_date":
                returnFlag = this.checkDatesLimit(
                    this.state.irregular_start_date,
                    this.state.irregular_end_date,
                    this.state.irregular_dates_limit_cnt
                ) ? null : this.props.langText.Message.IrrDateSetError;
                break;
            default:
                returnFlag = false;
                break;
        }
        return returnFlag;
    }

    getModalData = (param) => {
        let elem = [];

        if(param === 'exception_play_setting') {
            elem.push(
                <BusinessSetting
                    key="business_setting_exception"
                    state={this.state}
                    propSetState={this.propSetState}
                    exceptionPlaySetting={true}
                    read={this.props.read}
                    dayString={this.day_string}
                    actionString={this.action_string}
                    businessString={this.business_string}
                    scriptSelect={this.script_select}
                    voiceSelect={this.voice_select}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                />
            );
        } else {
            if (this.props.state.modalType === "calendar_set") {
                elem.push(
                    <ASFieldGroup
                        key="setting_days"
                        label={this.props.langText.Body.Day}>
                        <p>{this.props.days.map(row => this.day_string[row]).join(",")}</p>
                    </ASFieldGroup>
                );
            } else {
                elem.push(
                    <ASFieldGroup
                        key="irregular_date"
                        label={this.props.langText.Body.Date}
                        validationState={this.validationHandle("irregular_date")}>
                        <Row>
                            <Col xs={12} md={12}>
                                <Col md={3} lg={3}>
                                    <DatePicker
                                        selected={this.state.irregular_start_date}
                                        dateFormat={this.props.langText.Body.DateFormat}  
                                        minDate = {new Date()}
                                        readOnly={false}
                                        onChange = {e => {
                                            this.setState({
                                                irregular_start_date: e,
                                                irregular_dates_arr: this.getDatesStartToLast(
                                                    moment(e).format("YYYY-MM-DD"), 
                                                    moment(this.state.irregular_end_date).format("YYYY-MM-DD")
                                                )
                                            });
                                        }}
                                        onKeyDown = {e => {
                                            e.preventDefault();
                                            return false;
                                        }} 
                                    />
                                </Col>
                                <Col xs={1} sm={1} md={1} lg={1}>
                                    <span className="calendar-Modal_TimeCss">～</span>
                                </Col>
                                <Col md={3} lg={3}>
                                    <DatePicker
                                        selected={this.state.irregular_end_date}
                                        dateFormat={this.props.langText.Body.DateFormat}  
                                        minDate = {this.state.irregular_start_date}
                                        maxDate = {moment(this.state.irregular_start_date).add(6, 'days')}
                                        readOnly={false}
                                        onChange = {e => {
                                            this.setState({
                                                irregular_end_date: e,
                                                irregular_dates_arr: this.getDatesStartToLast(
                                                    moment(this.state.irregular_start_date).format("YYYY-MM-DD"),
                                                    moment(e).format("YYYY-MM-DD")
                                                )
                                            });
                                        }}
                                        onKeyDown = {e => {
                                            e.preventDefault();
                                            return false;
                                        }} 
                                    />
                                </Col>
                            </Col>
                        </Row>
                    </ASFieldGroup>
                );
            }
            elem.push(
                <BusinessSetting
                    key="business_setting"
                    state={this.state}
                    propSetState={this.propSetState}
                    read={this.props.read}
                    dayString={this.day_string}
                    actionString={this.action_string}
                    businessString={this.business_string}
                    scriptSelect={this.script_select}
                    voiceSelect={this.voice_select}
                    langText={this.props.langText}
                    charaLimit={this.props.charaLimit}
                />
            );
        }

        return <Col sm={12}>{elem}</Col>;
    }

    getSettingState() {
        let state = JSON.parse(JSON.stringify(this.props.state));
        if (this.props.read) state.modalType = "read";
        return state;
    }

    render() {
        
        return (
            <AscModal
                key="business-calendar-time-setting-modal"
                state={this.getSettingState()}
                propSetState={this.props.propSetState}
                langText={this.props.langText}
                validationHandle={this.validationHandle}
                customFunc={() => {
                    let state = {...this.state};
                    this.props.onClick(state);
                }}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.state.modalType === "calendar_set" ? this.props.langText.Body.BusinessHourSetting : this.props.langText.Body.IrregularSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getModalData()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.exceptionPlaySetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getModalData("exception_play_setting")}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}