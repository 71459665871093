import React from "react";
import {Panel} from "react-bootstrap";
import Component from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";

// 通話モニタリング画面(待ち呼一覧、シートビュー)
export default class CallMonitoring extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: [],
            show: false,
        };
    }

    panelOnClick = (path) => {
        this.props.historyPush({
            pathname: path,
        });
    };


    render() {
        const callMonitoringPermission = this.props.userInfo.permission_json_data.find(
            (permission) => permission.controller_id === "CallMonitoring"
        );

        return (
            <React.Fragment>
                <SetBreadCrumb displayItems={[{ name: this.props.langText.Body.CallMonitoring }]} />
                <div className="flex-style">
                    {callMonitoringPermission && callMonitoringPermission.read &&
                        <Panel
                            bsStyle="info"
                            bsSize="large"
                            className="callmonitoring-select"
                            onClick={() => this.panelOnClick("CallMonitoring/WaitStatuses")}
                        >
                            <Panel.Heading>
                                <Panel.Title componentClass="h1">
                                    {this.props.langText.Body.WaitStatuses}
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body className="body-callmonitoring-select">
                                {this.props.langText.Body.WaitStatusesDescription}
                            </Panel.Body>
                        </Panel>
                    }
                    {callMonitoringPermission && callMonitoringPermission.read &&
                        <Panel
                            bsStyle="info"
                            bsSize="large"
                            className="callmonitoring-select"
                            onClick={() => this.panelOnClick("CallMonitoring/SeatView")}
                        >
                            <Panel.Heading>
                                <Panel.Title componentClass="h1">
                                    {this.props.langText.Body.SeatView}
                                </Panel.Title>
                            </Panel.Heading>
                            <Panel.Body className="body-callmonitoring-select">
                                {this.props.langText.Body.SeatViewDescription}
                            </Panel.Body>
                        </Panel>
                    }
                </div>
            </React.Fragment>
        );
    }
}
