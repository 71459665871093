import React from "react"
import Component from "../../components/AscComponent";
import {Panel, Glyphicon, Row, Col, Button, ButtonGroup, Checkbox, Radio, ControlLabel, FormGroup, FormControl} from "react-bootstrap"
import CommonTable from '../../containers/Elements/Table/CommonTable'
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import chara_limit from "../../chara_limit";
import AscAudio from "../../containers/Elements/Audio/Audio";
import "../Elements/FromGroup/form_select.css"
import { SketchPicker } from 'react-color'
import reactCSS from 'reactcss'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import * as faIcon from "@fortawesome/free-solid-svg-icons";
import * as GlobalConst from "../../components/AscConstants";
import omniphone_example_left from "../../image/omniphone_example_left.png";
import omniphone_example_right from "../../image/omniphone_example_right.png";
import SetDataWithSpeechBubble from "../Elements/AscElements/SetDataWithSpeechBubble";
import {Creatable} from 'react-select';
import moment from "moment";
import ExternalNumberScheduleExtensionModal from "./ExternalNumberScheduleExtensionModal"
import ExternalNumberScheduleDispensingModal from "./ExternalNumberScheduleDispensingModal";
import { MultiSelect } from "react-multi-select-component";
export default class ExternalNumberModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // スケジュール内線グループモーダル表示用
            show: false,
            modalType: null,
            scheduled_inbound_group_no: 0,
            start_datetime: moment().format("YYYYMMDD"),
            start_time_hour: "",
            start_time_minutes: "",
            end_datetime: moment().format("YYYYMMDD"),
            end_time_hour: "",
            end_time_minutes: "",
            schedule_extenxion_group: [],
            inboundGroupSelect: this.props.state.inboundGroupSelect,
            inboundGroupcount: 0,
            inbound_group_base: this.props.state.inbound_group_base,
            inbound_group_cm51ids: this.props.state.inbound_group_cm51ids || [],

            // スケジュール着信コントロールモーダル表示用
            channel_number: this.props.state.channel_number,
            dispensing_show: false,
            dispensing_modalType: null,
            dispensing_scheduled_no: 0,
            dispensing_start_datetime: moment().format("YYYYMMDD"),
            dispensing_start_time_hour: "",
            dispensing_start_time_minutes: "",
            dispensing_end_datetime: moment().format("YYYYMMDD"),
            dispensing_end_time_hour: "",
            dispensing_end_time_minutes: "",
            dispensing_schedule_time: null,
            dispensing_schedule_count: "",
            dispensing_schedule: [],
            dispensing_count: 0,
            dispensingScheduleTimeValidationFlag: false,
            dispensingScheduleCountValidationFlag: false,
        };
    };
    getExternalNumberInfoItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onMultiTelNumberChangeLimit,
            onIntChange,
            onKeyDownIntCheck,
            onSelectChange,
            onCheckBoxChange,
            onMultiSelectChange,
            onRadioChange,
            validationHandle,
            getScopeGreaterEqual,
            usedDisplayNumbersearch,
            systemParameters,
            sprintf
        } = this.props;
        let itemArr = [];

        // 会社名
        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    value = {state.company_id}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.company_name}
                </InlineForm.static>
            );
        }

        // GW名
        if (state.modalType === "insert"  || (state.modalType === "update" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))) {
            itemArr.push(
                <InlineForm
                    key = "gateway_id"
                    controlId = "gateway_id"
                    label = {langText.Body.GatewayName}
                    type = "select"
                    onChange = {e => onMultiSelectChange(e, "gateway_id")}
                    options = {state.gatewaySelect}
                    value = {state.gateway_id}
                    isClearable = {true}
                    validationState = {validationHandle("gateway_id")}
                    isMulti = {true}
                    closeMenuOnSelect={false}
                    placeholder = {state.buttondisabledArr.gateway_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.gateway_id[0]}
                    />
            );
        } else {
            let gateway_names = [];

            if (state.gateway_id && Array.isArray(state.gateway_id)) {
                state.gateway_id.forEach((gid, key) => {
                    const gateway_info = state.gatewaySelect.find(option => option.value === parseInt(gid));
                    if (gateway_info) {
                        if (gateway_names.length) gateway_names.push(<br key={"gw_"+key} />);
                        gateway_names.push(<span key={key}>{gateway_info.label}</span>);
                    }
                });
            }

            itemArr.push(
                <InlineForm.static
                    key = "gateway_id"
                    controlId = "gateway_id"
                    label = {langText.Body.GatewayName}>
                    {gateway_names}
                </InlineForm.static>
            );
        }

        //回線タイプ
        if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
            itemArr.push(
                <InlineForm
                    key = "line_type"
                    controlId = "line_type"
                    label = {langText.Body.LineType}
                    type = "select"
                    onChange={e => onSelectChange(e, "line_type")}
                    options = {state.lineTypeSelect}
                    value = {state.line_type}
                    isClearable = {true}
                    validationState = {validationHandle("line_type")}
                    placeholder = {langText.SelectOption.Placeholder}
                />
            );
        } else {
            //label表示にする
            let label = '';
            state.lineTypeSelect.forEach(line => {
                if (line.value === state.line_type) {
                    label = line.label;
                }
            });
            
            itemArr.push(
                <InlineForm.static
                    key = "line_type"
                    controlId = "line_type"
                    label = {langText.Body.LineType}>
                    {label ? label : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        // 名称
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.ExtensionName}
                    type = "text"
                    onChange = {e =>onTextChange_Limit(e, "memo",chara_limit.External_memo)}
                    value = {state.memo}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.ExtensionName}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        // メモ
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key="description"
                    controlId="description"
                    label={langText.Body.Memo}
                    componentClass = "textarea"
                    rows="3"
                    value={state.description}
                    onChange={e => onTextChange_Limit(e, "description", charaLimit.External_description)}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "description"
                    controlId = "description"
                    label = {langText.Body.Memo}
                >
                    {state.description}
                </InlineForm.static>
            );
        }

        // 表示番号
        if (state.modalType === "insert") {
            //OEM権限以上
            itemArr.push(
                <InlineForm
                    key = "display_number"
                    controlId = "display_number"
                    label = {langText.Body.DisplayNumber}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {(e) => {
                        onTextChange_Limit(e, "display_number",chara_limit.External_display_number);
                        usedDisplayNumbersearch(e.target.value);
                    }}
                    value = {state.display_number}
                    validationState = {validationHandle("display_number")}/>
            );
            if (state.display_number_validation_flag == false) {
                itemArr.push(
                    <p key="displayNumberDuplicate" className="text-danger">
                        {langText.Message.ExternalNumber_Duplicate}
                    </p>
                );
            }
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "display_number"
                    controlId = "display_number"
                    label = {langText.Body.DisplayNumber}>
                    {state.display_number}
                </InlineForm.static>
            );
        }

        // 裏番号
        if (state.modalType === "insert"  || (state.modalType === "update" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company))) {
            //会社管理者権限以上
            itemArr.push(
                <InlineForm
                    key="back_number"
                    controlId="back_number"
                    validationState = {validationHandle("back_number")}
                    label={langText.Body.BackNumber}>
                    <Creatable
                        isMulti
                        isClearable
                        placeholder={langText.Message.Validation_Number_input}
                        onChange={(e) =>
                            onMultiTelNumberChangeLimit(
                                e,
                                "back_number",
                                charaLimit.External_back_number)
                        }
                        noOptionsMessage={() => langText.Message.Validation_Number_input}
                        formatCreateLabel={(inputText) => `${inputText} ${langText.Message.Creating}`}
                        value={state.back_number}
                    />
                </InlineForm>
            );

            if (state.back_number_validation_flag === false) {
                itemArr.push(
                    <p key="backNumberDuplicate" className="text-danger">
                        {`${langText.Message.TelNumbers_Duplicate}`}
                    </p>
                );

                state.registeredBackNumber.forEach(backNumber => {
                    itemArr.push(
                        <p key={backNumber} className="text-danger">
                            {`\n${backNumber}`}
                        </p>
                    );
                })
            }
        } else {
            let backNumbers = [];
            state.back_number.forEach(obj => {
                backNumbers.push(<p>{obj.value}</p>);
            });
            itemArr.push(
                <InlineForm.static
                    key = "back_number"
                    controlId = "back_number"
                    label = {langText.Body.BackNumber}>
                    {backNumbers}
                </InlineForm.static>
            );
        }

        // 最大着信数
        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "channel_number"
                    controlId = "channel_number"
                    label = {langText.Body.ChannelNumber}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("channel_number",charaLimit.External_channel_number)}
                    value = {state.channel_number}
                    validationState = {validationHandle("channel_number")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "channel_number"
                    controlId = "channel_number"
                    label = {langText.Body.ChannelNumber}>
                    {state.channel_number}
                </InlineForm.static>
            );
        }

        // 最大待ち呼数
        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "wait_number"
                    controlId = "wait_number"
                    label = {langText.Body.WaitNumber}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("wait_number",charaLimit.External_wait_number)}
                    value = {state.wait_number}
                    validationState = {validationHandle("wait_number")}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "wait_number"
                    controlId = "wait_number"
                    label = {langText.Body.WaitNumber}>
                    {state.wait_number}
                </InlineForm.static>
            );
        }

        // 待ち呼音声
        if((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <Row key = {"sound_id"}>
                    <Col xs = {6} sm = {6} md = {11} lg = {11}>
                        <InlineForm
                            controlId = "sound_id"
                            label = {langText.Body.SoundNumber}
                            type = "select"
                            onChange={e => onSelectChange(e, "sound_id")}
                            options = {state.waitNumberSoundSelect}
                            value = {state.sound_id}
                            isClearable = {true}
                            placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                            isDisabled = {!state.buttondisabledArr.sound_id[0]}
                        />
                    </Col>
                    <Col xs = {6} sm = {6} md = {1} lg = {1}>
                        <AscAudio
                            type = "url"
                            data = {
                                    state.waitNumberSoundSelect[state.waitNumberSoundSelect.findIndex((v) => v.value === state.sound_id)] ? 
                                    state.waitNumberSoundSelect[state.waitNumberSoundSelect.findIndex((v) => v.value === state.sound_id)].url
                                    :
                                    state.wait_number_sound_url
                                    }
                            className = "set_audio_button"
                        />
                    </Col>
                </Row>
            );
        } else {
            //待ち呼音声表示
            let wait_number_sound = state.waitNumberSoundSelect.find((row, key) => row.value === state.sound_id);
            itemArr.push(
                <InlineForm.static
                    key = "sound_id"
                    controlId = "sound_id"
                    label = {langText.Body.SoundNumber}>
                    {
                        wait_number_sound ? wait_number_sound.label : langText.SelectOption.Unset
                    }
                </InlineForm.static>
            );
        }

        // 待ち呼制限時間
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "wait_call_timeout_label"
                    controlId = "wait_call_timeout_label"
                    label = {langText.Body.WaitCallTimeOut}
                >
                    <Checkbox
                        key = "wait_call_timeout_use_flag"
                        className = "wait_call_timeout_use_flag"
                        checked = {state.waitCallTimeoutUseFlag}
                        onChange = {e => onCheckBoxChange(e, "waitCallTimeoutUseFlag")}
                    >
                        {langText.Body.WaitCallTimeOutCheckBox}
                    </Checkbox>
                </InlineForm>
            );
            if (state.waitCallTimeoutUseFlag) {
                // 待ち呼制限時間(秒)
                itemArr.push(
                    <InlineForm
                        key="wait_call_timeout_seconds"
                        controlId="wait_call_timeout_seconds"
                        type="text"
                        label={langText.Body.WaitCallTimeOutSeconds}
                        onKeyDown = {e => onKeyDownIntCheck(e)}
                        onChange={onIntChange("waitCallTimeoutSeconds", charaLimit.External_wait_call_timeout_seconds)}
                        value={state.waitCallTimeoutSeconds}
                        validationState={validationHandle("waitCallTimeoutSeconds")}
                    />
                );
                if (state.waitCallTimeoutSeconds > systemParameters.WAIT_CALL_TIMEOUT_LIMIT_SECONDS) {
                    // MAX設定値より大きい値が入力された場合、エラーメッセージを表示
                    itemArr.push(
                        <p key="wait_call_timeout_sec_over_max" className="text-danger">
                            {sprintf(
                                langText.Message.ExternalNumber_WaitCallTimeOutLimit,
                                systemParameters.WAIT_CALL_TIMEOUT_LIMIT_SECONDS
                            )}
                        </p>
                    );
                }

                // 待ち呼制限時間超過時音声
                itemArr.push(
                    <Row key = "wait_timeout_sound_id">
                        <Col xs = {6} sm = {6} md = {11} lg = {11}>
                            <InlineForm
                                controlId = "wait_call_timeout_sound_id"
                                label = {langText.Body.WaitCallTimeOutSound}
                                type = "select"
                                onChange={e => onSelectChange(e, "waitCallTimeoutSoundId")}
                                options = {state.waitCallTimeoutSoundSelect}
                                value = {state.waitCallTimeoutSoundId}
                                isClearable = {true}
                                placeholder = {state.buttondisabledArr.sound_load[0]
                                                ? <Glyphicon glyph="refresh" className="spinning"/> 
                                                : langText.SelectOption.Placeholder_defaultSound}
                                isDisabled = {!state.buttondisabledArr.sound_id[0]}
                            />
                        </Col>
                        <Col xs = {6} sm = {6} md = {1} lg = {1}>
                            <AscAudio
                                type = "url"
                                data = {
                                        state.waitCallTimeoutSoundSelect[state.waitCallTimeoutSoundSelect.findIndex(
                                            (v) => v.value === state.waitCallTimeoutSoundId)] 
                                        ? state.waitCallTimeoutSoundSelect[state.waitCallTimeoutSoundSelect.findIndex(
                                            (v) => v.value === state.waitCallTimeoutSoundId)].url
                                        : state.waitCallTimeoutSoundUrl
                                    }
                                className = "set_audio_button"
                            />
                        </Col>
                    </Row>
                );
            }
        } else {
            if(state.waitCallTimeoutUseFlag){
                // 待ち呼制限時間(秒)
                itemArr.push(
                    <InlineForm.static
                        key = "wait_call_timeout_seconds"
                        controlId = "wait_call_timeout_seconds"
                        label = {langText.Body.WaitCallTimeOutSeconds}
                    >
                        {state.waitCallTimeoutSeconds}
                    </InlineForm.static>
                );
                // 待ち呼制限時間超過時音声
                itemArr.push(
                    <InlineForm.static
                        key = "wait_call_timeout_sound_id"
                        controlId = "wait_call_timeout_sound_id"
                        label = {langText.Body.WaitCallTimeOutSound}
                    >
                        {
                            state.waitCallTimeoutSoundId ?
                                state.waitCallTimeoutSoundSelect.map((row, key) =>
                                    row.value === state.waitCallTimeoutSoundId ? row.label : ""
                                ):
                                langText.Message.DefaultAudioUse
                        }
                    </InlineForm.static>
                );
            } else {
                // 待ち呼制限時間「設定なし」と表示
                itemArr.push(
                    <InlineForm.static
                        key = "wait_call_timeout_seconds_unset"
                        controlId = "wait_call_timeout_seconds_unset"
                        label = {langText.Body.WaitCallTimeOut}
                    >
                        {langText.SelectOption.Unset}
                    </InlineForm.static>
                )
            }
        }

        // 待ち呼超過時の着信動作
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "call_over_action_form"
                    controlId = "call_over_action_form"
                    label = {langText.Body.ControlCallOverAction}
                    validationState = {validationHandle("call_over_action_control")}
                >
                    {state.callOverActionControlSelect.map((row, index) => (
                        <Radio
                            key = {index}
                            value = {row.value}
                            validationState = {validationHandle("call_over_action_control")}
                            onChange = {e => onRadioChange(e, "call_over_action_control")}
                            checked = {row.value === state.call_over_action_control}
                        >
                            {row.label}
                        </Radio>))
                    }
                </InlineForm>
            );
            // 待ち呼超過時の着信動作（音声再生）
            if (state.call_over_action_control === "playback") {
                // 待ち呼超過音声
                itemArr.push(
                    <Row key = "call_over_sound_id">
                        <Col xs = {6} sm = {6} md = {11} lg = {11}>
                            <InlineForm
                                controlId = "call_over_sound_id"
                                label = {langText.Body.CallOverSound}
                                type = "select"
                                onChange={e => onSelectChange(e, "call_over_sound_id")}
                                options = {state.callOverSoundSelect}
                                value = {state.call_over_sound_id}
                                isClearable = {true}
                                placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                                isDisabled = {!state.buttondisabledArr.sound_id[0]}
                            />
                        </Col>
                        <Col xs = {6} sm = {6} md = {1} lg = {1}>
                            <AscAudio
                                type = "url"
                                data = {
                                        state.callOverSoundSelect[state.callOverSoundSelect.findIndex((v) => v.value === state.call_over_sound_id)] ? 
                                        state.callOverSoundSelect[state.callOverSoundSelect.findIndex((v) => v.value === state.call_over_sound_id)].url
                                        :
                                        state.call_over_sound_url
                                    }
                                className = "set_audio_button"
                            />
                        </Col>
                    </Row>
                );

                // 待ち呼超過時の着信ステータス
                itemArr.push(
                    <InlineForm
                        key = "call_over_action"
                        controlId = "call_over_action"
                        label = {<SetDataWithSpeechBubble 
                                    displayData = {langText.Body.CallOverAction} 
                                    speechBubbleData = {langText.Body.CallOverActionExp}
                                    infoIconDisplayFlag = {true}
                                    infoIconType = {faIcon.faInfoCircle}
                                    infoIconClassName = "modal-info-icon"
                                    infoIconColor = "silver"
                                    infoIconSize = "lg"/>}                  
                        type = "select"
                        onChange = {e => onSelectChange(e, "call_over_action")}
                        options = {state.callOverActionSelect}
                        value = {state.call_over_action}
                        validationState = {validationHandle("call_over_action")}
                        placeholder = {langText.SelectOption.Placeholder}/>
                );
            }
        } else {
            let value = state.callOverActionSelect.find(row => row.value === state.call_over_action);
            itemArr.push(
                <InlineForm.static
                    key = "call_over_action"
                    controlId = "call_over_action"
                    label = {<SetDataWithSpeechBubble 
                        displayData = {langText.Body.CallOverAction} 
                        speechBubbleData = {langText.Body.CallOverActionExp}
                        infoIconDisplayFlag = {true}
                        infoIconType = {faIcon.faInfoCircle}
                        infoIconClassName = "modal-info-icon"
                        infoIconColor = "silver"
                        infoIconSize = "lg"/>}>
                    {value && value.label ? value.label : ""}
                </InlineForm.static>
            );

            if (state.call_over_action_control === "playback") {
                let call_over_sound = state.callOverSoundSelect.find((row, key) => row.value === state.call_over_sound_id);
                itemArr.push(
                    <InlineForm.static
                        key = "call_over_sound_id"
                        controlId = "call_over_sound_id"
                        label = {langText.Body.CallOverSound}>
                        {
                            call_over_sound ? call_over_sound.label : langText.SelectOption.Placeholder_defaultSound
                        }
                    </InlineForm.static>
                )
            }
        }

        // 内線タイプ
        if ((state.modalType === "insert") || (state.modalType === "update" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem))) {
            // OEM権限以上
            itemArr.push(
                <InlineForm
                    key = "inbound_type"
                    controlId = "inbound_type"
                    label = {langText.Body.InboundGroupType}
                    type = "select"
                    onChange = {e => onSelectChange(e, "inbound_type")}
                    options = {state.inboundTypeSelect}
                    value = {state.inbound_type}
                    isClearable = {true}
                    placeholder = {langText.SelectOption.Placeholder}
                    validationState = {validationHandle("inbound_type")}/>
            )

            if(state.acd_flag){
                itemArr.push(
                <p key="inbound_type_acd_setting_message" className="text-danger">
                    {langText.Message.ExternalNumber_AcdSettingMessage}
                </p>
                );
            }
        } else {
            let value = state.inboundTypeSelect.find(row => row.value === state.inbound_type);
            itemArr.push(
                <InlineForm.static
                    key = "inbound_type"
                    controlId = "inbound_type"
                    label = {langText.Body.InboundGroupType}>
                    {value && value.label ? value.label : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        // 通話成立時特殊音声再生
        if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
            itemArr.push(
                <InlineForm
                    key = "abj_flag_lable"
                    controlId = "abj_flag_label"
                    label = {langText.Body.AbjSound}
                >
                    <Checkbox
                        key = "abj_flag"
                        className = "abj_flag"
                        checked = {state.abj_flag}
                        onChange = {e => onCheckBoxChange(e, "abj_flag")}
                    >
                        {langText.Body.AbjSound}
                    </Checkbox>
                </InlineForm>
            );
        } else {
            if (state.abj_flag && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
                itemArr.push(
                    <InlineForm.static
                        key = "abj_flag_lable"
                        controlId = "abj_flag_label"
                        label = {langText.Body.AbjSound}
                    >
                        {langText.Message.ExternalNumber_AbjSettingMessage}
                    </InlineForm.static>
                );
            }
        }

        // 通話開始前音声
        if((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "before_dial_flag_label"
                    controlId = "before_dial_flag_label"
                    label = {langText.Body.BeforeDialSoundPlay}
                >
                    <Checkbox
                        key = "before_dial_flag"
                        className = "before_dial_flag"
                        checked = {state.before_dial_flag}
                        onChange = {e => onCheckBoxChange(e, "before_dial_flag")}
                    >
                        {langText.Body.BeforeDialSoundPlay}
                    </Checkbox>
                </InlineForm>
            )
            if(state.before_dial_flag){
                itemArr.push(
                    <Row key = {"before_dial_sound_id"}>
                        <Col xs = {6} sm = {6} md = {11} lg = {11}>
                            <InlineForm
                                controlId = "before_dial_sound_id"
                                label = {langText.Body.BeforeDialSound}
                                type = "select"
                                onChange={e => onSelectChange(e, "before_dial_sound_id")}
                                options = {state.beforeDialSoundSelect}
                                value = {state.before_dial_sound_id}
                                isClearable = {true}
                                placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                                isDisabled = {!state.buttondisabledArr.sound_id[0]}
                            />
                        </Col>
                        <Col xs = {6} sm = {6} md = {1} lg = {1}>
                            <AscAudio
                                type = "url"
                                data = {
                                        state.beforeDialSoundSelect[state.beforeDialSoundSelect.findIndex((v) => v.value === state.before_dial_sound_id)] ?
                                        state.beforeDialSoundSelect[state.beforeDialSoundSelect.findIndex((v) => v.value === state.before_dial_sound_id)].url
                                        :
                                        state.before_dial_sound_url
                                        }
                                className = "set_audio_button"
                            />
                        </Col>
                    </Row>
                );
            }

        } else {
            if(state.before_dial_flag){
                let value = state.beforeDialSoundSelect.find(row => row.value === state.before_dial_sound_id);
                itemArr.push(
                    <InlineForm.static
                        key = "before_dial_sound_id"
                        controlId = "before_dial_sound_id"
                        label = {langText.Body.BeforeDialSound}>
                        {value ? value.label : langText.Message.DefaultAudioUse}
                    </InlineForm.static>
            );
            }
        }

        // 録音データ保管期限
        if (state.modalType === "update" || state.modalType === "copy" || state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key="voiceLogExpire"
                    controlId="voiceLogExpire"
                    label={langText.Body.VoiceLogExpire}
                    type="select"
                    options = {state.voice_log_expire_external_number_select}
                    value={state.voice_log_expire_external_number}
                    onChange={e => onSelectChange(e, "voice_log_expire_external_number")}
                />
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "VoiceLogExpire"
                    controlId = "VoiceLogExpire"
                    label={langText.Body.VoiceLogExpire}
                >
                    {state.voice_log_expire_external_number_select
                    && state.voice_log_expire_external_number_select.find(row =>
                        row.value === state.voice_log_expire_external_number)
                        ? state.voice_log_expire_external_number_select.find(row =>
                            row.value === state.voice_log_expire_external_number).label
                        : langText.SelectOption.CompanyOverwrite}
                </InlineForm.static>
            );
        }

        // コール結果
        if (state.addingOptionCallResult) {
            if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = "call_results"
                        controlId = "call_results"
                        label = {langText.Body.CallResult}
                        type = "select"
                        onChange = {e => onMultiSelectChange(e, "call_results")}
                        options = {state.callResultsSelect}
                        value = {state.call_results}
                        isClearable = {true}
                        validationState = {validationHandle("call_results")}
                        isMulti = {true}
                        closeMenuOnSelect={false}
                        placeholder = {state.buttondisabledArr.call_results_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.call_results_id[0]}
                    />
                );
            } else {
                let callResultsNames = [];

                if (state.call_results && Array.isArray(state.call_results)) {
                    state.call_results.forEach((id, key) => {
                        const callResultsInfo = state.callResultsSelect.find(option => option.value === parseInt(id));
                        if (callResultsInfo) {
                            if (callResultsNames.length) callResultsNames.push(<br key={"call_result_"+ key} />);
                            callResultsNames.push(<span key={key}>{callResultsInfo.label}</span>);
                        }
                    });
                }

                itemArr.push(
                    <InlineForm.static
                        key = "callResults"
                        controlId = "callResults"
                        label = {langText.Body.CallResult}>
                        {callResultsNames.length > 0 ? callResultsNames : langText.SelectOption.Unset}
                    </InlineForm.static>
                );
            }
        }

        const styles = reactCSS({
            'default': {
                color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${ state.omni_phone_back_ground_color}`,
                display: 'block'
                },
                swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                },
                popover: {
                position: 'absolute',
                zIndex: '2',
                },
                cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                },
                default_set: {
                width: '60px',
                height: '14px',
                borderRadius: '2px',
                },
                before_set: {
                width: '100px',
                height: '14px',
                borderRadius: '2px',
                },
                paddingLeft30: {
                paddingLeft: '30px',
                },
            },
        });
        const font_styles = reactCSS({
            'default': {
                color: {
                width: '36px',
                height: '14px',
                borderRadius: '2px',
                background: `${ state.omni_phone_font_color}`,
                display: 'block'
                },
                font_color:{
                    color: `${ state.omni_phone_font_color}`,
                },
                swatch: {
                padding: '5px',
                background: '#fff',
                borderRadius: '1px',
                boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                display: 'inline-block',
                cursor: 'pointer',
                },
                popover: {
                position: 'absolute',
                zIndex: '2',
                },
                cover: {
                position: 'fixed',
                top: '0px',
                right: '0px',
                bottom: '0px',
                left: '0px',
                },
                default_set: {
                width: '60px',
                height: '14px',
                borderRadius: '2px',
                },
                before_set: {
                width: '100px',
                height: '14px',
                borderRadius: '2px',
                },
                paddingLeft30: {
                paddingLeft: '30px',
                },
            },
        });

        const example_styles = reactCSS({
            'default': {
                left_font_color:{
                    color: `${ state.omni_phone_font_color}`,
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    textAlign:"left",
                },
                right_font_color:{
                    color: `${ state.omni_phone_font_color}`,
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    textAlign:"right",
                },
                flag_color:{
                    color: `${ GlobalConst.omni_phone_font_color}`,
                },
                swatch: {
                    padding: '5px',
                    background: '#fff',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    cursor: 'pointer',
                },
                background: {
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: GlobalConst.omni_phone_border_color,
                    height: '60px',
                    width: '250px',
                    padding: '5px',
                    borderRadius: '1px',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                    cursor: 'pointer',
                    background: `${ state.omni_phone_back_ground_color}`,
                    display:"inline-table",
                },
                div_style: {
                    paddingRight: "0px",
                    paddingLeft: "0px",
                    marginTop:"10px",
                },
                div_img_left: {
                    display:"inline-table",
                    padding: "5px",
                },
                div_img_right: {
                    display:"inline-table",
                    padding: "5px",
                },
                img_left: {
                    height: '60px',
                    borderRadius: '10px 0px 0px 10px',
                    marginBottom:"30px",
                    borderWidth: "2px",
                    borderColor: "red",
                    borderStyle: "dashed",
                },
                img_right: {
                    height: '60px',
                    borderRadius: '0px 10px 10px 00px',
                    marginBottom:"30px",
                },
                parents_div:{
                    paddingRight: "0px",
                    paddingLeft: "0px",
                },
                
            },
        });

        // オムニフォン着信時(背景色)
        if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = "OmniPhoneincomingNumberColor"
                        controlId = "OmniPhoneincomingNumberColor"
                        label = {langText.Body.OmniPhoneincomingNumberColor}
                    >
                        <div className="col-sm-1">
                            <div style={ styles.swatch } onClick={e => this.handleClick("back_ground")}>
                            <div style={ styles.color } />
                            </div>
                            { state.omni_phone_back_ground_display_color_picker ? <div style={ styles.popover }>
                            <div style={ styles.cover } onClick={e => this.handleClose("back_ground")}/>
                            <SketchPicker color={ state.omni_phone_back_ground_color } onChange={e =>this.handleChange("back_ground",e)} />
                            </div> : null }
                        </div>

                        <div className="col-sm-1">
                            <div style={ styles.swatch } onClick={e => this.defaultColorSet("back_ground")}>
                                <div style={ styles.default_set }>
                                    {langText.Body.OmniPhoneResetDefaultColorSet}
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-sm-1" style={ styles.paddingLeft30 }>
                            <div style={ styles.swatch } onClick={e => this.beforeColorSet("back_ground")}>
                                <div style={ styles.before_set }>
                                    {langText.Body.OmniPhoneResetBeforeColorSet}
                                </div>
                            </div>
                        </div>
                    </InlineForm>
                );
                
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "OmniPhoneincomingNumberColor"
                    controlId = "OmniPhoneincomingNumberColor"
                    label = {langText.Body.OmniPhoneincomingNumberColor}>
                    <span>
                        <span style={ styles.swatch }>
                            <span style={ styles.color } />
                        </span>
                    </span>
                </InlineForm.static>
            );
        }

        // オムニフォン着信時(文字色)
        if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = "OmniPhoneincomingFontColor"
                        controlId = "OmniPhoneincomingFontColor"
                        label = {langText.Body.OmniPhoneincomingFontColor}
                    >
                        <div className="col-sm-1">
                            <div style={ font_styles.swatch } onClick={e => this.handleClick("font")}>
                                <div style={ font_styles.color } />
                            </div>
                            { state.omni_phone_font_display_color_picker ? <div style={ font_styles.popover}>
                            <div style={ font_styles.cover } onClick={e => this.handleClose("font")}/>
                            <SketchPicker color={ state.omni_phone_font_color } onChange={e => this.handleChange("font",e)} />
                            </div> : null }
                        </div>
                        <div className="col-sm-1">
                            <div style={ font_styles.swatch } onClick={e => this.defaultColorSet("font")}>
                                <div style={ font_styles.default_set }>
                                    {langText.Body.OmniPhoneResetDefaultColorSet}
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-1" style={ font_styles.paddingLeft30 }>
                            <div style={ font_styles.swatch } onClick={e => this.beforeColorSet("font")}>
                                <div style={ font_styles.before_set }>
                                    {langText.Body.OmniPhoneResetBeforeColorSet}
                                </div>
                            </div>
                        </div>
                    </InlineForm>
                );
                
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "OmniPhoneincomingFontColor"
                    controlId = "OmniPhoneincomingFontColor"
                    label = {langText.Body.OmniPhoneincomingFontColor}>
                    <span>
                        <span style={ font_styles.swatch }>
                            <span style={ font_styles.color } />
                        </span>
                    </span>
                </InlineForm.static>
            );
        }

        // オムニフォン着信時のイメージ
        itemArr.push(
            <InlineForm
                key = "OmniPhoneExampleExplanation"
                controlId = "OmniPhoneExampleExplanation"
                label = {langText.Body.OmniPhoneExampleExplanation}
                style={example_styles.parents_div}
            >
                <div className="col-sm-12" style={example_styles.parents_div}>
                    <div style={ example_styles.div_img_left }>
                        <img style={ example_styles.img_left } src={omniphone_example_left}></img>
                    </div>
                    <div style={ example_styles.background }>
                        <div className="col-sm-12" style={ example_styles.div_style }>
                            <div className="col-sm-5" style={ example_styles.left_font_color }>
                                <label>着信番号<br/>012012345678</label>
                                {/* <label>012012345678</label> */}
                            </div>
                            <div className="col-sm-2" style={ example_styles.flag_color }>
                                <FontAwesomeIcon
                                icon = {faIcon.faChevronLeft}
                                size="2x"/>
                            </div>
                            <div className="col-sm-5" style={ example_styles.right_font_color }>
                                <label>相手先番号<br/>08012345678</label>
                                {/* <label>08012345678</label> */}
                            </div>
                        </div>
                    </div>
                    <div style={ example_styles.div_img_right }>
                        <img style={ example_styles.img_right } src={omniphone_example_right} ></img>
                    </div>
                </div>
            </InlineForm>
        );

        return itemArr;
    }
    defaultColorSet = (type) => {
        let {
            propSetState,
            state
        } = this.props;
        const omni_phone_font_color = state.omni_phone_font_color;
        const omni_phone_back_ground_color= state.omni_phone_back_ground_color;
        switch (type) {
            case "font":
                if (omni_phone_font_color != GlobalConst.omni_phone_font_color) {
                    propSetState({ before_omni_phone_font_color: omni_phone_font_color })
                }
                propSetState({ omni_phone_font_color: GlobalConst.omni_phone_font_color })
                break;
            case "back_ground":
                if (omni_phone_back_ground_color != GlobalConst.omni_phone_back_ground_color) {
                    propSetState({ before_omni_phone_back_ground_color: omni_phone_back_ground_color })
                }
                propSetState({ omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color })
                break
            default:
                if (state.before_omni_phone_font_color != GlobalConst.omni_phone_font_color) {
                    propSetState({ before_omni_phone_font_color: GlobalConst.omni_phone_font_color })
                }
                if (state.before_omni_phone_back_ground_color != GlobalConst.omni_phone_back_ground_color) {
                    propSetState({ before_omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color })
                }
                propSetState({ omni_phone_font_color: GlobalConst.omni_phone_font_color })
                propSetState({ omni_phone_back_ground_color: GlobalConst.omni_phone_back_ground_color })
                break;
        }
        
    }

    beforeColorSet = (type) => {
        let {
            propSetState,
            state
        } = this.props;

        switch (type) {
            case "font":
                if (state.before_omni_phone_font_color != "") {
                     propSetState({ omni_phone_font_color: state.before_omni_phone_font_color })
                }
                break;
            case "back_ground":
                if (state.before_omni_phone_back_ground_color != "") {
                    propSetState({ omni_phone_back_ground_color: state.before_omni_phone_back_ground_color })
                } 
                break;
            default:
                propSetState({ omni_phone_font_color: state.before_omni_phone_font_color })
                propSetState({ omni_phone_back_ground_color: state.before_omni_phone_back_ground_color })
                break;
        }
    }

    onClickPlusHandle() {
        let {
            state,
            propSetState,
            getDepartmentSelect,
        } = this.props;
        if (state["departmentInfos"].length < 10) {
            if (state.gateway_id.length > 0 ) {
                state["departmentInfos"].push({});
                propSetState({departmentInfos: state["departmentInfos"]});
                if (!this.props.state.floor_flg) {
                    getDepartmentSelect(state["departmentInfos"]);
                }
            } else {
                alert(this.props.langText.Message.ExternalNumber_GwNoSelect);   
            }
        } else {
            alert(this.props.langText.Message.ExternalNumber_DepartmentLimit10);   
        }
        
    
    }

    onClickMinusHandle(index) {
        let {
            state,
            propSetState,
            getDepartmentSelect,
            inboundGroupTypeSelectChanged,
        } = this.props;

        if (state["floorSelect"]) {
            state["floorSelect"].splice(index, 1);
            propSetState({floorSelect: state["floorSelect"]});
        }

        state["departmentInfos"].splice(index, 1);
        propSetState({departmentInfos: state["departmentInfos"]});
        if (!this.props.state.floor_flg) {
            getDepartmentSelect(state["departmentInfos"]);
        }
        if (state["departmentInfos"]) {
            let floor_id_arr = [];
            let department_id_arr = [];
            
            state["departmentInfos"].forEach((row,index) => {
                if (row.department_id) {
                    department_id_arr.push(row.department_id);
                }
                if (row.floor_id) {
                    floor_id_arr.push(row.floor_id);
                }
            });
            let inbound_type = state['inbound_type'];
            let select_dep = this.props.state.floor_flg ? floor_id_arr : department_id_arr;
            
            inboundGroupTypeSelectChanged({
                department_id: select_dep,
                inbound_type
            })
        }
    }

    handleClick = (type) => {
        let {
            state,
            propSetState,
        } = this.props;

        if (type == "font") {
            propSetState({ omni_phone_font_display_color_picker: !state.omni_phone_font_display_color_picker })
        } else if (type == "back_ground") {
            propSetState({ omni_phone_back_ground_display_color_picker: !state.omni_phone_back_ground_display_color_picker })
        }

    };
    
    handleClose = (type) => {
        let {
            propSetState,
        } = this.props;
        
        if (type == "font") {
            propSetState({ omni_phone_font_display_color_picker: false })
        } else if (type == "back_ground") {
            propSetState({ omni_phone_back_ground_display_color_picker: false })
        }
    };

    handleChange = (type,color) => {
        let {
            propSetState,
            state,
        } = this.props;

        if (type == "font") {
            if (state.before_omni_phone_font_color != state.omni_phone_font_color) {
                if (state.before_omni_phone_font_color !="") {
                    propSetState({ before_omni_phone_font_color: state.omni_phone_font_color });
                    
                } else {
                    propSetState({ before_omni_phone_font_color: color.hex });
                }
            }
            propSetState({ omni_phone_font_color: color.hex });
        } else if (type == "back_ground") {
               
            if (state.before_omni_phone_back_ground_color != state.omni_phone_back_ground_color) {
                if (state.before_omni_phone_back_ground_color !="") {
                    propSetState({ before_omni_phone_back_ground_color: state.omni_phone_back_ground_color });
                } else {
                    propSetState({ before_omni_phone_back_ground_color: color.hex });
                }
                
            }
            propSetState({ omni_phone_back_ground_color: color.hex });
        }
        
    };

    // 拠点設定
    getDepartmentSettingInfoItem() {
        let {
            onDepartmentChange,
            state,
            langText,
            charaLimit,
            departmentInfos_onTextChange_Limit,
            onKeyDownIntCheck,
            validationHandle,
            getScopeGreaterEqual,
            usedPrefixSearch,
            userInfo,
            currentPermission
        } = this.props;
        let mainitemArr = [];

        state.departmentInfos.forEach((row, index) => {
            let itemArr = [];

            if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                //会社管理者以上
                itemArr.push(
                    <Button key="minus"
                        className="multiValueFormMinus"
                        onClick={e => this.onClickMinusHandle(index)}>
                        <Glyphicon glyph="minus"/>
                    </Button>
                );
            }
            // 拠点名
            if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                //会社管理者以上
                if(this.props.state.floor_flg){
                    row.department_name = row.parent_department_name || row.department_name;
                }
                itemArr.push(
                    <InlineForm
                        key = "department_id"
                        controlId = "department_id"
                        label = {langText.Body.DepartmentName}
                        type = "select"
                        isClearable = {true}
                        onChange = {e => onDepartmentChange(e, "department_id", index)}
                        options = {state.departmentSelectArr[index] || state.departmentSelect}
                        value = {row.parent_department_id || row.department_id}
                        validationState = {validationHandle("department_id", index)}
                        placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.department_id[0]}
                        />
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "department_id"
                        controlId = "department_id"
                        label = {langText.Body.DepartmentName}>
                        {row.department_name}
                    </InlineForm.static>
                );
            }

            // フロア名
            if (this.props.state.floor_flg) {
                if ((state.modalType === "insert" || state.modalType === "update")
                    && (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)
                        || (GlobalConst.SCOPE_OBJECT.department.name === currentPermission.scope_code
                            && state.departmentInfos[index].parent_department_id === userInfo.parent_department_id))) {
                    //拠点管理者で自分の配下フロアもしくは会社管理者以上
                    itemArr.push(
                        <InlineForm
                            key = "floor_id"
                            controlId = "floor_id"
                            label = {langText.Body.FloorName}
                            type = "select"
                            options = {state.floorSelect[index]|| []}
                            value = {row.floor_id || ""}
                            isClearable = {true}
                            onChange = {e => onDepartmentChange(e, "floor_id", index)}
                            validationState = {validationHandle("floor_id", index)}
                            placeholder = {state.buttondisabledArr.floor_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                            isDisabled = {!state.buttondisabledArr.floor_id[0]}
                            />
                    );
                } else {
                    itemArr.push(
                        <InlineForm.static
                            key = "floor_id"
                            controlId = "floor_id"
                            label = {langText.Body.FloorName}>
                            {row.floor_name}
                        </InlineForm.static>
                    );
                }
            }

            // プレフィックス
            if ((state.modalType === "insert" || state.modalType === "update")
                && (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)
                    || (getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)
                        && state.departmentInfos[index].parent_department_id === userInfo.parent_department_id)
                    || state.departmentInfos[index].department_id === userInfo.department_id)) {
                itemArr.push(<InlineForm
                        key = "prefix"
                        controlId = "prefix"
                        label = {langText.Body.Prefix}
                        type = "text"
                        onKeyDown = {e => onKeyDownIntCheck(e)}
                        onChange = {e =>departmentInfos_onTextChange_Limit(e, "prefix",index,charaLimit.External_prefix,state.departmentInfos)}
                        onBlur = {() => usedPrefixSearch(index)}
                        value = {row.prefix || ""}
                        validationState = {validationHandle("prefix", index)}
                        disabled={!row.department_id}
                    />
                );
                if(state.emptyPrefix[index])
                {
                    itemArr.push(
                        <p key = {"emptyPrefix"}>
                            {langText.Body.EmptyPrefix + state.emptyPrefix[index]}
                        </p>
                    );
                }
                if(row.prefix_duplicationflag === true)
                {
                    itemArr.push(
                        <p key = {index}>
                            <font color="red">
                                {langText.Body.PrefixDuplicate}
                            </font>
                        </p>
                    );
                }
                if(!this.isSystemUsedPrefix(row.prefix))
                {
                    itemArr.push(
                        <p key = "PrefixSystemUseNumber" className="text-danger">
                                {langText.Body.PrefixSystemUseNumber}
                        </p>
                    );
                }
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "prefix"
                        controlId = "prefix"
                        label = {langText.Body.Prefix}>
                        {row.prefix}
                    </InlineForm.static>
                );
            }
            
            mainitemArr.push(
            <div key={index}> 
                {itemArr}
            </div>);
        });
        if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            //会社管理者以上
            mainitemArr.push(
                <Button key="plus"
                    className="multiValueFormPlus"
                    onClick={e => this.onClickPlusHandle()}>
                    <Glyphicon glyph="plus"/>
                </Button>
            );
        }
        if (!mainitemArr.length) {
            mainitemArr.push(
                <InlineForm.static
                    key = "department_setting_unset"
                    controlId = "department_setting_unset">
                    {langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        return <div>
            {mainitemArr}

        </div>;
    }

    inbound_group_id_disabled = () => {
        if(!this.props.state.department_id === true && !this.props.state.inbound_type === true)
        {
            return true;
        }
        else
        {
            return false;
        }
    }

    // 着信設定
    getInboundSettingInfoItem = () => {
        let {
            state,
            langText,
            onMultiSelectChangeExternal,
            validationHandle,
            systemParameters,
            onRadioChange,
            onCheckBoxChange,
            boardWidth
        } = this.props;
        let itemArr = [];

        let column = [
            {
                Header: langText.Body.No,
                accessor: 'scheduled_inbound_group_no',
                width: boardWidth.sId
            },
            {
                Header: langText.Body.ExtensionStartDateTime,
                accessor: 'original_start_datetime',
                width: boardWidth.xlarge,
                Cell: data => {
                    return data.value ? moment(data.value).utc().add(9, "h").format("YYYY/MM/DD HH:mm") : "";
                }
            },
            {
                Header: langText.Body.ExtensionEndDateTime,
                accessor: 'original_end_datetime',
                width: boardWidth.xlarge,
                Cell: data => {
                    return data.value ? moment(data.value).utc().add(9, "h").format("YYYY/MM/DD HH:mm") : "";
                }
            },
            {
                Header: langText.Body.InboundGroupName,
                accessor: 'cm28_inbound_group_infos',
                width: boardWidth.xxlarge,
                sortable: false,
                Cell: data => {
                    let inbound_group_name_arr = [];

                    let selectids = [];
                    inbound_group_name_arr = (data.value && data.value.length) && data.value.map(row =>{
                        let label = state.inboundGroupSelect.find(elem => elem.value === row.cm28_id) && state.inboundGroupSelect.find(elem => elem.value === row.cm28_id).label;
                        return label || row.inbound_group_name
                    });
                    let schedule_inbound_ids = data.value.map(row=>{
                        return row.cm28_id
                    })
                    for(let i = 0; i < state.inbound_group_cm51ids.length; i++){
                        if(schedule_inbound_ids.indexOf(state.inbound_group_cm51ids[i].id) != -1){
                            if(selectids.length && selectids.findIndex(({cm51_id}) => cm51_id === state.inbound_group_cm51ids[i].cm51_id) === -1){
                                selectids.push(state.inbound_group_cm51ids[i]);
                            }
                            else if(!selectids.length){
                                selectids.push(state.inbound_group_cm51ids[i]);
                            }
                        }
                    }
                    inbound_group_name_arr = inbound_group_name_arr || [];
                    let inboundGroupInfos = [...new Set(inbound_group_name_arr)]
                    inboundGroupInfos.push(<b>{langText.Body.ScheduleExtenCount + "(" + selectids.length + ")"}</b>);
                    inboundGroupInfos = (
                        <>
                            {inboundGroupInfos.map((row, index) =>{
                                return <div key = {index}>{row}</div>
                            })}
                        </>
                    );

                    return inboundGroupInfos;
                }
            }
        ];
        if((state.modalType === "insert" || state.modalType === "update")){
            column.push({
                Header: langText.Body.Control,
                width: boardWidth.Id,
                sortable: false,
                Cell: data => {
                    let rowData = [];
                        if (moment(data.row._original.original_end_datetime) > moment()) {
                            rowData.push(
                                <Button
                                    key={1}
                                    bsSize="xsmall"
                                    onClick={this.modalShow("update", data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="pencil"/>
                                </Button>
                            );
                        }
                        rowData.push(
                            <Button
                                key={1}
                                bsSize="xsmall"
                                onClick={this.modalShow("copy", data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="copy"/>
                            </Button>
                        );
                        rowData.push(
                            <Button
                                key={1}
                                bsSize='xsmall'
                                onClick={e => this.deleteScheduleExtension(data.index)}
                                className='control-button'
                            >
                                <Glyphicon glyph='minus'/>
                            </Button>
                        );
                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            });
        }

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "inbound_group_id"
                    controlId = "inbound_group_id"
                    label = {langText.Body.InboundGroupName}
                    type = "select"
                    onChange = {e => onMultiSelectChangeExternal(e, "inbound_group_id")}
                    options = {state.inboundGroupSelect}
                    value = {state.inbound_group_id}
                    isClearable = {true}
                    validationState = {validationHandle("inbound_group_id")}
                    isMulti = {true}
                    closeMenuOnSelect={false}
                    placeholder = {state.buttondisabledArr.inbound_group_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.inbound_group_id[0]}/>
            );
            let drawdata = '';
            let drawcolor = 'black';
            if(state.inboundGroupcount > systemParameters.EXTEN_COUNT)
            {
                drawdata = state.inboundGroupcount + " / " + systemParameters.EXTEN_COUNT + " " + langText.Body.ExtenCountOver_1 + systemParameters.EXTEN_COUNT + langText.Body.ExtenCountOver_2;
                drawcolor = 'red';
            }
            else
            {
                drawdata = state.inboundGroupcount + " / " + systemParameters.EXTEN_COUNT;
                drawcolor = 'black';
            }
            itemArr.push(
                <InlineForm.static
                    key = "exten_count"
                    controlId = "exten_count"
                    label = {langText.Body.ExtenCount}>
                        <font color={drawcolor}>
                            {drawdata}
                        </font>
                </InlineForm.static>
            )

            if(state.acd_flag){
                itemArr.push(
                <p key="inbound_group_acd_setting_message" className="text-danger">
                    {langText.Message.ExternalNumber_AcdSettingMessage}
                </p>
                );
            }
        } else {
            let drawdata = "";
            for(let i = 0; i < state.inbound_group_name.length;i++){
                if(i === state.inbound_group_name.length - 1){drawdata = drawdata + state.inbound_group_name[i];}
                else{drawdata = drawdata + state.inbound_group_name[i] + ",";}
            }
            itemArr.push(
                <InlineForm.static
                    key = "inbound_group_id"
                    controlId = "inbound_group_id"
                    label = {langText.Body.InboundGroupName}>
                    {drawdata ? drawdata : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <FormGroup>
                    <ControlLabel
                        key = "schedule-extension-label"
                        className = "schedule-extension-label"
                    >
                        {langText.Body.SchedueExtensionGroup}
                        <ButtonGroup className="margin-left-05">
                            <Button
                                bsStyle="default"
                                bsSize="sm"
                                className="schedule-add-button"
                                onClick = {this.modalShow("insert")}
                                disabled = {!state.buttondisabledArr.inbound_group_id[0]}
                            >
                                {langText.Body.Add}
                            </Button>
                        </ButtonGroup>
                    </ControlLabel>
                </FormGroup>
            )
        } else {
            itemArr.push(
                <FormGroup>
                    <ControlLabel
                        key = "schedule-extension-label"
                        className = "schedule-extension-label"
                    >
                        {langText.Body.SchedueExtensionGroup}
                    </ControlLabel>
                    {
                        state.schedule_extension_group_infos.length === 0 &&
                        <div className = "col-sm-12" key = "extension-static">
                            <FormControl.Static
                                className = "schedule-extension-static"
                            >
                                {langText.SelectOption.Unset}
                            </FormControl.Static>
                        </div>
                    }
                </FormGroup>
            );
        }
        if(state.modalType && state.schedule_extension_group_infos.length){
            itemArr.push(
                <CommonTable
                    talbeRef = {this.table}
                    manual = 'manual'
                    className = "schedule-extension-table"
                    columns = {column}
                    data = {state.schedule_extension_group_infos}
                    showPagination={false}
                    pageSize = {state.schedule_extension_group_infos.length}
                    defaultSorted = {[{id:"scheduled_inbound_group_no", desc:false}]}
                    onSortedChange = {(e) => {this.onSortScheduledInboundGroupInfoTable(e)}}
                />
            )
        }
        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "dial_restriction_flag"
                    controlId = "dial_restriction_flag"
                    label = {langText.Body.DialRestrictionFlagSetting}>
                    {state.DialRestrictionSelect.map((row, index)=>(
                        <Radio
                            key = {index}
                            value = {row.value}
                            onChange = {e => {
                                onRadioChange(e, "dial_restriction_flag");
                            }}
                            checked = {row.value === state.dial_restriction_flag}
                        >
                            {row.label}
                        </Radio>))
                    }
                </InlineForm>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "dial_restriction_flag"
                    controlId = "dial_restriction_flag"
                    label = {langText.Body.DialRestrictionFlagSetting}>
                    {state.DialRestrictionSelect.map((row) =>
                        row.value === state.dial_restriction_flag ? row.label : ""
                    )}
                </InlineForm.static>
            );
        }

        if(state.addingOptionACD) {
            // 会社の追加オプション「ACD」がONの場合のみ表示
            if ((state.modalType === "insert" || state.modalType === "update")) {
                itemArr.push(
                    <InlineForm
                        controlId = "acd_setting_form"
                        label = {
                            <SetDataWithSpeechBubble
                                displayData = {langText.Body.ACDSetting}
                                speechBubbleData = {langText.Body.ACDSettingDescription}
                                infoIconDisplayFlag = {true}
                                infoIconType = {faIcon.faInfoCircle}
                                infoIconClassName = "modal-info-icon"
                                infoIconColor = "silver"
                                infoIconSize = "lg"/>}>
                        <Checkbox
                            key = "acd_setting_flag"
                            className = "acd_setting_flag"
                            checked = {state.acd_flag}
                            onChange = {e => onCheckBoxChange(e, "acd_flag")}>
                            {langText.SelectOption.Use}
                        </Checkbox>
                    </InlineForm>
                );

                if(state.acd_flag){
                    itemArr.push(
                    <p key="acd_flag_setting_message" className="text-danger">
                        {langText.Message.ExternalNumber_AcdSettingMessage}
                    </p>
                    );
                }
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "acd_setting_flag"
                        controlId = "acd_setting_flag"
                        label = {langText.Body.ACDSetting}>
                        {state.acd_flag
                            ? langText.SelectOption.Use
                            : langText.SelectOption.Disuse}
                    </InlineForm.static>
                );
            }
        }

        return itemArr;
    }

    // 着信コントロール設定
    getDispensingInfoItem = () => {
        let {
            state,
            langText,
            onCheckBoxChange,
            onKeyDownIntCheck,
            onIntChange,
            charaLimit,
            validationHandle,
            boardWidth
        } = this.props;
        let itemArr = [];
        let default_dispensing_time = 60;

        let column = [
            {
                Header: langText.Body.No,
                accessor: 'dispensing_scheduled_no',
                width: boardWidth.sId
            },
            {
                Header: langText.Body.DispensingStartDateTime,
                accessor: 'original_start_datetime',
                width: boardWidth.xlarge,
                Cell: data => {
                    return data.value ? moment(data.value).utc().add(9, "h").format("YYYY/MM/DD HH:mm") : "";
                }
            },
            {
                Header: langText.Body.DispensingEndDateTime,
                accessor: 'original_end_datetime',
                width: boardWidth.xlarge,
                Cell: data => {
                    return data.value ? moment(data.value).utc().add(9, "h").format("YYYY/MM/DD HH:mm") : "";
                }
            },
            {
                Header: langText.Body.SecCnt,
                accessor: 'secCnt',
                width: boardWidth.Id
            },
            {
                Header: langText.Body.InboundCnt,
                accessor: 'inboundCnt',
                width: boardWidth.Id
            }
        ];

        if((state.modalType === "insert" || state.modalType === "update")){
            column.push({
                Header: langText.Body.Control,
                width: boardWidth.Id,
                sortable: false,
                Cell: data => {
                    let rowData = [];
                        // 編集ボタン
                        if (moment(data.row._original.original_end_datetime) > moment()) {
                            rowData.push(
                                    <Button
                                        key={1}
                                        bsSize="xsmall"
                                        onClick={this.modalShow("dispensing_update", data.row._original)}
                                        className="control-button"
                                    >
                                        <Glyphicon glyph="pencil"/>
                                    </Button>
                            );
                        }
                        // 複製ボタン
                        rowData.push(
                            <Button
                                key={1}
                                bsSize="xsmall"
                                onClick={this.modalShow("dispensing_copy", data.row._original)}
                                className="control-button"
                            >
                                <Glyphicon glyph="copy"/>
                            </Button>
                        );
                        // 削除ボタン
                        rowData.push(
                            <Button
                                key={1}
                                bsSize='xsmall'
                                onClick={e => this.deleteScheduleDispensing(data.index)}
                                className='control-button'
                            >
                                <Glyphicon glyph='minus'/>
                            </Button>
                        );
                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            });
        }

        // 着信コントロールのチェックボックス
        if ((state.modalType === "insert" || state.modalType === "update")) {
                itemArr.push(
                    <InlineForm>
                        <ControlLabel>
                            <Checkbox
                                key = "dispensing-flag"
                                className = "dispensing-flag"
                                checked = {state.dispensing_flag}
                                onChange = {e => onCheckBoxChange(e, "dispensing_flag")}
                                validationState = {validationHandle("dispensing_flag")}
                            >
                                <div className="font-weight-bold">
                                {langText.Body.Dispensing}
                                </div>
                            </Checkbox>
                        </ControlLabel>
                    </InlineForm>
                );

            if(state.dispensing_flag){
                    itemArr.push(
                        <InlineForm
                            key = "dispensing-time"
                            controlId = "dispensing-time"
                            label = {langText.Body.SecCnt}
                            type = "text"
                            onKeyDown = {e => onKeyDownIntCheck(e)}
                            onChange={onIntChange("dispensing_time",charaLimit.External_dispensing_time)}
                            value = {state.dispensing_time}
                            placeholder={langText.Message.ExternalNumber_DispensingTime}
                            validationState = {validationHandle("dispensing_time")}
                            />
                    );
                    if((state.dispensing_time !== null && state.dispensing_time < 1)
                        || state.dispensing_time > 60){
                        itemArr.push(
                        <p key="dispensing-time-limit" className="text-danger">
                            {langText.Message.ExternalNumber_DispensingTimeLimit}
                        </p>
                        );
                    }
                    itemArr.push(
                        <InlineForm
                            key = "dispensing-count"
                            controlId = "dispensing-count"
                            label = {langText.Body.InboundCnt}
                            type = "text"
                            onKeyDown = {e => onKeyDownIntCheck(e)}
                            onChange={onIntChange("dispensing_count",charaLimit.External_dispensing_count)}
                            value = {state.dispensing_count}
                            validationState = {validationHandle("dispensing_count")}
                            />
                    );
                    if(state.channel_number && state.dispensing_count > state.channel_number){
                        itemArr.push(
                        <p key="dispensing-count-limit" className="text-danger">
                            {langText.Message.ExternalNumber_DispensingTimeOverChannelNumber}
                        </p>
                        );
                    }
            }
        } else {
            itemArr.push(
                <InlineForm>
                    <ControlLabel>
                            <div className="font-weight-bold">
                            {langText.Body.Dispensing}
                            </div>
                    </ControlLabel>
                </InlineForm>
            );

            if(state.dispensing_flag){
                itemArr.push(
                    <InlineForm.static
                        key = "dispensing-time"
                        controlId = "dispensing-time"
                        label = {langText.Body.SecCnt}>
                        {state.dispensing_time && state.dispensing_time !== default_dispensing_time
                            ? state.dispensing_time
                            : langText.Message.ExternalNumber_DispensingTimeShow}
                    </InlineForm.static>
                );

                itemArr.push(
                    <InlineForm.static
                        key = "dispensing-count"
                        controlId = "dispensing-count"
                        label = {langText.Body.InboundCnt}>
                        {state.dispensing_count}
                    </InlineForm.static>
                );
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "dispensing-setting-unset"
                        controlId = "dispensing-setting-unset">
                        {langText.SelectOption.Unset}
                    </InlineForm.static>
                );
            }
        }

        // スケジュール着信コントロール追加
        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    validationState = {validationHandle("schedule_dispensing")}
                >
                    <ControlLabel
                        key = "schedule_dispensing_label"
                        className = "schedule_dispensing_label"
                    >
                        {langText.Body.ScheduleDispensing}
                    </ControlLabel>
                    <ButtonGroup className="margin-left-05">
                        <Button
                            bsStyle="default"
                            bsSize="sm"
                            className="schedule-add-button"
                            onClick = {this.modalShow("dispensing_insert")}
                        >
                            {langText.Body.Add}
                        </Button>
                    </ButtonGroup>
                </InlineForm>
            );
            if (state.channel_number && state.schedule_dispensing_infos.some(row => {
                return row.inboundCnt > state.channel_number
            })){
                itemArr.push(
                    <p key="schedule-dispensing-count-limit" className="text-danger">
                        {langText.Message.ExternalNumber_ScheduleDispensingTimeOverChannelNumber}
                    </p>
                );
            }
        } else {
            itemArr.push(
                <FormGroup>
                    <ControlLabel
                        key = "schedule_dispensing_label"
                        className = "schedule_dispensing_label"
                    >
                        {langText.Body.ScheduleDispensing}
                    </ControlLabel>
                    {
                        state.schedule_dispensing_infos.length === 0 &&
                        <div className = "col-sm-12" key = "dispensing_static">
                            <FormControl.Static
                                className = "schedule_dispensing_static"
                            >
                                {langText.SelectOption.Unset}
                            </FormControl.Static>
                        </div>
                    }
                </FormGroup>
            );
        }
        if(state.modalType && state.schedule_dispensing_infos.length){
            itemArr.push(
                <CommonTable
                    talbeRef = {this.table}
                    manual = 'manual'
                    className = "schedule_dispensing_table"
                    columns = {column}
                    data = {state.schedule_dispensing_infos}
                    showPagination = {false}
                    pageSize = {state.schedule_dispensing_infos.length}
                    defaultSorted = {[{id:"dispensing_scheduled_no", desc:false}]}
                    onSortedChange = {(e) => {this.onSortScheduledDispensingInfoTable(e)}}
                />
            )
        }

        return itemArr;
    }

    // 応答待ち時間設定
    getTimeoutSettingInfoItem = () => {
        let {
            state,
            langText,
            onSelectChange,
            validationHandle,
            onIntChange,
            onKeyDownIntCheck,
            charaLimit,
            onRadioChange
        } = this.props;
        let itemArr = [];
        let default_timeout_sec = 300;

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "timeout_sec"
                    controlId = "timeout_sec"
                    label = {langText.Body.TimeoutSec}
                    type = "text"
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange={onIntChange("timeout_sec",charaLimit.External_timeout_sec)}
                    value = {state.timeout_sec}
                    placeholder={langText.Message.ExternalNumber_DefaultTimeoutSec}
                    validationState = {validationHandle("timeout_sec")}
                    />
            );
            if(state.timeout_sec > charaLimit.External_timeout_sec_max){
                itemArr.push(
                <p key="timeout_sec_over_max" className="text-danger">
                    {langText.Message.ExternalNumber_TimeoutSecOverMax}
                </p>
                );
            }
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "timeout_sec"
                    controlId = "timeout_sec"
                    label = {langText.Body.TimeoutSec}>
                    {state.timeout_sec && state.timeout_sec !== default_timeout_sec ? state.timeout_sec : langText.Message.ExternalNumber_DefaultTimeoutSecShow}
                </InlineForm.static>
            );
        }


        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "timeout-playback-form"
                    controlId = "timeout-playback-form"
                    >
                    {state.timeoutPlaybackSelect.map((row, index)=>(
                        <Radio
                            key = {index}
                            value = {row.value}
                            onChange = {e => {
                                onRadioChange(e, "timeout_playback_flag");
                            }}
                            checked = {row.value === state.timeout_playback_flag}
                        >
                            {row.label}
                        </Radio>))
                    }
                </InlineForm>
            );
            if(state.timeout_playback_flag === "Y"){
                itemArr.push(
                    <Row key = {"cm35_id_timeout_sound"}>
                        <Col xs = {6} sm = {6} md = {11} lg = {11}>
                            <InlineForm
                                controlId = "cm35_id_timeout_sound"
                                label = {langText.Body.TimeoutAudio}
                                type = "select"
                                onChange={e => onSelectChange(e, "cm35_id_timeout_sound")}
                                options = {state.timeoutSoundSelect}
                                value = {state.cm35_id_timeout_sound}
                                isClearable = {true}
                                placeholder = {state.buttondisabledArr.sound_load[0]
                                    ? <Glyphicon glyph="refresh" className="spinning"/>
                                    : langText.SelectOption.Placeholder_defaultSound}
                                isDisabled = {!state.buttondisabledArr.sound_id[0]}
                            />
                        </Col>
                        <Col xs = {6} sm = {6} md = {1} lg = {1}>
                            <AscAudio
                                type = "url"
                                data = {
                                        state.timeoutSoundSelect[state.timeoutSoundSelect.findIndex((v) => v.value === state.cm35_id_timeout_sound)] ?
                                        state.timeoutSoundSelect[state.timeoutSoundSelect.findIndex((v) => v.value === state.cm35_id_timeout_sound)].url
                                        :
                                        state.timeout_sound_url
                                        }
                                className = "set_audio_button"
                            />
                        </Col>
                    </Row>
                );
            }
        } else {
            let value = state.timeoutSoundSelect.find(row => row.value === state.cm35_id_timeout_sound);
            itemArr.push(
                <div key="timeout_sound_div">
                    <InlineForm.static
                        key = "timeout_sound"
                        controlId = "timeout_sec">
                        {state.timeoutPlaybackSelect.map((row, key) =>
                            row.value === state.timeout_playback_flag ? row.label : ""
                        )}
                    </InlineForm.static>
                </div>
            );
            if(state.timeout_playback_flag === "Y"){
                let timeout_audio = state.timeoutSoundSelect.find((row) =>row.value === state.cm35_id_timeout_sound);
                itemArr.push(
                    <div>
                        <InlineForm.static
                            key = "timeout_sound_name"
                            controlId = "timeout_sound_name"
                            label = {langText.Body.TimeoutAudio}>
                            {timeout_audio ? timeout_audio.label : langText.SelectOption.Placeholder_defaultSound}
                        </InlineForm.static>
                    </div>
                );
            }
        }

        return itemArr;
    }

    // リスト指定着信設定
    getListAppointSettingInfoItem = () => {
        let {
            state,
            langText,
            onSelectChange,
            validationHandle,
            onCheckBoxChange,
            onRadioChange,
            numberWithCommas,
            getSoundName
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                    <Checkbox
                        key = "list-appoint-inbound"
                        className = "list-appoint-inbound"
                        checked = {state.listAppointInboundFlag}
                        onChange = {e => onCheckBoxChange(e, "listAppointInboundFlag")}
                    >
                        {langText.Body.ListAppointInbound}
                    </Checkbox>
                
            );
            if(state.listAppointInboundFlag){
                itemArr.push(
                    <InlineForm
                        key = "list"
                        controlId = "list"
                        type = "select"
                        options = {state.listSelect}
                        value = {state.list_id}
                        label = {langText.Body.List}
                        validationState = {validationHandle("list_id")}
                        onChange = {e => onSelectChange(e, "list_id")}
                        isClearable = {true}
                        placeholder = {state.buttondisabledArr.list_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.list_id[0]}
                        />
                );
                itemArr.push(
                    <Row key="list-message">
                        <Col xs = {6} sm = {6} md = {9} lg = {9}>
                            {(state.list_id && !state.list_count) &&
                            <>
                                <div className="list-message">
                                    {langText.Message.ExternalNumber_ListMessage1}
                                </div>
                                <div className="list-message">
                                    {langText.Message.ExternalNumber_ListMessage2}
                                </div>
                            </>
                            }
                            
                        </Col>
                        <Col xs = {6} sm = {6} md = {3} lg = {3}>
                            {state.list_id &&
                                <p>{langText.Body.List} : {numberWithCommas(state.list_count)}{langText.Body.Count}</p>
                            }
                        </Col>
                    </Row>
                );
                
                itemArr.push(
                    <InlineForm
                        key = "inbound-action-form"
                        controlId = "inbound-action-form"
                        label = {langText.Body.OutOfListInboundAction}
                        validationState = {validationHandle("list_out_action")}
                    >
                        {state.inboundActionSelect.map((row, index)=>(
                            <Radio
                                key = {index}
                                value = {row.value}
                                validationState = {validationHandle("list_out_action")}
                                onChange = {e => onRadioChange(e, "list_out_action")}
                                checked = {row.value === state.list_out_action}
                            >
                                {row.label}
                            </Radio>))
                        }
                    </InlineForm>
                );
                if(state.list_out_action === "playback"){
                    itemArr.push(
                    <Row key = "list_sound_id">
                        <Col xs = {6} sm = {6} md = {11} lg = {11}>
                            <InlineForm
                                controlId = "list_sound_id"
                                label = {langText.Body.OutOfListInboundPlayBack}
                                type = "select"
                                onChange={e => onSelectChange(e, "list_sound_id")}
                                options = {state.listOutSoundSelect}
                                value = {state.list_sound_id}
                                isClearable = {true}
                                placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                                isDisabled = {!state.buttondisabledArr.sound_id[0]}
                            />
                        </Col>
                        <Col xs = {6} sm = {6} md = {1} lg = {1}>
                            <AscAudio
                                type = "url"
                                data = {
                                        state.listOutSoundSelect[state.listOutSoundSelect.findIndex((v) => v.value === state.list_sound_id)] ? 
                                        state.listOutSoundSelect[state.listOutSoundSelect.findIndex((v) => v.value === state.list_sound_id)].url
                                        :
                                        state.list_sound_url
                                    }
                                className = "set_audio_button"
                            />
                        </Col>
                    </Row>
                    );
                }
            }
        } else {
            let {list_out_action} = this.props.state;
            if(list_out_action === "playback") list_out_action = langText.Body.PlayVoice;
            else if(list_out_action === "busy") list_out_action = langText.Body.Busy;

            if(state.listAppointInboundFlag){
                itemArr.push(
                    <InlineForm.static
                        key = "list"
                        controlId = "list"
                        label = {langText.Body.List}>
                        {state.list_name}
                    </InlineForm.static>
                );
                itemArr.push(
                    <InlineForm.static
                        key = "list_out_action"
                        controlId = "list_out_action"
                        label = {langText.Body.OutOfListInboundAction}>
                        {list_out_action}
                    </InlineForm.static>
                );
                if(list_out_action ===　langText.Body.PlayVoice){
                    let list_sound_name = getSoundName(state.list_sound_id);
                    itemArr.push(
                        <InlineForm.static
                            key = "list_sound_id"
                            controlId = "list_sound_id"
                            label = {langText.Body.OutOfListInboundPlayBack}
                        >
                            {list_sound_name ? list_sound_name : langText.SelectOption.Placeholder_defaultSound}
                        </InlineForm.static>
                    );
                }
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "list_appoint-unset"
                        controlId = "list_appoint-unset">
                        {langText.SelectOption.Unset}
                    </InlineForm.static>
                );
            }
        }
        return itemArr;
    }

    // 非通知番号からの着信設定
    getAnonymousSettingInfoItem = () => {
        let {
            state,
            langText,
            onSelectChange,
            onRadioChange,
            getSoundName
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "anonymous_action_form"
                    controlId = "anonymous_action_form"
                    label = {langText.Body.AnonymousAction}
                >
                    {state.anonymousActionSelect.map((row, index)=>(
                        <Radio
                            key = {index}
                            value = {row.value}
                            onChange = {e => onRadioChange(e, "anonymous_action")}
                            checked = {row.value === state.anonymous_action}
                        >
                            {row.label}
                        </Radio>))
                    }
                </InlineForm>
            );
            if(state.anonymous_action === "playback"){
                itemArr.push(
                    <Row key = "anonymous_sound_id">
                        <Col xs = {6} sm = {6} md = {11} lg = {11}>
                            <InlineForm
                                controlId = "anonymous_sound_id"
                                label = {langText.Body.AnonymousSoundPlayBack}
                                type = "select"
                                onChange={e => onSelectChange(e, "anonymous_sound_id")}
                                options = {state.anonymousSoundSelect}
                                value = {state.anonymous_sound_id}
                                isClearable = {true}
                                placeholder = {state.buttondisabledArr.sound_load[0]
                                    ? <Glyphicon glyph="refresh" className="spinning"/>
                                    : langText.SelectOption.Placeholder_defaultSound}
                                isDisabled = {!state.buttondisabledArr.sound_id[0]}
                            />
                        </Col>
                        <Col xs = {6} sm = {6} md = {1} lg = {1}>
                            <AscAudio
                                type = "url"
                                data = {
                                    state.anonymousSoundSelect[state.anonymousSoundSelect.findIndex(
                                        (v) => v.value === state.anonymous_sound_id)]
                                        ? state.anonymousSoundSelect[state.anonymousSoundSelect.findIndex(
                                            (v) => v.value === state.anonymous_sound_id)].url
                                        : state.anonymous_sound_url
                                }
                                className = "set_audio_button"
                            />
                        </Col>
                    </Row>
                );
            }
        } else if (state.modalType === "delete" || state.modalType === "read") {
            let {anonymous_action} = this.props.state;
            let anonymousRow = state.anonymousActionSelect.find(row => anonymous_action === row.value);
            if(anonymous_action === "playback") anonymous_action = langText.Body.PlayVoice;
            itemArr.push(
                <InlineForm.static
                    key = "anonymous_action"
                    controlId = "anonymous_action"
                    label = {langText.Body.AnonymousAction}>
                    {anonymousRow ? anonymousRow.label : ""}
                </InlineForm.static>
            );
            if(anonymous_action === langText.Body.PlayVoice){
                itemArr.push(
                    <InlineForm.static
                        key = "anonymous_sound_id"
                        controlId = "anonymous_sound_id"
                        label = {langText.Body.AnonymousSoundPlayBack}
                    >
                        {state.anonymous_sound_id
                            ? getSoundName(state.anonymous_sound_id)
                            : langText.Message.DefaultAudioUse}
                    </InlineForm.static>
                );
            }
        }
        return itemArr;
    }

    // 全内線未接続時の設定
    getUnconnectedSettingInfoItem = () => {
        let {
            state,
            langText,
            onSelectChange,
            validationHandle,
            onRadioChange,
            getSoundName
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "update")) {
                itemArr.push(
                    <InlineForm
                        key = "unconnected_action_form"
                        controlId = "unconnected_action_form"
                        label = {langText.Body.UnconnectedAction}
                        validationState = {validationHandle("unconnected_action")}
                    >
                        {state.UnconnectedActionSelect.map((row, index)=>(
                            <Radio
                                key = {index}
                                value = {row.value}
                                validationState = {validationHandle("unconnected_action")}
                                onChange = {e => onRadioChange(e, "unconnected_action")}
                                checked = {row.value === state.unconnected_action}
                            >
                                {row.label}
                            </Radio>))
                        }
                    </InlineForm>
                );
                if(state.unconnected_action === "playback"){
                    itemArr.push(
                    <Row key = "unconnected_sound_id">
                        <Col xs = {6} sm = {6} md = {11} lg = {11}>
                            <InlineForm
                                controlId = "unconnected_sound_id"
                                label = {langText.Body.UnconnectedSoundPlayBack}
                                type = "select"
                                onChange={e => onSelectChange(e, "unconnected_sound_id")}
                                options = {state.UnconnectedSoundSelect}
                                value = {state.unconnected_sound_id}
                                isClearable = {true}
                                placeholder = {state.buttondisabledArr.sound_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder_defaultSound}
                                isDisabled = {!state.buttondisabledArr.sound_id[0]}
                            />
                        </Col>
                        <Col xs = {6} sm = {6} md = {1} lg = {1}>
                            <AscAudio
                                type = "url"
                                data = {
                                        state.UnconnectedSoundSelect[state.UnconnectedSoundSelect.findIndex((v) => v.value === state.unconnected_sound_id)] ?
                                        state.UnconnectedSoundSelect[state.UnconnectedSoundSelect.findIndex((v) => v.value === state.unconnected_sound_id)].url
                                        :
                                        state.extension_unconnected_sound_url
                                    }
                                className = "set_audio_button"
                            />
                        </Col>
                    </Row>
                    );
            }
        } else if (state.modalType === "delete" || state.modalType === "read") {
            let unconnectedRow = state.UnconnectedActionSelect.find(row => state.unconnected_action === row.value);
            let {unconnected_action} = this.props.state;
            if(unconnected_action == "playback") unconnected_action = langText.Body.PlayVoice;
            itemArr.push(
                <InlineForm.static
                    key = "unconnected_action"
                    controlId = "unconnected_action"
                    label = {unconnectedRow.value !== 'unset' ? langText.Body.UnconnectedAction : ""}>
                    {unconnectedRow ? unconnectedRow.label : ""}
                </InlineForm.static>
            );
            if(unconnected_action ==　langText.Body.PlayVoice){
                let unconnected_sound = getSoundName(state.unconnected_sound_id);
                itemArr.push(
                    <InlineForm.static
                        key = "unconnected_sound_id"
                        controlId = "unconnected_sound_id"
                        label = {langText.Body.UnconnectedSoundPlayBack}
                    >
                        {unconnected_sound ? unconnected_sound : langText.SelectOption.Placeholder_defaultSound}
                    </InlineForm.static>
                );
            }
        }
        return itemArr;
    }

    // 営業カレンダー設定
    getBusinessCalendarSettingInfoItem = () => {
        let {
            state,
            langText,
            onSelectChange,
            validationHandle,
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "update")) {
            itemArr.push(
                <InlineForm
                    key = "business_calendar_id"
                    controlId = "business_calendar_id"
                    label = {langText.Body.BusinessCalendar}
                    type = "select"
                    onChange = {e => onSelectChange(e, "business_calendar_id")}
                    options = {state.businessCalendarSelect}
                    value = {state.business_calendar_id}
                    isClearable = {true}
                    validationState = {validationHandle("business_calendar_id")}
                    placeholder = {state.buttondisabledArr.business_calendar_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.business_calendar_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "business_calendar_id"
                    controlId = "business_calendar_id"
                    label = {state.business_calendar_name ? langText.Body.BusinessCalendar : ""}>
                    {state.business_calendar_name ? state.business_calendar_name : langText.SelectOption.Unset}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    // 分析オプション設定
    // 会社の追加オプション「通話品質分析」がONの場合のみ表示
    getCallAnalysisInfoItem = () => {
        let {
            state,
            langText,
            onCheckBoxChange,
            getScopeGreaterEqual,
            onMultiSelectChange
        } = this.props;
        let itemArr = [];

        let itemBody = [];
        if ((state.modalType === "insert" || state.modalType === "update") && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) {
            itemBody.push(
                <InlineForm.static
                    key = "callAnalysis-flag-form"
                    controlId = "callAnalysis-flag-form"
                    label = {langText.Body.CallAnalysisTitle}
                >
                    <Checkbox
                        key = "callAnalysis-flag"
                        className = "callAnalysis-flag"
                        checked = {state.callAnalysisFlag}
                        onChange = {e => onCheckBoxChange(e, "callAnalysisFlag")}
                    >
                        {langText.Body.CallAnalysisCheckBox}
                    </Checkbox>
                    {
                        state.callAnalysisFlag && state.addingfastOption && (
                            <Checkbox
                                key = "callAnalysis-fast-option-flag"
                                checked = {state.fastOptionFlag}
                                onChange = {e => onCheckBoxChange(e, "fastOptionFlag")}
                            >
                                {langText.Body.QuickMode}
                            </Checkbox>
                        )
                    }
                </InlineForm.static>
            );
            // 応対品質分析
            // 会社の追加オプション「通話評価」がONかつ「通話品質分析」対象外線番号の場合のみ表示
            if (state.addingOptionCallEval && state.callAnalysisFlag) {
                itemBody.push(
                    <InlineForm.static
                        key = "callEval-flag-form"
                        controlId = "callEval-flag-form"
                        label = {langText.Body.CallEvalTitle}
                    >
                        <Checkbox
                            key = "callEval-flag"
                            className = "callEval-flag"
                            checked = {state.callEvalFlag}
                            onChange = {e => onCheckBoxChange(e, "callEvalFlag")}
                        >
                            {langText.Body.CallEvalCheckBox}
                        </Checkbox>
                    </InlineForm.static>
                );
            }
            // 会社の追加オプション「通話品質分析」がONかつ「通話品質分析」対象外線番号の場合のみ表示
            if (state.addingOptionCallAnalysis && state.callAnalysisFlag) {
                // キーワードグループ
                itemBody.push(
                    <InlineForm
                        key = "keyword_groups"
                        controlId = "keyword_groups"
                        label = {langText.Body.KeywordGroup}
                        type = "select"
                        onChange = {e => onMultiSelectChange(e, "keyword_groups")}
                        options = {state.keywordGroupSelect}
                        value = {state.keyword_groups}
                        isClearable = {true}
                        isMulti = {true}
                        closeMenuOnSelect={false}
                        placeholder = {state.buttondisabledArr.keyword_groups_load[0] 
                            ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.keyword_groups[0]}
                    />
                );
            }
        } else {
            itemBody.push(
                <InlineForm.static
                    key = "callAnalysis-flag"
                    controlId = "callAnalysis-flag"
                    label = {langText.Body.CallAnalysisTitle}
                >
                    <p>
                        {state.callAnalysisFlag
                            ? langText.SelectOption.Use
                            : langText.SelectOption.Disuse}
                    </p>
                    {
                        state.callAnalysisFlag && state.addingfastOption && (
                            <>
                                {`${langText.Body.QuickMode}${state.fastOptionFlag
                                    ? langText.SelectOption.Use
                                    : langText.SelectOption.Disuse}`}
                            </>
                        )
                    }
                </InlineForm.static>
            );
            // 応対品質分析
            // 会社の追加オプション「通話評価」がONかつ「通話品質分析」対象外線番号の場合のみ表示
            if (state.addingOptionCallEval && state.callAnalysisFlag) {
                itemBody.push(
                    <InlineForm.static
                        key = "callEval-flag"
                        controlId = "callEval-flag"
                        label = {langText.Body.CallEvalTitle}
                    >
                        {state.callEvalFlag
                            ? langText.SelectOption.Use
                            : langText.SelectOption.Disuse}
                    </InlineForm.static>
                );
            } 
            // 会社の追加オプション「通話品質分析」がONかつ「通話品質分析」対象外線番号の場合のみ表示
            if (state.addingOptionCallAnalysis && state.callAnalysisFlag) {
                // キーワードグループ
                let keywordGroupsNames = [];
                if (state.keyword_groups && Array.isArray(state.keyword_groups)) {
                    state.keyword_groups.forEach((id, key) => {
                        const keywordGroupsInfo = state.keywordGroupSelect.find(option => option.value === parseInt(id));
                        if (keywordGroupsInfo) {
                            if (keywordGroupsNames.length) keywordGroupsNames.push(<br key={"keyword_group_"+ key} />);
                            keywordGroupsNames.push(<span key={key}>{keywordGroupsInfo.label}</span>);
                        }
                    });
                }

                itemBody.push(
                    <InlineForm.static
                        key = "keyword_groups"
                        controlId = "keyword_groups"
                        label = {langText.Body.KeywordGroup}>
                        {keywordGroupsNames.length > 0 ? keywordGroupsNames : langText.SelectOption.Unset}
                    </InlineForm.static>
                );
            }
        }
        itemArr.push(
            <Panel bsStyle="info">
                <Panel.Heading>
                    <Panel.Title>
                        <SetDataWithSpeechBubble
                            displayData={langText.Body.AnalysisSettingTitle}
                            speechBubbleData={langText.Body.CallAnalysisDescription}
                            infoIconDisplayFlag={true}
                            infoIconType={faIcon.faInfoCircle}
                            infoIconClassName="modal-info-icon"
                            infoIconColor="silver"
                            infoIconSize="lg"
                        />
                    </Panel.Title>
                </Panel.Heading>
                <Panel.Body>
                    {itemBody}
                </Panel.Body>
            </Panel>
        );
        return itemArr;
    }
    
    /**
     * スケジュール内線グループ、スケジュール着信コントロール追加モーダルショー
     * @param {String} modalType
     * @param {Object} param
     */
    modalShow = (modalType, param) => event => {
        event.preventDefault();
        let setData = {};
        switch (modalType) {
            // スケジュール内線グループ
            case "insert":
                setData = {
                    scheduled_inbound_group_no: 0,
                    start_datetime: moment().format("YYYYMMDD"),
                    start_time_hour: "",
                    start_time_minutes: "",
                    end_datetime: moment().format("YYYYMMDD"),
                    end_time_hour: "",
                    end_time_minutes: "",
                    schedule_extenxion_group:[],
                    inboundGroupSelect: this.props.state.inboundGroupSelect,
                    inboundGroupcount: 0,
                    inbound_group_base: this.props.state.inbound_group_base,
                    inbound_group_cm51ids: this.props.state.inbound_group_cm51ids || [],
                    validate_msg: "",
                    show: true,
                    modalType
                };
                this.setState(setData);
                break;

            // スケジュール着信コントロール
            case "dispensing_insert":
                setData = {
                    dispensing_scheduled_no: 0,
                    dispensing_start_datetime: moment().format("YYYYMMDD"),
                    dispensing_start_time_hour: "",
                    dispensing_start_time_minutes: "",
                    dispensing_end_datetime: moment().format("YYYYMMDD"),
                    dispensing_end_time_hour: "",
                    dispensing_end_time_minutes: "",
                    dispensing_schedule: [],
                    dispensing_schedule_time: null,
                    dispensing_schedule_count: "",
                    validate_msg: "",
                    dispensingScheduleTimeValidationFlag: false,
                    dispensingScheduleCountValidationFlag: false,
                    show: true,
                    modalType
                };
                this.setState(setData);
                break;

            // スケジュール内線グループ
            case "update":
                setData = {
                    scheduled_inbound_group_no: param.scheduled_inbound_group_no,
                    start_datetime: moment(param.original_start_datetime).format("YYYYMMDD"),
                    start_time_hour: moment(param.original_start_datetime).format("HH"),
                    start_time_minutes: moment(param.original_start_datetime).format("mm"),
                    end_datetime: moment(param.original_end_datetime).format("YYYYMMDD"),
                    end_time_hour: moment(param.original_end_datetime).format("HH"),
                    end_time_minutes: moment(param.original_end_datetime).format("mm"),
                    schedule_extenxion_group: param.cm28_inbound_group_infos.map(row => {return row.cm28_id}),
                    inboundGroupSelect: this.props.state.inboundGroupSelect,
                    inboundGroupcount: 0,
                    inbound_group_base: this.props.state.inbound_group_base,
                    inbound_group_cm51ids: this.props.state.inbound_group_cm51ids || [],
                    validate_msg: "",
                    show: true,
                    date_validation: true,
                    modalType
                };
                this.setState(setData);
                break;

            // スケジュール着信コントロール
            case "dispensing_update":
                setData = {
                    dispensing_scheduled_no: param.dispensing_scheduled_no,
                    dispensing_start_datetime: moment(param.original_start_datetime).format("YYYYMMDD"),
                    dispensing_start_time_hour: moment(param.original_start_datetime).format("HH"),
                    dispensing_start_time_minutes: moment(param.original_start_datetime).format("mm"),
                    dispensing_end_datetime: moment(param.original_end_datetime).format("YYYYMMDD"),
                    dispensing_end_time_hour: moment(param.original_end_datetime).format("HH"),
                    dispensing_end_time_minutes: moment(param.original_end_datetime).format("mm"),
                    dispensing_schedule: param.dispensing_schedule,
                    dispensing_schedule_time: param.secCnt,
                    dispensing_schedule_count: param.inboundCnt,
                    validate_msg: "",
                    show: true,
                    dispensing_date_validation: true,
                    dispensingScheduleTimeValidationFlag: false,
                    dispensingScheduleCountValidationFlag: false,
                    modalType
                };
                this.setState(setData);
                break;

            // スケジュール内線グループ
            case "copy":
                setData = {
                    scheduled_inbound_group_no: 0,
                    start_datetime: moment().format("YYYYMMDD"),
                    start_time_hour: "",
                    start_time_minutes: "",
                    end_datetime: moment().format("YYYYMMDD"),
                    end_time_hour: "",
                    end_time_minutes: "",
                    schedule_extenxion_group: param.cm28_inbound_group_infos.map(row => {return row.cm28_id}),
                    inboundGroupSelect: this.props.state.inboundGroupSelect,
                    inboundGroupcount: 0,
                    inbound_group_base: this.props.state.inbound_group_base,
                    inbound_group_cm51ids: this.props.state.inbound_group_cm51ids || [],
                    validate_msg: "",
                    show: true,
                    modalType
                };
                this.setState(setData);
                break;

            // スケジュール着信コントロール
            case "dispensing_copy":
                setData = {
                    dispensing_scheduled_no: 0,
                    dispensing_start_datetime: moment().format("YYYYMMDD"),
                    dispensing_start_time_hour: "",
                    dispensing_start_time_minutes: "",
                    dispensing_end_datetime: moment().format("YYYYMMDD"),
                    dispensing_end_time_hour: "",
                    dispensing_end_time_minutes: "",
                    dispensing_schedule: [],
                    dispensing_schedule_time: param.secCnt,
                    dispensing_schedule_count: param.inboundCnt,
                    validate_msg: "",
                    dispensingScheduleTimeValidationFlag: false,
                    dispensingScheduleCountValidationFlag: false,
                    show: true,
                    modalType
                };
                this.setState(setData);
                break;
            default:
                break;
        }
    }

    /**
     * スケジュール内線グループ、スケジュール着信コントロールのモーダルバリデーション関数
     * @param {String} param
     */
    validationHandle = (param) => {
        let nowState = null;
        let {
            start_datetime,
            end_datetime,
            schedule_extenxion_group,
            date_validation,
            start_time_hour,
            start_time_minutes,
            end_time_hour,
            end_time_minutes,
            dispensing_flag,
            dispensing_start_datetime,
            dispensing_start_time_hour,
            dispensing_start_time_minutes,
            dispensing_end_datetime,
            dispensing_end_time_hour,
            dispensing_end_time_minutes,
            dispensing_schedule_time,
            dispensing_schedule_count,
            dispensing_date_validation
        } = this.state;

        let {
            channel_number
        } = this.props.state;

        switch (param) {
            case 'start_datetime':
            case 'end_datetime':
            case 'dispensing_start_datetime':
            case 'dispensing_end_datetime':
                nowState = this.state[param]
                    ? null
                    : 'error'
                break;
            case 'start_time_hour':
            case 'start_time_minutes':
            case 'end_time_hour':
            case 'end_time_minutes':
            case 'dispensing_start_time_hour':
            case 'dispensing_start_time_minutes':
            case 'dispensing_end_time_hour':
            case 'dispensing_end_time_minutes':
                nowState = this.state[param]
                    ? null
                    : this.props.langText.Message.Validation_Select_input
                break;
            case 'schedule_extenxion_group':
                nowState = schedule_extenxion_group.length
                    ? null
                    : 'error'
                break;
            case 'dispensing_schedule_time':
                nowState = !this.state[param] && this.state[param] !== 0 || (
                    this.state[param] > 0 && this.state[param] <= 60)
                    ? null
                    : 'error'
                break;
            case 'dispensing_schedule_count':
                nowState = (!channel_number && this.state[param] && this.state[param] > 0) || (
                    this.state[param] && this.state[param] <= channel_number)
                    ? null
                    : 'error'
                break;
            case 'insert':
            case 'update':
            case 'copy':
                nowState = (
                    start_datetime &&
                    end_datetime &&
                    start_time_hour &&
                    start_time_minutes &&
                    end_time_hour &&
                    end_time_minutes &&
                    schedule_extenxion_group.length &&
                    date_validation
                );
                break;
            case "dispensing_insert":
            case 'dispensing_update':
            case 'dispensing_copy':
                nowState = (
                    dispensing_start_datetime &&
                    dispensing_end_datetime &&
                    dispensing_start_time_hour &&
                    dispensing_start_time_minutes &&
                    dispensing_end_time_hour &&
                    dispensing_end_time_minutes &&
                    (dispensing_schedule_time === null || dispensing_schedule_time === "" ||(dispensing_schedule_time > 0 && dispensing_schedule_time <= 60)) &&
                    ((!channel_number && dispensing_schedule_count && dispensing_schedule_count > 0) ||
                    (dispensing_schedule_count && dispensing_schedule_count <= channel_number)) &&
                    dispensing_date_validation
                );
                break;
            default:
                nowState = null;
                break;
        }

        return nowState;
    }

    /**
     * スケジュール内線グループ、スケジュール着信コントロールのモーダル画面でボタン押下時作動
     * @param {String} modalType
     */
    onClickHandle = modalType => event => {
        switch (modalType) {
            case "insert":
            case "update":
            case "copy":
                //スケジュール追加時モーダル表示・サーバーに送信するデータセット
                let group_info = [...this.props.state.schedule_extension_group_infos];
                let {
                    inboundGroupSelect,
                    start_datetime,
                    start_time_hour,
                    start_time_minutes,
                    end_datetime,
                    end_time_hour,
                    end_time_minutes,
                    inboundGroupcount
                } = this.state;
                let {
                    last_duplicate_id
                } = this.props.state;

                let label = '';
                let cm28_info_arr = this.state.schedule_extenxion_group.map(row => {
                    inboundGroupSelect.forEach(elem =>{
                        if(row === elem.value){
                            label = elem.label;
                        }
                    });
                    return {cm28_id: row, inbound_group_name: label}
                });
                last_duplicate_id = last_duplicate_id + 1

                if (modalType === "update") {
                    let targetScheduleInfo = group_info.find((row) => row.scheduled_inbound_group_no === this.state.scheduled_inbound_group_no);
                    targetScheduleInfo.original_start_datetime = moment(start_datetime).add(start_time_hour, 'hours').add(start_time_minutes, 'minutes').format();
                    targetScheduleInfo.original_end_datetime = moment(end_datetime).add(end_time_hour, 'hours').add(end_time_minutes, 'minutes').format();
                    targetScheduleInfo.cm28_inbound_group_infos = cm28_info_arr;
                } else {
                    let add_schedule_info = {
                        duplicate_id : last_duplicate_id,
                        original_start_datetime: moment(start_datetime).add(start_time_hour, 'hours').add(start_time_minutes, 'minutes').format(),
                        original_end_datetime: moment(end_datetime).add(end_time_hour, 'hours').add(end_time_minutes, 'minutes').format(),
                        cm28_inbound_group_infos: cm28_info_arr,
                        exten_count: inboundGroupcount
                    };
                    group_info.push(add_schedule_info);
                }

                // 開始日時の昇順でソート
                group_info.sort((a, b) => {
                    if (moment(a.original_start_datetime) > moment(b.original_start_datetime)) return 1;
                    if (moment(a.original_start_datetime) < moment(b.original_start_datetime)) return -1;
                })
                // Noの調整
                let no = 1;
                group_info.forEach(row => {
                    row.scheduled_inbound_group_no = no;
                    no ++;
                })
                this.props.propSetState({
                    schedule_extension_group_infos: group_info,
                    last_duplicate_id
                });
                this.setState({
                    show: false,
                });
                break;

                case "dispensing_insert":
                case "dispensing_update":
                case "dispensing_copy":
                    //スケジュール追加時モーダル表示・サーバーに送信するデータセット
                    let dispensing_info = [...this.props.state.schedule_dispensing_infos];
                    let {
                        dispensing_start_datetime,
                        dispensing_start_time_hour,
                        dispensing_start_time_minutes,
                        dispensing_end_datetime,
                        dispensing_end_time_hour,
                        dispensing_end_time_minutes,
                        dispensing_schedule_time,
                        dispensing_schedule_count,
                        dispensing_count
                    } = this.state;
                    let {
                        dispensing_last_duplicate_id
                    } = this.props.state;

                    dispensing_last_duplicate_id = dispensing_last_duplicate_id + 1
    
                    if (modalType === "dispensing_update") {
                        let dispensingTargetScheduleInfo = dispensing_info.find((row) => row.dispensing_scheduled_no === this.state.dispensing_scheduled_no);
                        dispensingTargetScheduleInfo.original_start_datetime = moment(dispensing_start_datetime).add(dispensing_start_time_hour, 'hours').add(dispensing_start_time_minutes, 'minutes').format();
                        dispensingTargetScheduleInfo.original_end_datetime = moment(dispensing_end_datetime).add(dispensing_end_time_hour, 'hours').add(dispensing_end_time_minutes, 'minutes').format();
                        dispensingTargetScheduleInfo.secCnt = dispensing_schedule_time ? dispensing_schedule_time : 60;
                        dispensingTargetScheduleInfo.inboundCnt = dispensing_schedule_count;
                    } else {
                        let add_schedule_dispensing_infos = {
                            duplicate_id : dispensing_last_duplicate_id,
                            original_start_datetime: moment(dispensing_start_datetime).add(dispensing_start_time_hour, 'hours').add(dispensing_start_time_minutes, 'minutes').format(),
                            original_end_datetime: moment(dispensing_end_datetime).add(dispensing_end_time_hour, 'hours').add(dispensing_end_time_minutes, 'minutes').format(),
                            secCnt: dispensing_schedule_time ? dispensing_schedule_time : 60,
                            inboundCnt: dispensing_schedule_count
                        };
                        dispensing_info.push(add_schedule_dispensing_infos);
                    }
    
                    // 開始日時の昇順でソート
                    dispensing_info.sort((a, b) => {
                        if (moment(a.original_start_datetime) > moment(b.original_start_datetime)) return 1;
                        if (moment(a.original_start_datetime) < moment(b.original_start_datetime)) return -1;
                    })
                    // Noの調整
                    let dispensing_no = 1;
                    dispensing_info.forEach(row => {
                        row.dispensing_scheduled_no = dispensing_no;
                        dispensing_no ++;
                    })
                    this.props.propSetState({
                        schedule_dispensing_infos: dispensing_info,
                        dispensing_last_duplicate_id
                    });
                    this.setState({
                        show: false,
                    });
                    break;

            default:
                alert(this.getErrorString({ code: "modal" }));
            
            
        }
    }

    onSortScheduledInboundGroupInfoTable = (e) => {
        let key = e[0].id;
        let isDesc = e[0].desc;
        let sortedScheduledInboundGroupInfos = this.props.state.schedule_extension_group_infos.concat();
        sortedScheduledInboundGroupInfos.sort((a, b) => {
            if (isDesc) {
                if (a[key] > b[key]) return -1;
                if (a[key] < b[key]) return 1;
            } else {
                if (a[key] > b[key]) return 1;
                if (a[key] < b[key]) return -1;
            }
        })
        this.props.propSetState({
            schedule_extension_group_infos: sortedScheduledInboundGroupInfos
        })
    }

    onSortScheduledDispensingInfoTable = (e) => {
        let key = e[0].id;
        let isDesc = e[0].desc;
        let sortedScheduledDispensingInfos = this.props.state.schedule_dispensing_infos.concat();
        sortedScheduledDispensingInfos.sort((a, b) => {
            if (isDesc) {
                if (a[key] > b[key]) return -1;
                if (a[key] < b[key]) return 1;
            } else {
                if (a[key] > b[key]) return 1;
                if (a[key] < b[key]) return -1;
            }
        })
        this.props.propSetState({
            schedule_dispensing_infos: sortedScheduledDispensingInfos
        })
    }

    /**
     * スケジュール内線グループ削除関数
     * モーダルのテーブル内の削除ボタン押下時作動
     * @param {Object} param
     */
    deleteScheduleExtension = (index) => {
        if(window.confirm(this.props.langText.Message.ExternalNumber_ScheduleExtensionDelete)){
            let group_info = [...this.props.state.schedule_extension_group_infos];
            let delete_group_infos = [...this.props.state.delete_group_infos];
            delete_group_infos.push(group_info[index]);
            //モーダルの表示上、該当スケジュールの行削除
            group_info.splice(index, 1);
            //scheduled_inbound_group_noの調整
            let no = 1;
            group_info.forEach(row => {
                row.scheduled_inbound_group_no = no;
                no ++;
            })
            this.props.propSetState({
                schedule_extension_group_infos: group_info,
                delete_group_infos
            });
        } else {
            return;
        }
    }

    /**
     * スケジュール着信コントロール削除関数
     * モーダルのテーブル内の削除ボタン押下時作動
     * @param {Object} param
     */
    deleteScheduleDispensing = (index) => {
        if(window.confirm(this.props.langText.Message.ExternalNumber_ScheduleDispensingDelete)){
            let dispensing_info = [...this.props.state.schedule_dispensing_infos];
            let delete_dispensing_infos = [...this.props.state.delete_dispensing_infos];
            delete_dispensing_infos.push(dispensing_info[index]);
            //モーダルの表示上、該当スケジュールの行削除
            dispensing_info.splice(index, 1);
            //scheduled_inbound_group_noの調整
            let no = 1;
            dispensing_info.forEach(row => {
                row.dispensing_scheduled_no = no;
                no ++;
            })
            this.props.propSetState({
                schedule_dispensing_infos: dispensing_info,
                delete_dispensing_infos
            });
        } else {
            return;
        }
    }

    /**
     * スケジュール内線グループモーダルで日時変更時作動
     * @param {String} param
     * @param {*} event
     */
    onDateChangeHandle = (event, param) => {
        let stateSetValue = "";
        switch (param) {
            case "start_datetime":
            case "end_datetime":
                stateSetValue = event ? event.format("YYYYMMDD") : null;
                break;
            default:
                stateSetValue = event.value;
                break;
        }
        this.setState({
            [param]: stateSetValue
        },()=>{
            let date_validation = this.checkDateValidate();
            this.setState({date_validation});
        });
    }

    checkDateValidate = () => {
        let {
            schedule_extension_group_infos
        } = this.props.state;

        if (this.state.start_datetime === null) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionStartDateRequiredError"});
            return false;
        } else if(this.state.end_datetime === null) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionEndDateRequiredEError"});
            return false;
        }

        let start_datetime = moment(this.state.start_datetime).add(this.state.start_time_hour, 'hours').add(this.state.start_time_minutes, 'minutes').format("YYYY-MM-DD HH:mm:00");
        let end_datetime = moment(this.state.end_datetime).add(this.state.end_time_hour, 'hours').add(this.state.end_time_minutes, 'minutes').format("YYYY-MM-DD HH:mm:00");

        // 開始日もしくは終了日に過去の日付が手動入力された場合、
        // 当日日付に変更
        if (moment(start_datetime).format("YYYYMMDD") < moment().format("YYYYMMDD")) {
            this.setState({
                start_datetime: moment().format("YYYYMMDD")
            })
            start_datetime = moment().startOf('day').add(this.state.start_time_hour, 'hours').add(this.state.start_time_minutes, 'minutes').format("YYYY-MM-DD HH:mm:00");
        } else if (moment(end_datetime).format("YYYYMMDD") < moment().format("YYYYMMDD")) {
            this.setState({
                end_datetime: moment().format("YYYYMMDD")
            })
            end_datetime = moment().startOf('day').add(this.state.end_time_hour, 'hours').add(this.state.end_time_minutes, 'minutes').format("YYYY-MM-DD HH:mm:00");
        }

        // 開始日が終了日より大きい場合
        // または終了日＞開始日+7日の場合
        // 終了日を開始日と同じ値に設定
        if
            (
                (moment(start_datetime).format("YYYYMMDD") > moment(end_datetime).format("YYYYMMDD")) ||
                (moment(end_datetime).format("YYYYMMDD") > moment(start_datetime).add(7, 'days').format("YYYYMMDD"))
            )
        {
            this.setState({
                end_datetime: moment(start_datetime).format("YYYYMMDD")
            })
            end_datetime = moment().startOf('day').add(this.state.end_time_hour, 'hours').add(this.state.end_time_minutes, 'minutes').format("YYYY-MM-DD HH:mm:00");
        }

        // 開始日時 > 終了日時になっていないかのチェック
        let isFromAfterToFlag = false;
        if (moment(this.state.start_datetime).format("YYYYMMDD") === moment(this.state.end_datetime).format("YYYYMMDD")) {
            if (
                (
                    this.state.start_time_hour &&
                    this.state.end_time_hour
                ) &&
                this.state.start_time_hour > this.state.end_time_hour
            ) {
                isFromAfterToFlag = true;
            } else if (
                (
                    this.state.start_time_hour &&
                    this.state.end_time_hour &&
                    this.state.start_time_minutes &&
                    this.state.end_time_minutes
                ) &&
                this.state.start_time_hour === this.state.end_time_hour && (this.state.start_time_minutes >= this.state.end_time_minutes)
            ) {
                isFromAfterToFlag = true;
            }
        }

        if (isFromAfterToFlag) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionFromToError"});
            return false;
        }

        // 終了日時 < 現在日時になっていないかのチェック
        let isEndTimeBeforeCurrentFlag = false;
        if (
            (
                this.state.start_time_hour &&
                this.state.end_time_hour &&
                this.state.start_time_minutes &&
                this.state.end_time_minutes
            ) &&
            moment(end_datetime) < moment()
        ) {
            isEndTimeBeforeCurrentFlag = true;
        }

        if (isEndTimeBeforeCurrentFlag) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionPastDateError"});
            return false;
        }

        let isDateRangeDuplicated = false;
        if (
            schedule_extension_group_infos.length &&
            (
                this.state.start_time_hour &&
                this.state.end_time_hour &&
                this.state.start_time_minutes &&
                this.state.end_time_minutes
            )
        ){
            // 日時期間重複検証
            isDateRangeDuplicated = schedule_extension_group_infos.some(row => {
                return (
                    // 既存のスケジュール内線を編集する場合、編集対象のスケジュール自体はチェックから除外するため、Noを比較
                    row.scheduled_inbound_group_no !== this.state.scheduled_inbound_group_no &&
                    (
                        (
                            moment(start_datetime) >= moment(row.original_start_datetime) &&
                            moment(start_datetime) <= moment(row.original_end_datetime)
                        )
                            ||
                        (
                            moment(row.original_start_datetime) >= moment(start_datetime) &&
                            moment(row.original_start_datetime) <= moment(end_datetime)
                        )
                    )
                )
            });
        }

        if (isDateRangeDuplicated) {
            this.setState({validate_msg: "ExternalNumber_ExistValidation"});
            return false;
        }

        return true;
    }


    /**
     * スケジュール着信コントロールモーダルで日時変更時作動
     * @param {String} param
     * @param {*} event
     */
    onDateChangeHandleDispensing = (event, param) => {
        let stateSetValue = "";
        switch (param) {
            case "dispensing_start_datetime":
            case "dispensing_end_datetime":
                stateSetValue = event ? event.format("YYYYMMDD") : null;
                break;
            default:
                stateSetValue = event.value;
                break;
        }
        this.setState({
            [param]: stateSetValue
        },()=>{
            let dispensing_date_validation = this.checkDateValidateDispensing();
            this.setState({dispensing_date_validation});
        });
    }

    checkDateValidateDispensing = () => {
        let {
            schedule_dispensing_infos
        } = this.props.state;

        if (this.state.dispensing_start_datetime === null) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionStartDateRequiredError"});
            return false;
        } else if(this.state.dispensing_end_datetime === null) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionEndDateRequiredEError"});
            return false;
        }

        let dispensing_start_datetime = moment(this.state.dispensing_start_datetime)
            .add(this.state.dispensing_start_time_hour, 'hours')
            .add(this.state.dispensing_start_time_minutes, 'minutes')
            .format("YYYY-MM-DD HH:mm:00");
        let dispensing_end_datetime = moment(this.state.dispensing_end_datetime)
            .add(this.state.dispensing_end_time_hour, 'hours')
            .add(this.state.dispensing_end_time_minutes, 'minutes')
            .format("YYYY-MM-DD HH:mm:00");

        // 開始日もしくは終了日に過去の日付が手動入力された場合、
        // 当日日付に変更
        if (moment(dispensing_start_datetime).format("YYYYMMDD") < moment().format("YYYYMMDD")) {
            this.setState({
                dispensing_start_datetime: moment().format("YYYYMMDD")
            })
            // 当日の日付と選択されている時分を組み合わせて新しい日時を計算
            dispensing_start_datetime = moment()
                .startOf('day')                                               // 当日の午前0時
                .add(this.state.dispensing_start_time_hour, 'hours')          // すでに選択されている時
                .add(this.state.dispensing_start_time_minutes, 'minutes')     // すでに選択されている分
                .format("YYYY-MM-DD HH:mm:00");
        } else if (moment(dispensing_end_datetime).format("YYYYMMDD") < moment().format("YYYYMMDD")) {
            this.setState({
                dispensing_end_datetime: moment().format("YYYYMMDD")
            })
            // 当日の日付と選択されている時分を組み合わせて新しい日時を計算
            dispensing_end_datetime = moment()
                .startOf('day')                                               // 当日の午前0時
                .add(this.state.dispensing_end_time_hour, 'hours')            // すでに選択されている時
                .add(this.state.dispensing_end_time_minutes, 'minutes')       // すでに選択されている分
                .format("YYYY-MM-DD HH:mm:00");
        }

        // 開始日が終了日より大きい場合
        // または終了日＞開始日+7日の場合
        // 終了日を開始日と同じ値に設定
        if
            (
                // 開始日が終了日より大きい場合
                (moment(dispensing_start_datetime).format("YYYYMMDD") > moment(dispensing_end_datetime).format("YYYYMMDD")) ||
                // または終了日＞開始日+7日の場合
                (moment(dispensing_end_datetime).format("YYYYMMDD") > moment(dispensing_start_datetime).add(7, 'days').format("YYYYMMDD"))
            )
        {
            // 終了日を開始日と同じ値に設定
            this.setState({
                dispensing_end_datetime: moment(dispensing_start_datetime).format("YYYYMMDD")
            })
            dispensing_end_datetime = moment()
                .startOf('day')                                               // 現在の日付の午前0時
                .add(this.state.dispensing_end_time_hour, 'hours')            // すでに選択されている時
                .add(this.state.dispensing_end_time_minutes, 'minutes')       // すでに選択されている分
                .format("YYYY-MM-DD HH:mm:00");
        }

        // 開始日時 > 終了日時になっていないかのチェック
        let isFromAfterToFlag = false;
        if (moment(this.state.dispensing_start_datetime).format("YYYYMMDD") === moment(this.state.dispensing_end_datetime).format("YYYYMMDD")) {
            if (
                (
                    this.state.dispensing_start_time_hour &&
                    this.state.dispensing_end_time_hour
                ) &&
                // 開始（時） > 終了時（時）
                this.state.dispensing_start_time_hour > this.state.dispensing_end_time_hour
            ) {
                isFromAfterToFlag = true;
            } else if (
                (
                    this.state.dispensing_start_time_hour &&
                    this.state.dispensing_end_time_hour &&
                    this.state.dispensing_start_time_minutes &&
                    this.state.dispensing_end_time_minutes
                ) &&
                // 開始（分） > 終了（分）
                this.state.dispensing_start_time_hour === this.state.dispensing_end_time_hour && (this.state.dispensing_start_time_minutes >= this.state.dispensing_end_time_minutes)
            ) {
                isFromAfterToFlag = true;
            }
        }

        if (isFromAfterToFlag) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionFromToError"});
            return false;
        }

        // 終了日時 < 現在日時になっていないかのチェック
        let isEndTimeBeforeCurrentFlag = false;
        if (
            (
                this.state.dispensing_start_time_hour &&
                this.state.dispensing_end_time_hour &&
                this.state.dispensing_start_time_minutes &&
                this.state.dispensing_end_time_minutes
            ) &&
            // 終了日時 < 現在日時
            moment(dispensing_end_datetime) < moment()
        ) {
            isEndTimeBeforeCurrentFlag = true;
        }

        if (isEndTimeBeforeCurrentFlag) {
            this.setState({validate_msg: "ExternalNumber_ScheduleExtensionPastDateError"});
            return false;
        }

        // 既に登録済みのスケジュールと日付が重複していないかのチェック
        let isDateRangeDuplicated = false;
        if (
            schedule_dispensing_infos.length &&
            (
                this.state.dispensing_start_time_hour &&
                this.state.dispensing_end_time_hour &&
                this.state.dispensing_start_time_minutes &&
                this.state.dispensing_end_time_minutes
            )
        ){
            // 日時期間重複検証
            isDateRangeDuplicated = schedule_dispensing_infos.some(row => {
                return (
                    // 既存のスケジュール着信コントロールを編集する場合、編集対象のスケジュール自体はチェックから除外するため、Noを比較
                    // (比較中のスケジュールNo.が現在編集中のスケジュールNo.と異なる場合のみチェックする)
                    row.dispensing_scheduled_no !== this.state.dispensing_scheduled_no &&
                    (
                        (
                            // 新しく登録したいスケジュールの開始日時が既存スケジュールの範囲内にある場合
                            moment(dispensing_start_datetime) >= moment(row.original_start_datetime) &&
                            moment(dispensing_start_datetime) <= moment(row.original_end_datetime)
                        )
                            ||
                        (
                            // 既存スケジュールの開始日時が新しく登録したいスケジュールの範囲内にある場合
                            moment(row.original_start_datetime) >= moment(dispensing_start_datetime) &&
                            moment(row.original_start_datetime) <= moment(dispensing_end_datetime)
                        )
                    )
                )
            });
        }

        if (isDateRangeDuplicated) {
            this.setState({validate_msg: "ExternalNumber_ExistDispensingValidation"});
            return false;
        }

        return true;
    }

    // 着信コントロールの秒数入力値チェック
    checkDispensingTime = () => {
        if (
            this.state.dispensing_schedule_time === null || 
            this.state.dispensing_schedule_time === "" || 
            (this.state.dispensing_schedule_time > 0 && this.state.dispensing_schedule_time <= 60)
        ) {
            this.propSetState({dispensingScheduleTimeValidationFlag: false});
        } else {
            this.propSetState({dispensingScheduleTimeValidationFlag: true});
        }
    }

    // 着信コントロールの着信数入力値チェック
    checkDispensingCount = () => {
        if (
            this.state.dispensing_schedule_count === null ||
            this.state.dispensing_schedule_count === "" || 
            (!this.props.state.channel_number && this.state.dispensing_schedule_count && this.state.dispensing_schedule_count > 0) ||
            (this.state.dispensing_schedule_count && this.state.dispensing_schedule_count <= this.props.state.channel_number)
        ) {
            this.propSetState({dispensingScheduleCountValidationFlag: false});
        } else {
            this.propSetState({dispensingScheduleCountValidationFlag: true});
        }
    }

    getModalBranch = () => {
        switch (this.state.modalType) {
            case "insert":
            case "copy":
            case "update":
                return (
                    <ExternalNumberScheduleExtensionModal
                        state = {this.state}
                        propState = {this.props.state}
                        userInfo = {this.props.userInfo}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                        onMultiSelectChangeExternal = {this.onMultiSelectChangeExternal}
                        systemParameters = {this.props.systemParameters}
                        onDateChangeHandle = {this.onDateChangeHandle}
                    >
                    </ExternalNumberScheduleExtensionModal>
                )
            case "dispensing_insert":
            case "dispensing_copy":
            case "dispensing_update":
                return (
                    <ExternalNumberScheduleDispensingModal
                        state = {this.state}
                        propState = {this.props.state}
                        userInfo = {this.props.userInfo}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        validationHandle = {this.validationHandle}
                        onMultiSelectChangeExternal = {this.onMultiSelectChangeExternal}
                        systemParameters = {this.props.systemParameters}
                        onDateChangeHandle = {this.onDateChangeHandle}
                        onIntChange = {this.onIntChange}
                        onDateChangeHandleDispensing  = {this.onDateChangeHandleDispensing}
                        onKeyDownIntCheck = {this.onKeyDownIntCheck}
                        checkDispensingTime = {this.checkDispensingTime}
                        checkDispensingCount = {this.checkDispensingCount}
                    >
                    </ExternalNumberScheduleDispensingModal>
                )
            default:
                break;
        }
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ExternalNumberSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getExternalNumberInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.DepartmentSetting}
                        </Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        {this.getDepartmentSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.InboundSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getInboundSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.DispensingSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getDispensingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.TimeoutSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getTimeoutSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.ListAppointInboundSetting}
                        </Panel.Title>  
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getListAppointSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.AnonymousSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getAnonymousSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.UnconnectedSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getUnconnectedSettingInfoItem()}
                    </Panel.Body>
                </Panel>

                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.BusinessCalendarSetting}
                        </Panel.Title>  
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getBusinessCalendarSettingInfoItem()}
                    </Panel.Body>
                </Panel>
                {this.props.state.addingOptionCallAnalysis ? this.getCallAnalysisInfoItem() : []}

                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </AscModal>
        );
    }
}
