import React, { Component } from "react";
import { Toaster } from "react-hot-toast";

/**
 * https://react-hot-toast.com/docs/toaster
 */

export default class ASToast extends Component {
    render() {
        const {
            position,
            reverseOrder,
            gutter,
            containerClassName,
            containerStyle,
            toastOptions
        } = this.props;

        return (
            <Toaster
                position={ position || "bottom-right" }
                reverseOrder={ reverseOrder || false }
                gutter={ gutter || 8 }
                containerClassName={ containerClassName }
                containerStyle={ containerStyle }
                toastOptions={ toastOptions ? toastOptions : {
                    duration: 2000,
                    style: {
                        background: "#333",
                        color: "#fff"
                    }
                }}
            />
        );
    }
}