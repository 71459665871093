import React, {Component} from "react"
import {Panel, Checkbox, Col, FormGroup, FormControl, Glyphicon, Button, Row} from "react-bootstrap"
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm"
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";
import ErrorMessageModal from "../Elements/AscElements/ErrorMessageModal";
import {CSVReader} from 'react-papaparse'


export default class OutboundRejectModal extends Component {
    getOutboundRejectInfo = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            onSelectChange,
            onCheckBoxChange,
            getScopeGreaterEqual,
            validationHandle,
            templateDownload,
            handleOnDrop,
            handleOnError,
            handleOnRemoveFile
        } = this.props;
        let itemArr = [];

        if ((state.modalType === "insert" || state.modalType === "insertBatch") 
                && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.company_id}
                    onChange = {e => onSelectChange(e, "company_id")}
                    options = {state.companySelect}
                    isClearable = {true}
                    validationState = {validationHandle("company_id")}
                    placeholder = {!state.buttondisabledArr.company_id[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.company_id[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "company_id"
                    controlId = "company_id"
                    label = {langText.Body.CompanyName}>
                    {state.company_name}
                </InlineForm.static>
            );
        }

        if ((state.modalType === "insert" || state.modalType === "update" 
            || state.modalType === "insertBatch")
            && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company))
        {
            itemArr.push(
                <InlineForm
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}
                    type = "select"
                    value = {state.department_id}
                    onChange = {e => onSelectChange(e, "department_id")}
                    options = {state.departmentSelect}
                    isClearable = {true}
                    validationState = {validationHandle("department_id")}
                    placeholder = {state.buttondisabledArr.department_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.department_id[0] || state.all_department_flag}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "department_id"
                    controlId = "department_id"
                    label = {langText.Body.DepartmentName}>
                    {state.all_department_flag ? langText.Body.AllDepartment : state.department_name}
                </InlineForm.static>
            );
        }

        if (this.props.state.floor_flg) {
            if ((state.modalType === "insert" || state.modalType === "update" 
                || state.modalType === "insertBatch")
                && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
            {
                itemArr.push(
                    <InlineForm
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}
                        type = "select"
                        value = {state.floor_id}
                        onChange = {e => onSelectChange(e, "floor_id")}
                        options = {state.floorSelect}
                        isClearable = {true}
                        validationState = {validationHandle("floor_id")}
                        placeholder = {state.buttondisabledArr.floor_load[0]
                            ? <Glyphicon glyph="refresh" className="spinning"/>
                            : langText.SelectOption.Placeholder}
                        isDisabled = {!(state.buttondisabledArr.floor_id[0] && state.company_id) || state.all_department_flag}/>
                )
            } else {
                itemArr.push(
                    <InlineForm.static
                        key = "floor_id"
                        controlId = "floor_id"
                        label = {langText.Body.FloorName}>
                        {state.all_department_flag ? langText.Body.AllFloor : state.floor_name}
                    </InlineForm.static>
                )
            }
        }

        if ((state.modalType === "insert" || state.modalType === "update" 
            || state.modalType === "insertBatch")
            && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) && state.company_id) {
            itemArr.push(
                <FormGroup
                    key = "all_department_flag"
                    controlId = "all_department_flag">
                    <Col smOffset={0} sm={3}>
                        <Checkbox
                            checked = {state.all_department_flag}
                            onChange = {e => onCheckBoxChange(e, "all_department_flag")}
                            disabled = {!state.company_id}>
                            {langText.Body.AllDepartment}
                        </Checkbox>
                    </Col>
                </FormGroup>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "telno"
                    controlId = "telno"
                    label = {langText.Body.Telno}
                    type = "text"
                    value = {state.telno}
                    onChange = {e => onTextChange_Limit(e, "telno",charaLimit.OutboundR_telno)}
                    validationState = {validationHandle("telno")}/>
            );
        } else if (state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key = "telno"
                    controlId = "telno"
                    label = {langText.Body.Telno}>
                    {state.telno}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}
                    type = "text"
                    value = {state.memo}
                    onChange = {e => onTextChange_Limit(e, "memo",charaLimit.OutboundR_memo)}/>
            );
        } else if (state.modalType === "delete") {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insertBatch") {
            itemArr.push(
                <Row>
                    <Col sm={5}> 
                        <Button
                            key = "template"
                            value = 'OUTBOUNDREJECT_IMPORT_TEMPLATE'
                            onClick = {templateDownload}>
                            {langText.Body.CustomerFormatDownload}
                        </Button>
                    </Col>
                    <Col sm={7}>
                        <CSVReader
                            onDrop = {handleOnDrop}
                            onError = {handleOnError}
                            addRemoveButton
                            removeButtonColor = "#659cef"
                            onRemoveFile = {handleOnRemoveFile}
                            isReset = {true}
                        >
                            <span>{this.props.langText.Message.CsvUpload}</span>
                        </CSVReader>
                    </Col>
                </Row>
            );
        }

        return itemArr;
    }

    errorModalClose = () => {
        this.props.propSetState({ errorMessageModalShow: false });
    }

    render() {
        return (
            <Row>
                <AscModal
                    state = {this.props.state}
                    propSetState = {this.props.propSetState}
                    langText = {this.props.langText}
                    validationHandle = {this.props.validationHandle}
                    onClick = {this.props.onClick}>
                    {
                        <Panel bsStyle="info">
                            <Panel.Heading>
                                <Panel.Title>
                                    {this.props.langText.Body.OutboundRejectSetting}
                                </Panel.Title>
                            </Panel.Heading>

                            <Panel.Body>
                                {this.getOutboundRejectInfo()}
                            </Panel.Body>
                        </Panel>
                    }
                </AscModal>

                {
                    this.props.state.errorMessageModalShow
                    &&
                    <ErrorMessageModal
                        state = {this.props.state}
                        langText = {this.props.langText}
                        errText = {this.props.state.errText}
                        errorModalClose = {this.errorModalClose}
                    />
                }
            </Row>
        );
    }
}