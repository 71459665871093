import React from "react";
import Component from '../../components/AscComponent';
import {Panel, Glyphicon} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";
import "../Elements/FromGroup/form_select.css"
import * as GlobalConst from "../../components/AscConstants";


export default class DepartmentModal extends Component {
    getDepartmentInfoItem = () => {
        let {
            floor_flg,
            state,
            langText,
            charaLimit,
            onKeyDownIntCheck,
            onTextChange_Limit,
            onSelectChange,
            validationHandle,
            getScopeGreaterEqual,
            checkDepartmentNameDuplicate
        } = this.props;
        let itemArr = [];
        if (state.modalType === "insert" && getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push(
                <InlineForm
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}
                    type = "select"
                    value = {state.companyId}
                    onChange = {e => onSelectChange(e, "companyId")}
                    options = {state.companySelect}
                    validationState = {validationHandle("companyId")}
                    placeholder = {!state.buttondisabledArr.companyId[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                    isDisabled = {!state.buttondisabledArr.companyId[0]}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "companyId"
                    controlId = "companyId"
                    label = {langText.Body.CompanyName}>
                    {state.companyName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "departmentName"
                    controlId = "departmentName"
                    label = {langText.Body.DepartmentName}
                    type = "text"
                    value = {state.departmentName}
                    onChange = {e => onTextChange_Limit(e, "departmentName",charaLimit.Department_departmentName)}
                    onBlur = {() => checkDepartmentNameDuplicate()}
                    validationState = {validationHandle("departmentName")}/>
            );

            if(state.departmentNameDuplicateFlag){
                itemArr.push(
                    <p key = "departmentNameDuplicate" className="text-danger">
                        {langText.Body.DepartmentNameDuplicate}
                    </p>
                );
            }
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "departmentName"
                    controlId = "departmentName"
                    label = {langText.Body.DepartmentName}>
                    {state.departmentName}
                </InlineForm.static>
            );
        }

        if (state.modalType === "insert") {
            itemArr.push(
                <InlineForm
                    key = "prefix"
                    controlId = "prefix"
                    label = {langText.Body.Prefix}
                    type = "text"
                    value = {state.prefix}
                    onKeyDown = {e => onKeyDownIntCheck(e)}
                    onChange = {e => onTextChange_Limit(e, "prefix", (floor_flg ? 2 : 3))}
                    placeholder = {floor_flg ? langText.Body.Length2 : langText.Body.Length3}
                    validationState = {validationHandle("prefix")}/>
            );
            if(!this.isSystemUsedPrefix(state.prefix))
            {
                itemArr.push(
                    <p key = "prefixSystemUseNumber" className="text-danger">
                        {langText.Body.PrefixSystemUseNumber}
                    </p>
                );    
            }
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "prefix"
                    controlId = "prefix"
                    label = {langText.Body.Prefix}>
                    {state.prefix}
                </InlineForm.static>
            );
        }

        if (!floor_flg) {
            if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = "pbx"
                        controlId = "pbx"
                        label = {langText.Body.Pbx}
                        type = "select"
                        value = {state.pbx}
                        onChange = {e => onSelectChange(e, "pbx")}
                        options = {state.pbxSelect}
                        validationState = {validationHandle("pbx")}
                        placeholder = {state.buttondisabledArr.pbx_load[0] ? <Glyphicon glyph="refresh" className="spinning"/> : langText.SelectOption.Placeholder}
                        isDisabled = {!state.buttondisabledArr.pbx[0]}/>
                );
            } else {
                let pbxRow = state.pbxSelect.find(row => state.pbx === row.value);
                itemArr.push(
                    <InlineForm.static
                        key = "pbx"
                        controlId = "pbx"
                        label = {langText.Body.Pbx}>
                        {pbxRow ? pbxRow.label : ""}
                    </InlineForm.static>
                );
            }
            if(state.modalType === "update") {
                itemArr.push(
                    <InlineForm.static
                        key = "call_record_password"
                        controlId = "call_record_password"
                        label = {langText.Body.VoiceLogPasswordNow}>
                        {state.call_record_password ? state.call_record_password : ""}
                    </InlineForm.static>
                );
            }
            if (state.modalType === "insert" || state.modalType === "update") {
                itemArr.push(
                    <InlineForm
                        key = "audio_record_password"
                        controlId = "audio_record_password"
                        label = {state.modalType === "update"?langText.Body.VoiceLogPasswordNew:langText.Body.VoiceLogPassword}
                        type = "text"
                        value = {state.audio_record_password}
                        onKeyDown = {e => onKeyDownIntCheck(e)}
                        onChange = {e => onTextChange_Limit(e, "audio_record_password", 4)}
                        validationState = {validationHandle("audio_record_password")}
                        placeholder = {langText.SelectOption.Placeholder_4_number}/>
                );
            }
        }

        if (state.modalType === "insert" || state.modalType === "update") {
            itemArr.push(
                <InlineForm
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}
                    type = "text"
                    value = {state.memo}
                    onChange = {e => onTextChange_Limit(e, "memo",charaLimit.Department_memo)}/>
            );
        } else {
            itemArr.push(
                <InlineForm.static
                    key = "memo"
                    controlId = "memo"
                    label = {langText.Body.Memo}>
                    {state.memo}
                </InlineForm.static>
            );
        }

        return itemArr;
    }

    //削除モーダルに所属ユーザー数表示
    getDepartmentUserInfo=()=>{
        let message = [];
        let {user_cnt} = this.props.state;
        if(user_cnt > 0){
            message.push(this.props.sprintf(this.props.langText.Message.UserBelongedDepartmentMessage, user_cnt));
        }
        
        return(
            <p className="user-belonged">
                {message}
            </p>
        )
    }

    render() {
        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Heading>
                        <Panel.Title>
                            {this.props.langText.Body.DepartmentSetting}
                        </Panel.Title>
                    </Panel.Heading>

                    <Panel.Body>
                        {this.getDepartmentInfoItem()}
                    </Panel.Body>
                </Panel>
                {
                    (this.props.state.modalType === "delete" && this.props.state.user_cnt > 0) &&
                    <div>
                        {this.getDepartmentUserInfo()}
                    </div>
                }
            </AscModal>
        );
    }
}