import React from "react"
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap"
import Component from "../../components/AscComponent"
import CommonTable from "../../containers/Elements/Table/CommonTable"
import CallResultModal from "./CallResultModal"
import * as GlobalConst from "../../components/AscConstants";

export default class CallResult extends Component {
    constructor(props) {
        super(props)
        this.state = {
            // table item
            columns:          this.getColumnsData(),
            data:             [],
            filtered:         [],
            pages:            null,
            loading:          false,

            // modal item
            show:             false,
            modalType:        null,

            // select item
            companySelect: [],

            // data item
            callResultId:        "",
            company_id:          "",
            companyName:         "",
            callResultCode:      "",
            callResultName:      "",
            callResultOrderNum:  "",
            callRankFirstModal:       [],
            callRankSecondModal:      [],
            callResultCodeDuplicateFlag : false,
            callResultNameDuplicateFlag :false,
            callRankFirstCodeDuplicateFlag : false,
            callRankFirstNameDuplicateFlag :false,
            callRankSecondCodeDuplicateFlag : false,
            callRankSecondNameDuplicateFlag :false,

            usingCallResults: [],
            usingCallResultFlag: false,

            // button_disabled
            buttondisabledArr: {
                "company_id" : []
            }
        }
    }

    getColumnsData() {
        let itemArr = [];
        // oem以上は会社カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                Filter: () => <FormControl
                    onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")}
                    placeholder={this.props.langText.Body.ForwardMatch}
                />
            });
        }
        itemArr.push({
            Header: this.props.langText.Body.CallResultCode,
            accessor: "call_result_code",
            Filter: () => <FormControl
                onChange={e => this.onFilterChange(e.target.value, "call_result_code")}
                placeholder={this.props.langText.Body.ForwardMatch}
            />
        });
        itemArr.push({
            Header: this.props.langText.Body.CallResultName,
            accessor: "call_result_name",
            Filter: () => <FormControl
                onChange={e => this.onFilterChange(e.target.value, "call_result_name")}
                placeholder={this.props.langText.Body.ForwardMatch}
            />
        });
        itemArr.push({
            Header: this.props.langText.Body.CallRankFirstName,
            sortable: false,
            Cell: data => {
                let rankNameArr = data.row._original.cm65_call_ranks_1.map(row => {
                    return row.rank_name;
                })
                return rankNameArr.length > 0 ? rankNameArr.join(",") : "";
            },
            Filter: () => <FormControl
                onChange={e => this.onFilterChange(e.target.value, "cm65_call_ranks_1.rank_name")}
                placeholder={this.props.langText.Body.ForwardMatch}
            />
        });
        itemArr.push({
            Header: this.props.langText.Body.CallRankSecondName,
            sortable: false,
            Cell: data => {
                let rankNameArr = data.row._original.cm65_call_ranks_2.map(row => {
                    return row.rank_name;
                })
                return rankNameArr.length > 0 ? rankNameArr.join(",") : "";
            },
            Filter: () => <FormControl
                onChange={e => this.onFilterChange(e.target.value, "cm65_call_ranks_2.rank_name")}
                placeholder={this.props.langText.Body.ForwardMatch}
            />
        });
        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.Control,
            Cell: data => {
                let rowData = [];

                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("update", data.row._original)}
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("read", data.row._original)}
                        >
                            <Glyphicon glyph="eye-open"/>
                        </Button>
                    );
                }

                if (
                    this.props.currentPermission.create &&
                    (data.row._original.cm65_call_ranks_1.length > 0 || data.row._original.cm65_call_ranks_2.length > 0)
                ) {
                    // コールランク1,2いずれかが1個でも設定されていれば、コピーボタンを表示
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow("copy", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }

                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            onClick={this.modalShow("delete", data.row._original)}
                            className="control-button"
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    )
                }

                return (
                    <Row className="text-center">
                        {rowData}
                    </Row>
                )
            }
        });

        return itemArr;
    }

    // 画面表示タイミング共通処理_変数初期化
    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "company_id" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param, switchdata) => {
        switch (switchdata)
        {
            case "push":
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
                break;
            case "pop":
                this.state.buttondisabledArr[param].pop();
                break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
                break;
        }
    }

    modalShow = (modalType, param) =>  async event => {
        event.preventDefault();

        this.modalshow_commonInit();

        let setData = {
            callResultId: param && param.id && modalType !== "copy"
                ? param.id
                : "",
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : "",
            companyName: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : "",
            callResultCode: param && param.call_result_code && modalType !== "copy"
                ? param.call_result_code
                : "",
            callResultCodeDuplicateFlag: false,
            callResultName: param && param.call_result_name && modalType !== "copy"
                ? param.call_result_name
                : "",
            callResultNameDuplicateFlag: false,
            callResultOrderNum: param && param.order_num && modalType !== "copy"
                ? param.order_num
                : ""
        }

        let callRankFirstInfos = [];
        if (param && param.cm65_call_ranks_1.length > 0) {
            param.cm65_call_ranks_1.forEach(row => {
                callRankFirstInfos.push({id: row.id, type: 1, order_num: row.order_num, value: {firstValue: row.rank_code, secondValue: row.rank_name}, del_flag: "N", invisible_flag: "N"});
            })
        }
        setData.callRankFirstModal = callRankFirstInfos;

        let callRankSecondInfos = [];
        if (param && param.cm65_call_ranks_2.length > 0) {
            param.cm65_call_ranks_2.forEach(row => {
                callRankSecondInfos.push({id: row.id, type: 2, order_num: row.order_num, value: {firstValue: row.rank_code, secondValue: row.rank_name}, del_flag: "N", invisible_flag: "N"});
            })
        }
        setData.callRankSecondModal = callRankSecondInfos;

        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            setData.company_id = this.props.userInfo.company_id;
            setData.companyName = this.props.userInfo.company_name;
        }

        if(modalType === "delete"){
            try {
                let checkCallResultUsing = await this.ascAxios("post", `${this.reactContainerPath}/checkCallResultUsing`, {cm64_id : param.id});
                setData.usingCallResultFlag = false;
                setData.usingCallResults = [];
                if(checkCallResultUsing.data.length > 0){
                        checkCallResultUsing.data.forEach(element => {
                            setData.usingCallResults.push(
                                element.cm61_external_numbers.memo
                                ? this.sprintf(
                                    this.props.langText.Message.AudioDeleteError_External,
                                    element.cm61_external_numbers.memo + " (" + element.cm61_external_numbers.display_number + ")")
                                : this.sprintf(
                                    this.props.langText.Message.AudioDeleteError_External,
                                    element.cm61_external_numbers.display_number)
                            );
                        });

                    setData.usingCallResults = setData.usingCallResults.map(elem =>{
                        return <div>{elem}</div>
                    });
                    setData.usingCallResultFlag = true;
                }
            } catch (err) {
                console.error(err);
                this.showErrorObjectMesssage(err, "GetDataError");
            }
        }

        this.setState(setData);
        await this.getCompanySelect();

        this.setState({show: true, modalType});

    }

    onClickHandle = modalType => async event => {
        let {
            callResultId,
            company_id,
            callResultCode,
            callResultName,
            callResultOrderNum,
            callRankFirstModal,
            callRankSecondModal
        } = this.state

        let showFlag = false;

        try{
            switch (modalType) {
                case "insert":
                case "copy":
                    this.blockUI();
                    let callRankFirstExceptDel = callRankFirstModal.filter(row => row.del_flag !== "Y");
                    let callRankSecondExceptDel = callRankSecondModal.filter(row => row.del_flag !== "Y");
                    await this.ascAxios('post', `${this.reactContainerPath}/insert`, {
                        company_id,
                        callResultCode,
                        callResultName,
                        callResultOrderNum,
                        callRankFirst: callRankFirstExceptDel,
                        callRankSecond: callRankSecondExceptDel
                    })
                    alert(this.props.langText.Message.DataInsertSuccess);
                    this.reactTableRefresh();
                    break;
                case "update":
                    this.blockUI();
                    await this.ascAxios('post', `${this.reactContainerPath}/update`, {
                        company_id,
                        callResultId,
                        callResultCode,
                        callResultName,
                        callResultOrderNum,
                        callRankFirst: callRankFirstModal,
                        callRankSecond: callRankSecondModal
                    })
                    alert(this.props.langText.Message.DataUpdateSuccess);
                    this.reactTableRefresh();
                    break;
                case "delete":
                    if (window.confirm(this.props.langText.Message.CallRankDeleteConfirm)) {
                        this.blockUI();
                        await this.ascAxios('post', `${this.reactContainerPath}/delete`, {
                            callResultId
                        })
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    } else {
                        showFlag = true;
                    }
                    break;
                default:
                    throw "ModalTypeError";
            }

            this.setState({
                show: showFlag
            })
        } catch(err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    onSelectChange = async (event, param) => {
        super.onSelectChange(event, param);
        try {
            switch (param) {
                case "company_id":
                    await this.checkDuplicate("callResultCode", event);
                    await this.checkDuplicate("callResultName", event);
                    break;
                default:
                    break;
            }
        } catch(err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }
    }

    onTextChange_Limit(event, param, limitcount) {
        super.onTextChange_Limit(event, param, limitcount);
        if (param === "callResultOrderNum") {
            if (event.target.value < 0) {
                this.setState({ [param]: 0 });
            }
        }
    }

    onMultiValueFormTextChange = (param, index, target, content) => {
        if (content.length <= this.props.charaLimit.CallRank_Info) {
            let arrTemp = this.state[param];
            if (target === "value1") {
                arrTemp[index].value.firstValue = content;
            } else if (target === "value2") {
                arrTemp[index].value.secondValue = content;
            } else if (target === "order_num") {
                if (content.length > this.props.charaLimit.CallRank_Order_Num) {
                    return;
                }
                if (content < 0) {
                    arrTemp[index].order_num = 0;
                } else {
                    arrTemp[index].order_num = content;
                }
            }
            this.setState({[param]: arrTemp});
        }
    }

    onMultiValueFormSizeChange = (param, handle, index) => {
        let arrTemp = this.state[param];

        if (handle === "plus") {
            let callRankType = param === "callRankFirstModal" ? 1 : 2;
            arrTemp.push({id: null, type: callRankType, order_num: null, value: {firstValue: "", secondValue: ""}, del_flag: "N", invisible_flag: "N"});
        } else if (handle === "minus") {
            arrTemp[index].del_flag = "Y";
            arrTemp[index].invisible_flag =
                (arrTemp[index].value.firstValue === "" && arrTemp[index].value.secondValue === "")
                ? "Y"
                : "N";
        } else if (handle === "undo") {
            arrTemp[index].del_flag = "N";
        }

        this.setState({[param]: arrTemp});
    }

    getCompanySelect = async() => {
        try{
            let res = await this.ascAxios("post", "Common/companySelect", {container: this.reactContainerPath});
            this.common_buttondisabledLengthCheck("company_id", "push");
            this.setState({
                companySelect: res.data
            })
        } catch (err) {
            this.setState({companySelect: []});
            console.error(err);
            alert(this.getErrorString(err.response.data));
        }
    }

    checkDuplicate = async (param, event) => {
        try {
            let company_id = "";
            if (event && event.value) {
                company_id = event.value;
            } else if (event === undefined){
                company_id = this.state.company_id;
            }
            switch (param) {
                case "callResultCode":
                case "callResultName":
                    if (company_id === "" || this.state[param] === "") {
                        //未入力の場合はチェックしない
                        param ==="callResultCode"
                            ? this.setState({callResultCodeDuplicateFlag: false})
                            : this.setState({callResultNameDuplicateFlag: false});
                        break;
                    }
                    let result = await this.ascAxios("post", `${this.reactContainerPath}/checkDuplicateCallResult`, {
                        id: this.state.callResultId ? this.state.callResultId : "",
                        company_id,
                        target: param,
                        target_value: param ==="callResultCode" ? this.state.callResultCode : this.state.callResultName
                    })
                    param ==="callResultCode"
                        ? this.setState({callResultCodeDuplicateFlag: result.data.isDuplicate})
                        : this.setState({callResultNameDuplicateFlag: result.data.isDuplicate});
                    break;
                default:
                    break;
            }
        } catch (err) {
            console.error(err);
            alert(this.getErrorString(err.response.data));
        }
    }

    // コールランクのコード・名称の重複チェック
    checkDuplicateCallRank = (callRankElements) => {
        if (!callRankElements) {
            // コードランクコードまたは名称が1個も入力されていない場合、処理を終了
            return true;
        }

        let callRankCodeArr = [];
        let callRankNameArr = [];

        callRankElements.forEach(row => {
            if (row.del_flag === "N") {
                callRankCodeArr.push(row.value.firstValue);
                callRankNameArr.push(row.value.secondValue);
            }
        })
        const callRankCodeElementsUnique = new Set(callRankCodeArr.filter(row => row !== ""));
        const callRankNameElementsUnique = new Set(callRankNameArr.filter(row => row !== ""));

        // コード・名称ともに
        // 入力された行の数(del_flag="Y"の行は除く) <> 値の重複を排除した行の数
        // であればエラーとする
        return (
            callRankCodeArr.length === callRankCodeElementsUnique.size &&
            callRankNameArr.length === callRankNameElementsUnique.size
        )
    }

    validationHandle = param => {
        let nowState = null;

        let {
            company_id,
            callResultCode,
            callResultCodeDuplicateFlag,
            callResultName,
            callResultNameDuplicateFlag,
            callRankFirstModal,
            callRankSecondModal,
            usingCallResultFlag
        } = this.state

        // コール結果コード・コールランクコードのチェック(全角文字禁止)
        let codeCheck = /^[\x01-\x7E]+$/;

        switch (param) {
            case "company_id":
                nowState = this.state[param]
                    ? null
                    : "error"
                break;
            case "callResultCode":
                nowState = this.state[param] && codeCheck.test(this.state[param]) && !callResultCodeDuplicateFlag
                    ? null
                    : 'error'
                break;
            case "callResultName":
                nowState = this.state[param] && !callResultNameDuplicateFlag
                    ? null
                    : 'error'
                break;
            case "callRankFirstModal":
            case "callRankSecondModal":
                // コード・名称のうち、ひとつでも入力されていない行がある場合、エラーとする
                let requiredCheckResult = this.state[param].every(row => {
                    return (
                        (row.del_flag === "Y" || (row.value.firstValue && row.value.secondValue)) &&
                        (!row.value.firstValue || codeCheck.test(row.value.firstValue))
                    );
                })

                if (!requiredCheckResult) {
                    nowState = "error";
                    break;
                }

                // 入力されているコードまたは名称に重複がある場合、エラーとする
                if (!this.checkDuplicateCallRank(this.state[param])) {
                    nowState = "error"
                    break;
                }

                nowState = null;
                break;
            case "insert":
            case "copy":
            case "update":
                nowState = (
                    company_id &&
                    (callResultCode && codeCheck.test(callResultCode)) &&
                    !callResultCodeDuplicateFlag &&
                    callResultName &&
                    !callResultNameDuplicateFlag &&
                    callRankFirstModal.every(row => {
                        return (
                            (row.del_flag === "Y" || (row.value.firstValue && row.value.secondValue)) &&
                            (!row.value.firstValue || codeCheck.test(row.value.firstValue))
                        );
                    }) &&
                    this.checkDuplicateCallRank(this.state.callRankFirstModal) &&
                    callRankSecondModal.every(row => {
                        return (
                            (row.del_flag === "Y" || (row.value.firstValue && row.value.secondValue)) &&
                            (!row.value.firstValue || codeCheck.test(row.value.firstValue))
                        );
                    }) &&
                    this.checkDuplicateCallRank(this.state.callRankSecondModal)
                )
                break;
            case "delete":
                nowState = !usingCallResultFlag;
                break;
            default:
                break;
        }

        return nowState;
    }

    render() {
        return (
            <div className="CallResult">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="customer-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }

                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>

                <CallResultModal
                    getScopeGreaterEqual = {this.getScopeGreaterEqual}
                    currentPermission = {this.props.currentPermission}
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    onClick = {this.onClickHandle}
                    charaLimit = {this.props.charaLimit}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    onSelectChange = {this.onSelectChange}
                    onMultiValueFormTextChange = {this.onMultiValueFormTextChange}
                    onMultiValueFormSizeChange = {this.onMultiValueFormSizeChange}
                    validationHandle = {this.validationHandle}
                    checkDuplicate={this.checkDuplicate}
                />
            </div>
        )
    }
}