import React, { useState } from "react";
import { Col } from "react-bootstrap";
import { FormControl } from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import InlineForm from "../Elements/FromGroup/InlineForm";
import * as GlobalConst from "../../components/AscConstants";
import { gql, useSubscription } from "@apollo/client";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import Appsync from "../../Omni-Cuscon-AppSync";
import moment from "moment";
import _, { isNull } from "lodash";

export default class ExtensionMonitor extends AscComponent {
    constructor(props) {
        super(props);
        this.state = {
            company_id: this.props.company_id,
            department_id: this.props.department_id,
            extensionList: {},
            render_item: [],
            defaultExtensionTableLength: 8,
            currentExtensionTableLength: 8,
            extensionSummary: [
                {
                    loginExtension: 0,
                    readyExtension: 0,
                    callExtension: 0,
                    workExtension: 0,
                    rest_time_05: 0,
                    rest_time_30: 0,
                    rest_time_45: 0,
                    rest_time_60: 0
                }
            ],
            extension_interval_time: 1,
            isExternalSearchMode: this.props.isExternalSearchMode,
            external_id_list: this.props.external_id_list,
            inbound_alert_time: this.props.inbound_alert_time,
            extension_list_interval: this.props.extension_list_interval,
            extension_error_count: 0
        };
    }

    componentDidMount() {
        let updateCallTimeInterval = setInterval(
            this.updateCallTime,
            this.state.extension_interval_time * 1000
        );
        let updateRestTimeInterval = setInterval(this.updateRestTime, 1000);

        this.setState({
            updateCallTimeInterval: updateCallTimeInterval,
            updateRestTimeInterval: updateRestTimeInterval
        });
        if (this.props.department_id) {
            this.getExtensionNumData({
                department_id: this.props.acd_department_id,
                external_id: this.props.acd_external_id
            });
            clearInterval(this.extensionListInterval);
            this.extensionListInterval = setInterval(
                this.getExtensionNumData,
                this.state.extension_list_interval * 1000,
                {
                    department_id: this.props.acd_department_id,
                    external_id: this.props.acd_external_id
                }
            );
            this.setState({ extensionListInterval: this.extensionListInterval });
        }

        this.setExtensionStatusData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState !== this.state) {
            this.getColumnsData("extension_number");
            this.getColumnsData("extension_summary");
        }
        if (prevProps.company_id !== this.props.company_id) {
            this.setState({
                company_id: this.props.company_id
            });
        }
        if (
            Array.isArray(this.props.department_id) &&
            this.props.department_id.length &&
            prevProps.department_id !== this.props.department_id
        ) {
            this.setState({
                department_id_list: this.props.department_id
            });
        }
        if (prevProps.extension_list_interval !== this.props.extension_list_interval) {
            clearInterval(this.extensionListInterval);
            this.getExtensionNumData({
                department_id: this.props.acd_department_id,
                external_id: this.props.acd_external_id
            });
            this.extensionListInterval = setInterval(
                this.getExtensionNumData,
                this.state.extension_list_interval * 1000,
                {
                    department_id: this.props.acd_department_id,
                    external_id: this.props.acd_external_id
                }
            );
            this.setState({
                extensionListInterval: this.extensionListInterval,
                extension_list_interval: this.props.extension_list_interval
            });
        }
        if (
            (prevProps.isExternalSearchMode !== this.props.isExternalSearchMode ||
                !_.isEqual(prevProps.acd_external_id, this.props.acd_external_id) ||
                !_.isEqual(prevProps.acd_department_id, this.props.acd_department_id)) &&
            !this.props.isExternalSearchMode
        ) {
            clearInterval(this.extensionListInterval);
            this.setState({
                company_id: this.props.company_id,
                department_id: this.props.acd_department_id,
                acd_external_id: this.props.acd_external_id,
                render_item: []
            });
            this.getExtensionNumData({
                department_id: this.props.acd_department_id,
                external_id: this.props.acd_external_id
            });
            this.extensionListInterval = setInterval(
                this.getExtensionNumData,
                this.state.extension_list_interval * 1000,
                {
                    department_id: this.props.acd_department_id,
                    external_id: this.props.acd_external_id
                }
            );
            this.setState({ extensionListInterval: this.extensionListInterval });
        }
        if (
            (prevProps.isExternalSearchMode !== this.props.isExternalSearchMode ||
                prevProps.external_id_list !== this.props.external_id_list) &&
            this.props.isExternalSearchMode
        ) {
            clearInterval(this.extensionListInterval);
            this.setState({
                external_id_list: this.props.external_id_list,
                render_item: []
            });
            this.getExtensionNumData({
                department_id: this.props.external_department_id,
                external_id: this.props.external_id_list
            });
            this.extensionListInterval = setInterval(
                this.getExtensionNumData,
                this.state.extension_list_interval * 1000,
                {
                    department_id: this.props.external_department_id,
                    external_id: this.props.external_id_list
                }
            );
            this.setState({ extensionListInterval: this.extensionListInterval });
        }
        if (prevProps.inbound_alert_time !== this.state.inbound_alert_time) {
            this.setState({
                inbound_alert_time: this.props.inbound_alert_time
            });
        }
        if (
            prevProps.isExternalSearchMode !== this.props.isExternalSearchMode &&
            !this.props.isExternalSearchMode
        ) {
            clearInterval(this.extensionListInterval);
            this.setState({
                isExternalSearchMode: this.props.isExternalSearchMode,
                extensionList: {}
            });
            this.getExtensionNumData({
                department_id: this.props.acd_department_id,
                external_id: this.props.acd_external_id
            });
            this.extensionListInterval = setInterval(
                this.getExtensionNumData,
                this.state.extension_list_interval * 1000,
                {
                    department_id: this.props.acd_department_id,
                    external_id: this.props.acd_external_id
                }
            );
            this.setState({ extensionListInterval: this.extensionListInterval });
        }
        if (
            prevProps.isExternalSearchMode !== this.props.isExternalSearchMode &&
            !_.isEqual(this.props.external_department_id, []) &&
            this.props.external_department_id !== null &&
            this.props.isExternalSearchMode
        ) {
            clearInterval(this.extensionListInterval);
            this.setState({
                isExternalSearchMode: this.props.isExternalSearchMode,
                extensionList: {}
            });
            this.getExtensionNumData({
                department_id: this.props.external_department_id,
                external_id: this.props.external_id_list
            });
            this.extensionListInterval = setInterval(
                this.getExtensionNumData,
                this.state.extension_list_interval * 1000,
                {
                    department_id: this.props.external_department_id,
                    external_id: this.props.external_id_list
                }
            );
            this.setState({ extensionListInterval: this.extensionListInterval });
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.updateCallTimeInterval);
        clearInterval(this.state.extensionListInterval);
    }

    updateRestTime = () => {
        let extensionList = this.state.render_item;
        const currentDate = moment(new Date());
        let rest_time_05 = 0;
        let rest_time_30 = 0;
        let rest_time_45 = 0;
        let rest_time_60 = 0;

        Object.keys(extensionList).map(function (key, index) {
            if (extensionList[key].extension_status === "rest") {
                const updateDate = moment(new Date(extensionList[key].extension_time));
                let duration = moment.duration(currentDate.diff(updateDate)).asMinutes();
                if (5 <= duration && duration < 30) {
                    rest_time_05++;
                } else if (30 <= duration && duration < 45) {
                    rest_time_30++;
                } else if (45 <= duration && duration < 60) {
                    rest_time_45++;
                } else if (60 <= duration) {
                    rest_time_60++;
                }
            }
        });

        this.setState((prevState) => {
            let extensionSummary = prevState.extensionSummary[0];
            return {
                extensionSummary: [
                    {
                        callExtension: extensionSummary.callExtension,
                        loginExtension: extensionSummary.loginExtension,
                        readyExtension: extensionSummary.readyExtension,
                        workExtension: extensionSummary.workExtension,
                        rest_time_05: rest_time_05,
                        rest_time_30: rest_time_30,
                        rest_time_45: rest_time_45,
                        rest_time_60: rest_time_60
                    }
                ]
            };
        });
    };

    updateCallTime = () => {
        let extensionList = this.state.render_item;
        this.setExtensionStatusData();
        const currentDate = new Date();
        Object.keys(extensionList).map(function (key, index) {
            if (
                extensionList[key].extension_status === "outbound" ||
                extensionList[key].extension_status === "call" ||
                extensionList[key].extension_status === "inbound" ||
                extensionList[key].extension_status === "hold"
            ) {
                const updateDate = new Date(extensionList[key].call_time);
                const time = currentDate - updateDate;
                extensionList[key].calling_time = moment(time).utc().format("HH:mm:ss");
            }
        });
    };

    getColumnsData(param) {
        let itemArr = [];

        switch (param) {
            case "extension_summary":
                itemArr.push({
                    Header: this.props.langText.Body.TotalLogin,
                    accessor: "loginExtension",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "loginExtension")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ExtensionStatusList.Call,
                    accessor: "callExtension",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "callExtension")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ExtensionStatusList.Ready,
                    accessor: "readyExtension",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "readyExtension")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ExtensionStatusList.Work,
                    accessor: "workExtension",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "workExtension")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.RestTimeList.Rest05,
                    accessor: "rest_time_05",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "rest_time_05")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.RestTimeList.Rest30,
                    accessor: "rest_time_30",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "rest_time_30")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.RestTimeList.Rest45,
                    accessor: "rest_time_45",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "rest_time_45")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.RestTimeList.Rest60,
                    accessor: "rest_time_60",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "rest_time_60")}
                        />
                    ),
                    Cell: (extension_summary) => {
                        let rowData = extension_summary.value;
                        return <div>{rowData}</div>;
                    }
                });
                break;

            case "extension_number":
                itemArr.push({
                    Header: this.props.langText.Body.OperatorId,
                    accessor: "operator_id",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "operator_id")}
                        />
                    ),
                    Cell: (extensionObj) => {
                        let rowData = extensionObj.value;
                        if (extensionObj.original.inbound_alert) {
                            return <div className="callmonitor-inbound-alert-text">{rowData}</div>;
                        }
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.OperatorName,
                    accessor: "operator_name",
                    Cell: (extensionObj) => {
                        let rowData = extensionObj.value;
                        if (extensionObj.original.inbound_alert) {
                            return <div className="callmonitor-inbound-alert-text">{rowData}</div>;
                        }
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ExtensionNumber,
                    accessor: "extension_number",
                    Filter: () => (
                        <FormControl
                            onChange={(e) =>
                                this.onFilterChange(e.target.value, "extension_number")
                            }
                        />
                    ),
                    Cell: (extensionObj) => {
                        let rowData = extensionObj.value.replace(/['a'-'z']/gi, "");
                        if (extensionObj.original.inbound_alert) {
                            return <div className="callmonitor-inbound-alert-text">{rowData}</div>;
                        }
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ExtensionStatus,
                    accessor: "extension_status",
                    width: this.props.boardWidth.medium, 
                    Filter: () => (
                        <FormControl
                            onChange={(e) =>
                                this.onFilterChange(e.target.value, "extension_status")
                            }
                        />
                    ),
                    Cell: (extensionObj) => {
                        return this.getStatus(extensionObj);
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.ExternalNumber,
                    accessor: "display_number",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "display_number")}
                        />
                    ),
                    Cell: (extensionObj) => {
                        let rowData = extensionObj.value;
                        if (
                            extensionObj.original.extension_status !== "inbound" &&
                            extensionObj.original.extension_status !== "outbound" &&
                            extensionObj.original.extension_status !== "call" &&
                            extensionObj.original.extension_status !== "hold"
                        ) {
                            rowData = "";
                        }
                        if (extensionObj.original.inbound_alert) {
                            return <div className="callmonitor-inbound-alert-text">{rowData}</div>;
                        }
                        return <div>{rowData}</div>;
                    }
                });

                itemArr.push({
                    Header: this.props.langText.Body.TalkingTime,
                    accessor: "calling_time",
                    Filter: () => (
                        <FormControl
                            onChange={(e) => this.onFilterChange(e.target.value, "calling_time")}
                        />
                    ),
                    Cell: (extensionObj) => {
                        let rowData;
                        if (
                            extensionObj.value &&
                            extensionObj.value !== "Invalid date" &&
                            (extensionObj.original.extension_status === "inbound" ||
                                extensionObj.original.extension_status === "outbound" ||
                                extensionObj.original.extension_status === "call" ||
                                extensionObj.original.extension_status === "hold")
                        ) {
                            rowData = extensionObj.value;
                        }
                        if (extensionObj.original.inbound_alert) {
                            return <div className="callmonitor-inbound-alert-text">{rowData}</div>;
                        }
                        return <div>{rowData}</div>;
                    }
                });
                break;
        }

        return itemArr;
    }

    //オペレーター詳細のステータス表示
    getStatus = (extensionObj = null) => {
        let rest_memo = extensionObj && extensionObj.original && extensionObj.original.rest_memo || "";
        let value = extensionObj && extensionObj.value || "";
        let inbound_alert = extensionObj && extensionObj.original && extensionObj.original.inbound_alert || "";
        
        let {
            LogIn,
            Inbound,
            Outbound,
            Call,
            Hold,
            Ready,
            Work,
            Rest,
            Rejection
        } = this.props.langText.Body.ExtensionStatusList;

        let statusListObj = {
            login: {label: LogIn, class: "cm-status-login"},
            inbound: {label: Inbound, class: "cm-status-ready"},
            outbound: {label: Outbound, class: "cm-status-ready"},
            call: {label: Call, class: "cm-status-calling"},
            hold: {label: Hold, class: "cm-status-calling"},
            ready: {label: Ready, class: "cm-status-ready"},
            work: {label: Work, class: "cm-status-work"},
            rest: {label: Rest + ":" + rest_memo, class: "cm-status-rest"},
            rejection: {label: Rejection, class: ""}
        };

        let statusData = "";

        if(value.trim() === ""){
            statusData = statusListObj.login;
        } else if(extensionObj){
            statusData = statusListObj[value];
        }

        return [
            <div className={statusData.class + " cm-status-common"+ (inbound_alert && " callmonitor-inbound-alert-text")} key = {0}>
                {statusData.label}
            </div>
        ];
    }

    extensionErrorAlert = ({ error_count, error_message }) => {
        if (error_count > 3) {
            alert(this.getErrorString(error_message));
            this.setState({ extension_error_count: 0 });
        } else {
            this.setState({
                extension_error_count: error_count + 1
            });
        }
    };

    getExtensionNumData = ({ department_id = null, external_id = null }) => {
        if (department_id) {
            this.ascAxios("post", `CallMonitor/getExtensionNumData`, {
                company_id: this.state.company_id,
                department_id: department_id,
                external_id: external_id
            })
                .then((res) => {
                    const prevExtensionList = this.state.extensionList;
                    const result = {};
                    let temp = Object.values(res.data.obj);
                    temp.forEach((row) => {
                        let row_time = null;
                        if (row.updateAt) {
                            row_time = row.updateAt;
                        } else {
                            row_time = row.createAt;
                        }
                        let update_time = new Date(row_time).getTime();
                        let prev_time = null;
                        let prev_extension = prevExtensionList[row.extension_number];
                        if (prev_extension) {
                            if (prev_extension.updateAt) {
                                prev_time = new Date(prev_extension.updateAt).getTime();
                            } else {
                                prev_time = new Date(prev_extension.createAt).getTime();
                            }
                        }
                        if (update_time >= prev_time || prev_time === null) {
                            result[row.extension_number] = row;
                        } else if (update_time < prev_time) {
                            result[row.extension_number] = prev_extension;
                        }
                    });
                    this.setState({
                        extensionList: result,
                        assignExtensionList: res.data.extensionNumber
                    });
                    this.setExtensionStatusData();
                    this.updateCallTime();
                    this.updateRestTime();
                })
                .catch((err) => {
                    this.extensionErrorAlert({
                        error_count: this.state.extension_error_count,
                        error_message: err
                    });
                });
        }
    };

    getLoginExtensionList = ({ company_id, department_id }) => {
        this.ascAxios("post", `CallMonitor/getExtensionStatusList`, {
            company_id: company_id,
            department_id: department_id
        })
            .then((res) => {
                const result = this.state.extensionList;
                res.data.extensionStatus
                    .filter((row) => row.login === true && row.operator_login === true)
                    .forEach((row) => {
                        result[row.extension_number] = row;
                    });
                this.setState({
                    extensionList: result
                });
                this.setExtensionStatusData();
                this.updateCallTime();
                this.updateRestTime();
            })
            .catch((err) => {
                alert(this.getErrorString(err));
            });
    };

    setExtensionStatusData(data) {
        const extensionList = this.state.extensionList;
        if (data && extensionList[data.extension_number]) {
            Object.assign(extensionList[data.extension_number], data);
        }

        const currentDate = moment(new Date());
        const temp = Object.values(extensionList);

        const sortedInboundTemp = temp
            .filter(
                (row) =>
                    row.extension_status === "inbound" &&
                    moment.duration(currentDate.diff(new Date(row.call_time))).asSeconds() >=
                        this.state.inbound_alert_time
            )
            .sort((a, b) => new Date(a.call_time) - new Date(b.call_time));
        const dataTemp = temp.filter(
            (row) =>
                row.extension_status !== "inbound" ||
                moment.duration(currentDate.diff(new Date(row.call_time))).asSeconds() <
                    this.state.inbound_alert_time
        );

        sortedInboundTemp.forEach((row) => {
            row.inbound_alert = true;
        });
        dataTemp.forEach((row) => {
            row.inbound_alert = false;
        });
        const result = sortedInboundTemp.concat(dataTemp);

        this.setState({
            render_item: result.filter(row => row.login && row.operator_login),
            currentExtensionTableLength: result.length,
            extensionSummary: [
                {
                    loginExtension: result.filter(
                        (row) => row.operator_login === true && row.login === true
                    ).length,
                    readyExtension: result.filter(
                        (row) => row.extension_status === "ready" && row.login === true && row.operator_login === true
                    ).length,
                    callExtension: result.filter(
                        (row) =>
                            (row.extension_status === "inbound" ||
                                row.extension_status === "outbound" ||
                                row.extension_status === "call" ||
                                row.extension_status === "hold") &&
                            row.login === true && row.operator_login === true
                    ).length,
                    workExtension: result.filter(
                        (row) => row.extension_status === "work" && row.login === true && row.operator_login === true
                    ).length,
                    rest_time_05: 0,
                    rest_time_30: 0,
                    rest_time_45: 0,
                    rest_time_60: 0
                }
            ]
        });
    }

    updateExtensionList(data) {
        if (!_.isEqual(data, this.state.prevExtensionTemp)) {
            let objTemp = this.state.extensionList;

            let prevUpdateTime = objTemp[data.extension_number]
                ? new Date(
                      objTemp[data.extension_number].updateAt ||
                          objTemp[data.extension_number].createAt
                  ).getTime()
                : 0;
            let newUpdateTime = new Date(data.updateAt || data.createAt).getTime();

            if (prevUpdateTime < newUpdateTime || !objTemp[data.extension_number]) {
                if(data) {
                    if (data.login && data.operator_login) {
                        objTemp[data.extension_number] = data;
                    } else if (!data.operator_login || !data.login) {
                        delete objTemp[data.extension_number];
                    }
                }

                this.setExtensionStatusData(data);
                this.setState({ prevExtensionTemp: data });
            }
        }
    }

    render() {
        return (
            <Col>
                {this.state.company_id ? (
                    <ExtensionStatus
                        company_id={this.state.company_id}
                        department_id={this.state.department_id}
                        isExternalSearchMode={this.state.isExternalSearchMode}
                        assignExtensionList={this.state.assignExtensionList}
                        external_department_id={this.state.external_department_id}
                        updateExtensionList={(e) => this.updateExtensionList(e)}
                    />
                ) : null}

                <div style={{ minHeight: 240 }}>
                    <Col xs={12} md={12}>
                        <Col sm={9} md={9} lg={9}>
                            <h3>{this.props.langText.Body.OperatorInfo}</h3>
                        </Col>
                    </Col>
                    <Col xs={12} md={12} className="margin-top-03 callmonitor-table-col">
                        <CommonTable
                            columns={this.getColumnsData("extension_summary")}
                            data={this.state.extensionSummary}
                            style={{ zIndex: 1 }}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                            showPagination={false}
                            minRows={1}
                        />
                    </Col>
                </div>
                <div>
                    <Col xs={12} md={12}>
                        <Col xs={4} sm={6} md={7} lg={8}>
                            <h3>{this.props.langText.Body.OperatorDetail}</h3>
                        </Col>
                    </Col>
                    <Col xs={12} md={12} className="margin-top-03 callmonitor-table-col">
                        <CommonTable
                            columns={this.getColumnsData("extension_number")}
                            data={this.state.render_item}
                            style={{ height: 320, zIndex: 1 }}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                            showPagination={false}
                            defaultPageSize={this.state.defaultExtensionTableLength}
                            pageSize={
                                this.state.currentExtensionTableLength >
                                this.state.defaultExtensionTableLength
                                    ? this.state.currentExtensionTableLength
                                    : this.state.defaultExtensionTableLength
                            }
                            minRows={8}
                        />
                    </Col>
                </div>
            </Col>
        );
    }
}

export const ExtensionStatus = ({
    company_id,
    department_id,
    assignExtensionList,
    isExternalSearchMode,
    external_department_id,
    updateExtensionList
}) => {
    const gqlData = Appsync.subscriptions.onExtensionStatusByCompanyId;
    const variables = {
        company_id: company_id
    };
    const [prevData, setPrevData] = useState(null);

    if (!Array.isArray(department_id)) {
        department_id = [department_id];
    }

    if (company_id) {
        const { data, loading, error } = useSubscription(gql(gqlData), {
            variables: variables
        });
        if (
            prevData !== data &&
            data &&
            data.onExtensionStatusByCompanyId.updateAt &&
            (!prevData ||
                prevData.onExtensionStatusByCompanyId.updateAt !==
                    data.onExtensionStatusByCompanyId.updateAt)
        ) {
            setPrevData(data);
            if (
                data &&
                assignExtensionList &&
                assignExtensionList.find(
                    (row) =>
                        row.extension_number == data.onExtensionStatusByCompanyId.extension_number
                )
            ) {
                if (
                    !isExternalSearchMode &&
                    department_id.find(
                        (row) => row == data.onExtensionStatusByCompanyId.department_id
                    )
                ) {
                    updateExtensionList(data.onExtensionStatusByCompanyId);
                } else if (
                    isExternalSearchMode &&
                    external_department_id &&
                    external_department_id.find(
                        (row) => row == data.onExtensionStatusByCompanyId.department_id
                    )
                ) {
                    updateExtensionList(data.onExtensionStatusByCompanyId);
                }
            }
        }
    }
    return null;
};
