import React from "react";
import {Button, Row, Col, Glyphicon, FormControl} from "react-bootstrap";
import AscComponent from "../../components/AscComponent";
import CommonTable from "../../containers/Elements/Table/CommonTable";
import PbxModal from "./PbxModal";


export default class Pbx extends AscComponent {
    constructor(props) {
        super(props);

        this.gatewayInfoSet = this.gatewayInfoSet.bind(this);

        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            gatewaySelect: [],
            gatewaySelectArr: [],

            // data item
            pbxId: "",
            pbxName: "",
            hostName: "",
            publicIpAddress: "",
            privateIpAddress: "",
            wssPort: "",
            bindPort: "",
            ftpPort: "",
            memo: "",
            gwInfos: [],
            oemSelectList: [],
            oemFlag: false,
            oemSelect: null,
            oemName: "",
            instanceId: "",

            monitoringFlag: true,
            monitoringFlgCheck: this.getSelectOption('monitoring_flg', this.props.langText.SelectOption),
            regionList: this.getSelectOption('region', this.props.langText.SelectOption)
        };

        
    }

    getColumnsData() {
        return [
            {
                Header: this.props.langText.Body.Id,
                accessor: "id",
                filterable: false,
                width: this.props.boardWidth.id,
            }, {
                Header: this.props.langText.Body.PbxName,
                accessor: "pbx_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "pbx_name")} />
            }, {
                Header: this.props.langText.Body.HostName,
                accessor: "host_name",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "host_name")} />
            }, {
                Header: this.props.langText.Body.PublicIP,
                accessor: "public_ipaddress",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "public_ipaddress")} />
            }, {
                Header: this.props.langText.Body.WssPort,
                accessor: "wss_port",
                width: this.props.boardWidth.smedium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "wss_port")} />
            }, {
                Header: this.props.langText.Body.BindPort,
                accessor: "bind_port",
                width: this.props.boardWidth.smedium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "bind_port")} />
            }, {
                Header: this.props.langText.Body.FtpPort,
                accessor: "ftp_port",
                width: this.props.boardWidth.small,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "ftp_port")} />
            }, {
                Header: this.props.langText.Body.Memo,
                accessor: "memo",
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "memo")} />
            }, {
                Header: this.props.langText.Body.Control,
                filterable: false,
                sortable: false,
                width: this.props.boardWidth.LargeControl,
                Cell: data => {
                    let rowData = [];

                        if (this.props.currentPermission.edit) {
                            rowData.push(
                                <Button
                                    key={0}
                                    bsSize="xsmall"
                                    onClick={event => this.modalShow(event, "update", data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="pencil" />
                                </Button>
                            );
                            rowData.push(
                                <Button
                                    key={1}
                                    bsSize="xsmall"
                                    onClick={event => this.modalShow(event, 'gwAssignPbx', data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="link"/>
                                </Button>
                            );
                        } else {
                            rowData.push(
                                <Button
                                    key={2}
                                    bsSize="xsmall"
                                    onClick={event => this.modalShow(event, "read", data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="eye-open" />
                                </Button>
                            );
                        }

                        if (this.props.currentPermission.delete) {
                            rowData.push(
                                <Button
                                    key={3}
                                    bsSize="xsmall"
                                    onClick={event => this.modalShow(event, "delete", data.row._original)}
                                    className="control-button"
                                >
                                    <Glyphicon glyph="minus" />
                                </Button>
                            );
                        }

                    return (
                        <Row className="text-center">
                            {rowData}
                        </Row>
                    );
                }
            }
        ]
    }

    gatewayInfoSet(event, param, index) {
        let myValue = event.value || event.target.value;
        let nowStateData = [...this.state["gwInfos"]];
        nowStateData[index][param] = myValue;
        this.setState({"gwInfos": nowStateData});
        this.getGwSelect(nowStateData);
    }

    getGwSelect = (gwInfos) => {
        let gwSelectArr = [],
            template = this.state.gatewaySelect,
            selectValues = gwInfos.map(row => {
                return row.gateway_id
            });
        selectValues.forEach((row, index) => {
            let nowTemplate = [...template],
                nowSetSelect = [...selectValues];

            // 僕のデータを選択されたArrから削除
            nowSetSelect.splice(index, 1);

            // Templateから選択されたデータ削除
            nowSetSelect.forEach(selected => {
                if (selected) {
                    let selectedIndex = nowTemplate.findIndex(data => data.value === selected);

                    if (selectedIndex !== -1) {
                        nowTemplate.splice(selectedIndex, 1);
                    }
                }
            });
            gwSelectArr.push(nowTemplate);
        });

        this.setState({gatewaySelectArr: gwSelectArr});
    }

    async modalShow(event, modalType, param) {
        event.preventDefault();
        
        this.getGatewaySelect();
        await this.setOemSelectData();

        let setData = {
            pbxId: param && param.id
                ? param.id
                : "",
            pbxName: param && param.pbx_name
                ? param.pbx_name
                : "",
            hostName: param && param.host_name
                ? param.host_name
                : "",
            publicIpAddress: param && param.public_ipaddress
                ? param.public_ipaddress
                : "",
            privateIpAddress: param && param.private_ipaddress
                ? param.private_ipaddress
                : "",
            wssPort: param && param.wss_port
                ? param.wss_port
                : "",
            bindPort: param && param.bind_port
                ? param.bind_port
                : "",
            ftpPort: param && param.ftp_port
                ? param.ftp_port
                : "",
            memo: param && param.memo
                ? param.memo
                : "",
            gwInfos: param && param.gwInfos
                ? [...param.gwInfos]
                : [],
            oemId: param && param.oem_id
                ? param.oem_id
                : "",
            oemFlag: param && param.oem_id
                ? param.oem_id ? true : false
                : "",
            monitoringFlag: param && param.monitoring_flag
                ? param.monitoring_flag === "Y" ? true : false
                : true,
            instanceId: param && param.instance_id
                ? param.instance_id
                : "",    
            region: param && param.region
                ? param.region
                : "",    
            modalType,
            show: true
        };
        this.getGwSelect((param && param.gwInfos) ? param.gwInfos : []);
        this.setState(setData);
    };

    getGatewaySelect = () => {
        this
            .ascAxios('post', `Common/gatewaySelect`)
            .then(res => {
                this.setState({gatewaySelect: res.data});
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data));
            });
    };


    onClickHandle = modalType => event => {
        let {
            pbxId,
            pbxName,
            hostName,
            publicIpAddress,
            privateIpAddress,
            wssPort,
            bindPort,
            ftpPort,
            memo,
            gwInfos,
            oemId,
            monitoringFlag,
            instanceId,
            region,
        } = this.state;

        switch (modalType) {
            case "insert":
                let result_data = "";
                // コンテキスト情報取得
                this.ascAxios("post", `${this.reactContainerPath}/context_get`, {})
                    .then((result) => {
                        // 検索結果が取れた場合(1個以上PBX存在している場合)
                        if (result.data != null) {
                            result_data = result.data.pbx_context_code;
                            // 情報取得できたか確認
                            result_data = parseInt(result_data, 16) + 1; // 16進から10進に変換して1追加
                            // 16進再変換して左詰め3桁調整
                            result_data = ("000" + result_data.toString(16)).slice(-3);
                        }
                        // データが存在しない場合(PBX0個の場合)
                        else {
                            result_data = "000";
                        }

                        this.blockUI();
                        this.ascAxios("post", `${this.reactContainerPath}/insert`, {
                            pbxId,
                            pbxName,
                            hostName,
                            publicIpAddress,
                            privateIpAddress,
                            wssPort,
                            bindPort,
                            ftpPort,
                            memo,
                            result_data,
                            oemId,
                            monitoringFlag,
                            instanceId: instanceId || null,
                            region
                        })
                            .then((result) => {
                                alert(this.props.langText.Message.DataInsertSuccess);
                                this.reactTableRefresh();
                            })
                            .catch((err) => {
                                alert(this.getErrorString(err.response.data));
                            });
                    })
                    .catch((err) => {
                        // 失敗
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "update":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/update`, {
                    pbxId,
                    pbxName,
                    hostName,
                    publicIpAddress,
                    privateIpAddress,
                    wssPort,
                    bindPort,
                    ftpPort,
                    memo,
                    oemId,
                    monitoringFlag,
                    instanceId: instanceId || null,
                    region
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "gwAssignPbx":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/gwAssignUpdate`, {
                    pbxId,
                    gwInfos,
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataUpdateSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            case "delete":
                this.blockUI();
                this.ascAxios("post", `${this.reactContainerPath}/delete`, {
                    pbxId,
                    pbxName,
                    hostName,
                    publicIpAddress,
                    privateIpAddress,
                    wssPort,
                    bindPort,
                    ftpPort,
                })
                    .then((result) => {
                        alert(this.props.langText.Message.DataDeleteSuccess);
                        this.reactTableRefresh();
                    })
                    .catch((err) => {
                        alert(this.getErrorString(err.response.data));
                    });
                break;

            default:
                alert(this.getErrorString({ code: "modal" }));
                break;
        }

        this.setState({show: false});
    };

    validationHandle = (param, index = null) => {
        let nowState = null;
        let {
            pbxName,
            hostName,
            publicIpAddress,
            privateIpAddress,
            wssPort,
            bindPort,
            ftpPort,
            oemFlag,
            oemId,
            monitoringFlag,
            instanceId,
            region
        } = this.state;

        switch (param) {
            case "pbxName":
            case "hostName":
            case "publicIpAddress":
            case "privateIpAddress":
            case "wssPort":
            case "bindPort":
            case "ftpPort":
                nowState = this.state[param]
                    ? null
                    : "error";
                break;

            case "insert":
            case "update":
            case "delete":
                nowState = (
                    pbxName && hostName && publicIpAddress && privateIpAddress && wssPort && bindPort && ftpPort
                );
                if (oemFlag) {
                    //oem会社を選択する場合は必須入力
                    nowState = nowState && (oemId ? true : false);
                }
                if (monitoringFlag) {
                    //モニタリングする場合はインスタンスIDとリージョンの入力必須
                    nowState = nowState && (region && instanceId ? true : false);
                }
                break;

            case "gateway_id":
            case "register_id":
            case "register_pw":
                if (this.state["gwInfos"][index] && this.state["gwInfos"][index][param]) {
                    nowState = null;
                } else {
                    nowState = "error";
                }

                break;

            case "gwAssignPbx":
                nowState = true;

                this.state["gwInfos"].forEach(row => {
                    if (!row.gateway_id || !row.register_id || !row.register_pw) {
                        nowState = false;
                    }
                });
                break;

            case "oemSelect":
                if (oemFlag) {
                    nowState = oemId ? true : false;
                }
                break;

            case "instanceId":
            case "region":
                if (monitoringFlag) {
                    nowState = this.state[param] ? true : false;
                }
                break;
            default:
                break;
        }

        return nowState;
    };

    /**
     * OEM管理会社一覧を取得する
     */
    setOemSelectData = async() => {
        try {
            const oemList = await this.ascAxios('post', `Common/oemSelect`);
            this.setState({oemSelectList: oemList.data});
        } catch(err) {
            alert(this.getErrorString(err.message));
        }
    }

    render() {
        return (
            <div className="Pbx">
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id="pbx-insert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={event => this.modalShow(event, "insert")}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                </Row>

                <PbxModal
                    state = {this.state}
                    propSetState = {this.propSetState}
                    langText = {this.props.langText}
                    charaLimit = {this.props.charaLimit}
                    onClick = {this.onClickHandle}
                    onTextChange_Limit = {this.onTextChange_Limit}
                    onIntChange = {this.onIntChange}
                    onKeyDownIntCheck = {this.onKeyDownIntCheck}
                    onSelectChange = {this.onSelectChange}
                    onMultiSelectChange = {this.onMultiSelectChange}
                    onCheckBoxChange = {this.onCheckBoxChange}
                    onRadioChange = {this.onRadioChange}
                    onGwChange = {this.gatewayInfoSet}
                    getGwSelect = {this.getGwSelect}
                    validationHandle = {this.validationHandle}
                    onCheckBoxChange_Init = {this.onCheckBoxChange_Init}
                />
            </div>
        );
    }
}
