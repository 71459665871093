import React from 'react'
import {Button, Row, Col, Glyphicon, FormControl} from 'react-bootstrap'
import AscComponent from '../../components/AscComponent'
import CommonTable from '../../containers/Elements/Table/CommonTable'
import UserModal from './UserModal'
import UserCsvInsertModal from './UserCsvInsertModal'
import ErrorMessageModal from "../Elements/AscElements/ErrorMessageModal";
import * as GlobalConst from '../../components/AscConstants';

export default class User extends AscComponent {
    constructor(props) {
        super(props)
        this.state = {
            // table item
            columns: this.getColumnsData(),
            data: [],
            filtered: [],
            pages: null,
            loading: false,

            // modal item
            show: false,
            modalType: null,

            // select item
            companySelect: [],
            departmentSelect: [],
            floorSelect: [],

            // data item
            userId:         '',
            company_id:     '',
            companyName:    '',
            department_id:  '',
            department_name: '',
            department_prefix: '',
            floor_id:       '',
            floor_name:     '',
            mailAddress:    '',
            firstNameCc:    '',
            lastNameCc:     '',
            firstNameKana:  '',
            lastNameKana:   '',
            password:       '',
            passwordRe:     '',
            oldPassword:    '',
            newPassword:    '',
            newPasswordRe:  '',
            downloadflag:   '',
            passwordResetFlag: false,
            // button_disabled
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load": [],
                "floor_load" : []
            },
            permissionSelect: [],
            loginLockFlag: false,
            csvFile: false,
            errorMessageModalShow: false,
            csvInsertLoadingFlag: false,
            user_affiliation_flag: false,

            // CSVヘッダー
            headerJson : [
                {
                    label: this.props.langText.Body.EmailAddress,
                    key: "mailAddress",
                    required: true
                },
                {
                    label: this.props.langText.Body.FirstNameCc + 
                    "(" + this.props.langText.Body.ChineseCharacter + ")",
                    key: "firstNameCc",
                    required: true
                },
                {
                    label: this.props.langText.Body.LastNameCc + 
                    "(" + this.props.langText.Body.ChineseCharacter + ")",
                    key: "lastNameCc",
                    required: true
                },
                {
                    label: this.props.langText.Body.FirstNameKana + 
                    "(" + this.props.langText.Body.Katakana + ")",
                    key: "firstNameKana",
                    required: false
                },
                {
                    label: this.props.langText.Body.LastNameKana + 
                    "(" + this.props.langText.Body.Katakana + ")",
                    key: "lastNameKana",
                    required: false
                },
                {
                    label: this.props.langText.Body.Password,
                    key: "password",
                    required: true
                },
                {
                    label: this.props.langText.Body.PermissionName,
                    key: "permission",
                    required: true
                }
            ]
        }
        // csvヘッダ定義
        this.headerJson = [
            {
                label: this.props.langText.Body.EmailAddress,
                key: "mailAddress",
                required: true
            },
            {
                label: this.props.langText.Body.FirstNameCc + 
                "(" + this.props.langText.Body.ChineseCharacter + ")",
                key: "firstNameCc",
                required: true
            },
            {
                label: this.props.langText.Body.LastNameCc + 
                "(" + this.props.langText.Body.ChineseCharacter + ")",
                key: "lastNameCc",
                required: true
            },
            {
                label: this.props.langText.Body.FirstNameKana + 
                "(" + this.props.langText.Body.Katakana + ")",
                key: "firstNameKana",
                required: false
            },
            {
                label: this.props.langText.Body.LastNameKana + 
                "(" + this.props.langText.Body.Katakana + ")",
                key: "lastNameKana",
                required: false
            },
            {
                label: this.props.langText.Body.Password,
                key: "password",
                required: true
            },
            {
                label: this.props.langText.Body.PermissionName,
                key: "permission",
                required: true
            }
        ];
        this.floor_load_flag = false;
        this.csvReaderRef = React.createRef();
    }

    getColumnsData() {
        let itemArr = [];

        // oem以上は会社カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
            itemArr.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: 'cm12_companies.company_name',
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm12_companies.company_name")} />
            });
        }

        // company以上は拠点カラム表示
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            itemArr.push({
                Header: this.props.langText.Body.DepartmentName,
                accessor: 'cm13_departments.department_name',
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.department_name")} />
            });
        }

        if (
            (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
            (this.props.userInfo.floor_flg &&
                this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
        ) {
            itemArr.push({
                Header: this.props.langText.Body.FloorName,
                accessor: 'cm13_departments.floor_name',
                width: this.props.boardWidth.medium,
                Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm13_departments.floor_name")} />
            });
        }

        itemArr.push({
            Header: this.props.langText.Body.EmailAddress,
            accessor: 'mail_address',
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "mail_address")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.UserName,
            accessor: 'user_name_sei',
            filterable: false,
            width: this.props.boardWidth.medium,
            Cell: data => {
                return `${data.row._original.user_name_sei} ${data.row._original.user_name_mei}`
            },
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "user_name_sei")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.Auth,
            accessor: 'cm73_permissions.name',
            width: this.props.boardWidth.medium,
            Filter: () => <FormControl onChange={e => this.onFilterChange(e.target.value, "cm73_permissions.name")} />
        });

        itemArr.push({
            Header: this.props.langText.Body.LastLoginDate,
            filterable: false,
            width: this.props.boardWidth.Date,
            accessor: 'ct90_login_histories.created',
            Cell: data => {
                let returnData = (
                    data.row._original.ct90_login_histories
                    && data.row._original.ct90_login_histories.created)
                    ? this.getMomentTime({
                        date:   data.row._original.ct90_login_histories.created,
                        format: this.props.langText.Body.DateFormat
                        })
                    : ''

                return returnData
            }
        });

        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            // 会社管理者以上の場合、「ロック状態」列を表示
            itemArr.push({
                Header: this.props.langText.Body.UserLockedFlag,
                filterable: false,
                accessor: 'login_failure_count',
                width: this.props.boardWidth.medium,
                Cell: data => {
                    let returnData = (
                        data.row._original.login_failure_count >= this.props.systemParameters.CUSCON_LOGIN_FAILURE_COUNT_MAX
                            ? { label: this.props.langText.Body.UserLockedStatus, style: {color: "red"} }
                            : { label: "" }
                    )
                    return [
                        <div className="user_locked_status" style={returnData.style}>
                            {returnData.label}
                        </div>
                    ];
                }
            });
        }

        itemArr.push({
            Header: this.props.langText.Body.Control,
            filterable: false,
            sortable: false,
            width: this.props.boardWidth.LargeControl,
            Cell: data => {
                // 権限に応じて各アイコン表示
                let rowData = [];
                // 編集権限あれば編集ボタン、編集権限無ければ閲覧ボタン
                if (this.props.currentPermission.edit) {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("update", data.row._original)}
                        >
                            <Glyphicon glyph="pencil"/>
                        </Button>
                    );
                } else {
                    rowData.push(
                        <Button
                            key={0}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("read", data.row._original)}
                        >
                            <Glyphicon glyph="eye-open"/>
                        </Button>
                    );
                }
                if (this.props.currentPermission.create) {
                    rowData.push(
                        <Button
                            key={1}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("copy", data.row._original)}
                        >
                            <Glyphicon glyph="copy"/>
                        </Button>
                    );
                }
                if (this.props.currentPermission.delete) {
                    rowData.push(
                        <Button
                            key={2}
                            bsSize="xsmall"
                            className="control-button"
                            onClick={this.modalShow("delete", data.row._original)}
                        >
                            <Glyphicon glyph="minus"/>
                        </Button>
                    );
                }

                return (
                    <Row className="text-center">
                        {rowData}
                    </Row>
                );
            }
        });

        return itemArr;
    }

    // 画面表示タイミング共通処理_変数初期化
    modalshow_commonInit() {
        this.setState({
            buttondisabledArr: {
                "company_id" : [],
                "department_id" : [],
                "floor_id" : [],
                "department_load": [],
                "floor_load" : []
            }
        });
    };

    common_buttondisabledLengthCheck = (param,switchdata) => {
        switch (switchdata)
        {
            case `push`:
                if(this.state.buttondisabledArr[param].length === 0)
                {
                    this.state.buttondisabledArr[param].push("dataset");
                }
            break;
            case `pop`:
                this.state.buttondisabledArr[param].pop();
            break;
            default:
                console.log("common_buttondisabledLengthCheck_switchErr");
            break;
        }
    }

    // テンプレートの成型
    moldCsvHeader = (user_affiliation_flag) => {
        let mold_Array = this.headerJson;
            if (!user_affiliation_flag) {
                //OEM管理者以上
                let oemArray = 
                [
                    // 会社名
                    {
                        label: this.props.langText.Body.CompanyName,
                        key: "companyName",
                        required: true
                    },
                    // 拠点名
                    {
                        label: this.props.langText.Body.DepartmentName,
                        key: "departmentName",
                        required: true
                    }
                ];

                //会社管理者以上
                let companyArray = 
                [
                    // 拠点名
                    {
                        label: this.props.langText.Body.DepartmentName,
                        key: "departmentName",
                        required: true
                    }
                ];

                //拠点管理者以上
                let departmentArray = [];



                if (
                    (this.props.systemParameters.SYSTEM_FLOOR_FLG === "Y" &&
                        this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.system)) ||
                    (this.props.userInfo.floor_flg &&
                        this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department))
                ) {
                    //OEM管理者以上
                    oemArray = 
                    [
                        // 会社名
                        {
                            label: this.props.langText.Body.CompanyName,
                            key: "companyName",
                            required: true
                        },
                        // 拠点名
                        {
                            label: this.props.langText.Body.DepartmentName,
                            key: "departmentName",
                            required: true
                        },
                        // フロア名
                        {
                            label: this.props.langText.Body.FloorName,
                            key: "floorName",
                            required: true
                        }
                    ];

                    //会社管理者以上
                    companyArray = 
                    [
                        // 拠点名
                        {
                            label: this.props.langText.Body.DepartmentName,
                            key: "departmentName",
                            required: true
                        },
                        // フロア名
                        {
                            label: this.props.langText.Body.FloorName,
                            key: "floorName",
                            required: true
                        }
                    ];

                    //拠点管理者以上
                    departmentArray = 
                    [
                        // フロア名
                        {
                            label: this.props.langText.Body.FloorName,
                            key: "floorName",
                            required: true
                        }
                    ];
                }
                
                //OEM管理者以上
                if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)) {
                    mold_Array = [...oemArray,...this.headerJson];
                //会社管理者以上
                }else if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
                    mold_Array = [...companyArray,...this.headerJson];
                //拠点管理者以上
                }else if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) && this.props.userInfo.floor_flg) {
                    mold_Array = [...departmentArray,...this.headerJson];
                }
            }
        return mold_Array;
    }

    modalShow = (modalType, param) => event => {
        event.preventDefault()

        this.floor_load_flag = false;
        // 条件配列の初期化
        this.modalshow_commonInit();

        let setData = {
            userId: param && param.id
                ? param.id
                : '',
            company_id: param && param.cm12_companies && param.cm12_companies.id
                ? param.cm12_companies.id
                : '',
            companyName: param && param.cm12_companies && param.cm12_companies.company_name
                ? param.cm12_companies.company_name
                : '',
            floor_flg: param && param.cm12_companies && param.cm12_companies.floor_flg
                ? param.cm12_companies.floor_flg === "Y"
                : null,
            department_id: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            department_name: param && param.cm13_departments && param.cm13_departments.department_name
                ? param.cm13_departments.department_name
                : '',
            department_prefix: param && param.cm13_departments && param.cm13_departments.department_prefix
                ? param.cm13_departments.department_prefix
                : '',
            floor_id: param && param.cm13_departments && param.cm13_departments.id
                ? param.cm13_departments.id
                : '',
            floor_name: param && param.cm13_departments && param.cm13_departments.floor_name
                ? param.cm13_departments.floor_name
                : '',
            mailAddress: param && param.mail_address
                ? param.mail_address
                : '',
            firstNameCc: param && param.user_name_sei
                ? param.user_name_sei
                : '',
            lastNameCc: param && param.user_name_mei
                ? param.user_name_mei
                : '',
            firstNameKana: param && param.user_name_kana_sei
                ? param.user_name_kana_sei
                : '',
            lastNameKana: param && param.user_name_kana_mei
                ? param.user_name_kana_mei
                : '',
            password: '',
            passwordRe: '',
            oldPassword: '',
            newPassword: '',
            newPasswordRe: '',
            permission_id: param && param.cm73_permissions.id
                ? param.cm73_permissions.id
                : '',
            permission_name: param && param.cm73_permissions.name
                ? param.cm73_permissions.name
                : '',
            permissionSelectLoad: true,
            passwordResetFlag: false,
            user_affiliation_flag: false,
            headerJson: this.moldCsvHeader(false)
        }

        // 新規作成時にcompany以下なら会社固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
            setData.company_id = this.props.userInfo.company_id
            setData.companyName = this.props.userInfo.company_name
            setData.floor_flg = this.props.userInfo.floor_flg
        }
        // 新規作成時にdepartment以下なら拠点固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)) {
            if (this.props.userInfo.floor_flg) {
                setData.department_id = this.props.userInfo.parent_department_id
                setData.department_name = this.props.userInfo.parent_department_name
                setData.department_prefix = this.props.userInfo.department_prefix
            } else {
                setData.department_id = this.props.userInfo.department_id
                setData.department_name = this.props.userInfo.department_name
            }
        }
        // 新規作成時にfloor以下ならフロア固定
        if (!param && this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) {
            setData.floor_id = this.props.userInfo.department_id
            setData.floor_name = this.props.userInfo.floor_name
        }

        setData.loginLockFlag = (
            modalType === "update" &&
            this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) &&
            param &&
            param.login_failure_count >= this.props.systemParameters.CUSCON_LOGIN_FAILURE_COUNT_MAX
        );

        this.setState(setData)

        this.setPermissionSelect(setData.company_id, setData.floor_flg)

        this.getCompanySelect()
        this.getDepartmentSelect(setData, "update")
        this.getFloorSelect(setData)

        this.setState({show: true, modalType})
    }

    onClickHandle = modalType => async event => {
        let {
            userId,
            company_id,
            department_id,
            floor_id,
            mailAddress,
            firstNameCc,
            lastNameCc,
            firstNameKana,
            lastNameKana,
            password,
            oldPassword,
            newPassword,
            downloadflag,
            permission_id,
            csvFile,
            user_affiliation_flag
        } = this.state

        switch (modalType) {
            case 'copy':
            case 'insert':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/insert`, {
                        mailAddress,
                        firstNameCc,
                        lastNameCc,
                        firstNameKana,
                        lastNameKana,
                        password,
                        company_id,
                        permission_id,
                        department_id: floor_id || department_id,
                        downloadflag,
                    })
                    .then(result => {
                        alert(
                            `${result.data.mailAddress} ${this.props.langText.Message.User_DataInsertSuccess}`
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(
                            this.getErrorString(err.response.data)
                        )
                    })
                break

            case 'update':
                this.blockUI();
                this
                    .ascAxios('post', `${this.reactContainerPath}/update`, {
                        userId,
                        mailAddress,
                        firstNameCc,
                        lastNameCc,
                        firstNameKana,
                        lastNameKana,
                        oldPassword,
                        newPassword,
                        company_id,
                        department_id: floor_id || department_id,
                        downloadflag,
                        permission_id,
                    })
                    .then(result => {
                        alert(
                            this.props.langText.Message.DataUpdateSuccess
                        )
                        this.reactTableRefresh()
                    })
                    .catch(err => {
                        alert(
                            this.getErrorString(err.response.data)
                        )
                    })
                break

            case 'delete':
                if (window.confirm(`${this.props.langText.Message.User_Delete_Check}`)) {
                    this.blockUI();
                    this
                        .ascAxios('post', `${this.reactContainerPath}/delete`, {
                            userId,
                            mailAddress,
                            company_id,
                            permission_id,
                            department_id: floor_id || department_id,
                        })
                        .then(result => {
                            alert(
                                this.props.langText.Message.DataDeleteSuccess
                            )
                            this.reactTableRefresh()
                        })
                        .catch(err => {
                            alert(
                                this.getErrorString(err.response.data)
                            )
                        })
                }
                break

            case "csvInsert":
                this.blockUI();
                this.setState({
                    csvInsertLoadingFlag: true,
                    loadingDisplayFlag: true
                }); 

                let result = await this.getCsvData(csvFile);
                let insertRow = result.insertArray;
                let errData = result.errorData;
                let errMessage = [];

                if (errData && errData.length > 0) {
                    errMessage.push(this.props.langText.Message[errData[0]]);
                    // エラーがある場合のメッセージ作成処理
                    switch (errData[0]) {
                        case "CsvTooManyRows":
                            errMessage[0] = this.sprintf(
                                this.props.langText.Message.CsvTooManyRows,
                                this.props.systemParameters.USER_CSV_ROW_COUNT_MAX_LIMIT
                            );
                            break;
                        case "CsvHeaderError":
                            // ヘッダーエラー
                            break;
                        case "CsvNotAvailable":
                            // バリデーションエラー
                            errMessage.push(
                                this.sprintf(
                                    this.props.langText.Message.FailedDataCount,
                                    errData[1].length
                                )
                            );
                            errData[1].forEach((err) => {
                                errMessage.push(
                                    this.sprintf(this.props.langText.Message.Line, err.row + 2) +
                                        "　" +
                                        err.message
                                );
                            });
                            break;
                        default:
                            throw new Error("ProcessingFailedError");
                            break;
                    }
                    this.setState({
                        errorMessageModalShow: true,
                        errText: errMessage.join("\r\n"),
                        csvInsertLoadingFlag: false,
                        loadingDisplayFlag: false
                    });
                    this.reactTableRefresh();
                    return;
                } else {
                    // DBとCognitoへの登録処理
                    let result = await this.ascAxios("post", `${this.reactContainerPath}/csvInsert`, {
                        company_id,
                        permission_id,
                        department_id: floor_id || department_id,
                        insertRow,
                        user_affiliation_flag
                    });
                    if (result.data.cognitoErrMailAddress.length === 0) {
                        // エラーが1件もない場合、全メールアドレス登録成功メッセージを表示
                        alert(this.sprintf(
                            this.props.langText.Message.CsvRegisterSuccess,
                            this.props.langText.Body.User, 
                            insertRow.length));
                    } else {
                        // Cognitoへの登録が失敗したメールアドレスをメッセージで表示
                        let userCsvRegisterErrorMsg = this.sprintf(
                            this.props.langText.Message.CsvRegisterError,
                            insertRow.length,
                            result.data.cognitoErrMailAddress.length,
                            this.props.langText.Body.User, 
                        );
                        for ( let errId of result.data.cognitoErrMailAddress ) {
                            userCsvRegisterErrorMsg += "\r\n" + "・" + errId;
                        }
                        alert( userCsvRegisterErrorMsg );
                    }
                    this.reactTableRefresh();
                    this.setState({ 
                        show: false,
                        csvInsertLoadingFlag: false,
                        loadingDisplayFlag: false
                    });
                }
                break;

            default:
                alert(
                    this.getErrorString({code: 'modal'})
                )
        }

        this.setState({
            show: false
        })
    }

    onClickCancelLockingBtn = (userId) => async event => {
        try{
            await this.ascAxios('post', `${this.reactContainerPath}/userLockingCancel`, {
                userId
            })
            alert(this.props.langText.Message.User_CancelUserLockingSuccess);
            this.reactTableRefresh();
        } catch(err) {
            console.error(err);
            this.showErrorObjectMesssage(err);
        }

        this.setState({show: false});
    }

    onSelectChange (event, param) {
        super.onSelectChange(event, param);
        let value = (event && event.value) ? event.value : "";
        let department_prefix = (event && event.department_prefix) ? event.department_prefix : "";
        let floor_flg = (event && event.floor_flg) ? event.floor_flg === "Y" : "";
        let permission_json_data = (event && event.json_data) ? event.json_data : "";

        if (param === "company_id") {
            this.setState({
                department_id: '',
                floor_id: '',
                permission_id: '',
                permission_json_data: "",
                floor_flg: floor_flg
            });
            this.getDepartmentSelect({company_id: value, floor_flg: floor_flg});
            this.getFloorSelect({
                company_id: this.state.company_id,
                department_id: value,
                floor_flg: this.state.floor_flg,
                department_prefix: department_prefix
            });
            this.setPermissionSelect(value, floor_flg);
        } else if (param === "department_id") {
            this.floor_load_flag = true;
            this.setState({floor_id: ''});
            this.getFloorSelect({
                company_id: this.state.company_id,
                department_id: value,
                department_prefix: department_prefix,
                floor_flg: this.state.floor_flg,
            });
        } else if (param === "permission_id") {
            this.setState({
                permission_id: value,
                permission_json_data: permission_json_data
            });
        }
    }

    onCheckBoxChange(event, param){
        super.onCheckBoxChange(event, param);

        if (param === "user_affiliation_flag") {
            let initIds = {};
            if (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)) {
                initIds = {
                    department_id: '',
                    floor_id: ''
                }
                
            } else {
                initIds = {
                    company_id: '',
                    department_id: '',
                    floor_id: ''
                }
            }
            this.setState({...initIds, headerJson: this.moldCsvHeader(event.currentTarget.checked)});
            this.removeCsvFile();
            // CSVファイルを削除する
            this.csvReaderRef.current.removeFile();
        }
    }

    getCompanySelect = () => {
        this
            .ascAxios('post', `Common/companySelect`, {container: this.reactContainerPath})
            .then(res => {
                this.common_buttondisabledLengthCheck(`company_id`,`push`);
                this.setState({
                    companySelect: res.data
                })
            })
            .catch(err => {
                alert(
                    this.getErrorString(err.response.data)
                )
            })
    }

    getDepartmentSelect = ({company_id, floor_flg, department_id, department_prefix}, type) => {
        if(company_id)
        {
            this.common_buttondisabledLengthCheck(`department_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`department_load`,`pop`);
        }
        this
            .ascAxios('post', `Common/departmentSelect`, {container: this.reactContainerPath, company_id, floor_flg})
            .then(res => {
                if (type === "update" && floor_flg && department_id && department_prefix) {
                    let my_prefix = department_prefix.slice(0, 2);
                    res.data.forEach(row => {
                        if (row.department_prefix && my_prefix === row.department_prefix) {
                            this.setState({department_id: row.value});
                        }
                    });
                }

                if(company_id)
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`push`);
                    this.common_buttondisabledLengthCheck(`department_load`,`pop`);
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`department_id`,`pop`);
                }

                this.setState({
                    departmentSelect: res.data
                })
            })
            .catch(err => {
                alert(
                    this.getErrorString(err.response.data)
                )
            })
    }

    /**
     * 権限一覧取得
     * @param company_id
     * @param floor_flg
     */
    async setPermissionSelect(company_id = null, floor_flg) {

        try {
            let result = await this.ascAxios('post', `Common/getPermissions`, {
                container: this.reactContainerPath,
                company_id: company_id,
                floor_flg: floor_flg
            })
            this.setState({
                permissionSelect: result.data,
                permissionSelectLoad: false
            })
        } catch (err) {
            alert(this.getErrorString(err.response.data))
            return null;
        }
    }

    getFloorSelect = ({company_id, department_id, department_prefix, floor_flg}) => {
        if(this.floor_load_flag === true && department_id && company_id)
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`push`);
        }
        else
        {
            this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
        }
        if (floor_flg) {
            this.ascAxios('post', `Common/floorSelect`, {container: this.reactContainerPath, company_id, department_prefix})
            .then(res => {
                if(this.state.department_id)
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`push`);
                    this.common_buttondisabledLengthCheck(`floor_load`,`pop`);
                    this.floor_load_flag = false;
                }
                else
                {
                    this.common_buttondisabledLengthCheck(`floor_id`,`pop`);
                }
                this.setState({
                    floorSelect: res.data
                })
            })
            .catch(err => {
                alert(this.getErrorString(err.response.data))
            })
        }
    }

    onRadioChangebool = (event,param,booltype) => {
        if(booltype === true)
        {
            this.setState({[param]: 'Y'});
        }
        else
        {
            this.setState({[param]: 'N'});
        }
    }

    validationHandle = param => {
        let validation_flag = true;

        let {
            mailAddress,
            firstNameCc,
            lastNameCc,
            company_id,
            department_id,
            floor_id,
            floor_flg,
            permission_id,
            permission_json_data,
            csvFile,
            csvInsertLoadingFlag
        } = this.state

        let emailCheck = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/

        let adminFlag = false;

        switch (param) {
            case 'mailAddress':
                validation_flag = emailCheck.test(this.state[param]);
                break;
            case 'firstNameCc':
            case 'lastNameCc':
                validation_flag = !!this.state[param];
                break;

            case 'company_id':
                validation_flag = !!this.state[param]
                break;

            case 'department_id':
            case 'floor_id':
                if (permission_json_data) {
                    let allScopeCode = permission_json_data.map(row => {
                        return row.scope_code
                    })
                    adminFlag = allScopeCode.every(row => {
                        return row === GlobalConst.SCOPE_OBJECT.oem.name || row === GlobalConst.SCOPE_OBJECT.system.name
                    })
                }
                validation_flag = !!this.state[param] || adminFlag;
                break;
            case 'permission_id':
                validation_flag = !!this.state[param]
                break;

            case 'copy':
            case 'insert':
                if (permission_json_data) {
                    let allScopeCode = permission_json_data.map(row => {
                        return row.scope_code
                    })
                    adminFlag = allScopeCode.every(row => {
                        return row === GlobalConst.SCOPE_OBJECT.oem.name || row === GlobalConst.SCOPE_OBJECT.system.name
                    })
                }
                validation_flag = (
                    emailCheck.test(mailAddress)
                    && firstNameCc
                    && lastNameCc
                    && this.passwordValidationHandle(param, this.state)
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
                        || (company_id !== null && company_id !== ''))
                    && (adminFlag || (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                        || (department_id !== null && department_id !== '')))
                    && (adminFlag || !floor_flg
                        || (floor_flg && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)
                            || (floor_id !== null && floor_id !== ''))))
                    && permission_id
                )
                break;

            case 'update':
                if (permission_json_data) {
                    let allScopeCode = permission_json_data.map(row => {
                        return row.scope_code
                    })
                    adminFlag = allScopeCode.every(row => {
                        return row === GlobalConst.SCOPE_OBJECT.oem.name || row === GlobalConst.SCOPE_OBJECT.system.name
                    })
                }
                validation_flag = (
                    firstNameCc
                    && lastNameCc
                    && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company)
                        || (company_id    !== null && company_id    !== ''))
                    && (adminFlag || (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department)
                        || (department_id !== null && department_id !== '')))
                    && (adminFlag || !floor_flg
                        || (floor_flg && (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)
                            || (floor_id !== null && floor_id !== ''))))
                    && permission_id
                    && this.passwordValidationHandle(param, this.state)
                );
                break;

            case 'delete':
                validation_flag = (
                    firstNameCc
                    && lastNameCc
                    && (company_id !== null && company_id !== '')
                    && (department_id !== null && department_id !== '')
                    && !floor_flg || (floor_flg && (floor_id !== null && floor_id !== ''))
                    && permission_id
                )
                break;
        
            case "csvInsert":
                if (this.state.user_affiliation_flag) {
                    validation_flag =
                        (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.company) ||
                            (company_id !== null && company_id !== "")) &&
                        (this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.department) ||
                            (department_id !== null && department_id !== "")) &&
                        (!floor_flg ||
                            (((floor_flg &&
                                this.getScopeLessThanEqual(GlobalConst.SCOPE_OBJECT.floor)) ||
                                (floor_flg !== null && floor_flg !== "")))) &&
                                csvFile && !csvInsertLoadingFlag
                }else{
                    validation_flag = csvFile && !csvInsertLoadingFlag
                }
                break;

            default:
                validation_flag = true;
                break;
        }
        return validation_flag;
    }

    /**
     * modalTypeにより分岐させる
     */
    getModalBranch = () => {
        switch (this.state.modalType) {
            case "insert":
            case "update":
            case "copy":
            case "delete":
            case "read":
                return (
                    <UserModal
                        getErrorString = {this.getErrorString}
                        getScopeGreaterEqual = {this.getScopeGreaterEqual}
                        currentPermission = {this.props.currentPermission}
                        floor_flg = {this.state.floor_flg}
                        loginUserId = {this.props.userInfo.user_id}
                        state = {this.state}
                        propSetState = {this.propSetState}
                        langText = {this.props.langText}
                        charaLimit = {this.props.charaLimit}
                        onClick = {this.onClickHandle}
                        onTextChange_Limit = {this.onTextChange_Limit}
                        onSelectChange = {this.onSelectChange}
                        onMultiSelectChange = {this.onMultiSelectChange}
                        onCheckBoxChange = {this.onCheckBoxChange}
                        onRadioChange = {this.onRadioChange}
                        onRadioChangebool = {this.onRadioChangebool}
                        validationHandle = {this.validationHandle}
                        onClickCancelLockingBtn = {this.onClickCancelLockingBtn}
                        passwordValidationHandle = {this.passwordValidationHandle}
                    />
                );
            case "csvInsert":
                return (
                    <UserCsvInsertModal
                        floor_flg={this.state.floor_flg}
                        getScopeGreaterEqual={this.getScopeGreaterEqual}
                        getScopeLessThanEqual={this.getScopeLessThanEqual}
                        state={this.state}
                        propSetState={this.propSetState}
                        langText={this.props.langText}
                        onSelectChange={this.onSelectChange}
                        onClick={this.onClickHandle}
                        onCheckBoxChange = {this.onCheckBoxChange}
                        handleOnDrop={this.handleOnDrop}
                        validationHandle={this.validationHandle}
                        charaLimit={this.props.charaLimit}
                        sprintf={this.sprintf}
                        getSelectOption={this.getSelectOption}
                        removeCsvFile={this.removeCsvFile}
                        csvReaderRef={this.csvReaderRef}
                        csvRowCountMaxLimit={this.props.systemParameters.USER_CSV_ROW_COUNT_MAX_LIMIT}/> 
                );
            default:
                break;
        }
    };

    /**
     * CSVファイルをアップロードまたはドラッグした際の読み込み処理
     * @param {} data
     * @param {} file
     */
    handleOnDrop = (data, file) => {
        let jsonData = data;

        // ファイル名チェック
        if (!file.name.match(/.csv$/i)) {
            alert(this.props.langText.Message.NotCsvFile);
            this.setState({ csvFile: false });
        } else {
            this.setState({ csvFile: jsonData });
        }
    };

    /**
     * アップロード済みのCSVファイルの削除処理
     */
    removeCsvFile = () => {
        this.setState({ csvFile: false });
    };

    /**
     * CSVデータをjsonカラムへ変換
     * @param {file} importFile
     * @return {array}
     *  insertArray DB(cm15)・cognitoへ登録する情報
     *  errorData [0]:エラーコード, [1]:エラー内容 errorDataに1件以上データがある場合、CSV登録は行われない
     */
    async getCsvData(importFile) {
        try {
            let insertArray = [];
            let errorData = [];
            let errorValidateUserCsv = [];

            if(importFile.slice(-1)[0].data.length === 1 && importFile.slice(-1)[0].data[0] === ""){
                // 最終行に改行が入っている場合、消去
                importFile.pop();
            }

            const csvHeaders = importFile[0].data.map((header) => header.trim());
            const list = importFile.filter((row, index) => {
                if (index !== 0) return row.data;
            });

            // CSV件数チェック
            if (list.length > this.props.systemParameters.USER_CSV_ROW_COUNT_MAX_LIMIT) {
                errorData[0] = "CsvTooManyRows";
                return { errorData };
            }

            // ヘッダ数チェック
            if (csvHeaders.length !== this.state.headerJson.length) {
                errorData[0] = "CsvHeaderError";
                return { errorData };
            }

            // ヘッダ設定値チェック
            csvHeaders.forEach((row, index) => {
                if (row !== this.state.headerJson[index].label) {
                    errorData[0] = "CsvHeaderError";
                    return { errorData };
                }
            });

            // メールアドレスの既存重複チェック用データ取得・作成
            const allMailAddress = await this.ascAxios(
                                        "post",
                                        "/User/getAllMailAddress"
                                    );
            const checkMailAddress = allMailAddress.data.map((row) => row.mail_address);

            // 権限の既存登録有無チェック用データ取得・作成
            const checkPermission = this.state.permissionSelect.map((row) => row.label);

            // メールアドレスのCSV内重複チェック用配列
            const csvUserMailAddressList = [];

            // メールアドレスのチェック(メールアドレス形式)
            const mailAddressCheck = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
            // パスワードのチェック(小文字の英数字が1文字以上必要・全角禁止・8文字以上)
            const passwordCheck = /^(?=.*?[a-z])(?=.*?[0-9])[\x20-\x7e]{8,}$/;

            // 1度チェックしたデータを毎回チェックしなくていいように以前の会社・拠点・フロアとしてそれぞれ保管する（負荷軽減）
            let previousCompany = [];
            let previousDepartment = [];
            let previousFloor = [];

            // CSVデータ部を1行ずつループ
            for (let [rowIndex, detailRow] of list.entries()) {
                
                let detail = {};
                let tmpDetail = detailRow.data;
                let checkBlankFlag = false;      //空白フラグ
                let previousCompanyIndex = null;
                let previousDepartmentIndex = null;

                //すべての項目が空白の場合はスキップする
                if (!tmpDetail.find(item => item.replace(/ /g,"").length > 0)) {
                    continue;
                }

                // CSV内重複チェックのため、配列に追加
                csvUserMailAddressList.push(tmpDetail[0]);

                // データ部のカラム数チェック
                if (tmpDetail.length !== this.state.headerJson.length) {
                    errorValidateUserCsv.push({
                        row: rowIndex,
                        message: this.sprintf(
                            this.props.langText.Message["CsvDataCountError"],
                            this.state.headerJson.length
                        )
                    });
                    break;
                }

                // 1カラムずつバリデーションチェックを行う。
                // エラーがあればエラー情報配列に行数、エラー内容をpush
                for (let [columnIndex, item] of tmpDetail.entries()) {
                    let value = "";
                    let errorFlg = false;
                    const jsonKey = this.state.headerJson[columnIndex].key;
                    const jsonLabel = this.state.headerJson[columnIndex].label;

                    // 全項目文字数チェック
                    if ([...item].length > this.props.charaLimit[`User_${jsonKey}`]) {
                        errorValidateUserCsv.push({
                            row: rowIndex,
                            message: this.sprintf(
                                this.props.langText.Message["CsvSizeError"],
                                jsonLabel,
                                this.props.charaLimit[`User_${jsonKey}`]
                            )
                        });
                    }

                    // 必須入力項目チェック
                    let requiredFieldArr = this.state.headerJson.filter(row => row.required && row.required === true);

                    if (requiredFieldArr.find(row => row.key === jsonKey) &&
                        (item === "" || item === undefined || item.length === 0)) {
                            errorValidateUserCsv.push({
                                row: rowIndex,
                                message: this.sprintf(
                                    this.props.langText.Message["CsvRequiredError"],
                                    jsonLabel
                                )
                            }
                        );
                    }

                    switch (jsonKey) {
                        case "companyName":
                            // 会社名の項目が空白であれば処理を終了する
                            if (item.length === 0) {
                                checkBlankFlag = true;
                                break;
                            }
                            // 以前に確認した会社名かどうかを確認して、あればインデックスを保管する
                            previousCompanyIndex = previousCompany.findIndex(row => row.name === item)
                            // 以前に確認した会社名でない場合
                            if (previousCompanyIndex === -1) {
                                // 会社名の登録チェック用データをサーバー側より取得する
                                const checkRegisteredCompany = await this.ascAxios("post",`${this.reactContainerPath}/checkRegisteredCompany`, {companyName: item});
                                // チェックした会社データを保管する
                                previousCompany.push({
                                    name: item,
                                    id: checkRegisteredCompany.data && checkRegisteredCompany.data.id ? checkRegisteredCompany.data.id : null
                                })
                                // 上で追加したデータのインデックスに更新する
                                previousCompanyIndex = previousCompany.length - 1
                                // 会社IDをデータ取得できなかったときはエラーにする
                                if(!previousCompany[previousCompanyIndex].id){
                                    errorValidateUserCsv.push({
                                        row: rowIndex,
                                        message: this.sprintf(
                                            this.props.langText.Message["CsvNotRegisteredInfoError"],
                                            jsonLabel,
                                            item
                                        )
                                    });
                                    errorFlg = true;
                                }
                            }
                            if (errorFlg) {
                                break;
                            }
                            //　以前に確認した会社データのID、または今回取得したIDを入れる
                            value = previousCompany[previousCompanyIndex].id;
                            break;

                        case "departmentName":
                            // 拠点名の項目が空白である、または会社管理者以上かつ空白フラグがtrueであれば処理を終了する
                            if (item.length === 0 || (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company) && checkBlankFlag)) {
                                checkBlankFlag = true;
                                break;
                            }
                            // 以前に確認した拠点名かどうかを確認して、あればインデックスを保管する
                            previousDepartmentIndex = previousDepartment.findIndex(row => row.name === item)
                            // 以前に確認した拠点名でない場合
                            if (previousDepartmentIndex === -1) {
                                // 会社名は入力値またはユーザー情報より取得する
                                let companyId = previousCompanyIndex !== null ? previousCompany[previousCompanyIndex].id : this.props.userInfo.company_id
                                // 拠点名の登録チェック用データをサーバー側より取得する
                                const checkRegisteredDepartment = 
                                    await this.ascAxios("post",`${this.reactContainerPath}/checkRegisteredDepartment`,
                                    {companyId: companyId, departmentName: item});
                                // チェックした拠点データを保管する
                                previousDepartment.push({
                                    name: item,
                                    id: checkRegisteredDepartment.data && checkRegisteredDepartment.data.id ? checkRegisteredDepartment.data.id : null,
                                    prefix: checkRegisteredDepartment.data && checkRegisteredDepartment.data.department_prefix ? checkRegisteredDepartment.data.department_prefix : null
                                })
                                // 上で追加したデータのインデックスに更新する
                                previousDepartmentIndex = previousDepartment.length - 1
                                // 拠点IDをデータ取得できなかったときはエラーにする
                                if(!previousDepartment[previousDepartmentIndex].id){
                                    errorValidateUserCsv.push({
                                        row: rowIndex,
                                        message: this.sprintf(
                                            this.props.langText.Message["CsvNotRegisteredInfoError"],
                                            jsonLabel,
                                            item
                                        )
                                    });
                                    errorFlg = true;
                                }
                            }
                            if (errorFlg) {
                                break;
                            }
                            // 以前に確認した拠点データのID、または今回取得したIDを入れる
                            value = previousDepartment[previousDepartmentIndex].id;
                            break;

                        case "floorName":
                            // フロア名の項目が空白である、または拠点管理者以上かつ空白フラグがtrueであれば処理を終了する
                            if (item.length === 0 || (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) && checkBlankFlag)) {
                                break;
                            }
                            // 以前に確認したフロア名かどうかを確認する
                            let checkedPreviousFloor = previousFloor.find(row => row.name === item)
                            // 以前に確認したフロア名でない場合
                            if (!checkedPreviousFloor) { 
                                // 会社名と拠点のプレフィックスは入力値またはユーザー情報より取得する
                                let companyId = previousCompanyIndex !== null ? previousCompany[previousCompanyIndex].id : this.props.userInfo.company_id
                                let departmentPrefix = previousDepartmentIndex !== null ? previousDepartment[previousDepartmentIndex].prefix : this.props.userInfo.department_prefix.slice(0, 2)
                                const checkRegisteredFloor = 
                                    await this.ascAxios("post",`${this.reactContainerPath}/checkRegisteredFloor`,
                                    {companyId: companyId, departmentPrefix: departmentPrefix, floorName: item});
                                // チェックしたフロアデータを保管する
                                previousFloor.push({
                                    name: item,
                                    id: checkRegisteredFloor.data && checkRegisteredFloor.data.id ? checkRegisteredFloor.data.id : null,
                                    prefix: checkRegisteredFloor.data && checkRegisteredFloor.data.department_prefix ? checkRegisteredFloor.data.department_prefix : null
                                })
                                // フロアIDをデータ取得できなかったときはエラーにする
                                if (!previousFloor[previousFloor.length - 1].id) {
                                    errorValidateUserCsv.push({
                                        row: rowIndex,
                                        message: this.sprintf(
                                            this.props.langText.Message["CsvNotRegisteredInfoError"],
                                            jsonLabel,
                                            item
                                        )
                                    });
                                    errorFlg = true;
                                }
                            }
                            if (errorFlg) {
                                break;
                            }
                            // 以前に確認したフロアデータがあればそのIDを、なければ今回取得したIDを入れる
                            value = checkedPreviousFloor ? checkedPreviousFloor.id : previousFloor[previousFloor.length - 1].id;
                            break;

                        case "mailAddress":
                            // メールアドレスのフォーマットチェック
                            if (!mailAddressCheck.test(item) && item.length !== 0) {
                                errorValidateUserCsv.push({
                                    row: rowIndex,
                                    message: this.sprintf(
                                        this.props.langText.Message["CsvFormatError"],
                                        jsonLabel
                                    )
                                });
                                errorFlg = true;
                            }
                            // メールアドレスの重複チェック(既存)
                            if (checkMailAddress.indexOf(item) >= 0) {
                                errorValidateUserCsv.push({
                                    row: rowIndex,
                                    message: this.sprintf(
                                        this.props.langText.Message["CsvDuplicateErrorInDb"],
                                        jsonLabel,
                                        item
                                    )
                                });
                                errorFlg = true;
                            }
                            // CSV内のメールアドレス重複チェック
                            let countIdInCsv = csvUserMailAddressList.filter((id) => id === item).length;
                            if (countIdInCsv > 1) {
                                errorValidateUserCsv.push({
                                    row: rowIndex,
                                    message: this.sprintf(
                                        this.props.langText.Message["CsvDuplicateErrorInCsv"],
                                        jsonLabel,
                                        item
                                    )
                                });
                                errorFlg = true;
                            }
                            if (errorFlg) {
                                break;
                            }
                            value = item;
                            break;
                        case "password":
                            // パスワードのフォーマットチェック
                            if (!passwordCheck.test(item) && item.length !== 0) {
                                errorValidateUserCsv.push({
                                    row: rowIndex,
                                    message: this.sprintf(
                                        this.props.langText.Message["CsvFormatError"],
                                        jsonLabel
                                    )
                                });
                                errorFlg = true;
                            }
                            if (errorFlg) {
                                break;
                            }
                            value = item;
                            break;
                        case "permission":
                            // 権限の登録有無チェック
                            if (checkPermission.indexOf(item) === -1 && item.length !== 0) {
                                errorValidateUserCsv.push({
                                    row: rowIndex,
                                    message: this.sprintf(
                                        this.props.langText.Message["CsvNotRegisteredInfoError"],
                                        jsonLabel,
                                        item
                                    )
                                });
                                errorFlg = true;
                            }
                            if (errorFlg || item.length === 0) {
                                break;
                            }
                            value = this.state.permissionSelect.find(row => row.label === item).value;
                            break;
                        default:
                            value = item;
                            break;
                    }
                    detail[this.state.headerJson[columnIndex].key] = value;
                }

                insertArray.push(detail);
            }


            if (errorValidateUserCsv.length > 0) {
                errorData[0] = "CsvNotAvailable";
                errorData[1] = errorValidateUserCsv;
            }
            return { insertArray, errorData };
        } catch (err) {
            console.error(err);
            throw new Error(err);
        }
    }

    /**
     * エラーモーダル（CSV投入処理）のクローズ処理
     */
    errorModalClose = () => {
        this.setState({ errorMessageModalShow: false });
    };

    render() {
        return (
            <div className='User'>
                <Row>
                    {
                        this.props.currentPermission.create
                        &&
                        <Col xs={12} md={12}>
                            <Button
                                id = "user-insert"
                                className = 'table-button'
                                bsStyle = 'default'
                                bsSize = 'sm'
                                onClick = {this.modalShow('insert')}
                            >
                                {this.props.langText.Body.InsertTitle}
                            </Button>
                            <Button
                                id="autoCallDetailList-csvInsert"
                                className="table-button"
                                bsStyle="default"
                                bsSize="sm"
                                onClick={this.modalShow("csvInsert")}>
                                {this.props.langText.Body.CsvImport}
                            </Button>
                        </Col>
                    }
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{height: this.props.tableHeight}}
                            manual = "manual"
                            columns = {this.state.columns}
                            data = {this.state.data}
                            pages = {this.state.pages}
                            loading = {this.state.loading}
                            defaultSorted={[{id: "id", desc: true}]}
                            onFetchData = {this.fetchData}
                            filtered={this.state.filtered}
                            filterable = {true}
                            previousText = {this.props.langText.Table.PreviousText}
                            nextText = {this.props.langText.Table.NextText}
                            loadingText = {this.props.langText.Table.LoadingText}
                            noDataText = {this.props.langText.Table.NoDataText}
                            rowsText = {this.props.langText.Table.RowsText}/>
                    </Col>
                    {this.state.errorMessageModalShow && (
                        <ErrorMessageModal
                            errorModalClose={this.errorModalClose}
                            state={this.state}
                            langText={this.props.langText}
                            errText={this.state.errText}
                        />
                    )}
                </Row>

                {/*モーダル表示分岐*/}
                {this.getModalBranch()}
            </div>
        )
    }
}