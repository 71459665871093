module.exports = {
    START: 0, // 開始
    PLAY: 1, // 再生
    QUESTION: 2, // 質問
    NUMBER: 4, // 番号入力
    TRANSFER: 5, // 転送
    RECORD: 6, // 録音
    COUNT: 7, // カウント
    JUMP: 8, // ジャンプ
    SMS: 13, // SMS送信
    TEMPLATE: 9, // テンプレート
    INCOMING: 10, // 着信
};