import React, {Component} from "react"
import { Panel, Button, Glyphicon, Col } from "react-bootstrap"
import { ViewCallHistoryElement, ViewVoiceTextElement, ViewCommentElement, ViewAudioControlElement, ViewCallCategoryTagElement} from "../Elements/AscElements/CallAnalysisElements";
import ButtonSpeechBubble from "../Elements/AscElements/SetButtonWithSpeechBubble";
import AscModal from "../Elements/Modal/Modal";
import "../Elements/FromGroup/form_select.css";
import InlineForm from "../Elements/FromGroup/InlineForm";

export default class CallAnalysisModal extends Component {
    getModalInfo = (modalType) => {
        let {
            langText,
            state,
            propSetState,
            validationHandle,
            onClick,
            customBtn1Func,
            customBtn2Func
        } = this.props;
        let info = null;

        switch (modalType) {
            case "analysis":
                info = (
                    <AscModal
                        state = {state}
                        propSetState = {propSetState}
                        langText = {langText}
                        validationHandle = {validationHandle}
                        onClick = {onClick}
                        customBtn1Func = {customBtn1Func}
                        customBtn2Func = {customBtn2Func}
                        customBtn1Msg = {(
                            state.selected
                            && state.selected.flag_data_json
                            && state.selected.flag_data_json.check_flag === "Y")
                            ? langText.Body.UnConfirmed
                            : langText.Body.Check
                        }
                        customBtn2Msg = {langText.Body.Close}
                        customBtn1Class = {(
                            state.selected
                            && state.selected.flag_data_json
                            && state.selected.flag_data_json.check_flag === "Y")
                            ? "unconfirmed-execute"
                            : "confirmed-execute"
                        }
                        customBtn2Class = "close-execute"
                        customBtn1Disabled = {validationHandle}
                    >
                        {this.getItemInfo(modalType)}
                    </AscModal>
                );
                break;
        
            case "callContentDownload":
                info = (
                    <AscModal
                        state = {state}
                        propSetState = {propSetState}
                        langText = {langText}
                        validationHandle = {validationHandle}
                        onClick = {() => onClick}
                    >
                        {this.getItemInfo(modalType)}
                    </AscModal>
                );
                break;
            default:
                break;
        }

        return info;
    }

    getItemInfo = (modalType) => {
        let {
            langText,
            state,
            boardWidth,
            propSetState,
            copyData,
            userInfo,
            updateVoiceData,
            getFilterData,
            getKeywordData,
            currentPermission,
            onPlayHandle,
            insertCommentData,
            updateCommentData,
            deleteCommentData,
            charaLimit,
            onTextChange_Limit,
            updateCallCategoryTag
        } = this.props;
        let info = null;

        switch (modalType) {
            case "analysis":
                info = (
                    <>
                        <Panel bsStyle="info">
                            <Panel.Heading>
                                <Panel.Title>
                                    {langText.Body.CallHistoryName}
                                </Panel.Title>
                            </Panel.Heading>
                            <ViewCallHistoryElement
                                state={state}
                                langText={langText}
                                boardWidth={boardWidth}
                            />
                        </Panel>
                        {
                            state.search_company_parameter_call_auto_tag &&
                            <Panel bsStyle="info">
                                <Panel.Heading>
                                    <Panel.Title>
                                        {langText.Body.CallCategoryTag}
                                    </Panel.Title>
                                </Panel.Heading>
                                <Panel.Body>
                                    <ViewCallCategoryTagElement
                                        state={state}
                                        langText={langText}
                                        userInfo={userInfo}
                                        propSetState={propSetState}
                                        updateCallCategoryTag={updateCallCategoryTag}
                                        currentPermission={currentPermission}
                                    />
                                </Panel.Body>
                            </Panel>
                        }
                        <Panel bsStyle="info">
                            <Panel.Heading>
                                <Panel.Title>
                                    {langText.Body.VoiceText}
                                    {state && state.selected && state.selected.text_data_json.length !==0 && 
                                    //音声テキストがあれば全体音声テキストコピーボタン・自動スクロールボタン表示
                                    <>
                                    <span className="pannel-btn-right">
                                        <ButtonSpeechBubble
                                            className="control-button"
                                            bsSize="xsmall"
                                            onClick={e => {
                                                let player = document.getElementById("audio-text-player");
                                                let panel = document.getElementById("voice-text-panel");
                                                propSetState({
                                                    auto_scroll_flag: !state.auto_scroll_flag,
                                                    message_box_data: [],
                                                    message_box_index: 0,
                                                });
                                                player.currentTime = 0;
                                                panel.scrollTo(0,0)
                                            }}
                                            DisplayIcon="resize-vertical"
                                            speechBubble={langText.Body.AutoScroll}
                                        />
                                        <ButtonSpeechBubble
                                            className="control-button"
                                            bsSize="xsmall"
                                            onClick={e => copyData("voice-all", state.selected.text_data_json)}
                                            disabled = {state.voice_edit_flag.some(row=> row)}
                                            DisplayIcon="copy"
                                            speechBubble={langText.Body.Copy}
                                        />
                                    </span>
                                    </>
                                    }
                                </Panel.Title>
                            </Panel.Heading>
                            <ViewVoiceTextElement
                                state={state}
                                langText={langText}
                                userInfo={userInfo}
                                copyData={copyData}
                                propSetState={propSetState}
                                updateVoiceData={updateVoiceData}
                                getFilterData={getFilterData}
                                getKeywordData={getKeywordData}
                                currentPermission={currentPermission}
                            />
                            <Panel.Footer className="analysis-audio-control-footer">
                                <ViewAudioControlElement
                                    state={state}
                                    langText={langText}
                                    userInfo={userInfo}
                                    copyData={copyData}
                                    propSetState={propSetState}
                                    updateVoiceData={updateVoiceData}
                                    getFilterData={getFilterData}
                                    onPlayHandle={onPlayHandle}
                                    getKeywordData={getKeywordData}
                                    currentPermission={currentPermission}
                                />
                            </Panel.Footer>
                        </Panel>
                        <Panel bsStyle="info">
                            <Panel.Heading>
                                <Panel.Title>
                                    {langText.Body.Comment}
                                    {state && state.selected && state.selected.comment_data_json &&
                                        //コメントがあれば全体コメントコピーボタン表示\
                                        <span className="pannel-btn-right">
                                            <ButtonSpeechBubble
                                                className="control-button"
                                                bsSize="xsmall"
                                                onClick={e => copyData("comment-all", state.selected.comment_data_json)}
                                                disabled = {state.comment_edit_flag.some(row=> row)}
                                                DisplayIcon="copy"
                                                speechBubble={langText.Body.Copy}
                                            />
                                        </span>
                                    }
                                </Panel.Title>
                            </Panel.Heading>
                            <ViewCommentElement
                                state={state}
                                langText={langText}
                                userInfo={userInfo}
                                copyData={copyData}
                                propSetState={propSetState}
                                insertCommentData={insertCommentData}
                                updateCommentData={updateCommentData}
                                deleteCommentData={deleteCommentData}
                                currentPermission={currentPermission}
                            />
                        </Panel>
                    </>
                );
                break;
        
            case "callContentDownload":
                info = (
                    <Panel bsStyle="info">
                        <Panel.Body>
                            <div className="message">
                                <p>{langText.Message.CallContentDownloadMessage[0]}</p>
                                <p>{langText.Message.CallContentDownloadMessage[1]}</p>
                                <p>{langText.Message.CallContentDownloadMessage[2]}</p>
                                <p className="text-danger">{langText.Message.DownloadDeadline}</p>
                            </div>
                            <Col sm={12} md={12} className="padding-left-0">
                                <Col sm={12} md={3} lg={2} className="padding-left-0 margin-top-05 width-auto">
                                    {langText.Body.FileName}
                                </Col>
                                <Col sm={12} md={9} lg={9} className="padding-left-0">
                                    <InlineForm
                                        key="fileName"
                                        controlId="fileName"
                                        type="text"
                                        value={state.fileName}
                                        onChange={e => onTextChange_Limit(e, "fileName", charaLimit.File_Name)}
                                        placeholder={`${userInfo.user_name_sei + userInfo.user_name_mei} + ${langText.Body.CreateDatetime}`}
                                    />
                                </Col>
                            </Col>
                        </Panel.Body>
                    </Panel>
                );
                break;
            default:
                break;
        }

        return info;
    }
    render() {
        let modalType = this.props.state.modalType;

        return (
            <>
                {this.getModalInfo(modalType)}
            </>
        );
    }
}
