import React from "react";
import { Button, Row, Col, Glyphicon } from 'react-bootstrap'
import AscComponent from "../../components/AscComponent";
import SetBreadCrumb from "../Elements/AscElements/SetBreadCrumb";
import { SelectCompanyElement } from "../Elements/AscElements/CompanyElements";
import * as GlobalConst from '../../components/AscConstants';
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import InlineForm from "../Elements/FromGroup/InlineForm";
import { MultiSelect } from "react-multi-select-component";
import CommonTable from "../Elements/Table/CommonTable";

export default class WaitCallSummary extends AscComponent {

    constructor(props) {
        super(props);

        const now = moment()
        const startDate = moment(now).startOf("days");
        const endDate = moment(now).endOf("days");
        this.state = {
            columns: this.getColumnsData(),
            summaryDateDisplay: now,
            company_id: this.props.userInfo.company_id || 0,
            companyName: this.getScopeGreaterEqual(
                GlobalConst.SCOPE_OBJECT.oem
            )
                ? this.props.userInfo.company_name
                : "",
            companySelect: [],
            departmentSelect: [],
            department_id: [],
            floorSelect: [],
            floor_id: [],
            floorFlg: this.props.userInfo.floor_flg,
            validationMessage: null,
            externalArr: [],
            externalSelected: [],
            summaryStartDate: moment.tz(startDate, "Asia/Tokyo").format(),
            summaryEndDate: moment.tz(endDate, "Asia/Tokyo").format(),
            // 検索条件の初期値に、会社ID：ログインユーザーの会社ID 集計日：当日 を設定
            filtered: [
                { id: "company_id", value: this.props.userInfo.company_id || 0 },
                { id: "date", value: [moment.tz(startDate, "Asia/Tokyo").format(), moment.tz(endDate, "Asia/Tokyo").format()]}
            ],
            autoUpdateInterval: 1
        }
        if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)) {
            this.setCommonCompanySelect("/Summary");
            this.setCommonDepartmentSelect(this.props.userInfo.company_id, this.props.userInfo.floor_flg);
            this.getAllExternal(this.props.userInfo.company_id);
        } else if (this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department)&&
                   this.props.userInfo.floor_flg) {
            this.setCommonFloorSelect(this.props.userInfo.parent_department_id);
            this.getExternalByDepartmentIdOrFloorId(this.props.userInfo.parent_department_id, this.props.userInfo.department_prefix);
        } else {
            this.getExternalByDepartmentIdOrFloorId(this.props.userInfo.department_id);
        }
    };

    // 他の画面に移動の時、Interval削除
    componentWillUnmount() {
        if (this.state.interval) clearInterval(this.state.interval);
    }

    getColumnsData() {
        let columnsDataList = [];
        if(this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)){
            columnsDataList.push({
                Header: this.props.langText.Body.CompanyName,
                accessor: "cm12_companies.company_name",
                filterable: false,
                sortable: false
            })
        }
        columnsDataList.push(
            {
                Header: this.props.langText.Body.ExternalNumberName,
                accessor: "cm61_external_numbers.memo",
                filterable: false,
                sortable: false
            },
            {
                Header: this.props.langText.Body.ExternalNumber,
                accessor: "cm61_external_numbers.display_number",
                filterable: false,
                sortable: false
            },
            {
                Header: this.props.langText.Body.WaitCallCount,
                accessor: "wait_call_current_count",
                filterable: false
            },
            {
                Header: this.props.langText.Body.WaitCallCountTotal,
                accessor: "wait_call_total_count",
                filterable: false
            },
            {
                Header: this.props.langText.Body.WaitCallDiscardCount,
                accessor: "wait_call_discard_count",
                filterable: false
            },
            {
                Header: this.props.langText.Body.WaitCallMaxTime,
                accessor: "wait_call_max_time",
                filterable: false
            },
            {
                Header: this.props.langText.Body.WaitCallDiscardAvgTime,
                accessor: "wait_call_discard_avg_time",
                filterable: false
            }
        );

        return columnsDataList;
    }

    onSelectChange = stateKey => event => {
        super.onSelectChange(event, stateKey);
        if (stateKey === "company_id") {
            const message = event.value
                ? null
                : this.props.langText.Message.CompanySelectError;
            this.setState({
                companyName: event.label,
                department_id: [],
                floor_id: [],
                floorFlg : event.floor_flg == "Y" ? true : false,
                validationMessage: message || this.state.validationMessage,
            });
            this.getAllExternal(
                event.value
            );
            this.setCommonDepartmentSelect(event.value, event.floor_flg);
        } else if (stateKey === "autoUpdateInterval") {
            clearInterval(this.state.interval);
            this.setState({disableFlg: false});
        }
    }

    // 複数選択用のChange関数
    onMultiCheckChange = stateKey => async event => {
        try {
            let departmentId = [];
            switch (stateKey) {
                case "department_id":
                    departmentId = event.map(row => {return row.value});
                    this.setCommonFloorSelect(departmentId);
                    const departmentPrefix = ( event[0] && event[0].department_prefix ) ? event[0] && event[0].department_prefix : "";
                    this.getExternalByDepartmentIdOrFloorId(departmentId, departmentPrefix);
                    break;
                case "floor_id":
                    departmentId = event.map(row => {return row.value});
                    this.getExternalByDepartmentIdOrFloorId(departmentId);
                    break;
                default:
                    break;
            }
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }

        this.setState({[stateKey]: event})
    }

    onChangeDate = () => event => {
        if (event === null) return false;

        const start = moment(event).startOf("days");
        const end = moment(event).endOf("days");

        this.setState(
            {
                summaryDateDisplay: moment(event),
                summaryStartDate: moment(start).tz("Asia/Tokyo").format(),
                summaryEndDate: moment(end).tz("Asia/Tokyo").format()
            }
        );
    }

    onIntervalChange = (e) => {
        let {autoUpdateInterval} = this.state;
        let stateInterval;

        if (autoUpdateInterval === 1) {
            // 自動更新無しが選択された場合は自動更新を止める
            this.onSearch();
            clearInterval(this.state.interval);
        } else if (autoUpdateInterval !== 1 && e) {
            // 自動更新間隔が選択されたら一回画面更新後、自動更新
            // 1分更新の場合、autoUpdateIntervalは60000(ms)
            // 5分更新の場合、autoUpdateIntervalは300000(ms)
            clearInterval(this.state.interval);
            this.onSearch();

            stateInterval = setInterval(() => {
                this.onSearch();
            }, autoUpdateInterval);

            // インターバルを設定し、更新ボタンを非活性化する
            this.setState({interval : stateInterval, disableFlg: true});
        }
    }

    onSearch = async () => {
        try {
            const filter = [];

            // 画面で指定されている項目を検索条件に追加
            if(this.state.company_id) {
                filter.push({
                    id: "company_id",
                    value: this.state.company_id
                });
            }

            if(this.state.department_id.length > 0) {
                filter.push({
                    id: "department_id",
                    value: this.state.department_id.map(department => department.value),
                });
            }

            if(this.state.floor_id.length > 0) {
                filter.push({
                    id: "floor_id",
                    value: this.state.floor_id.map(floor => floor.value),
                });
            }

            if(this.state.externalSelected.length > 0) {
                filter.push({
                    id: "external_number",
                    value: this.state.externalSelected.map(external_number => external_number.value),
                });
            }

            filter.push({
                    id: "date",
                    value: [this.state.summaryStartDate, this.state.summaryEndDate]
                }
            )

            filter.push({
                id: "floorFlg",
                value: this.state.floorFlg
            });

            this.setState({
                filtered: filter,
            }, () => {
                // 検索時にページを先頭にする
                this.table.current.state.page = 0;
            });

        } catch(err) {
            this.showErrorObjectMesssage(err);
        }
    }

    setCommonCompanySelect = async (reactContainerPath) => {
        try {
            await super.setCommonCompanySelect(reactContainerPath);
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    setCommonDepartmentSelect = async (company_id, floor_flg) => {
        try {
            const departmentlist = await super.setCommonDepartmentSelect({
                container: "/Summary",
                company_id: company_id,
                floor_flg: floor_flg == true || floor_flg == "Y" ? true : false
            });
            this.setCommonFloorSelect(departmentlist);
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    setCommonFloorSelect = async (departmentIds) => {
        try {
            await super.setCommonFloorSelect({
                container: "/Summary",
                departmentIds
            });
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    };

    getAllExternal = async (companyId) => {
        try {
            const external = await this.ascAxios("post", `Summary/getExternalByCompanyId`, {
                companyId
            });
            this.setState({
                externalArr: external.data,
                externalSelected: external.data,
            });
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    };

    getExternalByDepartmentIdOrFloorId = async (departmentId, departmentPrefix = null) => {
        try {
            const externalArr = await this.ascAxios("post", `Summary/getExternalByDepartmentIdOrFloorId`, {
                departmentId,
                departmentPrefix
            });

            this.setState({
                externalArr: externalArr.data,
                externalSelected: externalArr.data,
            });
        } catch (err) {
            this.showErrorObjectMesssage(err);
        }
    }

    render(){
        return(
            <>
                <SetBreadCrumb
                    displayItems={[
                        { link: "/Summary", name: this.props.langText.Body.Summary },
                        { name: this.props.langText.Body.WaitCallSummary }
                    ]}
                />
                <Row>
                    <Col>
                        <Col xs={1} className="margin-top-1">
                            {this.props.langText.Body.CompanyName}
                        </Col>
                        {/* OEM管理者以上は会社選択表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.oem)
                            ?
                            <Col xs={2} className="margin-top-05">
                                <SelectCompanyElement
                                    state={this.state}
                                    colWidth = {8.5}
                                    langText={this.props.langText}
                                    onSelectChange={this.onSelectChange("company_id")}
                                    labelDisabled={true}
                                ></SelectCompanyElement>
                            </Col>
                            :
                            <Col xs={2} className="margin-top-1">
                                {this.props.userInfo.company_name}
                            </Col>
                        }
                        <Col xs={1} className="margin-top-1">
                            {this.props.langText.Body.DepartmentName}
                        </Col>
                        {/* 会社管理者以上は拠点選択表示 */}
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.company)
                            ?
                            <Col xs={2} className="margin-top-05">
                                <MultiSelect
                                    hasSelectAll={true}
                                    options={this.state.departmentSelect}
                                    value = {this.state.department_id}
                                    onChange={this.onMultiCheckChange("department_id")}
                                    overrideStrings={{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                        allItemsAreSelected: this.props.langText.Body.AllSelected,
                                        selectAll: this.props.langText.MultiSelect.AllSelect,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>
                            :
                            <Col xs={2} className="margin-top-1">
                                {this.props.userInfo.department_name}
                            </Col>
                        }
                        {/* 拠点管理者以上かつフロア有りの場合はフロア選択表示 */}
                        {((this.props.userInfo.floor_flg && this.state.floorFlg) || this.state.floorFlg) &&
                            <Col xs={1} className="margin-top-1">
                                {this.props.langText.Body.FloorName}
                            </Col>
                        }
                        {this.getScopeGreaterEqual(GlobalConst.SCOPE_OBJECT.department) && this.state.floorFlg
                            ?
                            <Col xs={2} className="margin-top-05">
                                <MultiSelect
                                    hasSelectAll={true}
                                    options={this.state.floorSelect}
                                    value = {this.state.floor_id}
                                    onChange={this.onMultiCheckChange("floor_id")}
                                    overrideStrings={{
                                        selectSomeItems: this.props.langText.SelectOption.Placeholder,
                                        allItemsAreSelected: this.props.langText.Body.AllSelected,
                                        selectAll: this.props.langText.MultiSelect.AllSelect,
                                        search: this.props.langText.Body.Search,
                                    }}
                                />
                            </Col>
                            :
                            this.state.floorFlg
                            ?
                                /* フロア管理者以下かつフロア有りの場合はフロア名をラベル表示 */
                                <Col xs={2} className="margin-top-1">
                                    {this.props.userInfo.floor_name}
                                </Col>
                            :
                                /* その他の場合はフロア名は非表示 */
                                null
                        }
                        <Col className = "seatview-form interval-select margin-top-05" xs={4}>
                            <Col xs={9}>
                                <InlineForm
                                    type = "select"
                                    options = {this.props.langText.SelectOption.autoUpdateIntervalList}
                                    value = {this.state.autoUpdateInterval}
                                    onChange = {this.onSelectChange("autoUpdateInterval")}
                                />
                            </Col>
                            <Col xs={1}>
                                <Button
                                    className = "auto-update-btn"
                                    bsStyle = "primary"
                                    bsSize = "sm"
                                    onClick = {e => this.onIntervalChange(e)}
                                    disabled = {this.state.disableFlg}
                                >
                                    <Glyphicon glyph="refresh"/>
                                </Button>
                            </Col>
                        </Col>
                    </Col>
                </Row>
                <Row className="margin-top-1">
                    <Col>
                        <Col xs={1} className="margin-top-05">
                            {this.props.langText.Body.SummaryTargetDay}
                        </Col>
                        <Col xs={2}>
                            <DatePicker
                                onChange={this.onChangeDate("startDate")}
                                selected={this.state.summaryDateDisplay}
                                dateFormat="YYYY-MM-DD"
                                className="form-control"
                                todayButton={this.props.langText.Body.Today}
                                maxDate = {new Date()}
                            />
                        </Col>
                        <Col xs={1} className="analysis-label">
                            <div className="margin-top-05">{this.props.langText.Body.CustomerBoardExtension}</div>
                        </Col>
                        <Col xs={2}>
                            <MultiSelect
                                value={this.state.externalSelected}
                                onChange={this.onMultiCheckChange(
                                    "externalSelected"
                                )}
                                options={this.state.externalArr}
                                overrideStrings={{
                                    selectSomeItems:
                                        this.props.langText.SelectOption.Placeholder,
                                    allItemsAreSelected:
                                        this.props.langText.MultiSelect.AllSelected,
                                    selectAll:
                                        this.props.langText.MultiSelect.AllSelect,
                                    search: this.props.langText.Body.Search,
                                }}
                            />
                        </Col>
                        <Col xs={1} >
                            <Button
                                id="summary"
                                className="summaryBtn margin-top-05"
                                bsStyle="primary"
                                bsSize="sm"
                                onClick={() => this.onSearch()}
                                disabled={!!this.state.validationMessage}
                            >
                                {this.props.langText.Body.Summary}
                            </Button>
                        </Col>
                    </Col>
                </Row>
                <Row className="margin-top-1">
                    <Col xs={12} md={12}>
                        <CommonTable
                            talbeRef={this.table}
                            style={{ height: this.props.tableHeight }}
                            manual="manual"
                            columns={this.state.columns}
                            data={this.state.data}
                            pages={this.state.pages}
                            loading={this.state.loading}
                            onFetchData={this.fetchData}
                            filtered={this.state.filtered}
                            previousText={this.props.langText.Table.PreviousText}
                            nextText={this.props.langText.Table.NextText}
                            loadingText={this.props.langText.Table.LoadingText}
                            noDataText={this.props.langText.Table.NoDataText}
                            rowsText={this.props.langText.Table.RowsText}
                        />
                    </Col>
                </Row>
            </>
        );
    }

}