import React from "react";
import AscComponent from "../../../components/AscComponent";
import Chart from "react-apexcharts";


export default class CallHeatMapChart extends AscComponent {
    constructor(props) {
        super(props);
        
        this.state = {
            indexKey: 1,
            series: [{
                data: []
            }],
            options: {
                chart: {
                    id: 'basic-heatmap',
                    toolbar: {
                        show: false
                    },
                    background: '#fff'
                },
                dataLabels: {
                    enabled: true,
                    formatter: function(value) {
                        return value === 0 ? '' : value.toLocaleString();
                    },
                    style: {
                        colors: ['#000']
                    }
                },
                colors: ['#2E9AFE'],
                xaxis: {
                    position: 'top',
                    tooltip: {
                        enabled: false
                    }
                },
                plotOptions: {
                    heatmap: {
                        colorScale: {
                            ranges: [{
                                from: 0,
                                to: 0,
                                color: '#EFF5FB'
                            }]
                        }
                    }
                },
                yaxis: {
                    labels: {
                        formatter: function (value, index) {
                            let result = '';

                            if ((index + 1) % 6 === 0) result = value;
                            if (value === "11PM") result = "12AM";

                            return result;
                        },
                        offsetY: 10
                        
                    }
                },
                legend: {
                    show: false
                }
            }
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (
            prevProps.state.call_heat_map_view_level !== this.props.state.call_heat_map_view_level ||
            prevProps.data !== this.props.data    
        ) {
            this.onChartDataChange(this.props.state.call_heat_map_view_level);
        }
    }

    onChartDataChange = (level) => {
        let {
            data,
            langText,
            filtered
        } = this.props;
        let hours = [],
            returnArr = [],
            method = (filtered.find(row => row.id === "summary_method")).value === "average"
                    ? langText.Body.Average
                    : langText.Body.MedianValue,
            unit = level === "all" ? langText.Body.TimeCount : langText.Body.Percent;

        if (level !== "all") {
            method = level === "in" ? langText.Body.InboundRate : langText.Body.OutboundRate;
        }

        for (let i = 0; i < 24; i++) {
            let hour = String(i);
            hours.push(hour); 
        }

        hours.forEach(hour => {

            let returnData = {
                name: this.convertToTimeFormat(Number(hour)),
                data: []
            };
            let dataArr = [];

            if (data && data.heatmap && data.heatmap.length > 0) {
                data.heatmap.forEach(day => {
                    let idx = day.value.findIndex(row => row.key === hour),
                        series_data = {};
    
                    switch (day.key) {
                        case "7":
                            series_data.x = "日曜";
                            break;
                        case "1":
                            series_data.x = "月曜";
                            break;
                        case "2":
                            series_data.x = "火曜";
                            break;
                        case "3":
                            series_data.x = "水曜";
                            break;
                        case "4":
                            series_data.x = "木曜";
                            break;
                        case "5":
                            series_data.x = "金曜";
                            break;
                        case "6":
                            series_data.x = "土曜";
                            break;
                        default:
                            break;
                    }
                    
                    if (level === "all") series_data.y = day.value[idx].all.toFixed(1);
                    else if (level === "out") series_data.y = day.value[idx].out.toFixed(1);
                    else if (level === "in") series_data.y = day.value[idx].in.toFixed(1);
                    
                    dataArr.push(series_data);
                });
            }

            returnData.data = dataArr;
            returnArr.push(returnData);
        });

        returnArr.reverse();

        this.setState({
            options: {
                ...this.state.options,
                tooltip: {
                    custom: function({ series, seriesIndex, dataPointIndex, w }) {
                        return `
                            <div class="heatmap-tooltip">
                                <div>${w.globals.labels[dataPointIndex]},${w.config.series[seriesIndex].name}</div>
                                <div>${method}: ${series[seriesIndex][dataPointIndex].toLocaleString()}${unit}</div>
                            </div>
                        `;
                    }
                }
            },
            series: [
                ...returnArr
            ],
            indexKey: this.state.indexKey + 1
        });
    }

    convertToTimeFormat = (time) => {
        if (time >= 0 && time <= 11) {
            return time === 0 ? "12AM" : time + "AM";
        } else if (time === 12) {
            return "12PM";
        } else {
            return (time - 12) + "PM";
        }
    }

    render() {
        return (
            <div className="call-heat-map-chart">
                    <Chart 
                        options={this.state.options} 
                        series={this.state.series} 
                        type="heatmap" 
                        height={600}
                        key={this.state.indexKey} />
            </div>
        );
    }
}
