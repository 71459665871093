import React, {Component} from "react";
import {Panel} from "react-bootstrap";
import AscModal from "../Elements/Modal/Modal";
import InlineForm from "../Elements/FromGroup/InlineForm";

export default class SignInModal extends Component {
    getResetPasswordItem = () => {
        let {
            state,
            langText,
            onTextChange,
            validationHandle
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <>
            <p className = "signin-message">
                {langText.Message.SignIn_NoExistMailAlert1}
            </p>
            <p className = "signin-message">
                {langText.Message.SignIn_NoExistMailAlert2}
            </p>
            </>
        );

        itemArr.push(
            <InlineForm
                key = "emailReset"
                controlId = "emailReset"
                label = {langText.Body.EmailAddress}
                type = "text"
                value = {state.emailReset}
                onChange = {e => onTextChange(e, "emailReset")}
                validationState = {validationHandle("emailReset")}/>
        );

        return itemArr;
    }

    getSubmitNewPassword = () => {
        let {
            state,
            langText,
            onTextChange,
            validationHandle,
            passwordValidationHandle
        } = this.props;
        let itemArr = [];

        itemArr.push(
            <p className = "signin-message">
                {langText.Message.SignIn_ResetPasswordExpireMessage}
            </p>
        );

        itemArr.push(
            <InlineForm.static
                key = "emailReset"
                controlId = "emailReset"
                label = {langText.Body.EmailAddress}>
                {state.emailReset}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm
                key = "resetPasswordCode"
                controlId = "resetPasswordCode"
                label = {langText.Body.VerificationCode}
                type = "text"
                value = {state.resetPasswordCode}
                onChange = {e => onTextChange(e, "resetPasswordCode")}
                validationState = {validationHandle("resetPasswordCode")}/>
        );

        itemArr.push(
            <InlineForm
                key = "newPassword"
                controlId = "newPassword"
                label = {langText.Body.PasswordNew}
                type = "password"
                value = {state.newPassword}
                onChange = {e => onTextChange(e, "newPassword")}
                validationState = {passwordValidationHandle("newPassword", state)}
                placeholder = {langText.Message.Password_Validation}/>
        );

        itemArr.push(
            <InlineForm
                key = "newPasswordRe"
                controlId = "newPasswordRe"
                label = {langText.Body.PasswordNewRe}
                type = "password"
                value = {state.newPasswordRe}
                onChange = {e => onTextChange(e, "newPasswordRe")}
                validationState = {passwordValidationHandle("newPasswordRe", state)}
                placeholder = {langText.Message.Password_Validation}/>
        );
        
        if((state.newPassword !== state.newPasswordRe) && (state.newPassword.length > 0 && state.newPasswordRe.length > 0)){
            itemArr.push(
                <p key = "passwordMismatch" className="text-danger">
                    {langText.Body.PasswordMismatch}
                </p>
            );
        }

        return itemArr;
    }

    getChangePasswordItem = () => {
        let {
            state,
            langText,
            charaLimit,
            onTextChange_Limit,
            passwordValidationHandle
        } = this.props;

        let itemArr = [];

        itemArr.push(
            <p key = "passwordExpiredMsg" className="text-danger">
                {langText.Body.LoginUserPasswordExpired}
            </p>
        );

        itemArr.push(
            <InlineForm.static
                key = "mailAddress"
                controlId = "mailAddress"
                label = {langText.Body.EmailAddress}>
                {state.emailLogin}
            </InlineForm.static>
        );

        itemArr.push(
            <InlineForm
                key = "oldPassword"
                controlId = "oldPassword"
                label = {langText.Body.PasswordOld}
                type = "password"
                value = {state.oldPassword}
                onChange = {e => onTextChange_Limit(e, "oldPassword", charaLimit.User_oldPassword)}
                validationState = {passwordValidationHandle("oldPassword")}
                placeholder = {langText.Message.Password_Validation}
                autoComplete="new-password"/>
        );

        itemArr.push(
            <InlineForm
                key = "newPassword"
                controlId = "newPassword"
                label = {langText.Body.PasswordNew}
                type = "password"
                value = {state.newPassword}
                onChange = {e => onTextChange_Limit(e, "newPassword", charaLimit.User_newPassword)}
                validationState = {passwordValidationHandle("newPassword")}
                placeholder = {langText.Message.Password_Validation}
                autoComplete="new-password"/>
        );

        if((state.oldPassword === state.newPassword) && (state.oldPassword.length > 0 && state.newPassword.length > 0)){
            itemArr.push(
                <p key = "passwordDuplicateOld" className="text-danger">
                    {langText.Body.PasswordDuplicateOld}
                </p>
            );
        }

        itemArr.push(
            <InlineForm
                key = "newPasswordRe"
                controlId = "newPasswordRe"
                label = {langText.Body.PasswordNewRe}
                type = "password"
                value = {state.newPasswordRe}
                onChange = {e => onTextChange_Limit(e, "newPasswordRe",charaLimit.User_newPasswordRe)}
                validationState = {passwordValidationHandle("newPasswordRe")}
                placeholder = {langText.Message.Password_Validation}
                autoComplete="new-password"/>
        );
        if((state.newPassword !== state.newPasswordRe) && (state.newPassword.length > 0 && state.newPasswordRe.length > 0)){
            itemArr.push(
                <p key = "passwordMismatch" className="text-danger">
                    {langText.Body.PasswordMismatch}
                </p>
            );
        }

        return itemArr;
    }

    render() {
        let signInItem = null;

        if (this.props.state.modalType === "resetPassword") {
            signInItem = this.getResetPasswordItem();
        } else if (this.props.state.modalType === "submitNewPassword") {
            signInItem = this.getSubmitNewPassword();
        } else if (this.props.state.modalType === "updateSelfPassword") {
            signInItem = this.getChangePasswordItem();
        }

        return (
            <AscModal
                state = {this.props.state}
                propSetState = {this.props.propSetState}
                langText = {this.props.langText}
                validationHandle = {this.props.validationHandle}
                onClick = {this.props.onClick}>
                <Panel bsStyle="info">
                    <Panel.Body>
                        {signInItem}
                    </Panel.Body>
                </Panel>
            </AscModal>
        );
    }
}