import React, { Component } from "react";
import { Creatable, components } from "react-select";

export default class KeywordSelect extends Component {
    render() {
        const {
            state,
            onChange,
            langText,
            onInputChangeHandle,
            propSetState
        } = this.props;

        let keywords = state.keywords ? state.keywords : [];

        const customComponents = {
            Menu: (props) => (
                <components.Menu {...props}>
                    {props.children}
                    <div>
                        {
                            state.inputValue &&
                            !(keywords.some(item => item.label === state.inputValue)) &&
                            <>
                                <div
                                    className="custom-create-option"
                                    onClick={() => {
                                        onChange([
                                            ...keywords,
                                            {
                                                label: state.inputValue,
                                                value: state.inputValue,
                                                isNot: true
                                            }
                                        ], "keyword");

                                        propSetState({
                                            inputValue: ""
                                        })
                                    }}
                                >
                                    {`${state.inputValue}${langText.Message.NotOptionCreating}`}
                                </div>
                                <div
                                    className="custom-create-option-regexp"
                                    onClick={() => {
                                        onChange([
                                            ...keywords,
                                            {
                                                label: state.inputValue,
                                                value: state.inputValue,
                                                isRegExp: true
                                            }
                                        ], "keyword");

                                        propSetState({
                                            inputValue: ""
                                        })
                                    }}
                                >
                                    {`${state.inputValue}${langText.Message.RegExpOptionCreating}`}
                                </div>
                            </>
                        }
                    </div>
                </components.Menu>
            )
        };

        const customStyles = {
            multiValue: (styles, { data }) => {
                if (data.isNot) {
                    return {
                        ...styles,
                        backgroundColor: '#FF8787',
                    };
                } else if (data.isRegExp) {
                    return {
                        ...styles,
                        backgroundColor: '#f5f5dc',
                        border: data.isValid ? '' : '2px solid red',
                        color: data.isValid ? '' : 'red'
                    };
                }
                return styles;
            }
        };

        return (
            <Creatable
                components={customComponents}
                isMulti
                isClearable
                inputValue={state.inputValue}
                onInputChange={onInputChangeHandle}
                value={state.keywords}
                placeholder={langText.Message.Validation_string_input}
                styles={customStyles}
                onChange={e => onChange(e, "keyword")}
                noOptionsMessage={() => langText.Message.Validation_string_input}
                formatCreateLabel={(inputText) => `${inputText} ${langText.Message.Creating}`}
            />
        );
    }
}
